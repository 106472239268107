import React, { useState, Fragment, useEffect, useContext } from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import List from "./List";
import ScreeningListService from "../ScreeningListService";
import Pagination from "../../Pagination";
import ConfirmMenu from "../../Modals/ConfirmMenu";
import ProjectMenu from "../../Modals/ProjectMenu";
import FormTemplateId from "../../../constants/FormTemplateList";
import CommentsModal from "../../Modals/CommentsModal";
import ChecklistsModal from "../../Modals/ChecklistsModal"
import {MenuItem, NavIcon} from '../../Common/MenuItem'
import CardMenu from "../../Modals/CardMenu";
import { Link } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import useStyle from "../../../hooks/useStyle";
import PreLoadSpinner from "../../PreLoadSpinner";
import FilterModal from "./ScreeningFilterModal";
import CommonService from "../../../services/CommonService";
import ScreeningListTable from './ScreeningListTable'
import {usePageTitle} from '../../../hooks/pageHead'

let requestParams = {}
let selectedList = {}
let dataSource = {}

const ScreeningLists = (props) => {
  usePageTitle('Screening Lists')
  useStyle('/css/tile-components.css', true)

  let cardGridCss = "col-md-4";
  let cardHeightCss = "ht-420";
  if (props.id) {
    cardGridCss = "col-sm-12";
    cardHeightCss = "mih-380";
  }

  let params = props.match.params;
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [lists, setLists] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isFormFilterOpen, toggleModal:toggleFormFilterModal } = useModal()
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal,} = useModal()
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()
  const [formFieldsList, setFormFieldsList] = useState({})
  const [showListsTable, setShowListsTable] = useState(false)

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      organization_id:currentUser.current_organization_id,
      screening_event_id:params.screening_event_id,
      form_fields_params: {
        ids:[543]
      },
    }

    getScreeningList()
  }, [props.searchString, currentpage])

  let getScreeningList = () => {
    ScreeningListService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        dataSource = res.data.data_source_list;
        setFormFieldsList(res.data.form_fields || {})
        setLists(res.data.screening_list)
      }
    })
  }

  const deleteActivity = (e) => {
    e.preventDefault()
    ScreeningListService.delete(selectedList.id).then((res) => {
      if (res.status == 204) {
        setLists(
          lists.filter((i) => i.id !== selectedList.id)
        )
      }
    })
    toggleDeleteModal(e)
  }

  const showMenu = (e, a) => {
    selectedList = a
    toggleMenuModal(e)
  }

  const addChecklist = (e, a) => {
    selectedList = a
    toggleChecklistModal(e)
  }

  const addNotes = (e, a) => {
    selectedList = a
    toggleCommentModal(e)
  }

  const toggleEventTable = () => setShowListsTable(!showListsTable)

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        <a href="#" onClick={(e) => toggleDeleteModal(e)}>
          <span className="font-13">{item.form_id} - {item.record_id}</span>
        </a>

        <Link to={`/screening_lists/create/${item.screening_event_id}/${item.form_type}/${item.form_id}/${item.id}`}>
          <i className="far fa-edit font-15" aria-hidden="true"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </Link>

        <a href="#" onClick={(e) => toggleDeleteModal(e)}>
          <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ list }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, list)} className="fas fa-bars m-r-15 font-16"/>
  )

  /*if(lists.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  const listCard = lists && lists.map((a, i) => (
      <div className={cardGridCss} key={i}>
        <List
          list={a}
          dataSource={dataSource}
          menu={Menu}
          addMenu={showMenu}
          addNotes={addNotes}
          formFieldsList={formFieldsList}
          addChecklist={addChecklist}
          cardHeightCss={cardHeightCss}/>
        {selectedList.id == a.id && (
          <ProjectMenu
            isOpen={isMenuOpen}
            toggleModal={toggleMenuModal}
            item={selectedList}
            type="screening_list"
            MenuItems={menuItems}
          />
        )}
      </div>
  ))

  return (
    <Fragment>
    <div id="ct">
      <div className="app_content">
        
      {!props.id &&
        <div className="app-header flex justspacebetween m-t-15">
          <div className="flex coldir">
            <div className="app-title m-r-20">Screening Lists</div>
            <div className="font-14">{lists.length} Items</div>
          </div>

          <div className="flex aligncenter">
          <a data-tip="Back to Screening Events" className="bg-highlight round-btn float-right m-r-5 m-t-4" onClick={e=>{props.history.goBack();e.preventDefault();}}>
              <i className="fas fa-home"></i>
            </a>
            <NavIcon id="toggle_list_table" dataTip="Toggle Screening List Table" 
            onSelect={toggleEventTable}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fa fa-table"/>
          </div>
        </div>
      }
        
      <div className="app-content-section m-t-30">
        {!showListsTable && listCard}

        {showListsTable && <Fragment>
          <ScreeningListTable lists={lists} requestParams={requestParams}
            selectedList={selectedList}
            getScreeningList={getScreeningList} toggleDeleteModal={toggleDeleteModal} />
        </Fragment>
      }
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage}
          setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>
      </div>
      </div>

      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteActivity} />
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedList} type="screening_list" />}
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedList} type="screening_list"/>}
      {isFormFilterOpen && <FilterModal  dataSource={dataSource} isOpen={isFormFilterOpen} toggleModal={toggleFormFilterModal} />}
    </Fragment>
  )
}

export default ScreeningLists;