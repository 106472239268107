import React, { useState, useEffect, Fragment} from 'react'
import {CheckBox, OptionsObjList} from "../../../Common/FormInput"
import FacilityService from './FacilityService'
import Cs from "../../../../services/CommonService"

const FacilityLocationFilter = ({organizationId, selectedFacilities, setSelectedFacilities, setMemberFacilities, existsFacilities, isMedicalFacilityUnselected, toggleAllFacility, isAllFacilityChecked}) => {
  
  const [loading, setLoading] = useState(false)
  const [districts, setDistricts] = useState([])
  const [talukas, setTalukas] = useState([])
  const [villages, setVillages] = useState([])
  const [filteredFacilities, setfilteredFacilities] = useState([])
  const [filter, setFilter] = useState({})

  useEffect(()=>{
    getDistricts()
  }, [])

  const getDistricts = () =>{
    setLoading(true)
    FacilityService.getLocations({
      organization_id:organizationId,
      fields:['district'],
      is_distinct:true
    }).then((res)=>{
      setLoading(false)
      setDistricts(res.data.list)  
    }) 
  }

  const getTalukas = (name, value) =>{
    setLoading(true)
    setFilter({...filter, [name]:value})
    FacilityService.getLocations({
      organization_id:organizationId,
      fields:['taluka'],
      is_distinct:true,
      district:value
    }).then((res)=>{
      setLoading(false)
      setTalukas(res.data.list)  
    }) 
  }

  const getMedicalFacilities = (name, value) =>{
    setLoading(true)
    setFilter({...filter, [name]:value})
    FacilityService.getLocations({
      organization_id:organizationId,
      fields:['id', 'phc_name', 'sub_facilities'],
      district:filter.district,
      taluka:value
    }).then((res)=>{
      setLoading(false)
      setfilteredFacilities(res.data.list)  
    })  
  }

  const onFacilitySelect = (facility, name, medical_facility_id) => {
    //setSelectedFacilities([...selectedFacilities, facility])
    setMemberFacilities(facility, name, 'id', medical_facility_id)
  }

  return(
    <form id="facility_form" className="ml-container m-b-10">
      {loading ? 
        <div className="spinner"/>  
        :
        <>
          <label className="form-label m-b-5">
            All Facilities
          </label>
          <span className="checkbox-container ani-opc" 
            onClick={()=>toggleAllFacility('all_facility')}>
              <span>All Facility Access</span>
              <input type="checkbox" checked={isAllFacilityChecked} readOnly/>
              <span className="checkbox-checkmark"/>
          </span>

          {isAllFacilityChecked ?
            null
            :
            <>
              <label className="form-label m-b-5">
                Select all applicable geographic areas to cover
              </label>
              <select name="district" 
                onChange={({target})=>getTalukas(target.name, target.value)} 
                className="form-control ml-input ml-border m-b-20" 
                placeholder="District" defaultValue={filter.district}>
                <option>None</option>
                <OptionsObjList list={districts} id_key="district" label_key="district"/>
              </select>

              <select name="taluka" 
                onChange={({target})=>getMedicalFacilities(target.name, target.value)} 
                className="form-control ml-input ml-border m-b-20"
                placeholder="Taluka" defaultValue={filter.taluka}>
                <option>None</option>
                <OptionsObjList list={talukas} id_key="taluka" label_key="taluka"/>
              </select>

              <label className="form-label req-field m-b-5">
                Select all applicable Facilities in selected area
              </label>
              {filteredFacilities.map((f, i)=>
                <Fragment key={i}>
                  <span className="checkbox-container" onClick={()=>onFacilitySelect(f, 'medical_facility_id')}>
                    <span>{f.phc_name}</span>
                    <input type="checkbox" checked={existsFacilities(f, 'medical_facility_id', 'id')} readOnly/>
                    <span className="checkbox-checkmark"/>
                  </span> 

                  {isMedicalFacilityUnselected(f) ? f.sub_facilities?.map((sf, ci)=>
                    <span key={ci} className="checkbox-container m-l-15" onClick={()=>onFacilitySelect(sf, 'medical_sub_facility_id', f.id)}>
                      <span>{sf.name || sf.name_subfacility}</span>
                      <input type="checkbox" checked={existsFacilities(sf, 'medical_sub_facility_id', 'id')} readOnly/>
                      <span className="checkbox-checkmark"/>
                    </span>
                  ):null}
                </Fragment>  
              )}
            </>
          }
        </>
      }
    </form>
  )
}

export { FacilityLocationFilter };

/*<CheckBox list={filteredFacilities} toggle={onFacilitySelect} 
            exists={existsFacilities} 
            labelKey="phc_name" valueKey="id" name="medical_facility_ids"
            onToggleReturnObject={true}/>*/