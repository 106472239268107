import React ,{Fragment,useState} from "react"
import moment from '../../../../node_modules/moment/min/moment.min.js'
//import Skills from '../../../components/ServiceProvider/List/Skills'
import {Link} from "react-router-dom"
import Cs from '../../../services/CommonService'
import env from "../../../env"
import {FormFieldCard, RenderData} from "../../HealthJourney/UserRoles/NotificationPill"
import { ChatGrayIcon, MaximizeIcon, HamburgerMenuIcon } from "../../Common/ImageIcons.js"

const DigitalContentTable = (props) => {
  
  let {digitalContent, formFieldsList, Datasource, addMenu, addNotes} = props;
  let {data, data_source} = digitalContent;
  let formFields = formFieldsList[digitalContent.content_form_id] || []

  const [activeIndex, setActiveIndex] = useState()
  const [activeView, setActiveView] = useState()

  let digitalContentType = Datasource.menu_digital_content.options;
  const cardStyle = {}

  try {
    const subject = digitalContentType.filter(function (el) {
       return el.sid === digitalContent.content_type
    });
    cardStyle.borderLeft = "10px solid "+ subject[0].icon_color
    cardStyle.backgroundColor = "black"
  } catch (e) {
    cardStyle.borderLeft = "10px solid #607D8B"
    cardStyle.backgroundColor = "black"
  }

  const toggleView = (id) => {
    if(activeView == id){
      setActiveView(null)    
    }else{
      setActiveView(id)
    }
  }

  const RenderCard = ({label, details, id}) =>{
    return(
      <Fragment>
        <div className="step-activity" onClick={e=>toggleView(id)}>
          {label}
        </div>
        {activeView == id && 
          <div className="vcard-field p-5">
            <div dangerouslySetInnerHTML={{ __html: details }}  />
          </div>
        }
      </Fragment>
    )
  }

  const GenericContent = () =>(
    <div className="bg-white border-rounded-10 card-shadow m-b-15">
      <div className="card-title card-title-gray flex p-b-15 p20 coldir" style={cardStyle}> 
        <h4 className="fw700 f18 card-heading flex coldir text-left">
          {data.title || data.record_title || data.heading || data.faq || data.name} 
        </h4>
        <div className="f14 m-t-10 fw500">{digitalContent.user?.name}-{Cs.formatUpdateDate(digitalContent.created_at)}</div>
      </div> 

      <div className="card-content p20">
        <div className="b-tab-table active scroll-auto ht-400">
          <div className="border-dashed-sm p-b-15 m-b-15 m-t-15">
            <div className="flex justspacebetween aligncenter">
              <h5 className="fw500 m-b-5">
                Content Type: {digitalContent.content_type.replace(/_/g, ' ')}
              </h5>
            </div>
          </div>
          <FormFieldCard formFields={formFields} data={data} dataSource={data_source}/>
        </div>
      </div>
        
      <div className="card-footer flex justspacebetween p20 aligncenter">
        <ul className="flex aligncenter">
          <li className="m-r-25">{digitalContent.record_id}</li>
          <li className="m-r-25">
            <ChatGrayIcon onSelect={(e) => addNotes(e, digitalContent)}/>
          </li>
        </ul>
         <HamburgerMenuIcon onSelect={(e)=>addMenu(e, digitalContent)}/>
      </div>
    </div>
  )

  if(!data)return null;

  return (<GenericContent/>)
    
}

export default DigitalContentTable;