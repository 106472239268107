import React, { Fragment, useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import useStyle from '../../hooks/useStyle'
import CommonService from "../../services/CommonService"
import useEscape from '../../hooks/useEscape'
import CTLazyLoad from "../Common/CTLazyLoad"
import {exitAlertMsg} from "../../constants/DataSourceList"
const css = ['/styles/add_role_dialog.css', '/styles/filter_dialog.css']

const GenericModal = ({ component: Component, ...props }) => {
  /*useStyle('add_role_dialog');
  useStyle('filter_dialog');*/
  const modalRef = useRef()
  
  useEffect(()=>{
    const body = document.querySelector("body");
    body.style.overflow = "hidden";
    modalRef.current.scrollTop = 0;
    return ()=>{
      body.style.overflow = "auto";
    }
  }, [])

  const onClose = (e) =>{
    if(props.closeConfirmPopup){
      //popupLocale(4) || 
      const text = exitAlertMsg;
      if (window.confirm(text) == true) {
        props.toggleModal(e);
      }
    }else{
      props.toggleModal(e);
    }
  }

  useEscape(onClose)
  
  return(
    ReactDOM.createPortal(
      <div className="ml-modal" ref={modalRef}>
        <div className={`ml-modal-content ml-animate-zoom ${props.widthCss}`}>
          {props.title ?
            <div className={`flex justspacebetween modal-title ${props.HeaderColor ? props.HeaderColor:''}`}>
              <h4 className="f18 text-white fw500 p12-10-15">
                {props.title}
                {props.subtitle && <h5>{props.subtitle}</h5>}
              </h4>
              <CloseIcon onClose={onClose} closeIco={props.closeIco}/>
            </div>
            :
            <CloseIcon onClose={onClose} closeIco={props.closeIco} css="abs"/>
          }
          {Component ? <Component {...props} />:null}
        </div>
      </div>, document.body
    )
  )

}

const CloseIcon = ({onClose, closeIco, css}) =>{
  return closeIco?
    <button className={`btn-close ${closeIco} ${css}`} onClick={e=>onClose(e)}/>
    :
    <button className={`btn-close ${css}`} onClick={e=>onClose(e)}>⨉</button>
}

export default GenericModal;