import env from "../../../env"
import axios from "axios"
import BaseService from "../../../services/BaseService"

class WellnessService extends BaseService {

  report(req){
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}health/symptoms/reports`, req).then((res) => {
        // handle success
        resolve(res.data)
      }).catch((e) => {
        // handle error
        reject(e)
      })
    })
  }
  
}

export default new WellnessService('health_backend','health/symptoms')