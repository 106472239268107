import React, {Fragment} from "react";
import {Link} from "react-router-dom";

const Member = (props) => {
  let {member, rolesObj, member:{data} } = props;

  return (
    <Fragment>
      <div className="user-list-container user-list-left bg-white">
        <img src="images/avatars/2s.png" alt="img" className="shadow-small bg-blue2-dark" />
        <h4>{member.data.email}</h4>
        <div className="ht-100 scroll-y">
          <p>{member.data.message}</p>
          <p className="color-highlight">{rolesObj[member.role_record_id].role}</p>
        </div>

        <div>
          <div className="action-btn bg-highlight" onClick={e=>props.setEdit(e, member)}>Edit</div>
          <div className="action-btn bg-highlight" onClick={e=>props.setDelete(e, member)}>Delete</div>
        </div>
      </div>
    </Fragment>
  )

}

export default Member;