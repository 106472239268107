const devEmails = [
  //'info@cuetree.com', 
  'author@ipipal.com', 
  'skasi@ipipal.com', 
  'jaibharathi1991@gmail.com'
]

//Production
/*export default {
  health_backend: 'https://www.api.cuedwell.com/',
  backend: 'https://beta.cuetree.com/',
  infant_backend: 'https://www.api.cuedwell.com/infant/',
  socket:'https://www.api.cuedwell.com',
  youtube_url:'https://www.youtube.com/embed/',
  file_url:'https://www.api.cuedwell.com/health/files?url=',
  s3_public:'https://selftree-public.s3.us-west-2.amazonaws.com/uploads/health_file_upload/',
  profile_image_url:'https://cuedwell.s3.ap-south-1.amazonaws.com/uploads/user/profile_image/',
  admin_emails:devEmails
}*/

//Test
export default {
  health_backend_local: 'https://health.api.cuetree.com/',
  health_backend: 'https://health.api.cuetree.com/',
  backend: 'https://beta.cuetree.com/',
  infant_backend: 'https://health.api.cuetree.com/infant/',
  socket:'https://health.api.cuetree.com',
  youtube_url:'https://www.youtube.com/embed/',
  file_url:'https://health.api.cuetree.com/health/files?url=',
  s3_public:'https://selftree-public.s3.us-west-2.amazonaws.com/uploads/health_file_upload/',
  profile_image_url:'https://selftree-health.s3-us-west-2.amazonaws.com/uploads/user/profile_image/',
  admin_emails:devEmails
}

//Local
/*export default {
  health_backend:'http://127.0.0.1:9001/',
  backend:'https://beta.cuetree.com/',
  infant_backend:'http://127.0.0.1:9001/infant/',
  socket:'http://127.0.0.1:9001',
  file_url:'http://127.0.0.1:9001/health/files?url=',
  youtube_url:'https://www.youtube.com/embed/',
  profile_image_url:'https://cuedwell.s3.ap-south-1.amazonaws.com/uploads/user/profile_image/',
  s3_public:'https://selftree-public.s3.us-west-2.amazonaws.com/uploads/health_file_upload/',
  admin_emails:devEmails
}*/