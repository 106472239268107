import React, { Fragment, useState, useEffect, useContext } from 'react';
import ReactDOM from 'react-dom';
import {Link, useHistory} from "react-router-dom";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import useStyle from '../../../hooks/useStyle';

const FilterModal = ({ params, isOpen, toggleModal, filterType, onSelect, menuList}) => {
  useStyle("add_role_dialog");
  useStyle("filter_dialog");
  
  const history = useHistory();
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  
  let dataSourceId = menuList.menu_digital_content.id
  let activityTypes = menuList.menu_digital_content.options.splice(1)

  const handleClick = (e, o) =>{
    toggleModal(e)
    onSelect(o)
    //history.push(`/digital_contents/create/${o.sid}/${o.child_form}`)
  }

  const FormFilters = () =>{
    //let permissions = currentUser.current_admin.permissions || {}
    //let content_permission = permissions.role_content || {}
    return(
      <Fragment>
        <div className="popup-menu-grid">
          {activityTypes.map((o, k) =>
            <Fragment key={k}> 
              <a className="ver-icons" onClick={e=>handleClick(e, o)}>
                <span>
                  <i className={`${o.icon || "fas fa-plus"}`} style={{ color: o.icon_color }}/>
                </span>
                <div className="popup-menu-title">{o.label}</div>
                <i className="fas fa-chevron-right lgrey pull-right" />
              </a>
            </Fragment> 
          )}
        </div>
        <div className="row"><p className="font-9 p-5 lgrey lh-16">{dataSourceId}</p></div>
      </Fragment>  
    )
  }

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom border-rounded-10">
          
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Please select an item</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
          
          <div className="p-5 scroll-x">
            <FormFilters/>
          </div>
            
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default FilterModal;