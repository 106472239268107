import React, {useContext} from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import { Link } from "react-router-dom";
import {NotesBox} from "./NotesBox";
import GenericModal from "../../Modals/GenericModal";
import useModal from "../../../hooks/useModal";
import PortalSideMenu from "./PortalSideMenu";

const JourneyInlineMenu = (props) => {
	const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
	let {journey} = props
  	let {data} = journey
  	
	return(
	  <>	
		<Link  className="round-btn white m-l-15" to={`/health/form/${journey.form_id}/${journey.id}`}>
        	<i className="fas fa-edit" data-tip="Edit health journey form" />
      	</Link>
      </>
  )
}

const PortalToggleMenu = (props) => {
	const { isOpen: isSideNavOpen, toggleModal: toggleOpenSideNav } = useModal();

	if(props?.menuOptions?.options?.length == 0) return null;

	if(isSideNavOpen){
	  return(
		  <PortalSideMenu openSideNav={isSideNavOpen} setOpenSideNav={toggleOpenSideNav} 
        {...props}
      />
	  )
	}
  
  /*
	<span id="case_menu" onClick={(e) => toggleOpenSideNav(e)} className="pathbutton-page zIndex">
      <i className="fa fa-bars p-5" data-tip="Access Advanced Child Nutrition Journey Tools" aria-hidden="true"/>
    </span>
  */  

  return (
	  <span id="case_menu" onClick={(e) => toggleOpenSideNav(e)}  
	  	data-tip="Access Advanced Child Nutrition Journey Tools"
	  	className="icon-gray-cirle border-rounded-30 flex aligncenter justifycenter m-l-10">
	  		<i className="fas fa-bars"/>
	  </span>
  )
}

export {JourneyInlineMenu, PortalToggleMenu};