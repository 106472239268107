import env from '../../../../env';
import axios from 'axios';
import BaseService from '../../../../services/BaseService';

let url = env.infant_backend

class ChildService extends BaseService{

  report(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}reports/childs`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  checkMeasurement(req){
    return new Promise((resolve, reject) => {
      axios.post(`${url}measurements`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  uploadMeasurements(file, data){
    return new Promise((resolve, reject) => {
      let fd = new FormData()
      fd.append('file', file)
      if(data){
        for (const property in data) {
          if(data[property])
            fd.append(`${property}`, data[property])
        }
      }

      let reader = new FileReader()
      reader.readAsDataURL(file)  

      reader.onload = function(_file) {
        axios.post(`${url}measurements`, fd, {headers: {'Content-Type': undefined}}).then((res)=>{
          resolve(res)
        })
      }
    })
  }

}

export default new ChildService('infant_backend','childs', {post: true, put: false});