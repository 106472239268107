import env from '../env';
import axios from 'axios';

class AuthenticationService {
  
  signin(req) {
    let url = `${env.health_backend}users/sign_in`
    if(req.user.registration_mode === 'uid'){
      url = `${env.health_backend}users/auth_uid`
    }
    
    return new Promise((resolve, reject) => {
      axios.post(url, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        resolve(e.response);
      })
    })
  }

  signup(data) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}users/sign_up`, data).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        resolve(e.response);
      })
    })
  }

  forgotPassword(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}users/forgot_password`, user).then((res) => {
        // handle success
        console.log('forgot success',res)
        resolve(res);
      }).catch((e) => {
        // handle error
        console.log('forgot eroor',e)
        reject(e);
      })
    })
  }

  resetPassword(userData,userId,token) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}users/`+userId+`/password_reset/`+token+``, userData).then((res) => {
        // handle success
        console.log('reset success',res)
        resolve(res);
      }).catch((e) => {
        // handle error
        console.log('reset eroor',e)
        reject(e);
      })
    })
  }

  get(){
    return new Promise((resolve, reject) => {
      var u = env.health_backend+'user'
      axios.get(u).then(function(response){
       resolve( response.data.user );
      }).catch(function(response){
       reject(response)
      });
      
    })
  }

  signout(){
    return new Promise((resolve, reject) => {
      var u = env.health_backend+'users/sign_out'
      axios.delete(u).then(function(response){
        resolve( response.data.user );
      }).catch(function(response){
        reject(response)
      });
    })
  }


  updatePassword(user) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}users/update_password`, user).then((res) => {
        resolve(res);
      }).catch((e) => {
        resolve(e);
      })
    })
  }

  updateUserName(user){
    return new Promise((resolve, reject) => {
      axios.put(`${env.health_backend}user`, user).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    }) 
  }

  updateProfileImage(file){
    return new Promise((resolve, reject) => {
      let postImageUploadurl = env.health_backend+'user/profile_image';
      let fd = new FormData();
      fd.append('file', file);
      //fd.append('crop', JSON.stringify(file.crop));
      axios.post(postImageUploadurl, fd, {headers: {'Content-Type': undefined}}).then((res)=>{
          resolve(res);
      })
    }) 
  }

  inviteLinkVisited(id){
    return new Promise((resolve, reject) => {
      axios.put(`${env.health_backend}health/organization_members/${id}`, {
        id:id,
        is_link_visited:true
      }).then((res) => {
        resolve(res);
      })
    })
  }

  getLoginHistory(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.health_backend}health/login_historys`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        resolve(e.response);
      })
    })
  }

}

export default new AuthenticationService();