import React, {useState, Fragment, useContext, useEffect, useMemo} from 'react'
import ReactTooltip from 'react-tooltip'
import { AuthContext } from '../../contexts/Authentication/AuthStateProvider'
import { CacheContext } from "../../contexts/Cache/CacheStateProvider"
import Card from './Card'
import NutritionCareService from './NutritionCareService'
import Pagination from '../Pagination'
import ConfirmMenu from '../Modals/ConfirmMenu'
import FormTemplateId from '../../constants/FormTemplateList'
import CommentsModal from '../Modals/CommentsModal'
import ProjectMenu from '../Modals/ProjectMenu'
import {Link, useHistory} from 'react-router-dom'
import useModal from '../../hooks/useModal'
import NotesModal from '../Modals/NotesModal'
import MenuModal from '../Modals/Menu'
import ChecklistsModal from '../Modals/ChecklistsModal'
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../../components/Common/SortDirection'
import CommonService from '../../services/CommonService'
import NotifiableRolesPopup from '../NotifiableRoles/NotifiableRolesPopup'
import GenericModal from '../Modals/GenericModal'
import CheckAccess from '../HealthJourney/Roles/CheckAccess'
import env from '../../env'
import SideMenuBar from '../Common/SideMenuBar'
import PaginationSM from '../Common/PaginationSM'
import { CalenderBtn } from '../Common/Button'
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons"
import DataLogList from '../DataLogs/DataLogList'
import DataSourceMenu from "../Modals/DataSourceMenu"
import OrgDataMapperTable from '../OrgDataMapper/OrgDataMapperTable'
import {usePageTitle} from '../../hooks/pageHead'
import {PillList} from '../HealthJourney/UserRoles/NotificationPill'
import {FilterBtn} from './Filters'

let requestParams = {}
let selectedItem = {}
let dataSource = {}
let pageTitle = ''
const NutritionCareList = (props) => {
  usePageTitle('NutritionCare List')
  useStyle('/css/tile-components.css', true)

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const {cacheState, cacheDispatch} = useContext(CacheContext)
  const history = useHistory()
  const {isPopupMode} = props

  //const isMaster = CheckAccess.checkIsAdmin(currentUser) || env.admin_emails.includes(currentUser.email) 
  const {isTemplate, filterCacheKey} = useMemo(() => 
    (props.location.pathname === '/templates/nutrition_cares')?
    {isTemplate:true, filterCacheKey:'ipaTemplateFilters'}
    :
    {isTemplate:false, filterCacheKey:'ipaFilters'}
    , [props.location]
  )

  const permission = useMemo(() => 
    CheckAccess.getMenuPermission(currentUser, 'interventions'), [currentUser.id]
  )

  const [nutritionCareList, setNutritionCareList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [formFieldsList, setFormFieldsList] = useState({})
  const [showTableView, setShowTableView] = useState(true)
  const [filter, setFilter] = useState(cacheState?.[filterCacheKey] || {})
  const [loading, setLoading] = useState(false)

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isNotifiableRoleOpen, toggleModal:toggleNotifiableRoleModal } = useModal()
  const { isOpen:isDataLogOpen, toggleModal:toggleDataLogModal } = useModal()
  const { isOpen:isProtocalMenuOpen, toggleModal: toggleProtocalMenu } = useModal()
  const { isOpen: isOrgDataMapperOpen, toggleModal: toggleOrgDataMapper } = useModal()
  const { isOpen: isNutritionCareOpen, toggleModal: toggleNutritionCareModal } = useModal()

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'filter': filter,
      'page': currentpage,
      'paginate': 1,
      'per_page': 10,
      'sort_column': requestParams.sort_column || 'updated_at',//data_source.pregnancy_stage.order_list,data_source.child_dev_stage.order_list
      'sort_direction': requestParams.sort_direction || 'desc',
      'totalItems': 0,
      'search': props.searchString,
      'data_source_params': {
        data_source_id:[3613, 3419, 3644, 3887, 3643],
        order_by:'order_list'
      },
      'form_fields_params':{ids:[506, 563, 564, 565, 582]}
    }

    if(isTemplate){
      requestParams.is_template = true
      pageTitle = 'Intervention Protocols Templates'
    }else{
      pageTitle = 'Intervention Protocols'
    }

    getIPA()

    return () =>{
      cacheDispatch({
        type: "UPDATE",
        payload: {
          [filterCacheKey]:filter
        }
      })
      requestParams = {}
      selectedItem = {}
    }
  }, [currentUser.id, currentpage, filter, props.searchString]);

  let getIPA = () => { 
    if(permission.read){
      setLoading(true)
      NutritionCareService.getAll(requestParams).then((res)=>{
       if(res.status == 200){
         dataSource = res.data.data_source_list
         requestParams.totalItems = res.data.meta.total
         setPagemeta(res.data.meta)
         setNutritionCareList(res.data.nutrition_cares)
         setFormFieldsList(res.data.form_fields || {})
         setLoading(false)
        }
      })
    }
  }

  const {sort} = useSort(requestParams, getIPA)

  const deleteItem = (e) => {
    e.preventDefault()
    setLoading(true)
    NutritionCareService.delete(selectedItem.id).then((res) => {
      if (res.status === 204) {
        setNutritionCareList(nutritionCareList.filter(i => i.id !== selectedItem.id))
        setLoading(false)
      }
    })
    toggleDeleteModal(e)
  }

  const addDelete = (e, item) => {
    if(permission.write){
      selectedItem = item
      toggleDeleteModal(e)  
    }
  }

  const addMenu = (item) => {
    selectedItem = item
    toggleMenuModal()
  }

  const addComment = (item) => {
    selectedItem = item
    toggleCommentModal()
  }

  const addChecklist = (item) => {
    selectedItem = item
    toggleChecklistModal()
  }

  const addNotifiableRole = (e, item) => {
    e.preventDefault()
    if(permission.write){
      selectedItem = item
      toggleNotifiableRoleModal(e)  
    }
  }

  const switchTableView = (e) =>{
    setShowTableView(!showTableView)
  }

  const clone = (e, item) =>{
    e.preventDefault()
    if(permission.write){
      const req = {
        id: item.id,
        privacy_type: 'private'
      }
      NutritionCareService.clone(req).then(({status, data})=>{
        if(status === 201){
          history.push(`/nutrition_care/edit/${data.nutrition_care.id}`)
        }
      })
    }
  }

  const openLogs = (e, item) =>{
    selectedItem = item
    toggleDataLogModal(e)
  }

  const openOrgDataMapperTable = (e, item) =>{
    e.preventDefault()
    if(permission.write){
      selectedItem = item
      toggleOrgDataMapper()
    }
  }

  const routeProtocalForm = (datasource) =>{
    history.push(`/nutrition_care/create/${datasource.sid}/${datasource.ipa_child_form}?is_template=${isTemplate || false}`)
  }

  const openPopup = (item, index) =>{
    selectedItem = item
    selectedItem.index = index
    toggleNutritionCareModal()
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        {(isTemplate && permission?.write) &&
          <a href onClick={e=>openOrgDataMapperTable(e, item)}>
            <i className="fas fa-check-circle"/>
            <span className="font-13">Add to Organization</span>
            <i className="fa fa-angle-right"/>
          </a>
        }
        {!isTemplate && permission.write &&
          <a href onClick={e=>addNotifiableRole(e, item)}>
            <i className="far fa-envelope"/>
            <span className="font-13">Add alert recipients</span>
            <i className="fa fa-angle-right"/>
          </a>
        }
        {(isTemplate && item.is_template && permission?.write) &&
          <a href onClick={e=>clone(e, item)}>
            <i className="fas fa-copy"/>
            <span className="font-13">Clone and Edit</span>
            <i className="fa fa-angle-right"/>
          </a>
        }
        {permission?.write && 
          <>
            <Link to={`/nutrition_care/alert/${item.id}`}>
              <i className="far fa-envelope"/>
              <span className="font-13">Add Alert Message</span>
              <i className="fa fa-angle-right"/>
            </Link>
            <Link to={`/nutrition_care/edit/${item.id}`}>
              <i className="far fa-edit"/>
              <span className="font-13">Edit</span>
              <i className="fa fa-angle-right"/>
            </Link>
            {CheckAccess.hasDeleteAccess(currentUser, item) &&
              <Link to="/" onClick={(e) => {addDelete(e, item);toggleMenuModal(e);}}>
                <i className="far fa-trash-alt"/>
                <span className="font-13">Delete</span>
                <i className="fa fa-angle-right"/>
              </Link>
            }
            <a href onClick={e=>openLogs(e, item)}>
              <i className="fas fa-globe"/>
              <span className="font-13">Versions</span>
              <i className="fa fa-angle-right"/>
            </a>
          </>
        }
        <Link to="/" onClick={e=>{addComment(e, item);toggleMenuModal(e)}}>
          <i className="fa fa-notes-medical"></i>
          <span className="font-13">Comments</span>
          <i className="fa fa-angle-right"/>
        </Link>
      </Fragment>
    )
  }

  const NutritionCareCardList = () => nutritionCareList && nutritionCareList.map((item, i) => (
    <div className="col-md-4" key={i}>
      <NutritionCare nutritionCare={item} addMenu={addMenu} toggleModal={toggleDeleteModal} 
        formFieldsList={formFieldsList} addComment={addComment} dataSource={dataSource}
        addChecklist={addChecklist} addNotifiableRole={addNotifiableRole}
        index={i}/>
    </div>
  ))

  const NutritionCare = (props) =>(
    <>
      <Card {...props} permission={permission}/>
   
      {selectedItem.id === props.nutritionCare.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
          item={props.nutritionCare} addDelete={addDelete} 
          type="nutrition_care" MenuItems={menuItems}/>
      }
    </>
  )
  
  const NutritionCarePopup = (props) =>(
    <div id="ct">
      <NutritionCare {...props}/>  
    </div>
  )

  const tableData = nutritionCareList.map((o, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray" onSelect={()=>openPopup(o, k)}>
          {o.record_id}
        </TD>
        <TD className="fw500" onSelect={()=>openPopup(o, k)}>
          {o.data.title}
        </TD>
        <TD>
          {o.data_source?.child_dev_stage?.label}
        </TD>
        <TD>
          {o.nutrition_care_alerts?.map((a, i)=>
            <span className="badge-2" key={i}>{a.data_source.language?.label}</span>
          )}
        </TD>
        {permission.write ?
          <>
            {isTemplate ?
              <TD>
                <div className="flex aligncenter coldir">
                  <LinkFontAwesomeIcon 
                    css="fas fa-plus-circle white"
                    id="new_org" tooltip="Add Organization" 
                    onSelect={(e)=>openOrgDataMapperTable(e, o)}>
                  </LinkFontAwesomeIcon>
                  <div className="m-t-5">
                    <PillList style="badge-2" list={o.assigned_organizations} dataKey="organization_name"/>
                  </div>
                </div>
              </TD>
              :
              <TD>
                <div className="flex aligncenter coldir">
                  <LinkFontAwesomeIcon 
                    css="far fa-envelope white"
                    id="add_alert" tooltip="Add Alert Recipients" 
                    onSelect={(e)=>addNotifiableRole(e, o)}>
                  </LinkFontAwesomeIcon>
                  <div className="m-t-5">
                    {o.alert_recipients?.map((a, i)=>
                      <span className="badge-2" key={i}>{a.role || a.email_id}</span>
                    )}
                  </div>
                </div>
              </TD>
            }
          </>
          : 
          null
        }
        <TD>
          {o.author_name}
        </TD>
        <TD>
          {CommonService.formatUpdateDate(o.updated_at)}
        </TD>
        {permission.write ?
          <TD>
            <div className="flex aligncenter">
              <LinkFontAwesomeIcon id="edit_care" tooltip="Edit Nutrition care" link={`/nutrition_care/edit/${o.id}?`} css="fas fa-edit white">
              </LinkFontAwesomeIcon>

              {CheckAccess.hasDeleteAccess(currentUser, o) &&
                <LinkDeleteIcon id="delete_entry" tooltip="Delete entry" onSelect={(e)=>{addDelete(e, o);toggleMenuModal(e);}}>
                </LinkDeleteIcon>
              }
            </div>
          </TD>
          :
          null
        }
      </TR>
    )
  })
  
  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20">{pageTitle}</div>
              <div className="font-14">{requestParams.totalItems} Items</div>
            </div>

            {!isPopupMode ?
              <div className="flex aligncenter">
                {permission.write && 
                  <span data-tip="Create Protocol" 
                    onClick={()=>toggleProtocalMenu()}
                    className="bg-highlight round-btn float-right m-l-15 m-t-4">
                      <i className="fas fa-plus"/>
                  </span>
                }

                <span data-tip="Toggle Table View" 
                  className="bg-highlight round-btn float-right m-l-15 m-t-4" 
                  onClick={e=>switchTableView(e)}>
                  <i className="fas fa-table"/>
                </span>
                
                {permission.write &&
                  <FilterBtn filter={filter} setFilter={setFilter} 
                    dataSource={dataSource} requestParams={requestParams}
                    currentUser={currentUser} getIPA={getIPA} isTemplate={isTemplate}/>
                }
              </div>
              :null
            }
          </div>  
      
      <div className="app-content-section m-t-30">
        {loading ? <div className="spinner"/>
          :
          <>
            {!showTableView && 
              <>
                <div className="row">
                  <NutritionCareCardList/>
                </div>
              </>
            }        
            {showTableView &&
              <div className="table-fix-head">
                <Table className="shadow-small">
                  <THead>
                    <TR>
                      <TH onClick={()=>sort('record_id')}>
                        ID 
                        <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                      </TH>
                      <TH onClick={()=>sort('data.title')} >
                        Title 
                        <SortDirection sort_column={requestParams.sort_column} column="data.title" reverse={requestParams.reverse}/>
                      </TH>
                      <TH onClick={()=>sort('data_source.child_dev_stage.value')} >
                        Dev Stage 
                        <SortDirection sort_column={requestParams.sort_column} column="data_source.child_dev_stage.value" reverse={requestParams.reverse}/>
                      </TH>
                      <TH>
                        Language
                      </TH>
                      {permission.write ?
                        <>
                          {isTemplate ?
                            <TH>
                              Orgs
                            </TH>
                            :
                            <TH>
                              Alert Recipients
                            </TH>
                          }
                        </>
                        :
                        null
                      }
                      <TH onClick={()=>sort('updated_by')} >
                        User 
                        <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
                      </TH>
                      <TH onClick={()=>sort('updated_at')} >
                        Date
                        <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
                      </TH>
                      {permission.write ?
                        <TH>
                          Action
                        </TH>
                        :
                        null
                      }
                    </TR>
                  </THead>
                  <TBody>
                    {tableData}
                  </TBody>
                </Table>
              </div>
            }
          </>
        }
        <PaginationSM
          className="pagination-bar"
          currentPage={currentpage}
          totalItems={pagemeta.total}
          itemsPerPage={pagemeta.per_page}
          onPageChange={setCurrentpage}
          siblingCount={2}
        />
      </div>
      </div>
      </div>

      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteItem}/>
      
      {isNutritionCareOpen && 
        <GenericModal component={NutritionCarePopup} nutritionCare={selectedItem} 
          addMenu={addMenu} toggleModal={toggleNutritionCareModal} 
          formFieldsList={formFieldsList} addComment={addComment} 
          dataSource={dataSource} addChecklist={addChecklist} 
          addNotifiableRole={addNotifiableRole}
          index={selectedItem.index}/>
      }

      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
          toggleModal={toggleCommentModal} item={selectedItem} 
          type="nutrition_care"/>
      }
      
      {isChecklistOpen && 
        <ChecklistsModal isOpen={isChecklistOpen} 
          toggleModal={toggleChecklistModal} item={selectedItem} 
          type="nutrition_care"/>
      }
      
      {isNotifiableRoleOpen && 
        <GenericModal isOpen={isNotifiableRoleOpen} 
          toggleModal={toggleNotifiableRoleModal} 
          title="Pick applicable roles to receive notification" 
          component={NotifiableRolesPopup} 
          notifiableType="nutrition_care"
          notifiableId={selectedItem.id}/>
      }

      {isDataLogOpen && 
        <GenericModal component={DataLogList} title="Versions" widthCss="w-100p"
          isOpen={isDataLogOpen} toggleModal={toggleDataLogModal}
          logType="nutrition_cares" logItem={selectedItem}
          formFields={formFieldsList[selectedItem.form_id]}/>
      }

      {isProtocalMenuOpen && 
        <DataSourceMenu toggleModal={toggleProtocalMenu} 
          dataSourceId={dataSource.journey_ipa_types?.id}
          datasourceList={dataSource.journey_ipa_types?.options} 
          onSelect={routeProtocalForm}/>
      }

      {isOrgDataMapperOpen &&
        <GenericModal component={OrgDataMapperTable} 
          title={selectedItem.data?.title}
          isOpen={isOrgDataMapperOpen} toggleModal={toggleOrgDataMapper}
          itemType="nutrition_care" itemId={selectedItem.id}/>
      }

      <SideMenuBar pageIds={['nutrition_cares']}/>
    </Fragment>
  )

}

export default NutritionCareList;