import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../PreLoadSpinner";
import DynamicForm from '../../FormBuilder/DynamicForm';
import FormContainer from '../../FormBuilder/FormContainer';
import FormTemplateId from '../../../constants/FormTemplateList'
import ForumService from "../ForumService";

let formMode = null; 
let formId = null; 
let forum = {}; 
let data = {}; 
let formFn = {};  

function ForumForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  console.log('props forum',props)
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  console.log('params forum',params)

  let currentUser = authState.user;

  /*TODO: Move API to Router to load data before view*/
 
  const get = () => {
    console.log('params forum get',params)
    ForumService.get(params.id).then((res) => {
      if(res.status==200){
        forum = res.data.discussion_request;
        data = forum.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       forum = null;
    })
  }
  
  useEffect(() => { 
    forum = {}; 
    data = {}; 
    formFn = {}; 
    formMode = 'create-form-submissions';
    if(params.id){
      formMode = 'edit-form-submissions';
      get()
    }else if(!isFormRendered){
      setFormRendered(true);
    }
   
  }, [params.id]);

  const create = (forum) => {
    forum.discussion_request_id = currentUser.id;
    ForumService.create(forum).then((res) => {
      console.log('forums form ',res)
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (forum) => {
    console.log('update forum')
    forum.discussion_request_id = currentUser.id;
    ForumService.update(forum).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    }).catch((res)=> {});
  }


  const onSubmitPageRedirect = () => {
    props.history.push("/forums")
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.HealthForumNewDiscussion} form={forum} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer>
    );  
  }else{
    return('')
  }
  
}

export default ForumForm;