import React, {useContext, useEffect} from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CommonService from "../../../../../services/CommonService";
import StepActivities from "../../StepActivities/StepActivities";
import env from "../../../../../env"
//import Tracking from "../../../../Common/UserActivityTracking" 

const PlannerReel = (props) => {
  const { state: authState, dispatch } = useContext(AuthContext)

  let activity = props.activity;
  activity.updated_at = CommonService.formatUpdateDate(activity.updated_at)
  let data = activity.data;
  let dataSource = activity.data_source;

  const cardStyle = {backgroundColor:data.background_color || data.header_background || "#607D8B"}
  const imgStyle = {height: '200px', width: '100%'}
  
  if (!data) return null;

  if(data.reference_url){
    const params = new URL(data.reference_url).searchParams;
    data.img_url = params.get('v')
  }

  const Footer = () => {
    return(
    <div className="card-footer">
      <div className="pull-left">
        <span className="m-l-25" onClick={(e) => props.addNotes(e, activity)}>
          <i className="far fa-comment font-18 text-muted" data-tip="Post/View messages"></i>
          <span className="badge-2 up bg-lgrey">{activity.comments_count || 0}</span>
        </span>
        <span className="m-l-25" onClick={(e) => props.addChecklist(e, activity)}>
          <i className="far fa-check-circle font-18 text-muted" data-tip="Post/View checklist items"></i>
          <span className="badge-2 up bg-lgrey">{activity.check_list_count || 0}</span>
        </span>
        <span className="m-l-25" onClick={(e) => props.addBookMark(e, activity)}>
          <i className="far fa-bookmark font-18 text-muted" data-tip="Save video bookmarks"></i>
          <span className="badge-2 up bg-lgrey">{activity.planner_step_count || 0}</span>
        </span>
        {env.admin_emails.includes(props.currentUser.email) && 
          <span className="m-l-25" onClick={(e) => props.openPlannerMapper(activity)}>
            <i className="fas fa-hands-helping font-18 text-muted" data-tip="Add Data Source Mapper"></i>
          </span>
        }
      </div>
      <div className="pull-right">
        <props.menu activity={activity} />
      </div>
    </div>
  )}

  const Card = () => {
    return (
      <div className="card-container bg-white shadow-small m-r-15">
        <div className="card-header p-5 br-btm-grey black w-350">
          <div className="font-16 m-t-10 ellipsis" data-tip={data.name}>
            {data.name} 
          </div>
        </div>
        <div className={`${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
            {data.reference_url && 
              <tr>
                <td>
                  <div className="ht-200 m-auto d-flex">
                    <a target="_blank" href={data.reference_url} className="text-center">
                      <img className="img-responsive w-350 ht-200" src={`https://img.youtube.com/vi/${data.img_url}/maxresdefault.jpg`}/>
                    </a>
                  </div>
                  <div>{dataSource?.language?.label}</div>
                </td>
              </tr>}
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    )
  }

    return <Card />;
}

export default PlannerReel;