import React, { useState, Fragment, useContext, useEffect} from 'react'
import ReactDOM from 'react-dom'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import ReactTooltip from "react-tooltip"
import {OptionsObjList, InputTags, Radio} from "../../../Common/FormInput"
import StepAssessmentService from "../StepAssessmentService"
import {Link, useParams} from "react-router-dom"
import FormHelper from '../../../FormBuilder/FormHelpers'
import RichText from '../../../Common/RichText'
import SummerNoteEditor from '../../../Common/SummerNoteEditor'

let searchKeyword = null
let timer = null
let validateAnswer = null

const AddQuestion = ({difficultyLevel, toggleModal, questionObj, assessment, assessmentList, setAssessmentList, typeOfQuestion, course}) => {
  
  questionObj.options = questionObj.options || []

  const { state:{user:currentUser}, dispatch } = useContext(AuthContext)
  let [question, setQuestion] = useState(questionObj || {options:[]})
  const [loading, setLoading] = useState(question.id?true:false)
  const [error, setError] = useState({})
  const params = useParams()

  const questionChoice = [
    {label:'New', value:'new'},
    {label:'Existing', value:"existing"}
  ]
  
  const checkboxEvaluationChoice = [
    {label:'Partial Credit', value:'partial_credit'},
    {label:'Full/No-Credit', value:"full_credit"}
  ]

  useEffect(() => {
    assessment.step_assessments = assessment.step_assessments || []
    if(question.id){
      setLoading(true)
      StepAssessmentService.get(question.id).then((res)=>{
        if(res.status == 200){
          setQuestion({...res.data.step_assessment})
          setLoading(false)
        }
      })  
    }
  }, [])

  useEffect(() => {
    FormHelper.validate('ct_form', question, error, setError)

    customValidation()

    return ()=>{
      validateAnswer = null
    }
  }, [question, loading])

  const handleTextAreaChange = (html, name) =>{
    question[name] = html
    setQuestion({
      ...question
    })
  }

  const handleInputChange = (event) => {
    question[event.target.name] = event.target.value
    setQuestion({
      ...question
    })
  }

  const onTagChange = (list, name) => {
    question[name] = list
    setQuestion({...question})   
  }

  const submitQuestion = (e)=>{
    setLoading(true)
    if(question.id){
      StepAssessmentService.update(question).then((res)=>{
        setLoading(false)
        toggleModal(e)
      })
    }else{
      question.assessment_id = assessment.id
      StepAssessmentService.create(question).then((res)=>{
        setLoading(false)
        toggleModal(e)
        res.data.step_assessment.created_user = {name:currentUser.name}
        assessment.step_assessments.unshift(res.data.step_assessment)
        setAssessmentList([...assessmentList])
      })
    }
  }

  const deleteOption = (o) =>{
    StepAssessmentService.deleteOption({id:o.id})
  }

  const updateOption = (option) =>{
    if(option.id){
      let req = {id: option.id, is_answer: !option.is_answer}
      StepAssessmentService.updateOption(req).then((res)=>{
        if(res.status == 204){  
          option.is_answer = !option.is_answer
          setAssessmentList([...assessmentList])                                         
        }
      })
    }else{
      option.is_answer = !option.is_answer
      setAssessmentList([...assessmentList])
    }
    customValidation()
  }

  const OptionAnswer = ({option}) =>{
    return(
      <span onClick={e => updateOption(option)} className={'m-r-5 font-16 '+(option.is_answer?'far fa-check-circle':'far fa-circle')}></span>  
    )
  }

  const customValidation = () =>{
    if('checkbox radio'.includes(question.input_type)){
      validateAnswer = (question.options && question.options.find(o => o.is_answer == true))
    }else{
      validateAnswer = true
    }
  }

  const selectQuestion = (q) =>{
    questionObj = {
      question:q.question, 
      input_type:q.input_type, 
      point:q.point, 
      parent_step_assessment_id:q.id,
      question_choice: question.question_choice
    }
    
    setQuestion({...questionObj})
    
    if('checkbox radio'.includes(q.input_type)){
      StepAssessmentService.getOptions({step_assessment_id: q.id}).then((res)=>{
        if(res.status == 200){
          res.data.question_options.forEach((o, i) => {
            delete o.id
            o.assessment_id = assessment.id
          })

          setQuestion({...questionObj, options:res.data.question_options})  
        } 
      })  
    }
  }

  return(
    <Fragment>
      {loading ? <div className="spinner"></div> : <form id="ct_form" className="ml-container">
      <label className="pull-right clear"><span className="lred font-16">* </span>Required field</label>
        <div className="ml-section">
          {!question.id && 
            <Fragment>
              <label className="req-field"><b>Pick Question</b></label>
              <select onChange={e=>handleInputChange(e)} name="question_choice" value={question.question_choice} className="form-control ml-margin-bottom font-14" required>
                <option value="" disabled selected>Select an item from list below</option>
                <OptionsObjList list={questionChoice} id_key="value" label_key="label"/>
              </select>
            </Fragment>
          }
            
          <FormField question={question} handleTextAreaChange={handleTextAreaChange}
          handleInputChange={handleInputChange} difficultyLevel={difficultyLevel}
          onTagChange={onTagChange} typeOfQuestion={typeOfQuestion}
          assessment={assessment} selectQuestion={selectQuestion}
          deleteOption={deleteOption} updateOption={updateOption} 
          OptionAnswer={OptionAnswer} checkboxEvaluationChoice={checkboxEvaluationChoice}/>
          
          {error.is_valid && validateAnswer &&  
            <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>submitQuestion(e)}>
              <b>{question.id?'Update':'Add'}</b>
            </div>
          }

        </div>
      </form> }
    </Fragment>
  )

};

const QuestionSearch = ({assessment, selectQuestion}) =>{

  const [stepAssessments, setAssessmentList] = useState([])

  const searchQuestion = (e) => {
    clearTimeout(timer)
    searchKeyword = e.target.value
    timer = setTimeout(triggerChange, 1000)
  }

  const triggerChange = () => {
    let req = {
      page:1,
      per_page:100,
      keywords:searchKeyword,
      include_all_journey:true
    }
    StepAssessmentService.getAll(req).then((res)=>{
      if(res.status == 200){
        setAssessmentList(res.data.step_assessments)
      }
    })
  }

  const searchListView = stepAssessments.map((s, k) =>  
    <span className="pill bg-green white m-r-5" key={k} onClick={e=>selectQuestion(s)}>
      <span dangerouslySetInnerHTML={{ __html: s.question }}></span>
    </span>
  )

  return(
    <Fragment>
      <label className="req-field"><b>Search Question</b></label>
      <input onChange={e=>searchQuestion(e)} name="search"
        className="form-control ml-margin-bottom"  placeholder="Search"/>
      {searchListView}  
    </Fragment>
  )
}

const FormField = ({difficultyLevel, question, handleTextAreaChange, handleInputChange, onTagChange, typeOfQuestion, assessment, selectQuestion, deleteOption, updateOption, OptionAnswer, checkboxEvaluationChoice}) => {
  return(
    <Fragment>
      {question.question_choice == 'existing'? <QuestionSearch assessment={assessment} selectQuestion={selectQuestion}/>: <Fragment>
        <label className="req-field"><b>Add Question Title</b></label>
        <SummerNoteEditor id="question" required="true" formData={question} 
        onContentChange={handleTextAreaChange} content={question.question} 
        dataType="question" dataId={question.id}/>       
        </Fragment>
      }

      <label><b>Add Question Instructions (including images) if any</b></label>
      <SummerNoteEditor id="description" formData={question} 
      onContentChange={handleTextAreaChange} content={question.description} 
      dataType="description" dataId={question.id}/>       

      <label className="req-field"><b>Specify type of response to question</b></label>
      <div className="fstElement fstMultipleMode m-t-5 ml-margin-bottom">
        <select onChange={e=>handleInputChange(e)} name="input_type" value={question.input_type} className="form-control font-14" required>
          <option value="" disabled selected>Select an item from list below</option>
          <OptionsObjList list={typeOfQuestion} id_key="sid" label_key="label"/>
        </select>
      </div>

      <label className="req-field"><b>Points Assigned to this Question</b></label>
      <input type="number" onChange={e=>handleInputChange(e)} name="point"
        className="form-control ml-margin-bottom"  placeholder="Points" 
        defaultValue={question.point} required/>
        
      {'checkbox radio'.includes(question.input_type) && <Fragment>          
        <label className="req-field"><b>Add Choices for Multiple Type Questions</b></label>
        <InputTags tagList={question.options} name="options" onDelete={deleteOption} 
          updateOption={updateOption} onTagChange={onTagChange} TagCheckBox={OptionAnswer} 
          isObject="true" required="true"/>
        </Fragment>
      }

      {question.input_type == 'checkbox' && <Fragment>
        <label className="req-field m-t-10"><b>Evaluation Choice</b></label>
        <select onChange={e=>handleInputChange(e)} name="evaluation_choice" value={question.evaluation_choice} className="form-control ml-margin-bottom font-14" required>
          <option value="" disabled selected>Select an item from list below</option>
          <OptionsObjList list={checkboxEvaluationChoice} id_key="value" label_key="label"/>
        </select>
      </Fragment>}

      {'textArea richtext textInput'.includes(question.input_type) && 
        <Fragment> 
          <label className="req-field m-t-10"><b>Answer Text</b></label>
          <SummerNoteEditor required="true" id="text_answer" formData={question} 
          onContentChange={handleTextAreaChange} content={question.text_answer} 
          dataType="text_answer" dataId={question.id}/>       
        </Fragment>
      }

      <label className="m-t-10"><b>Primary topic covered by this item</b></label>
      <InputTags tagList={question.topics} name="topics" onTagChange={onTagChange} />

      <label className="m-t-10"><b>Skills covered by this item</b></label>
      <InputTags tagList={question.skills} name="skills" onTagChange={onTagChange} />

      <label className="req-field"><b>Difficulty level of item</b></label>
      <div className="fstElement fstMultipleMode m-t-5 ml-margin-bottom">
        <select onChange={e=>handleInputChange(e)} name="difficulty_level" 
          value={question.difficulty_level} className="form-control font-14" required>
          <option value="" disabled selected>Select an item from list below</option>
          <OptionsObjList list={difficultyLevel?.options} id_key="sid" label_key="label"/>
        </select>
      </div>
    </Fragment>
  )
}

export default AddQuestion;