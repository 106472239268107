import React, { useState, Fragment, useEffect, useContext, useRef } from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PlannerReel from "./PlannerReel";
import PlannerService from "../PlannerService";
import Pagination from "../../../../Pagination";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import CommentsModal from "../../../../Modals/CommentsModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import CardMenu from "../../../../Modals/CardMenu";
import { Link } from "react-router-dom";
import useModal from "../../../../../hooks/useModal";
import useStyle from "../../../../../hooks/useStyle";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import Title from "../../../GenericPortalPage/Title";
import GenericModal from "../../../../Modals/GenericModal";
import {SwiperContainer, SwiperSlide} from "../../../../Common/Swiper"
import CommonService from "../../../../../services/CommonService";
import PlannerSteps from "./PlannerSteps"
//import MapperDataSourceListModal from "../../../../FormBuilder/MapperDataSourceListModal"
import env from "../../../../../env"

let requestParams = {}
let selectedPlanner = {}
let dataSource = {}
let journeyCategory = null
let cardViewCount = null

const PlannerReelList = (props) => {
  useStyle("card")
  useStyle("card_table")
  useStyle("add_role_dialog")
  
  let params = props.match.params;
  let {project, child} = props.location.state || {};
  let queryParam = querystringify.parse(props.location.search);

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [plannerList, setPlannerList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState({})
  const [loading, setLoading] = useState(true); 

  const { isOpen:isBookMarkOpen, toggleModal:toggleBookMarkModal } = useModal();
  const {isOpen:isChecklistOpen, toggleModal:toggleChecklistModal} = useModal()
  const {isOpen:isCommentOpen, toggleModal:toggleCommentModal} = useModal()
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal} = useModal()
  const {isOpen: isMenuOpen, toggleModal: toggleMenuModal} = useModal()
  const {isOpen: isPlannerMapperOpen, toggleModal: togglePlannerMapper} = useModal()
  const indexEle = useRef(null)

  /* Recommended,Trending,Recent*/
  const [recomList, setRecomList] = useState([]);
  const [trendList, setTrendList] = useState([]);
  const [recentList, setRecentList] = useState([]);


  let cardGridCss = "pull-left";
  let cardHeightCss = "";
  if (queryParam.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-8";
    cardHeightCss = "mih-380";
  }

  useEffect(() => {
    journeyCategory = null;
    requestParams = {
      page: currentpage,
      per_page: 40,
      search: props.searchString,
      journey_profile_id: params.journey_profile_id
    }

    getJourneyPlannerList()
  }, [props.searchString, currentpage]);

  let getJourneyPlannerList = () => {
    setLoading(true)
    PlannerService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        dataSource = res.data.dataSource_list;
        requestParams.totalItems = res.data.meta.total;
        cardViewCount = CommonService.getCardCount(screen) 
        requestParams.totalPages = Math.ceil(requestParams.totalItems / cardViewCount)

        if(requestParams.search){
          setPlannerList(res.data.planners);
        }else if(queryParam.id){
          cardViewCount = 1
          setPlannerList(res.data.planners);
        }else{
          setPlannerList(res.data.planners);
          setRecomList(res.data.planners)
          setTrendList([])
          setRecentList(res.data.recent_planner)
          setPlannerList([...plannerList, ...res.data.planners]);  
        }
        
        setLoading(false)
      }
    })
  }

  const deletePlanner = (e) => {
    e.preventDefault()
    PlannerService.delete(selectedPlanner.id).then((res) => {
      if (res.status == 204) {
        setPlannerList(
          plannerList.filter((i) => i.id !== selectedPlanner.id)
        )
      }
    })
    toggleDeleteModal(e);
  }

  const showMenu = (e, a) => {
    selectedPlanner = a;
    toggleMenuModal(e);
  }

  const addChecklist = (e, a) => {
    selectedPlanner = a;
    toggleChecklistModal(e);
  }

  const addNotes = (e, a) => {
    selectedPlanner = a;
    toggleCommentModal(e);
  }

  const addBookMark =(e, a) =>{
    //setActiveIndex(i==activeIndex?null:i)
    selectedPlanner = a
    toggleBookMarkModal(e)
  }

  const routePlanner = (activity) => {
    props.history.push(
      `/team/activity/${params.journeyCategory_id}/${params.education_journey_profile_id}?id=${activity.id}`
    )
    
    setPlannerList(
      plannerList.filter((i) => i.id == activity.id)
    )
  }

  const openPlannerMapper = (planner) =>{
    selectedPlanner = planner
    togglePlannerMapper()
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        <Link>
          <span className="font-13"> Updated at {item.updated_at}</span>
        </Link>
        <Link>
          <span className="font-13">{item.planner_form_id} - {item.record_id}</span>
        </Link>
        {env.admin_emails.includes(currentUser.email) && <Fragment>
          <Link to={`/health/planner/form/${item.id}`}>
            <i className="far fa-edit font-15" aria-hidden="true"></i>
            <span className="font-13">Edit</span>
            <i className="fa fa-angle-right"></i>
          </Link>
          <a href="#" onClick={(e) => toggleDeleteModal(e)}>
            <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
            <span className="font-13">Delete</span>
            <i className="fa fa-angle-right"></i>
          </a>
          </Fragment>
        }  
      </Fragment>
    )
  }

  const Menu = ({ activity }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, activity)} className="fas fa-bars m-r-15 font-16"/>
  )

  /*if(plannerList.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  const JourneyPlannerCard = ({planner}) => planner && planner.map((a, i) => (
    <div className={cardGridCss} key={i}>
      <PlannerReel activity={a} dataSource={dataSource} menu={Menu}
        addNotes={addNotes} addChecklist={addChecklist} addBookMark={addBookMark}
        journeyCategory={journeyCategory} cardHeightCss={cardHeightCss}
        routePlanner={routePlanner} openPlannerMapper={openPlannerMapper} 
        currentUser={currentUser} journeyProfileId={params.journey_profile_id}/>
        {selectedPlanner.id == a.id && (
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
            item={selectedPlanner}  type="activity"  MenuItems={menuItems}/>
        )}
    </div>
  ))

  if(loading){
    return(
      <div className="spinner"/>
    )
  }

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-4">
          <Title title={"Guide Reel"} project={project} />
        </div>
        <div className="col-xs-4 text-center font-14 m-t-15">{requestParams.totalItems} Items</div>
        <div className="col-xs-4">
          {project && <Fragment>
            <Link to={`/infant/portal/${params.journey_profile_id}`}
              data-tip="Back to Journey Home"
              className="bg-highlight round-btn float-right m-r-5 m-t-4">
              <i className="fas fa-home"></i>
            </Link>
          </Fragment>}
        </div>
      </div>

      <div className="page-content row">
        <div className="p-5 m-5 col-xs-12">
          <span className="font-20" style={{borderBottom: '3px solid #00A2FF'}}>Recommended List</span>
        </div>
        <div className="recomList br-btm-grey" style={{width:'100%',display:'flex',whiteSpace:'nowrap',overflowX:'scroll'}}>
          <JourneyPlannerCard planner={recomList}/>
        </div>

        <div className="p-5 m-5 col-xs-12">
          <span className="font-20" style={{borderBottom: '3px solid #00A2FF'}}>Recent List</span>
        </div>
        <div className="recentList br-btm-grey" style={{width:'100%',display:'flex',whiteSpace:'nowrap',overflowX:'scroll'}}>
          <JourneyPlannerCard planner={recentList}/>
        </div>
       
        <div className="p-5 m-5 col-xs-12">
          <span className="font-20" style={{borderBottom: '3px solid #00A2FF'}}>Trending List</span>
        </div>
        <div className="trendList br-btm-grey" style={{width:'100%',display:'flex',whiteSpace:'nowrap',overflowX:'scroll'}}>
          <JourneyPlannerCard planner={trendList}/>
        </div>
      </div>

      {isBookMarkOpen && <GenericModal title="Video Bookmarks" component={PlannerSteps} toggleModal={toggleBookMarkModal} selectedPlanner={selectedPlanner}/>}
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deletePlanner} />
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedPlanner} type="planner" />}
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedPlanner} type="planner"/>}
    </Fragment>
  )
}

export default PlannerReelList;
/*
  {isPlannerMapperOpen && <MapperDataSourceListModal isOpen={isPlannerMapperOpen} toggleModal={togglePlannerMapper} item={selectedPlanner} type="planner"/>}

*/