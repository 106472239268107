import React, { Fragment, useContext, useEffect} from 'react'
import {Link} from "react-router-dom"
import {FormTitle, FormDescription} from "./FieldLabel"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {usePageTour, setPageTour} from "../Common/usePageTour"
import useStyle from '../../hooks/useStyle'
import {usePageLocale} from '../../hooks/useLocale'
import { LocalizeContext } from "../../contexts/Localize/LocalizeStateProvider"
import {FormRuleAlertIcon} from "./FormRuleAlert"
import {HelpIcon} from "../Help/HelpSidePanel"

const FormContainer = (props) => {
  useStyle('/css/view-details-form.css', true)

  let {post, formId, showFormUpdateLink} = props	
  const tour = usePageTour() 
  
  const { state:{user:currentUser} } = useContext(AuthContext)
  const {labelLocale} = usePageLocale(currentUser?.current_locale, 'formLocale')

  try{
    formId = props?.children[0]?.props?.formId
  }catch(e){
    console.log(e.message)
  }

  const openPageTour = () =>{
    try{
      /*template.template_fields[activeKey].child_template_fields.forEach((f)=>{
        setPageTour(
          `form_${template.id}`,
          {id:f.client_id, title:f.label, description:f.label}
        )   
      })*/
      tour && tour.init(true, 'form_tour')
    }catch(e){
      console.log(e.message)
    }
  }

  return (
    <section id="ct">
      <div className={props.isPopupMode ? 'form p-0 m-t-0' : 'form app_content m-t-30 m-t-20-xs'}>
        <div className="flex justspacebetween modal-title">
          <h4 className="f18 f16-xss text-white fw500 p20 p15-xs p10-xss">
            <FormTitle title={post.title} locale={post.locale_title} 
            currentLocale={currentUser.current_locale}/> 
          </h4>
        </div>
        <div className="tab-details">
          <div className="flex justspacebetween aligncenter coldir-xs alignstart-xs form-details">
            <p>
              <FormDescription details={post.details} locale={post.locale_details} 
                currentLocale={currentUser.current_locale}/> 
            </p>
            <p>
              <span className="text-red m-r-5">*</span>
              {labelLocale(1)}
              <HelpIcon recordIds={post.help_id}/>
            </p>
          </div>
          <FormRuleAlertIcon currentUser={currentUser} formId={formId}/>
        </div>
        {props.children}
      </div>
    </section>
  )
}

export default FormContainer;

/*
<div className="page-content row">
      <div className={`portlet ${props.isPopupMode && 'p-0 m-t-0'}`} >
        <div id="f_title" className="portlet-heading bg-black color-white">
          <h4 className="portlet-title text-uppercase">
            <FormTitle title={post.title} locale={post.locale_title} 
              currentLocale={currentUser.current_locale}/> 
          </h4>
          <div className="clearfix"/>
        </div>
        <div id="portlet1" className="panel-collapse collapse in">
          <div className="portlet-body p-t-0">
            <div className="row p-7">
              {showFormUpdateLink && 
                <Link className="font-15 pull-right" to={`/data_source_list/form/${formId}`}>
                  <u>Suggest updates to the forms</u>
                </Link>
              }

              <FormDescription details={post.details} locale={post.locale_details} 
                currentLocale={currentUser.current_locale}/> 

              <label className="pull-right clear">
                <span className="fas fa-chalkboard tour-btn" onClick={()=>openPageTour()}/>
                <span className="lred font-16 m-l-15" id="f_required">*</span>
                {labelLocale(1)}
              </label>
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
*/