import BaseService from "../../../services/BaseService";
import env from '../../../env';
import axios from 'axios';
let url = env.health_backend+'training/'

class AssessmentService extends BaseService{
  
  getUserAssessments(req){
    return new Promise((resolve, reject) => {
      axios.get(url+'user/assessments', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  getEmailNotificationList(id, req){
    return new Promise((resolve, reject) => {
      axios.get(url+'email_notification/assessments/'+id, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  clone(req){
    return new Promise((resolve, reject) => {
      axios.post(url+'clone/assessments', req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  search(req){
    return new Promise((resolve, reject) => {
      axios.post(url+'search/assessments', req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }
}

export default new AssessmentService('health_backend', 'training/assessments');