import env from '../../env'
import BaseService from "../../services/BaseService"
import axios from 'axios'

class TrainingProgramsService extends BaseService {

	getTrainingManagerContent(req){
		return new Promise((resolve, reject) => {
	      axios.get(env.health_backend+'training/manager', {params: req}).then((res) => {
	        // handle success
	        resolve(res);
	      })
    	})
	}

}

export default new TrainingProgramsService('health_backend', 'health/training_programs')