import React, { Fragment, useState, useEffect, useContext} from "react"
import ReactDOM from "react-dom"
import { Link } from "react-router-dom"
import useStyle from "../../hooks/useStyle"
import CommonService from "../../services/CommonService"
import useEscape from "../../hooks/useEscape"
import {getLocale} from "../FormBuilder/FieldLabel"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {usePageLocale} from '../../hooks/useLocale'

const DataSourceMenu = ({id, journeyProfileId, isOpen, toggleModal, onSelect, dataSourceId, datasourceList, dataSourceKey}) => {
  
  useStyle("add_role_dialog");
  useStyle("filter_dialog");
  useEscape(toggleModal);
  
  const { state:{screen, user:currentUser} } = useContext(AuthContext);
  const { labelLocale:popupLocale } = usePageLocale(currentUser.current_locale, 'popup')

  const [list, setList] = useState(datasourceList);
  
  useEffect(() => {
    if(id)getList()
  }, [])

  let getList = () => {
    let req = {
      data_source_params:{
        data_source_id: [id],
      }
    }

    CommonService.getDataSource(req).then((res) => {
      setList(res[Object.keys(res)[0]].options);
    })
  }

  const DataFilters = () =>{
    if(dataSourceKey == 'data'){
      return(
        <Fragment>
          {list.map((o, k) =>(
            <div className="ver-icons" onClick={()=>onSelect(o.data)} key={k}>
              <span>
                <i className={`${o.data.icon || "fas fa-plus"}`} style={{ color: o.data.icon_color }}/>
              </span>
              <div className="popup-menu-title">{getLocale(o.data, currentUser.current_locale)}</div>
              <i className="fas fa-chevron-right lgrey pull-right"/>
            </div>
          ))}
        </Fragment>    
      )
    }

    return(
      <Fragment>
        {list.map((o, k) =>(
          <div className="ver-icons" onClick={()=>onSelect(o)} key={k}>
            <span>
              <i className={`${o.icon || "fas fa-plus"}`} style={{ color: o.icon_color }}/>
            </span>
            <div className="popup-menu-title">{getLocale(o, currentUser.current_locale)}</div>
            <i className="fas fa-chevron-right lgrey pull-right"/>
          </div>
        ))}
      </Fragment>  
    )
  }

  return ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom border-rounded-10">
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">{popupLocale(3)}</h4>
            <button className="btn-close" title="Close Modal" onClick={e=>toggleModal(e)}>
              ×
            </button>
          </div>

          {list && (
            <div className="p-5 scroll-x">
              <div className="popup-menu-grid">
                <DataFilters/>
              </div>

              <small className="font-9 lgrey lh-16 p-5">{id || dataSourceId}</small>
            </div>
          )}
        </div>
      </div>
    </Fragment>, document.body
  )
}

export default DataSourceMenu;