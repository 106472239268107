import env from '../../env';
import axios from 'axios';
import BaseService from '../../services/BaseService';

class ForumService extends BaseService {

  reply(req) {
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}health/discussion_responses`, req).then((res) => {
        // handle success
        console.log('reply response',res);
        resolve(res);
      }).catch((e) => {
        console.log('reply error',e);
        // handle error
        reject(e);
      })
    })
  }
  
}

export default new ForumService('health_backend','health/discussion_responses');