import React, {Fragment} from "react"
import {Link} from "react-router-dom"
import moment from '../../../../node_modules/moment/min/moment.min.js'
import useStyle from '../../../hooks/useStyle'
import useSort from '../../../hooks/useSorting'
import SortDirection from '../../../components/Common/SortDirection'
import CheckAccess from '../Roles/CheckAccess'
import ChildGrowth from "../../../constants/ChildGrowthDataPoints"
import Cs from "../../../services/CommonService"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import useModal from "../../../hooks/useModal"
import HealthCategoryService from "../HealthCategoryService"
import GenericModal from "../../../components/Modals/GenericModal"
import {OptionsObjList} from "../../Common/FormInput"
import {Table, THead, TH, TR, TD, TBody} from "../../Common/TableView"
import {PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon} from "../../Common/ImageIcons"
import {DraftBadge} from "../UserRoles/NotificationPill"
import {NotificationBadge} from "../../Common/BadgePill"

let selectedJourney = {}

const InfantJourneyTable = (props) => {
  useStyle('table')  

  const {routeJourney, openNotification, update, dataSources, labelLocale, userLocale, journeyStatusList} = props
  const {sort} = useSort(props.requestParams, props.getJourneyList)
  const { isOpen, toggleModal:toggleDeleteModal } = useModal()

  const onDelete = (j) => {
    selectedJourney = j
    toggleDeleteModal()
  }

  const deleteJourney = (e) => {
    e.preventDefault()
    HealthCategoryService.delete(selectedJourney.id).then((res) => {
      if(res.status == 204) {
        props.setJourneyList(props.journeyList.filter(i => i.id !== selectedJourney.id))
      }
    })
    toggleDeleteModal(e)
  }

  const tableData = props.journeyList.map((j, k) => {
    const sid = j.data_source?.health_condition?.sid
    const statusStyle = j.status?{
      color:'white',
      backgroundColor: journeyStatusList.find(o=> o.sid === j.status)?.bg_color
    }:{}

    if(sid === 'cancer_journey'){
      return(
        <CancerRow j={j} routeJourney={routeJourney} 
          journeyStatusList={journeyStatusList}
          onDelete={onDelete} openUserRole={props.openUserRole} 
          openNotification={openNotification} update={update} 
          statusStyle={statusStyle} key={k} index={k}
          userLocale={userLocale} labelLocale={labelLocale}/>
      )
    }else if(sid === 'dementia_journey'){
      return(
        <DementiaRow j={j} routeJourney={routeJourney} 
          journeyStatusList={journeyStatusList}
          onDelete={onDelete} openUserRole={props.openUserRole} 
          openNotification={openNotification} update={update} 
          statusStyle={statusStyle} key={k} index={k}
          userLocale={userLocale} labelLocale={labelLocale}/>
      )
    }else if(sid === 'ckd_journey'){
      return(
        <CancerRow j={j} routeJourney={routeJourney}
          journeyStatusList={journeyStatusList} 
          onDelete={onDelete} openUserRole={props.openUserRole} 
          openNotification={openNotification} update={update} 
          statusStyle={statusStyle} key={k} index={k}
          userLocale={userLocale} labelLocale={labelLocale}/>
      )
    } else if(sid === 'infant_journey'){
      return(
        <MCJRow j={j} routeJourney={routeJourney} 
          journeyStatusList={journeyStatusList}
          onDelete={onDelete} openUserRole={props.openUserRole} 
          openNotification={openNotification} update={update} 
          statusStyle={statusStyle} key={k} index={k}
          userLocale={userLocale} labelLocale={labelLocale}/>
      )
    }
  })

/*
  <SortDirection sort_column={props.requestParams.sort_column} column="record_id" reverse={props.requestParams.reverse}/>
  onClick={()=>sort('record_id')}
  <SortDirection sort_column={props.requestParams.sort_column} column="data.record_title" reverse={props.requestParams.reverse}/>
  onClick={()=>sort('data.record_title')}
  <SortDirection sort_column={props.requestParams.sort_column} column="last_update_at" reverse={props.requestParams.reverse}/>
  onClick={()=>sort('last_update_at')}
  <SortDirection sort_column={props.requestParams.sort_column} column="status" reverse={props.requestParams.reverse}/>
  onClick={()=>sort('status')}
*/

  return (
    <Fragment>
      <div className="app-content-section app-section-2 table-fix-head m-t-30">
        <Table className="shadow-small" id="patient_lists">
          <THead>
            <TR>
              <TH>
                Number
              </TH>
              <TH>
                {labelLocale(8)}
              </TH>
              <TH>
                {labelLocale(14)}
              </TH>
              <TH >
                {labelLocale(10)}
              </TH>
              <TH>
                {labelLocale(11)}
              </TH>
            </TR>
          </THead>
          <TBody>
            {tableData}
          </TBody>
      </Table>
      {props.journeyList.length == 0 && <props.NoRecords/>}    
    </div>

    <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteJourney}/>
    
    </Fragment>
  )
}

const MobileRequired = ({labelLocale}) => <font className="c-bemedian">{labelLocale(13)}</font>;
const BabyNameRequired = ({labelLocale}) => <font className="c-bemedian">{labelLocale(12)}</font>;
const AddressRequired = ({labelLocale}) => <font className="c-bemedian">Address Not Provided</font>;
const StatuDropDown = ({id, status, statusStyle, update, index, journeyStatusList, userLocale}) => (
  <select className="form-control input-sm fw500 wk-center" style={statusStyle}
    onChange={e=>update(index, {id:id, status:e.target.value})} 
    placeholder="Status" defaultValue={status}>
      <OptionsObjList list={journeyStatusList} id_key='sid' label_key='label' user_locale={userLocale}/>
  </select>
)

const MCJRow = ({labelLocale, j, index, statusStyle, routeJourney, onDelete, openUserRole, openNotification, update, journeyStatusList, userLocale}) => {
  let ds = j.data_source || {}
  let data = j.data || {}
  let child = j.child || {data:{}}
  let measurement = j.last_activity.child_measurement || {}
  const addessInfo = (data.taluka || data.address || '') +' '+ (data.village || '')

  return (
    <TR className="">
      <TD className="text-gray">
        {j.record_id}
        <DraftBadge isDraft={j.draft} styleName="d-block"/>
      </TD>
      <TD>
        <div className="m-b-10 pointer" onClick={e=>routeJourney(j)}>
          {data.mother_name || data.patient_name} - {child.data.baby_name?` ${child.data.baby_name}`:<BabyNameRequired labelLocale={labelLocale}/>}
        </div>
        <div className="m-b-10">
          {ds.health_condition?.label}
        </div>
        <div className="flex cgap-10 coldir-xs">
          <div className="flex aligncenter">
            <PhoneIcon title={data.cell_number_formatted || <MobileRequired labelLocale={labelLocale}/>}/>
          </div>
          <div className="flex aligncenter">
            <MapIcon title={addessInfo.length>0 ? addessInfo : <AddressRequired labelLocale={labelLocale}/>}/>
          </div>
        </div>
      </TD>
      <TD>
        <div className="dblock m-b-10">{Cs.formatUpdateDate(j?.last_update_at)}</div>
        <div className="dblock">{j?.last_updated_user?.name}</div>
        <div className="dblock">{j?.member_role?.role}</div>
        <div className="dblock">{measurement?.location?.label}</div>
      </TD> 
      <TD>
        {j.status != 'closed' && 
          <div className="flex aligncenter pointer">
            <LinkMapIcon id="path_details" tooltip="View path details" onSelect={()=>routeJourney(j)}>
              <NotificationBadge count={j.schedule_today_count}/>
              <NotificationBadge 
                position="bottom"
                count={j.schedule_missed_count}/>
            </LinkMapIcon>
            {j.nutrition_care_alert_count > 0 &&
              <LinkMailIcon id="notification" count={j.nutrition_care_alert_count} 
                tooltip="Journey Notification" onSelect={()=>openNotification(j)} 
                className="far fa-envelope m-l-15 font-18 text-muted"/>
            }
            <LinkUserIcon id="assign_user" tooltip="Assign journey" onSelect={()=>openUserRole(j)} count={j.shared_journey_count}/>
            <LinkDeleteIcon id="delete_journey" tooltip="Delete journey" onSelect={()=>onDelete(j)}/>
          </div>
        }
      </TD>
      <TD>
        <StatuDropDown id={j.id} status={j.status} {...{statusStyle, update, index, journeyStatusList, userLocale}}/>
      </TD>
    </TR>
  )
}

const CancerRow = ({labelLocale, j, index, statusStyle, routeJourney, onDelete, openUserRole, openNotification, update, journeyStatusList, userLocale}) => {
  let ds = j.data_source || {}
  let data = j.data || {}

  return (
    <TR className="">
      <TD className="text-gray">
        {j.record_id}
        <DraftBadge isDraft={j.draft}/>
      </TD>
      <TD>
        <div className="m-b-10 pointer" onClick={e=>routeJourney(j)}>
          {data.patient_name} 
        </div>
        <div className="m-b-10">
          {ds.health_condition?.label}
        </div>
        <div className="flex coldir-xs cgap-10">
          <div className="flex aligncenter">
            <PhoneIcon title={data.cell_number || <MobileRequired labelLocale={labelLocale}/>}/>
          </div>
          <div className="flex aligncenter">
            <MapIcon title={data.taluka || <AddressRequired labelLocale={labelLocale}/>}/>
          </div>
        </div>
      </TD>
      <TD>
        <div className="dblock m-b-10">{Cs.formatUpdateDate(j.updated_at)}</div>
        <div className="dblock">{j.updated_user?.name}</div>
      </TD>
      <TD>
        {j.status != 'closed' && 
          <div className="flex aligncenter pointer">
            <LinkMapIcon id="path_details" tooltip="View path details" onSelect={()=>routeJourney(j)}>
            </LinkMapIcon>
            <LinkUserIcon tooltip="Assign journey" onSelect={()=>openUserRole(j)}>
            </LinkUserIcon>
            <LinkDeleteIcon id="delete_journey" tooltip="Delete journey" onSelect={()=>onDelete(j)}>
            </LinkDeleteIcon>
          </div>
        }
      </TD>
      <TD>
        <StatuDropDown id={j.id} status={j.status} {...{statusStyle, update, index, journeyStatusList, userLocale}}/>
      </TD>
    </TR>
  )
}

const DementiaRow = ({labelLocale, j, index, statusStyle, routeJourney, onDelete, openUserRole, openNotification, update, journeyStatusList, userLocale}) => {
  let ds = j.data_source || {}
  let data = j.data || {}

  return (
    <TR className="">
      <TD className="text-gray">
        {j.record_id}
        <DraftBadge isDraft={j.draft}/>
      </TD>
      <TD>
        <div className="m-b-10 pointer" onClick={e=>routeJourney(j)}>
          {data.patient_name} 
        </div>
        <div className="m-b-10">
          {ds.health_condition?.label}
        </div>
        <div className="flex cgap-10 coldir-xs">
          <div className="flex aligncenter m-r-20">
            <PhoneIcon title={data.cell_number || <MobileRequired labelLocale={labelLocale}/>}/>
          </div>
          <div className="flex aligncenter">
            <MapIcon title={data.taluka || <AddressRequired labelLocale={labelLocale}/>}/>
          </div>
        </div>
      </TD>
      <TD>
        <div className="dblock m-b-10">{Cs.formatUpdateDate(j.updated_at)}</div>
        <div className="dblock">{j.updated_user?.name}</div>
      </TD> 
      <TD className="">
        {j.status != 'closed' && 
          <div className="flex aligncenter pointer">
            <LinkMapIcon id="path_details" tooltip="View path details" onSelect={()=>routeJourney(j)}/>
            <LinkUserIcon tooltip="Assign journey" onSelect={()=>openUserRole(j)}/>
            <LinkDeleteIcon id="delete_journey" tooltip="Delete journey" onSelect={()=>onDelete(j)}/>
          </div>
        }
      </TD>
      <TD className="">
        <StatuDropDown id={j.id} status={j.status} {...{statusStyle, update, index, journeyStatusList, userLocale}}/>
      </TD>
    </TR>
  )
}

export default InfantJourneyTable;