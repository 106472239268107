import React, {Fragment, useState, useEffect, useContext} from "react"
import {AuthContext} from "../../../../contexts/Authentication/AuthStateProvider"
import HealthCategoryService from "../../HealthCategoryService"
import ApexChart from "../../../Charts/ApexChart"
import {DateTimePicker, OptionsObjList} from "../../../Common/FormInput"
import PreLoadSpinner from "../../../PreLoadSpinner"
import useStyle from "../../../../hooks/useStyle"
import {TDInputMultiSelect} from "../../../Common/FormInput"
import {PresetDateRange, LeaderboardCharts} from "../../../../constants/DataSourceList"
import {Filters} from "./Filters"
import FilterSlider from "../../../Common/FilterSlider"
import useModal from '../../../../hooks/useModal'
import { Table, THead, TH, TR, TD, TBody } from "../../../Common/TableView"
import useSort from '../../../../hooks/useSorting'
import SortDirection from '../../../Common/SortDirection'
import Tabs, { TabPane } from "rc-tabs"

let requestParams = null
const VillageLeaderboard = ({talukaData}) => {

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [filter, setFilter] = useState({})
  const [charts, setCharts] = useState(LeaderboardCharts.village)
  const [chartsData, setChartsData] = useState()

  const legend = {show:true, position:'right', width:'125px'}

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)

  useEffect(()=>{
    requestParams = {
      filter_type:'leadership',
      geo_level:'villages',
      district:currentUser.current_organization?.data?.district,
      taluka:talukaData.taluka,  
    }
    getReport()
  }, [filter])

  const getReport = ()=>{
    setLoading(true)
    HealthCategoryService.getInfantInsights(requestParams).then((res)=>{
      setData(res.data.reports)
      setChartsData(res.data.kpis)
      setLoading(false)     
    })
  }
  
  const {sort} = useSort(requestParams, getReport)

  return(
    <>
      {loading ? 
        <div className="spinner"/>
        :
        <div id="ct">
            <div className="card-title-gray flex justspacebetween p20">
              <h4 className="fw700 f18 text-left">
                <span>
                  {currentUser.current_organization?.data?.state}  
                  {currentUser.current_organization?.data?.state && ' > '}  
                </span>
                <span>
                  {currentUser.current_organization?.data?.district} 
                  {currentUser.current_organization?.data?.district && ' > '}  
                </span>
                <span>{talukaData.taluka}</span>
              </h4>
              <h4 className="fw700 f16">
                Last 90 Days
              </h4>
            </div>
            <Tabs>
              <TabPane tab="General" key="1">
                <div className="card-body p10 table-responsive clearfix">
                  <Table className="shadow-small">
                    <THead>
                      <TR>
                        <TH onClick={e=>sort('village')}>
                          No 
                          <SortDirection sort_column={requestParams.sort_column} column="village" reverse={requestParams.reverse}/>
                        </TH>
                        <TH onClick={e=>sort('village')}>
                          Name 
                          <SortDirection sort_column={requestParams.sort_column} column="village" reverse={requestParams.reverse}/>
                        </TH>
                        <TH onClick={e=>sort('medical_facilities')}>
                          Facilities
                          <SortDirection sort_column={requestParams.sort_column} column="medical_facilities" reverse={requestParams.reverse}/>
                        </TH>
                        <TH onClick={e=>sort('is_active')}>
                          Users
                          <SortDirection sort_column={requestParams.sort_column} column="is_active" reverse={requestParams.reverse}/>
                        </TH>
                        <TH onClick={e=>sort('active_cases')}>
                          Cases
                          <SortDirection sort_column={requestParams.sort_column} column="active_cases" reverse={requestParams.reverse}/>
                        </TH>
                        <TH onClick={e=>sort('weight_below_median')}>
                          Weight Below Median
                          <SortDirection sort_column={requestParams.is_active} column="weight_below_median" reverse={requestParams.reverse}/>
                        </TH>
                        <TH onClick={e=>sort('weight_above_median')}>
                          Weight Above Median
                          <SortDirection sort_column={requestParams.is_active} column="weight_above_median" reverse={requestParams.reverse}/>                            
                        </TH>
                        <TH onClick={e=>sort('height_below_median')}>
                          Height Below Median
                          <SortDirection sort_column={requestParams.is_active} column="height_below_median" reverse={requestParams.reverse}/>                            
                        </TH>
                        <TH onClick={e=>sort('height_above_median')}>
                          Height Above Median
                          <SortDirection sort_column={requestParams.is_active} column="height_above_median" reverse={requestParams.reverse}/>
                        </TH>
                      </TR>
                    </THead>
                    <TBody>
                      <TableData reports={data}/>
                    </TBody>
                  </Table>
                </div>
            </TabPane>
            <TabPane tab="KPI" key="2">
              <div className="card-body p20 clearfix">
                {charts.map((c, i) => chartsData[c.data_key][c.sid]?
                  <div className="col-md-6" key={i}>
                    <div className="p20">
                      <ApexChart id={`leaderboard_chart_${i}`} 
                        chartType={c.chart} title={c.label} legend={legend}
                        className="br-grey ht-400 wk-center bg-white w-full"
                        data={chartsData[c.data_key][c.sid]} />
                    </div>
                  </div>
                  :
                  null
                )} 
              </div>
            </TabPane>
          </Tabs>
      </div>
    }
    </>
  )
 
}

const TableData = ({reports=[]}) => reports.map((r, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray">
          {k+1}
        </TD>
        <TD className="fw500 text-blue underline">
          {r.village}
        </TD>
        <TD>
          {r.medical_facilities}
        </TD>
        <TD className="font-18">
        </TD>
        <TD>
          {r.active_cases}
        </TD>
        <TD>
          {r.weight_below_median}
        </TD>
        <TD>
          {r.weight_above_median}
        </TD>
        <TD>
          {r.height_below_median}
        </TD>
        <TD>
          {r.height_above_median}
        </TD>
      </TR>
    )
  })

export default VillageLeaderboard;