import React, {Fragment} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import List from './List'
import Form from './Form'
import AlertForm from './AlertForm'

const NutritionCareRouter = (props) => (
  <Fragment>	
    <PrivateRoute path="/nutrition_cares" searchString={props.searchString} component={List} exact />
    <PrivateRoute path="/templates/nutrition_cares" searchString={props.searchString} component={List} exact />
    <PrivateRoute path="/nutrition_care/edit/:id?" component={Form} exact />
    <PrivateRoute path="/nutrition_care/create/:type/:formId/:id?" component={Form} exact />
  	<PrivateRoute path="/nutrition_care/alert/:nutrition_care_id/:id?" component={AlertForm} exact />
  </Fragment>
)

export default NutritionCareRouter;