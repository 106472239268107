import env from '../../../env'
import BaseService from "../../../services/BaseService"
import axios from 'axios'

class TrainingSessionsService extends BaseService {

	updateSortOrder(req) {
	  return new Promise((resolve, reject) => {
	    axios.put(`${env.health_backend}health/sort/training_sessions/${req.id}`, req)
	    .then((res) => {
	      // handle success
	      resolve(res)
	    }).catch((e) => {
	      // handle error
	      resolve(e.response)
	    })
	  })
  	}

}

export default new TrainingSessionsService('health_backend', 'health/training_sessions')