import { useState, useEffect } from 'react';
import Cs from "../../services/CommonService"
import AuthenticationService from "../../services/AuthService"
import PushNotification from "../../components/PushNotification/PushNotification"

const useAuth = (history, userDispatch, geoData) => {

	let [user, setUser] = useState({
	    registration_mode: 'phone_no',
	    email: '',
	    password: '',
	    isSubmitting: false,
	    errorMessage: null,
  	})

	const handleFormSubmit = (e) => {
	    e && e.preventDefault()
	    const userAgent = Cs.getBrowserType()
	    setUser({
	      ...user,
	      isSubmitting: true,
	      errorMessage: null,
	    })
	    /*if (user.isChecked) {
	      localStorage.email = user.email
	      localStorage.password = user.password
	      localStorage.checkbox = true
	    }*/
	    const req = { 
	      user: { 
	        email: user.email, 
	        password: user.password,
	        phone_no: user.phone_no,
	        uid: user.uid,
	        registration_mode: user.registration_mode,
	        geo_data:  geoData.geo_data,
	        user_agent: userAgent,
	        platform: navigator.platform, 
	      } 
	    }
	    AuthenticationService.signin(req).then((res) => {
	      if (res.status === 201) {  
	        userDispatch({
	          type: "LOGIN",
	          payload: res.data,
	        })
	        if(userAgent !== 'Safari')PushNotification();
	      } else {
	      	if(user.registration_mode === 'uid'){
	      		history.push('/signin')
	      	}else{
		      	setUser({
		          ...user,
		          isSubmitting: false,
		          errorMessage: res.data.error || res.statusText,
		        })
	      	}
	      }
	    }).catch((error) => {
	      console.log('error',error)
	      setUser({ ...user, isSubmitting: false, errorMessage: 'Network Error' })
	    })
  	}

	const routeUser = (user) => {
	    if(user.is_active == false && user.in_active_reason == "default_password_not_reset"){
	      history.push("/password/update")
	    }
	    else if(user.current_member?.home_page?.url){
	      history.push(user.current_member?.home_page?.url)
	    }
	    else{
	      history.push("/health/table")
	    }
	}

	return {
		user,
		setUser,
		handleFormSubmit,
		routeUser
	}

}

export default useAuth;