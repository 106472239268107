import env from '../../env';
import axios from 'axios';
import BaseService from '../../services/BaseService';

class HealthCategoryService extends BaseService{

  timelines(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.health_backend}health/timelines`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getInfantInsights(req){
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}health/infant_insights`, req).then((res) => {
        // handle success
        resolve(res)
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getNotificationReports(){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/reports/event_driven_notifications').then((res) => {
        // handle success
        resolve(res)
      }).catch((e) => {
        // handle error
        reject(e)
      })
    })
  }

  getContentManagerData(){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'content/manager').then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

  async getUpcomingCaseInfo(){
    let res = await axios.get(env.health_backend+'health/upcoming/journey_profiles')
    return res
  }

}

export default new HealthCategoryService('health_backend','health/journey_profiles', {post: true, put: false});