import React, { useState, Fragment, useEffect, useContext } from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Event from "./Event";
import ScreeningEventsService from "../ScreeningEventsService";
import Pagination from "../../Pagination";
import ConfirmMenu from "../../Modals/ConfirmMenu";
import ProjectMenu from "../../Modals/ProjectMenu";
import FormTemplateId from "../../../constants/FormTemplateList";
import CommentsModal from "../../Modals/CommentsModal";
import ChecklistsModal from "../../Modals/ChecklistsModal"
import {MenuItem, NavIcon} from '../../Common/MenuItem'
import CardMenu from "../../Modals/CardMenu";
import { Link } from "react-router-dom";
import useModal from "../../../hooks/useModal";
import useStyle from "../../../hooks/useStyle";
import PreLoadSpinner from "../../PreLoadSpinner";
import FilterModal from "./ScreeningFilterModal";
import CommonService from "../../../services/CommonService";
import ScreeningEventTable from './ScreeningEventTable'

let requestParams = {}
let selectedEvent = {}
let dataSource = {}

const ScreeningEvents = (props) => {
  useStyle('/css/tile-components.css', true)

  let cardGridCss = "col-md-4";
  let cardHeightCss = "ht-420";
  if (props.id) {
    cardGridCss = "col-sm-12";
    cardHeightCss = "mih-380";
  }

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [events, setEvents] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isFormFilterOpen, toggleModal:toggleFormFilterModal } = useModal()
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal,} = useModal()
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()
  const [formFieldsList, setFormFieldsList] = useState({})
  const [showEventsTable, setShowEventsTable] = useState(false)

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      organization_id: currentUser.current_organization_id,
      data_source_params: {
        data_source_id: [3738],
        response_type: "object",
      },
      form_fields_params: {
        ids:[542]
      },
      id: props.id
    }

    getScreeningEvent()
  }, [props.searchString, currentpage])

  let getScreeningEvent = () => {
    ScreeningEventsService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        dataSource = res.data.data_source_list;
        setFormFieldsList(res.data.form_fields || {})
        setEvents(res.data.screening_events)
      }
    })
  }

  const deleteActivity = (e) => {
    e.preventDefault()
    ScreeningEventsService.delete(selectedEvent.id).then((res) => {
      if (res.status == 204) {
        setEvents(
          events.filter((i) => i.id !== selectedEvent.id)
        )
      }
    })
    toggleDeleteModal(e)
  }

  const showMenu = (e, a) => {
    selectedEvent = a
    toggleMenuModal(e)
  }

  const addChecklist = (e, a) => {
    selectedEvent = a
    toggleChecklistModal(e)
  }

  const addNotes = (e, a) => {
    selectedEvent = a
    toggleCommentModal(e)
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        <a href="#">
          <span className="font-13">{item.form_id} - {item.record_id}</span>
        </a>

        <Link to={`/screening_events/create/${item.form_id}/${item.id}`}>
          <i className="far fa-edit font-15" aria-hidden="true"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </Link>

        <a href="#" onClick={(e) => toggleDeleteModal(e)}>
          <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ event }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, event)} className="fas fa-bars m-r-15 font-16"/>
  )

  const toggleEventTable = () => setShowEventsTable(!showEventsTable)


  /*if(events.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  const eventCard = events && events.map((a, i) => (
      <div className={cardGridCss} key={i}>
        <Event
          event={a}
          dataSource={dataSource}
          addMenu={showMenu}
          menu={Menu}
          addNotes={addNotes}
          addChecklist={addChecklist}
          formFieldsList={formFieldsList}
          cardHeightCss={cardHeightCss}/>
        {selectedEvent.id == a.id && (
          <ProjectMenu
            isOpen={isMenuOpen}
            toggleModal={toggleMenuModal}
            item={selectedEvent}
            type="screening_event"
            MenuItems={menuItems}
          />
        )}
      </div>
  ))

  return (
    <Fragment>
      <div id="ct">
      <div className="app_content">
        <div className="app-header flex justspacebetween m-t-15">
          <div className="flex coldir">
            <div className="app-title m-r-20">Screening Events</div>
            <div className="font-14">{events.length} Items</div>
          </div>

          <div className="flex aligncenter">
            <Link to='/screening_events/create/542' 
              className="bg-highlight round-btn float-right m-r-5 m-t-4">  
              <i className="fas fa-plus"></i>
            </Link>
            <NavIcon id="toggle_event_table" dataTip="Toggle Screening Events Table" 
              onSelect={toggleEventTable}
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fa fa-table"/>
          </div>
        </div>
        
        <div className="app-content-section m-t-30">
          <div className="row">
            {!showEventsTable && eventCard}
          </div>

        {showEventsTable && <Fragment>
          <ScreeningEventTable events={events} requestParams={requestParams}
            selectedEvent={selectedEvent}
            getScreeningEvent={getScreeningEvent} toggleDeleteModal={toggleDeleteModal} />
        </Fragment>
      }
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage}
          setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>
      </div>
      </div>

      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteActivity} />
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedEvent} type="screening_event" />}
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedEvent} type="screening_event"/>}
      {isFormFilterOpen && <FilterModal  dataSource={dataSource} isOpen={isFormFilterOpen} toggleModal={toggleFormFilterModal} />}
    </Fragment>
  )
}

export default ScreeningEvents;