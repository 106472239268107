import React, {useState, Fragment, useContext, useEffect, useRef} from "react"
import ReactTooltip from 'react-tooltip'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import DigitalContent from '../../../components/DigitalContent/List/DigitalContent'
import DigitalContentService from "../../../components/DigitalContent/DigitalContentService"
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu"
import FormTemplateId from '../../../constants/FormTemplateList'
import CommentsModal from "../../Modals/CommentsModal"
import ProjectMenu from "../../Modals/ProjectMenu"
import {Link} from "react-router-dom"
import {CheckBox} from "../../Common/FormInput"
import {MenuItem, NavIcon} from '../../Common/MenuItem'
import useModal from "../../../hooks/useModal"
import NotesModal from "../../Modals/NotesModal"
import MenuModal from "../../Modals/Menu"
import ChecklistsModal from "../../Modals/ChecklistsModal"
import useStyle from '../../../hooks/useStyle'
import FilterModal from "../List/DigitalContentFilterModal"
import GenericModal from '../../Modals/GenericModal'
import DigitalContentForm from '../Form/DigitalContentForm'
import DigitalContentTable from "./DigitalContentTable"
import FilterSlider from "../../Common/FilterSlider"
import CommonService from "../../../services/CommonService"
import {SwiperContainer, SwiperSlide} from "../../Common/Swiper"
import {ActiveStatusList, setDefaultLabel} from "../../../constants/DataSourceList"
import DigitalContentTableList from "./DigitalContentTableList"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import querystringify from "querystringify"
import { CTTooltip } from "../../Common/CueTooltip"
import { HamburgerMenuIcon } from "../../Common/ImageIcons"
import { AddBtn, FontAwesomeBtn } from "../../Common/Button"
import {usePageTitle} from '../../../hooks/pageHead'

let requestParams = {}
let selectedDigitalContent = {}
let dataSource = {}
let formProps = {}
let cardViewCount = 1

const DigitalContentList = (props) => {
  useStyle('/css/tile-components.css', true)
  usePageTitle('DataSource List')

  const indexEle = useRef(null)
  let queryParam = querystringify.parse(props.location.search)

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)

  const [digitalContentList, setDigitalContentList] = useState([])
  const [currentpage,setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [filter, setFilter] = useState()
  const [subjectId, setSubjectId] = useState()
  const [webPageId, setWebPageId] = useState()
  const [isActive, setIsActive] = useState()
  const [loading, setLoading] = useState(false)
  const [formFieldsList, setFormFieldsList] = useState({})
  const [showDigitalContentTable, setShowDigitalContentTable] = useState(false)

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isFormOpen, toggleModal:toggleFormModal} = useModal()
  const { isOpen:isFormFilterOpen, toggleModal:toggleFormFilterModal } = useModal()

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'content_type': subjectId,
      'web_page': webPageId,
      'is_active': isActive,
      'data_source_params': {
	       "data_source_id":[3657],
	       //"response_type":"object"
         'order_by': 'order_list',
      },
      'form_fields_params': {
        'ids':[511, 512, 513, 514, 515, 516, 518, 549, 491]
      },
      'page': currentpage,
      'paginate': 1,
      'per_page': 10,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'totalItems': 0,
      'search': queryParam.search,
    }

    getDigitalContents()
    //test()
    return ()=>{
      formProps = {}
      requestParams = {}
      selectedDigitalContent = {}
    }
  }, [queryParam.search, currentUser.id, currentpage, subjectId, webPageId, isActive])

  let paginate = (page) =>{
    requestParams.page = page
    getDigitalContents(true)
    setCurrentpage(page)
  }

  let test = () =>{
    DigitalContentService.getAll({
      content_type: 'marketing',
      page: 1,
      paginate: 1,
      per_page: 10,
      sort_column: 'updated_at',
      sort_direction: 'asc',
      totalItems: 0,
      data_source_filter: [{"key":"web_page.label","operator":"=","value":"Home"}]
    }).then((res)=>{

    })
  }

  let getDigitalContents = (is_paginate) => { 
    setLoading(true)
    requestParams.content_type = subjectId 

    DigitalContentService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
      dataSource = res.data.data_source_list;
      setDefaultLabel(null, dataSource.menu_digital_content.options);
       //setPagemeta(res.data.meta)
       requestParams.totalItems = res.data.meta.total
       cardViewCount = CommonService.getCardCount(screen) 
       requestParams.totalPages = Math.ceil( requestParams.totalItems / cardViewCount);
       setFormFieldsList(res.data.form_fields || {})
       if(requestParams.search || isActive || subjectId || webPageId || requestParams.page == 1 || is_paginate){
          setDigitalContentList(res.data.digital_contents)
        }else{
          setDigitalContentList([...digitalContentList ,...res.data.digital_contents])
        }
        setLoading(false)
      }
    })
  }

  const deleteItem = (e) => {
    e.preventDefault();
    DigitalContentService.delete(selectedDigitalContent).then((res) => {
      if (res.status == 204) {
        setDigitalContentList(digitalContentList.filter(i => i.id !== selectedDigitalContent.id))
      }
    })
    toggleDeleteModal(e);
  }

  const addDelete = (e, ja) => {
    selectedDigitalContent = ja;
    toggleDeleteModal(e);
  }

  const addMenu = (e, ja) => {
    selectedDigitalContent = ja;
    toggleMenuModal(e);
  }

  const addNotes = (e, ja) => {
    selectedDigitalContent = ja;
    toggleCommentModal(e);
  }

  const toggle = (value) => {
    if(value && value != filter){
      setFilter(value)
      setSubjectId(value)
    }else{
      setFilter()
      setSubjectId()
    }  
  }

  const exist = (value) =>{
    return value === filter 
  }

  const openFormModal = (type, id) =>{
    formProps = {
      match:{params:{
        id: id,
        formId: type?.child_form,
        type: type?.sid
      }},
      isPopupMode:true,
      onSuccess:getDigitalContents
    }

    toggleFormModal()
  }

  const toggleWebpage = (value) => {
    if(value && value != webPageId){
      setWebPageId(value)
    }else{
      setWebPageId()
    }
  }

  const updateStatus = (e, item) =>{
    e.preventDefault()
    item.is_active = !item.is_active 
    DigitalContentService.update({
      id:item.id,
      is_active:item.is_active
    }).then((res) => {
      if(res.status==204){
        setDigitalContentList([...digitalContentList])
      }
    })
  }

  const webPageExists = (value) => requestParams.web_page == value;

  const toggleActive = (value) => {
    if(value && value != isActive){
      setIsActive(value);
    }else{
      setIsActive()
    }
  }

  const activeExist = (value) => requestParams.is_active == value;

  const toggleDigitalContentTable = () => setShowDigitalContentTable(!showDigitalContentTable)

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <Link to="/">
          <span className="font-13">{item.content_form_id} - {item.record_id}</span>
        </Link>
        <Link to={`/digital_contents/edit/${item.id}?`}>
          <i className="far fa-edit"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link to="/" onClick={(e) => {addDelete(e, item);toggleMenuModal(e);}}>
          <i className="far fa-trash-alt"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link to="/"onClick={e=>{addNotes(e, item);toggleMenuModal(e)}}>
          <i className="fa fa-notes-medical"></i>
          <span className="font-13">Comments</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <MenuItem id="status" icon={item.is_active?'fas fa-check-circle ':'fas fa-circle'} 
          action="Status" onSelect={e => updateStatus(e, item)}/>
      </Fragment>
    )
  }

  const Details = ({ ja, fn }) => (
    <div></div>
  )

  const TrainingSubjectFilter = () => (
    <div className="p-5 scroll-x">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Types of Content
        </h5>
        
        <CheckBox list={dataSource.menu_digital_content.options || []} labelKey="label" valueKey="sid" 
          toggle={toggle} exists={exist}/> 

        <h5 className="font-16 text-center bg-highlight white">
          Webpage
        </h5>

        <CheckBox list={dataSource.menu_digital_content.options || []} labelKey="label" valueKey="value" 
          toggle={toggleWebpage} exists={webPageExists}/> 

        <h5 className="font-16 text-center bg-highlight white">
          Active Status
        </h5>

        <CheckBox list={ActiveStatusList} labelKey="label" valueKey="value" 
          toggle={toggleActive} exists={activeExist}/>  
      </div>
    </div>
  )

  const TrainingSubjectFilters = () => {
    if(Object.keys(dataSource).length != 0){
      let {menu_digital_content} = dataSource
      let content_type = menu_digital_content?.options?.find(o => o.sid === subjectId);
      let web_page = menu_digital_content?.options?.find(o => o.sid === webPageId);
      let is_active = ActiveStatusList?.find(o => o.value === isActive);    
      
      return(
        <div className="pull-right m-r-20">
          {content_type?.value ? <span className="pill bg-highlight white">{content_type.label}</span>: null }
          {web_page?.value ? <span className="pill bg-highlight white">{web_page.label}</span>: null }
          { is_active ? <span className="pill bg-highlight white">{is_active.label}</span>: null }
        </div>
      )
    }else{
      return null
    }
  }
     
  const digitalContentsCard =  digitalContentList && digitalContentList.map((d, i) => (
    <div className="col-md-4" key={i}>
      <DigitalContentTable digitalContent={d}  addMenu={addMenu}
        details={Details} addNotes={addNotes} Datasource={dataSource} 
        toggleModal={toggleDeleteModal} formFieldsList={formFieldsList} index={i}/>
        {selectedDigitalContent.id == d.id && 
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
          item={selectedDigitalContent} addDelete={addDelete} type="digital_content" 
          MenuItems={menuItems}/>
        }
    </div>
  ))

  if(loading){
    return(
      <PreLoadSpinner/>
    )
  }

  return (
    <Fragment>
      <div id="ct">
      <div className="app_content">
        <div className="app-header flex justspacebetween m-t-15">
          <div className="flex">
            <div className="app-title m-r-20">Digital Content</div>
          </div>
          <div className="flex aligncenter">
            <FontAwesomeBtn 
              iconCss="fas fa-plus" name="Add" css="m-r-5"
              id="create_items" tooltip="Create New Item"  
              onSelect={()=>toggleFormFilterModal()}>
            </FontAwesomeBtn>
              
            <FontAwesomeBtn id="toggle_digital_content_table" 
              tooltip="Toggle Digital Content Table" css="m-r-5"
              onSelect={()=>toggleDigitalContentTable()} 
              iconCss="fas fa-table" name="Table">
            </FontAwesomeBtn>

            <FontAwesomeBtn id="filter_items" tooltip="Filter Items" 
              onSelect={()=>toggleFilterModal()} css="m-r-5" 
              iconCss="fas fa-sliders-h" name="Filter" >
            </FontAwesomeBtn>
            <TrainingSubjectFilters />
          </div>
        </div>

        <div className="app-content-section m-t-30">
          {!showDigitalContentTable && !isFormOpen &&
            <div className="row">
              {digitalContentsCard}
            </div>
          }

          {showDigitalContentTable && 
            <DigitalContentTableList digitalContentList={digitalContentList} 
              requestParams={requestParams} selectedDigitalContent={selectedDigitalContent} 
              updateStatus={updateStatus} getDigitalList={getDigitalContents} 
              addDelete={addDelete} />
          }

          <Pagination totalItems={requestParams.totalItems} currentpage={currentpage} 
            setCurrentpage={paginate} itemsPerPage={requestParams.per_page}/>
        </div>
      </div>
      </div>
    
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteItem}/>
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedDigitalContent} type="digital_content"/>}
      {isFormFilterOpen && <FilterModal isOpen={isFormFilterOpen} toggleModal={toggleFormFilterModal} menuList={dataSource} onSelect={openFormModal}/>}
      {isFormOpen && <GenericModal component={DigitalContentForm} {...formProps} isOpen={isFormOpen} toggleModal={toggleFormModal} widthCss="w-100p"/>}
      {isFilterOpen && <FilterSlider component1={TrainingSubjectFilter} openSideNav={isFilterOpen} setOpenSideNav={toggleFilterModal}/> }
    </Fragment>
  )

};

export default DigitalContentList;