import React, {Fragment} from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import SchedulesList from './List/PreScheduleEventList'
import SchedulesForm from './Form/PreScheduleEventForm'

const PreScheduleEventRouter = (props) => (
  <Fragment>	
    <PrivateRoute path="/schedule/event" component={SchedulesList} searchString={props.searchString} exact />
    <PrivateRoute path="/schedule/event/create/:type/:form_id/:id?" component={SchedulesForm} exact />
    <PrivateRoute path="/schedule/edit/:id" component={SchedulesForm} exact />
  </Fragment>
)

export default PreScheduleEventRouter;