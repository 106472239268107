import React, { Fragment, useState, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"
import OrganizationPartnershipService from "./OrganizationPartnershipService"
import useStyle from '../../hooks/useStyle'
import ReactDOM from 'react-dom'
import Cs from '../../services/CommonService'
import env from "../../env"

const OrganizationPartnerships = (props) => {
  useStyle('dashboard')	
  
  const { state: { screen, user: currentUser }, dispatch} = useContext(AuthContext)

  const {organization} = props

  const [loading, setLoading] = useState(true) 
  let [selectedPartnerships, setSelectedPartnerships] = useState({})
  let [organizations, setOrganizations] = useState([])

  useEffect(() => {
    getOrganizationPartnerships()
  }, [])

  const getOrganizationPartnerships = () =>{
	  setLoading(true)
  	const req = {
  	  consumer_organization_id: organization.id,
    }
    OrganizationPartnershipService.getAll(req).then((res)=>{
      if(res.status == 200){
        setOrganizations(res.data.organizations)
        setSelectedPartnerships(Cs.listToObj(res.data.organization_partnerships, 'provider_organization_id'))
		    setLoading(false)
      }
    })
  }

  const createOrRemove = (selected_org, provider_org) =>{
  	if(selected_org){
  	  	OrganizationPartnershipService.delete(selected_org.id).then((res)=>{
	      if(res.status == 204){
	        selectedPartnerships[selected_org.provider_organization_id] = null
	        setSelectedPartnerships({...selectedPartnerships})
	      }
	    })	
  	}else{
  		const req = {
	  	  'provider_organization_id':provider_org.id,
        'consumer_organization_id':organization.id
	  	}
	    OrganizationPartnershipService.create(req).then((res)=>{
	      if(res.status == 201){
	        selectedPartnerships[provider_org.id] = res.data.organization_partnership
	        setSelectedPartnerships({...selectedPartnerships})
	      }
	    })
  	}
  }

  const OrganizationList = () => organizations.map((o, i) => {
  	const selectedPartnership = selectedPartnerships[o.id]
    if(o.id == organization.id) return null
  	return(
      <div className="m-b-5" onClick={e=>createOrRemove(selectedPartnership, o)} key={i}>
      	<span className={selectedPartnership?'font-18 far fa-check-square text-muted':'font-18 far fa-square text-muted'}/>
      	<span className="m-l-15 font-14">{o.name}</span>
      </div>
    )
  }) 

  if(loading){
    return(
      <div className="spinner"/>
    )
  }

  return(
  	<div className="p-15">
  		<OrganizationList/>
  	</div>
  )
}

export default OrganizationPartnerships;