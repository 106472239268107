import env from '../../env';
import axios from 'axios';
import BaseService from "../../services/BaseService";

class OrganizationService extends BaseService {
  
  report(req){  
    return new Promise((resolve, reject) => {
      axios.post(env.health_backend+'health/report/organizations', req).then((res) => {
        resolve(res)
      })
    })
  }

}

export default new OrganizationService('health_backend', 'health/organizations');