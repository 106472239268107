import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import DynamicForm from "../../FormBuilder/DynamicForm"
import FormTemplateId from "../../../constants/FormTemplateList"
import ScreeningListService from "../ScreeningListService"
import querystringify from "querystringify"
import FormContainer from '../../FormBuilder/FormContainer'
import {CityState} from '../../Common/CityState'

let formMode = null
let list = {}
let data = {}
let formFn = {}

function ScreeningForm(props) {
  
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let forigenObject =  props.location.state;
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ScreeningListService.get(params.id).then((res) => {
      if (res.status == 200) {
        list = res.data.screening;
        data = list.data || {}
        //params.form_template_id = list.activity_form_id;
        console.log('data',data)
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function (res) {
      list = null;
    })
  }

  useEffect(() => {
    list = {}
    data = {}
    formMode = "create-form-submissions";
    data = {}
    if (params.id) {
      get();
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, []);

  const create = (list) => {
    list.created_by = currentUser.id;
    list.updated_by = currentUser.id;
    list.form_id = params.formId;
    list.form_type = params.formType
    list.screening_event_id = params.eventId
    ScreeningListService.create(list).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const update = (list) => {
    list.updated_by = currentUser.id;
    ScreeningListService.update(list).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = () => {
    if(props.isPopupMode){
      props.onFormSubmit()
    }else if(queryParam.activity_form_id){
      props.history.goBack()
      /*props.history.push(
        `/infant/activities/${params.journey_profile_id}?activity_form_id=${queryParam.activity_form_id}`
      )*/  
    }else{
      /*props.history.push(
        `/health/activities/${params.journey_profile_id}`
      ) */
      props.history.goBack() 
    } 
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm
          formMode={formMode}
          formId={params.formId}
          form={list}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
          
          <CityState formFn={formFn} data={data}/>
      </FormContainer>
    )
  } else {
    return null;
  }
}

export default ScreeningForm;
