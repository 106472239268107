import React, {useState, useEffect} from 'react'
import {CueTip} from '../../../components/Common/CueTooltip'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
/*import useCustomForm from '../../../hooks/useCustomForm';*/
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, Description, requiredMsg} from '../FieldLabel'
import NumberFormat from 'react-number-format'

const InputTextField = ({renderField, field, formData, formFn, readOnly, errors, isFormWizard, position, openFieldLabelModal, currentLocale, formId}) => {

  const [showError, setShowError] = useState(false)
  const [value, setValue] = useState(formData[field.client_id])

  useEffect(()=>{
    if(value != formData[field.client_id]){
      formData[field.client_id] = FormHelper.checkDecimalPoint(formData[field.client_id], inputAttributes.fraction_size)
      setValue(formData[field.client_id])
    }
  }, [formData[field.client_id]])

  if(!renderField){
    return null
  }
  
  let inputAttributes = {
  	'id': field.client_id,
  	'name': field.client_id,
  	'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only),
    'type':'text',
    'required':field.required,
    'className':'form-input form-input-gray form-input-full'
  }

  let parentAttributes = {
  	'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes)

  let validation = {
    required: 'This is required'
  }

  const handleChange = (event) => {
    const { target } = event;
    const { name } = target;
    formData[name] = target.value;
    setValue(target.value)
    FormHelper.setFormTitle(field, formData, target.value)
    if(field.required || target.value?.length > 0){
      setError(event)
    }else{
      //not required empty fields validation true
      FormHelper.setErrorState(errors, name, false)
      setShowError(!showError);
    }
  }

  const setFormatNumber = (values, event) =>{
    const { formattedValue, value } = values;
    formData[field.client_id] = value;
    formData[field.client_id+'_formatted'] = formattedValue;
    setValue(value)
    FormHelper.setFormTitle(field, formData, formattedValue)
    if(field.required)
      //Only required field validation
      setError(event)
  }
    
  const setError = (event) => {
    /*if(field.required){*/
      const { target:{name, validity:{valid, valueMissing, typeMismatch, patternMismatch, tooShort, tooLong, rangeOverflow, rangeUnderflow}} } = event;
      errors[name].invalid = !valid;
      /*errors[name].typeMismatch = typeMismatch;
      errors[name].patternMismatch = patternMismatch;
      errors[name].tooShort = tooShort;
      errors[name].tooLong = tooLong;
      errors[name].rangeOverflow = rangeOverflow;
      errors[name].rangeUnderflow = rangeUnderflow;*/
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
        if(valueMissing){
          errors[name].errormsg =  field.label+' is required.'
        }else if(typeMismatch){
          errors[name].errormsg = 'Invalid Input'
        }else if(patternMismatch){
          errors[name].errormsg = 'Invalid Input'
        }else if(tooShort){
          errors[name].errormsg = 'Input too short'
        }else if(tooLong){
          errors[name].errormsg = 'Input too long'
        }else if(rangeOverflow){
          errors[name].errormsg = 'Number must be lower than the highest value specified'
        }else if(rangeUnderflow){
          errors[name].errormsg = 'Number must be greater than the lowest value specified'
        }
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
    /*}*/
  }

  const refreshFormSubmit = (target) =>{
    formFn.refreshFormSubmit()
  }

  FormHelper.setInputTypeValidation(field, inputAttributes, validation, position, formFn);
  FormHelper.setMinMaxValidation(field, inputAttributes, validation);
  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const Error = () => {
    if(errors[field.client_id].invalid && (errors[field.client_id].touched || errors.isSubmitted)){
      return(
        <div className="errormsg">
          {errors[field.client_id].errormsg || requiredMsg}
        </div>
      )
    }
    return null
  }

  if(field.component_type === "phone_no"){
    return(
      <div {...parentAttributes}>
        <div className="flex coldir m-b-20 tooltip">
          <CueTip 
            positionCss={position>1?'top':'bottom'}
            tooltip={field.tooltip}
            currentLocale={currentLocale}
            locale={field.locale?.tooltip}/>
          <FieldLabel field={field} labelAttributes={labelAttributes} 
            currentLocale={currentLocale}
            openFieldLabelModal={openFieldLabelModal}/>
          <div className="flex">
            <input 
              defaultValue={formFn.country_code}
              className="form-control w-42 ht-42 f15"
              placeholder="Country Code"/>
            <NumberFormat mask="_" 
              value={value} 
              onValueChange={(values, sourceInfo) => setFormatNumber(values, sourceInfo.event)}
              {...inputAttributes} 
              onBlur={() => {refreshFormSubmit()}}
              data-tip data-for={`tooltip_${field.client_id}`}/>
          </div>
          <Description description={field.description} />
          <Error/>
        </div>
      </div> 
    )
  }

  return (
  	<div {...parentAttributes}>
      <div className="flex coldir m-b-20 tooltip">
        <CueTip 
          positionCss={position>1?'top':'bottom'}
          tooltip={field.tooltip}
          currentLocale={currentLocale}
          locale={field.locale?.tooltip}/>
    	  <FieldLabel field={field} labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal} formId={formId}/>
   	    <input {...inputAttributes}   
          onChange={e => handleChange(e)} 
          value={value} 
          onBlur={(e) => refreshFormSubmit(e.target)}/> 
        <Description description={field.description} currentLocale={currentLocale}
          locale={field.locale?.description}/>
   	    <Error/>
      </div>
    </div> 
  )
}

export default InputTextField;


/*
/*let validator = {
    set: function(obj, prop, value) {
      if (field === 'age') {
        if (!Number.isInteger(value)) {
            throw new TypeError('The age is not an integer');
        }
        if (value > 200) {
            throw new RangeError('The age seems invalid');
        }
      }

      // The default behavior to store the value
      obj[prop] = value;

      // Indicate success
      return true;
    }
  }

  let modal = new Proxy(formData, validator);*/
