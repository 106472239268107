import React, {Fragment,useState} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../../hooks/useStyle';
import useSort from '../../../../hooks/useSorting';
import SortDirection from '../../../Common/SortDirection'
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from '../../../Common/arrayMove';
import Cs from "../../../../services/CommonService";
import StepAssessments from "./StepAssessments"
import { Table, THead, TH, TR, TD, TBody } from "../../../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../../Common/ImageIcons"

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt"></span>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
})

const AssessmentTable = ({assessment, requestParams, openQuestionModal, typeOfQuestion, journeyCategory, selectedAssessment, togglePublishModal, share}) => {

  const [currentAssessment, setCurrentAssessment] = useState();

  const toggleAssessment = (id) => {
    if(id === currentAssessment){
      setCurrentAssessment()
    }else{
      setCurrentAssessment(id)
    }
  }

  const TableData = ({assessment}) => assessment && assessment.map((o, k) =>
    <TR key={k}>
      <TD data-title="Id" className="text-gray">{o.record_id}</TD>
      <TD data-title="Name of Exam" className="fw500">{o.data.subject_name}</TD>
      <TD data-title="Created Date">{Cs.formatUpdateDate(o.updated_at)}</TD>
      <TD data-title="Submission Date">{Cs.formatUpdateDate(o.data.submission_deadline_formatted_date)}</TD>
      <TD data-title="Created By">{o.author_name}</TD>
      <TD data-title="Questions">
        <div>
          {o.step_assessments && o.step_assessments.length || 0} Questions
          {o.id == currentAssessment ? 
            <i className="m-l-5 m-t-5 fa fa-chevron-up" onClick={e=>toggleAssessment(o.id)} aria-hidden="true"></i>
            :<i className="m-l-5 m-t-5 fa fa-chevron-down" onClick={e=>toggleAssessment(o.id)} aria-hidden="true"></i>
          }
        </div>
        {o.id === currentAssessment ? 
          <div>
            <StepAssessments flag={false} stepAssessments={o.step_assessments}  
            openQuestionModal={openQuestionModal} typeOfQuestion={typeOfQuestion} />
          </div>:
          <div></div>
        }      
      </TD>
      <TD data-title="Preview" >
        {o.is_published && 
          <Fragment>
            <Link id="question_form_icon" 
              to={`/question/${o.id}?is_preview=true`}
              data-tip="Preview Question Form">
              <i className="far fa-file"/>
            </Link>
          </Fragment>
        }
      </TD>
      <TD data-title="Publish" >
        <div className="flex aligncenter">
          <span id="publish_icon" 
            onClick={(e) => {selectedAssessment = o;togglePublishModal(e)}}
            action="Publish Assessment to Students">
            <i className={o.is_published?'far fa-check-circle':'far fa-circle'} />
          </span>
        </div>
      </TD>

    </TR>
  )

  return (
    <div className="col-xs-12 m-l-10 m-t-10">
      <SortableContainer distance={1} lockAxis="y" useDragHandle>
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH>Id
              </TH>
              <TH>Name of Exam 
              </TH>
              <TH>Created Date 
              </TH>
              <TH>Submission Date
              </TH>
              <TH>Created By
              </TH>
              <TH>Questions
              </TH>
              <TH>Preview
              </TH>
              <TH>Publish
              </TH>
            </TR>
          </THead>
          <TBody>
            <TableData assessment={assessment} />
          </TBody>
        </Table>
      </SortableContainer>
    </div>
  )

}

export default AssessmentTable;