import React,{useState} from "react"
import Cs from '../../services/CommonService'

let ImageList = ({list, styleName, loading="auto"}) => list && list.length>0 ? list.map((n, i) =>
  <img src={Cs.getIconByType(n)} loading={loading} className={styleName} key={i}/>
): null;

let Image = ({image, styleName, loading="auto"}) => image ?<img className={styleName} loading={loading} src={Cs.getIconByType(image)}/>: null;

let ImagePlayList = ({list, styleName, loading="auto", pagerPostion='bottom'}) => {
  const [index, setIndex] = useState(0)

  if(!(list && list.length>0))return null;

  setTimeout(()=>{
  	if(index == (list.length-1)){
  		setIndex(0)
  	}else{
  		setIndex(index+1)
  	}
  }, 5000)
  
  return(	
	  <>
      {pagerPostion == 'top' && 
        <div className="text-center m-t-5">
          <ImageListDots list={list} index={index} setIndex={setIndex}/>
        </div>
      }
	    <img src={Cs.getIconByType(list[index])} loading={loading} className={styleName}/>	
      {pagerPostion == 'bottom' && 
        <div className="text-center m-t-5">
          <ImageListDots list={list} index={index} setIndex={setIndex}/>
        </div>
      }
    </>
  )
}

let ImageListDots = ({list, index, setIndex}) => list && list.length>0 ? list.map((n, i) =>
    <span className={`m-r-5 pointer fas ${index==i ? 'fa-circle highlight':'fa-circle'}`} onClick={(e)=>{e.preventDefault();setIndex(i)}} key={i}/>
): null;

let ImageFlexList = ({list, styleName, loading="auto"}) => {
  if(!(list && list.length>0))return null;

  return( 
    <div className="d-flex">
      {list.map((i, k)=>
        <img key={k} src={Cs.getIconByType(i)} loading={loading} className={styleName}/>  
      )}
    </div>
  )
}

let FileListLink = ({list, styleName, iconClass, showFileName}) => list && list.length>0 ? list.map((n, i) =>
  <a className={styleName} href={Cs.getFileUrl(n)} target="_blank" key={i}>
  <img src={Cs.getIconByType(n)} className={iconClass}/>
   {showFileName &&<span className="m-l-5 font-13" data-tip={Cs.getFileName(n)}>{Cs.getFileName(n).substring(0,20)}</span>}
  </a>
): null

let VedioLink = ({url, styleName, iconClass, showFileName}) => url?(
   <a className={styleName} href={Cs.getFileUrl(url)} target="_blank">
      <i className={iconClass}/>
      {showFileName &&<span className="m-l-5 font-13" data-tip={url}>{Cs.extractHostName(url)}</span>}
   </a>
):null

export {ImageList, Image, ImagePlayList, FileListLink, VedioLink, ImageFlexList};