import React, { Fragment, useState, useEffect, useContext } from 'react'
import { useHistory, useParams, Prompt } from 'react-router-dom'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import InputTextField from '../../components/FormBuilder/InputComponents/InputTextField'
import InputTextArea from '../../components/FormBuilder/InputComponents/InputTextArea'
import InputSelect from '../../components/FormBuilder/InputComponents/InputSelect'
import InputRadio from '../../components/FormBuilder/InputComponents/InputRadio'
import InputCheckbox from '../../components/FormBuilder/InputComponents/InputCheckbox'
import InputDataTimePicker from '../../components/FormBuilder/InputComponents/InputDataTimePicker'
import InputColorPicker from '../../components/FormBuilder/InputComponents/InputColorPicker'
import InputMultiSelect from '../../components/FormBuilder/InputComponents/InputMultiSelect'
import InputMultiSelectSearch from '../../components/FormBuilder/InputComponents/InputMultiSelectSearch'
import InputMultiFileUpload from '../../components/FormBuilder/InputComponents/InputMultiFileUpload'
import InputFileUpload from '../../components/FormBuilder/InputComponents/InputFileUpload'
import InputMultiRating from '../../components/FormBuilder/InputComponents/InputMultiRating'
import InputRating from '../../components/FormBuilder/InputComponents/InputRating'
import InputTags from '../../components/FormBuilder/InputComponents/InputTags'
import InputExplanatoryText from '../../components/FormBuilder/InputComponents/InputExplanatoryText'
//import InputMapLocation from '../../components/FormBuilder/InputComponents/InputMapLocation'
import InputSlider from '../../components/FormBuilder/InputComponents/InputSlider'
import InputAutoComplete from '../../components/FormBuilder/InputComponents/InputAutoComplete'
import InputSignaturePad from '../../components/FormBuilder/InputComponents/InputSignaturePad'
import InputRichText from '../../components/FormBuilder/InputComponents/InputRichText'
import InputRichImageMap from '../../components/FormBuilder/InputComponents/InputRichImageMap'
import InputLottieSvg from '../../components/FormBuilder/InputComponents/InputLottieSvg'
import FormHelper from '../../components/FormBuilder/FormHelpers'
import Cs from '../../services/CommonService'
import Tabs, { TabPane } from 'rc-tabs'
import FormSubmit from '../../components/FormBuilder/FormSubmit'
import ErrorsList from '../../components/FormBuilder/ErrorsList'
import ExitForm from "../../components/Modals/ExitForm"
import PreLoadSpinner from "../../components/PreLoadSpinner"
import DataSourceListModal from "../DataSources/DataSourceListModal"
import useModal from "../../hooks/useModal"
import AddLocale from "../../components/Locale/AddLocale"
import GenericModal from "../../components/Modals/GenericModal"
import InputTableView from './InputComponents/InputTableView'
import {tabMoreIcon} from "../Common/Button"
import {TabLabel} from './FieldLabel'
import {usePageLocale} from '../../hooks/useLocale'
import { LocalizeContext } from "../../contexts/Localize/LocalizeStateProvider"
import {exitAlertMsg} from "../../constants/DataSourceList"

//let template = {}
let readOnly = true
let errors = {invalid:{}}
let noOfTabs = 0
let template = {}
let formTabs = []
let isFormSubmitted = false
let selectedFormField = {}

const DynamicForm = (props) => {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const {localeDispatch} = useContext(LocalizeContext)

  const [activeKey, setActiveKey] = useState('0')
  const [count, setCount] = useState(0)
  const [loading, setLoading] = useState(true)
  const [langauge, setLangauge] = useState(currentUser.current_locale)

  const {isOpen: isDataSourceModalOpen, toggleModal: toggleDataSourceModal} = useModal()
  const {isOpen: isFieldLabelOpen, toggleModal: toggleFieldLabelModal} = useModal()
  
  const {labelLocale} = usePageLocale((props.formFn.locale || langauge), 'formLocale')
  const {labelLocale:formCancelAlertLocale} = usePageLocale(currentUser.current_locale, 'formCancelAlert')

  let params = useParams()
  const history = useHistory()

  const forceUpdate = () =>{
    setCount(value => ++value)
  }

  props.formFn.refreshForm = () => forceUpdate()
  props.formFn.setLoading = (status) => setLoading(status)
  props.formFn.setLocale = (language) => {
    setLangauge(language)
  }
    
  const onSubmit = (draft, event) => {
    setLoading(true)
    event.stopPropagation()
    isFormSubmitted = true

    props.form.updated_by = currentUser.id
    props.form.draft = draft?true:false
    props.form.data = props.data

    if(props.formMode === 'create-form-submissions'){
      props.form.data.ct_time_to_submit = Cs.geTimeDiff(
        Cs.getCurrentDateTime(), props.formFn.ct_start_time
      )
      props.form.created_by = currentUser.id
      props.onCreate(props.form)
    }else{
      props.onUpdate(props.form)
    }
  }

  useEffect(() => {
    setLangauge(currentUser.current_locale)
    getFormTemplate()
    props.formFn.ds = {}
    return () => {
      template = {}
      formTabs = []
      noOfTabs = 0
      readOnly = true
	    errors = {invalid:{}}
	    isFormSubmitted = false
    }
  }, [props.formId, currentUser.current_locale])

  const getFormTemplate = () => {
    if(props.formId) {
      Cs.getFormTemplateById(
        props.formId, 
        {
          'locale': props.formFn.locale || langauge, 
          'is_public': props.formFn.is_public,
          'page_locale':{
            'data_source_ids':[3930, 3953],
            'locale':props.formFn.locale || langauge,
          }
        }
      ).then((data) => {
        setForm(data.form_template)
        localeDispatch({
          type: "UPDATE",
          payload: {
            'formLocale':(data.page_locale?.text_labels_forms),
            'formCancelAlert':(data.page_locale?.cancellation)
          }
        })
      })
    }else if(props.user_form_id){
      Cs.getUserForm({ id: props.user_form_id }).then((tf) => {
        setForm(tf)
      })
    }
    setLoading(false)
  }

  const setForm = (tf) =>{
    //template = tf;
    noOfTabs = tf.template_fields.length
    tf.fill_info = tf.fill_info || 'Please fill out the following fields'
    if(props.formMode === 'create-form-submissions'){
      readOnly = false
    }else if(props.formMode === 'edit-form-submissions'){
      readOnly = !(props.form.created_by === currentUser.id || props.formFn.editPermission)
    }else if(props.formMode === 'view-form-submissions'){
      tf.fill_info = 'View out the following fields'
      tf.post.details ='View Details'
      readOnly = true
    }
    template = tf
    props.setPost({...tf.post, help_id:tf.help_id})  

    props.formFn.ct_start_time = Cs.getCurrentDateTime()
  }

  let callback = function(key) {
    setActiveKey(key)
  }

  const nextTab = () =>{
    let i = (parseInt(activeKey) +1).toString()
    setActiveKey(i)
  }

  const previousTab = () =>{
    const i = (parseInt(activeKey)-1).toString()
    setActiveKey(i)
  }

  useEffect(()=>{
    /*try{
      setTimeout(function() {
        document.getElementById("form-container").scrollTop = 0;
      }, 1000);
    }catch(e){
      console.error("form auto scroll error")
    }*/
  }, [activeKey])

  const openDataSourceModal = (field) =>{
    selectedFormField = field
    toggleDataSourceModal()
  }

  const openFieldLabelModal = (field) =>{
    selectedFormField = field
    toggleFieldLabelModal()
  }

  const resetFieldError = (field) =>{
    delete errors[field.client_id]
    delete errors.invalid[field.client_id]
  }
  
  const getExitAlert = () =>{
    return `  ${formCancelAlertLocale(3)}\n\n  ${formCancelAlertLocale(4)}`
  }
  
  if(loading){
    return <div className="spinner"/>
  }

  formTabs = template.template_fields?.filter((parentField) =>{
    return FormHelper.checkFieldConditionality(parentField, props.data, true)
  }) 
  noOfTabs = formTabs?.length

  return (
    <Fragment>
      <div className="bg-white p20 p5-xss card-min-ht">
        <form className="row">
          <ErrorsList errors={errors} formFn={props.formFn} setActiveKey={setActiveKey}/>
          {!readOnly && !isFormSubmitted && <Prompt message={getExitAlert()}/>}
          <Tabs activeKey={activeKey} onChange={callback} moreIcon={tabMoreIcon}>
            {template.id && formTabs.map((parentField, key) => 
                <TabPane tab={<TabLabel tab={parentField} currentLocale={currentUser.current_locale}/>} key={key} forceRender="true">
                  <div id="form-container" className={screen.xs?'form-ht':''}>
                    <div className="row">
                      <ChildElementList pkey={key} childField={parentField.child_template_fields}
                        formId={props.formId} activeKey={activeKey} data={props.data} 
                        formFn={props.formFn} resetFieldError={resetFieldError}
                        fieldShowHideFn={props.fieldShowHideFn} currentUser={currentUser}
                        forceUpdate={forceUpdate} form={props.form} 
                        screen={screen} langauge={langauge}
                        openFieldLabelModal={openFieldLabelModal} 
                        openDataSourceModal={openDataSourceModal}
                        labelLocale={labelLocale}/>
                    </div>
                  </div>
                </TabPane>
              )
            }
          </Tabs>
          <div className="clerfix"/>
          <FormSubmit labelLocale={labelLocale} errors={errors} form={props.form} 
            readOnly={readOnly} onCancel={props.onCancel} onSubmit={onSubmit} 
            activeKey={activeKey} previousTab={previousTab} nextTab={nextTab} 
            noOfTabs={noOfTabs} formFn={props.formFn} forceUpdate={forceUpdate}
            formCancelAlertLocale={formCancelAlertLocale} isPopupMode={props.isPopupMode}
            showDraftBtn={template.show_draft_btn} getExitAlert={getExitAlert}>
          </FormSubmit>
          <div className="row">
            <small className="m-l-5 d-block">cT{template.updated_at}-{template.id}</small>
            <small className="m-l-5 lgrey d-block">
              <i className="m-r-5 far fa-copyright" aria-hidden="true"/>
              {new Date().getFullYear()} IPIPAL Inc. All rights reserved.
            </small>
          </div>
        </form>
      </div>
        
      {isDataSourceModalOpen && 
        <DataSourceListModal form_template_id={template.id} 
          data_source_id={selectedFormField.data_source_id} 
          journey_profile_id={params.journey_profile_id}
          toggleModal={toggleDataSourceModal}/>
      }

      {isFieldLabelOpen && 
        <GenericModal component={AddLocale} 
          defaultLabel={selectedFormField.label} 
          localeType="form_field" 
          translateFieldKey="label"
          title={selectedFormField.label} 
          toggleModal={toggleFieldLabelModal} 
          onCancel={toggleFieldLabelModal} 
          labelObj={selectedFormField}/>
      }
    </Fragment>
  )
    
}

const ChildElementList = ({forceUpdate, labelLocale, screen, form, currentUser, langauge, formId, activeKey, data, formFn, childField, pkey, fieldShowHideFn, resetFieldError, openDataSourceModal, openFieldLabelModal}) => childField.map((field, key) => {

    FormHelper.checkFieldValidity(field, data, errors, pkey)
      
    if(fieldShowHideFn){
      if(fieldShowHideFn(field)){
        resetFieldError(field)
        return
      }
    }

    const result = FormHelper.checkFieldConditionality(field, data)
    if(!result){
      resetFieldError(field)
      //return
    }

    if(activeKey!=pkey)return; 
    
    field.formTemplateId = formId

    let fieldProps = {
      'labelLocale':labelLocale,
      'field':field, 
      'formData':data, 
      'formFn':formFn,
      'readOnly':readOnly,
      'position': key,
      'openDataSourceModal':openDataSourceModal,
      'openFieldLabelModal':openFieldLabelModal,
      'currentLocale':langauge,
      'formId': formId,
      'renderField':result,
      'forceUpdate':forceUpdate,
      'errors':errors
    }

    switch (field.component) {
      case 'textInput':
        FormHelper.onChangeExp(field, data, formFn.ds)
        return <InputTextField key={key} {...fieldProps} />
        break;
      case 'textArea':
        return <InputTextArea key={key} {...fieldProps} />
        break;
      case 'timepicker':
        return <InputDataTimePicker key={key} {...fieldProps} />
        break;
      case 'datepicker':
        return <InputDataTimePicker key={key} {...fieldProps} />
        break;
      case 'checkbox':
        return <InputCheckbox key={key} {...fieldProps} />
        break;
      case 'radio':
        return  <InputRadio key={key} {...fieldProps} />
        break;
      case 'select':
        return <InputSelect key={key} screen={screen} {...fieldProps}/>
        break;
      case 'multi_select':
        if(field.is_dynamic_data){
          return <InputMultiSelectSearch key={key} {...fieldProps} />  
        }else{
          return <InputMultiSelect screen={screen} key={key} {...fieldProps}/>
        }
        break;
      case 'slider':
        return <InputSlider key={key} {...fieldProps} />
        break;
      case 'counter':
        fieldProps.field.field_validation_options = {validation_type:'counter'}
        return <InputTextField key={key} {...fieldProps} />
        break;
      case 'autocomplete':
        return <InputAutoComplete key={key} {...fieldProps} />
        break;
      case 'imageupload':
        return <InputFileUpload key={key} formId={form.id} {...fieldProps} />
        break;
      case 'multi_file_upload':
        return <InputMultiFileUpload key={key} formId={form.id} {...fieldProps} />
        break;
      case 'richtext':
        return <InputRichText key={key} formId={form.id} {...fieldProps} user={currentUser}/>
        break;
      case 'explanatory_text':
        return <InputExplanatoryText key={key} {...fieldProps} />
        break;
      case 'color_picker':
        return <InputColorPicker key={key} {...fieldProps} />
        break;
      case 'rating':
        if(field.data_source_id){
          return <InputMultiRating key={key} {...fieldProps} />
        }else{
          return <InputRating key={key} {...fieldProps} />
        }
        break;
      case 'input_tags':
        return <InputTags key={key} {...fieldProps} />
        break;
      case 'signature':
        return <InputSignaturePad key={key} {...fieldProps} />
        break;
      case 'rich_image_map':
        return <InputRichImageMap key={key} user={currentUser} {...fieldProps} />
        break;
      case 'lottie_svg':
        return <InputLottieSvg key={key} user={currentUser} {...fieldProps} />
        break;
      case 'table_view':
        return <InputTableView key={key} screen={screen} user={currentUser} {...fieldProps} />
        break;
      default:
        console.log(field.component)
        return <Fragment key={key}>Not Implemented</Fragment>
      }
    }
  )

export default DynamicForm;