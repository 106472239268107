import { useState, useEffect, useContext} from 'react';
import {getLocale} from '../components/FormBuilder/FieldLabel';
import { LocalizeContext } from "../contexts/Localize/LocalizeStateProvider";

const usePageLocale = (currentUserLocale, cacheKey, isOptions=true) => {
  const {localeState, localeDispatch} = useContext(LocalizeContext)
  const [pageLocales, setPageLocales] = useState();

  useEffect(() => {
    setPageLocales(isOptions?localeState[cacheKey]?.options:localeState[cacheKey])
  }, [localeState])
  
  //const [userLocale, setUserLocale] = useState(currentUserLocale);

  /*useEffect(() => {
    if(cacheKey){
      const local = localStorage.getItem("ct_locale_"+cacheKey)
      setPageLocales(local?JSON.parse(local):{})
    }
  }, [])

  useEffect(() => {
    if(cacheKey && Object.keys(pageLocales).length > 0){
      localStorage.setItem("ct_locale_"+cacheKey, JSON.stringify(pageLocales));
    }
  }, [pageLocales])*/

  const labelLocale = (key, localeKey="label") => {
    return getLocale(pageLocales?.[key], currentUserLocale, localeKey)
  }

  const getObjLocale = (object) => {
    return getLocale(object, currentUserLocale)
  }

  return {
    labelLocale,
    getObjLocale,
    setPageLocales
  }
};

export {usePageLocale};