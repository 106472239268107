import React, {Fragment, useState, useEffect, memo} from "react"
import {Link} from "react-router-dom"
import moment from 'moment'
import importScript from '../../hooks/importScript'
import Cs from "../../services/CommonService"

const DiffMatchPatch = ({oldData, newData, formFields}) => {

  useEffect(() => {
    importScript('/scripts/diff_match_patch.min.js', setInit, window.diff_match_patch)
  }, [])

  const setInit = () =>{
    const text1 = objToString(oldData.data, oldData.data_source)
    const text2 = objToString(newData.data, oldData.data_source)
    let dmp = new window.diff_match_patch()
    dmp.Diff_Timeout = parseFloat(1)
    dmp.Diff_EditCost = parseFloat(4)
    let d = dmp.diff_main(text1, text2)
    let ds = dmp.diff_prettyHtml(d)
    document.getElementById('outputdiv').innerHTML = ds 
  }

  const objToString = (data={}, data_source={}) =>{
    let txt = ''
    formFields.forEach((value, index) => {
      if(['richtext', 'textInput', 'textArea', 'timepicker'].includes(value.component)){
        txt += `\n${index+1}) ${value.label}\n`
        txt += Cs.stripeHtml(data[value.client_id])
      }else if(['select', 'radio'].includes(value.component)){
        txt += `\n${index+1}) ${value.label}\n`
        txt += (data_source[value.client_id]?.label)
      }else if(['multi_select'].includes(value.component)){
        txt += `\n${index+1}) ${value.label}\n`
        txt += (data_source[value.client_id]?.map((d)=> d.data.label))
      } 
      txt += '\n'
    })

    return txt
  }

  /*const objToString = (data) =>{
    let txt = ''
    for (const [key, value] of Object.entries(data)) {
      if(typeof value === 'string' && isNaN(value) && !data[key+'_array']){
        txt += ('\n\n' + Cs.stripeHtml(value))
      } 
    }

    return txt
  }*/

  return(
    <div id="outputdiv"/>
  )
}

export default memo(DiffMatchPatch);