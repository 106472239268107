import { useState, useEffect } from 'react';

const useOnLine = () => {
  
  const [isOnline, setOnline] = useState(window.navigator.onLine);
  const [isConnectionOk, setConnectionOk] = useState();

  useEffect(()=>{
    window.onoffline = (e) => {
      setOnline(false)
      console.log("The network connection is online.")
    }

    window.ononline = (e) => {
      setOnline(true)
      console.log("The network connection is online.")
      if (navigator.connection.downlink < 5){
        setConnectionOk(true)
      } else {
        setConnectionOk(false)
      }
    }
    
  }, [])

  return {
    isOnline,
    isConnectionOk
  }

};

export default useOnLine;