import React, {useContext} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CommonService from "../../services/CommonService";
import env from '../../env';
import Footer from '../Footer/Footer'
import { Fragment } from "react";
import { Link, useHistory } from "react-router-dom";

class ErrorBoundary extends React.Component {

  static contextType = AuthContext;

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    const {state: {user: currentUser },} = this.context

    // Catch errors in any components below and re-render with error message
    console.log(currentUser)
    console.log(error)
    console.log(errorInfo)

    let req = {
      method: 'post',
      url: `${env.health_backend}health/client_errors`,
      data: {
        error: {
          msg:error.message,
          //stack:error.stack
        },
        errorInfo: errorInfo,
        user_id: currentUser && currentUser.id,
        url: window.location.href
      }
    }

    this.setState({
      error: error,
      errorInfo: errorInfo
    })

    CommonService.useAxios(req)
    // You can also log error messages to an error reporting service here
  }
  
  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <Fragment>
          <div className="row">
            <div className="col-xs-12 col-md-12">
              <div className="content m-t-10 card-container p-7 bg-white shadow-small">
                <div className="row">
                <h5>Oops. Something is not correct.</h5>
                </div>
                  <h5>Sorry for the inconvenience.</h5>
                  <h5>
                    <a href="/health/table">Click to go back to the HOME SCREEN</a> 
                  </h5>   
              </div>
            </div>
          </div>
          <Footer />
        </Fragment>
      );
    }
    
    // Normally, just render children
    return this.props.children;
  }  
}

export default ErrorBoundary;