import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../PreLoadSpinner"
import DynamicForm from "../../FormBuilder/DynamicForm"
import FormTemplateId from "../../../constants/FormTemplateList"
import querystringify from "querystringify"
import FormContainer from '../../FormBuilder/FormContainer'
import GenericModal from '../../Modals/GenericModal'
import useModal from "../../../hooks/useModal"
import TrainingSessionsService from './TrainingSessionsService'

let formMode = null
let trainingSession = {}
let data = {}
let formFn = {}

function TrainingSessionForm(props) {
  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const params = props.match.params
  const path = props.match.path
  const history = props.history

  const get = () => {
    TrainingSessionsService.get(params.id).then((res) => {
      if (res.status == 200) {
        trainingSession = res.data.training_session
        data = trainingSession.data || {}
        setFormRendered(true)
      }
    }).catch((res) => {
      trainingSession = null
    })
  }

  useEffect(() => {
    trainingSession = {}
    data = {}
    formFn = {form_type: "health.training_session"}
    formMode = "create-form-submissions"
    data = {}
    if (params.id) {
      get()
      formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (trainingSession) => {
    trainingSession.training_program_id = params.training_program_id
    TrainingSessionsService.create(trainingSession).then(({status, data}) => {
      if (status == 201) {
        onSubmitPageRedirect()
      }
    })
  }

  const update = (trainingSession) => {
    TrainingSessionsService.update(trainingSession).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = (cancel) => {
    if(props.onSuccess){
      props.toggleModal()
      props.onSuccess()
    }else{
      history.push('/list/training_programs')
    }
  }

  const onCancel = () => onSubmitPageRedirect(true)

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode}
          formId={FormTemplateId.TrainingSessionForm}
          form={trainingSession}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
      </FormContainer>
    )
  } else {
    return null
  }
  
}

export default TrainingSessionForm;