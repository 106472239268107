import React, {Fragment, useState, useContext, useEffect} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../Common/SortDirection'
import Cs from "../../services/CommonService"
import env from "../../env"
import DiffMatchPatch from './DiffMatchPatch'

let requestParams ={}
const DataLogList = (props) => {
  useStyle('table')
  
  const {state: { user: currentUser }, dispatch} = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])
  const [log, setLog] = useState()
  const [logIndex, setLogIndex] = useState()

  useEffect(() => {
    requestParams = {
      page:1,
      per_page:100,
    } 
    getAll()
  }, [])

  const getAll = () =>{
    Cs.useAxios({
      method: 'get',
      url: `${env.health_backend}health/logs`,
      params: {'loggable_type':props.logType, 'loggable_id':props.logItem.id}
    }).then((res)=>{
      if(res.status == 200){
        setList(res.data.audits)
        setIsLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const get = (id, index) => {
    setLog(null)
    setIsLoading(true)
    setLogIndex(index)
    Cs.useAxios({
      method: 'get',
      url: `${env.health_backend}health/logs/${id}`,
    }).then((res)=>{
      if(res.status == 200){
        setLog(res.data.audit)
        setIsLoading(false)
      }
    })
  }

  const tableData = list.map((o, k) => {
    return(
      <tr key={k}>
        <td className="text-center">
          {o.id}
        </td>
        <td className="ellipsis-lg text-center">
          {o.user.name}
        </td>
        <td className="text-center">
          {Cs.formatUpdateDate(o.created_at)}
        </td>
        <td className="text-center">
          <span className="far fa-edit font-15" onClick={()=>get(o.id, k)}/>
        </td>
      </tr>
    )
  })

  return (
    <Fragment>
      {isLoading && <span className="spinner"/>}

      <div className="page-content row">
        <div className="table-responsive">
          <table className="shadow-small">
            <thead>
              <tr className="white bg-black">
                <th onClick={()=>sort('record_id')} className="p-2 bold-400 text-center miw-95">
                  ID 
                  <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                </th>
                <th onClick={()=>sort('user.name')} className="p-2 bold-400 text-center miw-95">
                  User 
                  <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
                </th>
                <th onClick={()=>sort('created_at')} className="p-2 bold-400 text-center miw-95">
                  Date
                  <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
                </th>
                <th className="p-2 bold-400 text-center">
                  Options
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData}
            </tbody>
          </table>
        </div>

        {log && 
          <div className="col-xs-12 p-20">
            <h4>
              {log.old_val ? 
                <>Comparing version {log.id} to {list[logIndex-1]?.id || log.id}</>
                :
                'Version 1'
              }
            </h4>
            <DiffMatchPatch 
              oldData={log.old_val}
              newData={log.new_val} 
              formFields={props.formFields}
            />
          </div>
        }
        
      </div>
      
    </Fragment>
  )

}

export default DataLogList;