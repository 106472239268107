import BaseService from "../../../services/BaseService"
import env from "../../../env"
import axios from "axios"

class DailyMeasurementsService extends BaseService {

  report(req){
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}health/daily_measurements/reports`, req).then((res) => {
        // handle success
        resolve(res.data)
      })
    })
  }
  
}

export default new DailyMeasurementsService('health_backend', 'health/daily_measurements')