import React, { Fragment, useState, useEffect, useContext, useMemo} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import { CacheContext } from "../../contexts/Cache/CacheStateProvider"
import AuthenticationService from "../../services/AuthService"
import NotifiableRoleService from "./NotifiableRoleService"
import useStyle from '../../hooks/useStyle'
import ReactDOM from 'react-dom'
import Cs from '../../services/CommonService'
import env from "../../env"
import { CheckBox } from "../Common/FormInput"

const RoleAndUserFilter = ({toggle, exists}) => {
  
  const {state: { user: currentUser }} = useContext(AuthContext)
  const {cacheState:{roleAndUserFilters}, cacheDispatch } = useContext(CacheContext)
  const {roles=[], orgMembers=[], isLoaded=false} = roleAndUserFilters || {}
  
  const [loading, setLoading] = useState(false)

  useEffect(() => {  
    if(!isLoaded)
      getRoleAndUsers()
  }, [])

  const getRoleAndUsers = () =>{
    setLoading(true)
    const req = {
      role_params:{
        default_fields:'ignore',
        fields:['id', 'role'],
        roleable_type: 'infant_journey',
        organization_id: currentUser.current_organization_id,
      },
      organization_member_params:{
        default_fields:'ignore',
        fields:['id', 'email', 'user_id'],
        organization_id: currentUser.current_organization_id,
      }
    }
    NotifiableRoleService.getAll(req).then(({status, data})=>{
      if(status === 200){
        cacheDispatch({
          type: "UPDATE",
          payload: {
            roleAndUserFilters:{
              roles: data.roles, 
              orgMembers: data.organization_members,
              isLoaded:true
            }
          }
        })
        setLoading(false)
      }
    })
  }

  return (
    <>
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Roles
        </h5>

        <CheckBox list={roles || []} 
          labelKey="role" valueKey="id" 
          toggle={toggle} exists={exists} 
          name="role_id"/> 
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Org Members
        </h5>

        <CheckBox list={orgMembers || []} 
          labelKey="email" valueKey="user_id" 
          toggle={toggle} exists={exists} 
          name="user_id"/> 
      </div>
    </>
  )

}

export default RoleAndUserFilter;