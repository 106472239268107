import env from '../../../../../env';
import axios from 'axios';
import BaseService from '../../../../../services/BaseService';

class SubFacilityService extends BaseService {
	getMemberSubFacilities(req){
	    return new Promise((resolve, reject) => {
	      axios.get(`${env.health_backend}health/get_member_sub_facilities`, {params: req}).then((res) => {
	        // handle success
	        resolve(res);
	      })
	    })
	}
}

export default new SubFacilityService('health_backend','health/medical_sub_facilities');