import React, {useState, useContext, useEffect, Fragment, useMemo} from "react"
import ReactTooltip from 'react-tooltip'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import Role from '../../HealthJourney/Roles/Role'
import RoleService from "../../HealthJourney/Roles/RoleService"
import InvitedUserService from "../../HealthJourney/InvitedUsers/InvitedUserService"
import ConfirmMenu from '../../Modals/ConfirmMenu'
import AddRole from '../../HealthJourney/Roles/AddRole'
import {Link} from "react-router-dom"
import useModal from '../../../hooks/useModal'
import useStyle from '../../../hooks/useStyle'
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import Title from "../GenericPortalPage/Title"
import InvitedUserModal from '../../HealthJourney/InvitedUsers/InvitedUserModal'
import CommonService from '../../../services/CommonService'
import useSort from '../../../hooks/useSorting'
import SortDirection from '../../../components/Common/SortDirection'
import GenericModal from "../../Modals/GenericModal"
import NotifiableRolesPopup from '../../NotifiableRoles/NotifiableRolesPopup'
import RoleSelectList from './RoleSelectList'
import {NotificationPopup} from '../../../components/Common/NotificationPopup'
import SideMenuBar from "../../Common/SideMenuBar"
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { AddBtn, FontAwesomeBtn } from "../../Common/Button"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../Common/ImageIcons"
import {usePageTitle} from '../../../hooks/pageHead'
import CheckAccess from './CheckAccess' 

let requestParams = {}
let newRole = {}
let dataSources = {}
let rolesUser = []
let newInviteUser = {}
let selectedInviteUser = {}
let selectedRole = {}

const RoleList = (props) => {
  useStyle('/css/tile-components.css', true)
  usePageTitle('Role List')
  
  let params = props.match.params
  let project = props.location.state

  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const currentOrgMember = currentUser?.current_member || {}
  const [roles, setRoles] = useState([])
  const [orgJourneyComponents, setJourneyComponents] = useState([])
  const [forceUpdate, setForceUpdate] = useState(false)
  const [showTableView, setShowTableView] = useState(true)

  const {isOpen:isDeletePopupOpen, toggleModal:toggleDeletePopup} = useModal()
  const {isOpen:isNewRolePopupOpen, toggleModal:toggleNewRolePopup} = useModal()
  const {isOpen:isInviteUserRolePopupOpen, toggleModal:toggleInviteUserPopup} = useModal()
  const {isOpen:isInviteUserDeletePopupOpen, toggleModal:toggleInviteUserDeletePopup} = useModal()
  const {isOpen:isReportRoleOpen, toggleModal:toggleReportRoleModal} = useModal()

  const permission = useMemo(() => 
    CheckAccess.getMenuPermission(currentUser, 'roles')
  , [currentUser.id])

  useEffect(() => {  
    ReactTooltip.rebuild()
    requestParams = { 
      'organization_id':params.organization_id,
      'roleable_id': params.roleable_id,
      'roleable_type': params.roleable_type,
      'subscription_id':params.subscription_id,
      'special_fields':['members_count'],
      'add_journey_components':true,
      'data_source_params':{
        "data_source_id":[3530, 3826]
      }
    }
    getRoleList()
  }, [props.searchString])

  let getRoleList = () => { 
    if(permission.read){
      RoleService.getAll(requestParams).then(({status, data})=>{
        if(status == 200){
          dataSources = data.data_source_list;
          rolesUser = data.roles_list;
          //dataSources.permissions = CommonService.listToObj(dataSources.maternity_infant_nodes.options, 'sid')
          //dataSources.permissions.quick_access = {sid:'quick_access', label:'Summary Tiles'} 
          if(params.roleable_type === 'infant_journey'){
            dataSources.infant_ppt = CommonService.listToObj(data.journey_components.element_ppt_mcj?.options, 'sid')
            dataSources.infant_process = CommonService.listToObj(data.journey_components.pins_process_mcj?.options, 'sid')
            dataSources.side_menu = CommonService.listToObj(data.journey_components.section_menu_nav_bar?.options, 'sid')
            dataSources.portal = CommonService.listToObj(data.journey_components.maternity_infant_nodes?.options, 'sid')
            setJourneyComponents({
              'element_ppt_mcj': data.journey_components.element_ppt_mcj,
              'pins_process_mcj': data.journey_components.pins_process_mcj,
              'side_menu':data.journey_components.section_menu_nav_bar,
              'mcj_portal':data.journey_components.maternity_infant_nodes,
            })  
          }
          setRoles(data.roles)
        }
      })
    }
  }

  const {sort} = useSort(requestParams, getRoleList)

  const deleteRole = (e) => {
    RoleService.delete(selectedRole.id).then((res) => {
      if(res.status==204){
        setRoles(roles.filter(r => r.record_id !== selectedRole.record_id));
        selectedRole = null
      }
    })    
    toggleDeletePopup(e)
  }

  const deleteInvitedUser = (e) => {
    e.preventDefault();
    InvitedUserService.delete(selectedInviteUser.id).then((res) => {
      if(res.status==204){
        let filteredUsers = selectedRole.role_users.filter(r => r.id !== selectedInviteUser.id)
        let filteredRoles = roles.filter(r => r.record_id !== selectedRole.record_id)
        selectedRole.role_users = filteredUsers;
        setRoles([selectedRole].concat(filteredRoles))
      }
    })  
    toggleInviteUserDeletePopup(e);  
  }

  const setEdit = (e, role) => {
    newRole = role;
    toggleNewRolePopup(e);
  }
  
  const addNewRole = (e, newRole) => {
    e.preventDefault();
    if(newRole.id){
      RoleService.update(newRole).then((res) => {
        if(res.status==204){
          restModal(e);
          getRoleList()
          //setRoles([...roles])
        }
      })  
    }else{
      newRole.organization_id = params.organization_id
      newRole.created_by = currentUser.id
      newRole.journey_proile_id = params.journey_proile_id
      newRole.roleable_id = params.roleable_id
      newRole.roleable_type = params.roleable_type

      RoleService.create(newRole).then((res) => {
        if(res.status==201){
          newRole.id = res.data.role.id;
          restModal(e);
          getRoleList()
          //setRoles([...roles, newRole])
        }
      })  
    }
  }

  const restModal = (e) =>{
    newRole = {};
    toggleNewRolePopup(e);
    //getRoleList();
  }

  const addInviteUser = (e, new_invite_user) => {
    e.preventDefault();
    if(new_invite_user.id){
      InvitedUserService.update(new_invite_user).then((res) => {
        if(res.status==204){
          getRoleList()
          restModalUser(e);
        }
      })  
    }else{
      new_invite_user.created_by = currentUser.id;
      new_invite_user.journey_category_id = params.journey_category_id;
      new_invite_user.journey_proile_id = params.journey_proile_id;
      //new_invite_user.family_id = currentUser.domain_info.id;
      
      InvitedUserService.create(new_invite_user).then((res) => {
        if(res.status==201){
          getRoleList()
          restModalUser(e)
        }
      })  
    }
  }

  const switchTableView = () =>{
    setShowTableView(!showTableView)
  }

  const restModalUser = (e) =>{
    e.stopPropagation();
    newInviteUser = {};
    toggleInviteUserPopup(e);
  }

  const deleteInviteUser = (e, invited_user, role) =>{
    selectedInviteUser = invited_user;
    selectedRole = role;
    toggleInviteUserDeletePopup(e)
  }

  const addNotifiableRole = (item) => {
    selectedRole = item
    toggleReportRoleModal()
  }

  const selectRole = (role) => selectedRole = role;

  /*const Menu = ({role}) => (
    <div className="dropup pull-right p-r-15">
      <button className="dropbtn">Menu</button>      
      <div className="dropup-content">
        <a href="#" onClick={e => {setEdit(e, role)}}>Edit</a>
        <a href="#" onClick={e => {toggleDeletePopup(e, role)} }>Delete</a>
      </div>
    </div>
  )*/
  
  const updateReportRole = (report_role_ids) =>{
    RoleService.update({
      id: selectedRole.id,
      report_role_ids:report_role_ids
    }).then((res) => {
      if(res.status==204){
        selectedRole.report_role_ids = report_role_ids
        setRoles([...roles])
      }
    })
  } 

  /*if(roles.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  const tableData = roles.map((o, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray">
          {o.record_id}
        </TD>
        <TD className="fw500">
          {o.role}
        </TD>
        <TD>
          {CommonService.formatUpdateDate(o.created_at)}
        </TD>
        <TD>
          {o.description}
        </TD>
        <TD>
          {permission.write &&
            <div className="flex aligncenter">
              <LinkFontAwesomeIcon 
                id="edit_role" tooltip="Edit role" onSelect={e=>setEdit(e, o)} 
                css="fas fa-edit white"/>
              <LinkFontAwesomeIcon 
                id="organization_members" tooltip="Users" 
                link={`/${o.role}/members/${params.organization_id}/false?role_id=${o.record_id}`} 
                css="fas fa-users white" count={o.members_count}/>
              <LinkDeleteIcon 
                id="delete_role" tooltip="Delete role" 
                onSelect={e=>{selectRole(o);toggleDeletePopup(e, o)}}/>
            </div>
          }
        </TD>
      </TR>
    )
  })

  if(!currentUser?.current_subscription){
    return <NotificationPopup 
      message="No Active Subscription" 
      onSuccess={e=>props.history.goBack()}/>
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir rgap-5-xs">
              <div className="app-title m-r-20">Roles</div>
              <div className="font-15">
                List of Roles - {roles.length}
              </div>
            </div>

            <div className="flex aligncenter rgap-5-xs">
              {permission.write &&
                <span onClick={()=>restModal()}
                  data-tip="Add New Role" 
                  className="bg-highlight round-btn float-right m-l-15 m-t-4">
                    <i className="fas fa-plus"/>
                </span>
              }
              <span id="back_btn" onClick={()=>props.history.goBack()} 
                data-tip="Back to Journey Home" 
                className="bg-highlight round-btn float-right m-l-15 m-t-4">
                  <i className="fas fa-home"/>
              </span>
              <span id="roles_btn" onClick={()=>switchTableView()}  
                data-tip="Toggle Table View" 
                className="bg-highlight round-btn float-right m-l-15 m-t-4">
                  <i className="fas fa-table"/>
              </span>
            </div>
          </div>

        <div className="app-content-section m-t-30">
          <div className="row">
            { !showTableView && roles && roles.map((role, i) => (
              <div className="col-md-4" key={i}>
                <Role role={role} setEdit={setEdit} setDelete={toggleDeletePopup} 
                  addNotifiableRole={addNotifiableRole} 
                  dataSources={dataSources} restModalUser={restModalUser} 
                  deleteInviteUser={deleteInviteUser} selectRole={selectRole}
                  orgJourneyComponents={orgJourneyComponents} permission={permission}
                  organizationId={params.organization_id}/>
              </div>
            ))}
          </div>
        
        { showTableView &&
          <Table className="shadow-small">
            <THead>
              <TR>
                <TH onClick={e=>sort('record_id')}>
                  ID 
                  <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('role')}>
                  Role 
                  <SortDirection sort_column={requestParams.sort_column} column="role" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('updated_at')}>
                  Date
                  <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('description')}>
                  Description
                  <SortDirection sort_column={requestParams.is_active} column="description" reverse={requestParams.reverse}/>
                </TH>
                <TH>
                  Action
                </TH>
              </TR>
            </THead>
            <TBody>
              {tableData}
            </TBody>
          </Table>
        }
        </div>
      </div>
      </div>
      {isReportRoleOpen && <GenericModal isOpen={isReportRoleOpen} 
        toggleModal={toggleReportRoleModal}
        title="Report Roles" component={RoleSelectList} roles={roles} 
        selectedRoles={selectedRole.report_role_ids || []} onSelect={updateReportRole}/>
      }
      <ConfirmMenu isOpen={isDeletePopupOpen} toggleModal={toggleDeletePopup} 
        success={deleteRole}/>

      {isNewRolePopupOpen && <AddRole role={newRole} isOpen={isNewRolePopupOpen} 
        toggleModal={toggleNewRolePopup} addNewRole={addNewRole} dataSources={dataSources} 
        orgJourneyComponents={orgJourneyComponents} currentOrgMember={currentOrgMember}/>
      }
      {isInviteUserRolePopupOpen && <InvitedUserModal inviteUser={newInviteUser} 
        isOpen={isInviteUserRolePopupOpen} toggleModal={toggleInviteUserPopup} 
        addInviteUser={addInviteUser} dataSources={dataSources} roles={rolesUser} 
        flag={true}/>
      }
      <ConfirmMenu isOpen={isInviteUserDeletePopupOpen} 
        toggleModal={toggleInviteUserDeletePopup} success={deleteInvitedUser}/>
      
      <SideMenuBar pageIds={['roles']}/>
    </Fragment>
  )

}

export default RoleList;