import env from '../../env';
import axios from 'axios';
import BaseService from "../../services/BaseService";

class OfflineService extends BaseService {
  
  getJourneyComponent(req){
    return new Promise((resolve, reject) => {
      let u = env.health_backend+'health/offline/components'

      axios.get(u, {params:req}).then(function(res){
        resolve( res );
      })
    })
  }

  getRequestQue(req){
    return new Promise((resolve, reject) => {
      let u = env.health_backend+'offline/requests'

      axios.get(u, {params:req}).then(function(res){
        resolve( res );
      })
    })
  }

}

export default new OfflineService('health_backend', 'health/offline_data');