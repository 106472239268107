import React, {Fragment,useState} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../hooks/useStyle';
import useSort from '../../../hooks/useSorting';
import SortDirection from '../../../components/Common/SortDirection'
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from '../../Common/arrayMove';
import Cs from "../../../services/CommonService";
import {PillDsList,PillDsField} from '../../HealthJourney/UserRoles/NotificationPill'
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../Common/ImageIcons"

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt"></span>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
})

const DigitalContentTableList = ({addDelete, getDigitalList, digitalContentList, requestParams, selectedDigitalContent, updateStatus}) => {

  const {sort} = useSort(requestParams, getDigitalList);
  
  const [currentAssessment, setCurrentAssessment] = useState();

  const TableData = ({digitalContentList}) => digitalContentList && digitalContentList.map((o, k) =>
    <TR key={k}>
      <TD className="text-gray" data-title="Id">{o.record_id}</TD>
      <TD className="fw500" data-title="Title">{o.data.heading || o.data.record_title || o.data.title}</TD>
      <TD data-title="Created Date">{Cs.formatUpdateDate(o.updated_at)}</TD>
      <TD data-title="Content Type">{o.content_type.replace(/_/g, ' ')}</TD>
      <TD data-title="Content Type">
      { o.data_source.web_page?.length > 0 ? <PillDsList list={o.data_source.web_page} dataKey="label" style="pill white bg-lgrey"/>
      :<PillDsField field={o.data_source.web_page} dataKey="label" style="pill white bg-lgrey" />
      }</TD>
      <TD data-title="Created By">{o.user?.name}</TD>
      <TD data-title="Preview">
        <Fragment>
          <Link id="question_form_icon" to={`/content_details/${o.id}?`} target="_blank"
            data-tip="Preview Question Form">
              <i className="far fa-file"/>
          </Link>
        </Fragment>
      </TD>
      <TD data-title="Active">
        <span id="publish_icon" className={o.is_active?'fas fa-check-circle':'far fa-circle'} action="Pub Active" onClick={(e)=> {selectedDigitalContent = o;updateStatus(e,selectedDigitalContent)}}/>
      </TD>
      <TD data-title="Options">
        <div className="flex aligncenter">
          <LinkFontAwesomeIcon id="edit_digital_contents" tooltip="Edit digital contents" link={`/digital_contents/edit/${o.id}`} css="fas fa-edit white">
          </LinkFontAwesomeIcon>
          <LinkDeleteIcon id="delete_digital_contents" tooltip="Delete digital contents" onSelect={(e)=>addDelete(e, o)}>
          </LinkDeleteIcon>
        </div>
      </TD>
    </TR>
  )

  return (
    <div className="col-xs-12 m-t-10 table-fix-head">
      <SortableContainer distance={1} lockAxis="y" useDragHandle>
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH onClick={e=>sort('record_id')}>Id
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('record_title')}>Title
                <SortDirection sort_column={requestParams.sort_column} column="record_title" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('updated_at')}>Updated Date 
                <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('content_type')}>Content Type
                <SortDirection sort_column={requestParams.sort_column} column="content_type" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('web_page')}>Web Page
                <SortDirection sort_column={requestParams.sort_column} column="web_page" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('created_by')}>Created By
                <SortDirection sort_column={requestParams.sort_column} column="created_by" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('record_id')}>Preview
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('is_active')}>Active
                <SortDirection sort_column={requestParams.sort_column} column="is_active" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('created_at')}>Options
                <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/> 
              </TH>
            </TR>
          </THead>
          <TBody>
            <TableData digitalContentList={digitalContentList} />
          </TBody>
        </Table>
      </SortableContainer>
    </div>
  )

}

export default DigitalContentTableList;