import React, {useState, Fragment} from 'react';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import CommonService from '../../../services/CommonService';
import ImageEditor from "../../ImageEditor/ImageEditor";
import GenericModal from "../../Modals/GenericModal";
import useModal from "../../../hooks/useModal";
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, Description, requiredMsg} from '../FieldLabel'
import {CueTip} from '../../../components/Common/CueTooltip'

let selectedFile = null;

const InputMultiFileUpload = ({renderField, labelLocale, field, position, formData, readOnly, errors, formFn, isFormWizard, formId, openFieldLabelModal, currentLocale}) => {
  if (typeof formData[field.client_id] === 'string') {
    formData[field.client_id] = [formData[field.client_id]]
  }

  formData[field.client_id] = formData[field.client_id] || [];
  
  const [model, setModel] = useState(formData[field.client_id]);
  const [showError, setShowError] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [fileVirusError, setFileVirusError] = useState(false);
  const [showUploadProgress, setShowUploadProgress] = useState(false);
  const { isOpen:isImageEditorOpen, toggleModal:toggleImageEditorModal } = useModal();
  
  if(!renderField){
    return null
  }

  let inputAttributes = {
    'type':'file',
    'id': 'multi_upload_selector_'+field.client_id,
    'name': field.client_id,
    'disabled':(readOnly || field.read_only)
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let validation = {
    required: field.required
  }

  let privacyType = field.privacy_type || 'private';
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);
  
  const onChange=event=>{
    if('image/svg+xml image/gif'.includes(event.target.files[0].type)){
      uploadMultiFileToUrl(event.target.files, event.target) 
    }else if(field.edit_image){
      selectedFile = event.target.files[0]
      toggleImageEditorModal()
      return
    }else{ 
      uploadMultiFileToUrl(event.target.files, event.target)
    }
  }

  const uploadMultiFileToUrl = (files, ele, blob) => {
    try {
      let file = null;
      if(files){
        let FileSize = files[0].size / 1024 / 1024; // in MB
        if (FileSize > 2) {
          ele.value = null
          setFileSizeError(true)
          return
        }else{
          file = files[0]
          setFileSizeError(false)
        }  
      }else if(blob){
        file = blob
        toggleImageEditorModal()
      }else{
        return
      }

      if(files.length>0){
        setShowUploadProgress(true)
        let imageData = {
          'fileable_type':formFn.form_type, 
          'fileable_id':formId,
          'journey_profile_id':formFn.journey_profile_id
        }
        for (let file of files) {
          CommonService.uploadFileToUrl(file, privacyType, imageData).then((res)=> {
            if(res.status == 201){
              let data = res.data;
              setModel([...model, data.file_upload.url])
              formData[field.client_id].push(data.file_upload.url);
              setError();
            }else if(res.status == 422){
              setFileVirusError(true)
            }
            ele.value = null;
            setShowUploadProgress(false)
          }, (err)=>{
            setShowUploadProgress(false)
          })
        }
      }
    } catch (e) {
      setShowUploadProgress(false)
      console.log(e)
    }
  }

  const removeImageFromList = (file, idx, event) => {
    try {
      event.stopPropagation();
      CommonService.deleteFileFromUrl(file).then((res) => {
        if(res.status == 204 || res.status == 404){
          setModel(model.filter(i => i !== file));
          formData[field.client_id].splice(idx, 1);
          setError();
        }
      })
    } catch (e) {
      alert(e);
    }
  }

  const setError = () => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = model.length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      setShowError(!showError);
      formFn.refreshFormSubmit();
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{requiredMsg}</div>
        </div>
      )
    }
    return null;
  }

  const FileError = () => {
    return(
      <div className="errormsg">
        {fileSizeError && <div>File Size is Large. Max Size 2MB.</div>}
        {fileVirusError && <div>File Is infected.</div>}
      </div>
    )
  }

  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);

  const ImageList = model.map((file, k) =>  
    <div className="upload-sm-card overflow" key={k}>
      <span className="upload-delete" onClick={e => removeImageFromList(file, k, e)}>×</span>
        <a href="#">
          <img src={CommonService.getIconByType(file)} alt="img" className="w-85" alt="img"/>
          <span>{CommonService.getFileName(file)}</span>
        </a>
    </div>
  )
  
  return (
    <Fragment>
  	<div {...parentAttributes}>
      <div className="flex coldir m-b-20 tooltip">
        <CueTip 
          positionCss={position>1?'top':'bottom'}
          tooltip={field.tooltip}
          currentLocale={currentLocale}
          locale={field.locale?.tooltip}/>
    	  <FieldLabel field={field} 
          labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
   	    {!showUploadProgress &&
          <label ng-hide="edit_row" className={`butn btn-default w-100p bg-highlight color-white ${readOnly && 'input-readonly'}`} htmlFor={`multi_upload_selector_${field.client_id}`}
          data-tip data-for={`tooltip_${field.client_id}`}> 	 
           {labelLocale(6)}
          </label>
        }
        {showUploadProgress &&
          <label className="butn btn-default w-100p bg-highlight color-white" >   
            Uploading...
          </label>
        }
        <input {...inputAttributes} className="hidden" onChange={e => onChange(e)} multiple />
        <Description description={field.description} currentLocale={currentLocale}
          locale={field.locale?.description}/>
        <div className="flex coldir-xs wrap p-10">  
          {ImageList}
        </div>
        <Error/>
        <FileError/>
 	    </div>
    </div> 
    {isImageEditorOpen && (
        <GenericModal
          component={ImageEditor}
          selectedFile={selectedFile}
          saveImage={uploadMultiFileToUrl}
          title="Image Editor"
          isOpen={isImageEditorOpen}
          toggleModal={toggleImageEditorModal}
          minWidth = {field.min_width || 200}
          minHeight = {field.min_height || 200}
          maxWidth = {field.min_width || 200}
          maxHeight = {field.min_height || 200}/>
    )}
    </Fragment>
  )
}

export default InputMultiFileUpload;

/* <div className="col-xs-6" key={k}>
      <div className="thumbnail">
        <span className="close" onClick={e => removeImageFromList(file, k, e)}>×</span>
         <a href={file} target="_blank">
          <img src={CommonService.getIconByType(file)} className="thumb-md"/>
        </a>
        <span>{CommonService.getFileName(file)}</span>
      </div>
    </div>*/