import React, {useState, useEffect, Fragment} from 'react';
import ReactTooltip from 'react-tooltip';
import {Calendar, Views, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {Link} from "react-router-dom";
import HealthRouter from "../HealthRouter"
import Title from "../../GenericPortalPage/Title";

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer

const eventsList = [
  {
    id: 0,
    title: 'All Day Event very long title',
    allDay: true,
    start: new Date(2020, 3, 0),
    end: new Date(2020, 3, 1),
  },
  {
    id: 1,
    title: 'Long Event',
    start: new Date(2020, 3, 7),
    end: new Date(2020, 3, 10),
  },

  {
    id: 2,
    title: 'DTS STARTS',
    start: new Date(2020, 2, 13, 0, 0, 0),
    end: new Date(2020, 2, 20, 0, 0, 0),
  },

  {
    id: 3,
    title: 'DTS ENDS',
    start: new Date(2020, 10, 6, 0, 0, 0),
    end: new Date(2020, 10, 13, 0, 0, 0),
  },

  {
    id: 4,
    title: 'Some Event',
    start: new Date(2020, 3, 9, 0, 0, 0),
    end: new Date(2020, 3, 10, 0, 0, 0),
  },
  {
    id: 5,
    title: 'Conference',
    start: new Date(2020, 3, 11),
    end: new Date(2020, 3, 13),
    desc: 'Big conference for important people',
  },
  {
    id: 6,
    title: 'Meeting',
    start: new Date(2020, 3, 12, 10, 30, 0, 0),
    end: new Date(2020, 3, 12, 12, 30, 0, 0),
    desc: 'Pre-meeting meeting, to prepare for the meeting',
  },
  {
    id: 7,
    title: 'Lunch',
    start: new Date(2020, 3, 12, 12, 0, 0, 0),
    end: new Date(2020, 3, 12, 13, 0, 0, 0),
    desc: 'Power lunch',
  },
  {
    id: 8,
    title: 'Meeting',
    start: new Date(2020, 3, 12, 14, 0, 0, 0),
    end: new Date(2020, 3, 12, 15, 0, 0, 0),
  },
  {
    id: 9,
    title: 'Happy Hour',
    start: new Date(2020, 3, 12, 17, 0, 0, 0),
    end: new Date(2020, 3, 12, 17, 30, 0, 0),
    desc: 'Most important meal of the day',
  },
  {
    id: 10,
    title: 'Dinner',
    start: new Date(2020, 3, 12, 20, 0, 0, 0),
    end: new Date(2020, 3, 12, 21, 0, 0, 0),
  },
  {
    id: 11,
    title: 'Birthday Party',
    start: new Date(2020, 3, 13, 7, 0, 0),
    end: new Date(2020, 3, 13, 10, 30, 0),
  },
  {
    id: 12,
    title: 'Late Night Event',
    start: new Date(2020, 3, 17, 19, 30, 0),
    end: new Date(2020, 3, 18, 2, 0, 0),
  },
  {
    id: 12.5,
    title: 'Late Same Night Event',
    start: new Date(2020, 3, 17, 19, 30, 0),
    end: new Date(2020, 3, 17, 23, 30, 0),
  },
  {
    id: 13,
    title: 'Multi-day Event',
    start: new Date(2020, 3, 20, 19, 30, 0),
    end: new Date(2020, 3, 22, 2, 0, 0),
  },
  {
    id: 14,
    title: 'Today',
    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
  },
  {
    id: 16,
    title: 'Video Record',
    start: new Date(2020, 3, 14, 15, 30, 0),
    end: new Date(2020, 3, 14, 19, 0, 0),
  },
  {
    id: 17,
    title: 'Dutch Song Producing',
    start: new Date(2020, 3, 14, 16, 30, 0),
    end: new Date(2020, 3, 14, 20, 0, 0),
  },
  {
    id: 18,
    title: 'Itaewon Halloween Meeting',
    start: new Date(2020, 3, 14, 16, 30, 0),
    end: new Date(2020, 3, 14, 17, 30, 0),
  }]

let Schedules = (props) => {
  let params = props.match.params;
  const { project } = params
  console.log('hihgschoolschedules',project)

  const [eventsList, setEventsList] = useState([]);
  let events = [];

  useEffect(() => {
    ReactTooltip.rebuild()
    let req = {
      'journey_type':params.journey_type,
      'journey_category_id':params.journey_category_id,
      'education_journal_entry_params':{
        'page': 1,
        'paginate': 1,
        'per_page': 10,
        'sort_column': 'updated_at', 
        'sort_direction': 'desc',
        'journey_category_id': params.journey_category_id,
        'education_journey_profile_id': params.journey_profile_id
      },
      'data_source_params':{
        'data_source_id': 2214
      }
    }

    HealthRouter.getSchedules(req).then((res)=>{
      if(res.status==200){
        console.log('hihgschoolschedules',res)
        res.data.education_schedules.forEach((e, i)=>{
          e.start = new Date(e.start);
          e.end = new Date(e.end);
          events.push({
            'id':i,
            'title':e.title,
            'start': new Date(e.start.getFullYear(), e.start.getDate(), e.start.getMonth(), e.start.getHours(), e.start.getMinutes(), e.start.getSeconds()),
            'end': new Date(e.start.getFullYear(), e.start.getDate(), e.start.getMonth(), e.start.getHours(), e.start.getMinutes(), e.start.getSeconds()),
            'allDay':true
          })
        })
        setEventsList(events);
        /* config.events = res.data.education_schedules;
        $scope.activities= res.data.activities;
        $scope.journalEntryTypes= res.data.data_source_list[0].options;*/
      }
    })
  }, []);

  /*$http({
    url: myConfig.family_tree_backend + 'education_schedules',
    method: "GET",
    params:  req
  }).then(function(res){
    if(res.status==200){
      config.events = res.data.education_schedules;
      $scope.activities= res.data.activities;
      $scope.journalEntryTypes= res.data.data_source_list[0].options;
    }
  })*/
  if(eventsList.length==0)return null;

  return(
    <Fragment>
      <div className="content top-10 clearfix">
      <Title title={"HighSchool Schedules"} project={project} />
        <Link data-tip="Back to Journey Home"   to={`/highschool/project/portal/${params.journey_category_id}`} className="bg-highlight round-btn float-right m-r-5 m-t-4">
          <i className="fas fa-home"></i>
        </Link>
        <div className="clear"></div>
      </div>

      <div className="p-5 bg-white">
        <Calendar 
          localizer={localizer}
          events={eventsList}
          startAccessor="start"
          endAccessor="end" />
      </div>
    </Fragment>
  )
}

export default Schedules