import React, { useEffect, useState, useContext } from 'react'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import querystringify from "querystringify"
import PreLoadSpinner from "../../../components/PreLoadSpinner"
import DynamicForm from '../../../components/FormBuilder/DynamicForm'
import FormTemplateId from '../../../constants/FormTemplateList'
import HealthCategoryService from "../HealthCategoryService"
import Cs from "../../../services/CommonService"
import FormWizard from '../../../components/FormBuilder/FormWizard'
import env from '../../../env'
import {CityState} from "../../../components/Common/CityState"
import FormContainer from '../../../components/FormBuilder/FormContainer'
import GeoLocation from "../../../components/Common/GeoLocation"
import {CountryCodes} from "../../../constants/CountryCodes"
import useModal from "../../../hooks/useModal"
import GenericModal from "../../../components/Modals/GenericModal"
import NutritionCareNotification from '../../NutritionCares/NutritionCareNotification'

let formMode = null 
let formId = null 
let profile = {} 
let data = {} 
let formFn = {}  
let TemplateId = null

function HealthJourneyForm(props) {
  
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const { state:{user:currentUser} } = useContext(AuthContext)

  const currentOrgData = currentUser.current_organization?.data || {}
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal()

  /*TODO: Move API to Router to load data before view*/
  const getById = (id) => {
    HealthCategoryService.get(id).then((res) => {
      if(res.status==200){
        profile = {...res.data.journey_profile, ...profile.geo_data}
        data = profile.data || {}
        setFormRendered(true)
      }
    }).catch(function(res){
      profile = null
    })
  }
  
  useEffect(() => { 
    formMode = 'create-form-submissions'
    profile = {} 
    data = {
      country: currentOrgData.country,
      country_code: currentOrgData.country_code,
      district: currentOrgData.district,
      district_code: currentOrgData.district_code,
      state: currentOrgData.state,
      state_code: currentOrgData.state_code,
      taluka: currentOrgData.taluka,
      taluka_code: currentOrgData.taluka_code
    } 
    
    const f = currentOrgData.country_code?
      CountryCodes[currentOrgData.country_code.toLowerCase()]:null
    
    formFn = {
      form_type: 'journey_profile',
      phone_display_format: f?.[4],
      country_code: f?.[2],
    }

    if(params.id){
      formMode = 'edit-form-submissions'
      getById(params.id)
    }else{
      setCaseId()
      setFormRendered(true)
    }
  }, [])

  const setCaseId = () =>{
    data.case_id = ''
    if(currentUser.current_organization.record_id)
      data.case_id += (currentUser.current_organization.record_id + '/')

    data.case_id += (currentUser.record_id + '/')
    data.case_id +=  new Date().getTime()
  }

  const create = (event) => {
    event.organization_id = currentUser.current_organization_id
    event.medical_facility_id = currentUser.current_medical_facility_id
    event.sub_facility_id = currentUser.current_sub_facility_id
    event.created_by = currentUser.id
    event.updated_by = currentUser.id
    event.form_id = params.form_id
    event.journey_type = queryParam.journey_type
    event.data.health_condition = queryParam.type
    event.member_role_id = currentUser.current_member?.role_record_id
    HealthCategoryService.create(event).then((res) => {
      if(res.status==201){
        profile = res.data.journey_profile
        if(profile.show_nutrition_care){
          formFn.setLoading(false)
          toggleNutritionCareModal()
        }else{
          onSubmitPageRedirect()
        }
      }
    }).catch(function(res){
      back() 
    })
  }

  const update = (event) => {
    event.member_role_id = currentUser.current_member?.role_record_id
    HealthCategoryService.update(event).then((res) => {
      if(res.status==204){
        profile = event
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }
  
  const onSubmitPageRedirect = () => {
    props.history.push(`/health/table`)  
  }

  const back = () => {
    props.history.goBack()
  }

  const closeNutritionCare = (e) =>{
    onSubmitPageRedirect()
    toggleNutritionCareModal(e)
  }

  if(isFormRendered){
    return (
      <>
        <FormContainer post={post}>
          <DynamicForm formMode={formMode} formId={params.form_id} 
            form={profile} data={data} formFn={formFn} onCreate={create} 
            onUpdate={update} post={post} onCancel={back} setPost={setPost} 
          />
          <CityState data={data} formFn={formFn}/>
          <GeoLocation geoData={profile}/>
        </FormContainer>

        {isNutritionCareOpen && 
          <GenericModal component={NutritionCareNotification} 
            toggleModal={closeNutritionCare} isOpen={isNutritionCareOpen}
            itemType="journey_profile" itemUuid={profile.id} 
            closeIco="fas fa-chevron-down"/>
        }
      </> 
    )  
  }else{
    return null
  }
  
}

export default HealthJourneyForm;