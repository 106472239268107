import React, {Fragment, useState} from "react"
import {Link} from "react-router-dom"
import Cs from '../../../services/CommonService'
import {FormFieldTable, RenderData} from "../../HealthJourney/UserRoles/NotificationPill"
import FormTemplateId from "../../../constants/FormTemplateList"
import ContentList from "../TrainingTopics/ContentList"
import { CTTooltip } from "../../Common/CueTooltip"

const SessionCard = (props) => {
  const [showContents, setShowContents] = useState(false)
  const [disabledSort, setDisableSort] = useState(true)

  let {session, formFieldsList, openTrainingTopicForm, openTrainingSessionForm, onDelete} = props
  let {data, data_source} = session

  //let formFields = formFieldsList[FormTemplateId.TrainingSessionForm] || []

  return(
    <>
      <div className="card-container shadow-small">
        <div className="bg-lightblue white p-5 bold-600 pointer">
          <div onClick={()=>setShowContents(!showContents)}>
            <span className="font-16">{data.title_session}</span>
            <div className="">
              <span className="font-15">
                {data.start_time}
              </span>  
              <span className="font-15"> to </span>
              <span className="font-15">{data.end_time}</span>
            </div>
          </div>
          <div className="text-right">
            <span className="m-l-20" data-tip="Edit" onClick={(e)=>openTrainingSessionForm(e, session.id)}>
              <i className="far fa-edit font-18"></i>
            </span>
            <span className="m-l-20" data-tip="Delete" onClick={(e=>onDelete(session))}>
              <i className="far fa-trash-alt font-18"></i>
            </span>
            <span className="m-l-20 m-r-5" data-tip="Add Topic" onClick={()=>openTrainingTopicForm(null, session.id)}>
              <i className="fas fa-plus-circle font-18"></i>
            </span>
            <span className="m-l-20 m-r-5" data-tip="Sort" onClick={()=>setDisableSort(!disabledSort)}>
              <i className={`fas fa-sort font-18 ${disabledSort?'':'green'}`}></i>
            </span>
          </div>
        </div>
        <CTTooltip />
      </div>
        
      {showContents &&
        <ContentList flag={true} trainingSessionId={session.id} 
          disabledSort={disabledSort} setDisableSort={setDisableSort}/>
      }
    </>
  )
}

export default SessionCard;