import React, { useState, Fragment, useEffect } from 'react'
import ReactDOM from 'react-dom'
import FormHelper from "../../FormBuilder/FormHelpers"

let invalidEmails = []
let timeout = null
const AddTraineeUser = ({ isOpen, toggleModal, onSubmit, editTrainee }) => {

  const [trainee, setTrainee] = useState(editTrainee || {})
  const [formError, setFormError] = useState({})
  const [error, setError] = useState()

  useEffect(()=>{
    return ()=>{
      invalidEmails = []
    }
  }, [])

  useEffect(() => {
    FormHelper.validate('ct_form', trainee, formError, setFormError)
  }, [trainee])

  const onInputChange = (target) =>{
    setTrainee({...trainee, [target.name]:target.value})
  }

  const setEmail = (emails) =>{
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      trainee.email = emails
      invalidEmails = FormHelper.validateEmails(emails)
      setTrainee({...trainee})
    }, 1000)
  }

  const validateForm = () =>{
    if(invalidEmails?.length == 0 && formError.is_valid){
      return true
    }

    return false
  }

  return(
    ReactDOM.createPortal(
      <Fragment>
        <div className="ml-modal">
          <div className="ml-modal-content ml-card-4 ml-animate-zoom border-rounded-10">
          
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">{trainee.id?'Update':'Add'} Trainee</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>

          <form id="ct_form" className="ml-container">
            <div className="ml-section">
              <label><b>User</b></label>
              <input onChange={e=>setEmail(e.target.value)} 
                className="ml-input ml-border"
                type="email" name="email"  
                placeholder="User Email..."
                defaultValue={trainee.email} required/>
              <div className="red ml-margin-bottom">
                {invalidEmails.length>0 && ('Invalid Email '+ invalidEmails.toString())}
              </div>
              
              <label><b>Message</b></label>
              <textarea onChange={e=>onInputChange(e.target)}
                className="ml-input ml-border ml-margin-bottom" 
                placeholder="Message" name="message"  
                defaultValue={trainee.message} required>
              </textarea>

              {validateForm() && 
                <button className="ml-button ml-block ml-section ml-padding bg-highlight white" 
                  onClick={(e)=>onSubmit(e, trainee)}>
                    <b>{trainee.id?'Update':'Add'}</b>
                </button>
              }
            </div>
          </form>
      
        </div>
      </div>
    </Fragment>, document.body)
  )
}

export default AddTraineeUser;