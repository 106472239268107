import env from '../../env';
import axios from 'axios';

class PreScheduleEventService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.health_backend}health/pre_schedule_events`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.health_backend}health/pre_schedule_events/${req}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(env.health_backend+'health/pre_schedule_events',profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }



  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.health_backend}health/pre_schedule_events/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.health_backend}health/pre_schedule_events/${id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new PreScheduleEventService();