import React, {Fragment, useContext} from 'react';
import { Route, Redirect } from 'react-router-dom';
import CookieNotice from "../components/Common/CookieNotice"
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";

const PublicRoute = ({component: Component, ...rest}) => {
	const { state:authState, dispatch } = useContext(AuthContext);

    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        /*<Route {...rest} render={props => (
            authState.isAuthenticated && restricted ?
                <Redirect to="/home" />
            : <Component {...props} />
        )} />*/

        /*TODO*/

        <Route {...rest} render={props => (
          <Fragment>
           <Component {...props} />
           {localStorage.is_cookie_accepted === undefined && <CookieNotice />}
          </Fragment>
        )} />
    );
};

export default PublicRoute;