import React, {Fragment, useState, useContext, useEffect} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../Common/MenuItem"
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../Common/SortDirection'
import Cs from "../../services/CommonService"
import ConfirmMenu from "../Modals/ConfirmMenu"
import useModal from "../../hooks/useModal"
import GenericModal from '../Modals/GenericModal'
import TrainingProgramsService from './TrainingProgramsService'
import TrainingProgramForm from './Form'
import FormTemplateId from "../../constants/FormTemplateList"
import ProgramCard from './ProgramCard'
import ProjectMenu from "../Modals/ProjectMenu"
import TraineeUserList from "./TraineeUsers/TraineeUserList"
import ReactTooltip from "react-tooltip"
import CommentsModal from "../Modals/CommentsModal"
import { AddBtn, FontAwesomeBtn } from "../Common/Button"
import { LinkFontAwesomeIcon } from "../Common/ImageIcons"
import {usePageTitle} from '../../hooks/pageHead'

let selectedProgram = {}
let requestParams ={}
let dataSources = []
let trainingProgramFormProps = {}

const TrainingPrograms = (props) => {
  useStyle('/css/sidebar-theme.css', true)
  useStyle('/css/tile-components.css', true)
  usePageTitle('TrainingPrograms')

  const {state: { user: currentUser }, dispatch} = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])
  const [formFieldsList, setFormFieldsList] = useState({})

  const {isOpen:isFormOpen, toggleModal:toggleFormModal} = useModal()
  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal()
  const {isOpen:isMenuOpen, toggleModal:toggleMenuModal} = useModal()
  const {isOpen:isTraineeListOpen, toggleModal:toggleTraineeList} = useModal()
  const {isOpen: isCommentOpen, toggleModal:toggleCommentModal} = useModal()

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      page:1,
      per_page:100,
      form_fields_params:{
        ids:[FormTemplateId.TrainingProgramForm]
      }
    } 
    getAll()
  }, [])

  const getAll = () =>{
    setLoading(true)
    TrainingProgramsService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setList(res.data.training_programs)
        setFormFieldsList(res.data.form_fields)
        setLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const onDelete = (e, program) => {
    selectedProgram = program
    toggleDeleteModal(e)
  }

  const deleteItem = (e) => {
    e.preventDefault();
    TrainingProgramsService.delete(selectedProgram.id).then((res) => {
      if (res.status == 204) {
        setList(list.filter(i => i.id !== selectedProgram.id))
      }
    })
    toggleDeleteModal(e)
  }

  const update = (program, key) =>{
    let req = {
      id: program.id,
      [key]: !program[key]
    }
    TrainingProgramsService.update(req).then((res) => {
      if (res.status == 204) {
        program[key] = !program[key]
        setList([...list])
      }
    })
  }

  const openTrainingProgramForm = (e, id) =>{
    e.preventDefault();
    trainingProgramFormProps = {
      match:{
        params:{
          id: id,
        }
      },
      isPopupMode:true,
      onSuccess:getAll
    }
    toggleFormModal()
  }

  const addMenu = (item) => {
    selectedProgram = item
    toggleMenuModal()
  }

  const openTrainingList = (e, item) =>{
    e.preventDefault()
    selectedProgram = item
    toggleTraineeList()
  }

  const addNotes = (e, item) => {
    selectedProgram = item;
    toggleCommentModal(e);
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <a href="/" onClick={(e) => openTrainingList(e, item)}>
          <i className="far fa-user"></i>
          <span className="font-13">Add Trainee</span>
          <i className="fa fa-angle-right"></i>
        </a>
        <Link to={`/assessment/form?training_program_id=${item.id}`}>
          <i className="fas fa-plus-circle"></i>
          <span className="font-13">Add Assessment</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <Link to={`/list/assessments/${item.id}`}>
          <i className="far fa-file-alt"></i>
          <span className="font-13">View Assessment</span>
          <i className="fa fa-angle-right"></i>
        </Link>
        <a href="/" onClick={(e) => openTrainingProgramForm(e, item.id)}>
          <i className="far fa-edit"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </a>
        <a to="/" onClick={(e) => onDelete(e, item)}>
          <i className="far fa-trash-alt"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ item, fn }) => (
    <span onClick={()=>addMenu(item)} className="fas fa-bars pull-right m-t-2 m-r-10" />     
  )

  const programCard =  list && list.map((p, i) => (
    <div className="col-md-4" key={i}>
      <ProgramCard program={p} menu={Menu} addMenu={addMenu} addNotes={addNotes} formFieldsList={formFieldsList} index={i}/>
      {selectedProgram.id == p.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
        item={selectedProgram} MenuItems={menuItems}/>
      }
    </div>
  ))

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20">Training Programs</div>
              <div className="font-15">
              List of Training Programs - {list.length}
              </div>
            </div>
            <div className="flex aligncenter">
              <AddBtn 
                id="new_program" dataTip="Create New Training Programs"   
                onSelect={()=>openTrainingProgramForm()}>
                Add
              </AddBtn>
              <LinkFontAwesomeIcon id="assessments" dataTip="Assessments" link={'/list/assessments'} css="fa fa-book-open white">
              </LinkFontAwesomeIcon>
            </div>
          </div>    
      
        {loading?<div className="spinner"/>
          :  
          <div className="app-content-section m-t-30">
            <div className="row">
              {programCard}
            </div>
          </div>
        }
      </div>
      </div>
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteItem}/>
      
      {isFormOpen && <GenericModal component={TrainingProgramForm} isOpen={isFormOpen} 
        toggleModal={toggleFormModal} widthCss="w-100p" {...trainingProgramFormProps} />
      }

      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} item={selectedProgram} 
        type="program"/>
      }

      {isTraineeListOpen && <GenericModal component={TraineeUserList}
        isOpen={isTraineeListOpen} toggleModal={toggleTraineeList}
        trainingProgramId={selectedProgram.id} widthCss="w-100p"
        itemType="training_program" itemId={selectedProgram.id}
        organizationId={currentUser.current_organization_id}
        title={selectedProgram.data.host}/>
      }
    </Fragment>
  )

}

export default TrainingPrograms;