import React, {Fragment} from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from "../../routes/PrivateRoute"
import HealthJourneyList from './List/HealthJourneyList'
import HealthJourneyForm from '../../components/HealthJourney/Form/HealthJourneyForm'
import HealthRouter from "./Health/HealthRouter"
import RoleList from '../../components/HealthJourney/Roles/RoleList'
import IncomingMails from './IncomingMails/IncomingMails'
import MemberList from '../Members/MemberList'
import HealthFamilyDashboard from './HealthFamilyDashboard'
import PortalPage from './GenericPortalPage/PortalPage'
import HealthImageMap from './GenericPortalPage/HealthImageMap'
import WellnessForm from './Wellness/Form/WellnessForm'
import TreatmentList from './Treatments/List/TreatmentList'
import TreatmentForm from './Treatments/Form/TreatmentForm'
import WellnessBlockPage from './Wellness/List/WellnessBlockPage'
import UpcomingDashboard from './Dashboard/UpcomingDashboard'

const HealthJourneyRouter = (props) => (
  <Fragment>	
    <Switch>
      <PrivateRoute path={["/health/table"]} searchString={props.searchString} component={HealthJourneyList} exact />
      <PrivateRoute path="/health/form/:form_id/:id?" component={HealthJourneyForm} exact />
      <PrivateRoute path="/health/role/:roleable_type/:roleable_id/:organization_id/:subscription_id" searchString={props.searchString} component={RoleList} exact />
      <PrivateRoute path="/health/incoming/emails/:type/:id" component={IncomingMails} exact />
      <PrivateRoute path="/health/members/:memberable_type/:memberable_id" component={MemberList} exact/>
      <PrivateRoute path="/health/family" component={HealthFamilyDashboard} exact />
      <PrivateRoute path="/health/common/page/:journey_profile_id" component={PortalPage} exact />
      <PrivateRoute path="/health/map/:formId?" component={HealthImageMap} exact />
      <PrivateRoute path="/health/treatment/:health_id?" searchString={props.searchString} component={TreatmentList} exact />
      <PrivateRoute path="/health/treatmentForm/:journey_profile_id/:id?" searchString={props.searchString} component={TreatmentForm} exact />
      <PrivateRoute path="/health/wellnessForm/:journey_profile_id/:symptom_id/:type/:form_id/:id?" searchString={props.searchString} component={WellnessForm} exact /> 
      <PrivateRoute path="/health/treatmentForm/:journey_profile_id/:treatment_id/:treatment_type/:treatment_form_id/:id?" searchString={props.searchString} component={TreatmentForm} exact />   
      <PrivateRoute path="/wellness/graph/:journey_profile_id" component={WellnessBlockPage} exact />
      <PrivateRoute path="/health/upcoming/dashboard" component={UpcomingDashboard} exact />
    </Switch>
    <Switch>
      <HealthRouter searchString={props.searchString} setHelpId={props.setHelpId} />
    </Switch>
  </Fragment>
)

export default HealthJourneyRouter;