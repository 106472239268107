import React ,{Fragment, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from '../../services/CommonService'
import env from '../../../src/env'
import useStyle from '../../hooks/useStyle'
import NutritionCareNotification from '../NutritionCares/NutritionCareNotification'
import useModal from "../../hooks/useModal"
import GenericModal from "../Modals/GenericModal"

const Notification = (props) => {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal()
  
  let profileImage = 'images/avatars/2s.png'
  let {notification, onDelete} = props
  notification.item_type = (notification.item_type == 'infant_journey.activity'?'infant.activity':notification.item_type)
  notification.updated_at = Cs.formatUpdateDate(notification.updated_at)
  if(notification.sender && notification.sender.profile_image_url){
    profileImage = env.file_url+notification.sender.profile_image_url
  }

  const openNotification = () =>{
    toggleNutritionCareModal()
  }

  const className = `${notification.status === 'read'?'bg-white-1':''} flex justspacebetween alignsflextart m-b-20 border-dashed-sm p-b-20 notification-card p10`
  
  return(
    <Fragment>  
      <div className={className}>
        <div className="flex alignsflextart" onClick={()=>openNotification()}>
          <span className="flex aligncenter user_dropdown-xs m-r-15">
            <span className="user_dropdown flex"></span>
          </span>
          <div className="flex coldir">
            <h5 className="f18 fw500 m-b-5">
              {notification.journey_profile_name}
              <span className="text-gray f14 m-l-10">{notification.updated_at}</span>
            </h5>
            <p className="m-b-5">
              {notification.message}
            </p>
            <p>{notification.sender_name} at {notification.updated_at}</p>
          </div>
        </div>
        <span onClick={()=>props.onDelete(notification)}
          className="table-icon table-icon-gray flex aligncenter justifycenter">
            <img src="images/delete-icon-black.png" alt="" />
        </span>
      </div>
     
      {isNutritionCareOpen && 
        <GenericModal component={NutritionCareNotification} 
        toggleModal={toggleNutritionCareModal} isOpen={isNutritionCareOpen}
        itemType={notification.item_type} itemUuid={notification.item_id} 
        notificationId={notification.id} closeIco="fas fa-chevron-down"/>
      }
    </Fragment>
  )
}

export default Notification;