import React, {useState} from "react"
import {NotificationBadge} from "./BadgePill"
import {Link} from "react-router-dom"
import {CueTip} from "./CueTooltip"

const TagContainer = (props) =>{
  if(props.tag === 'a'){
    return(
      <Link {...props}>
        {props.children}
        <CueTip tooltip={props.tooltip}/>
      </Link>  
    )
  }else if(props.tag === 'span'){
    let clickProp = {}
    if(props.onSelect){
      clickProp.onClick = (e) => props.onSelect(e)
    }

    return(
      <span {...clickProp} {...props}>
        {props.children}
        <CueTip tooltip={props.tooltip}/>
      </span>  
    )
  }
}

const PhoneIcon = ({title, tooltip}) => {
  const defaultCss = 'tooltip gray-icon tcenter flex aligncenter justifycenter m-r-5'
  return (
    <>
      <span className={defaultCss} tooltip={tooltip}>
        <img src="images/phone-icon.png" alt="" />
        <CueTip tooltip={tooltip}/>
      </span>
      {title}
    </>
  )
}

const MapIcon = ({title, tooltip}) => {
  const defaultCss = 'tooltip gray-icon tcenter flex aligncenter justifycenter m-r-5'
  return (
    <>
      <span className={defaultCss} tooltip={tooltip}>
        <img src="images/map-icon.png" alt="" />
        <CueTip tooltip={tooltip}/>
      </span>
      {title}
    </>
  )
}

const LinkMapIcon = (props) => {
  const {id, onSelect, link, tooltip} = props
  const defaultCss = 'tooltip table-icon table-icon-lgrey flex aligncenter justifycenter m-r-10'
  return (
    <TagContainer tag={link ? 'a':'span'} id={id} to={link} onSelect={onSelect} tooltip={tooltip} href="#" className={defaultCss}>
      <span className="fas fa-hand-point-up white font-16"/>
      {props.children}
    </TagContainer>
  )
}

const LinkMailIcon = ({id, onSelect, link, count, tooltip}) => {
  const defaultCss="tooltip table-icon table-icon-lgrey flex aligncenter justifycenter m-r-10 pos_relative"
   
  return (
    <TagContainer tag={link ? 'a':'span'} id={id} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="images/mail-icon.png" alt="" />
      <NotificationBadge count={count}/>
    </TagContainer>
  )
}                      

const LinkUserIcon = ({id, onSelect, link, tooltip, count}) => {
  const defaultCss = 'tooltip table-icon table-icon-lgrey flex aligncenter justifycenter m-r-10'
  return (
    <TagContainer tag={link ? 'a':'span'} id={id} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="images/user-icon.png" alt=""/>
      <NotificationBadge count={count}/>
    </TagContainer>
  )
}                      

const LinkDeleteIcon = ({id, onSelect, link, tooltip}) => {
  const defaultCss = 'tooltip table-icon table-icon-lgrey flex aligncenter justifycenter m-l-5'
  return (
    <TagContainer tag={link ? 'a':'span'} id={id} to={link} onSelect={onSelect} className={defaultCss} tooltip={tooltip}>
      <img src="images/delete-icon.png" alt="" />
    </TagContainer>
  )
} 

const LinkFontAwesomeIcon = ({onSelect, link, css, count, tooltip}) => {
  const defaultCss = 'tooltip table-icon table-icon-lgrey flex aligncenter justifycenter m-l-5 pos_relative'
  return (
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss} tooltip={tooltip}>
      <span className={css}/>
      {count && <NotificationBadge count={count}/>}
    </TagContainer>
  )
}

const EnvelopeGrayIcon = ({onSelect, link, css, count, tooltip}) =>{
  const defaultCss = 'tooltip pos_relative'
  return (
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/envelope-icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
}

const EditGrayIcon = ({onSelect, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/edit-icon-gray.png" />
    </TagContainer>
  )
}

const HamburgerMenuIcon = ({onSelect, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip}>
      <img src="./images/hamburger-meni-icon-gray.png" alt="" />
    </TagContainer>
  )
}

const MaximizeIcon = ({onSelect, link, css, children, tooltip}) =>{
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={css} tooltip={tooltip}>
      <img src="./images/maximize-icon.png" />
    </TagContainer>
  )
}

const ChatGrayIcon = ({onSelect, count, link, css, tooltip}) => {
  const defaultCss = 'tooltip pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/mesage-icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
}

const CheckListGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/check-icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )  
}

const UserGroupGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/user-group-icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )                      
}

const MapGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/map-icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
}

const BookmarkGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/bookmark-icon-gray.png"/>
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
}

const EyeGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/eye=icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
}
                        
const GlobeGrayIcon = ({onSelect, count, link, css, tooltip}) =>{
  const defaultCss = 'tooltip pos_relative'
  return(
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} tooltip={tooltip} className={defaultCss}>
      <img src="./images/globe-icon-gray.png" />
      <NotificationBadge count={count} css="notification_count border-rounded notification_blue fw900"/>
    </TagContainer>
  )
} 

const FontAwesomeIcon2 = ({onSelect, link, css, count, tooltip, parentCss}) => {
  const defaultCss = 'table-icon font-20 text-center '+(parentCss?parentCss:'')
  return (
    <TagContainer tag={link ? 'a':'span'} to={link} onSelect={onSelect} className={defaultCss} tooltip={tooltip}>
      <span className={css}/>
      {count && <NotificationBadge count={count}/>}
    </TagContainer>
  )
}

export {GlobeGrayIcon, MaximizeIcon, EnvelopeGrayIcon, EditGrayIcon, HamburgerMenuIcon, 
  PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon, 
  LinkFontAwesomeIcon, EyeGrayIcon, BookmarkGrayIcon, MapGrayIcon, UserGroupGrayIcon,
  CheckListGrayIcon, ChatGrayIcon, FontAwesomeIcon2
};