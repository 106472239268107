import React, { Fragment, useState, useEffect, useContext} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import { CacheContext } from "../../contexts/Cache/CacheStateProvider";
import Cs from '../../services/CommonService';
import { LinkFontAwesomeIcon } from "../Common/ImageIcons"
import useModal from "../../hooks/useModal"
import OrganizationService from "./OrganizationService"
import GenericModal from '../Modals/GenericModal'
import FacilityList from '../HealthJourney/MotherInfantJourney/MedicalCareFacility/List'
import MemberList from './Members/MemberList'
import PreScheduleEventList from '../PreScheduleEvent/List/PreScheduleEventList'
import CounselingList from '../HealthJourney/MotherInfantJourney/Counseling/List/CounselingList'
import NutritionCareList from  '../NutritionCares/List'

const OrganizationReport = ({organization}) => {
  const {cacheState, cacheDispatch} = useContext(CacheContext)

  const [loading, setLoading] = useState(true)
  const [reports, setReports] = useState({})
  
  const {isOpen:isMedicalFacilityOpen, toggleModal:toggleMedicalFacility} = useModal()
  const {isOpen:isMemberListOpen, toggleModal:toggleMemberList} = useModal()
  const {isOpen:isPreScheduleEventOpen, toggleModal:togglePreScheduleEvent} = useModal()
  const {isOpen:isCounselingOpen, toggleModal:toggleCounseling} = useModal()
  const {isOpen:isIpaOpen, toggleModal:toggleIpa} = useModal()

  useEffect(() => {
    getReport()
    return () =>{
      cacheDispatch({
        type: "CLEAR",
      })
    }
  }, [organization.id])

  const getReport = () =>{
    setLoading(true)
    OrganizationService.report({'organization_id': organization.id}).then((res)=>{
      if(res.status === 200){
        setReports(res.data.report)
        setLoading(false)
      }
    })
  }

  const setFilter = (filterName) =>{
    cacheDispatch({
      type: "UPDATE",
      payload: {
        [filterName]:{
          'assigned_to_organization_id': organization.id
        }
      }
    })

    if(filterName === 'preScheduleFilter'){
      togglePreScheduleEvent()
    }else if(filterName === 'ipaFilters'){
      toggleIpa()
    }else if(filterName === 'counselingFilters'){
      toggleCounseling()
    }
  }

  if(loading)
    return <div className="spinner"/>
  
  if(isMedicalFacilityOpen)
    return  (
      <GenericModal 
        {...(Cs.routeMedicalFacility(organization.id))}
        component={FacilityList}
        toggleModal={toggleMedicalFacility}
        title={organization.data.name || 'Organization'}
      />
    )
  
  if(isMemberListOpen)
    return  (
      <GenericModal 
        {...(Cs.routeOrgMemberList(organization.id))}
        component={MemberList}
        toggleModal={toggleMemberList}
        title={organization.data.name || 'Organization'}
      />
    )

  if(isPreScheduleEventOpen)
    return  (
      <GenericModal 
        component={PreScheduleEventList}
        toggleModal={togglePreScheduleEvent}
        isPopupMode={true}
        title={organization.data.name || 'Organization'}
      />
    )

  if(isIpaOpen)
    return  (
      <GenericModal 
        component={NutritionCareList}
        toggleModal={toggleIpa}
        isPopupMode={true}
        location={{}}
        title={organization.data.name || 'Organization'}
      />
    )

  if(isCounselingOpen)
    return  (
      <GenericModal 
        component={CounselingList}
        toggleModal={toggleCounseling}
        isPopupMode={true}
        match={{}}
        location={{}}
        title={organization.data.name || 'Organization'}
      />
    )

  const Header = () => (
    <div className="card-title card-title-gray flex p10 coldir">
      <h4 className="fw700 f18 card-heading flex coldir text-left">
        {organization.data.name}
      </h4>
      <div className="f14 m-t-10 fw500">{Cs.formatUpdateDate(organization.updated_at)}</div>
    </div>
  )

  const Card = () => {
    return (
      <div id="ct">
        <div className="bg-white border-rounded-10 card-shadow m-b-15">
          <Header />
          <div className="card-content p20">
            <div className="b-tab-table active scroll-auto ht-300">
              <div className="border-dashed-sm p-b-15 m-b-15">
                <h4 className="fw500 m-b-5"># of Facilities</h4>
                <div className="badge-2" onClick={toggleMedicalFacility}>{reports.medical_facilities_count}</div>
              </div>
              <div className="border-dashed-sm p-b-15 m-b-15">
                <h4 className="fw500 m-b-5"># of Members</h4>
                <div className="badge-2" onClick={toggleMemberList}>{organization.members_count}</div>
              </div>
              <div className="border-dashed-sm p-b-15 m-b-15">
                <h4 className="fw500 m-b-5">Subscription</h4>
                <p>{organization.subscription?.data?.name}</p>
              </div>
              <div className="border-dashed-sm p-b-15 m-b-15">
                <h4 className="fw500 m-b-5">Calendar Manager items assigned</h4>
                <div className="badge-2" onClick={()=>setFilter('preScheduleFilter')}>{reports.pre_schedules_count}</div>
              </div>
              <div className="border-dashed-sm p-b-15 m-b-15">
                <h4 className="fw500 m-b-5">IPA Tiles assigned</h4>
                <div className="badge-2" onClick={()=>setFilter('ipaFilters')}>{reports.ipa_count}</div>
              </div>   
              <div className="border-dashed-sm p-b-15 m-b-15">
                <h4 className="fw500 m-b-5">Counseling Tiles assigned</h4>
                <div className="badge-2" onClick={()=>setFilter('counselingFilters')}>{reports.counselings_count}</div>
              </div>   
            </div>
          </div>
        </div>
      </div>
    )
  }

  return <Card/>
}

const OrgReportIcon = ({organization}) =>{
  const {isOpen:isReportOpen, toggleModal:toggleReportModal} = useModal()

  if(isReportOpen){
    return(
      <GenericModal 
        component={OrganizationReport} organization={organization}
        isOpen={isReportOpen} toggleModal={toggleReportModal}/>
    )
  }

  return(
    <LinkFontAwesomeIcon  onSelect={()=>toggleReportModal()}
      css="fas fa-poll white"
      id="facilities_organization" 
      tooltip="Report"/>
  )

}

export {OrgReportIcon};