import env from "../../../../env";
import axios from "axios";

class StepActivitiesService {
  getAll(req) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${env.edu_team_backend}step_activities`, { params: req })
        .then((res) => {
          // handle success
          resolve(res);
        })
        .catch((e) => {
          // handle error
          reject(e);
        });
    });
  }

  get(req) {
    console.log("req sp axios", req);
    return new Promise((resolve, reject) => {
      axios
        .get(`${env.edu_team_backend}step_activities/${req.id}`, {
          params: req,
        })
        .then((res) => {
          // handle success
          resolve(res);
        })
        .catch((e) => {
          // handle error
          console.log("getid sp", e);
          reject(e);
        });
    });
  }

  create(req) {
    return new Promise((resolve, reject) => {
      axios
        .post(env.edu_team_backend + "step_activities", req)
        .then((res) => {
          // handle success
          resolve(res);
        })
        .catch((e) => {
          // handle error
          reject(e);
        });
    });
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${env.edu_team_backend}step_activities/${req.id}`, req)
        .then((res) => {
          // handle success
          resolve(res);
        })
        .catch((e) => {
          // handle error
          reject(e);
        });
    });
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`${env.edu_team_backend}step_activities/${req.id}`)
        .then((res) => {
          // handle success
          resolve(res);
        })
        .catch((e) => {
          // handle error
          reject(e);
        });
    });
  }
}

export default new StepActivitiesService();
