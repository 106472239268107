import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import DynamicForm from '../../../FormBuilder/DynamicForm'
import FormTemplateId from '../../../../constants/FormTemplateList'
import AssessmentService from "../AssessmentService"
import querystringify from "querystringify"
import FormContainer from '../../../FormBuilder/FormContainer'

let formMode = null 
let assessment = {} 
let data = {}
let formFn = {}  

function AssessmentForm(props) {
  const { state:{user:currentUser} } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  
  const get = () => {
    AssessmentService.get(params.id).then((res) => {
      if(res.status==200){
        assessment = res.data.assessment
        data = assessment.data || {}
        setFormRendered(true)
      }
    }).catch(function(res){
       assessment = null
    })
  }
  
  useEffect(() => { 
    assessment = {} 
    data = {} 
    formMode = 'create-form-submissions'
    formFn = {
      'form_type':'carrier_training.assessment', 
      'editPermission':queryParam.edit
    } 
    if(params.id){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [])

  const create = (assessment) => {
    assessment.training_program_id = queryParam.training_program_id || null
    assessment.assessment_type = params.type
    assessment.assessment_form_id = FormTemplateId.TrainingAssessment  
    AssessmentService.create(assessment).then((res) => {
      if(res.status == 201){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const update = (assessment) => {
    AssessmentService.update(assessment).then((res) => {
      if(res.status == 204){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    //formFn.setIsFormSubmitted && formFn.setIsFormSubmitted()
    props.history.goBack()
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.TrainingAssessment} 
        form={assessment} data={data} formFn={formFn} onCreate={create} 
        onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
        setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null
  }
  
}

export default AssessmentForm;