import React, {useState, useEffect, Fragment, useContext} from "react";
import ReactDOM from 'react-dom';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import useModal from "../../hooks/useModal";
import HelpService from './HelpService';
import useStyle from '../../hooks/useStyle';
import env from '../../env';
import ConfirmMenu from "../Modals/ConfirmMenu";
import Cs from '../../services/CommonService';
import SortDirection from '../../components/Common/SortDirection';
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from '../Common/arrayMove';
import {getLocale} from '../FormBuilder/FieldLabel';
import CheckAccess from '../HealthJourney/Roles/CheckAccess';
import LocaleList from "../Locale/LocaleMenu"
import GenericModal from "../Modals/GenericModal"
import {HelpDetails} from "./HelpSidePanel"

let requestParams = {};
let selectedHelp = null;

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt m-5 black"/>)

const SortableContainer = sortableContainer(({children}) => 
  <ul>{children}</ul>
)

const WAIT_INTERVAL = 1000;
let timer = null;
let searchKeyword = null;

const HelpSideNav = (props) => {
  useStyle('help');

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const history = useHistory()

  const [helpIndex, setHelpIndex] = useState();
  const [categoryIndex, setCategoryIndex] = useState();
  const [helpList, setHelpList] = useState([]);
  const [direction, setDirection] = useState('right');
  const [toggle, setToggle] = useState(true);
  const [hide, setHide] = useState(false);
  const [isSearchOpen, toggleSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dataSource, setDataSource] = useState();
  const [filter, setFilter] = useState({
    'journey_type_ids': currentUser?.current_subscription?.data?.journeys_array
  });
  const [isMaximized, setMaximized] = useState(false);
  const [categoryStats, setCategoryStats] = useState();

  const { isOpen: showLocaleModal, toggleModal: toggleLocaleModal} = useModal()
  const { isOpen:isDeleteOpen, toggleModal:toggleDeleteModal } = useModal();

  const match = useRouteMatch();
  const pageId = match.path.split("/:")[0]

  useEffect(() => {
    timer = null
    searchKeyword = null

    requestParams = {
      'page': 1,
      'paginate': 1,
      'per_page': 15,
      'sort_column': 'sort_order',
      'sort_direction': 'asc',
      'data_source_params': {
        'locale':currentUser.current_locale,
        'data_source_id': [3937]
      }
    }

    getHelp();   
  }, [props.helpId, toggle, filter]);

  let getHelp = () => { 
    setLoading(true)
    //setToggle(true)
    //requestParams.page_url = pageId
    requestParams.filter = filter
    requestParams.search = searchKeyword
    requestParams.add_category_count = categoryStats?false:true
    HelpService.getAll(requestParams).then((res)=>{
      if(res.status === 200){
        setLoading(false)
        setHelpList(res.data.helps)
        setDataSource(res.data.data_source_list)
        if(requestParams.add_category_count)
          setCategoryStats(res.data.category_count)
      }
    })
  }

  const getHelpByCategory = (category) =>{
    if(categoryIndex === category.pk_id){
      setCategoryIndex(null)
    }else{
      setHelpList([])
      setFilter({...filter, 'category': category.value})
      setCategoryIndex(category.pk_id)
    }
  }

  const setPosition = () =>{
    let p = direction==='left'?'right':'left'
    setDirection(p)
  }

  const onDelete = (help) => {
    selectedHelp = help;
    toggleDeleteModal();
  }

  const deleteHelp = (e) =>{
    e.preventDefault()
    HelpService.delete(selectedHelp.id).then((res)=>{
      if(res.status == 204){
        setHelpList(helpList.filter(r => r.record_id !== selectedHelp.record_id));
      }
    })
    toggleDeleteModal(e)
  }

  const toggleHelp = (i) =>{
    if(helpIndex == i){
      setHelpIndex(null)
    }else{
      setHelpIndex(i)
    }
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    let priorItemId = null
    let nextItemId = null

    if(newIndex == 0 ){
      console.log(helpList[newIndex].data.name)
      nextItemId = helpList[newIndex].id
    }else if(newIndex+1 == helpList.length){
      console.log(helpList[newIndex].data.name)
      priorItemId = helpList[newIndex].id
    }else if(oldIndex > newIndex){
      console.log(helpList[newIndex-1].data.name)
      console.log(helpList[newIndex].data.name)

      priorItemId = helpList[newIndex-1].id
      nextItemId = helpList[newIndex].id
    }else if(oldIndex < newIndex){
      console.log(helpList[newIndex].data.name)
      console.log(helpList[newIndex+1].data.name)

      priorItemId = helpList[newIndex].id
      nextItemId = helpList[newIndex+1].id
    }

    function onSuccess(){
      setHelpList(arrayMove(helpList, oldIndex, newIndex))
    }

    updateSortOrder(helpList[oldIndex].id, priorItemId, nextItemId, onSuccess)    
  }

  const updateSortOrder = (id, prior_item_id, next_item_id, success_callback) =>{
    let req = {
      'update_type': 'sort_order',
      'prior_item_id':prior_item_id,
      'next_item_id':next_item_id,
      'id':id
    }
    HelpService.update(req).then((res)=>{
      if(res.status == 204){
        success_callback()
      }
    }) 
  }

  const onSearch=(value)=>{
    clearTimeout(timer)
    searchKeyword = value
    timer = setTimeout(triggerChange, WAIT_INTERVAL)
  }

  const triggerChange = () => {
    getHelp()
  }

  const translateAndClone = (item) => {
    if(CheckAccess.isDevUser(currentUser.email)){
      selectedHelp = Cs.deepCopy(item);
      selectedHelp.data_source = null
      selectedHelp.id = null 
      toggleLocaleModal()
    }
  } 

  const onTranslateComplete = (translatedData) => {
    toggleLocaleModal()
    history.push(`/help/create?parent_id=${translatedData.record_id}`, translatedData.data)
    selectedHelp = null
  }

  const SortableItem = sortableElement(({help}) => {
    return(
      <div className="p-5 zIndex-99" style={{border: helpIndex == help.id ? '2px solid #00a2ff':''}} key={help.id}>
        <h5 className="pointer br-b-grey plr-10" onClick={()=>toggleHelp(help.id)}>
          {CheckAccess.isDevUser(currentUser.email) && <DragHandle/>}
          <i className={`fas ${help.id != helpIndex?'fa-caret-square-down':'fa-caret-square-up'} m-r-15`}/>
          {help.data.title}
          {CheckAccess.isDevUser(currentUser.email) &&
            <div className="font-16 ht-25">
              {help.parent_id ?
                null
                : 
                <span className="m-r-20 pull-right" onClick={()=>translateAndClone(help)}>
                  <i className="far fa-copy"/>
                </span>
              }
                
              <Link to={`/help/create/${help.id}`} className="m-r-20 pull-right">
                <i className="far fa-edit"/>
              </Link>

              <span className="m-r-20 pull-right" onClick={()=>onDelete(help)}>
                <i className="far fa-trash-alt"/>
              </span>
            </div>
          }
        </h5>
          
        {helpIndex == help.id && 
          <HelpDetails h={help}/>
        }
      </div>     
    )
  })

  const helpListPanel =  helpList && helpList.map((help, i) => 
    <SortableItem key={`item-${i}`} index={i} help={help} />
  )

  const CategoryList = () =>  dataSource ? dataSource.feedback_question.options.map((c, k)=>
    <div className="p-5" style={{border: categoryIndex === c.pk_id ? '2px solid #00a2ff':''}} key={k}>
      <h5 className="pointer br-b-grey plr-10" onClick={()=>getHelpByCategory(c)}>
        <i className={`fas ${c.pk_id != categoryIndex?'fa-caret-square-down':'fa-caret-square-up'} m-r-10`}/>
        {getLocale(c, currentUser.current_locale)} 
        <span className="badge-2 m-l-15">{categoryStats?.[c.value]?.count || 0}</span>
      </h5>
          
      {categoryIndex === c.pk_id && 
        <SortableContainer onSortEnd={onSortEnd} distance={1} useDragHandle>
          {helpListPanel}
        </SortableContainer>
      }
    </div>
  ):null

  return(
    ReactDOM.createPortal(
      <Fragment>
        <div id="#ct"
          className={`col-xs-12 col-sm-10 col-md-10 col-lg-8 zIndex-99 ${hide ? 'help-side-nav':'help-side-nav-hide'}`} 
          style={{
            right: direction==='right'?'unset':0,
            display: toggle?'block' :'none',
            height: hide?'unset':(isMaximized?'90%':'500px')
          }}>
          
          <div className="help-header bg-highlight clearfix">
            <div className={`col-xs-12 help-icon ${direction==='right'?'pull-right':'pull-left'}`}> 
              <h4 className="pull-left white">
                Help
              </h4>

              <span className="m-r-20 pull-right" onClick={()=>{props.setIsHelpOpen(false);setToggle(!toggle)}}>
                <i className="fas fa-times white"/>
              </span>

              <a className="input-group m-r-20 pull-right" id="search_box">
                {isSearchOpen ? 
                  <input type="text" onChange={(e)=>onSearch(e.target.value)} placeholder="Search" className="form-control input-sm"/>
                  :
                  null
                }
                <span className="input-group-btn" onClick={()=>toggleSearch(!isSearchOpen)}>
                  <p type="button" className="bg-highlight input-sm">
                    <i className="fas far fa-search white font-14"/>
                  </p>
                </span>
              </a>

              <span className="m-r-20 pull-right" onClick={()=>setPosition()}>
                <i className="fas fa-exchange-alt white"/>
              </span>

              <span className="m-r-20 pull-right" onClick={()=>setHide(!hide)}>
                <i className={`white ${hide ? 'fa fa-caret-down' : 'fa fa-caret-up'}`}/>
              </span>

              {CheckAccess.isDevUser(currentUser.email) &&
                <Link to={`/help/create?page=${pageId}`} className="m-r-20 pull-right">
                  <i className="fas fa-plus white"/>
                </Link>
              }

              <span className="m-r-20 pull-right" onClick={()=>setMaximized(!isMaximized)}>
                <i className="fas fa-expand white"/>
              </span>
              
            </div>
          </div> 
         
          {!hide ?
            <>
              {loading ?
                <div className="spinner"/>
                :
                <div className="row scroll-y ht-92p">
                  <CategoryList/>
                </div>
              }
            </>  
            : 
            null
          }
        </div>

        {showLocaleModal && 
          <GenericModal component={LocaleList} title="Langauage"
          isOpen={showLocaleModal} toggleModal={toggleLocaleModal}
          onTranslateComplete={onTranslateComplete} 
          selectedItem={selectedHelp}/>
        }

        <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteHelp}/>
      </Fragment>
    , document.body)
  )
}

export default HelpSideNav;