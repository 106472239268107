import React, {Fragment, useState, useContext, useEffect} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../Common/MenuItem"
import useStyle from '../../hooks/useStyle'
import {usePageTitle} from '../../hooks/pageHead'
import useSort from '../../hooks/useSorting'
import SortDirection from '../Common/SortDirection'
import Cs from "../../services/CommonService"
import ConfirmMenu from "../Modals/ConfirmMenu"
import useModal from "../../hooks/useModal"
import SubscriptionService from "./SubscriptionService"
import SubscriptionAccesses from './SubscriptionAccesses'
import GenericModal from '../Modals/GenericModal'
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons"

let selectedSubscription = {}
let requestParams ={}
let dataSources = []
const SubscriptionsTable = (props) => {
  usePageTitle('Subscriptions List')

  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])

  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal()
  const {isOpen:isAccessOpen, toggleModal:toggleAccessModal} = useModal()

  useEffect(() => {
    requestParams = {
      page:1,
      per_page:100,
      organization_id: currentUser.current_organization_id,
      data_source_params: {
        data_source_id:[3678],
      }
    } 
    getAll()
  }, [])

  const getAll = () =>{
    SubscriptionService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSources = res.data.data_source_list
        setList(res.data.subscriptions)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const onDelete = (e, org) => {
    selectedSubscription = org
    toggleDeleteModal(e)
  }

  const deleteOrg = (e) => {
    e.preventDefault();
    SubscriptionService.delete(selectedSubscription).then((res) => {
      if (res.status == 204) {
        setList(list.filter(i => i.id !== selectedSubscription.id))
      }
    })
    toggleDeleteModal(e)
  }

  const updateOrg = (org, key) =>{
    let req = {
      id: org.id,
      [key]: !org[key]
    }
    SubscriptionService.update(req).then((res) => {
      if (res.status == 204) {
        org[key] = !org[key]
        setList([...list])
      }
    })
  }

  const openAccessModal = (o) =>{
    selectedSubscription = o
    toggleAccessModal()
  }

  const tableData = list.map((o, k) => {
    let ds = o.data_source || {}
    let data = o.data || {}

    return(
      <TR key={k}>
        <TD className="text-gray">
          {o.record_id}
        </TD>
        <TD className="fw500">
          {data.name}
        </TD>
        <TD>
          {Cs.formatUpdateDate(o.created_at)}
        </TD>
        <TD>
          <span className={o.is_active?'fas fa-check-circle':'far fa-circle'} onClick={e=> updateOrg(o, 'is_active')}/>
        </TD>
        <TD>
          <span className="far fa-list-alt" onClick={e=>openAccessModal(o)}/>
        </TD>
        <TD className="">
          <div className="flex aligncenter">
            <Link to={`/subscription/create/${o.id}`}>
              <span className="fas fa-edit color-blue2-dark"/>
            </Link>

            <Link to={`/${data.name}/subscription/members/${o.id}`}>
              <span className="fas fa-users color-blue2-dark m-l-15"/>
            </Link>
          </div>
        </TD>
      </TR>
    )
  })

  return (
    <Fragment>
      <div id="ct">
      <div className="app_content">
        <div className="app-header flex justspacebetween m-t-15">
          <div className="flex coldir">
            <div className="app-title m-r-20">Subscription</div>
            <div className="font-14">List of Subscriptions - {list.length}</div>
          </div>
          <div className="flex aligncenter">
            <NavIcon id="new_org" dataTip="Create New Subscription" 
              className="bg-highlight round-btn float-right m-r-5 m-t-4" 
              icon="fas fa-plus"
              link="/subscription/create"/>
          </div>
        </div>
        
      <div className="app-content-section m-t-30 table-fix-head">
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH onClick={e=>sort('record_id')}>
                ID 
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
              </TH>
              <TH onClick={e=>sort('data.name')}>
                Name 
                <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
              </TH>
              <TH onClick={e=>sort('created_at')}>
                Date
                <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
              </TH>
              <TH onClick={e=>sort('is_active')}>
                Active
                <SortDirection sort_column={requestParams.is_active} column="is_active" reverse={requestParams.reverse}/>
              </TH>
              <TH onClick={e=>sort('is_active')}>
                Components
                <SortDirection sort_column={requestParams.is_active} column="is_active" reverse={requestParams.reverse}/>
              </TH>
              <TH>
                Action
              </TH>
            </TR>
          </THead>
          <TBody>
            {tableData}
          </TBody>
        </Table>
      </div>
      </div>
      </div>
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteOrg}/>
      {isAccessOpen && 
        <GenericModal component={SubscriptionAccesses} 
          toggleModal={toggleAccessModal}
          subscription={selectedSubscription} 
          title={selectedSubscription.data.name}
          components={dataSources?.service_options?.options || []}/>
      }
    </Fragment>
  )

}

export default SubscriptionsTable;