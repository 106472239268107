let FormHelper = {}  
  
  FormHelper.setInputTypeValidation = (field, inputAttributes, validationAttributes, position, formFn) => {
  	
    /*if(position == 0){
      inputAttributes.autoFocus = true;
    }*/

    const vo = field.field_validation_options;
  	if(vo){
  		/*if(vo.display_format && vo.display_format!=''){
			inputAttributes['ui-mask'] = vo.display_format;
			if(vo.preserve_display_format){
				inputAttributes['model-view-value'] = true;
			}
		}*/

		/*if(vo.display_filter && vo.display_filter.filter){
			inputAttributes['view-filter'] = null;
			inputAttributes['filter_format'] = vo.display_filter.filter;
			if(vo.display_filter.currency_symbol){
				inputAttributes['currency_symbol'] = vo.display_filter.currency_symbol;
			}else if(vo.display_filter.fraction_size){
				inputAttributes['fraction_size'] = vo.display_filter.fraction_size;
			}
		}*/

		if(vo.validation_type){
			switch (vo.validation_type) {
    	  case 'email':
    			inputAttributes['type'] = "email";
    			validationAttributes.pattern = {
        		value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            validate:true,
           	message: "invalid email address"
          }
        	break;
    		case 'number':
          inputAttributes['type'] = "number"
          inputAttributes['step'] = 'any'
          inputAttributes.inputMode = "numeric"
          if(vo.upper_limit){
            inputAttributes['max'] = vo.upper_limit;
          }

          if(vo.lower_limit){
            inputAttributes['min'] = vo.lower_limit;
          }
          
          if(vo.display_filter.fraction_size){
            inputAttributes['fraction_size'] = vo.display_filter.fraction_size;
          }
    				/*inputAttributes['pattern'] = vo.regex;
    				validationAttributes.pattern = {
            	value: vo.regex,
            	message: "invalid input pattern"
          	}*/
        	break;
        case 'url':
    			inputAttributes['type'] = "url";
          break;
        case 'alpha_numeric':
    			inputAttributes['pattern'] =  vo.regex; 
    			validationAttributes.pattern = {
        		value: vo.regex,
        		message: "invalid input"
        	}
        	break;
        case 'phone_no':
          field.component_type = "phone_no"
          inputAttributes.format = formFn.phone_display_format || vo.display_format 
          inputAttributes.inputMode = "numeric"
    				//inputAttributes['pattern'] = vo.regex;
    				/*validationAttributes.pattern = {
            			value: vo.regex,
            			message: "invalid phone no"
          	}*/
          break;
        case 'counter':
          inputAttributes['type'] = "number";
          break;
			  }
		  }
  	}
  }

  FormHelper.checkDecimalPoint = (value, fraction_size) => {
    if(fraction_size > 0 && value != null){
      return parseFloat(value).toFixed(fraction_size)
    }
    return value
  }

  FormHelper.setMinMaxValidation = (field, inputAttributes, validationAttributes) => {
  	const vo = field.field_validation_options;
  	if(vo){
  	  if(vo.min_length){
		    inputAttributes['minLength'] = vo.min_length;
	    }
	
	    if(vo.max_length){
		    inputAttributes['maxLength'] = vo.max_length;
	    }
  	}
  }

  FormHelper.checkAccessControl = (field, parentAttributes) => {
	  if(field && field.expression){
		  
  	}
  }

  FormHelper.checkFieldConditionality = (field, modal_val, is_tab) => {
	  let visibility = true;
    if(field?.visibility_logic){
		  const f = field.visibility_logic;
		  let e ='';
		  if(f.exp_show && f.exp_show!=''){
			   e = f.exp_show;
		  }

		  if(f.exp_hide && f.exp_hide!=''){
			  if(e!=''){
				  e += ' || '+f.exp_hide;
			  }else{
				  e = f.exp_hide;
			  }
		  }
      
      try{
        visibility = eval(e);
	    }catch(e){
        console.log(e)
        visibility = false;
      }
    }
    if(!visibility){
      is_tab ? 
        FormHelper.removeTabData(field.child_template_fields, modal_val)
        :
        FormHelper.removeFieldData(field, modal_val)
    }
    return visibility;
  }

  FormHelper.removeFieldData = (field, form_data) =>{
    if(['checkbox', 'multi_select', 'input_tags'].indexOf(field.component) > -1){
      delete form_data[field.client_id]
      delete form_data[field.client_id+'_array']
    }else{
      delete form_data[field.client_id]
    }
  }

  FormHelper.removeTabData = (child_template_fields, form_data) =>{
    for (let i = 0; i < child_template_fields?.length; i++) {
      FormHelper.removeFieldData(child_template_fields[i], form_data)
    }
  }

  FormHelper.check_isNaN = (field) => {
	  if(!isNaN(field.client_id)){
		  field.client_id = 'field_'+field.client_id;
	  }
  }

  FormHelper.setDisabled = (field, inputAttributes) => {
  	if(field.read_only == 'yes'){
		  inputAttributes['disabled'] = true;
  	}	
  }

  FormHelper.initializeValue = (field, modal_val, form_mode) => {
	  if(form_mode == 'create-form-submissions' && field.default_value!=null){
		  if('radio select checkbox'.includes(field.component)){
			  modal_val[field.client_id] = field.default_value;
		  }
	  }
  }

  FormHelper.setTouched = (e, form) => {
  	form[e.target.name].touched = true;
  }

  FormHelper.setDatePicker = (field, inputAttributes) => {
    const vo = field.field_validation_options;
    field.dateFormat = "YYYY-MM-DD";
    if(vo){
      field.dateFormat = vo.display_format || field.dateFormat;
      if(vo.is_future_date?.indexOf('future') > -1){
        inputAttributes.minDate = new Date();
      }
      if(vo.is_future_date?.indexOf('past') > -1){
        inputAttributes.maxDate = new Date();
      }
    }
    if(field.component == 'timepicker'){
      inputAttributes.showTimeSelect = true;
    }
  }

  /*
    @params d:data
    @params ds:data_source
  */
  FormHelper.onChangeExp = (field, d, ds) => {
    try{
      if(field.on_change_expression){
        d[field.client_id] = eval(field.on_change_expression);
        console.log(field.client_id)
        console.log(d[field.client_id])
      }
    }catch(e){
      console.error(e.message)
    }
  }

  FormHelper.isNumeric = (value) => {
    if(isNaN(value)){
      return value
    }

    return parseInt(value);
  }

  FormHelper.checkFieldValidity = (field, data, errors, pkey) => {
    const name = field.client_id;
    if(field.required){
      FormHelper.setErrorFields(field, errors, pkey)
      if(data[name]==null || data[name]===''){
        errors.invalid[name] = true;
        errors[name].invalid = true;
      }
    }else if(['textInput'].indexOf(field.component)>-1 && !field.required){
      FormHelper.setErrorFields(field, errors, pkey)
    } 

    /*const notRequiredValidationField = (['textInput'].indexOf(field.component)>-1
    if(field.required || notRequiredValidationField){
      FormHelper.setErrorFields(field, errors, pkey)
      if(notRequiredValidationField && !field.required){
        errors.invalid[name] = false;
        errors[name].invalid = false;
      }elseif(data[name]==null || data[name]===''){
        errors.invalid[name] = true;
        errors[name].invalid = true;
      }
    }*/
  }

  FormHelper.setErrorFields = (field, errors, pkey) =>{
    const name = field.client_id;
    errors[name] = errors[name] || {};
    errors[name].required = field.required;
    errors[name].label = field.label;
    errors[name].id = field.client_id;
    errors[name].tab_index = pkey;
  }

  FormHelper.setErrorState = (errors, name, state) =>{
    errors[name].invalid = state;
    if(!state)
      errors[name].errormsg = null;
      delete errors.invalid[name]
  }
      
  FormHelper.setLabelStyle = (field, isFormWizard, parentAttributes) =>{
    let labelAttributes = {
      'id':'label_'+field.client_id
    }

    if(isFormWizard){
      labelAttributes.className=`regTitles2 ${field.required && 'req-field'}`;
      parentAttributes.className="form-group col-xs-12"
    }else{
      labelAttributes.className=`form-label m-b-10 ${field.required && 'req-field'}`;
      parentAttributes.className=`br-blue-hov br-btm-dgrey ${field.no_of_column == '1'?'col-xs-12':'col-md-6'}`;
    }

    return labelAttributes;
  }

  FormHelper.setFormTitle = function(component, modal_val, value, data_source){
    try{  
      if(component.is_form_title){
        if(component.component == 'multi_select' || component.component == 'checkbox'){
          const o = data_source.options.reduce((obj, item) => (obj[item.value] = item, obj) ,{});
          let t = [];
          value.map((i)=>{
            t.unshift(o[i])
          })
          modal_val.record_title = t.join(' ');
        }else if(data_source){
          const o = data_source.options.reduce((obj, item) => (obj[item.value] = item, obj) ,{});
          modal_val.record_title = o[value].label;
        }else{
          modal_val.record_title = value;  
        }
      }
    }catch(e){
        
    } 
  }

  FormHelper.validate = (form_id, data, error, setError) =>{
    const ele = document.getElementById(form_id)
    if(!ele)return;
    error = {is_valid:false}
    ele.querySelectorAll("[required]").forEach((i)=> {
      error[i.name] = error[i.name] || {}  
      if(Array.isArray(data[i.name]) && data[i.name].length == 0){
        error[i.name].invalid = true
      }else if(data[i.name] ?? false){
        delete error[i.name]
      }else{
        error[i.name].invalid = true
      } 

      /*if (!allAreFilled) return
        if (!i.value) allAreFilled = false
        if (i.type === "radio") {
          let radioValueCheck = false
          document.getElementById(form_id).querySelectorAll(`[name=${i.name}]`).forEach((r) => {
            if (r.checked) radioValueCheck = true
          })
          allAreFilled = radioValueCheck
      }*/

    })
    error.is_valid = Object.keys(error).length == 1;
    setError({...error})
  }

  FormHelper.toggleListString = (item, list = [], setter) => {
    const idx = list.indexOf(item);
    if(idx > -1) {
      list = list.filter(i => i !== item)
    }else {
      list.push(item)
    }
    setter([...list])
  }

  FormHelper.existsListString = (item, list = []) => {
    return list.indexOf(item) > -1
  }

  FormHelper.validateEmails = (mails) =>{
    const emails = mails.replace(/\s/g,'').split(",");
    let invalidEmails = []
    for (let i = 0; i < emails.length; i++) {
      if( emails[i] == "" || ! FormHelper.validateEmail(emails[i])){
        invalidEmails.push(emails[i])
      }
    }
    return invalidEmails
  }

  FormHelper.validateEmail = (email) =>{
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  
  FormHelper.searchObjList = (list, term, type='start_with') =>{
    const p = new RegExp('^' + term, 'i');
    return list.filter(i => (p.test(i.label))) 
  }

  FormHelper.searchList = (list, term, type='start_with') =>{
    const p = new RegExp('^' + term, 'i');
    return list.filter(i => (p.test(i))) 
  }

export default FormHelper;