import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import {Workbox} from 'workbox-window';

const registerSw = async () => {
	/*if ('serviceWorker' in navigator) {
        const {Workbox} = await import('workbox-window');

        const wb = new Workbox('/sw.js');
        wb.register();
        
	}*/
	if ('serviceWorker' in navigator) {
		var registrations = await navigator.serviceWorker.getRegistrations();
		if (registrations.length > 0) {
			for (const registration of registrations) {
                await registration.unregister();
            }
        }
        return
		
	    const wb = new Workbox('/sw.js');

	    wb.addEventListener('installed', event => {
	      /**
	       * We have the condition - event.isUpdate because we don't want to show
	       * this message on the very first service worker installation,
	       * only on the updated
	       */
	      if (event.isUpdate) {
	        if (window.confirm(`New app update is available!. Click OK to refresh`)) {
	          window.location.reload();
	        }
	      }
	    });
	    wb.register();
  	}
}
registerSw()
ReactDOM.render(<BrowserRouter><App /></BrowserRouter>, document.getElementById('page'));