import React, {useContext, useEffect} from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../services/CommonService"
import AssessmentService from "../AssessmentService"
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import StepAssessments from "./StepAssessments"
import ReactTooltip from "react-tooltip"
import Tabs, { TabPane } from "rc-tabs"
import { ChatGrayIcon, HamburgerMenuIcon, MaximizeIcon, BookmarkGrayIcon, CheckListGrayIcon, EnvelopeGrayIcon } from "../../../Common/ImageIcons"

const Assessment = (props) => {
  const {state: { screen, user: currentUser }, dispatch} = useContext(AuthContext)

  let {assessment, assessmentList} = props
  assessment.updated_at = Cs.formatUpdateDate(assessment.updated_at)
  let data = assessment.data
  let ds = props.dataSource

  const cardStyle = {backgroundColor: (assessment.course?.color || '#607D8B')}

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  if (!data) return null

  const handleClick =(id)=> {
      console.log('scroll')
      //document.getElementById('info').scrollTop -= 10;
      document.getElementById('info_'+id).scrollTop += 10;

  }

  const Header = () => (
    <div className="card-title card-title-gray flex p-b-15 p20 coldir"> 
      <h4 className="fw700 f18 card-heading flex coldir text-left">
        {data.subject_name}
      </h4>
      <div className="f14 m-t-10 fw500">{assessment.author_name}-{Cs.formatUpdateDate(assessment.updated_at)}</div>
      <MaximizeIcon onSelect={(e) => props.updatePreview(e, assessment)}/>
    </div>
  )

  const routeAnswer = (assessment) => {
    props.history.push(`/answers/${assessment.id}`)
  }

  const Footer = () => (
    <div className="card-footer flex justspacebetween p20 aligncenter">
      <ul className="flex aligncenter">
        <li className="m-r-25">
          <ChatGrayIcon onSelect={(e) => props.addNotes(e, assessment)}/>
        </li>
        <li className="m-r-25" onClick={(e) => props.addChecklist(e, assessment)}>
          <CheckListGrayIcon count={assessment.check_list_count || 0} tooltip="Create/manage checklist"/>
        </li>
        <li className="m-r-25" onClick={e => routeAnswer(assessment)}>
          <EnvelopeGrayIcon count={assessment.assessment_response_count || 0} tooltip="View test responses"/>
        </li>
      </ul>
      <HamburgerMenuIcon onSelect={(e)=>props.addMenu(assessment)}/>
    </div>
  )

  const Card = () => {
    return (
      <div className="bg-white border-rounded-10 card-shadow m-b-15">
        <Header />
        <div className="card-content p20">
          <div className="b-tab-table active scroll-auto ht-400">
            <div className="col-xs-12">
              <div className="pull-left p-5 text-center"> 
                <h5 className="fw500 m-b-5">Start</h5>
                {Cs.formatUpdateDate(data.date_time, 'MMM DD, YYYY hh:mm a') || '-'}
              </div>
              <div className="pull-right p-5 text-center">  
                <h5 className="fw500 m-b-5">Submit by</h5>
                {Cs.formatUpdateDate(data.submission_deadline, 'MMM DD, YYYY hh:mm a') || '-'}
              </div>
            </div>
            <div className="clear">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Details" key="1">
                  <div id={`info_${props.index}`} className="p-10" dangerouslySetInnerHTML={{ __html: data.details}}></div>    
                </TabPane>
                <TabPane tab="Questions" key="2">
                  <StepAssessments assessment={assessment} stepAssessments={assessment.step_assessments} 
                    openQuestionModal={props.openQuestionModal} typeOfQuestion={props.typeOfQuestion}/>
                </TabPane>
              </Tabs>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
    )
  }

  return(<Card/>)
}

export default Assessment;