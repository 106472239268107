import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../../PreLoadSpinner"
import DynamicForm from "../../../../FormBuilder/DynamicForm"
import FormTemplateId from "../../../../../constants/FormTemplateList"
import PlannerService from "../PlannerService"
import querystringify from "querystringify"
import FormContainer from '../../../../../components/FormBuilder/FormContainer'
import env from '../../../../../env'

let formMode = null
let planner = {}
let data = {}
let formFn = {}

function PlannerForm(props) {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    PlannerService.get(params.id).then((res) => {
      if (res.status == 200) {
        planner = res.data.planner[0]
        data = planner.data || {}
        //params.form_template_id = planner.planner_form_id;

        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    }).catch(function (res) {
      planner = null;
    })
  }

  useEffect(() => {
    planner = {}
    data = {}
    formFn = {
      form_type: "health.planner",
      editPermission:queryParam.edit,
      localeKey:'language',
    }
    formMode = "create-form-submissions";
    data = {}

    if (params.id) {
      get()
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (planner) => {
    planner.created_by = currentUser.id;
    planner.updated_by = currentUser.id;
    planner.journey_profile_id = params.journey_profile_id;
    planner.planner_form_id = queryParam.planner_form_id || FormTemplateId.HealthPlannerForm;
    planner.organization_id = currentUser.current_organization_id
    PlannerService.create(planner).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect()
      }
    })
  }

  const update = (planner) => {
    planner.updated_by = currentUser.id;
    PlannerService.update(planner).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/health/planner`)  
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post}>
        <DynamicForm
          formMode={formMode}
          formId={queryParam.planner_form_id || FormTemplateId.HealthPlannerForm}
          form={planner}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}/>
      </FormContainer>
    )
  } else {
    return null;
  }
}

export default PlannerForm;