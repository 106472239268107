import env from '../../../env';
import BaseService from "../../../services/BaseService";
import axios from 'axios';
const url = 'health/organization_member'
class OrganizationMemberService extends BaseService {
	
	getJourneyProfiles(req){
	  return new Promise((resolve, reject) => {
	    axios.post(`${env.health_backend}${url}/journey_profiles`, req).then((res) => {
	        // handle success
	        resolve(res);
	    })
	  })
  	}

  	getGeoData(req){
	  return new Promise((resolve, reject) => {
	    axios.post(`${env.health_backend}${url}/geo_data`, req).then((res) => {
	        // handle success
	        resolve(res);
	    })
	  })
  	}

  	sendInvite(req){
	  return new Promise((resolve, reject) => {
	    axios.post(`${env.health_backend}${url}/send_invite`, req).then((res) => {
	        // handle success
	        resolve(res);
	    })
	  })
  	}

  	async updateReport(req){
	    let res = await axios.post(`${env.health_backend}${url}/report/refresh`, req)
	    return res
  	}
  	
  	async search(req){
	    let res = await axios.get(`${env.health_backend}${url}/search`, {params: req})
	    return res
  	}
}

export default new OrganizationMemberService('health_backend', `${url}s`);