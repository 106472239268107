import React from "react"

const HealthTitle = (props) => {
  let project = props.project;
  let title = props.title;

  return (
    <>
    	{project &&
        <div>
          <span className="font-16 bold-600">{project.data.mother_name || project.data.patient_name +" |"}</span>
          <span className="m-l-5">{project.data.district}</span>
   		  </div>
      }
    	<div className="font-15">
        {title}         
      </div> 
  	</>
  )
}

export default HealthTitle;


