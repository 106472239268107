import React, {useState, useRef, useEffect} from 'react';
import {CueTip} from '../../../components/Common/CueTooltip';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, Description, requiredMsg} from '../FieldLabel'
import Cs from '../../../services/CommonService';

const SEARCH_WAIT_INTERVAL = 500;
//let searchKeyword = null;
let timer = null;

const InputAutoComplete = ({labelLocale, renderField, field, position, formData, readOnly, errors, forceUpdate, formFn, initDataLoad, isFormWizard, openDataSourceModal, openFieldLabelModal, currentLocale}) => {
  let pagenate = formFn[field.client_id+'_pagenate'] || {};
  let path = formFn[field.client_id+'_path']|| 'label';
  let op_kv = field.options_key_value;

  //searchKeyword = formData[field.client_id];
  const label = formData[field.client_id+'_label'] || formData[field.client_id]
  let [search, setSearch] = useState(label);
  const [list, setList] = useState([]);
  const [showError, setShowError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [noResultFound, setNoResultFound] = useState(false);
  //const inputEl = useRef(null);

  useEffect(()=>{
    setSearch(label)
  }, [label])

  useEffect(() => {
    try{  
      if(renderField && !search && initDataLoad)
        formFn['get_'+field.client_id](search, onSuccessCallback, onFailureCallback);
    }catch(e){
      console.log(e)
    }
  }, [])
  
  if(!renderField){
    return null
  }

  let inputAttributes = {
    'type':'text',
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': labelLocale(5),
    'disabled':(readOnly || field.read_only),
    'className':'form-input form-input-gray form-input-full'
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes);

  let validation = {
    required: field.required
  }

  FormHelper.setDisabled(field, inputAttributes);
  
  const handleChange=(event)=>{
    clearTimeout(timer);
    search = event.target.value
    setSearch(search);
    pagenate.page = 1; 
    timer = setTimeout(onSearch, SEARCH_WAIT_INTERVAL);
  }

  const onSearch = () => {
    if(search && search != ''){
      try{
        setNoResultFound(false)
        setLoading(true)
        formFn['get_'+field.client_id](search, onSuccessCallback, onFailureCallback);
        //showResultSet = true; 
        //onChange(search);
      }catch(e){
        setLoading(false)
        console.log(e)
      }
    }else{
      setList([]);  
      formData[field.client_id] = null;
      setError(true);
    }
  }

  const onItemSelect = (item) => {
   /* item = FormHelper.isNumeric(item.value);
    formData[field.client_id] = item.value;*/
    formFn['set_'+field.client_id](item);
    //inputEl.current.value = formData[field.client_id];
    setSearch(formData[field.client_id+'_label'] || formData[field.client_id])
    setList([]);
    setError(true); 
    setTimeout(function() {
      forceUpdate();
    }, 0);
  }

  /*execute a function when someone clicks in the document:*/
  /*document.addEventListener("click", function (e) {
    setList([]);
  })*/

  const next = (e) => {
    e.preventDefault();
    if((pagenate.page+1) <= pagenate.pageSize){
      pagenate.page += 1; 
      onSearch();
    }
  }

  const previous = (e) => {
    e.preventDefault();
    if((pagenate.page - 1) > 0){ 
      pagenate.page -= 1; 
      onSearch();
    }
  }

  const onSuccessCallback = (data) =>{
    if(data.length == 0) setNoResultFound(true);
    setList(data);
    setLoading(false);
  }

  const onFailureCallback = (error) =>{
    setList(error);
    setLoading(false)
  }

  /*Load data source dynamically*/
  if(field.data_source_id){
    formFn['get_'+field.client_id] = (search, onSuccessCallback, onFailureCallback) =>{
      Cs.searchDataSource({
        page: pagenate.page || 1,
        per_page: 100,
        data_source_id: [field.data_source_id],
        search: search,
        is_form_field:true,
        sort_column:'id',
        sort_direction:'asc'
      }).then((res)=>{
        pagenate.pageSize = res.meta.last_page
        onSuccessCallback(res.data_source_lists)
      })
    }

    formFn['set_'+field.client_id] = (item) =>{
      formData[field.client_id] = item.value;
      formData[field.client_id+'_label'] = item.label;
      setSearch(item.label)
    }
  }

  const setError = (reset_state) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[field.client_id]==null?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
      if(reset_state){
        setShowError(!showError);
        formFn.refreshFormSubmit();  
      }
    }
  }

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{requiredMsg}</div>
        </div>
      )
    }else if(noResultFound){
      return(
        <div className="errormsg">
          <div>No Result Found</div>
        </div>
      )
    }
    return null;
  }

  const highlight = (text, phrase) => {
    if (phrase) text = text.toString();text = text.replace(new RegExp('('+phrase+')', 'gi'),'<strong>$1<strong>')
    return <span dangerouslySetInnerHTML={{ __html: text }} />
  }

  const searchResult = list.map((r, k) =>  
    <div key={k} onClick={e => onItemSelect(r)}>{highlight(r[path])}</div>
  )

  return (
  <div {...parentAttributes}>
    <div className="flex coldir m-b-20 tooltip">
      <CueTip 
          positionCss={position>1?'top':'bottom'}
          tooltip={field.tooltip}
          currentLocale={currentLocale}
          locale={field.locale?.tooltip}/>
      <FieldLabel field={field} labelAttributes={labelAttributes} 
        currentLocale={currentLocale}
        openFieldLabelModal={openFieldLabelModal}/>
    	<div className="autocomplete">
        {loading && <div className="spinner"></div>}
        <input {...inputAttributes} value={search} onChange={e => handleChange(e)} 
        data-tip data-for={`tooltip_${field.client_id}`} />
        <div id="autocomplete-list" className="autocomplete-items">
          { (pagenate.pageSize>1 && list.length>0) &&
            <ul className="pager">
              <li onClick={e => previous(e)}><a href="/">Previous</a></li>
              <li onClick={e => next(e)}><a href="/">Next</a></li>
              <small>{pagenate.page} of {pagenate.pageSize}</small>
            </ul> 
          }
          {searchResult}
        </div> 
        <Description description={field.description}/>
        <Error/>
      </div>
    </div>
  </div>
  )
}

export default InputAutoComplete;