import React, { Fragment, useState, useEffect, useContext} from 'react'
import { AuthContext } from '../../contexts/Authentication/AuthStateProvider'
import AuthenticationService from '../../services/AuthService'
import SubscriptionAccessesService from './SubscriptionAccessesService'
import useStyle from '../../hooks/useStyle'
import ReactDOM from 'react-dom'
import Cs from '../../services/CommonService'

const SubscriptionAccesses = (props) => {
  useStyle('dashboard')	
  
  const {subscription} = props

  const { state: { screen, user: currentUser }, dispatch} = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  let [selectedComponents, setSelectedComponents] = useState({})
  let [components, setComponents] = useState({})

  useEffect(() => {
    getAccessComponents()
  }, [])

  const getAccessComponents = () =>{
	  setLoading(true)
  	const req = {
  	  subscription_id: subscription.id
  	}
    SubscriptionAccessesService.getAll(req).then(({status, data})=>{
      if(status == 200){
        setSelectedComponents(Cs.listToObj(data.subscription_accesses, 'data_source_list_id'))
		    setComponents(data.journey_components)
        setLoading(false)
      }
    })
  }

  const createOrRemove = (selected_component, component, data_source_id) =>{
  	if(selected_component){
  	  	SubscriptionAccessesService.delete(selected_component.id).then((res)=>{
	      if(res.status == 204){
	        selectedComponents[selected_component.data_source_list_id] = null
	        setSelectedComponents({...selectedComponents})
	      }
	    })	
  	}else{
  		const req = {
	  	  'subscription_id':subscription.id,
	  	  'component_sid':component.sid,
	      'data_source_id':data_source_id,
	      'data_source_list_id':component.pk_id  ,
	  	}
	    SubscriptionAccessesService.create(req).then((res)=>{
	      if(res.status == 201){
	        selectedComponents[component.pk_id] = res.data.access
	        setSelectedComponents({...selectedComponents})
	      }
	    })
  	}
  } 

  /*const updateAccesses = (selected_component, req) =>{
    SubscriptionAccessesService.update(req).then((res)=>{
      if(res.status == 204){
        selectedComponents[selected_component.data_source_list_id].locale_data = req.locale_data
        setSelectedComponents({...selectedComponents})
      }
    })
  }*/

  if(loading){
    return(
      <div className="spinner"/>
    )
  }

  return(
  	<div className="p-15 vh-80 scroll-y">
      <JourneyType 
        journeyComponent={components[3737]} 
        createOrRemove={createOrRemove}
        selectedComponents={selectedComponents}/>

      {subscription?.data_source?.journeys?.map((c, i) =>
        <Fragment key={i}>
          <JourneyType journeyType={c} 
            journeyComponent={components[c.data.ppt_ds]} 
            createOrRemove={createOrRemove}
            selectedComponents={selectedComponents}/>

    		  <JourneyType journeyType={c} 
            journeyComponent={components[c.data.process_ds]} 
            createOrRemove={createOrRemove}
            selectedComponents={selectedComponents}/>

          <JourneyType journeyType={c} 
            journeyComponent={components[c.data.portal_ds]} 
            createOrRemove={createOrRemove}
            selectedComponents={selectedComponents}/>
        </Fragment>
      )}
  	</div>
  )
}

const JourneyType = ({journeyType, journeyComponent, createOrRemove, selectedComponents}) => {
  return (
    <div className="m-t-5 br-btm-grey">
      <h5>{journeyComponent.label}</h5>
      <AccessList journeyType={journeyType} 
        dataSourceId={journeyComponent.id}
        componentList={journeyComponent.options}
        createOrRemove={createOrRemove}
        selectedComponents={selectedComponents}
      />
      <span className="font-9">{journeyComponent.id}</span>
    </div>
  )
}

const AccessList = ({journeyType, componentList, createOrRemove, selectedComponents, dataSourceId}) => componentList?.map((c, i) => {
  const component = selectedComponents[c.pk_id]

  return(
    <div key={i} className="m-b-5" onClick={e=>createOrRemove(component, c, dataSourceId)}>
      <span className={component?'font-20 far fa-check-square text-muted':'font-20 far fa-square text-muted'}/>
      <span className="m-l-15 font-14">{c.label}</span>
    </div>
  )
})

export default SubscriptionAccesses;