import React, {useState, Fragment, useEffect, useContext} from "react"
import {Link} from "react-router-dom"
import moment from 'moment/min/moment.min'
import useStyle from '../../../../hooks/useStyle'
import useSort from '../../../../hooks/useSorting'
import SortDirection from '../../../Common/SortDirection'
import Cs from '../../../../services/CommonService'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import AnswerService from "../AnswerService"
import {NavIcon, CardMenuInfo, MenuItem, CardMainMenu} from '../../../Common/MenuItem'
import {OptionsObjList} from "../../../Common/FormInput"
import {RecordAudio, RecordAudioSafari} from '../../../Common/RecordAudio'
import Tabs, { TabPane } from 'rc-tabs'
import useModal from "../../../../hooks/useModal"
import ConfirmMenu from "../../../Modals/ConfirmMenu"

let requestParams = {}

const AnswerValidation = (props) => {
  useStyle('table')
  
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [answer, setAnswer] = useState()
  const [answerFields, setAnswerFields] = useState([])
  const [loading, setLoading] = useState(true) 
  const [errorMsg, setErrorMsg] = useState()
  
  const { isOpen:isResultModalOpen, toggleModal:toggleResultModal } = useModal()

  useEffect(() => {
    getAnswerValidation()
  }, [])

  let getAnswerValidation = () => { 
    setLoading(true)
    AnswerService.getAnswerFields({
      answer_id:props.answerId, 
      assessment_id:props.assessmentId
    }).then((res)=>{
      if(res.status == 200){
      	setAnswer(res.data.response)
        setAnswerFields(res.data.response_fields)
        setLoading(false)
      }
    })
  }

  const submitRemark = (event) =>{
    event.preventDefault()
    if(answer.post_assessment_id === null){
      setErrorMsg('Please select follow up action in Remarks')
      toggleResultModal(event)
    }else if(answer.assessment_notes === (null || "")){
      setErrorMsg('Please fill notes in Remarks')
      toggleResultModal(event)
    }else{
      setErrorMsg('')
      setLoading(true)
      answer.calculate_total_score = true
      AnswerService.update(answer).then((res)=>{
        if(res.status == 204){
          setLoading(false)
          toggleResultModal(event)
        }
      })
    }
  }

  const updateAnswerField = (e, answer_field) =>{
    answer_field[e.target.name] = e.target.value
    AnswerService.updateAnswerField(answer_field).then((res)=>{
      if(res.status == 204){

      }
    })
  }

  const handleInputChange = (event) => {
    setAnswer({
      ...answer,
      [event.target.name]: event.target.value,
    })
  }

  const tableData = answerFields.map((q, k) =>{
    if(q.input_type == 'checkbox' && !q.question_key.includes('_array')){
      return null
    }

    return(
      <tr key={k}>
        <td data-title="No." className="text-cap">{k+1}</td>
        <td data-title="Question" className="text-cap">
          <div className="bold-600 font-14" dangerouslySetInnerHTML={{ __html: q.question }}/>
        </td>
        <td data-title="Expected Answer" className="text-cap">
          <div className="font-15">{q.multi_choice_answer || q.answer}</div>
        </td>
        <td data-title="Student Answer" className="text-cap">
          <div className="font-15" dangerouslySetInnerHTML={{ __html: (q.text_answer || q.option_answer) }}></div>
        </td>
        <td data-title="Score" className="">
          <div className="font-12">Point {q.point}</div>
          <input type="number" name="earned_score" onChange={e=>updateAnswerField(e, q)} 
          min="0" step="0.5" max={q.point} className="form-control input-sm font-15" 
          placeholder="Assign point" defaultValue={q.earned_score}/>      
        </td>
      </tr>
  	)
  })

  if(loading){
    return(<div className="spinner"/>)
  }

  return (
    <Fragment>
      <div className="row m-l-5">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Answer Sheet" key="1">
            <div className="col-xs-12 table-responsive">
              <div className="scrollable">
              <table className="shadow-small">
                <thead>
                  <tr className="white bg-black">
                    <th>No. 
                    </th>
                    <th>Question 
                    </th>
                    <th>Student Answer 
                    </th>
                    <th>Expected Answer 
                    </th>
                    <th>Score
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData}
                </tbody>
              </table>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Instructor’s Remarks" key="2">
            <form className="ml-container">
                <div className="ml-section">
                  <label>Follow up actions <span className="m-l-5 lred">*</span></label>
                  <select onChange={e=>handleInputChange(e)} name="post_assessment_id" 
                    value={answer.post_assessment_id} className="form-control ml-margin-bottom" required>
                    <option value="" disabled selected>Select an item from list below</option>
                    <OptionsObjList list={props.dataSource?.post_assessment?.options || []} id_key="pk_id" label_key="label"/>
                  </select>

                  <label>Notes <span className="m-l-5 lred">*</span></label>
                  <textarea onChange={e=>handleInputChange(e)} name="assessment_notes"
                    className="form-control ml-margin-bottom" 
                    placeholder="Notes" 
                    defaultValue={answer.assessment_notes} required>
                  </textarea>

                  <label>Audio Message</label>
                  <RecordAudioSafari/>
                </div>
              </form>
          </TabPane>
        </Tabs>
        <span className="m-l-5 lred">{errorMsg}</span>
          <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=> toggleResultModal(e)}>
            <b>Submit</b>
          </div>
      </div>
      <ConfirmMenu isOpen={isResultModalOpen} toggleModal={toggleResultModal} success={submitRemark} message="Are you sure that the grading step is complete?"/>
    </Fragment>
  )

}

export default AnswerValidation;