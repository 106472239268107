import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../PreLoadSpinner"
import DynamicForm from "../FormBuilder/DynamicForm"
import FormTemplateId from "../../constants/FormTemplateList"
import SubscriptionService from "./SubscriptionService"
import querystringify from "querystringify"
import FormContainer from '../FormBuilder/FormContainer'
import GenericModal from '../Modals/GenericModal'
import useModal from "../../hooks/useModal"
import env from "../../env"

let formMode = null
let subscription = {}
let data = {}
let formFn = {}

function SubscriptionForm(props) {
  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const params = props.match.params
  const path = props.match.path

  const get = () => {
    SubscriptionService.get(params.id).then((res) => {
      if (res.status == 200) {
        subscription = res.data.subscription
        data = subscription.data || {}
        setFormRendered(true)
      }
    }).catch((res) => {
      subscription = null
    })
  }

  useEffect(() => {
    subscription = {}
    data = {}
    formFn = {
      form_type: "health.subscription",
      editPermission: env.admin_emails.includes(currentUser.email)
    }
    formMode = "create-form-submissions"
    data = {}
    if (params.id) {
      get()
      formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (subscription) => {
    SubscriptionService.create(subscription).then(({status, data}) => {
      if (status == 201) {
        onSubmitPageRedirect()
      }
    })
  }

  const update = (subscription) => {
    SubscriptionService.update(subscription).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = (cancel) => {
    props.history.push('/list/subscriptions')
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode}
          formId={FormTemplateId.SubscriptionForm}
          form={subscription}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
      </FormContainer>
    )
  } else {
    return null
  }
}

export default SubscriptionForm;