import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../../PreLoadSpinner"
import DynamicForm from "../../../../FormBuilder/DynamicForm"
import FormTemplateId from "../../../../../constants/FormTemplateList"
import ActivityService from "../ActivityService"
import querystringify from "querystringify"
import FormContainer from '../../../../../components/FormBuilder/FormContainer'
import GeoLocation from "../../../../../components/Common/GeoLocation"
import Cs from "../../../../../services/CommonService"

let formMode = null
let activity = {}
let data = {}
let formFn = {}

function ActivityForm(props) {
  const { state: authState, dispatch } = useContext(AuthContext)
  
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  const [lastActivity, setLastActivity] = useState()

  const childInfo =  props.location.state
  const params = props.match.params
  const queryParam = querystringify.parse(props.location.search)
  const formReportType = (queryParam.activity_form_id==FormTemplateId.AssessBfForm)?'assess_bf_favorable_count':null
  const isMCJMeasureGrowthForm = FormTemplateId.McjMeasureGrowthForm == queryParam.activity_form_id

  /*TODO: Move API to Router to load data before view*/
  /*last_activity -> get last submission*/
  /*parent_activity -> get previous submission from current activity*/
  const get = (fields) => {
    const req = {
      id: params.id,
      report_type: formReportType,
      journey_profile_id: params.journey_profile_id,
      activity_form_id:queryParam.activity_form_id,
      fields:fields
    }
    ActivityService.getById(req).then((res) => {
      if (res.status === 200) {
        activity = {...res.data.activity, ...activity.geo_data}
        data = {...data, ...activity.data}
        setLastActivity(res.data.last_activity)
        setAssessBfFavorable(res.data.reports) 
        setFormRendered(true)
      }
    }).catch(function (res) {
      setFormRendered(true)
      activity = null
    })
  }

  useEffect(() => {
    formFn = {
      'form_type': 'health.activity',
      'journey_profile_id': params.journey_profile_id
    }
    activity = {}
    data = {}
    setChildInfo()
    setFormMode()
  }, [])

  const setFormMode = () =>{
    if (params.id) {
      get(isMCJMeasureGrowthForm?['parent_activity']:null)
      formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      formMode = "create-form-submissions"
      if(formReportType || isMCJMeasureGrowthForm){
        if(params.journey_profile_id){
          get(['last_activity'])
        }else{
          setFormRendered(true)
        }
      }else{
        setFormRendered(true)
      }
    }
  }

  const setChildInfo = () =>{
    if(childInfo){
      const temp = {
        baby_age: childInfo.baby_age,
        child_fk_id: childInfo.child_fk_id,
        dob_baby_formatted_date: childInfo.dob_baby_formatted_date,
        id_mother: childInfo.id_mother
      }
      Object.assign(data, temp) 
    }
  }

  const setAssessBfFavorable = (reports=[]) =>{
    formFn.assess_bf_favorable = Cs.listToObj(reports, 'client_id')
  }

  const checkBfReport = (field) =>{
    //fields selected positive options 3 consecutive times
    return formFn.assess_bf_favorable && formFn.assess_bf_favorable[field.client_id]?.value == 1  
  }

  const resetError = (error) =>{
    error.invalid = false
    error.err_msg = null
  }

  formFn.on_select_height_baby = (height, error) =>{
    try{
      const lastHeightMeasurement = lastActivity.data_source ? lastActivity.data_source.height_baby.label : childInfo.birth_height
      if(height.label != null && parseFloat(height.label) < parseFloat(lastHeightMeasurement)){      
        /*Remove error msg if err_msg already set*/
        if(formFn.isHeightErrorAlreadySet){
          resetError(error)
          data.is_invalid_height = true
        }else{
          error.invalid = true
          error.err_msg = `The length you have just entered may be incorrect based on the data you previously entered.
          Please check and re-enter the length.`
          formFn.isHeightErrorAlreadySet = true
        }
      }else{
        resetError(error)
      }
    }catch(e){
      console.error(e.message)
    }
  }

  const create = (activity) => {
    activity.organization_id = authState.user.current_organization_id
    activity.created_by = authState.user.id
    activity.updated_by = authState.user.id
    activity.journey_profile_id = params.journey_profile_id
    activity.activity_form_id = parseInt(queryParam.activity_form_id) || 440
    activity.activity_type = queryParam.label || "Activity"
    activity.parent_activity_id = lastActivity?.id
    activity.schedule_id = params.schedule_id
    activity.member_role_id = authState.user.current_member?.role_record_id
    ActivityService.create(activity).then((res) => {
      if (res.status === 201) {
        onSubmitPageRedirect(res.data.activity)
      }
    }).catch((res) => {
      onSubmitPageRedirect()
    })
  }

  const update = (activity) => {
    activity.updated_by = authState.user.id
    activity.member_role_id = authState.user.current_member?.role_record_id
    ActivityService.update(activity).then((res) => {
      if (res.status === 204) {
        onSubmitPageRedirect()
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = (activity) => {
    if(props.isPopupMode){
      props.onFormSubmit(activity)
    }else if(queryParam.activity_form_id){
      props.history.goBack()
      /*props.history.push(
        `/infant/activities/${params.journey_profile_id}?activity_form_id=${queryParam.activity_form_id}`
      )*/  
    }else{
      props.history.push(
        `/health/activities/${params.journey_profile_id}`
      )  
    } 
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode}
          formId={queryParam.activity_form_id || FormTemplateId.HealthActivityForm}
          form={activity}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          fieldShowHideFn={formReportType=='assess_bf_favorable_count' && checkBfReport}
          isPopupMode={props.isPopupMode}/>
        <GeoLocation geoData={activity}/>
      </FormContainer>
    )
  } else {
    return null
  }
}

export default ActivityForm;