import React, {useState, Fragment, useEffect,useContext,useRef} from "react"
import querystringify from "querystringify"
import ReactTooltip from "react-tooltip"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import Assessment from './Assessment'
import AssessmentService from "../AssessmentService"
import ConfirmMenu from "../../../Modals/ConfirmMenu"
import ProjectMenu from "../../../Modals/ProjectMenu"
import FormTemplateId from '../../../../constants/FormTemplateList'
import CommentsModal from "../../../Modals/CommentsModal"
import CardMenu from "../../../Modals/CardMenu"
import {Link} from "react-router-dom"
import useModal from "../../../../hooks/useModal"
import NotesModal from "../../../Modals/NotesModal"
import MenuModal from "../../../Modals/Menu"
import ChecklistsModal from "../../../Modals/ChecklistsModal"
import useStyle from '../../../../hooks/useStyle'
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import {NotificationPopup} from "../../../Common/NotificationPopup"
import {SortableContainer, SortableElement} from 'react-sortable-hoc'
import arrayMove from '../../../Common/arrayMove'
import {NavIcon, CardMenuInfo, MenuItem, CardMainMenu} from '../../../Common/MenuItem'
import {SwiperContainer, SwiperSlide} from "../../../Common/Swiper"
import CommonService from "../../../../services/CommonService"
import AddQuestion from "./AddQuestion"
import GenericModal from "../../../Modals/GenericModal"
import StepAssessmentService from "../StepAssessmentService"
import AssessmentTable from "./AssessmentTable"
import { CTTooltip } from "../../../Common/CueTooltip"
import TraineeUserList from "../../TraineeUsers/TraineeUserList"
import { FontAwesomeLink, FontAwesomeBtn } from "../../../Common/Button"

let requestParams = {}
let searchParams = {}
let selectedAssessment = {}
let selectedStepAssessment = {}
let dataSource = {}
let cardViewCount = 1
let publishType = null
let timer = null

const typeOfQuestion = [
  {'label':'Select single item from multiple choices', 'sid':'radio'},
  {'label':'Select several items from multiple choices', 'sid':'checkbox'},
  {'label':'Short Answer Response', 'sid':'textArea'},
  {'label':'Long Essay Answer Response', 'sid':'richtext'},
  {'label':'Submit URL response', 'sid':'textInput'}
]

const AssessmentList = (props) => {
  useStyle('/css/tile-components.css', true)

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  let cardGridCss = "col-md-4"
  let cardHeightCss = "ht-400"
  if (queryParam.id) {
    cardGridCss = "col-md-12"
    cardHeightCss = "mih-380"
  }

  const {state: { screen, user: currentUser }, dispatch} = useContext(AuthContext)
  const [assessmentList, setAssessmentList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [showSearch, setShowSearch] = useState(false)
  const [showAssessmentTable, setShowAssessmentTable] = useState(false)
  const [pagemeta, setPagemeta] = useState([])
  const [share, setShare] = useState({})
  const [userGroupEmailNotifications, setUserGroupEmailNotifications] = useState([])
  const [course, setCourse] = useState({})

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal()
  const { isOpen:isQuestionModalOpen, toggleModal:toggleQuestionModal } = useModal()
  const { isOpen:isPublishModalOpen, toggleModal:togglePublishModal } = useModal()
  const { isOpen:isResultModalOpen, toggleModal:toggleResultModal } = useModal()
  const { isOpen:isNotificationTypeOpen, toggleModal:toggleNotificationType } = useModal()
  const { isOpen:isUserGroupEmailNotificationOpen, toggleModal:toggleUserGroupEmailNotification } = useModal()
  const {isOpen:isTraineeListOpen, toggleModal:toggleTraineeList} = useModal()

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'page':currentpage,
      'per_page':10,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'id':queryParam.id,
      'training_program_id':params.training_program_id,
      'data_source_params':{
        'data_source_id':[3802]
      }
    }

    getAssessmentList()

    return () => {
      requestParams = {} 
    }
  }, [props.searchString, currentpage, props.location])

  let getAssessmentList = () => { 
    setLoading(true)
    AssessmentService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSource = res.data.data_source_list
        requestParams.totalItems = res.data.meta.total
        setAssessmentList(res.data.assessments)
        res.data.assessments.length == 0 && !requestParams.search && toggleHelpMessage()
      }
    })
  }

  let searchAssessment = () =>{
    AssessmentService.search(searchParams).then((res)=>{
      setAssessmentList(res.data.assessments)  
    })
  }

  let handleSearch = (e) =>{
    clearTimeout(timer)
    searchParams = {
      'page':currentpage,
      'per_page':20,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':e.target.value
    }
    timer = setTimeout(searchAssessment, 1000)
  }

  let getEmailNotificationList = (e, item) => { 
    e.preventDefault()
    setLoading(true)
    let req = {
      notifiable_id:item.id,
      notifiable_type:'training_assessment'
    }
    AssessmentService.getEmailNotificationList(item.id, req).then((res)=>{
      if(res.status == 200){
        setLoading(false)
        setUserGroupEmailNotifications(res.data.sent_email_notifications)
        toggleUserGroupEmailNotification()
      }
    })
  }
    
  const addNotes = (e, a) => {
    selectedAssessment = a
    toggleCommentModal(e)
  }

  const addChecklist = (e, a) => {
    selectedAssessment = a
    toggleChecklistModal(e)
  }

  const deleteAssessment = (e) => {
    e.preventDefault()
    AssessmentService.delete(selectedAssessment.id).then((res)=>{
      if(res.status==204){
        setAssessmentList(assessmentList.filter(i => i.id !== selectedAssessment.id))
      }
    })
    toggleDeleteModal(e)
  }

  const toggleAssessmentTable = () => setShowAssessmentTable(!showAssessmentTable)

  const update = (e, item, key) => {
    e && e.preventDefault()
    let req = null
    if(key){
      req = {[key]: !item[key], id: item.id}
    }

    AssessmentService.update(req).then((res)=>{
      if(res.status==204){
        if(key)item[key] = !item[key];
        setAssessmentList([...assessmentList])
      }
    })
  }
  
  const publishAssessment = (e) => {
    update(e, selectedAssessment, publishType)
    toggleResultModal(e)
  }

  const openNotificationType = (e, item, type) =>{
    publishType = type
    selectedAssessment = item
    toggleResultModal(e)
  }

  const openAnswerWindow = (e, item) => {
    e.preventDefault()
    update(e, item, 'is_answer_window_open')
  }

  const updateNotificationItem = (req) =>{
    req[publishType] = true
    
    AssessmentService.update(req).then((res)=>{
      if(res.status==204){
        selectedAssessment[publishType] = true
        setAssessmentList([...assessmentList])
      }
    })
  }

  /*
  const updateResult = (e) =>{
    update(e, selectedAssessment, 'is_result_published')
  }*/

  const updatePreview = (e, item) =>{
    update(e, item, 'is_previewed')
    props.history.push(`/question/${item.id}?is_preview=true`)
  }

  const showMenu = (a) => {
    selectedAssessment = a
    toggleMenuModal()
  }

  const routeAssessment = (assessment) =>{
    props.history.push(`/assessments?id=${assessment.id}`)
    /*setAssessmentList(assessmentList.filter(i => i.id == assessment.id))*/
  }

  const openQuestionModal = (e, question) =>{
    selectedStepAssessment = question
    toggleQuestionModal(e)
    toggleMenuModal(e)
  }

  const clone = (e, item) =>{
    e.preventDefault()
    let req = {
      'id':item.id,
    }

    AssessmentService.clone(req).then((res)=>{
      if(res.status == 200){
        setAssessmentList([res.data.assessment, ...assessmentList])  
      }
    })
  }

  const openTrainingList = (e, item) =>{
    e.preventDefault()
    selectedAssessment = item
    toggleTraineeList()
  }

  const menuItems = ({item}) =>{
    const form_template_id = item.assessment_form_id
    const title = item.data && item.data?.record_title?.replace(/\s/g, '_')

    const editAccess = true
    
    return(
      <Fragment>
        <CardMenuInfo formId={item.assessment_form_id} recordId={item.record_id} 
          createdAt={item.created_at} userName={item.author_name} />

        <a href="/" onClick={(e) => openTrainingList(e, item)}>
          <i className="far fa-user"></i>
          <span className="font-13">Add Trainee</span>
          <i className="fa fa-angle-right"></i>
        </a>

        {editAccess && <MenuItem id="assessment_icon" 
          onSelect={e => openQuestionModal(e, {})}
          icon="far fas fa-question-circle" 
          action="Add Question"/>
        }

        <MenuItem id="question_form_icon" 
          onSelect={e => updatePreview(e, item)}
          icon="far fas fa-eye" 
          action="Preview Question Form"/> 

        {item.is_previewed &&   
          <MenuItem id="publish_icon" 
            onSelect={(e) => openNotificationType(e, item, 'is_published')}
            icon={item.is_published?'far fas fa-check-circle':'far fas fa-newspaper'} 
            action="Publish Assessment to Students"/>
        }

        {item.is_published && 
          <MenuItem id="publish_answer_icon" 
            onSelect={(e) => openNotificationType(e, item, 'is_result_published')}
            icon="far fas fa-newspaper" 
            action="Publish Result"/> 
        }

        {editAccess &&
          <MenuItem id="clone_icon" icon="fas fa-clone"
            action="Copy" onSelect={(e) => clone(e, item)}/>
        }

        {editAccess &&  
          <MenuItem id="edit_icon" 
            link={`/assessment/form/${item.id}?edit=${editAccess}`}
            icon="far fas fa-edit" action="Edit"/>
        }
        
        {editAccess &&
          <MenuItem id="delete_icon" icon="far fas fa-trash-alt"
            action="Delete" onSelect={(e) => toggleDeleteModal(e)}/>
        }
   
        {!editAccess && 
          <p className="m-t-10 p-5 lred">
            This item can no longer be edited or deleted since it has been published. 
            Contact the journey administrator for assistance with this item.
          </p>
        }    
      </Fragment>
    )
  }

  const assessmentCard = assessmentList && assessmentList.map((a, i) => (
    <div className={cardGridCss} key={i}>
      <Assessment assessment={a} updatePreview={updatePreview} addNotes={addNotes} 
        addChecklist={addChecklist} dataSource={dataSource} cardHeightCss={cardHeightCss} 
        openQuestionModal={openQuestionModal} index={i} typeOfQuestion={typeOfQuestion} 
        history={props.history} share={share} addMenu={showMenu}
        routeAssessment={routeAssessment}/>
      {selectedAssessment.id == a.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
        item={selectedAssessment} type="training_assessment" 
        MenuItems={menuItems} menuHeight="365px"/>
      }
    </div>
  ))

  if(queryParam.id && !loading && assessmentList.length == 0){
    return(
      <NotificationPopup iconClass="fa fa-times-circle lred font-24" 
      header="Not Found" message="Sorry, this message was deleted by the author"/>
    )
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex">
              <div className="app-title m-r-20">Assessments</div>
            </div>
            <div className="flex aligncenter">
              <FontAwesomeLink 
                name="Add" iconCss="fas fa-plus"
                id="add_new_assessment" dataTip="Create New Assessment"  
                link={`/assessment/form?training_program_id=${params.training_program_id || ''}`}>
              </FontAwesomeLink>
                
              <FontAwesomeBtn id="toggle_assessment_table" dataTip="Toggle Assessment Table" 
                onSelect={()=>toggleAssessmentTable()} css="m-r-5"
                iconCss="fas fa-table m-l-5" name="Table" >
              </FontAwesomeBtn>

              <FontAwesomeBtn id="toggle_search" dataTip="Toggle Search" 
                onSelect={()=>setShowSearch(!showSearch)} css="m-r-5"
                iconCss="fas far fa-search" name="Filter" >
              </FontAwesomeBtn>
            </div>
          </div>

          <div className="app-content-section m-t-30">
            {showSearch && 
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <a className="input-group m-t-10" id="search_box">
                    <span className="input-group-btn" style={{zIndex:"9",left:'44px'}}>
                      <p type="button" className="input-sm" onClick={e=>handleSearch(e)} style={{height:'40px'}}>
                        <i className="fa fa-search lgrey" style={{fontSize:'26px'}}></i>
                      </p>
                    </span>
                    <input type="text" onChange={e=>handleSearch(e)} placeholder="Search" className="w-98p form-control input-sm" style={{fontSize:'16px',height:'40px',textIndent:"30px"}} />
                    <span className="input-group-btn" style={{zIndex:"9",right:"48px"}}>
                      <p type="button" className="input-sm" onClick={e=>setShowSearch(!showSearch)} style={{height:'40px'}}>
                        <i className="fa fa-times lgrey" style={{fontSize:'26px'}}></i>
                      </p>
                    </span>
                  </a>
                </div>
              </div>
            }
              
            {!showAssessmentTable && !queryParam.id && 
              <div className="row">
                {assessmentCard}
              </div>
            }

            {!showAssessmentTable && queryParam.id &&
              <div className="row">
                {assessmentCard}
              </div>           
            }

            {showAssessmentTable && 
              <Fragment>
                <AssessmentTable assessment={assessmentList} requestParams={requestParams} 
                  openQuestionModal={openQuestionModal} typeOfQuestion={typeOfQuestion} 
                  selectedAssessment={selectedAssessment} togglePublishModal={togglePublishModal} 
                  share={share}/>
              </Fragment>
            }
          </div>
        </div>
        </div>

      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} 
        item={selectedAssessment} type="training_assessment"/>
      }
     
      {isChecklistOpen && 
        <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} 
        item={selectedAssessment} type="training_assessment"/>
      }
     
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} 
        success={deleteAssessment}/>
      
      <ConfirmMenu isOpen={isResultModalOpen} toggleModal={toggleResultModal} 
        success={publishAssessment} 
        message="Are you sure that this Assessment is complete and ready to be published?"/>

      {isHelpMessageOpen && 
        <NotificationPopup header="Note" message="Add assessment" 
        onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} 
        toggleModal={toggleHelpMessage}/>
      }
     
      {isQuestionModalOpen &&
        <GenericModal component={AddQuestion} questionObj={selectedStepAssessment} 
          title="Add Question" isOpen={isQuestionModalOpen} toggleModal={toggleQuestionModal} 
          assessment={selectedAssessment} assessmentList={assessmentList} 
          setAssessmentList={setAssessmentList} typeOfQuestion={typeOfQuestion} 
          course={course} difficultyLevel={dataSource.dificulty_level}/>
      }

      {isTraineeListOpen && <GenericModal component={TraineeUserList}
        isOpen={isTraineeListOpen} toggleModal={toggleTraineeList}
        itemType="training_assessment" itemId={selectedAssessment.id} 
        widthCss="w-100p" organizationId={currentUser.current_organization_id}
        title={selectedAssessment.data.subject_name}/>
      }
      <CTTooltip />
    </Fragment>
  )

}

export default AssessmentList;