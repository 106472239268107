import React, { Fragment, useState, useContext, useReducer} from 'react';
import ReactTooltip from 'react-tooltip';
import { AuthContext } from "../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "./PreLoadSpinner";
import PrivateRoute from "../routes/PrivateRoute";
import PublicRoute from "../routes/PublicRoute";
import { Route, Switch } from 'react-router-dom';
import SideMenu from './SideMenu/SideMenu'
import ProfileForm from "./HealthJourney/Form/ProfileForm";
import Header from './Header/Header'
import HealthJourneyRouter from './HealthJourney/HealthJourneyRouter'
import InfantJourneyRouter from './HealthJourney/MotherInfantJourney/Router'
import useModal from "../hooks/useModal";
import UserProfile from './UserProfile/UserProfile'
import LeaderShipDashboard from "./HealthJourney/Dashboard/LeaderShipDashboard"
import HelpRouter from "./Help/HelpRouter";
import ForumRouter from "./Forums/ForumRouter";
import env from "../env"
//import ChatBot from "../components/Chats/ChatBot"
import Footer from "./Footer/Footer";
import ChatModal from "./Chats/ChatModal";
import {Socket} from "./Common/Socket"
import {CTTooltip} from "./Common/CueTooltip"
import NutritionCareRouter from "./NutritionCares/Router"
import DigitalContentRouter from './DigitalContent/DigitalContentRouter';
import ScreeningEventRouter from './ScreeningCamp/ScreeningEventRouter';
import LocaleList from "./Locale/LocaleMenu"
import GenericModal from "./Modals/GenericModal";
import PreScheduleEventRouter from './PreScheduleEvent/PreScheduleEventRouter';
import Organization from './Organizations/Route'
import OrganizationForm from './Organizations/OrganizationForm'
import OrganizationsModal from "./Organizations/OrganizationsModal";
import DataSourceList from './DataSources/DataSourceList'
import FormsList from './DataSources/FormsList'
import DataSourceForm from './DataSources/DataSourceForm'
import SubscriptionsRouter from './Subscriptions/Route'
import WelcomeTour from './Help/WelcomeTour'
import TrainingRouter from './TrainingPrograms/Route'
import ContactsRouter from './Contacts/ContactsRouter'
import NotificationList from './Notifications/NotificationList'
import PublicNotificationsPage from './Notifications/PublicNotificationsPage'
import ContentManager from './Dashboard/ContentManager'
import AddToHomeScreen from "./Common/AddToHomeScreen"
import { CacheContext } from "../contexts/Cache/CacheStateProvider"
import { LocalizeContext } from "../contexts/Localize/LocalizeStateProvider"
import CacheReducer from '../contexts/Cache/CacheReducer'
import LocaleReducer from '../contexts/Localize/LocalizeReducer'
import LoginHistory from './Authentication/LoginHistory'
import FormList from './Offline/FormList' 
import ImageCompress from './ImageEditor/ImageCompress'
import FeedbackForm from './Feedback/FeedbackForm'
import FeedbackList from './Feedback/List/FeedbackList'
import Form from './FormBuilder/Form'
import {AboutUs} from './AboutUs/AboutUs'
import ErrorBoundary from "./Common/ErrorBoundary"

const Home = (props) => {
  const { state:{screen, user:currentUser, isAuthenticated:isAuthenticated}, dispatch } = useContext(AuthContext)
  const [cacheState, cacheDispatch] = useReducer(CacheReducer, {})
  const [localeState, localeDispatch] = useReducer(LocaleReducer, {})

  let [openSideNav, setOpenSideNav] = useState(false)
  let [searchString, setSearchString] = useState("")
  let [openChatBot, setOpenChatBot] = useState(false)

  const {isOpen: showChatModal, toggleModal: toggleChatModal} = useModal()
  const {isOpen: showLocaleModal, toggleModal: toggleLocaleModal} = useModal()
  const {isOpen: showOrgModal, toggleModal: toggleOrgModal} = useModal()

  //Socket(currentUser)

  return (
    <Fragment>
      <LocalizeContext.Provider value={{localeState, localeDispatch}}>
      <CacheContext.Provider value={{cacheState, cacheDispatch}}>
        
        {isAuthenticated && 
          <Header openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} 
          setSearchString={setSearchString} toggleLocaleModal={toggleLocaleModal} 
          toggleOrgModal={toggleOrgModal} {...props}/>
        }
      
        <ChatModal isOpen={showChatModal} toggleModal={toggleChatModal} currentUser={currentUser}/>
      
        {/*screen.xs && <AddToHomeScreen/>*/}

        {isAuthenticated && 
          <SideMenu openSideNav={openSideNav} setOpenSideNav={setOpenSideNav} 
          toggleOrgModal={toggleOrgModal} toggleChatModal={toggleChatModal}
          toggleLocaleModal={toggleLocaleModal}/>
        }

        {showLocaleModal && 
          <GenericModal component={LocaleList} 
          title="Langauage" type="user_locale"
          selectedItems={[currentUser.current_locale]}
          isOpen={showLocaleModal} toggleModal={toggleLocaleModal}/>
        }

        {showOrgModal && 
          <GenericModal component={OrganizationsModal} 
          title="Select Organization"
          isOpen={showOrgModal} toggleModal={toggleOrgModal}/>
        }

        <ErrorBoundary>
          <Switch> 
            <PrivateRoute path="/image/test" component={ImageCompress} exact />
            <Route path="/profile/:id?" component={OrganizationForm} exact />
            <PrivateRoute path="/user/profile" component={UserProfile} exact />
            <PrivateRoute path="/leadership" component={LeaderShipDashboard} exact />
            <PrivateRoute path="/data_source_list/form/:organization_id" component={DataSourceForm} exact />
            <PrivateRoute path="/list/data_sources" component={DataSourceList} searchString={searchString} exact />
            <PrivateRoute path="/list/forms" component={FormsList} searchString={searchString} exact />
            <PrivateRoute path="/login/historys" component={LoginHistory} exact />
            <PrivateRoute path="/offline" component={FormList} exact/>
            <PrivateRoute path="/feedback/:id?" component={FeedbackForm} exact/>
            <PrivateRoute path="/list/feedback" component={FeedbackList} exact/>
            <PrivateRoute path="/form/:form_template_id" component={Form} exact/>
          </Switch>
          <Switch><HealthJourneyRouter searchString={searchString}/></Switch>
          <Switch><InfantJourneyRouter searchString={searchString}/></Switch>
          <Switch><ContactsRouter searchString={searchString}/></Switch>
          <Switch><HelpRouter searchString={searchString} /></Switch>
          <Switch><ForumRouter /></Switch>
          <Switch><PreScheduleEventRouter searchString={searchString}/></Switch>
          <Switch><NutritionCareRouter searchString={searchString}/></Switch>
          <Switch><DigitalContentRouter searchString={searchString} /></Switch>
          <Switch><ScreeningEventRouter searchString={searchString} /></Switch>
          <Switch><Organization searchString={searchString} /></Switch>
          <Switch><SubscriptionsRouter searchString={searchString}/></Switch>
          <Switch><TrainingRouter searchString={searchString}/></Switch>
          {isAuthenticated && <Footer openChatBot={openChatBot} setOpenChatBot={setOpenChatBot} />}
          <PrivateRoute path="/journey/tour" component={WelcomeTour} exact />
          <PrivateRoute path="/notifications" component={NotificationList} exact />
          <PrivateRoute path="/manage/contents" component={ContentManager} exact />
          <PublicRoute path="/about_us/:content_type" component={AboutUs} exact/>
          <PublicRoute path="/public/notifications/:journey_profile_id/:parent_notification_id" 
            component={PublicNotificationsPage} exact />
          <CTTooltip />
        </ErrorBoundary>
      </CacheContext.Provider>
      </LocalizeContext.Provider>
    </Fragment>
  )
}

export default Home;