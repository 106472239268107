import React, {useState, Fragment, useEffect, useRef, useContext, useMemo} from "react"
import { Link, useHistory } from "react-router-dom"
import AuthenticationService from "../../services/AuthService"
import {AuthContext} from "../../contexts/Authentication/AuthStateProvider"
import {CacheContext} from "../../contexts/Cache/CacheStateProvider"
import CheckAccess from '../HealthJourney/Roles/CheckAccess' 
import env from '../../env'
import {EditLocaleIco} from '../Locale/LocaleIcon'
import useModal from "../../hooks/useModal"
import UserAccessComponents from "../HealthJourney/Roles/UserAccessComponents"
import Cs from "../../services/CommonService"
import {LocalizeContext} from "../../contexts/Localize/LocalizeStateProvider"
import {HelpIcon} from "../Help/HelpSidePanel"

const SideMenu = (props) => {
  const { state: { screen, user: currentUser }, dispatch} = useContext(AuthContext)
  const {cacheState, cacheDispatch} = useContext(CacheContext)
  const {localeState:{sideMenuList}, localeDispatch} = useContext(LocalizeContext)

  const sideMenuDsId = 3737
  const headerDsId = 3932

  const sideNavEl = useRef(null)
  const sideNavMenuEl = useRef(null)
  const history = useHistory()
  const isDevAdmin = CheckAccess.isDevUser(currentUser.email)

  const [sideMenu, setSideMenu] = useState()
  const [loading, setLoading] = useState(true)
  const { isOpen: isUserAccessOpen, toggleModal:toggleUserAccess } = useModal()

  useEffect(() =>{
    setSideMenu(sideMenuList?.options || {})
  }, [sideMenuList])

  const signout = (e) => {
    e.stopPropagation()
    e.preventDefault()
    AuthenticationService.signout().then(() => {
      cacheDispatch({
        type: "UPDATE",
        payload: {}
      })
      dispatch({ type: "LOGOUT" })
    })
  }

  useEffect(() => {
    getSideMenuDs()
    document.addEventListener("keydown", handleEscape)
    document.addEventListener("click", handleClick, true)

    return () => {
      document.removeEventListener("keydown", handleEscape)
      document.removeEventListener("click", handleClick, true)
    }
  }, [])

  const handleEscape = (e) => {
    if (e.keyCode === 27) props.setOpenSideNav(false);
  }

  /*const handleClick = (e) => {
    if (
      !currentUser.edit_locale && (e.target.id !== 'edit_locale')
      && sideNavEl.current 
      && (sideNavEl.current.contains(e.target) || sideNavMenuEl.current.contains(e.target))
    ) {
      props.setOpenSideNav(false);
    }
  }*/

  const handleClick = (e) => {
    if (
      (sideNavMenuEl.current && sideNavMenuEl.current.contains(e.target))
    ) {
      close()
    }
  }

  const close = (e) => {
    e && e.preventDefault();
    props.setOpenSideNav(false);
  }
    
  const checkAccess = (menu_type) => CheckAccess.checkOrgAccess(currentUser, menu_type, 'menu_accesses')

  const editLocale = () =>{
    currentUser.edit_locale = !currentUser.edit_locale
    dispatch({
      type: "UPDATE",
      payload: { user: currentUser },
    })
  }
  
  const routeOrgProfile = (e) =>{
    e.preventDefault()
    const userOrg = currentUser.organizations.find((o)=> o.created_by === currentUser.id)
    if(userOrg){
      history.push(`/profile/${userOrg.id}`)
    }
  }

  const openUserAccess = () =>{
    toggleUserAccess()
  }

  const getSideMenuDs = () =>{
    setLoading(true)
    Cs.getDataSource({
      'data_source_params':{
        'data_source_id':[sideMenuDsId, headerDsId],
        'response_type':'object', 
        'group_by':'sid',
        'locale':currentUser.current_locale,
        'per_page' : 30
      }
    }).then((data)=>{
      if(data){
        localeDispatch({
          type: "UPDATE",
          payload: {
            'sideMenuList':(data.section_menu_nav_bar),
            'header':(data.text_header_items),
          }
        })
        //setSideMenu(data.section_menu_nav_bar?.options || {})
        setLoading(false)
      }
    })
  }

  const onLocaleUpdate = (menu) =>{
    sideMenu[menu.sid] = menu
    setSideMenu({...sideMenu})
  }

  if(isUserAccessOpen){ 
    return <UserAccessComponents isOpen={isUserAccessOpen} toggleModal={toggleUserAccess}/>
  }
  
  return (
    <div id="menu-main" ref={sideNavEl}
      className={`menu menu-box-left menu-box-detached round-medium ${props.openSideNav ? "menu-active" : ""} `}
      style={{ width: 260, opacity: 1, display: "block" }}>
      <div className="menu-icons">
        <a href="#">
          <i className="fa fa-lightbulb color-yellow1-dark hidden" />
        </a>
        <span onClick={() => {props.toggleOrgModal();close();}}>
          <i className="fas fa-building color-green1-dark" />
        </span>
        <span onClick={()=>openUserAccess()}>
          <i className="fas fa-sitemap color-orange-dark" />
        </span>
        <Link to="/user/profile" onClick={() => close()}>
          <i className="fa fa-cog color-blue2-dark" />
        </Link>
        <span className="close-menu" onClick={(e) => close(e)}>
          <i className="fa fa-times color-red2-dark " />
        </span>
        <div className="clear" />
      </div>

      <div className="menu-items">
        <div className="menu-header p-10 brb-lgrey">
          <div className="font-13 p-l-15 m-t-5 pointer black-light-1">
            <div className="m-b-15" data-tip="Language Menu" 
              onClick={() => {props.toggleLocaleModal();close();}}>
              <i className="fas fa-globe w-30 color-blue2-dark" />
              <span className="text-cap">{currentUser.current_locale}</span>
            </div>
            <div className="m-b-15" data-tip="Signed in as">
              <i className="fas fa-user-tag w-30 color-blue2-dark" />
              <span>{currentUser.name}</span>
            </div>
            <div className="m-b-15" data-tip="Orgnization Menu" 
              onClick={() => {props.toggleOrgModal();close();}}>
              <i className="fas fa-building w-30 color-blue2-dark" />
              <span>{currentUser?.current_organization?.data?.name}</span>
            </div>
            <div className="m-b-15" onClick={()=>openUserAccess()}
              data-tip="Click to view available services">
              <i className="fas fa-sitemap w-30 color-blue2-dark" />
              <span>{CheckAccess.getRoleName(currentUser)}</span>
            </div>
            {isDevAdmin && 
              <span id="edit_locale" className="font-14" onClick={()=>editLocale()} data-tip="Switch Locale">
                <i className="fas fa-yin-yang w-30 color-blue2-dark"/>
                <span id="edit_locale">Set language</span>
              </span>
            }
          </div>
          <a href="#" className="d-block" />
        </div>
      
        <div className="content bottom-10">
          {loading ?
            null
            :
            <div className="link-list link-list-1" ref={sideNavMenuEl}>
              {isDevAdmin && 
                <MenuIcon currentLocale={currentUser.current_locale} 
                  menuItem={sideMenu.upcoming} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-home color-blue2-dark" label="Dashboard" sid="upcoming"
                  id="nav-welcome" link="/health/upcoming/dashboard"/>
              }
                
              <MenuIcon currentLocale={ currentUser.current_locale} 
                menuItem={sideMenu.dashboard} onLocaleUpdate={onLocaleUpdate} 
                iconCss="fa fa-home color-blue2-dark" label="Home" sid="dashboard"
                id="nav-welcome" link="/health/table"/>
                
              {checkAccess('contacts') &&
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.contacts} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-users color-blue2-dark" label="Contacts" sid="contacts"
                  id="nav-contact" link="/schedules?filter=all"/>
              }

              {isDevAdmin &&
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.data_explorer} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" label="Contact Requests" 
                  sid="data_explorer" id="nav-chart" link="/list/contacts"/>
              }
              
              {checkAccess('facilities') &&  
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.facilities} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-hospital color-blue2-dark" label="Facilities" 
                  sid="facilities" id="nav-facilities" 
                  link={`/health/list/facilities/${currentUser.current_organization_id}/${currentUser?.current_subscription?.id}`}/>
              }

              {isDevAdmin && 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.organizations} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-hospital color-blue2-dark" label="Organizations" 
                  sid="organizations" id="nav-facilities" link="/list/organizations"/>
              }

              {isDevAdmin && 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.personal_users} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="Personal Users" 
                  sid="personal_users" id="nav-facilities" link="/list/personal/users"/>
              }

              {isDevAdmin && 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.subscriptions} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-user-shield color-blue2-dark" label="Subscriptions" 
                  sid="subscriptions" id="nav-facilities" link="/list/subscriptions"/>
              }
              
              {checkAccess('members') && 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.members} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-hospital color-blue2-dark" label="Organization Members" 
                  sid="members" id="nav-facilities" 
                  link={`/${currentUser.current_organization.data.name}/members/${currentUser.current_organization_id}`}/>
              }

              {(checkAccess('roles') || isDevAdmin) && 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.roles} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="Roles" 
                  sid="roles" id="nav-roles" 
                  link={`/health/role/infant_journey/${currentUser.id}/${currentUser.current_organization_id}/${currentUser?.current_subscription?.id}`}/>
              }

              {(checkAccess('partners') || isDevAdmin) && 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.partners} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="Partners" sid="partners"
                  id="nav-roles" link="/list/partners"/>
              }

              {checkAccess('guides') && 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.guides} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" label="Guide" 
                  sid="guides" id="nav-guide" link="/health/planner"/>
              }

              {/*checkAccess('interventions')*/}

              {(isDevAdmin || checkAccess('interventions'))  &&
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.interventions} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-hospital color-blue2-dark" label="Intervention Protocols" 
                  sid="interventions" id="nav-nutrition-cares" link="/nutrition_cares"/>
              }
              
              {(isDevAdmin || checkAccess('interventions'))  &&
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.intervention} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-hospital color-blue2-dark" label="Intervention Protocol Templates" 
                  sid="intervention" id="nav-nutrition-cares" link="/templates/nutrition_cares"/>
              }

              {/*checkAccess('calendar')*/}

              {checkAccess('training') &&  
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.training} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="Training Program" 
                  sid="training" id="nav-ds" link="/list/training_programs"/>
              }
              
              {(isDevAdmin || checkAccess('calendar')) &&
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.calendar} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-calendar color-blue2-dark" label="Calendar Manager" 
                  sid="calendar" id="nav-calendar-manager" link="/schedule/event"/>
              }
                
              {(isDevAdmin || checkAccess('insights')) &&
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.reports} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" label="Reports" 
                  sid="reports" id="nav-chart" link="/infant/chart/state"/>
              }
              
              {isDevAdmin &&
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.data_explorer} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-book color-blue2-dark" label="MCJ data explorer" 
                  sid="data_explorer" id="nav-chart" link="/infant/measurements"/>
              }

              {/*checkAccess('manage_counseling') && */}

              {(isDevAdmin || checkAccess('counseling'))&& 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.counseling} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-people-arrows color-blue2-dark" label="Counseling" 
                  sid="counseling" id="nav-guide" link="/infant/list/counseling"/>
              }

              {(isDevAdmin || checkAccess('counseling'))&& 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.counseling_template} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-solid fa-people-arrows color-blue2-dark" label="Counseling Templates" 
                  sid="counseling_template" id="nav-guide" link="/templates/counselings"/>
              }

              {isDevAdmin &&
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.chat_messenger} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-comments color-blue2-dark" label="Chat Messenger" 
                  sid="chat_messenger" id="nav-chat" href="/" onSelect={props.toggleChatModal}/>
              }
                
              {checkAccess('community') &&
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.community} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-globe color-blue2-dark" label="Practice Community" 
                  sid="community" link="/forums"/>
              }
              
              {/*checkAccess('calendar')*/}

              {isDevAdmin &&  
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.field_resource_management} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fa fa-user color-blue2-dark" label="Field Resource Management" 
                  sid="field_resource_management" id="nav-ds" link=""/>
              }
              
              {/*checkAccess('communications')*/}  

              {isDevAdmin && 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.digital_content} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-newspaper color-blue2-dark" label="Digital Content" 
                  sid="digital_content" id="nav-communications" link="/digital_contents"/>
              }
                
              {checkAccess('screening') && 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.screening} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-newspaper color-blue2-dark" label="Screening Events" 
                  sid="screening" id="nav-data-choices" link="/screening_events"/>
              }
                
              {checkAccess('data_choices') && 
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.data_choices} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="fas fa-database color-blue2-dark" label="Data Sources" 
                  sid="data_choices" id="nav-ds" link="/list/forms"/>
              }
              
              {isDevAdmin &&
                <MenuIcon currentLocale={ currentUser.current_locale} 
                  menuItem={sideMenu.help} onLocaleUpdate={onLocaleUpdate} 
                  iconCss="far fa-question-circle color-blue2-dark" label="Help" 
                  sid="help" link="/help"/>
              }

              <MenuIcon currentLocale={ currentUser.current_locale} 
                menuItem={sideMenu.profile} onLocaleUpdate={onLocaleUpdate} 
                iconCss="fa fa-user color-blue2-dark" label="Profile" sid="profile"
                id="nav-org" onSelect={routeOrgProfile}/>

              {isDevAdmin && 
                <>
                  <MenuIcon currentLocale={ currentUser.current_locale} 
                    menuItem={sideMenu.quiz} onLocaleUpdate={onLocaleUpdate} 
                    iconCss="far fa-question-circle color-blue2-dark" label="Quiz" 
                    sid="quiz" link="/list/quiz"/>
                
                  <MenuIcon currentLocale={ currentUser.current_locale} 
                    menuItem={sideMenu.offline} onLocaleUpdate={onLocaleUpdate} 
                    iconCss="far fa-question-circle color-blue2-dark" label="Offline" 
                    sid="offline" link="/offline" id="offline-nav"/>

                  <MenuIcon currentLocale={ currentUser.current_locale} 
                    menuItem={sideMenu.feedback} onLocaleUpdate={onLocaleUpdate} 
                    iconCss="far fa-question-circle color-blue2-dark" label="Feedback" 
                    sid="feedback" link="/list/feedback"/>
                </>
              }
                
              <MenuIcon currentLocale={ currentUser.current_locale} 
                menuItem={sideMenu.sign_out} onLocaleUpdate={onLocaleUpdate} 
                iconCss="fa fa-times color-red2-dark" label="Sign Out" sid="sign_out"
                onSelect={signout}/>
            </div>
          }
          
          <div className="center-text m-t-10">
            {/*<a href="#"
              className="icon icon-xxs round-tiny shadow-large bottom-0 bg-facebook">
              <i className="fab fa-facebook-f" />
            </a>
            <a href="#"
              className="icon icon-xxs round-tiny shadow-large bottom-0 bg-twitter">
              <i className="fab fa-twitter" />
            </a>
            <a href="#"
              className="icon icon-xxs round-tiny shadow-large bottom-0 bg-instagram">
              <i className="fab fa-instagram" />
            </a>
            <a href="#"
              className="icon icon-xxs round-tiny shadow-large bottom-0 bg-linkedin">
              <i className="fab fa-linkedin-in" />
            </a>
            <a href="#"
              className="icon icon-xxs round-tiny shadow-large bottom-0 bg-whatsapp">
              <i className="fab fa-whatsapp" />
            </a>*/}
            <p className="top-10 font-10 opacity-50">
              <i className="fa fa-copyright" aria-hidden="true"></i>
              <span className="copyright-year">2023</span> IPIPAL Inc. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const MenuIcon = ({link, onSelect, iconCss, label, sid, onLocaleUpdate, menuItem, currentLocale}) => {
  
  const menuLocale = useMemo(() => {
    return{
      'label' : menuItem?.[currentLocale]?.label || label
    }
  }, [sid])

  if(link){
    return(
      <div className="flex justspacebetween aligncenter">
        <Link to={link}>
          <i className={iconCss} />
          <span>
            {menuLocale.label}
          </span>           
        </Link>
        <div className="">
          {menuItem ? 
            <EditLocaleIco localeType="data_source_data" 
              labelObj={menuItem} onLocaleUpdate={onLocaleUpdate}/> 
            : null
          }
          <HelpIcon recordIds={menuItem?.help_id} className="font-14 m-r-10"/>
          <i className="fa fa-angle-right r-ico"/>         
        </div>
      </div>
    )
  }else{
    return(
      <div className="flex justspacebetween aligncenter">
        <a href="/" onClick={e=>onSelect(e)}>
          <i className={iconCss} />
          <span>{menuLocale.label}</span>
        </a>
        <div className="">
          {menuItem ? 
            <EditLocaleIco localeType="data_source_data"
              labelObj={menuItem} onLocaleUpdate={onLocaleUpdate}/> 
            : null
          }
          <HelpIcon recordIds={menuItem?.help_id} className="font-14 m-r-10"/>
          <i className="fa fa-angle-right r-ico"/>         
        </div>
      </div>
    )
  }
}

export default SideMenu;

/*
{checkAccess('invite_user') &&
              <Link id="nav-users" to={`/health/members/infant_journey/${currentUser.id}`}>
                <i className="fa fa-globe color-blue2-dark" />
                <span>Users</span>
                <i className="fa fa-angle-right" />
              </Link>
            }
 <Link id="nav-dashboard" to="/health">
              <i className="fa fa-desktop color-blue2-dark" />
              <span>Dashboard
                <EditLocaleIco localeType="side_menu" labelObj={{key:'dashboard', label:'Dashboard'}}/>
              </span>
              <i className="fa fa-angle-right" />
            </Link>

*/