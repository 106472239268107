import React, {useContext, useState, useEffect} from "react"
import {AuthContext} from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'
import Cs from "../../services/CommonService"
import GeoLocation from "../Common/GeoLocation"
import {usePageTitle} from "../../hooks/pageHead"
import useAuth from './useAuth' 
import PreLoadSpinner from "../PreLoadSpinner"

let geoData = {}
const LoginUID = (props) => {
  useStyle('/css/login.css', true) 
  usePageTitle('Login')

  let params = props.match.params

  const { state: authState, dispatch } = useContext(AuthContext)
  const { user, setUser, handleFormSubmit, routeUser } = useAuth(props.history, dispatch, geoData)

  useEffect(()=>{
    if(params.uid){
      /*
        setUser({
          'registration_mode': 'uid',
          'uid': params.uid
        })
        setting uid like this not working
      */
      user.registration_mode = 'uid'
      user.uid = params.uid
      setUser({...user})
      handleFormSubmit()
    }else{
      props.history.push('/signin')
    }
  }, [])

  useEffect(() => {
    if(authState.isAuthenticated){
      routeUser(authState.user)
    }
  }, [authState.isAuthenticated])


  return (
    <div id="ct">
      <PreLoadSpinner/>
      <GeoLocation geoData={geoData} getAddress="true"/>
    </div>
  )

}

export default LoginUID;