import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import DynamicForm from "../../../../FormBuilder/DynamicForm";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import ActivityService from "../ActivityService";
import querystringify from "querystringify";
import FormContainer from '../../../../../components/FormBuilder/FormContainer';

let formMode = null
let activity = {}
let data = {}
let formFn = {}

function ActivityForm(props) {
  const { state: authState, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let forigenObject =  props.location.state;
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ActivityService.getById(params.id).then((res) => {
      if (res.status == 200) {
        activity = res.data.activity;
        data = activity.data || {}
        //params.form_template_id = activity.activity_form_id;
 
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function (res) {
      activity = null;
    })
  }

  useEffect(() => {
    activity = {}
    data = {}
    formFn = {
      form_type: "health.activity",
      journey_category_id: params.journey_profile_id,
    }
    formMode = "create-form-submissions";
    data = {}
    if(forigenObject)data = Object.assign(forigenObject, data); 
    if (params.id) {
      get();
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, []);

  const create = (activity) => {
    activity.organization_id = authState.user.current_organization_id
    activity.created_by = authState.user.id;
    activity.updated_by = authState.user.id;
    activity.journey_profile_id = params.journey_profile_id;
    activity.activity_form_id = 440;
    activity.activity_type = "Activity";
    ActivityService.create(activity).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const update = (activity) => {
    activity.updated_by = authState.user.id;
    ActivityService.update(activity).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = () => {
    if(props.isPopupMode){
      props.onFormSubmit()
    }else if(queryParam.activity_form_id){
      props.history.goBack()
      /*props.history.push(
        `/infant/activities/${params.journey_profile_id}?activity_form_id=${queryParam.activity_form_id}`
      )*/  
    }else{
      /*props.history.push(
        `/health/activities/${params.journey_profile_id}`
      ) */
      props.history.goBack() 
    } 
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm
          formMode={formMode}
          formId={FormTemplateId.HealthActivityForm}
          form={activity}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
      </FormContainer>
    )
  } else {
    return null;
  }
}

export default ActivityForm;
