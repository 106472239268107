import React, { useState, useEffect, Fragment} from 'react';
import ReactDOM from 'react-dom';
import HealthCategoryService from "../../HealthJourney/HealthCategoryService"
import Comments from "./Comments"
import FileUploads from "./FileUploads"
import Links from "./Links"
import useStyle from '../../../hooks/useStyle';

const WAIT_INTERVAL = 1000;
let timer = null;
let searchKeyword = null;
const FilesLinksModal = ({journeyType,journeyProfile, toggleModal}) => {

  const [activeTab, setActiveTab] = useState('file_uploads');
  const [search, setSearch] = useState('');

  useEffect(() => {
    timer = null;
    searchKeyword = null;
  }, []);

  const handleChange=(event)=>{
    clearTimeout(timer);
    searchKeyword = event.target.value;
    timer = setTimeout(triggerChange, WAIT_INTERVAL);
  }

  const triggerChange = () => {
    setSearch(searchKeyword);
    console.log(searchKeyword)
  }

  return(ReactDOM.createPortal(
    <React.Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom border-rounded-10">
      
        <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Collections</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
        </div>

        <div className="ml-container">
          <div className="ml-section">
            <div className="clearfix">
             <div onClick={e=>setActiveTab('file_uploads')} className="col-xs-4 text-center font-14">
              Uploads {activeTab == 'file_uploads' && <div className="ml-tab-active"/>}
             </div>
             <div onClick={e=>setActiveTab('links')} className="col-xs-4 text-center font-14">
              Links {activeTab == 'links' && <div className="ml-tab-active"/>}
             </div>
             <div onClick={e=>setActiveTab('comments')} className="col-xs-4 text-center font-14">
              Comments {activeTab == 'comments' && <div className="ml-tab-active"/>}
             </div>
            </div>
            <div className="search-box search-color bg-white shadow-tiny rounded-xl top-5 bottom-10" >
              <i className="fa fa-search"></i>
              <input type="text" placeholder="Search here.." autoFocus onChange={(e)=>handleChange(e)} />
            </div>
          </div>
          <div className="ml-section">
            {activeTab == 'file_uploads' && 
              <div className="col-xs-12">
                <FileUploads search={searchKeyword} journeyProfile={journeyProfile} journeyType={journeyType}/>
              </div>
            }
            
            {activeTab == 'comments' && 
              <div className="col-xs-12">
                <Comments search={searchKeyword} journeyProfile={journeyProfile} journeyType={journeyType} />
                </div>
            }
            
            {activeTab == "links" && 
              <div className="col-xs-12">
                <Links search={searchKeyword} journeyProfile={journeyProfile} journeyType={journeyType} />
              </div>
            }
          </div>
        </div>
    
        </div>
      </div>
    </React.Fragment>, document.body)
  )
}

export default FilesLinksModal;