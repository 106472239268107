import React, {Fragment, useState, useEffect, useContext} from "react"
import {Link, useHistory} from "react-router-dom"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../../services/CommonService"
import useModal from "../../../../../hooks/useModal"
import {FormFieldTable, RenderData} from "../../../UserRoles/NotificationPill"
import ActivityService from "../../Activities/ActivityService"
import useStyle from '../../../../../hooks/useStyle'
import NutritionCareNotification from '../../../../NutritionCares/NutritionCareNotification'
import GenericModal from "../../../../../components/Modals/GenericModal"
import ActivityForm from '../../../MotherInfantJourney/Activities/Form/ActivityForm'
import CTLazyLoad from "../../../../Common/CTLazyLoad"
import { LinkFontAwesomeIcon } from "../../../../Common/ImageIcons"
import useSort from '../../../../../hooks/useSorting'
import SortDirection from '../../../../../components/Common/SortDirection'
import {FontAwesomeBtn} from "../../../../Common/Button"
import {OptionsObjList} from "../../../../Common/FormInput"
import { CTTooltip, CueTip } from "../../../../Common/CueTooltip"
import {DraftBadge} from "../../../UserRoles/NotificationPill"
import {ActivityCard} from "./ActivityCard"
import PaginationSM from '../../../../Common/PaginationSM'
import {EmptyRowAlert} from '../../../../Common/TableView'

const css = ['/css/assess-bf-card.css']
let requestParams = {}
let selectedItem = null
let actFormProps = null
const AssessBFTable = (props) =>{
  const {state: { user: currentUser }} = useContext(AuthContext)

  const {journeyProfile, searchString, componentName, dataSource, timeFilter} = props
  const history = useHistory()

  const [child, setChild] = useState()
  const [loading, setLoading] = useState(true)
  const [isTableView, setTableView] = useState(true)
  const [activities, setActivities] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState({})
  const [formFields, setFormFields] = useState()
  const [formFieldsGrouped, setFormFieldsGrouped] = useState()
  const [accessBfReport, setAccessBFReport] = useState({})
  const [filter, setFilter] = useState(timeFilter?.options[0])
  const [formFieldAlerts, setFormFieldAlerts] = useState({})
  const [counselingIds, setCounselingIds] = useState([])

  const { isOpen:isAssessMenuOpen, toggleModal: toggleAssessMenuModal } = useModal()
  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal()
  const { isOpen:isInfantActivityFormOpen, toggleModal:toggleInfantActivityModal } = useModal()
  const { isOpen:isActivityCardOpen, toggleModal: toggleActivityCardModal } = useModal()

  useEffect(() => {
    requestParams = {
      sort_column: 'scheduled_date',
      sort_direction: 'desc',
      journey_profile_id: journeyProfile.id,
      search: searchString,
      page: currentpage,
      per_page: 15,
      activity_form_id: '477',
      filter: filter,
      form_alerts_params:{
        form_id: 477,
        default_fields:'ignore',
        fields:['record_id', 'data_source_list_value', 'field_client_id', 'form_template_id', 'data_source_mappings']   
      },
      form_fields_params: {
        ids:[477]
      }
    }
      getActivityList()
  }, [searchString, currentpage, filter])

  let getActivityList = () => {
    setLoading(true)
    if(formFields) delete requestParams.form_fields_params
    
    ActivityService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        requestParams.totalItems = res.data.meta.total
        setActivities(res.data.activities)
        setChild(res.data.child)
        setPagemeta(res.data.meta)
        /*const access_bf = Cs.groupBy(res.data.access_bf_reports, 'value')
        setAccessBFReport({
          ...access_bf,
          favorable_count:access_bf[1]?.reduce((acc, val) => acc + parseInt(val.count), 0),
          unfavorable_count:access_bf[1]?.reduce((acc, val) => acc + parseInt(val.count), 0)
        })*/
        if(!formFields){
          setFormFields(res.data.form_fields[477])
          setFormFieldsGrouped(Cs.groupBy(res.data.form_fields[477], 'client_id'))
        }
        if(res.data.form_alerts){
          setFormFieldAlerts(Cs.groupBy(res.data.form_alerts, 'field_client_id'))
        }
        setLoading(false)
      }
    })
  }
  
  const {sort} = useSort(requestParams, getActivityList)

  const onRangeFilterChange = (selectedIndex) =>{
    const f = timeFilter?.options[selectedIndex]
    setFilter({
      label:f.label,
      per_page:15,
      date_type:f.sid
    })
  } 

  const routeAssess = (id, schedule_id) =>{
    actFormProps = Cs.routeInfantPath(dataSource, {}, journeyProfile, history, null, id)
    if(child){
      let c = child
      c.data.baby_age = Cs.getAge(c.data.dob_baby_formatted_date)
      actFormProps.location.state = {
        child_fk_id: c.record_id, 
        dob_baby_formatted_date: c.data.dob_baby_formatted_date, 
        id_mother: journeyProfile.data.cell_number, 
        baby_age: c.data.baby_age
      }
    }

    actFormProps.match.params.schedule_id = schedule_id
    actFormProps.component = ActivityForm
    toggleAssessMenuModal()
    toggleInfantActivityModal()
  }

  const onFormSubmit = (activity={}) =>{
    if(activity.show_nutrition_care){
      openNotification(null, activity)
    }
    getActivityList()
    toggleInfantActivityModal()
  }

  const openNotification = (e, activity) =>{
    e && e.stopPropagation()
    setCounselingIds([])
    selectedItem = activity
    toggleNutritionCareModal()
  }

  const openActivityCard = (activity) =>{
    selectedItem = activity
    toggleActivityCardModal()
  }

  const openCounselingAlert = (alerts) =>{
    selectedItem = null
    const counselingIds = alerts.map(a=>a.mappings)?.flat()?.map(c => c.mapping_id)
    if(counselingIds.length > 0){
      setCounselingIds(counselingIds)
      toggleNutritionCareModal()
    }
  }

  const setAlertMessage = (activity) =>{
    if(activity.id){
     return ''
    }else if(Cs.compareDate(activity.scheduled_date, null, 'days') >= 0){
      return activity.notification_text || 'A visit to take new measurements is scheduled for this date'
    }else{
      return 'Measurement data from this visit was not submitted.'
    }
  }

  const checkAlertMessage = (activity) =>{
    if(activity.id){
     return ''
    }else if(Cs.compareDate(activity.scheduled_date, null, 'days') >= 0){
      return activity.notification_text || 'A visit to take new measurements is scheduled for this date'
    }else{
      return 'Measurement data from this visit was not submitted.'
    }
  }

  const groupAccessBFReport = (data_source) =>{
    const accessBfReport = {1:[], 2:[]}
    for (const k in data_source) {
      const d = data_source[k]
      if(d?.value){
        d.key = k
        accessBfReport[d.value].push(d)
      }
    }
    return accessBfReport
  }
    
  const AccessBFTable = () =>(
      <table className="table">
        <thead>
          <tr>
            <th className='text-center'>
              Id 
            </th>
            <th className='text-center'>
              Location
            </th>
            <th className='text-center'>
              Date 
            </th>
            <th className='text-center'>
              As in Tutorial
            </th>
            <th className='text-center'>
              Needs Tutorial
            </th>
            <th className='text-center'>
              Notification
            </th>
            <th className='text-center'>
              By
            </th>
            <th className='text-center'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <AccessBFTableData activities={activities} 
          routeAssess={routeAssess}
          openNotification={openNotification}
          checkAlertMessage={checkAlertMessage}
          groupAccessBFReport={groupAccessBFReport}
          formFieldsGrouped={formFieldsGrouped}
          openActivityCard={openActivityCard}
          formFieldAlerts={formFieldAlerts}
          openCounselingAlert={openCounselingAlert}/>
        </tbody>
      </table>
  )

  const ActivityList = () => activities && activities.map((a, i) => (
    <div className="col-xs-12 col-sm-6 col-md-4 p-5" key={i}>
      <GenericContent activity={a} formFieldsGrouped={formFieldsGrouped} index={i}
      openNotification={openNotification} routeAssess={routeAssess}
      groupAccessBFReport={groupAccessBFReport} 
      checkAlertMessage={checkAlertMessage}
      openActivityCard={openActivityCard}/>
    </div>
  ))

  if(isActivityCardOpen){
    return <GenericModal 
      component={ActivityCard} 
      isOpen={isActivityCardOpen}
      toggleModal={toggleActivityCardModal}
      activity={selectedItem}
      formFields={formFields}/>
  }

  return(
    <CTLazyLoad css={css}>
      <FontAwesomeBtn id="bf_add_btn" tooltip="Record details in a new entry" 
        iconCss="fa fas fa-plus" isResponsive={false} name="Add"
        css="pull-right m-b-5 m-r-15 lh-17" colorCss="btn-green"
        onSelect={()=>routeAssess()}>
      </FontAwesomeBtn>

      <FontAwesomeBtn id="bf_toggle_table" tooltip="Toggle Card/Table View" 
        iconCss="fa fa-table" isResponsive={false} name="Table" 
        css="pull-right m-b-5 m-r-15 lh-17" colorCss="bg-highlight"
        onSelect={()=>setTableView(!isTableView)}>
      </FontAwesomeBtn>
      
      <span id="bf_date_filter" className="tooltip pull-right m-r-15">  
        <select onChange={(e)=>onRangeFilterChange(e.target.selectedIndex)}
          className="form-control ht-33 fw500 mw-150"
          defaultValue={filter.label}>
          <OptionsObjList list={timeFilter?.options} id_key="label" label_key="label" user_locale={currentUser.current_locale}/>
        </select>
        <CueTip tooltip="View data for a calendar range" positionCss="bottom"/>
      </span>
        
      <div className="col-xs-12 table-responsive table-fix-head">
        {loading?<div className="spinner"/>:
          <>    
            {isTableView?
              <AccessBFTable/>
              :
              <ActivityList/>
            }

            <PaginationSM
              className="pagination-bar"
              currentPage={currentpage}
              totalItems={pagemeta.total}
              itemsPerPage={pagemeta.per_page}
              onPageChange={setCurrentpage}
              siblingCount={2}/>

            {activities.length === 0 &&
              <EmptyRowAlert/>
            }
        
            {isNutritionCareOpen && 
              <GenericModal component={NutritionCareNotification} 
              toggleModal={toggleNutritionCareModal} isOpen={isNutritionCareOpen}
              itemType="infant.activity" itemUuid={selectedItem?.id}
              closeIco="fas fa-chevron-down" counselingIds={counselingIds}/>
            } 

            {isInfantActivityFormOpen && 
              <GenericModal toggleModal={toggleInfantActivityModal} 
              isPopupMode="true" onFormSubmit={onFormSubmit} 
              closeConfirmPopup={true} {...actFormProps} />
            }
          </>
        }
      </div>
    </CTLazyLoad>
  )
}

const AccessBFTableData = ({formFieldAlerts, openCounselingAlert, activities, openActivityCard, routeAssess, openNotification, checkAlertMessage, groupAccessBFReport, formFieldsGrouped}) => activities.map((activity, k) => {
  const {data, data_source} = activity
  const msg = checkAlertMessage(activity)
  const accessBfReport = groupAccessBFReport(data_source)

  return(
    <tr key={k}>
      <td className="text-center" onClick={()=>openActivityCard(activity)}>
        {activity.record_id}
        <DraftBadge isDraft={activity.draft}/>
      </td>

      <td className="text-center">
        
      </td>

      <td className="text-center">
        {Cs.formatUpdateDate(activity.id? activity.created_at : activity.scheduled_date)}
      </td>
        
      <td className="text-center">
        <div className="mxh-250 scroll-y">
          <BFPanelList 
            isPillView={true}
            className="card-bg-green" 
            list={accessBfReport[1]} 
            formFieldsGrouped={formFieldsGrouped}/>
        </div>
      </td>

      <td className="text-center">
        <div className="mxh-250 scroll-y">
          <BFPanelList 
            isPillView={true}
            className="badge-danger" 
            list={accessBfReport[2]} 
            formFieldsGrouped={formFieldsGrouped}
            formFieldAlerts={formFieldAlerts}
            openCounselingAlert={openCounselingAlert}/>
        </div>
      </td>

      <td className="text-center">
        <div className="card-footer flex justspacebetween p20 aligncenter mih-72">
          <ul className="flex aligncenter">
            {activity.nutrition_care_alert_count > 0 &&
              <li className="m-r-20">
                <LinkFontAwesomeIcon 
                  tooltip="Notifications"
                  css="fas fa-envelope white"
                  count={activity.nutrition_care_alert_count}
                  onSelect={(e)=>openNotification(e, activity)} />
              </li>
            }
          </ul>
        </div> 
      </td>
      
      <td className="text-center">
        {activity.author_name}
        <div>{activity?.member_role?.role}</div>
      </td>

      <td className="text-center">
        {activity.id ?
          <span onClick={e=>routeAssess(activity.id, activity.schedule_id)} className="fas fa-edit m-r-15 font-18 text-muted" />
          :
          <>
            {Cs.compareDate(activity.scheduled_date, null, 'days', false, true) <= 0 &&
              <div className="text-center p-t-15p pointer">
                <button onClick={e=>routeAssess(activity.id, activity.schedule_id)}
                  type="button" className="btn btn-icon-add btn-sm text-white m-b-5">
                    Add
                </button>
              </div>
            }
            <div className="m-t-15 w-100">  
              {msg}
            </div>
          </>
        }
      </td>
    </tr>
  )
})

const BFPanelList = ({list = [], formFieldAlerts={}, openCounselingAlert, className, formFieldsGrouped, isPillView}) => list ? list.map((a, i) => {
  const field = formFieldsGrouped[a.key]?.[0]
  const fieldAlerts = formFieldAlerts[field.client_id]
  if(isPillView){
    return(
      <div key={i} className={`badge ${className} f16 w-full black`}>
        {field?.label} 
        {fieldAlerts?
          <div className="badge-2 bg-lred m-l-5" onClick={()=>openCounselingAlert(fieldAlerts)}>
            {fieldAlerts?.length}
          </div>
          :null
        }
      </div>
    )
  }

  return(
    <tr key={i}>
      <td>
        <div className="flex aligncenter">
          <div className={`dots m-r-10 ${className}`}></div>
          <div>{field?.label}</div>
        </div>
      </td>
    </tr>
  )  
}):null

const GenericContent = ({activity, formFieldsGrouped, openNotification, routeAssess, checkAlertMessage, groupAccessBFReport}) =>{
  const {data, data_source} = activity
  const accessBfReport = groupAccessBFReport(data_source)
  const msg = checkAlertMessage(activity)

  return(
    <>
        <div className="bg-white border-rounded-10 card-shadow m-b-15">
          <div className="card-title card-title-gray flex justspacebetween p-b-15 p20 f18">
            Assess BF {activity.id? '' : Cs.formatUpdateDate(activity.scheduled_date)}
            <div>
              {activity.author_name} - {Cs.formatUpdateDate(activity.created_at)}
            </div>
          </div>
          <div className="p20 ht-240 scroll-auto">
            <div className="wk-center">
              {!activity.id && 
                <>
                  {Cs.compareDate(activity.scheduled_date, null, 'days', false, true) <= 0 &&
                    <div className="text-center p-t-15p pointer">
                      <button onClick={e=>routeAssess(activity.id, activity.schedule_id)}
                        type="button" className="btn btn-icon-add btn-sm text-white m-b-5">
                          Add
                      </button>
                    </div>
                  }
                  <div className="m-t-15 w-100">  
                    {msg}
                  </div>
                </>
              }
            </div>
            {activity.id && 
              <>
                <div className="col-xs-12 col-sm-6">
                  <table className="table table-stripe table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">
                          {activity.author_name}
                          <div>{activity?.member_role?.role}</div>
                        </th>
                      </tr>
                      <tr>
                        <th>
                          <div className="badge badge-danger f16 flex aligncenter justifycenter w-full black">
                            <i className="fas fa-thumbs-down w-16px m-r-10" alt=""/>
                            Not Ok - {accessBfReport[2]?.length}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <BFPanelList className="dot-red-dark" list={accessBfReport[2]} formFieldsGrouped={formFieldsGrouped}/>
                    </tbody>
                  </table>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <table className="table table-stripe table-bordered">
                    <thead>
                      <tr>
                        <th className="text-center">{Cs.formatUpdateDate(activity.created_at)}</th>
                      </tr>
                      <tr>
                        <th>
                          <div className="badge f16 card-bg-green flex aligncenter justifycenter w-full text-center black">
                            <i className="fas fa-thumbs-up w-16px m-r-10" alt=""/>
                            Ok - {accessBfReport[1]?.length}
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <BFPanelList className="dot-green-dark" list={accessBfReport[1]} formFieldsGrouped={formFieldsGrouped}/>
                    </tbody>
                  </table>
                </div>
          </>   
        }
        </div>
        <div className="card-footer flex justspacebetween p20 aligncenter mih-72">
          <ul className="flex aligncenter">
            {activity.nutrition_care_alert_count > 0 &&
              <li className="m-r-20">
                <LinkFontAwesomeIcon 
                  tooltip="Notifications"
                  css="fas fa-envelope white"
                  count={activity.nutrition_care_alert_count}
                  onSelect={(e)=>openNotification(e, activity)} />
              </li>
            }
            {activity.id &&
              <li className="m-r-20">
                <LinkFontAwesomeIcon 
                  tooltip="Edit"
                  css="fas fa-edit white"
                  onSelect={()=>routeAssess(activity.id, activity.schedule_id)} />
              </li>
            }
          </ul>
        </div>
      </div>
    </>
  ) 
}

export default AssessBFTable;

/*
 return(
    <>
      <div className="card-container bg-white shadow-small">
        <div className="white card-header p-5 bg-activity">
          <div className="font-16 lh-18 ellipsis-lg">
            {activity.id?
              'Assess BF'
              :
              Cs.formatUpdateDate(activity.scheduled_date)
            }
          </div>
          <div className="col-xs-12">
            <div className="pull-left">
              {activity.author_name}
              <div>{activity?.member_role?.role}</div>
            </div>
            <div className="pull-right">{Cs.formatUpdateDate(activity.created_at)}</div>
          </div> 
        </div> 

        <div className="ht-240 scroll-auto">
          <div className="wk-center">
            {!activity.id && 
              <>
                {Cs.compareDate(activity.scheduled_date, null, 'days', false, true) <= 0 &&
                  <div className="text-center p-t-15p pointer">
                    <span onClick={e=>routeAssess(activity.id, activity.schedule_id)} 
                      className="fas fa-plus font-30 text-muted">
                    </span>
                  </div>
                }
                <div className="m-t-15 w-100">  
                  {msg}
                </div>
              </>
            }

            {activity.id &&
              <>
                <div className="col-xs-12 col-sm-6">
                  <div className='m-t-5 white bg-black p-2 bold-400 font-16 text-center'>
                    Unfavorable - {accessBfReport[2]?.length}
                  </div>
                  <BFPanelList list={accessBfReport[2]} formFieldsGrouped={formFieldsGrouped} className="bg-lred white"/>
                </div>
                <div className="col-xs-12 col-sm-6">
                  <div className='m-t-5 white bg-black p-2 bold-400 font-16 text-center'>
                    Favorable - {accessBfReport[1]?.length}
                  </div>
                  <BFPanelList list={accessBfReport[1]} formFieldsGrouped={formFieldsGrouped} className="bg-lgreen white"/>
                </div>
              </>            
            }
          </div>
        </div>

        <div className="vard-footer m-t-10 ht-25">
          {activity.nutrition_care_alert_count > 0 &&
            <span onClick={e=>openNotification(e, activity)} 
              className="fas fa-envelope m-l-15 font-18 text-muted">
              <span className="badge-2 up bg-lred">{activity.nutrition_care_alert_count}</span> 
            </span>
          } 

          {activity.id &&
            <span onClick={e=>routeAssess(activity.id, activity.schedule_id)} 
              className="fas fa-edit m-l-15 font-18 text-muted">
            </span>   
          }
        </div>
      </div>
    </>
  )
*/