import React, {Fragment,useState} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../../hooks/useStyle';
import useSort from '../../../../hooks/useSorting';
import SortDirection from '../../../../components/Common/SortDirection'
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from '../../../Common/arrayMove';
import Cs from "../../../../services/CommonService";
import { Table, THead, TH, TR, TD, TBody } from "../../../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../../Common/ImageIcons";

const FacilityTableList = ({toggleDeleteModal, getFacilityList, facilityList, organizationId, requestParams, selectedFacility, openMapModal, permission}) => {

  const {sort} = useSort(requestParams, getFacilityList);
  
  const TableData = ({facilityList}) => facilityList && facilityList.map((o, k) =>
    <TR key={k}>
      <TD className="fw500 text-gray" data-title="Id">{o.record_id}</TD>
      <TD data-title="Name">{o.data.name}</TD>
      <TD data-title="Created Date">{Cs.formatUpdateDate(o.updated_at)}</TD>
      <TD data-title="Taluka">{o.data.taluka}</TD>
      <TD data-title="District">
      { o.data.district}</TD>
      <TD data-title="Created By">{o.user?.name}</TD>
      <TD data-title="Remarks">{o.data.remarks}</TD>
      <TD data-title="Options" >
        <div className="flex aligncenter">
          {permission.write ? 
            <>
              <LinkDeleteIcon id="delete_facility" tooltip="Delete facility" onSelect={(e)=>toggleDeleteModal(e, o)}/>
              <LinkFontAwesomeIcon css="far fa-edit white" link={`/health/facility/${organizationId}/${o.id}`}/>
              <LinkFontAwesomeIcon css="fas fa-users white" count={o.members_count} link={`/${o.data.name}/facility/members/${organizationId}/${o.id}`}/>
            </>
            : null
          }
          {o.data.latitude && o.data.longitude &&
            <LinkFontAwesomeIcon css="fas fa-location-dot white" onSelect={()=>openMapModal(o)}/>
          }
        </div>
      </TD>
    </TR>
  )

  return (
    <div className="table-fix-head">
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH onClick={e=>sort('record_id')}>Id
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('data.name')}>Facility Name
                <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('updated_at')}>Updated Date 
                <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('data.taluka')}>Taluka
                <SortDirection sort_column={requestParams.sort_column} column="data.taluka" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('data.district')}>District
                <SortDirection sort_column={requestParams.sort_column} column="data.district" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('created_by')}>Created By
                <SortDirection sort_column={requestParams.sort_column} column="created_by" reverse={requestParams.reverse}/> 
              </TH>
  
              <TH onClick={e=>sort('data.remarks')}>Remarks
                <SortDirection sort_column={requestParams.sort_column} column="data.remarks" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('created_at')}>Options
                <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/> 
              </TH>
            </TR>
          </THead>
          <TBody>
            <TableData facilityList={facilityList} />
          </TBody>
        </Table>
    </div>
  )

}

export default FacilityTableList;