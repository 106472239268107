import env from '../../env';
import axios from 'axios';
import BaseService from '../../services/BaseService';

class HelpService extends BaseService{

  getHelpScreens(req){
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}health/help_screens`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
  
}

export default new HelpService('health_backend','health/helps');