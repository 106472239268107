import React, {useState, useEffect} from "react"
import importScript from '../../hooks/importScript'
import env from '../../env'

let url = env.socket;
let socket = {}

const Socket = (currentUser) => {
    
    let [isConnected, setIsConnected] = useState(false);
    
    useEffect(() => {
        if(!isConnected)importScript('https://cdnjs.cloudflare.com/ajax/libs/socket.io/4.3.2/socket.io.js', init);
        return () => {
          try{  
            
          }catch(e){
            console.log(e)
          }  
        }

        if (window.Notification && Notification.permission !== "granted") {
            Notification.requestPermission(function (status) {
                if (Notification.permission !== status) {
                    Notification.permission = status;
                }
            })
        }
    }, []);

    function init(){
        if(!currentUser)return;
        socket.io = window.io(env.socket+'?token='+currentUser.id);

        //io('ws://127.0.0.1:9001', {transports: ["websocket"]});
        
        setIsConnected(true);    

        /*socket.io.on("welcome", (data) => {
          console.log("Message: ", data)  
        })  */
        
        socket.io.on("chat_message", (res) => {
            console.log("Message: ")  
            socket.onChatMessageReceived(res)
            if (window.Notification && Notification.permission === "granted") {
                systemNotification(res);
            }else if (window.Notification && Notification.permission !== "denied") {
              Notification.requestPermission(function (status) {
                if (status === "granted") {
                   systemNotification(res); 
                }else {
                    uiNotification(res);
                }
              })
            }else {
               uiNotification(res);
            }
        })
 
        /*socket.subscription = socket.fayeClient.subscribe (sender_channel, function (res) {
            
            //uiNotification(res);
        })*/
    }

    const uiNotification = (res) =>{
        if(res.notification_type == 'chat'){
            res.data.notifiable_type = 'chat'
            //setSocketData && setSocketData(res.data)
        }
        /*res.data.sender_name = res.sender_name
        setNotification(res.data);*/
    }

    const systemNotification = (res) =>{
        let notification = {}
        if(res.notification_type == 'chat'){
            notification = new Notification(`Chat-${res.data.author.name}`, {
                body: res.data.message,
                icon: 'images/cueTree_logo.png'
            })
            //setSocketData && setSocketData(res.data)
        }else{
            notification = new Notification(res.data.message, {
                body: res.data.email_data.title,
                icon: 'images/cueTree_logo.png'
            })
        }  

        notification.onclick = function(event) {
            event.preventDefault(); // prevent the browser from focusing the Notification's tab
            if(res.notification_type == 'chat'){
                socket.openChatUser(res)
            }else{
                window.open('https://health.beta.cuetree.com/redirect?data='+JSON.stringify(res.data || {}));    
            }
        }
    }

}

export {Socket, socket}