import React, { useState, useEffect, Fragment} from 'react'
import {Link, useHistory} from "react-router-dom"
import OrganizationMemberService from "./OrganizationMemberService"
import Cs from "../../../services/CommonService"
import {Table, THead, TH, TR, TD, TBody} from "../../Common/TableView"
import {PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon} from "../../Common/ImageIcons"

const MemberJourneyList = ({params, member}) => {
  const history = useHistory()

  const [loading, setLoading] = useState(true)
  const [journeyProfiles, setJourneyProfiles] = useState([])

  useEffect(() => {
    getMemberJourneys()
  }, [])

  const getMemberJourneys = () =>{
    const req = {
      organization_id:params.organization_id,
      medical_facility_id:params.medical_facility_id,
      member_user_id:member.user_id,
      member_role_id:member.role_record_id
    } 
    OrganizationMemberService.getJourneyProfiles(req).then((res) => {
      if (res.status == 200) {
      	setJourneyProfiles(res.data.journey_profiles)
      	setLoading(false)
      }
    })
  }

  let routeJourney = (journey)=>{
    Cs.routeJourney(journey, {history: history})
  }

  const tableData = journeyProfiles.map((j, k) => {
    let data = j.data || {}

    return(
      <TR key={k} className="">
        <TD className="ellipsis-lg">
          <span className="bold pointer" onClick={e=>routeJourney(j)}>
            {data.mother_name || data.patient_name}
          </span>
          <div className="m-t-10">
            <i className="fas fa-phone lgrey font-16 no-click m-r-10"/>
            {data.cell_number_formatted || <font className="c-bemedian">Mobile Not Provided</font>}
          </div>
          <div className="m-t-10">
            <i className="fas fa-map-marker lgrey font-16 no-click m-r-10"/>
            {data.village} {data.taluka || <font className="c-bemedian">Address Not Provided</font>}
          </div>
        </TD>
        <TD className="vat">
           <span className="m-t-5">{Cs.formatUpdateDate(j.updated_at)}</span>
        </TD>
        <TD className="vat m-t-10 d-flex">
          {j.status != 'closed' && 
            <span id="path_details" data-tip="View path details" 
            	onClick={e=>routeJourney(j)} 
            	className="fa far fa-map font-18 text-muted"
            />
          }
        </TD>
        <TD className="vat text-cap">
          <span className="m-t-10">{j.status}</span>
        </TD>
      </TR>
    )
  })

  return(
    <div id="ct">  
  	  <div className="table-responsive" >
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH>Name | Case ID</TH>
              <TH>Last</TH>
              <TH>Options</TH>
              <TH>Status</TH>
            </TR>
          </THead>
          <TBody>
            {tableData}
          </TBody>
        </Table>
      </div>
    </div>
  )

}

export default MemberJourneyList;