import React, {useRef, useEffect, useState} from 'react'
import importScript from '../../hooks/importScript'
import useStyle from '../../hooks/useStyle'
import Cs from '../../services/CommonService'

const LeafletMap = ({lat, long, markerTitle, latLongList=[], addCustomMarker, zoomOffset}) => {
  useStyle('/scripts/leaflet_latest/leaflet.css', true)

  const [loading, setLoading] = useState(true)
  const [map, setMap] = useState()

  useEffect(() => {
    importScript('/scripts/leaflet_latest/leaflet.js', setMapView, window.L)
  }, [])

  useEffect(()=>{
    if(map){
      let tiles = window.L.tileLayer(
        'https://tile.openstreetmap.org/{z}/{x}/{y}.png', 
        {
          maxZoom: 18,
          attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          //id: 'mapbox/streets-v11',
          tileSize: 512,
          zoomOffset: zoomOffset || -1
        }
      )
      .addTo(map);
      
      if(addCustomMarker){
        addCustomMarker(map)
      }else if(latLongList.length>0){

      }else{
        addMarker(lat, long)
      }
    }
  }, [map])
  
  const setMapView = () =>{
    setMap(
      window.L.map('map').setView([lat, long], 13)
    )

    setLoading(false)

  /*  var circle = window.L.circle([51.508, -0.11], {
      color: 'red',
      fillColor: '#f03',
      fillOpacity: 0.5,
      radius: 500
    }).addTo(map).bindPopup('I am a circle.');

    var polygon = window.L.polygon([
      [51.509, -0.08],
      [51.503, -0.06],
      [51.51, -0.047]
    ]).addTo(map).bindPopup('I am a polygon.');


    var popup = window.L.popup()
      .setLatLng([51.513, -0.09])
      .setContent('I am a standalone popup.')
      .openOn(map);

    function onMapClick(e) {
      popup
        .setLatLng(e.latlng)
        .setContent('You clicked the map at ' + e.latlng.toString())
        .openOn(map);
    }

    map.on('click', onMapClick);*/

  }

  const addMarker = (lat, long) =>{
    var marker = window.L.marker([lat, long])
      .addTo(map)
      .bindPopup(`<b>${markerTitle}</b>`)
      .openPopup();
  }

  return (
    <>
      <div className="row p-5">
        <div className="col-xs-12 p-5">
          <div id="map" style={{'height': '90vh'}}></div>
        </div>
      </div> 
    </>
  )
}

export {LeafletMap};