import React, {useContext} from 'react'
import { Route, Redirect } from 'react-router-dom'
import { AuthContext } from "../contexts/Authentication/AuthStateProvider"
import CommonService from "../services/CommonService"

const PrivateRoute = ({component: Component, ...rest}) => {
	const { state:{isAuthenticated, user:currentUser}, dispatch } = useContext(AuthContext)
    
    if(!isAuthenticated){
    	return(
    		<Route {...rest} render={props => <Redirect to="/signin"/>} />
    	)
    }else if(!currentUser.current_organization && currentUser.organizations.length == 0){
        /*User is not part of any organization. Route to org profile form.*/
  		return(
  			<Route {...rest} render={props => <Redirect to="/profile"/>} />
    	)
    }else if(currentUser.current_organization?.is_active == false){
        /*Organization is Inactive*/
        return(
            <Route {...rest} render={props => <Redirect to="/switch/organization"/>} />
        )
    }else if(!currentUser.current_member){
        //User is not a member of organization or medical facility
        return(
            <Route {...rest} render={props => <Redirect to="/switch/organization"/>} />
        )
    }else{
    	return(
    		<Route {...rest} render={(props) => <Component {...rest} {...props}/>} />
    	)
    }
}

export default PrivateRoute;