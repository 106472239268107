import React, { useEffect } from "react"
import {JourneyInlineMenu, PortalToggleMenu} from "../GenericPortalPage/Common"
import Cs from "../../../services/CommonService"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import {DraftBadge} from "../UserRoles/NotificationPill"

const JourneyBlock = (props) => {
  let components = {
    499:CancerJourneyBlock,
    500:MotherInfantJourneyBlock,
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const TagName = components[props.formId];  

  if(TagName){
    return (
      <>
        <TagName {...props}/>
      </>
    )
   }else{
  	return (
  		<div>None</div>
  	)
  }  	
}

const CancerJourneyBlock = (props) => {
  let {journey, menuOptions, orgBgImageStyle} = props
  let {data, data_source} = journey
  
  return (
    <>
      <div className="flex coldir fulwid p10">
        <div className="flex alignstart justspacebetween f16">
          <div>
            <div className="f24 m-r-20 text-blue-2 flex aligncenter">
              {journey.record_id} - {data.patient_name}
              <DraftBadge isDraft={journey.draft} styleName="m-l-15"/>
            </div>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20">
                <i className="fas fa-location-dot text-blue-2 m-r-5"/> 
                {journey.data.district}, {journey.data.state || "Address Not Provided"}
              </li>
              <li className="m-r-20">
                <i className="fas fa-phone text-blue-2 m-r-5"/> 
                {data.cell_number || "Mobile Not Provided"}
              </li>
              <li className="m-r-20">
                <span className="far fa-calendar-alt text-blue-2 m-r-5"/>
                {Cs.formatUpdateDate(journey.created_at, 'MMM D YYYY')}
              </li>
            </ul>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20">
                <span>Health Condition: </span>
                {data_source.health_condition.label}
              </li>
            </ul>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20">
                <span>Last Update: </span>
                {Cs.formatUpdateDate(journey.updated_at, 'MMM D YYYY')}
              </li>
            </ul>
          </div>  

          <div className="flex aligncenter">
            <Link data-tip="Edit"
              className="icon-gray-cirle border-rounded-30 flex aligncenter justifycenter" 
              to={`/health/form/${journey.form_id}/${journey.id}`}>
                <i className="fas fa-edit" />
            </Link>

            <PortalToggleMenu journey={journey} menuOptions={menuOptions} 
              journeyType="infant_journey" menuDsId='3421'
              permissionKey="infant_portal"/>
          </div>  
        </div>
      </div>
    </> 
  )
}

const MotherInfantJourneyBlock = (props) => {
  let {journey, child, menuOptions, orgBgImageStyle} = props
  let {data} = journey

  return (
    <>
      <div className="flex coldir fulwid p10">
        <div className="flex alignstart justspacebetween f16">
          <div>
            <div className="f24 m-r-20 text-blue-2 flex aligncenter">
              {journey.record_id} - {""+journey.data.mother_name || journey.data.patient_name}
              <DraftBadge isDraft={journey.draft} styleName="m-l-15"/>
            </div>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20">
                <i className="fas fa-location-dot text-blue-2 m-r-5"/> 
                {journey.data.district}, {journey.data.state || "Address Not Provided"}
              </li>
              <li className="m-r-20">
                <i className="fas fa-phone text-blue-2 m-r-5"/> 
                {journey.data.cell_number_formatted || "Mobile Not Provided"}
              </li>
              <li className="m-r-20">
                <span className="far fa-calendar-alt text-blue-2 m-r-5"/>
                {journey.data.record_date}
              </li>
            </ul>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20">
                <span>Height: </span>
                {journey.data_source?.mother_height?.label}
              </li>
              <li className="m-r-20">
                <span>Weight: </span>
                {journey.data_source?.mother_weight?.label}
              </li>
            </ul>
            <ul className="flex aligncenter m-t-15 card-xs-content">
              <li className="m-r-20">
                <span>Last Update: </span>
                {Cs.formatUpdateDate(journey.updated_at, 'MMM D YYYY')}
              </li>
              <li className="m-r-20">
                <div>{journey?.last_updated_user?.name}</div>
                <div>{journey?.member_role?.role}</div>
              </li>
            </ul>
          </div>  

          <div className="flex aligncenter">
            {!child && 
              <Link data-tip="Add Child" 
                to={`/infant/child/form/${journey.id}`} 
                className="pill font-14 bg-green white fw500 m-r-10" >
                Add Child
              </Link>
            }
            
            <Link data-tip="Edit"
              className="icon-gray-cirle border-rounded-30 flex aligncenter justifycenter" 
              to={`/health/form/${journey.form_id}/${journey.id}`}>
                <i className="fas fa-edit" />
            </Link>

            <PortalToggleMenu journey={journey} menuOptions={menuOptions} 
              journeyType="infant_journey" menuDsId='3530'
              permissionKey="infant_portal" child={child}/>
          </div>  
        </div>
      </div>

    </> 
  )
  
}

export default JourneyBlock;