import env from '../../../env';
let CheckAccess = {}
//let role = {};

/*CheckAccess.userAccess = (currentUser, journey, invited_journey_member) =>{
  if(currentUser.id == journey.updated_by ||
    (invited_journey_member && (invited_journey_member.full_access ||  
    (invited_journey_member.permissions && invited_journey_member.permissions.full_access)))){
      journey.is_admin_user = true;
      journey.is_template_journey = false;
  }else if(journey.education_journey_profiles.data.ai_training_user=='Yes'){
      journey.is_template_journey = true;
  }
}

CheckAccess.hasStepActivityAccess = (currentUser, journey, activity) =>{
  try{
    if(journey.is_admin_user || currentUser.id == activity.created_by){
      return true;
    }else if(activity.user_roles && activity.user_roles.permissions.add_step_activity){
      return true;
    }
  }catch(e){
      return false;  
  }
}

CheckAccess.hasChecklistAccess = (journey, activity) =>{
	return (journey.is_admin_user || (activity.user_roles && activity.user_roles.permissions.add_checklist))
}

CheckAccess.hasRatingAccess = (activity) =>{
	return activity.user_roles && activity.user_roles.permissions.add_review
}

CheckAccess.hasQuickOrPathAccess = (permission) =>{
	return (permission.quick_access || permission.track)
}

CheckAccess.hasAccess = (permission, key) =>{
  return (permission[key])
}

CheckAccess.isJourneyAuthor = (currentUser, journey)=>{
  return currentUser.id == journey.updated_by
}*/

CheckAccess.destructUser = (current_user)=>{
  const currentMember = current_user?.current_member || {}
  const orgMenuAccesses = current_user?.current_subscription?.menu_accesses || {}
  const userMenuAccess = currentMember?.permissions?.menu || {}
  return {
    currentMember:currentMember,
    orgMenuAccesses:orgMenuAccesses,
    userMenuAccess:userMenuAccess
  }
}

CheckAccess.checkOrgAccess = (current_user, sid)=>{
  const {
    currentMember, orgMenuAccesses, userMenuAccess
  } = CheckAccess.destructUser(current_user)

  /*const currentMember = current_user?.current_member || {}
  const orgMenuAccesses = current_user?.current_subscription?.menu_accesses || {}
  const userMenuAccess = currentMember?.permissions?.menu || {}*/

  if(orgMenuAccesses?.[sid] && 
    (currentMember.role_master 
      || currentMember.role_admin 
      ||  userMenuAccess?.[sid])){
    return true
  }

  return false
}

/*CheckAccess.checkOrgAccess = (current_user, sid)=>{
  const currentMember = current_user?.current_member || {}
  const orgMenuAccesses = current_user?.current_subscription?.menu_accesses || {}
  const userMenuAccess = currentMember?.permissions?.menu || {}

  if(currentMember.role_master || currentMember.role_admin || 
      (orgMenuAccesses?.[sid] && userMenuAccess?.[sid])){
    return true
  }

  return false
}*/

CheckAccess.checkUserAccess = (current_member={}, user_access={}, sid)=>{
  if(current_member.role_master || current_member.role_admin || current_member.role_dupty_admin || user_access?.[sid]){
    return true
  }

  return false
}

CheckAccess.checkIsAdmin = (current_user) =>{
  //const currentMember = current_user?.current_member || {}
  const {currentMember} = CheckAccess.destructUser(current_user)
  return currentMember.role_master || currentMember.role_admin || currentMember.is_dupty_admin
}

CheckAccess.checkLocaleTranslateAccess = (current_user, data_item, data_type, rw_type) =>{
  //const currentMember = current_user?.current_member || {}
  const {currentMember} = CheckAccess.destructUser(current_user)
  const rw = currentMember?.permissions?.menu[data_type] 
  return CheckAccess.checkIsAdmin(current_user) 
    || data_item.created_by === current_user.id
    || (rw?.[rw_type] 
      && currentMember?.permissions?.locale_translate?.indexOf(data_item?.data_source?.language?.sid) > -1
    )
}

CheckAccess.checkLocaleAuditAccess = (current_user, data_item, data_type) =>{
  //const currentMember = current_user?.current_member || {}
  const {currentMember} = CheckAccess.destructUser(current_user)
  return (currentMember?.permissions?.menu[data_type]?.read
    && currentMember?.permissions?.locale_audit?.indexOf(data_item?.data?.language_sid) > -1
  )
}

CheckAccess.getMenuPermission = (current_user, sid)=>{
  /*const currentMember = current_user?.current_member || {}
  const orgMenuAccesses = current_user?.current_subscription?.menu_accesses || {}
  const userMenuAccess = currentMember?.permissions?.menu || {}*/
  const {
    orgMenuAccesses, userMenuAccess
  } = CheckAccess.destructUser(current_user)

  if(orgMenuAccesses?.[sid] && CheckAccess.checkIsAdmin(current_user)){
    //default full access
    return {read:true, write:true}
  }

  return userMenuAccess?.[sid]?userMenuAccess?.[sid]:{read:false, write:false}
}

CheckAccess.getRoleName = (current_user) =>{
  //const currentMember = current_user?.current_member || {}
  const {currentMember} = CheckAccess.destructUser(current_user)

  if(currentMember.role_master){
    return 'Org Master'
  }else if(currentMember.role_admin){
    return 'Org Admin'
  }else if(currentMember.is_dupty_admin){
    return 'Org Deputy Admin'
  }else{
    return currentMember.role_names
  }
}

CheckAccess.getJourneyTypeIds = (current_user) =>{
  return current_user
  ?.current_subscription
  ?.data_source
  ?.journeys
  ?.map((j) => j.data.pk_id.toString()) || []
}

CheckAccess.hasDeleteAccess = (current_user, data_item) =>{
  //const currentMember = current_user?.current_member || {}
  const {currentMember} = CheckAccess.destructUser(current_user)
  return CheckAccess.checkIsAdmin(current_user) 
    || data_item.created_by === current_user.id
}

CheckAccess.hasEditAccess = (current_user, data_item) => {
  return (current_user.id === data_item.created_by)
}

CheckAccess.isDevUser = (email) => env.admin_emails.includes(email)

CheckAccess.filterUserLocale = (localeList, currentUser) =>{
  return localeList.filter(l => (['english', currentUser?.current_organization?.data_source?.language?.sid].indexOf(l.sid)>-1))
}

export default CheckAccess;