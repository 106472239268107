import React, {useState, useEffect, Fragment, useContext} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import ReactDOM from 'react-dom';
import useModal from "../../hooks/useModal";
import HelpService from './HelpService';
import useStyle from '../../hooks/useStyle';
import env from '../../env';
import Cs from '../../services/CommonService';
import {getLocale} from '../FormBuilder/FieldLabel';
import FilterSlider from "../Common/FilterSlider";
import {PlannerVideoPlayer} from '../Common/VideoPlayer';

const HelpSidePanel = (props) => {
  useStyle('help')

  const { state:{user:currentUser} } = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [helps, setHelps] = useState()

  useEffect(() => {
    if(props.recordIds){
      getHelp()   
    }else{
      console.error("No Help id passed")
    }
  }, [])

  let getHelp = () => { 
    setLoading(true)
    HelpService.get(null, {'parent_ids':props.recordIds, 'locale':currentUser.current_locale})
    .then((res)=>{
      if(res.status === 200){
        setHelps(res.data.help || [])
        setLoading(false)
      }
    })
  }

  return(
    <div id="#ct" className="p-l-15 p-r-10 zIndex-99">
      {loading?
        <div className="spinner"/>
        :  
        <>
          {helps.map((h, i) =>
            <div key={i}>
              <h5 className="pointer br-b-grey plr-10">
                {h.data?.title}
              </h5>
              <HelpDetails h={h}/>
            </div>
          )}
        </>
      }
    </div>      
  )
}

const HelpDetails = ({h}) =>{
  const { isOpen:playVideo, toggleModal:togglePlayVideo } = useModal()
  if(h.data.video_url){
    const params = new URL(h.data.video_url).searchParams
    h.data.img_url = `https://img.youtube.com/vi/${params.get('v')}/mqdefault.jpg`
  }

  return(
    <>
      <div id="help_img" dangerouslySetInnerHTML={{ __html: h.data?.help_detail}}/>
      { h.data.video_url ?
        <>
          <div className="border-dashed-sm play-btn p5" onClick={()=>togglePlayVideo()}>
            <img src={h.data.img_url} className="img-thumbnail img-rounded img-responsive center-block"/>
          </div>
          {playVideo ? <PlannerVideoPlayer url={h.data.video_url} toggleModal={togglePlayVideo}/> :null}
        </>
        :null
      }
    </>
  )
}

const HelpIcon = (props) =>{
  const { isOpen:isPanelOpen, toggleModal:togglePanel } = useModal()
  
  if(!props.recordIds)
    return null

  const css = props.className || 'highlight m-l-15 font-18'

  return(
    <>
      <span data-tip="Help" className={css} 
        onClick={()=>togglePanel()}>
          <i className="fa fa-info-circle"/>
      </span>

      {isPanelOpen &&
        <FilterSlider title="Help" component1={HelpSidePanel} 
          openSideNav={isPanelOpen} setOpenSideNav={togglePanel}
          {...props}/>
      }
    </>
  )
}

export {HelpIcon, HelpDetails};