import React, {useState, useReducer, useEffect} from "react"
import { Route, Switch, withRouter} from 'react-router-dom'
import { AuthContext, initialState } from "./contexts/Authentication/AuthStateProvider"
import Home from "./components/Home"
import Login from "./components/Authentication/Login"
import Signup from "./components/Authentication/Signup"
import ForgetPassword from "./components/Authentication/ForgetPassword"
import ResetPassword from './components/Authentication/ResetPassword'
import PublicRoute from './routes/PublicRoute'
import AxiosConfig from './AxiosConfig'
import Reducer from './contexts/Authentication/AuthReducer'
import AuthenticationService from "./services/AuthService"
import PreLoadSpinner from "./components/PreLoadSpinner"
import HrTimeline from "./components/Common/HrTimeline"
import ErrorBoundary from "./components/Common/ErrorBoundary"
import LoginUID from "./components/Authentication/LoginUID"

function App() {
  const [state, dispatch] = useReducer(Reducer, initialState)
  const [isUserSessionLoaded, setIsUserSessionLoaded] = useState(false)   

  useEffect(()=>{
    if(!state.getUserSession)getCurrentUserSession();
  }, [])

  const getCurrentUserSession = () =>{
    state.getUserSession = true
    AuthenticationService.get().then((user)=>{
      if(user.id){
        state.user = user
        state.isAuthenticated = true
      }
      setIsUserSessionLoaded(true)
    }, (err)=>{
      setIsUserSessionLoaded(true)
    }) 
  }

  if(!isUserSessionLoaded){
    return(
      <PreLoadSpinner/>
    )
  }

  return (
    <AuthContext.Provider value={{state, dispatch}}>
      <ErrorBoundary>
        <Switch>
          <PublicRoute path="/" component={Login} exact/>
          <PublicRoute path="/signin/:organization_member_id?" component={Login} exact/>
          <PublicRoute path="/auth/:uid" component={LoginUID} exact/>
          {/*<PublicRoute path="/c2lnbnVw/signup/:organization_member_id?" component={Signup} exact/>*/}
          {/*<PublicRoute path="/forget_password" component={ForgetPassword} exact/>*/}
          <PublicRoute path={['/password_reset/:userId/:token', '/password/update']} component={ResetPassword} exact/>
          <Route path="/timelinehr" component={HrTimeline}/>
          <Route path="/" component={Home}/>
          <Route component={Page404}/>
        </Switch>
      </ErrorBoundary>  
    </AuthContext.Provider>
  )
}

const Page404 = ({ location }) => (
  <div>
    <h2>No match found for <code>{location.pathname}</code></h2>
  </div>
)

export default withRouter(App);