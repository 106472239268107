import React, { useEffect, useState, useContext, Fragment} from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import CommonService from "../../services/CommonService"
import useStyle from '../../hooks/useStyle'
import {Link} from "react-router-dom"
import PreLoadSpinner from "../PreLoadSpinner"
import env from '../../env'
import HealthCategoryService from '../HealthJourney/HealthCategoryService'

let profileImage = 'images/avatars/2s.png'

const ContentManager = (props) => {
  useStyle('dashboard');

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [recentPlanners, setRecentPlanners] = useState([])
  const [recentCounselings, setRecentCounselings] = useState([])
  const [favouritePlanners, setFavouritePlanners] = useState([])
  const [favouriteCounselings, setFavouriteCounselings] = useState([])

  useEffect(() => {
    getAll()
  }, [currentUser.id])

  let getAll = function(){
    HealthCategoryService.getContentManagerData().then(({status, data})=>{
      if(status == 200){
        setRecentPlanners(data.recent_planners)
        setRecentCounselings(data.recent_counselings)
        setFavouritePlanners(data.favourite_planners)
        setFavouriteCounselings(data.favourite_counselings)
      }
    })
  }

  const getProfileImg = (n) =>{
    if(n.sender && n.sender.profile_image_url){
      const userImg = env.file_url+n.sender.profile_image_url
      return userImg
    }
    return profileImage
  }

  const RecentPlannersView = ({}) => recentPlanners.map((n, k) =>  (
    <Fragment key={k}>  
      <div className="d-flex pointer">
        <div>
          <img src={getProfileImg(n)} className="rounded-circle bg-lgrey-2" width="50"/>
        </div>
        <div className="m-l-10">  
          <h5 className="d-title">{n.data.name}</h5>
          <p className="d-description">{n.data.details}</p>
          <p className="d-description">
            <span className="lred">{CommonService.formatUpdateDate(n.updated_at)}</span> 
          </p>
        </div>
      </div>
      <div className="divider"/>
    </Fragment>
  ))

  const RecentCounselingsView = ({}) => recentCounselings.map((n, k) =>  (
    <Fragment key={k}>  
      <div className="d-flex pointer">
        <div>
          <img src={getProfileImg(n)} className="rounded-circle bg-lgrey-2" width="50"/>
        </div>
        <div className="m-l-10">  
          <h5 className="d-title">{n.data_source?.stage?.label}</h5>
          <p className="d-description" dangerouslySetInnerHTML={{ __html: n.data.details }}></p>
          <p className="d-description">
            <span className="lred">{CommonService.formatUpdateDate(n.updated_at)}</span>
          </p>
        </div>
      </div>
      <div className="divider"/>
    </Fragment>
  ))

  return (
    <Fragment>
      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home scroll-x">
            <Link to="/list/training_programs" className="head-boder">
              <h3><i className="fas fa-comments m-r-5 font-24 color-highlight"/> 
                Most Recent Training Program entries
              </h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <RecentPlannersView/>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home scroll-x">
            <Link to="/list/assessments/" className="head-boder">
              <h3>
                <i className="far fa-calendar-alt m-r-5 font-24 o-red"/>  
                Most Recent Training Quiz entries
              </h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <RecentCounselingsView/>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home scroll-x">
            <Link to="/list/training_programs" className="head-boder">
              <h3><i className="fas fa-home m-r-5 font-24 green"/> 
                Pinned Training Program entries
              </h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <RecentPlannersView/>
          </div>
        </div>
      </div>

      <div className="col-xs-12 col-md-6">
        <div className="card card-style">
          <div className="content ht-home scroll-x">
            <Link to="/list/assessments/" className="head-boder">
              <h3><i className="fas fa-globe m-r-5 font-24 lightcoral"/> 
                Pinned Quiz entries
              </h3>
            </Link>
            <p className="m-b-10">
              Tap to view more.
            </p>
            <RecentCounselingsView/>
          </div>
        </div>
      </div>
      
    </Fragment>
  )
}

export default ContentManager;