import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import DynamicForm from "../../../../FormBuilder/DynamicForm";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import ActivityService from "../ActivityService";
import querystringify from "querystringify";
import FormContainer from '../../../../FormBuilder/FormContainer';
import GeoLocation from "../../../../Common/GeoLocation"

let formMode = null;
let activity = {}
let data = {}
let formFn = {}

function UploadForm(props) {
  const { state: authState, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});


  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ActivityService.getById({id:props.item}).then((res) => {
      if (res.status == 200) {
        activity = {...res.data.activity, ...activity.geo_data}
        data = activity.data || {}
        //params.form_template_id = activity.activity_form_id;
 
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function (res) {
      activity = null;
    })
  }

  useEffect(() => {
    activity = {}
    data = {}
    formFn = {
      form_type: "health.activity",
    }
    formMode = "create-form-submissions";
    data = {}
    if (props.item) {
      get();
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, []);

  const create = (activity) => {
    activity.organization_id = authState.user.current_organization_id
    activity.created_by = authState.user.id;
    activity.updated_by = authState.user.id;
    ActivityService.create(activity).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const update = (activity) => {
    activity.updated_by = authState.user.id;
    ActivityService.update(activity).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = () => {
   
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode}
          formId={FormTemplateId.UploadMeasureGrowthMCJForm}
          form={activity}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
        <GeoLocation geoData={activity}/>
      </FormContainer>
    )
  } else {
    return null;
  }
}

export default UploadForm;