import React, {useContext} from "react"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../services/CommonService"
import { CTTooltip } from "../../../Common/CueTooltip"
import {ImageList} from "../../../Common/Image"
import { ChatGrayIcon, CheckListGrayIcon, HamburgerMenuIcon, MaximizeIcon, LinkFontAwesomeIcon } from "../../../Common/ImageIcons"

const Medical = (props) => {

  let {facility, permission, dataSource:ds} = props
  facility.updated_at = Cs.formatUpdateDate(facility.updated_at)
  const geo_data = facility.geo_data
  let data = facility.data
  data.photos = data.photos || []

  if (!data) return null;

  const Header = () => (
    <div className="card-title card-title-gray flex p-b-15 p20 coldir">
      <h4 className="fw700 f18 card-heading flex coldir text-left">
        {data.name || facility.phc_name}
      </h4>
      <div className="f14 m-t-10 fw500"> &nbsp;-&nbsp; {facility.updated_at}</div>
    </div>
  )

  const Footer = () => {
    return(
      <div className="card-footer flex justspacebetween p20 aligncenter">
        <ul className="flex aligncenter">
          <li className="m-r-25" data-tip="Chat">
            <ChatGrayIcon onSelect={(e) => props.addNotes(e, facility)}/>
          </li>
          <li className="m-r-25" data-tip="Checklist">
            <CheckListGrayIcon onSelect={(e) => props.addChecklist(e, facility)}/>
          </li>
          {permission.write &&
            <li className="m-r-25">
              <LinkFontAwesomeIcon css="fas fa-users white" count={facility.members_count} 
              link={`/${data.name}/facility/members/${props.organizationId}/${facility.id}`}
              tooltip="View Members"/>
            </li>
          }
          <li className="m-r-25">
            {data.latitude && data.longitude &&
              <LinkFontAwesomeIcon css="fas fa-location-dot white" 
              tooltip="Location"
              onSelect={()=>props.openMapModal(facility)}/>
            }
          </li>
          <li className="m-r-25">
            <LinkFontAwesomeIcon css="far fa-building white" tooltip="Sub Facilities"
            link={`/health/sub_facilities/${props.organizationId}/${facility.id}`}/>
          </li>
        </ul>
        <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, facility)}/>
      </div>
    )
  }

  const Card = () => {
    return (
      <div className="bg-white border-rounded-10 card-shadow m-b-15">
        <Header />
        <div className="card-content p20">
          <div className="b-tab-table active scroll-auto ht-300">
            <div className="border-dashed-sm p-b-15 m-b-15">
              <h4 className="fw500 m-b-5">Taluka</h4>
              <p>{data.taluka}</p>
            </div>
            <div className="border-dashed-sm p-b-15 m-b-15">
              <h4 className="fw500 m-b-5">District</h4>
              <p>{data.district}</p>
            </div>
            <div className="border-dashed-sm p-b-15 m-b-15">
              <h4 className="fw500 m-b-5">Geo Location</h4>
              <p>{data?.latitude}</p>
              <p>{data?.longitude}</p>
            </div>
            <div className="border-dashed-sm p-b-15 m-b-15">
              <h4 className="fw500 m-b-5">Remarks</h4>
              <p>{data.remarks}</p>
            </div>   
            {data.photos.length > 0 &&
              <div className="border-dashed-sm p-b-15 m-b-15">
                <h4 className="fw500 m-b-5">Photos</h4>
                <ImageList list={data.photos}/>
              </div>   
            }
          </div>
        </div>
        <Footer/>
      </div>
    )
  }

  return <Card />;
}

export default Medical;