import React, {Fragment, useState, useEffect, useContext} from "react"
import {AuthContext} from "../../../../contexts/Authentication/AuthStateProvider"
import GmapChart from "../../../Charts/GmapChart"
import HealthCategoryService from "../../HealthCategoryService"
import ApexChart from "../../../Charts/ApexChart"
import {DateTimePicker, OptionsObjList} from "../../../Common/FormInput"
import PreLoadSpinner from "../../../PreLoadSpinner"
import LeaderShipDashboard from "../../Dashboard/LeaderShipDashboard"
import useStyle from "../../../../hooks/useStyle"
import { Table, THead, TH, TR, TD, TBody } from "../../../Common/TableView"
import {GraphMetricsList, PresetDateRange, MaternalPregnancyFilter} from "../../../../constants/DataSourceList"
import {TDInputMultiSelect, CheckBox} from "../../../Common/FormInput"
import {Filters} from "./Filters"
import FilterSlider from "../../../Common/FilterSlider"
import useModal from '../../../../hooks/useModal'
import { ChatGrayIcon, CheckListGrayIcon, LinkFontAwesomeIcon } from "../../../Common/ImageIcons"
import CommentsModal from "../../../Modals/CommentsModal"
import ChecklistsModal from "../../../Modals/ChecklistsModal"

let requestParams = null
let selectedChart = null
const Charts = ({isFilterBtnClick, activeTab}) => {

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [filter, setFilter] = useState({data_filter_types:['zscore_statics']})
  const [charts, setCharts] = useState([])
  const [reportType, setReportType] = useState('kpis')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)

  const {isOpen: isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const {isOpen: isChecklistOpen, toggleModal: toggleChecklistModal } = useModal()
  const {isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal()

  useEffect(() => {
    toggleFilterModal()
  }, [isFilterBtnClick])

  useEffect(()=>{
    requestParams = {
      filter_type:reportType,
      data_filter_types:filter.data_filter_types,
      start_date:filter.start_date,
      end_date:filter.end_date,
      data_range:filter.data_range,
      district:currentUser.current_organization?.data?.district,
      talukas:filter.talukas,  
      villages:filter.villages,  
      medical_facility_ids:filter.medical_facility_ids 
    }
  	getReport()
  }, [filter, reportType])

  const getReport = ()=>{
  	setLoading(true)
  	HealthCategoryService.getInfantInsights(requestParams).then((res)=>{
      setData(res.data.reports)
      setCharts(MaternalPregnancyFilter.filter(i => filter.data_filter_types.indexOf(i.sid) > -1))
	    setLoading(false)			
	  })
  }

  const metricsView = GraphMetricsList.map((m, i)=>
  	<div key={i} className="font-15 p-5" onClick={e=>{setLoading(true);setReportType(m.sid)}}>
  	  <i className={`fas ${m.sid == reportType?'fa-check-circle':'fa-circle'} m-r-5 highlight`}/>
  	  {m.label} 
  	</div>
  )
  
  const openNotes = (type, callback) =>{
    selectedChart = {
      id:currentUser.current_organization_id,
      type:type
    }
    
    if(callback === 'comment'){
      toggleCommentModal()
    }else if(callback === 'checklist'){
      toggleChecklistModal()
    }
  }

  return(
    <>
      <div className="app-content-section">
        <div className="row">
          <div className="bg-white p20 border-rounded-10 m-b-30">
            <div className="row">
              <div className="col-md-12">
                <div className="card-title-gray flex justspacebetween p20">
                  <h4 className="fw700 f18 text-left">
                    Geographic Analysis
                  </h4>
                </div>
                <div className="card-body p20 clearfix">
                  {loading ? 
                    <div className="spinner"/>
                    :
                    <>
                      {charts.map((c, i) => 
                        <Card c={c} data={data} key={i} index={i} openNotes={openNotes}/>
                      )}  
                    </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
      {/*{(isFilterOpen && activeTab === 'kpis') && 
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={toggleFilterModal}
          chartFilter={filter}
          setChartFilter={setFilter}
          onFilterSubmit={getReport}
          reportType={reportType}/>
      }*/}
      {isChecklistOpen && 
        <ChecklistsModal isOpen={isChecklistOpen} 
        toggleModal={toggleChecklistModal} 
        item={selectedChart} 
        type={selectedChart.type}/>
      }
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} 
        item={selectedChart} 
        type={selectedChart.type}/>
      }
    </>
  )
 
}

const Card = ({c, data, index, openNotes}) =>{
  const legend = {show:true, position:'right', width:'1%'}
  
  return(
    <div className="col-md-6">
      {
        c.chart === 'table'?
          <WhoMedianTable zscoreStatics={data[c.data_key]}/>
          :
          <div className="br-grey wk-center bg-white w-full ht-500">
            <ApexChart id={`chart_${index}`} chartType={c.chart} 
              data={data[c.data_key][c.sid]} legend={legend} 
              chartWidth={c.chartWidth} title={c.label} 
            />
            <div className="card-footer flex justspacebetween p20 aligncenter">
              <ul className="flex aligncenter">
                <li className="m-r-25" data-tip="Chat">
                  <ChatGrayIcon onSelect={()=>openNotes(c.sid+'_chart', 'comment')}/>
                </li>
                <li className="m-r-25" data-tip="Checklist">
                  <CheckListGrayIcon onSelect={()=>openNotes(c.sid+'_chart', 'checklist')}/>
                </li>
              </ul>
            </div>
          </div>
      }
    </div>
  )
}

const NotificationChart = () =>{
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState()

  useEffect(()=>{
    HealthCategoryService.getNotificationReports().then(({status, data})=>{
      if(status == 200){
        setData(data)
        setLoading(false)
      }
    })
  }, [])

  return(
    <div className="page-content row p-5">
      {loading ? <div className="spinner"/>:
        <>
          <div className="col-md-6 col-sm-12 m-b-5 p-10">
            <ApexChart id="chat_1" reportType="bar" title="Planner" data={data.planner} 
            className="br-grey ht-400 wk-center bg-white w-full" />
          </div>
           
          <div className="col-md-6 col-sm-12 m-b-5 p-10"> 
            <ApexChart id="chat_4" reportType="pie" title="Counseling" data={data.counseling} 
            className="br-grey ht-400 wk-center bg-white w-full"/>
          </div>
        </>
      }
    </div>
  )    
}

const WhoMedianTable = ({zscoreStatics = {}}) =>{
  let [totalValue, setTotalValue] = useState(0)

  useEffect(()=>{
    for (const k in zscoreStatics) {
      totalValue += parseInt(zscoreStatics[k])
    }
    setTotalValue(totalValue)
  }, [])

  const calPercentage = (partialValue) =>{
    return Math.round((100 * partialValue) / totalValue)
  }

  return (
    <div className="br-grey ht-500 bg-white">
      <div className="p-5 text-center font-16 br-grey fw700">
        Severity and prevalence of malnutrition in area covered
      </div>
      <div className="table-responsive">
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH className='p-2 bold-400 text-center miw-95'>
                
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                SUW 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                MUW 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                SS 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                MS 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                SAM 
              </TH>
              <TH className='p-2 bold-400 text-center miw-95'>
                MAM 
              </TH>
            </TR>
          </THead>
          <TBody>
            <TR>
              <TD className="text-center">Number of cases</TD>
              <TD className="text-center">{zscoreStatics.suw}</TD>
              <TD className="text-center">{zscoreStatics.muw}</TD>
              <TD className="text-center">{zscoreStatics.ss}</TD>
              <TD className="text-center">{zscoreStatics.ms}</TD>
              <TD className="text-center">{zscoreStatics.sam}</TD>
              <TD className="text-center">{zscoreStatics.mam}</TD>
            </TR>

            <TR>
              <TD className="text-center">% of cases</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.suw)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.muw)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.ss)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.ms)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.sam)}</TD>
              <TD className="text-center">{calPercentage(zscoreStatics.mam)}</TD>
            </TR>
          </TBody>
        </Table>
      </div>
    </div>
  )
}

export default Charts;

/*
<div id="table1">
          <div className="bg-white p20 border-rounded-10 m-b-30">
            <div className="row">
              <div className="col-md-6">
                <div className="card-title card-title-gray flex aligncenter p-b-15 p20 w-full">
                  <h4 className="fw700 f18 text-left">
                    Select Analysis
                  </h4>
                </div>
                <div className="card-body flex aligncenter justifycenter coldir p20">
                  <div className="col-xs-12">
                      
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card-title-gray justspacebetween p20">
                  <h4 className="fw700 f18 text-left">
                    Data Range
                  </h4>
                </div>
                <div className="card-body p20 clearfix">
                  <div className="col-xs-4">
                    <div className="m-r-5">
                      <DateTimePicker name="start_date" onChange={onFilterChanged} 
                        dateFormat="YYYY-MM-DD" defaultValue={filter.start_date}
                        className="form-input form-input-gray form-input-full"
                        placeholder="Start Date"/>
                    </div>
                  </div>
                  <div className="col-xs-4">
                    <div className="m-r-5">
                        <DateTimePicker name="end_date" onChange={onFilterChanged} 
                          dateFormat="YYYY-MM-DD" defaultValue={filter.end_date}
                          className="form-input form-input-gray form-input-full"
                          placeholder="End Date"/>
                      </div>
                    </div>
                    <div className="col-xs-4">
                      <div className="m-r-5">
                        <select onChange={e => {setLoading(true);onFilterChanged('data_range', e.target.value);}} 
                          defaultValue={reportType}
                          className="form-select-dropdown w-full form-select-w-100-xs m-b-10-xs">
                          <option value="all">Preset date filters</option>
                          <OptionsObjList list={PresetDateRange} id_key="sid" label_key="label"/>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 m-t-5">
                  <div className="card-title-gray aligncenter p20 w-full">
                    <h4 className="fw700 f18 text-left">
                      Chart Filter
                    </h4>
                  </div>
                  <div className="card-body p20">
                    <div className="clearfix">
                      <TDInputMultiSelect id="data_filter" label="" 
                        handleChange={onDataFilterChanged} idx={0} 
                        options={MaternalPregnancyFilter} screen={{}}
                        column={{client_id:'data_filter_types'}} 
                        rowData={filter} valueKey="sid"/>  
                    </div>
                  </div>
                </div>
              </div>        
            </div>
*/