import React, {Fragment, useState, useContext, useEffect} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../../Common/MenuItem"
import useStyle from '../../../hooks/useStyle'
import useSort from '../../../hooks/useSorting'
import SortDirection from '../../Common/SortDirection'
import Cs from "../../../services/CommonService"
import ContactService from "../ContactService"
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { LinkDeleteIcon } from "../../Common/ImageIcons"

let requestParams ={}
const ContactListTable = (props) => {
  
  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])

  useEffect(() => {
    requestParams = {
      
    } 
    getAll()
  }, [])

  const getAll = () =>{
    ContactService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setList(res.data.contactus)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const deleteContact = (contact) =>{
    let req = {
      id: contact.id,
    }
    ContactService.delete(req.id).then((res) => {
      if (res.status == 204) {
        setList(list.filter(i => i.id !== contact.id))
      }
    })
  }

  const tableData = list.map((o, k) => {
    let ds = o.data_source || {}
    let data = o.data || {}

    return(
      <TR key={k}>
        <TD className="text-gray">
          {k+1}
        </TD>
        <TD className="fw500">
          {data.first_name + " " + data.last_name}
        </TD>
        <TD>
          {Cs.formatUpdateDate(o.created_at)}
        </TD>
        <TD>
          {data.project_interest}
        </TD>
        <TD>
          {data.whats_app}
        </TD>
        <TD>
          {data.email}
        </TD>
        <TD>
          <div className="flex aligncenter">
            <LinkDeleteIcon id="delete_contact" tooltip="Delete contact" onSelect={()=>deleteContact(o)}>
            </LinkDeleteIcon>
          </div>
        </TD>
      </TR>
    )
  })

  return (
    <Fragment>
      <div id="ct">
      <div className="app_content">
        <div className="app-header flex justspacebetween m-t-15">
          <div className="flex coldir">
            <div className="app-title m-r-20">Contact Requests</div>
            <div className="font-15">
              List of Contact Requests - {list.length}
            </div>
          </div>
        </div>
        
        <div id="ct" className="app-content-section m-t-30 table-fix-head">
          <Table className="shadow-small" id="patient_lists">
            <THead>
              <TR>
                <TH onClick={e=>sort('record_id')} >
                  ID 
                  <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('data.name')} >
                  Name 
                  <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('created_at')} >
                  Date
                  <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('data.project_interest')} >
                  Project Interest
                  <SortDirection sort_column={requestParams.project_interest} column="project_interest" reverse={requestParams.reverse}/>
                </TH>
                <TH >
                  Whats App Number
                  <SortDirection  column="whats_app" />
                </TH>
                <TH onClick={e=>sort('data.project_interest')} >
                  Email
                  <SortDirection column="email" />
                </TH>
                <TH>
                  Delete
                </TH>
              </TR>
            </THead>
            <TBody>
              {tableData}
            </TBody>
          </Table>
        </div>
      </div>
      </div>
    </Fragment>
  )

}

export default ContactListTable;