import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../PreLoadSpinner"
import DynamicForm from "../../../FormBuilder/DynamicForm"
import FormTemplateId from "../../../../constants/FormTemplateList"
import PatientAdditionalInfoService from "../PatientAdditionalInfoService"
import querystringify from "querystringify"
import FormContainer from '../../../../components/FormBuilder/FormContainer'
import GeoLocation from "../../../../components/Common/GeoLocation"

let formMode = null
let additionalInfo = {}
let data = {}
let formFn = {}

function PatientAdditionalInfoForm(props) {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)

  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let forigenObject =  props.location.state
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const get = () => {
    PatientAdditionalInfoService.get(params.id).then((res) => {
      if (res.status == 200) {
        additionalInfo = {...res.data.additional_info, ...additionalInfo.geo_data}
        data = additionalInfo.data || {}
 
        /*TODO Render Delay Issue*/
        setFormRendered(true)
      }
    }).catch(function (res) {
      additionalInfo = null
    })
  }

  useEffect(() => {
    additionalInfo = {}
    data = {}
    formFn = {
      form_type: "health.additional_info",
      journey_profile_id: params.journey_profile_id,
    }
    formMode = "create-form-submissions"
    if(forigenObject)data = Object.assign(forigenObject, data); 
    if (params.id) {
      get();
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, []);

  const create = (additionalInfo) => {
    additionalInfo.organization_id = currentUser.current_organization_id
    additionalInfo.created_by = currentUser.id
    additionalInfo.updated_by = currentUser.id
    additionalInfo.medical_facility_id = currentUser.current_medical_facility_id
    additionalInfo.journey_profile_id = params.journey_profile_id
    additionalInfo.form_id = parseInt(queryParam.form_id)
    additionalInfo.form_type = queryParam.label
    PatientAdditionalInfoService.create(additionalInfo).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect(res.data.additional_info)
      }
    }).catch((res) => {

    })
  }

  const update = (additionalInfo) => {
    additionalInfo.updated_by = currentUser.id
    additionalInfo.medical_facility_id = currentUser.current_medical_facility_id

    PatientAdditionalInfoService.update(additionalInfo).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect(additionalInfo)
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = (additionalInfo) => {
    if(props.isPopupMode){
      props.onFormSubmit(additionalInfo)
    }
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode}
          formId={queryParam.form_id}
          form={additionalInfo}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
        <GeoLocation geoData={additionalInfo}/>
      </FormContainer>
    )
  } else {
    return null
  }
}

export default PatientAdditionalInfoForm;