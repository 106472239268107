import React, { Fragment, useState } from "react";
import useStyle from "../../../hooks/useStyle";

const team = [
  {
    rank: 1,
    name: "Ahmedabad",
    handle: "Ahmedabad",
    img: "https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png",
    Ranking: 36,
    sent: 31,
  },
  {
    rank: 2,
    name: "Amreli",
    handle: "Amreli",
    img: "https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png",
    Ranking: 31,
    sent: 21,
  },
  {
    rank: 3,
    name: "Jamnagar",
    handle: "Jamnagar",
    img: "https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png",
    Ranking: 24,
    sent: 7,
  },
  {
    rank: 4,
    name: "Surat",
    handle: "Surat",
    img: "https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png",
    Ranking: 22,
    sent: 4,
  },
  {
    rank: 5,
    name: "Vadodara",
    handle: "Vadodara",
    img: "https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png",
    Ranking: 18,
    sent: 16,
  },
  {
    rank: 6,
    name: "Rajkot",
    handle: "Rajkot",
    img: "https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png",
    Ranking: 16,
    sent: 6,
  },
  {
    rank: 7,
    name: "Porbandar",
    handle: "Porbandar",
    img: "https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png",
    Ranking: 10,
    sent: 21,
  },
  {
    rank: 8,
    name: "Kutch",
    handle: "kutch",
    img: "https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png",
    Ranking: 7,
    sent: 46,
  },
  {
    rank: 9,
    name: "Gandhinagar",
    handle: "Gandhinagar",
    img: "https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png",
    Ranking: 4,
    sent: 2,
  },
  {
    rank: 10,
    name: "Botad",
    handle: "Botad",
    img: "https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png",
    Ranking: 1,
    sent: 24,
  },
];

const LeaderShipDashboard = (props) => {
  useStyle("sample");
  const tlist =
    team &&
    team.map((a, i) => (
      <div className="c-list__grid">
        <div className="c-flag c-place u-bg--transparent u-text--dark u-bg--yellow">
          {a.rank}
        </div>
        <div className="c-media">
          <div className="c-media__content">
            <div className="c-media__title">{a.name}</div>
          </div>
        </div>
        <div className="u-text--right c-Ranking u-text--yellow">
          <div className="u-mt--8">
            <strong>{a.sent}</strong> 👍
          </div>
        </div>
      </div>
    ));

  return (
    <div className="l-wrapper">
      <div className="l-grid">
        <div className="l-grid__item l-grid__item--sticky">
          <div className="c-card u-bg--light-gradient u-text--dark">
            <div className="c-card__body">
              <div className="u-display--flex u-justify--space-between">
                <div className="u-text--left">
                  <div className="u-text--small">My Rank</div>
                  <h2>3rd Place</h2>
                </div>
                <div className="u-text--right">
                  <div className="u-text--small">My Score</div>
                  <h2>24</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="c-card">
            <div className="c-card__body">
              <div className="u-text--center" id="winner">
                <div className="u-text-small u-text--medium u-mb--16">
                  Top Ranking Last Week
                </div>
                <img
                  className="c-avatar c-avatar--lg"
                  src="https://toppng.com/uploads/preview/roger-berry-avatar-placeholder-11562991561rbrfzlng6h.png"
                />
                <h3 className="u-mt--16">Ahmedabad</h3>
              </div>
            </div>
          </div>
          <div className="c-card">
            <div className="c-card__body">
              <div className="c-list__grid">
                
                <div className="c-media">
                  <div className="c-media__content">
                    <div className="c-media__title">Birth</div>
                  </div>
                </div>
                <div className="u-text--right c-Ranking u-text--yellow">
                  <div className="u-mt--8">
                    <strong>10</strong>
                  </div>
                </div>
              </div>
              <div className="c-list__grid">
               
                <div className="c-media">
                  <div className="c-media__content">
                    <div className="c-media__title">Latching</div>
                  </div>
                </div>
                <div className="u-text--right c-Ranking u-text--yellow">
                  <div className="u-mt--8">
                    <strong>5</strong>
                  </div>
                </div>
              </div>
              <div className="c-list__grid">
                
                <div className="c-media">
                  <div className="c-media__content">
                    <div className="c-media__title">CF</div>
                  </div>
                </div>
                <div className="u-text--right c-Ranking u-text--yellow">
                  <div className="u-mt--8">
                    <strong>7</strong>
                  </div>
                </div>
              </div>
              <div className="c-list__grid">
                
                <div className="c-media">
                  <div className="c-media__content">
                    <div className="c-media__title">BF</div>
                  </div>
                </div>
                <div className="u-text--right c-Ranking u-text--yellow">
                  <div className="u-mt--8">
                    <strong>12</strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="l-grid__item">
          <div className="c-card">
            <div className="c-card__header">
              <h3>Received Ranking</h3>
              <select className="c-select">
                <option selected="selected">
                  Sunday, Feb. 23 - Sunday, Feb. 30
                </option>
              </select>
            </div>
            <div className="c-card__body">
              <ul className="c-list" id="list">
                <li className="c-list__item">
                  <div className="c-list__grid">
                    <div className="u-text--left u-text--small u-text--medium">
                      Rank
                    </div>
                    <div className="u-text--left u-text--small u-text--medium">
                      District
                    </div>
                    <div className="u-text--right u-text--small u-text--medium">
                      # of Ranking
                    </div>
                  </div>
                  {tlist}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LeaderShipDashboard;
