import React, {useEffect, useState, Fragment, useContext, useMemo} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import { CacheContext } from "../../../contexts/Cache/CacheStateProvider"
import PreSchedulesService from "../PreScheduleService"
import Pagination from '../../Pagination'
import ReactTooltip from "react-tooltip"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import {Link, useHistory} from "react-router-dom"
import useModal from "../../../hooks/useModal"
import useStyle from '../../../hooks/useStyle'
import env from '../../../env'
import SortDirection from '../../Common/SortDirection'
import useSort from '../../../hooks/useSorting'
import Cs from "../../../services/CommonService"
//import FilterModal from "../../../components/Organizations/List/OrganizationsFilterModal";
import GenericModal from "../../Modals/GenericModal"
import PreScheduleSteps from "./PreScheduleSteps"
import NotifiableRolesPopup from '../../NotifiableRoles/NotifiableRolesPopup'
import LocaleList from "../../Locale/LocaleMenu"
import FilterSlider from "../../Common/FilterSlider"
import {CheckBox} from "../../Common/FormInput"
import {MenuItem, NavIcon} from '../../Common/MenuItem'
import SideMenuBar from "../../Common/SideMenuBar"
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../Common/ImageIcons"
import { AddBtn, FontAwesomeLink } from "../../Common/Button"
import DynamicCard from "../../Common/DynamicCard"
import FormTemplateId from '../../../constants/FormTemplateList'
import DataLogList from '../../DataLogs/DataLogList'
import DataSourceMenu from "../../Modals/DataSourceMenu"
import {PlannerVideoPlayer} from '../../Common/VideoPlayer'
import OrgDataMapperTable from '../../OrgDataMapper/OrgDataMapperTable'
import {usePageTitle} from '../../../hooks/pageHead'
import {FilterBtn} from './Filters'
import CheckAccess from '../../HealthJourney/Roles/CheckAccess'

let requestParams = {}
let selectedPreSchedule = {}
let dataSource = {}
let selectedPlanner = {}
const PreScheduleEventList = (props) => {
  useStyle('/css/tile-components.css', true)
  usePageTitle('PreSchedule Events')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const {cacheState, cacheDispatch} = useContext(CacheContext)
  const history = useHistory()
  const {isPopupMode} = props
  const permission = useMemo(() => 
    CheckAccess.getMenuPermission(currentUser, 'calendar'), [currentUser.id]
  )

  const [preSchedulesList, setPreSchedulesList] = useState([])
  const [currentpage,setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [filter, setFilter] = useState(cacheState?.preScheduleFilter || {})
  const [formFields, setFormFields] = useState({})

  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen:isEventScheduleOpen, toggleModal:toggleEventScheduleModal } = useModal()
  const { isOpen:isNotifiableRoleOpen, toggleModal:toggleNotifiableRoleModal } = useModal()
  const { isOpen:showLocaleModal, toggleModal: toggleLocaleModal} = useModal()
  const { isOpen:isScheduleCardOpen, toggleModal: toggleScheduleCardModal} = useModal()
  const { isOpen:isDataLogOpen, toggleModal:toggleDataLogModal } = useModal()
  const { isOpen:isPreScheduleMenuOpen, toggleModal: togglePreScheduleMenu } = useModal()
  const { isOpen: isVideoPlayerOpen, toggleModal: toggleVideoPlayer } = useModal()
  const { isOpen: isOrgDataMapperOpen, toggleModal: toggleOrgDataMapper } = useModal()

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      filter:filter,
      form_fields_params:{ids:[FormTemplateId.PreScheduleEventForm]},
      search: props.searchString,
      data_source_params: {
        data_source_id: [3613, 3887, 3644]
      }
    }
    getpreSchedulesList()

    return () =>{
      cacheDispatch({
        type: "UPDATE",
        payload: {
          'preScheduleFilter':filter
        }
      })
      requestParams = {}
      selectedPreSchedule = {}
      selectedPlanner = {}
    }
  }, [props.searchString, currentUser.id, currentpage, filter])

  const getpreSchedulesList = () => { 
    if(permission.read){
      PreSchedulesService.getAll(requestParams).then((res)=>{
       if(res.status == 200){
          dataSource = res.data.data_source_list
          setPreSchedulesList(res.data.pre_schedules)
          setFormFields(res.data.form_fields || {})
        }
      })
    }
  }

  const {sort} = useSort(requestParams, getpreSchedulesList)

  const addDelete = (e, item) => {
    e.stopPropagation()
    if(permission.write){
      selectedPreSchedule = item
      toggleDeleteModal()
    }
  }

  const addEventDays =(e, item) =>{
    e.stopPropagation()
    selectedPreSchedule = item
    toggleEventScheduleModal()
  }

  const deletePreSchedule = (e) => {
    e.stopPropagation()
    PreSchedulesService.delete(selectedPreSchedule).then((res) => {
      if (res.status == 204) {
        setPreSchedulesList(preSchedulesList.filter(i => i.id !== selectedPreSchedule.id))
      }
    })
    toggleDeleteModal(e)
  }

  const addNotifiableRole = (e, item) => {
    e.stopPropagation()
    if(permission.write){
      selectedPreSchedule = item
      toggleNotifiableRoleModal()
    }
  }

  const translateAndClone = (e, item) => {
    e.stopPropagation()
    if(permission.write){
      selectedPreSchedule = Cs.deepCopy(item) //Deep copy
      selectedPreSchedule.clone_id = item.id
      toggleLocaleModal()
    }
  } 

  const onTranslateComplete = (translatedData) => {
    toggleLocaleModal()
    PreSchedulesService.clone(selectedPreSchedule).then((res) => {
      if (res.status == 201) {
        setPreSchedulesList([res.data.pre_schedule, ...preSchedulesList])
      }
    })
  }

  const openPreScheduleCard = (item) => {
    selectedPreSchedule = item
    toggleScheduleCardModal()
  }

  const openLogs = (e, item) =>{
    e.stopPropagation()
    if(permission.write){
      selectedPreSchedule = item
      toggleDataLogModal()
    }
  }

  const routePreScheduleForm = (datasource) =>{
    history.push(`/schedule/event/create/${datasource.sid}/${datasource.calendar_child_form}`)
  }

  const openVideo = (item) =>{
    if(item.planner_fk_id){
      selectedPlanner = item
      toggleVideoPlayer()
    }
  }

  const openOrgDataMapperTable = (e, item) =>{
    e.stopPropagation()
    if(permission.write){
      selectedPreSchedule = item
      toggleOrgDataMapper()
    }
  }

  const SchedulesTable = () =>(
    <Table className="shadow-small">
      <THead>
        <TR>
          <TH onClick={e=>sort('record_id')} >Id 
            <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
          </TH>
          <TH onClick={e=>sort('data.title')} >Title 
            <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
          </TH>
          <TH onClick={e=>sort('updated_at')} >Date 
            <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
          </TH>
          <TH onClick={e=>sort('data.journey_type')} >Journey Type
            <SortDirection sort_column={requestParams.sort_column} column="data.journey_type" reverse={requestParams.reverse}/>
          </TH>
          <TH onClick={e=>sort('data.reference_event')} >
            <SortDirection sort_column={requestParams.sort_column} column="data.reference_event" reverse={requestParams.reverse}/>
              Reference Event
          </TH>
          <TH onClick={e=>sort('updated_by')} >
            <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
              By
          </TH>
          {permission.write &&
            <>
              <TH>
                Actions
              </TH>
              <TH onClick={e=>sort('sequence_nos')}>
                <SortDirection sort_column={requestParams.sort_column} column="sequence_nos" reverse={requestParams.reverse}/>
                Days
              </TH>
            </>
          }
        </TR>
      </THead>
      <tbody>
        {schedulesTableData}
      </tbody>
    </Table>
  )
  
  const schedulesTableData = preSchedulesList && preSchedulesList.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <TR key={k} onSelect={()=>openPreScheduleCard(a)}>
        <TD className="text-gray" tooltip="Id">{a.record_id}</TD>

        <TD className="fw500">
          <span dangerouslySetInnerHTML={{ __html: data.title }}/>
        </TD>
        
        <TD>
          {Cs.formatUpdateDate(a.updated_at, 'MMMM DD YYYY')}
        </TD>
       
        <TD>
          {
            dataSource.journey_ipa_types?.options
            ?.find((o => o.calendar_child_form === a.form_id))
            ?.label
          }
        </TD>

        <TD>
          {ds.ref_event?.label}
        </TD>
        
        <TD>
          {a.author?.name}
        </TD>

        {permission.write &&
          <>
            <TD>
              <div className="flex aligncenter">
                <LinkFontAwesomeIcon id="edit_calendar" tooltip="Set calendar details" 
                  link={`/schedule/edit/${a.id}`} css="fas fa-edit white">
                </LinkFontAwesomeIcon>

                <LinkFontAwesomeIcon id="logs" tooltip="Version Info" 
                  onSelect={(e)=>openLogs(e, a)} css="fas fa-history white">
                </LinkFontAwesomeIcon>

                <LinkFontAwesomeIcon id="alert_entry" tooltip="Add alert recipients" 
                  onSelect={(e)=>addNotifiableRole(e, a)} css="fas fa-envelope white">
                </LinkFontAwesomeIcon>

                <LinkFontAwesomeIcon id="select" tooltip="Add to Organization" 
                  onSelect={(e)=>openOrgDataMapperTable(e, a)} css="fas fa-check-circle white"
                  count={a.assigned_organization_count}>
                </LinkFontAwesomeIcon>

                {!a.clone_id && 
                  <LinkFontAwesomeIcon id="translate_entry" tooltip="Translate & Clone" 
                    onSelect={(e)=>translateAndClone(e, a)} css="fas fa-clone white">
                  </LinkFontAwesomeIcon>
                }

                <LinkDeleteIcon id="delete_entry" tooltip="Delete" 
                  onSelect={(e)=>addDelete(e, a)}>
                </LinkDeleteIcon>
              </div>
            </TD>
            
            <TD>
              <AddBtn extraClassName="btn-sm"
                id="add_new_journey" tooltip="Launch a new health journey" 
                onSelect={(e)=>addEventDays(e, a)}>
                Add
              </AddBtn>
              <div className="p-5">{a.sequence_nos?.sort()?.join(", ")}</div>
            </TD>
          </>
        }
      </TR>
    )
  })
  
  // if(isScheduleCardOpen){
  //   return (
  //     <GenericModal toggleModal={toggleScheduleCardModal} 
  //       isOpen={isScheduleCardOpen}
  //       component={DynamicCard}
  //       formFields={formFields[FormTemplateId.PreScheduleEventForm]}
  //       item={selectedPreSchedule}
  //       isPopupMode="true" 
  //     />
  //   )
  // }

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex aligncenter">
              <div className="app-title m-r-20">Case Visit Scheduling</div>
              <div className="font-16">{preSchedulesList.length} Items</div>
            </div>

            {!isPopupMode ?
              <div className="flex aligncenter">
                {permission.write &&
                  <>
                    <NavIcon id="add_new_btn" dataTip="Add new" 
                      className="bg-highlight round-btn m-r-10" 
                      onSelect={()=>togglePreScheduleMenu()} icon="fas fa-plus"/>

                    <FilterBtn filter={filter} setFilter={setFilter} 
                      dataSource={dataSource} requestParams={requestParams}
                      getpreSchedulesList={getpreSchedulesList}
                      currentUser={currentUser}
                    />
                  </>
                }
              </div>
              :null
            }
          </div>
        
          <div className="app-content-section m-t-30 table-responsive">
            <SchedulesTable/>
          </div>
        </div>
      </div>
      
      <ConfirmMenu isOpen={isOpen} 
        toggleModal={toggleDeleteModal} 
        success={deletePreSchedule}/>
      
      {permission.write && isEventScheduleOpen && 
        <GenericModal title="Select Days" 
          component={PreScheduleSteps} 
          toggleModal={toggleEventScheduleModal} 
          preSchedule={selectedPreSchedule}/>
      }
      
      {isNotifiableRoleOpen && 
        <GenericModal isOpen={isNotifiableRoleOpen} 
          toggleModal={toggleNotifiableRoleModal} 
          title="Roles" 
          component={NotifiableRolesPopup} 
          notifiableType="pre_schedule_event"
          notifiableId={selectedPreSchedule.id}/>
      }

      {showLocaleModal && 
        <GenericModal component={LocaleList} 
          title="Langauage" isOpen={showLocaleModal} 
          toggleModal={toggleLocaleModal} 
          onTranslateComplete={onTranslateComplete} 
          selectedItem={selectedPreSchedule}/>
      }

      {isScheduleCardOpen &&
        <GenericModal toggleModal={toggleScheduleCardModal} 
          isOpen={isScheduleCardOpen}
          component={DynamicCard}
          formFields={formFields[FormTemplateId.PreScheduleEventForm]}
          item={selectedPreSchedule}
          isPopupMode="true" 
          onDataItemSelect={openVideo}
        />
      }
      
      {isDataLogOpen && 
        <GenericModal component={DataLogList} title="Versions" widthCss="w-100p"
        isOpen={isDataLogOpen} toggleModal={toggleDataLogModal}
        logType="pre_schedules" logItem={selectedPreSchedule}
        formFields={formFields[selectedPreSchedule.form_id]}/>
      }

      {isPreScheduleMenuOpen && 
        <DataSourceMenu toggleModal={togglePreScheduleMenu} 
        dataSourceId={dataSource.journey_ipa_types?.id}
        datasourceList={dataSource.journey_ipa_types?.options} 
        onSelect={routePreScheduleForm}/>
      }

      {isVideoPlayerOpen && 
        <PlannerVideoPlayer 
          toggleModal={toggleVideoPlayer}
          plannerId={selectedPlanner.planner_fk_id} />
      }

      {permission.write &&isOrgDataMapperOpen &&
        <GenericModal component={OrgDataMapperTable} 
          title={selectedPreSchedule.data?.title}
          isOpen={isOrgDataMapperOpen} toggleModal={toggleOrgDataMapper}
          itemType="pre_schedule" itemId={selectedPreSchedule.id}/>
      }

      <SideMenuBar pageIds={['pre_schedule_events']}/>
    </Fragment>
  )

}

export default PreScheduleEventList;