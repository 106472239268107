import env from '../env';
import axios from 'axios';
import BaseService from './BaseService';
const url = `${env.health_backend}health/form_alerts`

class FormAlertService extends BaseService{
	get(req){
	    return new Promise((resolve, reject) => {
	      axios.get(`${url}`, {params: req}).then((res) => {
	        // handle success
	        resolve(res);
	      })
	    })
  	}
}

export default new FormAlertService('health_backend','health/form_alerts');