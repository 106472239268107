import React, { Fragment, useState, useEffect, useContext, useRef} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../../hooks/useStyle'
import Cs from '../../../services/CommonService'
import { useHistory } from "react-router-dom"
import OrganizationMemberService from "./OrganizationMemberService"
import {LeafletMap} from '../../Common/Leaflet'
import GenericModal from '../../Modals/GenericModal'
import { LinkFontAwesomeIcon } from "../../Common/ImageIcons"
import useModal from "../../../hooks/useModal"

const MemberGeoDataModal = ({organizationId, medicalFacilityId, memberUserId}) => {

  const { state: { isAuthenticated, user: currentUser }} = useContext(AuthContext)
  const [geoData, setGeoData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getGoeData()
  }, [])

  const getGoeData = () => {
    const req = {
      organization_id:organizationId,
      user_id:memberUserId
    }
    OrganizationMemberService.getGeoData(req).then(({status, data})=>{
      if(status === 200){
        setGeoData(data)
        setLoading(false)
      }
    })
  }

  const addCustomMarker = (map) =>{
    const markerArray = []
    for (const key in geoData) {
      const lits = geoData[key]
      let i = lits?.length || 0
      
      while(i--) {
        const g = lits[i]
        if(g.latitude && g.longitude){
          markerArray.push(window.L.marker([g.latitude, g.longitude]))
        }
      }  
    }
    if(markerArray.length > 0){
      let group = window.L.featureGroup(markerArray).addTo(map);
      map.fitBounds(group.getBounds());
    }
  }

  if(loading)
    return <div className="spinner"/>

  return(
    <LeafletMap 
      lat={21.7679} long={78.8718}
      addCustomMarker={addCustomMarker}
      zoomOffset={-1}
      title="Map"/>
  )
}

const GeoDataIcon = ({organizationId, medicalFacilityId, memberUserId}) =>{
  const { isOpen:isMapOpen, toggleModal:toggleMapModal } = useModal()

  return(
    <>
      <LinkFontAwesomeIcon onSelect={()=>toggleMapModal()} css="fas fa-location-dot white"/>

      {isMapOpen &&
        <GenericModal 
          component={MemberGeoDataModal} 
          isOpen={isMapOpen}
          toggleModal={toggleMapModal}
          organizationId={organizationId}
          medicalFacilityId={medicalFacilityId}
          memberUserId={memberUserId}/>
      }
    </>
  )
}

export {MemberGeoDataModal, GeoDataIcon};