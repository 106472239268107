import React, { Fragment, useState, useEffect, useContext} from "react";
import ReactDOM from "react-dom";
import { Link, useHistory } from "react-router-dom";
import useStyle from "../../../../../hooks/useStyle";
import Cs from "../../../../../services/CommonService";
import ActivityForm from '../Form/ActivityForm';
import useModal from "../../../../../hooks/useModal";
import GenericModal from "../../../../Modals/GenericModal";

let actFormProps = {}
const ActivityModalLinks = ({child, journeyProfile, schedule, scheduleId, autoOpenForm, onScheduledFormSubmit, onScheduledFormClose}) => {
  const history = useHistory()

  const { isOpen:isInfantActivityFormOpen, toggleModal:toggleInfantActivityModal } = useModal()

  useEffect(()=>{
    if(autoOpenForm){
      routeActivityForm({sid:schedule.scheduled_form_type, child_form:schedule.scheduled_form_id}, {visit_date:schedule.start})
    }
  }, [schedule])

  const routeActivityForm = (path, state) =>{
    actFormProps = Cs.routeInfantPath(path, {}, journeyProfile, history, null)
    if(child){
      const c = child
      c.data.baby_age = Cs.getAge(c.data.dob_baby_formatted_date)
      actFormProps.location.state = {
        'child_fk_id': c.record_id, 
        'dob_baby_formatted_date': c.data.dob_baby_formatted_date, 
        'id_mother': journeyProfile.data.cell_number, 
        'baby_age': c.data.baby_age
      }
    }

    if(path.sid === 'growth_measurement' && state){
      const d = Cs.formatUpdateDate(state.visit_date ,'YYYY-MM-DD')
      actFormProps.location.state.visit_date = d
      actFormProps.location.state.visit_date_formatted_date = d 
    }
    actFormProps.match.params.schedule_id = scheduleId
    actFormProps.component = ActivityForm
    toggleInfantActivityModal()
  }

  const onFormSubmit = (activity={}) =>{
    toggleInfantActivityModal()
    onScheduledFormSubmit && onScheduledFormSubmit(schedule)
  }

  const onFormClose = () =>{
    toggleInfantActivityModal()
    onScheduledFormClose && onScheduledFormClose(schedule)
  }

  return(
    <>
      {!autoOpenForm && journeyProfile &&
        <>
          {schedule.scheduled_form_type === 'growth_measurement'  &&
            <div className="badge white bg-highlight pointer m-r-5 m-b-5" 
              onClick={()=>routeActivityForm(
                {sid:'growth_measurement', child_form:schedule.scheduled_form_id}, 
                {visit_date:schedule.start})
              }>
              Measure Growth
            </div>
          }
          {schedule.scheduled_form_type === 'assess_bf' &&
            <div className="badge white bg-highlight pointer m-r-5 m-b-5" 
              onClick={()=>routeActivityForm({sid:'assess_bf', child_form:schedule.scheduled_form_id})}>
              Assess BF
            </div>
          }
          {schedule.scheduled_form_type === 'prenatal_care' &&
            <div className="badge white bg-highlight pointer m-r-5 m-b-5" 
              onClick={()=>routeActivityForm({sid:'prenatal_care', child_form:schedule.scheduled_form_id})}>
              Antenatal
            </div>
          }
        </>
      }

      {isInfantActivityFormOpen && 
        <GenericModal toggleModal={onFormClose} 
          isPopupMode="true" onFormSubmit={onFormSubmit} 
          closeConfirmPopup={true} {...actFormProps} />
      }
    </>
  )
}

export default ActivityModalLinks;