import React, {useState, Fragment} from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Pagination from '../../Pagination'
import useStyle from '../../../hooks/useStyle';
import CommonService from '../../../services/CommonService'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import useModal from "../../../hooks/useModal";



let requestParams = {}

const IncomingMails = (props) => {
  useStyle('card');
  useStyle('card_table');
  let params = props.match.params;

  const { state:{screen, user:currentUser}, dispatch } = React.useContext(AuthContext);
  const [mailList, setMailList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [activeIndex, setActiveIndex] = useState();
  const { isOpen:isDeleteModalOpen, toggleModal:toggleDeleteModal } = useModal();



  const toggleMenu =(i) =>{
    setActiveIndex(i==activeIndex?null:i)
  }


  React.useEffect(() => {
    requestParams = {
      'mailable_type': params.type,
      'mailable_id': params.id
    }

    getIncomingMails();
  }, [currentUser.id, currentpage]);

  let getIncomingMails = () => { 
    CommonService.getIncomingMails(requestParams).then((res)=>{
     if(res.status == 200){
       setMailList(res.data.incoming_mails)
       //requestParams.totalItems = res.data.meta.total;
      }
    })
  }

  const toggleDelete =(e,i) =>{
    setActiveIndex(i==activeIndex?null:i)
    toggleDeleteModal(e,i)
  }
  const deleteMail = (e,i) => {
    e.preventDefault();
    CommonService.deleteIncomingMails(activeIndex).then((res)=>{
      if(res.status==204){
        setMailList(mailList.filter(i => i.id !== activeIndex))
      }
    })
    toggleDeleteModal(e,i);
  }

  const List =  mailList && mailList.map((m, index) => {
    return(
    <div className="col-xs-12 m-t-5" key={index}>
      <div className="font-15 card-container bg-white shadow-small mail">
        <h4>{m.subject}</h4>
        <i className="fas fa-plus pull-right note-toggle" onClick={e=>toggleMenu(m.id)}></i>
        <div>{CommonService.formatUpdateDate(m.updated_at)}</div>
        <div>
        {activeIndex == m.id && <div dangerouslySetInnerHTML={{ __html: m.body_html_string }}></div> }
        </div>
        <div>Sender - {m.sender}</div>
        <div>Receiver - {m.receiver}</div>
        <i className="fas fa-trash pull-right note-delete " onClick={e=>toggleDelete(e,m.id)}></i>
      </div>
    </div>
  )})
  
  return (
    <React.Fragment>
      <div className="content top-10 clearfix">
        <div className="float-left font-16 font-500">Incoming Mails</div>
        <div className="clear"></div>
      </div>
      <div className="page-content row">
        {List}
        <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteMail}/>
      </div>
    </React.Fragment>
  )

}

export default IncomingMails;