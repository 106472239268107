import React, { useState, Fragment } from 'react';
import ReactDOM from 'react-dom';

const AddMember = ({ editMember, isOpen, toggleModal, addNewMember, rolesList}) => {

  editMember.role_ids = editMember.role_ids || [];

  const [count, setCount] = useState(0);
  const [member, setMember] = useState(editMember);

  /* 
   * To check an item exist in checkbox options.
   */
  const toggle = (item) => {
    let idx = member.role_ids.indexOf(item);
    if (idx > -1) {
      member.role_ids = (member.role_ids.filter(i => i !== item))
    }else {
      member.role_ids.push(item)
    }
    setMember({...member});
  }

  const exists = (item) => {
    return member.role_ids.indexOf(item) > -1
  }

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom border-rounded-10">
        
        <div className="flex justspacebetween modal-title">
          <h4 className="f18 text-white fw500 p12-10-15">{member.id?'Update':'Add'} Member</h4>
          <button className="btn-close"
            title="Close Modal" onClick={e=>toggleModal(e)}>×
          </button>
        </div>

        <form className="ml-container">
          <div className="ml-section">
            <label><b>Member</b></label>
            <input onChange={e=>{member.data.email = e.target.value}} 
              className="ml-input ml-border ml-margin-bottom"
              type="email" placeholder="User Email..." name="email"  
              defaultValue={member.data.email} required/>
            
            <label><b>Message</b></label>
            <textarea onChange={e=>{member.data.message = e.target.value}}
              className="ml-input ml-border ml-margin-bottom" placeholder="Message" 
              defaultValue={member.data.message} required>
            </textarea>

            <label><b>Permissions</b></label>
            {rolesList.map((p, k) =>
              <span className="checkbox-container" key={k} onClick={e => toggle(p.record_id, e)}>
                <span>{p.role}</span>
                <input type="checkbox" checked={exists(p.record_id)} readOnly/>
                <span className="checkbox-checkmark"></span>
              </span>
            )}

            <button className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>{addNewMember(e, member)}}>
              <b>{member.id?'Update':'Add'}</b>
            </button>
          </div>
        </form>
    
      </div>
    </div>
  </Fragment>, document.body))
}

export default AddMember;