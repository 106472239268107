import React, { Fragment, useState, useEffect, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"
import NotifiableRoleService from "./NotifiableRoleService"
import useStyle from '../../hooks/useStyle'
import ReactDOM from 'react-dom'
import Cs from '../../services/CommonService'
import env from "../../env"

const NotifiableRolesPopup = (props) => {
  useStyle('dashboard')	

  const {notifiableType, notifiableId} = props
  const { state: { screen, user: currentUser }} = useContext(AuthContext)

  let [selectedRoles, setSelectedRoles] = useState({})
  let [roles, setRoles] = useState([])
  const [loading, setLoading] = useState(true)
  let [orgMembers, setOrgMembers] = useState([])

  useEffect(() => {
    getRoles()
  }, [])

  const getRoles = () =>{
	  setLoading(true)
  	const req = {
      notifiable_role_params:{
        notifiable_type: notifiableType,
        notifiable_id: notifiableId,
        organization_id: currentUser.current_organization_id,
        page:1,
        per_page:100
  	  },
      role_params:{
        roleable_type: 'infant_journey',
        organization_id: currentUser.current_organization_id,
      },
      organization_member_params:{
        organization_id: currentUser.current_organization_id,
      }
  	}
    NotifiableRoleService.getAll(req).then((res)=>{
      if(res.status === 200){
        setRoles(res.data.roles)
        setOrgMembers(res.data.organization_members)
        setSelectedRoles(res.data.notifiable_roles)
		    setLoading(false)
      }
    })
  }

  const createOrRemove = (access, item, key) =>{
  	if(access){
  	  	NotifiableRoleService.delete(access.id).then((res)=>{
	      if(res.status == 204){
	        setSelectedRoles(selectedRoles.filter((i) => i.id !== access.id))
	      }
	    })	
  	}else{
  		const req = {
        'notifiable_type':notifiableType,
        'notifiable_id':notifiableId,
	  	  'organization_id':currentUser.current_organization_id,
	  	}
      if(key == 'role'){
        req.role_id = item.id
        req.role_record_id = item.record_id
      }else if(key == 'org_member'){
        req.user_id = item.user_id
        req.email_id = item.email
      }
	    NotifiableRoleService.create(req).then((res)=>{
	      if(res.status == 201){
	        setSelectedRoles([res.data.notifiable_role, ...selectedRoles])
	      }
	    })
  	}
  }

  const toggleMember = (member) =>{
    const r = selectedRoles.find((i) => i.user_id == member.user_id)
    createOrRemove(r, member, 'org_member')
  }

  const toggleRole = (role) =>{
    const r = selectedRoles.find((i) => i.role_id == role.id)
    createOrRemove(r, role, 'role')
  }

  const memberExists = (member) =>{
    const r = selectedRoles.find((i) => i.user_id == member.user_id)
    return r?.id
  }

  const roleExists = (role) =>{
    const r = selectedRoles.find((i) => i.role_id == role.id)
    return r?.id
  }

  const MemberList = () => orgMembers.map((m, i) => {
    return(
      <div className="m-b-5" onClick={e=>toggleMember(m)} key={i}>
        <span className={memberExists(m)?'font-18 far fa-check-square text-muted':'font-18 far fa-square text-muted'}/>
        <span className="m-l-15 font-16">{m.email}</span>
      </div>
    )
  })

  const RoleList = () => roles.map((r, i) => {
    return(
      <div className="m-b-5" onClick={e=>toggleRole(r)} key={i}>
        <span className={roleExists(r)?'font-18 far fa-check-square text-muted':'font-18 far fa-square text-muted'}/>
        <span className="m-l-15 font-16">{r.role}</span>
      </div>
    )
  })

  if(loading){
    return(
      <div className="spinner"/>
    )
  }

  return(
  	<div className="p-15">
      <span className="bold font-16 underline">Roles</span> 
  		<RoleList/>

      <span className="bold font-16 underline">Users</span> 
      <MemberList/>
  	</div>
  )
}

export default NotifiableRolesPopup;