import React, {Fragment} from 'react'
import PrivateRoute from '../../routes/PrivateRoute'
import TrainingPrograms from './List'
import TrainingProgramForm from './Form'
import TrainingSessions from './TrainingSessions/List'
import AssessmentList from "./Assessments/List/AssessmentList"
import AssessmentForm from "./Assessments/Form/AssessmentForm"
import StepAssessmentForm from "./Assessments/Form/StepAssessmentForm"
import QuestionForm from "./Assessments/Form/QuestionForm"
import AssessmentAnswers from "./Assessments/List/AssessmentAnswers"
import UserAssessments from './Assessments/List/UserAssessments'
import TrainingManager from './TrainingManager'

const TrainingRouter = (props) => (
  <Fragment>	
    <PrivateRoute path="/list/training_programs" component={TrainingPrograms} searchString={props.searchString} exact/>
    <PrivateRoute path="/list/training/sessions/:training_program_id" component={TrainingSessions} searchString={props.searchString} exact/>
    <PrivateRoute path="/training_programs/create/:id?" component={TrainingProgramForm} exact/>
    <PrivateRoute path="/list/assessments/:training_program_id?" component={AssessmentList} exact/>
    <PrivateRoute path="/assessment/form/:id?" component={AssessmentForm} exact/>
    <PrivateRoute path="/step_assessment/form/:assessment_id/:id?" component={StepAssessmentForm} exact/>
    <PrivateRoute path="/question/:assessment_id/:id?" component={QuestionForm} exact/>
    <PrivateRoute path="/answers/:assessment_id" component={AssessmentAnswers} exact/>
    <PrivateRoute path="/list/quiz" component={UserAssessments} exact/>
    <PrivateRoute path="/manage/training_programs" component={TrainingManager} exact/>
  </Fragment>
)

export default TrainingRouter;