import React, {Fragment, useState, useEffect, useContext} from 'react'
import ReactTooltip from 'react-tooltip'
import { useHistory } from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import env from "../../env"
import {Link} from "react-router-dom"
import Socket from "../../components/Common/Socket"
import CheckAccess from "../HealthJourney/Roles/CheckAccess"
import {usePageTour} from "../../components/Common/usePageTour"
import useStyle from '../../hooks/useStyle'
import { LocalizeContext } from "../../contexts/Localize/LocalizeStateProvider"
import {usePageLocale} from "../../hooks/useLocale"
import {useSearch} from '../../hooks/useSearch'

/*const WAIT_INTERVAL = 1000;
let timer = null;
let searchKeyword = null;*/

const Header = (props) => {

  const {state:{screen, user:currentUser}, dispatch} = useContext(AuthContext)
  //const {localeState:{header}, localeDispatch} = useContext(LocalizeContext)

  const history = useHistory()
  
  // const [headerItems, setHeaderItems] = useState(header?.options || {})
  const {labelLocale} = usePageLocale(currentUser.current_locale, 'header')
  const {searchButton, searchClick, setSearchButton, clearSearch, onSearchCallback, searchKeyword} = useSearch(props.setSearchString)

  /*const [scroll, setScroll] = useState(0)      
  const [isTop, setIsTop] = useState(false) */
  let tour = usePageTour()

  /*${env.file_url+currentUser.profile_image_url}*/
  const profileImageStyle = {background: currentUser.profile_image_url?
    `var(--lite_orange) url(../images/user-demo-icon.png) no-repeat -3px -4px`
    :
    `var(--lite_orange) url(../images/user-demo-icon.png) no-repeat -3px -4px`
  }

  useEffect(() => {
    /*timer = null
    searchKeyword = null*/

    let unlisten = history.listen((location, action) => {
      ReactTooltip.hide()
      clearSearch()
      setSearchButton(false)
    })

    setTimeout(function () {
      tour && tour.init()
    }, 1000)

    return () => {
      unlisten()
    }
  }, []);

  /*const handleChange=(event)=>{
    clearTimeout(timer)
    searchKeyword = event.target.value
    timer = setTimeout(triggerChange, WAIT_INTERVAL)
  }

  const triggerChange = () => {
    props.setSearchString(searchKeyword)
  }*/

  const openFeedback =(e) =>{
    props.history.push(`/feedback?url=${props.location.pathname}`)
  }

  /*useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollCheck = window.scrollY < 100
      if (scrollCheck !== scroll) {
        setIsTop(scrollCheck)
        setScroll(scrollCheck)
      }
    })
  },[])*/

  return (
    <Fragment>
      <div id="ct" className="header-container">
        <header className="top_bar">
          <div className="flex wrap justspacebetween aligncenter top_bar_content">
            <div className="flex aligncenter gap-5">
              <span className="logo_bg flex justifycenter aligncenter">
                <Link to="/health/table" data-tip={labelLocale('home_screen')}>
                  <img id="header_home" className="w-55 w-40-xs"
                    src="images/logo/cuetree_Icon.png" alt="cueTree Logo" />
                </Link>
              </span>
              <span className="flex justifycenter aligncenter m-l-10 text-white f18"
                data-tip={labelLocale('back_page')}
                onClick={() => history.goBack()}>
                  <i className="fa fa-arrow-left" />
              </span>
            </div>
            <div className="flex aligncenter pointer">
              <ul className="flex aligncenter menu-xs">
                <li data-tip={labelLocale('search')}>
                  <a id="search_btn" onClick={e => searchClick()} 
                    className="flex m-r-30 m-r-15-xs pos_relative">
                    <i className="fas far fa-search white font-14"/>
                  </a>
                </li>
                <li data-tip={(currentUser?.current_organization?.data?.name || '')}>
                  <a onClick={(e) => props.toggleOrgModal()} className="flex m-r-30 m-r-15-xs">
                    <img src="images/web-icon.png" alt="" />
                  </a>
                </li>
                <li data-tip={labelLocale('notification')}>
                  <Link to="/notifications" className="flex m-r-30 m-r-15-xs pos_relative">
                    <img src="images/notification-icon.png" alt="" />
                    <span className="notification_count_header tcenter f10 border-rounded flex aligncenter justifycenter fw900">
                      0
                    </span>
                  </Link>
                </li>
                <li data-tip={labelLocale('preference')}>
                  <Link to="/user/profile" 
                    className="flex aligncenter m-r-30 m-r-15-xs user_dropdown-xs">
                      <span className="user_dropdown flex" style={profileImageStyle}/>
                  </Link>
                </li>
                <li data-tip={labelLocale('main_menu')} onClick={e=> {props.setOpenSideNav(!props.openSideNav)}}>
                  <div className="flex m-r-30 m-r-15-xs">
                    <img src="images/menu-icon.png" alt="" />
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </header>
      </div>
      
      {searchButton && 
        <div className="search-box flex search-color bg-white shadow-tiny bottom-10">
          <div className="flex_grow">
            <i className="fa fa-search"/>
            <input type="text" 
              placeholder="Search here.." 
              value={searchKeyword}
              autoFocus onChange={(e)=>onSearchCallback(e)}/>
          </div>
          <div>
            <i className="fas fa-times search-close" onClick={()=>clearSearch()}/>
          </div> 
        </div>
      }
    </Fragment>
  )

}

export default Header;