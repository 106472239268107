import React, {useContext, useEffect, useState} from "react"
import {Link, useHistory} from "react-router-dom"
import {JourneyInlineMenu, PortalToggleMenu} from "../../GenericPortalPage/Common"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../services/CommonService"
import ChildForm from "../../MotherInfantJourney/Child/ChildForm"
import GenericModal from "../../../../components/Modals/GenericModal"
import useModal from "../../../../hooks/useModal"
import {NotesBox} from "../../GenericPortalPage/NotesBox"
import UsersRolesPopup from '../../../DataAccesses/UsersRolesPopup'
import ReactTooltip from "react-tooltip"
import {usePageLocale} from "../../../../hooks/useLocale"
import {DraftBadge} from "../../UserRoles/NotificationPill"

let formProps = null
const ChildBlock = (props) => {
  let {journey, menuOptions, orgBgImageStyle, dataSource} = props
  let {last_measurement:lastMeasurement} = journey
  let [child, setChild] = useState(props.child)
  let {data, data_source} = child
  let history = useHistory()

  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const {isOpen: isInfantActivityFormOpen, toggleModal:toggleInfantActivityModal } = useModal()
  const {isOpen: isNotesOpen, toggleModal:toggleNotesModal } = useModal()
  const {isOpen: isUserRoleOpen, toggleModal: toggleUserRole } = useModal()

  const {labelLocale} = usePageLocale(currentUser.current_locale, 'childBirthTab')

  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])

  const routeActivityForm = (path) => {
    formProps = Cs.routeInfantPath(path, {}, journey, history);
    if (path.sid == "instant_baby") {
      formProps.component = ChildForm;
    }
    toggleInfantActivityModal()
  }

  const onFormSubmit = (child_form_data) => {
    setChild(child_form_data)
    toggleInfantActivityModal()
  }

  const openUserRole = () => {
    toggleUserRole()
  }

  return(
    <>
      <div className="flex coldir fulwid p10">
          <div className="f16">
            <div className="flex justspacebetween">
              <div className="f24 m-r-20 text-blue-2">
                {journey.record_id} - {child?.data?.baby_name || 'Baby name pending'}
                <DraftBadge isDraft={child.draft} styleName="m-l-15"/>
              </div>
              <div className="flex aligncenter">
                <span data-tip="Edit child details"
                  className="icon-gray-cirle border-rounded-30 flex aligncenter justifycenter" 
                  onClick={() => routeActivityForm({sid:'instant_baby'})}>
                    <i className="fas fa-edit" />
                </span>

                <PortalToggleMenu journey={journey} menuOptions={menuOptions} 
                  journeyType="infant_journey" menuDsId='3530' 
                  permissionKey="infant_portal" child={child}/>
              </div>
            </div>
            <ul className="flex aligncenter m-t-15">
              <li className="m-r-20">
                {data_source?.gender?.label} 
              </li>
              <li className="m-r-20">
                | &nbsp; {data_source?.delivery_type?.label}
              </li>
              <li className="m-r-20">
                | &nbsp; {data_source?.gestational_week?.label ? (data_source?.gestational_week?.label + 'W') : ''}  
              </li>
            </ul>
            <ul className="flex aligncenter m-t-15">
              <li className="m-r-20">
                <span className="fa fa-location-dot text-blue-2 m-r-5"/>
                {data_source?.delivery_location?.label || 'Location not available'}
              </li>
              <li className="m-r-20">
                <span className="far fa-calendar-alt text-blue-2 m-r-5"/>
                {child.data.dob_baby}
              </li>
            </ul>
            <ul className="flex aligncenter m-t-15">
              <li className="m-r-20 d-grid-xss" label="Birth Details">
                {labelLocale(1)}
              </li>
              <li className="m-r-20 d-grid-xss">
                H: {child.data_source?.height?.label} cms
              </li>
              <li className="m-r-20 d-grid-xss">
                W: {child.data_source?.weight?.label} kgs 
              </li>
            </ul>
            <ul className="flex aligncenter m-t-15">
              <li className="m-r-20 d-grid-xss" label="Last Update">
                {labelLocale(2)}&nbsp; 
              </li>
              <li className="m-r-20 d-grid-xss">
                H: {lastMeasurement?.data_source?.height_baby?.label} cms
              </li>
              <li className="m-r-20 d-grid-xss">
                W: {lastMeasurement?.data_source?.baby_weight?.label} kgs
              </li>
              <li className="m-r-20 d-grid-xss">
                <div>{child?.member_role?.role}</div>
              </li>
            </ul>
        </div>
      </div>

      {isInfantActivityFormOpen && 
        <GenericModal toggleModal={toggleInfantActivityModal} isPopupMode="true"
          onFormSubmit={onFormSubmit} closeConfirmPopup={true} {...formProps}/>
      }

      {isNotesOpen && 
        <GenericModal title="Notes" component={NotesBox} 
        toggleModal={toggleNotesModal} journeyProfileId={journey.id}
        noteableId={journey.id}
        currentUser={currentUser} />
      }

      {isUserRoleOpen && 
        <GenericModal component={UsersRolesPopup} 
        isOpen={isUserRoleOpen} toggleModal={toggleUserRole}
        itemType="journey_profile" itemId={journey.id} 
        showRoles="true" showOrgMembers="true" 
        title="Assign this case to Roles and/or Users"/>
      }
    </>
  )
}  

export default ChildBlock;