import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import moment from "../../../../node_modules/moment/min/moment.min.js";
import Rating from "../../../components/Rating";
import Cs from "../../../services/CommonService";
import ReactTooltip from 'react-tooltip';

const Forum = (props) => {

  const { forum } = props;
  let data = forum.data;
  let profileImage = "images/avatars/2s.png";
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  const replyList =
    forum.discussion_responses &&
    forum.discussion_responses.map((a, i) => {
      return (
        <div className="d-flex" key={i}>
          <div className="card-header">
            <img src={profileImage} className="rounded-circle bg-lgrey-2"
              width="50"/>
          </div>
          <div className="card-body">
            <p className="font-15"
              dangerouslySetInnerHTML={{ __html: a.data.response }}
            ></p>
          </div>
        </div>
      );
    });

  const ReplyCount = () => {
    if (forum.discussion_responses) {
      if (forum.discussion_responses.length > 1) {
        return forum.discussion_responses.length + " replies";
      } else {
        return forum.discussion_responses.length + " reply";
      }
    } else {
      return null;
    }
  };

  return (
    <div className="container-fluid mt-100">
          <div className="card mb-4">
            <div className="card-header">
              <div className="media flex-wrap w-100 align-items-center">
                <img
                  src={profileImage}
                  className="d-block ui-w-40 rounded-circle"
                  alt=""
                />
                <div className="media-body ml-3">
                  <a href="#" data-abc="true">
                    Tom Harry
                  </a>
                  <div className="text-muted small">
                    {Cs.formatUpdateDate(forum.created_at)}
                  </div>
                </div>
                <div className="text-muted small ml-3">
                  <div>
                    Member since <strong>01/1/2019</strong>
                  </div>
                  <div>
                    <strong>134</strong> posts
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              <p> {data.subject} </p>
              <p dangerouslySetInnerHTML={{ __html: data.description }}>
              </p>
              <div
                className="highlight underline font-14"
                onClick={(e) => setToggle(!toggle)}
              >
                <ReplyCount />
                {toggle ? replyList : ""}
              </div>
            </div>
            <div className="card-footer d-flex flex-wrap justify-content-between align-items-center px-0 pt-0 pb-3">
              <div className="px-4 pt-3">
                  <i className="fa fa-heart text-danger"></i>
                  <span className="align-middle">445</span>
                <span className="text-muted d-inline-flex align-items-center align-middle ml-4">
                  <i className="fa fa-eye text-muted fsize-3"></i>
                  <span className="align-middle">14532</span>
                </span>
              </div>
              <div className="px-4 pt-3 d-flex">
                <props.reply forum={forum} />
                <props.edit forum={forum} />
                <props.delete forum={forum} />
              </div>
            </div>
          </div>
    </div>
  );
};

export default Forum;
