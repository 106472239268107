import axios from 'axios';

//axios.defaults.baseURL = 'https://jsonplaceholder.typicode.com';
//axios.defaults.headers.common['Authorization'] = 'AUTH TOKEN';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.withCredentials = true;
//axios.defaults.withCredentials = 'include';

axios.interceptors.request.use(request => {
    console.log(request);
    // Edit request config
    return request;
}, error => {
    //console.log(error);
    networkError(error);
    return Promise.reject(error);
});

axios.interceptors.response.use(response => {
    console.log(response);
     // Edit response config
     return response;
}, error => {
    //console.log(error);
    networkError(error);
    /*return error.response;*/
    return Promise.reject(error);
});

const networkError = (e) => {
    if(navigator.onLine && e.response){
      if(e.config.url.indexOf('nominatim.openstreetmap.org') > -1){
        //third party api issue
      }else if(e.response.data?.error){
        alert(e.response.data?.error)
      }
    }else if(window.location.pathname !== '/offline'){
      let text = "Network Issue. Press OK to use offline mode.";
      if (window.confirm(text) == true) {
        document.getElementById('offline-nav').click()
      }
    }
}