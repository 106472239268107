import React, {Fragment, useState, useEffect} from "react"
import useSort from "../../hooks/useSorting"
import SortDirection from "../Common/SortDirection"
import Cs from "../../services/CommonService"
import useModal from "../../hooks/useModal"
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import OrgDataMapperService from "./OrgDataMapperService"

let requestParams ={}
const OrgDataMapperTable = (props) => {

  const [isLoading, setIsLoading] = useState(true)
  const [dataMappers, setDataMappers] = useState([])

  useEffect(() => {
    requestParams = {
      item_id: props.itemId,
      item_type: props.itemType
    } 
    getAll()
  }, [])

  const getAll = () =>{
    OrgDataMapperService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setDataMappers(res.data.data_mappers)
        setIsLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const deleteMapper = (id) => {
    OrgDataMapperService.delete(id).then((res) => {
      if (res.status == 204) {
        setDataMappers(dataMappers.filter(m => m.id !== id))
      }
    })
  }

  const createMapper = (organization) =>{
    let req = {
      assigned_to_organization_id: organization.organization_id,
      item_id: props.itemId,
      item_type: props.itemType
    }
    OrgDataMapperService.create(req).then((res) => {
      if (res.status == 201) {
        const idx = dataMappers.findIndex(m => m.organization_id == organization.organization_id)
        if(idx>-1){
          dataMappers[idx] = {...organization, ...res.data.data_mapper}
          setDataMappers([...dataMappers])
        }
      }
    })
  }

  const tableData = dataMappers.map((o, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray">
          {o.organization_name}
        </TD>
        <TD className="fw500">
          <span 
            className={o.id?'fas fa-check-circle':'far fa-circle'}
            onClick={()=>{o.id?deleteMapper(o.id):createMapper(o)}}> 
          </span>
        </TD>
        <TD>
          {Cs.formatUpdateDate(o.created_at)}
        </TD>
      </TR>
    )
  })

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content table-fix-head">
          <div className="app-content-section m-t-30">
            <Table className="shadow-small">
              <THead>
                <TR>
                  <TH onClick={e=>sort('organizations.organization_name')}>
                    Organization 
                    <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                  </TH>
                  <TH onClick={e=>sort('id')}>
                    Active 
                    <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
                  </TH>
                  <TH onClick={e=>sort('created_at')}>
                    Log
                    <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
                  </TH>
                </TR>
              </THead>
              <TBody>
                {tableData}
              </TBody>
            </Table>
          </div>
        </div>
      </div>

    </Fragment>
  )

}

export default OrgDataMapperTable;