import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../../PreLoadSpinner"
import DynamicForm from "../../../../FormBuilder/DynamicForm"
import FormTemplateId from "../../../../../constants/FormTemplateList"
import CounselingService from "../CounselingService"
import querystringify from "querystringify"
import FormContainer from '../../../../../components/FormBuilder/FormContainer'
import env from '../../../../../env'
import useModal from "../../../../../hooks/useModal"
import GenericModal from "../../../../Modals/GenericModal"
import PlannerService from '../../../Health/Planners/PlannerService'
import PlannerStepService from '../../../Health/Planners/PlannerStepService'

let formMode = null
let counseling = {}
let data = {}
let formFn = {}
let formId = null 
function CounselingForm(props) {
  //const { state: authState, dispatch } = useContext(AuthContext);
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  const [plannerStepList, setPlannerStepList] = useState([])

  //const forigenObject =  props.location.state;
  const params = props.match.params;
  const queryParam = querystringify.parse(props.location.search);

  const { isOpen:isPlannerStepOpen, toggleModal:toggleStepPlanner } = useModal()

  formFn.planners_path = 'name'
  formFn.planners_pagenate = {
    page:1,
    per_page:15
  }

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    CounselingService.get(params.id).then(res => {
      if (res.status === 200) {
        counseling = res.data.counseling
        data = counseling.data || {}
        formId = counseling.counseling_form_id
        setFormRendered(true)
      }
    }).catch(function (res) {
      counseling = null
    })
  }

  useEffect(() => {
    counseling = {}
    data = {}
    formId = parseInt(params.form_id)
    formFn = {
      locale: 'all',
      form_type: 'health.counseling',
      journey_category_id: params.journey_profile_id,
      editPermission: queryParam.edit,
      localeKey:'language',
    }
    formMode = 'create-form-submissions'
    //if(forigenObject)data = Object.assign(forigenObject, data) 
    if (params.id) {
      get()
      formMode = 'edit-form-submissions'
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (counseling) => {
    counseling.created_by = currentUser.id
    counseling.updated_by = currentUser.id
    counseling.journey_profile_id = params.journey_profile_id
    counseling.counseling_form_id = formId
    counseling.counseling_type = params.form_type
    counseling.is_template = queryParam.is_template=='true'?true:false
    CounselingService.create(counseling).then((res) => {
      if (res.status === 201) {
        onSubmitPageRedirect()
      }
    }).catch((res) => {

    })
  }

  const update = (counseling) => {
    counseling.updated_by = currentUser.id
    CounselingService.update(counseling).then((res) => {
      if (res.status === 204) {
        onSubmitPageRedirect()
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = () => {
    props.history.goBack()
    /*props.history.push(
      `/infant/list/counseling/${params.journey_profile_id}?label=${queryParam.label}`, {project, child}
    )*/
  }

  formFn.on_select_language = (item) =>{
    console.log(item)
    data.language_sid = item.sid
    formFn.setLocale(item.sid)
  }

  formFn.on_step_open = (options, setOptions) =>{
    setOptions(options.filter((o)=>o.stage_id == data.stage))
  }

  formFn.get_planners = (search, on_success, on_failure) => {
    let req = {
      search: search,
      step_search: search,
      is_form_search: true,
      filter:{language:data.language},
      page: formFn.planners_pagenate.page,
      per_page: formFn.planners_pagenate.per_page
    }
    
    PlannerService.getAll(req).then(({status, data})=>{
      formFn.planners_pagenate.pageSize = data.meta.last_page
      on_success(data.planners)
    })
  }

  formFn.toggle_planners = (item) =>{
    const listKey = 'planners_array'
    const idx = data[listKey].findIndex(o => o.planner_fk_id == item.planner_fk_id);
    if(idx > -1) {
      data[listKey].splice(idx, 1);
    }else{
      data[listKey].push({name:item.name, planner_fk_id:item.id});
    }
    data.planners = data[listKey].map((o)=> o.id).join(",");
    return data[listKey]
  }

  formFn.exist_planners = (item, list_field) =>{
    return data.planners_array.findIndex(o => o.planner_fk_id == item.planner_fk_id) > -1;
  }

  formFn.select_planners = (p) => {
    p.step_planners = p.step_planners || []
    formFn.seletedPlanner = p

    let req = {
      planner_id: p.planner_fk_id,
    }

    PlannerStepService.getAll(req).then((res) => {
      setPlannerStepList(res.data.planner_steps)
      toggleStepPlanner()
    })
  }

  const PlannerStepList = ({}) => plannerStepList.map((o, k) =>
    <span className="checkbox-container" key={k} onClick={e => selectStepPlanner(o)}>
      <span>{o.data.start_time} - {o.data.description}</span>
      <input type="checkbox" checked={existStepPlanner(o)} readOnly/>
      <span className="checkbox-checkmark"></span>
    </span>
  )

  const PlannerModal = ({}) => (
    <div className="p-5">
      <h5 className="bg-lgrey ml-center white p-5 m-b-5">Planner</h5>
      <PlannerStepList/>
    </div>
  )

  const selectStepPlanner = (step_planner) =>{
    let list = formFn.seletedPlanner.step_planners
    const idx = list.findIndex(o => o == step_planner.id);
    if(idx > -1) {
      list.splice(idx, 1);
    }else{
      list.push(step_planner.id);
    }
    setPlannerStepList([...plannerStepList])
  }

  const existStepPlanner = (step_planner) =>{
    let list = formFn.seletedPlanner.step_planners
    return list.findIndex(o => o == step_planner.id) > -1; 
  }

  if (isFormRendered) {
    return (
      <>
        <FormContainer post={post} isPopupMode={props.isPopupMode}>
          <DynamicForm formMode={formMode}
            formId={formId}
            form={counseling}
            data={data}
            formFn={formFn}
            onCreate={create}
            onUpdate={update}
            post={post}
            onCancel={onSubmitPageRedirect}
            setPost={setPost}
            isPopupMode={props.isPopupMode}/>
          {isPlannerStepOpen && 
            <GenericModal component={PlannerModal} 
            toggleModal={toggleStepPlanner} isPopupMode={true}/>
          }
        </FormContainer>
      </>
    )
  } else {
    return null
  }

}

export default CounselingForm;