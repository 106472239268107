import React, {Fragment} from 'react'
import useStyle from '../../hooks/useStyle'
import SortDirection from './SortDirection'
import useSort from '../../hooks/useSorting'
import {TableColumnData} from '../HealthJourney/UserRoles/NotificationPill'
import Cs from "../../services/CommonService"
import {getLocale} from '../FormBuilder/FieldLabel'
import {usePageLocale} from "../../hooks/useLocale"

const DynamicTable = (props) => {
  let {
  	requestParams, onSort, specialFields=[], formFields=[], formDataList=[], 
  	tClass, thClass, tbClass, OptionsEle, onRowClick, currentLocale, isSortable=true
  } = props	
  
  const {labelLocale:tableHeadLocale} = usePageLocale(currentLocale, 'commonTableHeaders')

  const {sort} = useSort(requestParams, onSort)

  const TableHeader = ({headers}) => headers.map((h, k) => {
    let attribute = {}
    if(isSortable)
      attribute.onClick = () => sort(h.client_id)

  	return(  
  	  <th key={k} className='text-center miw-95' {...attribute}>
        {getLocale(h, currentLocale)} 
        {isSortable ?
          <SortDirection sort_column={requestParams.sort_column} column={h.client_id}
          reverse={requestParams.reverse}/> 
          :
          null
        }
      </th> 	
  	)
  })

  const TableRow = ({list}) => list.map((l, k) => {
  	let dataSource = l.data_source || {}
    let data = l.data || {}

  	return(  
  	  <tr key={k} onClick={()=>onRowClick && onRowClick(l)}>
  	  	<SpecialFieldData specialFields={specialFields} data={l} row={l}/>	
  		  <TableColumnData {...{formFields, data, dataSource}}/>	
  		  {OptionsEle && <OptionsEle item={l}/>}
  	  </tr>
  	)
  })

  const SpecialFieldData = ({specialFields, data, row}) => specialFields.map((f, k) => {
  	if(f.ele){
  		return (
  			<td className="text-center" key={k}>
  			   {f.ele(f.client_id, data, row)}
  			</td>
  		)
  	}else{
  		return(  
	  	  <td className="text-center" key={k}>
	        {data[f.client_id]}
	      </td>
  		)
  	}
  })

  return(
    <table className={tClass}>
      <thead className={thClass}>
        <TableHeader headers={specialFields} isSortable={isSortable}/>
      	<TableHeader headers={formFields}/>
      	<th onClick={()=>sort('created_at')} className='text-center miw-95' label="Option">
          {tableHeadLocale(3)}
          <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
  		  </th>
      </thead>
      <tbody>
        <TableRow list={formDataList} /> 
      </tbody>
    </table>	
  )
}

export default DynamicTable;