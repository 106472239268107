import React, { Fragment,useState,useEffect } from 'react';
import ReactDOM from 'react-dom';
import useStyle from '../../hooks/useStyle';
import CommonService from "../../services/CommonService";
import useEscape from '../../hooks/useEscape';

const GalleryModal = ({  item, type, isOpen, toggleModal, showInline }) => {
  useStyle('add_role_dialog');
  useStyle('filter_dialog');
  useEscape(toggleModal);
  console.log('toggleModal',item, type, isOpen, toggleModal, showInline)

  let data = item.data
  let images = data.image_upload
  const [currentImage, setCurrentImage] = useState('');
  useEffect(() => {
  
  },);

  const thumbNailImag = images && images.map((im,i)=>{
    return(
    <div className="pull-left img-container  m-auto w-20p d-flex ht-100 m-5">
      <img className="img-responsive w-100p" onClick={(e)=>setCurrentImage(im)} src={CommonService.getIconByType(im)} alt="Nature"/>
    </div>
    )
  })

  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom border-rounded-10">

          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Image Gallery</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
          
          <div className="row w-100p">
            {thumbNailImag}
          </div>
          { currentImage ? <div className="container m-auto img-container">
            <img className="img-responsive product-img p-10" src={CommonService.getIconByType(currentImage)} />
          </div>:''}
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default GalleryModal;