import React, {useState, useEffect, useContext} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import {Link} from "react-router-dom";
import useStyle from '../../hooks/useStyle';
import CommonService from '../../services/CommonService';
import Notification from "./Notification"
import NotificationRoute from  "./NotificationRoute"
import CTLazyLoad from "../Common/CTLazyLoad"
import {usePageTitle} from '../../hooks/pageHead';
import PaginationSM from "../Common/PaginationSM"

let requestParams = {}
let selectedNotification = null;
const css = ['/css/notification.css']
export const NotificationList = (props) => {
  usePageTitle('News List');
  
  let params = props.match.params;
  
  const { state:currentUser, dispatch } = useContext(AuthContext);
  
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [pagemeta, setPagemeta] = useState([])
  const [currentpage, setCurrentpage] = useState(1)

  useEffect(() => {  
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'created_at', 
      'sort_direction': 'desc',
    }

    getNotificationsList();
  }, [props.searchString, currentpage]);

  let getNotificationsList = () => { 
    CommonService.getNotifications(requestParams).then((res)=>{
     if(res.status == 200){
        setNotifications(res.data.notifications)
        setUnreadCount(res.data.unread_count)
        setPagemeta(res.data.meta)
      }
    })
  }
  
  const onDelete = (notification) => {
    selectedNotification = notification;
    CommonService.deleteNotification({id:selectedNotification.id}).then((res) => {
      if(res.status==204){
        setNotifications(notifications.filter(r => r.id !== selectedNotification.id));
      }
    })   
  }

  /*const setNotificationRead = (n) => {
    let req = {'status':'read'}
    CommonService.updateNotification(n.id, req).then((res)=>{
      n.status = 'read';
    })
  }

  const routeNotification = (n) =>{
    setNotificationRead(n)
    NotificationRoute.routeNotification(n, props)
  }*/

  return (
    <CTLazyLoad css={css}>
      <div id="ct">
        <div className="app_content m-b-80">
          <div  className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20-xs">
            <div className="flex coldir fulwid p20">
              <div className="flex alignstart justspacebetween">
                <div>
                  <div className="app-title m-r-20">Notifications</div>
                  <p className="m-t-10">Org Master</p>
                </div>
              </div>
            </div>
          </div>
          <div className="app-content-section m-t-30 m-t-0-xs m-t-20-xs m-b-5">
            <div className="m-t-30 m-t-0-xs">
              <div className="bg-white p20 border-rounded-10">
                <div className="bg-white flex justspacebetween aligncenter">
                  <h5 className="f15 fw500">Unread {unreadCount} notification</h5>
                </div>
                <div className="row m-t-30">
                  <div className="col-md-12">
                    {notifications.map((n, i) => 
                      <Notification key={i} notification={n} onDelete={onDelete}/>           
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <PaginationSM
            className="pagination-bar"
            currentPage={currentpage}
            totalItems={pagemeta.total}
            itemsPerPage={pagemeta.per_page}
            onPageChange={setCurrentpage}
            siblingCount={2}
          />
        </div>
      </div>
    </CTLazyLoad>
  )
}

export default NotificationList;