import React, { useEffect, useState, useContext } from 'react';
import querystringify from "querystringify";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../components/PreLoadSpinner";
import DynamicForm from '../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../constants/FormTemplateList'
import FeedbackService from "../Feedback/FeedbackService";
import FormContainer from '../../components/FormBuilder/FormContainer';

let formMode = null; 
let formId = null; 
let feedback = {}; 
let data = {}; 
let formFn = {};  

function FeedbackForm(props) {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  useEffect(() => { 
    formMode = 'create-form-submissions';
    data = {}; 
    feedback = {}; 
    formFn = {};
    if(params.id){
      formMode = 'view-form-submissions';
      getById(params.id)
    }else{
      setFormRendered(true);
    }
  }, []);

  const getById = (id) => {
    FeedbackService.get(id).then((res) => {
      if(res.status==200){
        feedback = res.data.feedback;
        data = feedback.data || {};
        setFormRendered(true);
      }
    })
  }

  const create = (feedback) => {
    feedback.created_by = currentUser.id;
    feedback.updated_by = currentUser.id;
    feedback.data.page_url = queryParam.url; 
    feedback.form_id = FormTemplateId.FeedbackForm;
    FeedbackService.create(feedback).then((res) => {
      if(res.status === 201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.goBack();
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.FeedbackForm} 
        form={feedback} data={data} formFn={formFn} onCreate={create} 
        post={post} onCancel={onSubmitPageRedirect} setPost={setPost} />
      </FormContainer> 
    )  
  }else{
    return null
  }
  
}

export default FeedbackForm;