import env from '../env';
import axios from 'axios';
import BaseService from './BaseService';

let url = `${env.health_backend}health/data_source_mappings`

class DataSourceMappingService extends BaseService {

}

export default new DataSourceMappingService('health_backend','health/data_source_mappings');