import React, { useState, Fragment} from 'react';
import ReactDOM from 'react-dom';

const InvitedUserModal = ({ inviteUser, isOpen, toggleModal, addInviteUser, dataSources, roles, familyMembers}) => {

   if(inviteUser)inviteUser.role_ids = inviteUser.role_ids || [];
  const [newInviteUser, setNewInviteUser] = useState(inviteUser);
  const [showUser, setShowUser] = useState(false);

  /* 
   * To check an item exist in checkbox options.
   */
  const setRole = (item, key) => {
    let list = newInviteUser[key];
    let idx = list.indexOf(item);
    if(idx > -1) {
      list = list.filter(i => i !== item)
      newInviteUser[key] = list;
    }else {
      list.push(item)
    }
    setNewInviteUser({...newInviteUser})
  }

  const exists = (item, key) => {
    let list = newInviteUser[key];
    return list.indexOf(item) > -1
  }

  const toggle = (key) => {
    setNewInviteUser({...newInviteUser, [key]: !newInviteUser[key]});
    console.log(newInviteUser)
  }

  const setEmail = (member) => {
    newInviteUser.email = member.data.email_id;
    newInviteUser.family_member_id = member.id;
    setShowUser(false);
  }

  return(ReactDOM.createPortal(
    <React.Fragment>
      <div className="ml-modal">
      <div className="ml-modal-content ml-card-4 ml-animate-zoom border-rounded-10">
      
      <div className="flex justspacebetween modal-title">
        <h4 className="f18 text-white fw500 p12-10-15">{newInviteUser.id?'Update':'Invite'} User</h4>
        <button className="btn-close"
          title="Close Modal" onClick={e=>toggleModal(e)}>×
        </button>
      </div>

    <form className="ml-container">
      <div className="ml-section">

        <label><b>Email</b></label>
        <input onChange={e=>{newInviteUser.email = e.target.value}} className="ml-input ml-border ml-margin-bottom"
          type="text" placeholder="Email" name="inviteUser_name" required defaultValue={newInviteUser.email}/>
        
        <span className="checkbox-container" onClick={e => toggle('full_access')}>
          <span>Full Access (Admin level permission) {newInviteUser.full_access}</span>
          <input type="checkbox" checked={newInviteUser.full_access} readOnly/>
          <span className="checkbox-checkmark"></span>
        </span>

        <label><b>Role</b></label>
        <br/>
        {roles && roles.map((r, k) => (
          <Fragment key={k}>
            <span className="checkbox-container" onClick={e => setRole(r.record_id, 'role_ids')}>
              <span>{r.role}</span>
              <input type="checkbox" checked={exists(r.record_id, 'role_ids')} readOnly/>
              <span className="checkbox-checkmark"></span>
            </span>
          </Fragment>
        ))}

        <label><b>Description</b></label>
        <textarea maxLength="100" onChange={e=>{newInviteUser.message = e.target.value}}
          className="ml-input ml-border" placeholder="Invitation Message" 
          defaultValue={newInviteUser.message} required>
        </textarea>
        <span>Maximum 100 Characters</span>

        <div className="ml-button ml-block ml-section ml-padding bg-highlight white" onClick={e=>{addInviteUser(e, newInviteUser)}}>
          <b>{newInviteUser.id?'Update':'Invite'}</b>
        </div>
        
      </div>
    </form>
    
  </div>
</div>
</React.Fragment>, document.body))
};

export default InvitedUserModal;

/*<span className="checkbox-container" onClick={e => toggle('is_journey_owner')}>
          <span>Is Journey User</span>
          <input type="checkbox" checked={inviteUser.is_journey_owner} readOnly/>
          <span className="checkbox-checkmark"></span>
        </span>*/


/*
<label><b>User</b></label>
        <div className="autocomplete">
          <input type="text" className="form-control" placeholder="Enter Name" autocomplete="off" defaultValue={newInviteUser.email} onChange={e=>setShowUser(true)}/>
          {showUser && 
            <div id="autocomplete-list" className="autocomplete-items">
              {familyMembers.map((m, i) => (
                <div onClick={e=>setEmail(m)}>
                  {m.data.name_of_contact}
                </div>
              ))}
            </div>
          }
        </div>
*/