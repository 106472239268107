import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import DynamicForm from "../../FormBuilder/DynamicForm"
import FormTemplateId from "../../../constants/FormTemplateList"
import ScreeningEventsService from "../ScreeningEventsService"
import querystringify from "querystringify"
import FormContainer from '../../FormBuilder/FormContainer'
import {CityState} from '../../Common/CityState'

let formMode = null
let screening = {}
let data = {}
let formFn = {}

function ScreeningEventForm(props) {
  
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let forigenObject =  props.location.state;
  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    ScreeningEventsService.get(params.id).then((res) => {
      if (res.status == 200) {
        screening = res.data.screening_event;
        data = screening.data || {}
        //params.form_template_id = screening.activity_form_id;
        console.log('data',data)
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function (res) {
      screening = null;
    })
  }

  useEffect(() => {
    screening = {}
    data = {}
    formMode = "create-form-submissions";
    data = {}
    if (params.id) {
      get();
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, []);

  const create = (screening) => {
    screening.created_by = currentUser.id;
    screening.updated_by = currentUser.id;
    screening.form_id = params.formId;
    ScreeningEventsService.create(screening).then((res) => {
      if (res.status == 201) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const update = (screening) => {
    screening.updated_by = currentUser.id;
    ScreeningEventsService.update(screening).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    }).catch((res) => {

    })
  }

  const onSubmitPageRedirect = () => {
    if(props.isPopupMode){
      props.onFormSubmit()
    }else{
      props.history.goBack() 
    } 
  }

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm
          formMode={formMode}
          formId={params.formId}
          form={screening}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
          
          <CityState formFn={formFn} data={data}/>
      </FormContainer>
    )
  } else {
    return null;
  }
}

export default ScreeningEventForm;
