import React, { Fragment, useContext, useState, useEffect, useRef } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from "../../services/CommonService"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from '../../../node_modules/moment/min/moment.min.js'
import FormHelper from '../FormBuilder/FormHelpers'
import useModal from "../../hooks/useModal";
import GenericModal from "../Modals/GenericModal";
import {ImageFlexList} from '../Common/Image'
import env from '../../env'
import {getLocale} from '../FormBuilder/FieldLabel'

const CheckBox = ({list, toggle, exists, labelKey, valueKey, name, required, onToggleReturnObject}) =>{
  
  const toggleFn = (o, e) =>{
    if(onToggleReturnObject){
      toggle(o, name)
    }else{
      toggle(o[valueKey], name, e)
    }
  }

  return <CheckBoxObjList list={list} toggle={toggleFn} exists={exists} 
    labelKey={labelKey} valueKey={valueKey} name={name} required={required}/>
}

const CheckBoxObjList = ({list, toggle, exists, labelKey, valueKey, name, required}) => list.map((o, k) =>
  <span className={`checkbox-container ${o.read_only=='yes'?'input-disabled':''} `} key={k} onClick={e => toggle(o, e)}>
    <span>{o[labelKey]}</span>
    <input type="checkbox" name={name} checked={exists(o[valueKey], name)} required={required} readOnly/>
    <span className="checkbox-checkmark"></span>
  </span>
)

const Radio = ({list, model, name, handleChange, required, exists}) =>{
  return <RadioObjList list={list} name={name} model={model} handleChange={handleChange} exists={exists} required={required}/>
}

const RadioObjList = ({list, handleChange, model, name, required, exists}) => list.map((o, k) =>
  <label className={`radio-container ${o.read_only=='yes'?'input-disabled':''}` } key={k}>
    <span>{o.label}</span>
    <input type="radio" name={name} required={required} onChange={(e)=>handleChange(e.target.value, k)}  defaultValue={o.value} checked={exists(o)}/>
    <span className="radio-checkmark"></span>
  </label>
);

const RadioStringList = ({list, handleChange, selected}) => list.map((o,k) =>
  <label className="radio-container pointer" key={k}>
    <span>{o}</span>
    <input type="radio" onChange={handleChange}  defaultValue={o} checked={selected==o}/>
    <span className="radio-checkmark"></span>
  </label>
)

const OptionsObjList = ({list, id_key, label_key, has_key, user_locale='english'}) => has_key==='data' ? 
  list.map((o, k) =>
    <option key={k} value={o.data[id_key]}>{o.data[label_key]}</option>
  )
  :
  list.map((o, k) =>
    <option key={k} value={o[id_key]}>{getLocale(o, user_locale, label_key)}</option>
  )

const InputTags = ({isObject, tagList, onTagChange, name, onDelete, updateOption, TagCheckBox, required}) =>{
  let [tag, setTag] = useState(tagList || []);

  useEffect(() => {
    if(tagList && tagList.length > 0){
      setTag(tagList)
    }
  }, [tagList])

  const addObj = (event) => {
    if (event.key === 'Enter') {
      if(event.target.value==null)return;
      tag.push({'label':event.target.value, 'client_id':tag.length+1})
      setTag([...tag]);
      event.target.value = null;
      onTagChange(tag, name)
    }
  }

  const removeObj = (e, item) => {
    e.preventDefault();
    if(item.id){
      tag = tag.filter(i => i.id !== item.id)
      setTag([...tag])
      onDelete(item)
    }else{
      let idx = tag.findIndex((i) => i.client_id == item.client_id)
      if (idx > -1) {
        tag = (tag.filter(i => i.client_id !== item.client_id))
        setTag([...tag])
      } 
    }
    onTagChange(tag, name)
  }

  const add = (event) => {
    if (event.key === 'Enter') {
      if(event.target.value==null)return;
      tag.push(event.target.value)
      setTag([...tag]);
      event.target.value = null;
      onTagChange(tag, name)
    }
  }

  const remove = (e, item) => {
    e.preventDefault();
    if(item){
      tag = tag.filter(i => i !== item)
      setTag([...tag])
      onDelete(item)
    }
    onTagChange(tag, name)
  }

  const tagsLabel = tag.map((t, k) =>  
    <span className="tag" key={k}>
      {TagCheckBox && <TagCheckBox option={t}/>}
      <span className="font-16">{t.label}&nbsp;&nbsp;</span>
      <a href="#" title="Removing tag" onClick={e => remove(e, t)}>x</a>
    </span>
  )

  const tags = tag.map((t, k) =>  
    <span className="tag" key={k}>
      {TagCheckBox && <TagCheckBox option={t}/>}
      <span>{t}&nbsp;&nbsp;</span>
      <a href="#" title="Removing tag" onClick={e => removeObj(e, t)}>x</a>
    </span>
  )

  return(
  	<div id="tags_input" className="tagsinput">
      {isObject?tagsLabel:tags}
      <input name={name} placeholder="add a tag" onKeyDown={isObject?addObj:add} required={required}/>
      <div className="tags_clear"></div>
    </div>  
  )

}

const DateTimePicker = ({id, name, onChange, dateFormat, defaultValue, placeholder, className='', required}) => {
  dateFormat = dateFormat || "YYYY-MM-DD"

  const [value, setDate] = useState()

  useEffect(()=>{
    setDate(defaultValue?moment(defaultValue).toDate():null)
  }, [defaultValue])

  const onDateChanged = (value) =>{
    setDate(value)
    onChange(name, moment(value).format(dateFormat), value)
  }
  
  return(
    <>
      <input type="hidden" name={name} required={required}/>
      <DatePicker dateFormat="yyyy-MM-dd" selected={value} 
        onChange={date => onDateChanged(date)} placeholderText={placeholder}
        peekNextMonth showMonthDropdown showYearDropdown 
        dropdownMode="select" className={className}/>
    </>
  )
}

const TDTextInput = ({rowData, column, handleChange, clientId}) =>{
  
  const onInputChange = (e) =>{
    //rowData[column.client_id] = e.target.value
    handleChange(e.target.value, clientId)
  }

  return(
    <input className="form-input form-input-gray form-input-full" 
      onChange={e=>onInputChange(e)}
      defaultValue={rowData[clientId]}/>  
  )
}

const TDInputSelect = ({label, screen, currentLocale, isObject, model, rowData, column, handleChange, clientId, options=[], dataTypes, formFn, inputAttributes}) =>{
  
  const onInputChange = (item, itemIdx) =>{
    const value = (typeof item === 'object'?item.value:item)
    //rowData[column.client_id] = options[itemIdx].label
    //rowData[column.client_id+'_dsl_id'] = options[itemIdx].pk_id
    //rowData[column.client_id+'_id'] = item
    
    //rowData[clientId] = item.value
    handleChange(value, clientId)
    if(formFn.ds)formFn.ds[clientId] = item;
  }

  const exists = (item) => {
    return rowData[clientId] == item
  }

  return(
    <CTSelect id={`c_${clientId}`} options={options} dataTypes={dataTypes} 
      model={rowData[clientId]} screen={screen} isObject={isObject} 
      handleChange={onInputChange} exists={exists} label={label}
      currentLocale={currentLocale} inputAttributes={inputAttributes}
    />
  )

  /*
    <select className="form-control input-sm" 
      onChange={e=>onInputChange(e)}
      defaultValue={rowData[column.key+'_id']}> 
      <option value=''>None</option> 
      <OptionsObjList list={options} id_key='value' label_key='label'/>
    </select>
  */
}

const TDInputRadio = ({label, screen, currentLocale, isObject, model, rowData, column, handleChange, clientId, options=[], dataTypes}) =>{
  
  const onInputChange = (value, itemIdx) =>{
    /*rowData[column.client_id] = options[itemIdx].label
    rowData[column.client_id+'_dsl_id'] = options[itemIdx].pk_id*/
    //rowData[clientId] = value
    handleChange(value, clientId)
  }

  const exists = (item) =>{
    return rowData[clientId] == item.value
  }

  return(
    <RadioObjList list={options} handleChange={onInputChange} exists={exists} model={rowData[column.client_id+'_id']} />
  )
}

const TDInputMultiSelect = ({id, label, rowData, column, readOnly, handleChange, clientId, options=[], screen, valueKey='value'}) =>{
  const listkey = clientId+'_array'
  const [selectedItems, setSelectedItems] = useState(rowData[listkey] || [])
  const [optionList, setOptionList] = useState(options)
  const [optionObjects, setOptionObjects] = useState({})

  const fieldEleRef = useRef()
  const fstListRef = useRef()

  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()

  useEffect(()=>{
    setOptionObjects(options.reduce((obj, item) => (obj[item[valueKey]] = item.label, obj), {}))
  }, [])

  const setter = (list) =>{
    rowData[listkey] = list
    rowData[clientId] = ''
    options.forEach((o)=>{
      rowData[clientId] += list.indexOf(o[valueKey])>-1?`${o.label},`:'';
    })

    /*rowData[column.client_id+'_dsl_id_array'] = options
    .filter(o => list.indexOf(o[valueKey])>-1)    
    .map(o => o.pk_id) */

    rowData[clientId] = rowData[clientId].slice(0, -1)
    handleChange(rowData[clientId], clientId)
    setSelectedItems(list)
  }

  const toggle = (item) =>{
    FormHelper.toggleListString(item[valueKey], selectedItems, setter)

    /*const idx = selectedItems.findIndex((i)=> i == item.value)
    if(idx > -1) {
      selectedItems.splice(idx, 1)
    }else {
      selectedItems.push(item.value)
    }*/
    
  }

  const exists = (item) => FormHelper.existsListString(item, selectedItems)

  const bindWindowClickEvent = () =>{
    if(screen.xs == true){
      toggleMenuModal()
    }else{
      fstListRef.current.classList.remove("hidden")
      window.addEventListener('click', eventFunction)  
    }
  }

  const eventFunction = (event) => {
    try{
      if(fieldEleRef.current.contains(event.target)){
        
      }else{
        fstListRef.current.classList.add("hidden")
        window.removeEventListener("click", eventFunction)
        //forceUpdate();
      }
    }catch(e){
    
    }
  }

  const search = (term) =>{
    if(term.length>0){
      term = term.toLowerCase()
      const result = options.filter(item => item.label.toLowerCase().indexOf(term) > -1);
      setOptionList([...result])  
    }else{
      setOptionList([...options])
    }
  }

  const selectedItemList = selectedItems.map((i, k) =>  
    <div key={k} className="fstChoiceItem font-15">
      {optionObjects[i]}
      <span className="fstChoiceRemove" type="button" onClick={e => toggle({value:i})}>×</span>
    </div>
  )

  return(
    <Fragment>
      <div className={`form-input-gray fstElement fstMultipleMode ${readOnly && 'input-readonly'}`} 
        ref={fieldEleRef} id={clientId+ '_container'}>
        <div className="fstControls" onClick={e => bindWindowClickEvent(e)}>
          {selectedItemList}
          <input onChange={e=>search(e.target.value)} className="fstQueryInput" placeholder="Tap here to select" />
        </div>
        <div className="fstResults hidden" ref={fstListRef} id={clientId+ "_result_set"}>
          <TDMultiOptions options={optionList} toggle={toggle} valueKey={valueKey} 
          exists={exists} clientId={column.client_id} showInputSearch={false}/>
        </div>
      </div>
      
      {isMenuOpen && 
        <GenericModal
          component={TDMultiOptions}
          showInputSearch={true}
          search={search}
          title={label}
          isOpen={isMenuOpen}
          toggleModal={toggleMenuModal}
          options={optionList} 
          toggle={toggle} 
          exists={exists} 
          clientId={column.client_id}
        />
      }
    </Fragment>
  )

}

const TDMultiOptions = ({options, toggle, exists, clientId, search, showInputSearch, valueKey}) =>{
  return(
    <div className="p-5 font-15">
      {showInputSearch &&
        <input className="form-control m-b-10"  autoFocus
        onChange={e=>search(e.target.value)} 
        placeholder="Type here to find available choices" />
      }
      <CheckBox list={options} toggle={toggle} exists={exists} 
        name={clientId} onToggleReturnObject={true}
        labelKey="label" valueKey={valueKey} />
    </div>
  )
}

const CTSelect = ({id, label, options, dataTypes, model, screen, forceUpdate, readOnly, isObject, handleChange, exists, currentLocale, inputAttributes}) =>{
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()
  const [objList, setObjList] = useState({})
  const fieldEleRef = useRef()
  const fstListRef = useRef()

  useEffect(()=>{
    if(options){
      setObjList(options.reduce((obj, item) => (obj[item.value] = getLocale(item, currentLocale), obj), {}))
    }
  }, [])

  const bindWindowClickEvent = () =>{
    if(screen.xs == true){
      toggleMenuModal()
    }else{
      fstListRef.current.classList.remove("hidden")
      window.addEventListener('click', eventFunction)  
    }
  }

  const eventFunction = (event) => {
    try{
      if(fieldEleRef.current.contains(event.target)){
        
      }else{
        hideList()
      }
    }catch(e){
    
    }
  }

  const hideList = () =>{
    if(screen.xs == true){
      toggleMenuModal()
    }else{
      fstListRef.current.classList.add("hidden")
      window.removeEventListener("click", eventFunction)
    }
  }

  const onSelect = (option, i) =>{
    handleChange(option, i)
    hideList()
  }

  return(
    <Fragment>
      <div className={readOnly ? 'input-readonly':''} ref={fieldEleRef} id={id+ '_container'}>
        {(isObject) ? 
          <CTOptionsObjList objList={objList} list={options} exists={exists} 
            handleChange={onSelect} currentLocale={currentLocale}
            model={model} bindWindowClickEvent={bindWindowClickEvent} 
            inputAttributes={inputAttributes} id={id}
            fstListRef={fstListRef} dataTypes={dataTypes}/> 
            : 
          <CTOptionsStringList list={options} exists={exists} id={id}
            handleChange={onSelect} model={model} 
            bindWindowClickEvent={bindWindowClickEvent} 
            inputAttributes={inputAttributes} 
            fstListRef={fstListRef}/>
        }   
      </div>

      {isMenuOpen ? 
        <GenericModal
          component={CTSingleSelectPopup}
          handleChange={handleChange} 
          exists={exists}
          isObject={isObject}
          options={options}
          dataTypes={dataTypes}
          title={label}
          isOpen={isMenuOpen}
          toggleModal={toggleMenuModal}
          currentLocale={currentLocale}/>
        :null
      }
    </Fragment>
  )
}

const CTOptionsStringList = ({id, list, model, exists, handleChange, bindWindowClickEvent, inputAttributes, fstListRef}) => {
  
  let [optionList, setOptionList] = useState(list)
  let [inputLabel, setInputLabel] =  useState(model)

  const search = (term) =>{
    setInputLabel(term)
    if(term.length>0){
      term = term.toLowerCase()
      const result = list.filter(item => item.toLowerCase().indexOf(term) > -1)
      setOptionList([...result])  
    }else{
      setOptionList([...list])
    }
  }

  const selectableItems = optionList && optionList.map((o, k) =>{
    return(  
      <li key={k} className={exists(o)?'dd-selected':''} onClick={e => handleChange(o)} title={o}>
        {o}
      </li>
    )
  })
    
  return(
    <>
      <div className="dropdown" onClick={e=>bindWindowClickEvent(e)}>
        <input {...inputAttributes} defaultValue={inputLabel} onChange={e=>search(e.target.value)} />
        <span className="dd-button-caret"/>
      </div>
      <div className="dd-menu scroll-sm hidden" ref={fstListRef} id={id+ "_result_set"}>
        {selectableItems}
      </div>
    </>
  )
}

const CTOptionsObjList = ({id, dataTypes, list, objList, model, exists, handleChange, bindWindowClickEvent, inputAttributes, currentLocale, fstListRef}) => {

  let [optionList, setOptionList] = useState(list)
  let [inputLabel, setInputLabel] =  useState(objList[model])

  useEffect(()=>{
    setInputLabel(objList[model])
  }, [model])

  const search = (term) =>{
    setInputLabel(term)
    if(term.length>0){
      let result = list.filter(item => item.label.toLowerCase().indexOf(term) > -1);
      setOptionList(result)  
    }else{
      setOptionList(list)
    }
  }

  const onChange = (o, k) => {
    setInputLabel(o.label)
    handleChange(o, k)
  }

  const selectableItems = optionList.map((o, k) => { 
    const itemHeight = o?.images?{height: '120px'}:{};

    return(
      <li key={k} style={itemHeight} className={exists(o.value)?'dd-selected':''}
        onClick={() => onChange(o, k)} title={o.description}>
        <div>
          {o[currentLocale]?.label || o.label}
          {o.images ? <ImageFlexList styleName="radio-img" list={o.images}/> : null}
        </div>
      </li>
    )
  })

  return(
    <>
      <div className="dropdown" onClick={e=>bindWindowClickEvent(e)}>
        <input {...inputAttributes} value={inputLabel} 
        onChange={e=>search(e.target.value)} />
        <span className="dd-button-caret"/>
      </div>
      <ul className="dd-menu scroll-sm hidden"  ref={fstListRef} id={id+ "_result_set"}>
        {selectableItems}
      </ul>
    </>
  )
}

const CTSingleSelectPopup = ({dataTypes, handleChange, exists, isObject, options, currentLocale}) =>{
  let [optionList, setOptionList] = useState(options);
  let itemHeight = dataTypes && dataTypes.image_url?{height: '100px'}:{};
  
  const search = (term) =>{
    if(term.length>0){
      term = term.toLowerCase()
      const result = isObject ? 
        options.filter(item => item.label.toLowerCase().indexOf(term) > -1)
        :
        options.filter(item => item.toLowerCase().indexOf(term) > -1) 
      setOptionList(result)  
    }else{
      setOptionList(options)
    }
  }

  const optionStringItems = optionList ? optionList.map((o, k) =>  
    <span key={k} className={`fstResultItem ${exists(o)?'fstSelected':''} `}
     onClick={e => handleChange(o, k)} title={o}>
      {o}
    </span>
  ):null;

  const optionObjectItems = optionList.map((o, k) =>  
    <span key={k} style={itemHeight} 
      className={`fstResultItem ${exists(o.value)?'fstSelected':''} `}
      onClick={e => handleChange(o, k)} title={o.description}>
        <div className="font-16">
          {o[currentLocale]?.label || o.label}
          {itemHeight && o.image_url && <img src={Cs.getIconByType(o.image_url)} alt="img" className="w-100 pull-right" style={{height:"60px"}} alt="img"/>}
        </div>
    </span>
  )

  return(
    <>
      <input className="form-control m-b-5" autoFocus onChange={e=>search(e.target.value)} 
      placeholder="Type here to find available choices" />
      {isObject?optionObjectItems:optionStringItems}
    </>
  )

}

const LocaleLabel = ({dataSource, currentLocale}) => {
  if(!dataSource)
    return null

  return dataSource[currentLocale]?.label || dataSource.label
}

let LocaleLabelList = ({list, currentLocale, css}) => list && list.length>0 ? list.map((ds, i) =>{
  const props = {
    className:css?css:'',
  }

  return(
    <span {...props} key={i}>
      <LocaleLabel dataSource={ds.data} currentLocale={currentLocale}/>
    </span>
  )  
}):null

export {LocaleLabel, LocaleLabelList, CTSelect, TDInputSelect, TDInputRadio, TDTextInput, TDInputMultiSelect, CheckBox, OptionsObjList, InputTags, Radio, CheckBoxObjList, DateTimePicker}