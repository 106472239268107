import env from '../../env'
import BaseService from "../../services/BaseService"
import axios from 'axios'

class OrganizationPartnershipService extends BaseService {
	
	getPartnershipOrganizations(req){
      return new Promise((resolve, reject) => {
        axios.get(env.health_backend+'health/partnership_organizations', {params: req}).then((res) => {
	      // handle success
	      resolve(res)
	    }).catch((e) => {
	      // handle error
	      resolve(e.response)
	    })
      })
  	}

}

export default new OrganizationPartnershipService('health_backend', 'health/organization_partnerships')