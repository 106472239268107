import React, {Fragment, useState, useContext, useEffect} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../hooks/useStyle';
import useSort from '../../hooks/useSorting';
import SortDirection from '../Common/SortDirection'
import Cs from "../../services/CommonService";
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView";
import AuthenticationService from "../../services/AuthService";
import {usePageTitle} from "../../hooks/pageHead"
import {AuthContext} from "../../contexts/Authentication/AuthStateProvider"

let requestParams = {}
const LoginHistory = (props) => {

  usePageTitle('Login List')

  const { state: {screen, user: currentUser }} = useContext(AuthContext)
  
  const [loading, setLoading] = useState(true)
  const [history, setHistory] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState({})

  useEffect(() => {
    requestParams = {
      page: currentpage,
      per_page: 15,
    }

    getLoginHistory()
  }, [props.searchString, currentpage])

  let getLoginHistory = () => {
    setLoading(true)
    AuthenticationService.getLoginHistory(requestParams).then((res) => {
      if (res.status === 200) {
        setHistory(res.data.login_historys)
        setLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getLoginHistory);
  
  const TableData = ({}) => history && history.map((o, k) =>
    <TR key={k}>
      <TD className="fw500 text-gray">{o.id}</TD>
      <TD>{Cs.formatUpdateDate(o.updated_at)}</TD>
      <TD>{o.geo_data?.address?.city}</TD>
      <TD>{o.geo_data?.address?.country}</TD>
    </TR>
  )

  return (
    <div id="ct">
      <div className="app_content">
        <div className="app-header flex justspacebetween m-t-15">
          <div className="flex coldir">
            <div className="app-title m-r-20">Login History</div>
            <div className="font-14">{requestParams.totalItems} Items</div>
          </div>
        </div>

      <div className="app-content-section m-t-30">
        {loading ? 
          <div className="spinner"/>
          :
          <div className="table-fix-head">
            <Table className="shadow-small">
              <THead>
                <TR>
                  <TH onClick={()=>sort('id')}>Id
                    <SortDirection sort_column={requestParams.sort_column} 
                    column="record_id" reverse={requestParams.reverse}/> 
                  </TH>
                  <TH>Date</TH>
                  <TH>City</TH>
                  <TH>Country</TH>
                </TR>
              </THead>
              <TBody>
                <TableData/>
              </TBody>
            </Table>
          </div>
        }
      </div>
    </div>
    </div>
  )

}

export default LoginHistory;