import env from '../../env';
import BaseService from "../../services/BaseService";

class SubscriptionAccessesService extends BaseService {
   /*
	@params
	id: subscription.id
   */	
   /*getSubscriptionAccess(id, req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.health_backend}/get_subscription_access/${id}`, {params: req}).then((res) => {
        // handle success
        resolve(res)
      }).catch((e) => {
        // handle error
        resolve(e.response)
      })
    })
  }*/

}

export default new SubscriptionAccessesService('health_backend', 'health/subscription_accesses');