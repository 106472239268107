import React, { Fragment, useState, useContext, useEffect } from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'
import env from '../../env'
import AuthenticationService from "../../services/AuthService"
import CommonService from "../../services/CommonService"
import Tabs, { TabPane } from 'rc-tabs'
import ImageEditor from "../ImageEditor/ImageEditor"
import useModal from "../../hooks/useModal"
import GenericModal from "../Modals/GenericModal"
import { Link } from "react-router-dom"

let profileImage = 'images/avatar-2.jpg'
let error = {}

export const UserProfile = (props) => {
   useStyle('/css/user-preference-page.css', true)

   const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
   const [user, setUser] = useState({})
   const [loading, setLoading] = useState(false)

   /*currentUser.setting = {
      'notify_daily_interactions': false,
      'notify_invite': true,
      'notify_journey_interactions': true,
      'notify_marketing': true,
      'notify_reminder': true,
      'notify_requests': true,
      'notify_share': true,
      'notify_system_alert': true
   }*/
   const [settings, setSetting] = useState(currentUser.setting || {})
   //:FIXME REMOVE FLAG

   /*if(currentUser.profile_image_url){
      profileImage = env.file_url+currentUser.profile_image_url;
   }*/

   const { isOpen:isImageEditorOpen, toggleModal:toggleImageEditorModal } = useModal();

   useEffect(()=>{
    const $ = document.querySelector.bind(document);
    const $All = document.querySelectorAll.bind(document);

    /*$("#menu").onclick = () => {
      $("#menu").classList.toggle("rotate");
      $(".nav-page1").classList.toggle("transform");
      $(".nav-page2").classList.toggle("transform");
      $(".menu-line1").classList.toggle("rotate1");
      $(".menu-line2").classList.toggle("rotate2");
    };*/

    function Tabs() {
      var bindAll = function () {
        var menuElements = document.querySelectorAll("[data-tab]");
        for (var i = 0; i < menuElements.length; i++) {
          menuElements[i].addEventListener("click", change, false);
        }
      };

      var clear = function () {
        var menuElements = document.querySelectorAll("[data-tab]");
        for (var i = 0; i < menuElements.length; i++) {
          menuElements[i].classList.remove("active");
          var id = menuElements[i].getAttribute("data-tab");
          document.getElementById(id).classList.remove("active");
        }
      };

      var change = function (e) {
        e.preventDefault()
        clear();
        e.target.classList.add("active");
        var id = e.currentTarget.getAttribute("data-tab");
        document.getElementById(id).classList.add("active");
      };

      bindAll();
    }

    var connectTabs = new Tabs();  
   }, [])

   const handleUserNameChange = (e) =>{
      user[e.target.name] = e.target.value;
      setUser({...user})
   }

   const handlePasswordChange = (e) =>{
      user[e.target.name] = e.target.value;
      setUser({...user})  
      checkPasswordValid()
   }

   const updatePassword = () => {
      setLoading(true)
      let data = {
        'user': user
      }
      AuthenticationService.updatePassword(data).then((res)=>{
        if (res.status == 200) {
          error.pwsuccess= res.data.message;
        } else if(res.status == 400) {
          error.pwerror = res.data.message;           
        } else {
         error.pwerror = 'Password Reset Failed'; 
        }
        setUser({...user})
        resetError('pwerror')
        setLoading(false)
      })
   }

   const resetError = (name) =>{
      setTimeout(()=>{
        error[name] = ''
        setUser({...user})
      }, 3000);
  }

  const checkPasswordValid = () =>{
    error.is_password_valid = false;
    if(user.password && user.new_password && user.password_confirmation){
      if(user.new_password === user.password_confirmation){
        error.is_password_valid = true;
      }
    }
  }

  const callback = (e) => {
    console.log(e)
  }

  const updateUserName = () => {
    let req = {'name':user.name}
    error = {};
    AuthenticationService.updateUserName(req).then((res)=>{
      if(res.status==200){
        error.username = 'Updated Successfully';
      }else{
        error.username = 'Updated Failed';
      }
      setUser({...user})
      resetError('username') 
    })
  }

  const profileImageUpload = (event, ele, blob) => {
    try {
      let file = null; 
      if(event){
        let files = event.target.files;
        let FileSize = files[0].size / 1024 / 1024; // in MB
        if (FileSize > 5) {
          event.target.value = null;
          return;
        } else {
          file = files[0]
        }
      }else{
        file = blob
      }

      if (file) {
        AuthenticationService.updateProfileImage(file).then((res) =>{
          if (res.status == 422) {
          
          }else if(res.status == 201) {
            toggleImageEditorModal()
            currentUser.profile_image = res.data.file_upload.url;
            ele.value = null;
            dispatch({
              type: "UPDATE",
              payload: {'user': currentUser},
            })
          }
        })
      }
    } catch (e) {
      console.log(e);
    }
  }

  const updateSetting = (field) =>{
    settings[field] = !settings[field];
    CommonService.updateSetting(settings).then((res)=>{
      if(res.status==204){
        currentUser.settings = res.data.setting;
        dispatch({
          type: "UPDATE",
          payload: {'user':currentUser},
        })   
      }
    })
  }

  return (
    <section id="ct" className="app-main">
         <div className="app-main__outer" id="appMain">
           <div className="app_content">
             <div className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20-xs">
               <div className="flex coldir fulwid p20">
                 <div className="flex alignstart justspacebetween">
                   <div>
                     <div className="app-title m-r-20">User preference page</div>
                     <p className="m-t-10">Org Master</p>
                   </div>
                   <div className="flex aligncenter hide-xs">
                      <Link id="nav-welcome" 
                        to="/health/table"
                        className="icon-gray-cirle border-rounded-30 flex aligncenter justifycenter m-l-10">
                          <i className="fas fa-bars"/>
                      </Link>
                      <Link id="nav-welcome" 
                        to="/health/table"
                        className="icon-gray-cirle border-rounded-30 flex aligncenter justifycenter m-l-10">
                          <img src="./images/home-icon-black.png" alt="" />
                      </Link>
                   </div>
                 </div>
               </div>
             </div>
             {loading?<div className="spinner"/>:null}
             <div className="app-content-section m-t-30 m-t-0-xs">
               <div className="row m-t-30 m-t-0-xs">
                 <div className="col-md-3">
                   <div className="bg-white border-rounded-10 p20 card-min-ht">
                     <ul className="flex coldir bordered-tab">
                       <li className="hidden">
                          <a href="true" data-tab="a" className="b-nav-tab">
                           About me
                          </a>
                       </li>
                       <li>
                         <a href="true" data-tab="b" className="b-nav-tab active">
                           Password Change
                         </a>
                       </li>
                       <li className="hidden">
                         <a href="true" data-tab="c" className="b-nav-tab">
                           Notification
                         </a>
                       </li>
                       <li className="hidden">
                         <a href="true" data-tab="d" className="b-nav-tab">
                           Whats New
                         </a>
                       </li>
                       <li className="hidden">
                         <a href="true" data-tab="e" className="b-nav-tab">
                           Message
                         </a>
                       </li>
                     </ul>
                   </div>
                 </div>
                 <div className="col-md-9">
                   <div id="a" className="b-tab">
                     <div className="bg-white border-rounded-10 p20 card-min-ht">
                       <h2 className="m-b-20 m-t-20-xs">About Me</h2>
                       <div className="flex aligncenter justspacebetween">
                       <div className="flex coldir m-b-20">
                          <label>
                            Add/Edit Profile Image
                            <small>(size should be 100 x 100)</small>
                          </label>
                          <img onClick={e=>toggleImageEditorModal(e)}
                            title="Add/Edit Profile Image"
                            style={{ width: "64px" }}
                            src={profileImage}
                          />
                          <h3 className="m-b-5">{currentUser.name}</h3>
                        </div>
                        <a href="#" className="btn btn-stroke-gray">
                           {" "}
                           Edit{" "}
                         </a>
                       </div>
                       <h3 className="m-t-30 m-b-30">Basic Information</h3>
                       <div className="flex coldir m-b-20">
                         <label className="form-label m-b-10"> Email </label>
                         <input
                           type="text"
                           className="form-input form-input-gray"
                           defaultValue={currentUser.email}
                           readOnly/>
                       </div>
                       <div className="flex coldir m-b-20">
                         <label className="form-label m-b-10"> User Name </label>
                         <input
                           type="text"
                           className="form-input form-input-gray"
                           onChange={e => handleUserNameChange(e)} 
                           defaultValue={currentUser.name}/>
                       </div>
                        {error.username && 
                          <div className="form-group error">
                            <label>{error.username}</label>
                          </div>
                        }
                        
                        {user.name &&   
                          <button type="button" onClick={e=>updateUserName(e)} className="butn btn-inverse">Update</button>
                        }                      
                     </div>
                   </div>
                   <div id="b" className="b-tab active">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <h2 className="m-b-20">Password Change</h2>
                      <div className="flex coldir m-b-20">
                        <label className="form-label m-b-10">Old Password</label>
                        <input placeholder="Old Password" name="password" onChange={e => handlePasswordChange(e)} className="form-input form-input-gray"/>    
                      </div>

                      <div className="flex coldir m-b-20">
                        <label className="form-label m-b-10">New Password</label>
                        <input placeholder="New Password" name="new_password" onChange={e => handlePasswordChange(e)} className="form-input form-input-gray"/>    
                      </div>

                      <div className="flex coldir m-b-20">
                        <label className="form-label m-b-10">Re-enter New Password</label>
                        <input placeholder="Retype New Password" name="password_confirmation" onChange={e => handlePasswordChange(e)} className="form-input form-input-gray"/>    
                      </div>

                      {(error.pwsuccess || error.pwerror) && 
                        <div className="flex coldir m-b-20 error">
                          <label>{error.pwsuccess}</label>
                          <label>{error.pwerror}</label>
                        </div>
                      }

                      {error.is_password_valid && 
                        <button type="button" onClick={e=>updatePassword()} className="btn-primary green text-white">Update</button>
                      }   
                        
                      {error.is_password_valid==false && <label className="error">Password Mismatch</label>}

                    </div>
                   </div>
                   <div id="c" className="b-tab">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                    <h2 className="m-b-20">Set your preferences to receive cueTree notifications</h2>

                    <div className="flex coldir m-b-20">
                      <span className="checkbox-container" 
                          onClick={e=>updateSetting('notify_journey_interactions')}>
                          <span className="m-l-35" data-toggle="tooltip" title="Notification sent soon after specified interactions (e.g., a comment, an invitation).">Journey Interactions</span>
                          <input type="checkbox" checked={settings.notify_journey_interactions}/>
                          <span className="checkbox-checkmark"></span>
                      </span>

                      <span className="checkbox-container"
                          onClick={e=>updateSetting('notify_daily_interactions')}>
                          <span className="m-l-35" data-toggle="tooltip" title="Notification sent once a day, summarizing action items applicable to a user.">Daily Digest</span>
                          <input type="checkbox" checked={settings.notify_daily_interactions}/>
                          <span className="checkbox-checkmark"></span>
                      </span>

                      <span className="checkbox-container event-none"
                          onClick={e=>updateSetting('notify_weekly_report')}>
                          <span className="m-l-35" data-toggle="tooltip" title="Notification sent once a week, summarizing action items applicable to a user.">Weekly Report</span>
                          <input type="checkbox" checked={settings.notify_weekly_report}/>
                          <span className="checkbox-checkmark"></span>
                      </span>

                      <span className="checkbox-container event-none"
                          onClick={e=>updateSetting('notify_system_alert')}>
                          <span className="m-l-35" data-toggle="tooltip" title="Notification about the cueTree system, sent for administrative purposes.">Administrative</span>
                          <input type="checkbox" checked={settings.notify_system_alert}/>
                          <span className="checkbox-checkmark"></span>
                      </span>

                      <span className="checkbox-container event-none"
                          onClick={e=>updateSetting('notify_marketing')}>
                          <span className="m-l-35" data-toggle="tooltip" title="Notification concerning products and services that may be of interest to users.">Marketing</span>
                          <input type="checkbox" checked={settings.notify_marketing}/>
                          <span className="checkbox-checkmark"></span>
                      </span>
                    </div>
                    </div>
                   </div>
                   <div id="d" className="b-tab">
                      <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <h2 className="m-b-20 m-t-20-xs">Whats New</h2>
                      </div>
                   </div>
                   <div id="e" className="b-tab">
                    <div className="bg-white border-rounded-10 p20 card-min-ht">
                      <h2 className="m-b-20 m-t-20-xs">Messages</h2>
                    </div>
                                         
                    </div>
                 </div>
               </div>
             </div>
           </div>
         </div>
         {isImageEditorOpen && (
            <GenericModal
              component={ImageEditor}
              image={profileImage}
              saveImage={profileImageUpload}
              title="Image Editor"
              isOpen={isImageEditorOpen}
              toggleModal={toggleImageEditorModal}
              minWidth = "200"
              minHeight = "200"
              maxWidth = "200"
              maxHeight = "200"/>
          )}
      </section>   
   )
}

export default UserProfile;