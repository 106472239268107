import React, {useState, useEffect} from "react"
import Cs from '../../../services/CommonService'
import ImageMap from '../../Common/ImageMap'

let HealthImageMap = (props) => {
  const {formId} = props
  const [imageMap, setImageMap] = useState()
  const [form, setForm] = useState()

  useEffect(()=>{
    getFormTemplate()
  }, [])
  
  const getFormTemplate = () =>{
    Cs.getFormTemplateById(488).then((data) => {
      setForm(data.form_template)
      let map = null
      for(let t of  data.form_template?.template_fields){
        map = t?.child_template_fields?.find((f)=> f.component == "image_map")
      }
      setImageMap(map)
    })
  }

  if(!imageMap) return null

  return(
    <ImageMap field={imageMap}/>
  )

}

export default HealthImageMap;