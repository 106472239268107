import React,{Fragment} from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
import ForumList from '../../components/Forums/List/ForumList'
import ForumForm from './Form/ForumForm'
import ForumReplyForm from '../../components/Forums/Form/ForumReplyForm'

const ForumRouter =  (props) => (
  <Fragment>  
    <PrivateRoute path="/forums" component={ForumList} exact />
    <PrivateRoute path="/forums/create/:id?" component={ForumForm} exact/>
    <PrivateRoute path="/forums/reply/:id?" component={ForumReplyForm} exact/>
  </Fragment>
)

export default ForumRouter;