import React, {Fragment,useState} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../hooks/useStyle';
import useSort from '../../../hooks/useSorting';
import SortDirection from '../../Common/SortDirection'
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from '../../Common/arrayMove';
import Cs from "../../../services/CommonService";
import {PillDsList,PillDsField} from '../../HealthJourney/UserRoles/NotificationPill'
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../Common/ImageIcons";

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt"></span>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
})

const ScreeningEventTable = ({events, requestParams, selectedEvent,getScreeningEvent, toggleDeleteModal}) => {

  const {sort} = useSort(requestParams, getScreeningEvent);
  
  const [currentAssessment, setCurrentAssessment] = useState();

  const TableData = ({events}) => events && events.map((o, k) =>
    <TR key={k}>
      <TD className="text-gray" data-title="Id">{o.record_id}</TD>
      <TD className="fw500" data-title="Name">{o.data.name}</TD>
      <TD data-title="Created Date">{Cs.formatUpdateDate(o.updated_at)}</TD>
      <TD data-title="Health Type">{o.data_source.type_health_condition?.label}</TD>
      <TD data-title="Organizer Name">{o.data.organizer_name}</TD>
      <TD data-title="Created By">{o.user?.name}</TD>
      <TD data-title="Options" >
        <div className="flex aligncenter">
          <LinkFontAwesomeIcon id="edit_screening_events" tooltip="Edit screening events" link={`/screening_events/create/${o.form_id}/${o.id}`} css="fas fa-edit text-white">
          </LinkFontAwesomeIcon>
          <LinkDeleteIcon id="delete_screening_events" tooltip="Delete screening events" onSelect={(e)=>toggleDeleteModal(e)}>
          </LinkDeleteIcon>
        </div>
      </TD>
    </TR>
  )

  return (
    <div className="col-xs-12 m-t-10">
      <SortableContainer distance={1} lockAxis="y" useDragHandle>
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH onClick={e=>sort('record_id')}>Id
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('data.name')}>Name
                <SortDirection sort_column={requestParams.sort_column} column="record_title" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('updated_at')}>Updated Date 
                <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('data.type_health_condition')}>Health Type
                <SortDirection sort_column={requestParams.sort_column} column="data.type_health_condition" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('data.organizer_name')}>Organizer Name
                <SortDirection sort_column={requestParams.sort_column} column="data.organizer_name" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('created_by')}>Created By
                <SortDirection sort_column={requestParams.sort_column} column="created_by" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('created_at')}>Options
                <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/> 
              </TH>
            </TR>
          </THead>
          <TBody>
            <TableData events={events} />
          </TBody>
        </Table>
      </SortableContainer>
    </div>
  )

}

export default ScreeningEventTable;