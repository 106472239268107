import React, {useState, createContext} from "react";

export const AuthContext = createContext();

let screen = {
  xs:false,
  sm:false,
  md:false,
  lg:false
}

function getBreakpoint() {
  const w = window.innerWidth;
  screen.width = w;
  screen.height = window.innerHeight;
  (w < 768) ? screen.xs=true : ((w < 992) ? screen.sm=true : ((w < 1200) ? screen.md=true : screen.lg=true));
}
getBreakpoint();
//window.addEventListener("resize", getBreakpoint); 

export const initialState = {
  isAuthenticated: false,
  user: {},
  token: null,
  showPageLoader: true,
  screen: screen
};