import React, { useState, Fragment, useEffect } from "react"
import { Link } from "react-router-dom"
import useStyle from "../../../../hooks/useStyle"
import Cs from "../../../../services/CommonService"
import Title from "../../GenericPortalPage/Title"
import HealthCategoryService from "../../HealthCategoryService"
import querystringify from "querystringify"
import DataSourceMenu from "../../../Modals/DataSourceMenu"
import useModal from "../../../../hooks/useModal"
//import ActivityList from "../Activities/List/ActivityList"
import GenericModal from "../../../Modals/GenericModal"
import PortalSideMenu from "../../GenericPortalPage/PortalSideMenu"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import Pagination from "../../../Pagination";
import ReactTooltip from "react-tooltip";
import {GrowthSpanField} from "../../../HealthJourney/UserRoles/NotificationPill"

let child_data = null
let child = {data:{}}
let dsPath = null
let selectedActivity = null
let requestParams = null

const InfantPath = (props) => {
  useStyle('timeline')
  useStyle('path')
  let queryParam = querystringify.parse(props.location.search)

  let params = props.match.params;
  let project = props.location.state;

  const [journey, setJourney] = useState()
  const [timeline, setTimeLine] = useState([])
  const [pagemeta, setPagemeta] = useState({})
  const [currentpage, setCurrentpage] = useState(1)

  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal} = useModal()
  const { isOpen: isActivityOpen, toggleModal: toggleActivityModal} = useModal()
  const { isOpen: isSideNavOpen, toggleModal: toggleOpenSideNav} = useModal()

  useEffect(() => {
    requestParams = {
      page: currentpage,
      per_page: 15,
      search: props.searchString,
      journey_profile_id:params.health_id,
      sort_direction: "desc",
      data_source_params:{
        "data_source_id": [3614]
      }
    }

    getEventList()
  }, [props.searchString, currentpage])

  let getEventList = () => { 
    HealthCategoryService.timelines(requestParams).then((res)=>{
      if(res.status == 200){
        res = res.data 
        dsPath = Cs.listToObj(res.data_source_list.maternity_child_Path.options, 'child_form')
        setJourney(res.journey_profile)
        setTimeLine([...timeline, ...res.timelines])
        if(res.child){
          child = res.child
          child_data = {
            child_fk_id: child.record_id, 
            dob_baby_formatted_date: child.data.dob_baby_formatted_date, 
            id_mother: child.data.mother_fk_id, 
            baby_age: Cs.getAge(child.data.dob_baby_formatted_date)
          }
        }
        setPagemeta(res.meta)
      }
    })
  }

  if(!journey){
    return null
  }

  const openActivityModal = (e, activity) =>{
    e.stopPropagation()
    selectedActivity = activity
    toggleActivityModal()
  }

  const routeActivity = (d) =>{
    props.history.push(`/infant/activity/form/${params.health_id}?activity_form_id=${d.child_form}`, child_data)
  }

  const routeEditActivity = (d) =>{
    props.history.push(`/infant/activity/form/${params.health_id}/${d.notifiable_id}?activity_form_id=${d.form_id}`, child_data)
  }

  const Profile = ({activity}) =>(
    <li>
      <div className="bullet pink"></div>
      <div className="time">
        {Cs.formatUpdateDate(activity.created_at)}
      </div>
      <div className="desc">
        <span className="pill bg-activity p-5 white">{activity.data.mother_name}</span><br/>
        <span className="pill bg-username p-5 white">{activity.user.name}</span>
      </div>
      <div className="anal">
        <span className="pill bg-lgrey white fas fa-eye p-5" onClick={e=>openActivityModal(e, activity)}></span>
        <span className="pill bg-lgrey white fas fa-edit p-5 m-l-15"  onClick={e=>routeEditActivity(activity)}></span>
        <span className="pill bg-lgrey white fas fa-envelope p-5 m-l-15"></span>
      </div>
    </li>
  )
  
  const Child = ({activity}) =>(
    <li>
      <div className="bullet orange"></div>
      <div className="time">
        {Cs.formatUpdateDate(activity.created_at)}
        <br/>
        <span className="pill bg-username p-5 white">{activity.user.name}</span>
      </div>
      <div className="desc">
        <span className="pill bg-activity p-5 white">Child Birth</span><br/>
        <GrowthSpanField data={activity.data_source?.height?.label} note={activity.data.height_note} unit="cms"/>
        <GrowthSpanField data={activity.data_source?.weight?.label} note={activity.data.weight_note} unit="kgs"/><br/>
        <span className="pill bg-lblue p-5 white">{Cs.formatUpdateDate(activity.data.dob_baby_formatted_date)}</span>
        <span className="pill bg-lblue p-5 white">{activity.data.notes_delivery}</span>
      </div>
      <div className="anal">
        <span className="pill bg-lgrey white fas fa-eye p-5" onClick={e=>openActivityModal(e, activity)}></span>
        <span className="pill bg-lgrey white fas fa-edit p-5 m-l-15"  onClick={e=>routeEditActivity(activity)}></span>
        <span className="pill bg-lgrey white fas fa-envelope p-5 m-l-15"></span>
      </div>
    </li>
  )

  const CFActivity = ({activity, color}) =>(
     <li>
      <div className="bullet green" style={{border:color}}></div>
        <div className="time">
          {Cs.formatUpdateDate(activity.created_at)}<br/>
          <span className="pill bg-username p-5 white">{activity.user.name}</span>
        </div>
        <div className="desc">
        <span className="pill bg-activity p-5 white">CF Record</span><br/>
        <GrowthSpanField data={activity.data_source?.baby_height?.label} note={activity.data.height_note} unit="cms"/>
        <GrowthSpanField data={activity.data_source?.baby_weight?.label} note={activity.data.weight_note} unit="kgs"/><br/>
        <span className="pill bg-lblue p-5 white">{activity.data.notes}</span>
        <div className="people">
          <img src="/images/avatar-2.jpg" alt="" />
          <img src="/images/avatar-2.jpg" alt="" />
          <img src="/images/avatar-2.jpg" alt="" />
        </div>
      </div>
      <div className="anal">
        <span className="pill bg-lgrey white fas fa-eye p-5" onClick={e=>openActivityModal(e, activity)}></span>
        <span className="pill bg-lgrey white fas fa-edit p-5 m-l-15"  onClick={e=>routeEditActivity(activity)}></span>
        <span className="pill bg-lgrey white fas fa-envelope p-5 m-l-15"></span>
      </div>
    </li>  
  )

  const BfActivity = ({activity, color}) =>(
    <li>
      <div className="bullet pink" style={{border:color}}></div>
      <div className="time">
        {Cs.formatUpdateDate(activity.created_at)}<br/>
        <span className="pill bg-username p-5 white">{activity.user.name}</span>
      </div>
      <div className="desc">
        <span className="pill bg-activity p-5 white">BF Check</span><br/>
        <span className="pill bg-activity p-5 white">Crawl Delivery - {activity.data_source?.crawl_at_delivery?.label}</span><br/>
        <span className="pill bg-lblue p-5 white">{activity.data.notes}</span>
      </div>
      <div className="anal">
        <span className="pill bg-lgrey white fas fa-eye p-5" onClick={e=>openActivityModal(e, activity)}></span>
        <span className="pill bg-lgrey white fas fa-edit p-5 m-l-15"  onClick={e=>routeEditActivity(activity)}></span>
        <span className="pill bg-lgrey white fas fa-envelope p-5 m-l-15"></span>
      </div>
    </li>
  )

  const GrowthActivity = ({activity, color}) =>(
    <li>
      <div className="bullet pink" style={{border:color}}></div>
      <div className="time">
        {Cs.formatUpdateDate(activity.created_at)}
        <br/>
        <span className="pill bg-username p-5 white">{activity.user.name}</span>
      </div>

      <div className="desc">
        <span className="pill bg-activity p-5 white">Growth Record</span><br/>
        <GrowthSpanField data={activity.data_source?.baby_weight?.label} note={activity.data.height_note} unit="cms"/>
        <GrowthSpanField data={activity.data_source?.height_baby?.label} note={activity.data.weight_note} unit="kgs"/><br/>
        <div className="timeline timeline-3">
          <ul className="p-0">
            <StepActivityCard stepGrowthActivity={activity.step_growth_activity}/>
          </ul>
        </div>
      </div>  
      <div className="anal">
        <span className="pill bg-lgrey white fas fa-eye p-5" onClick={e=>openActivityModal(e, activity)}></span>
        <span className="pill bg-lgrey white fas fa-edit p-5 m-l-15"  onClick={e=>routeEditActivity(activity)}></span>
        <span className="pill bg-lgrey white fas fa-envelope p-5 m-l-15"></span>
      </div>
    </li>
  )

  const StepActivityCard = ({stepGrowthActivity}) => stepGrowthActivity && stepGrowthActivity.map((t, i) => {
    return(
      <li onClick={e=>openActivityModal(e, t)}>
        <div className="bullet pink"></div>
        <div className="desc-3">
          <h3>{t.notifiable_type}</h3>
        </div>
      </li>
    )
  })

  const ActivityCard = () => timeline.map((t, i) => {
    let c = null
    try{
      if(t.form_id)c = '2px solid '+dsPath[t.form_id].icon_color;
    }catch(e){
      console.log(e)
    } 

    if(t.notifiable_type == 'journey_profile'){
      return <Profile activity={t} key={i}/>
    }else if(t.notifiable_type == 'child'){
      return <Child activity={t} key={i}/>
    }else if(t.notifiable_type == 'cf_record_activity'){
      return <CFActivity activity={t} color={c} key={i}/>
    }else if(t.notifiable_type == 'bf_check_activity'){
      return <BfActivity activity={t} color={c} key={i}/>
    }else if(t.notifiable_type == 'growth_activity'){
      return <GrowthActivity activity={t} color={c} key={i}/>
    }   
  })

  return (
    <Fragment>
      <div className="time-container">
        <div className="time-header ht-120">
          <div className="color-overlay">
            {/*<div className="day-number">8</div>*/}
            <div className="date-right">
              <div className="day-name">{journey.data.mother_name} {child.data.baby_name?`- ${child.data.baby_name}`:''}</div>
              <div className="month">{journey.data.district},{journey.data.state}</div>
              <div className="month"> Date of birth: {child.data.dob_baby}, M: {journey.data.cell_number}</div>
            </div>
          </div>
          <span onClick={e=>toggleOpenSideNav(e)} className="pathbutton zIndex">
            <i className="fa fa-bars p-5" data-tip="Access Advanced Child Nutrition Journey Tools" aria-hidden="true"></i>
          </span>
        </div>
  
        <div className="timeline">
          <ul>
            <ActivityCard/>
          </ul>
        </div>  

        <Pagination loadMoreBtn="true" totalItems={pagemeta.total} currentpage={currentpage}
        setCurrentpage={setCurrentpage} itemsPerPage={pagemeta.per_page}/>
      </div>
      
      {isSideNavOpen && <PortalSideMenu history={props.history} openSideNav={isSideNavOpen} setOpenSideNav={toggleOpenSideNav} journeyProfileId={params.health_id}/>}
      {isMenuOpen && <DataSourceMenu id="3614" toggleModal={toggleMenuModal} onSelect={routeActivity}/>}
      
    </Fragment>
  )
}
/*
{isActivityOpen && <GenericModal title="Activity" component={ActivityList} 
        toggleModal={toggleActivityModal} {...props} id={selectedActivity.notifiable_id}/>}
*/
export default InfantPath;