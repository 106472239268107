import React, { Fragment, useState, useEffect, useContext} from 'react'
import { AuthContext } from '../../contexts/Authentication/AuthStateProvider'
import useStyle from '../../hooks/useStyle'
import SubscriptionService from './SubscriptionService'
import OgranizationSubscriptionService from './OgranizationSubscriptionService'

const SubscriptionsModal = (props) => {
  useStyle('dashboard')	
  
  const {organization, organization:{subscription:orgSubscription}, resetOrganizationList } = props

  const { state: { screen, user: currentUser }, dispatch} = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [subscriptions, setSubscriptions] = useState([])

  useEffect(() => {
    getSubscriptions()
  }, [])

  const getSubscriptions = () =>{
	  setLoading(true)
    SubscriptionService.getAll().then(({status, data})=>{
      if(status == 200){
		    setSubscriptions(data.subscriptions)
        setLoading(false)
      }
    })
  }

  const addSubscription = (subscription) =>{
    let req = {
      organization_id:organization.id,
      subscription_id:subscription.id
    }
    
    if(orgSubscription?.id == subscription.id){
      req.delete_all = true
    }

    OgranizationSubscriptionService.create(req).then(({status, data})=>{
      if(status == 201){
        organization.subscription = data.organization_subscription?subscription:null
        resetOrganizationList()
      }
    })  
  } 

  if(loading){
    return(
      <div className="spinner"/>
    )
  }

  return(
  	<div className="p-15 vh-80 scroll-y">
      <SubscriptionList subscriptions={subscriptions} 
        orgSubscription={orgSubscription}
        addSubscription={addSubscription}
      />
  	</div>
  )
}

const SubscriptionList = ({subscriptions, orgSubscription, addSubscription}) => subscriptions?.map((s, i) => {
  return(
    <div key={i} className="m-b-5" onClick={e=>addSubscription(s)}>
      <span className={orgSubscription?.id == s.id?'font-18 far fa-check-square text-muted':'font-18 far fa-square text-muted'}/>
      <span className="m-l-15 font-14">{s.data.name}</span>
    </div>
  )
})

export default SubscriptionsModal;