import React, {useState, Fragment, useEffect, useContext} from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import HelpCard from './HelpCard';
import HelpService from '../HelpService';
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import FormTemplateId from '../../../constants/FormTemplateList';
import CommentsModal from "../../Modals/CommentsModal";
import ProjectMenu from "../../Modals/ProjectMenu"
import {Link} from "react-router-dom";
import useModal from "../../../hooks/useModal";
import useStyle from '../../../hooks/useStyle';
import env from '../../../env';
import PaginationSM from '../../Common/PaginationSM';

let requestParams = {};
let selectedHelp = {};
let dataSource = {};

const HelpList = (props) => {
  useStyle('/css/tile-components.css', true)

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);

  const [helpList, setHelpList] = useState([]);
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formFieldsList, setFormFieldsList] = useState([]);

  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal();
  const { isOpen, toggleModal:toggleDeleteModal } = useModal();
  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal();

  useEffect(() => {
    requestParams = {
      'filter_favourites': false,
      'is_active': true,
      'is_user_suggested': false,
      'page': currentpage,
      'paginate': 1,
      'per_page': 15,
      'sort_column': '',
      'sort_direction': 'desc',
      'search': null,
      'form_fields_params': {ids:[496]}
    }

    getHelpList();
  }, [currentUser.id, currentpage]);

  let getHelpList = () => { 
    setLoading(true)
    HelpService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       setHelpList(res.data.helps)
       setPagemeta(res.data.meta)
       setFormFieldsList(res.data.form_fields || {})
       //requestParams.totalItems = res.data.meta.total;
       dataSource = res.data.data_source_list;
      }
    })
    setLoading(false)
  }

  const deleteHelp = (e) => {
    e.preventDefault();
    HelpService.delete(selectedHelp.id).then((res) => {
      if (res.status == 204) {
        setHelpList(helpList.filter(i => i.id !== selectedHelp.id))
      }
    })
    toggleDeleteModal(e);
  }

  const addDelete = (help) => {
    selectedHelp = help;
    toggleDeleteModal();
  }

  const HelpCardList = () => helpList && helpList.map((help, i) => (
    <div className="col-md-4" key={i}>
      <HelpCard help={help} toggleModal={toggleDeleteModal} 
        formFieldsList={formFieldsList} addDelete={addDelete} index={i}/>
    </div>
  ))

  return(
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20">Help</div>
              <div className="font-14">{pagemeta?.total} Items</div>
            </div>

            <div className="flex aligncenter">
              <Link className="bg-highlight round-btn float-right m-r-5 m-t-4" to="/help/create/">  
                <i className="fas fa-plus"/>
              </Link>
            </div>
          </div>  
      
          <div className="app-content-section m-t-30">
            {loading ? 
              <div className="spinner"/>
              :
              <>
                <div className="row">
                  <HelpCardList/>
                </div>    
                
                <PaginationSM
                  className="pagination-bar"
                  currentPage={currentpage}
                  totalItems={pagemeta.total}
                  itemsPerPage={pagemeta.per_page}
                  onPageChange={setCurrentpage}
                  siblingCount={2}/>
              </>
            }
          </div>
        </div>
      </div>

      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteHelp}/>
      
    </Fragment>
  )
  
}

export default HelpList;