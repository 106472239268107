import React, {Fragment, useState, useContext, useEffect} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../../Common/MenuItem"
import useStyle from '../../../hooks/useStyle'
import useSort from '../../../hooks/useSorting'
import Cs from "../../../services/CommonService"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import useModal from "../../../hooks/useModal"
import GenericModal from '../../Modals/GenericModal'
import TrainingContentsService from './TrainingContentsService'
import TrainingTopicForm from './Form'
import FormTemplateId from "../../../constants/FormTemplateList"
import ProjectMenu from "../../Modals/ProjectMenu"
import ContentCard from "./ContentCard"
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from '../../Common/arrayMove';
import CommentsModal from "../../Modals/CommentsModal"

let selectedContent = {}
let requestParams ={}
let dataSources = []
let trainingContentFormProps = {}

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
})

const ContentList = (props) => {
  const {disabledSort, trainingSessionId} = props

  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])
  const [formFieldsList, setFormFieldsList] = useState({})

  const {isOpen:isFormOpen, toggleModal:toggleFormModal} = useModal()
  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal()
  const {isOpen:isMenuOpen, toggleModal:toggleMenuModal} = useModal()
  const {isOpen: isCommentOpen, toggleModal:toggleCommentModal} = useModal()

  useEffect(() => {
    requestParams = {
      page:1,
      per_page:100,
      sort_column:'sort_order',
      sort_direction:'asc',
      training_session_id:trainingSessionId,
      form_fields_params:{
        ids:[FormTemplateId.TrainingContentForm]
      }
    } 
    getAll()
  }, [])

  const getAll = () =>{
    setLoading(true)
    TrainingContentsService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setList(res.data.training_contents)
        setFormFieldsList(res.data.form_fields)
        setLoading(false)
      }
    })
  }

  const onDelete = (e, program) => {
    selectedContent = program
    toggleDeleteModal(e)
  }

  const deleteItem = (e) => {
    e.preventDefault()
    TrainingContentsService.delete(selectedContent.id).then((res) => {
      if (res.status == 204) {
        setList(list.filter(i => i.id !== selectedContent.id))
      }
    })
    toggleDeleteModal(e)
  }

  const addNotes = (e, program) => {
    selectedContent = program;
    toggleCommentModal(e);
  }

  const openTrainingContentForm = (e, id) =>{
    e.preventDefault();
    trainingContentFormProps = {
      match:{
        params:{
          id: id,
        }
      },
      isPopupMode:true,
      onSuccess:getAll
    }
    toggleFormModal()
  }

  const addMenu = (item) => {
    selectedContent = item;
    toggleMenuModal();
  }

  const updateSortOrder = (id, prior_item_id, next_item_id, success_callback) =>{
    let req = {
      'update_type': 'sort_order',
      'prior_item_id':prior_item_id,
      'next_item_id':next_item_id,
      'id':id
    }
    TrainingContentsService.updateSortOrder(req).then((res)=>{
      if(res.status == 204){
        success_callback()
      }
    })
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    let priorItemId = null
    let nextItemId = null

    if(newIndex == 0 ){
      //console.log(list[newIndex].data.name)
      nextItemId = list[newIndex].id
    }else if(newIndex+1 == list.length){
      //console.log(list[newIndex].data.name)
      priorItemId = list[newIndex].id
    }else if(oldIndex > newIndex){
      //console.log(list[newIndex-1].data.name)
      //console.log(list[newIndex].data.name)

      priorItemId = list[newIndex-1].id
      nextItemId = list[newIndex].id
    }else if(oldIndex < newIndex){
      //console.log(list[newIndex].data.name)
      //console.log(list[newIndex+1].data.name)

      priorItemId = list[newIndex].id
      nextItemId = list[newIndex+1].id
    }

    function onSuccess(){
      setList(arrayMove(list, oldIndex, newIndex))
    }
    
    updateSortOrder(list[oldIndex].id, priorItemId, nextItemId, onSuccess)
  }

  const menuItems = ({item}) =>{
    return(
      <Fragment>
        <a href="/" onClick={(e) => openTrainingContentForm(e, item.id)}>
          <i className="far fa-edit"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </a>
        <a to="/" onClick={(e) => onDelete(e, item)}>
          <i className="far fa-trash-alt"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>
      </Fragment>
    )
  }

  const Menu = ({ item, fn }) => (
    <span data-tip="Menu" onClick={()=>addMenu(item)} className="fas fa-bars pull-right m-t-2 m-r-10" />     
  )

  const SortableItem = sortableElement((cprops) => 
    <div className={`col-xs-12 ${disabledSort?'col-sm-6 col-md-4':''}`} key={cprops.index}>
      <ContentCard disabledSort={disabledSort} addNotes={addNotes} {...cprops}/>
      {selectedContent.id == cprops.content.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal} 
        item={selectedContent} MenuItems={menuItems}/>
      }
    </div>
  )

  const ContentCards = () => list && list.map((p, i) => (
    <SortableItem key={`item-${i}`} disabled={disabledSort} content={p} menu={Menu} 
      formFieldsList={formFieldsList} index={i}/>
  ))

  return (
    <Fragment>
      {loading?<div className="spinner"/>
        : 
        <div className="row page-content">
          <SortableContainer onSortEnd={onSortEnd} distance={1} lockAxis="y">
            {list?.length>0?
              <ContentCards/>:<div className="text-center p-5 font-18">No Items</div>
            }
          </SortableContainer>
        </div>
      }
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteItem}/>
      
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} item={selectedContent} 
        type="topics"/>
      }
      {isFormOpen && <GenericModal component={TrainingTopicForm} isOpen={isFormOpen} 
        toggleModal={toggleFormModal} widthCss="w-100p" {...trainingContentFormProps} />
      }
    </Fragment>
  )

}

export default ContentList;