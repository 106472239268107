import React, {Fragment, useState} from "react"
import {Link} from "react-router-dom"
import Cs from '../../../services/CommonService'
import env from "../../../env"
import {FormFieldTable, RenderData} from "../../HealthJourney/UserRoles/NotificationPill"
import FormTemplateId from "../../../constants/FormTemplateList"
import { CTTooltip } from "../../Common/CueTooltip"

const ContentCard = (props) => {
  let {content, formFieldsList, disabledSort} = props
  let {data, data_source} = content
  let formFields = formFieldsList[FormTemplateId.TrainingContentForm] || []

  const GenericContent = () =>(
    <div className="card-container bg-white shadow-small">
      <div className="white card-header p-5 bg-activity" >
        <div className="font-16 lh-18 ellipsis-lg">{data.title}</div>
        <div className="col-xs-12">
          <div className="pull-left">{content.user?.name}</div>
          <div className="pull-right">{Cs.formatUpdateDate(content.created_at)}</div>
        </div> 
      </div> 
      
      {disabledSort &&
        <>
          <div className="ht-200 scroll-auto">
            <table className="table card-table">
              <tbody>
                <FormFieldTable formFields={formFields} data={data} dataSource={data_source}/>
              </tbody>
            </table>
          </div>

          <div className="vard-footer m-t-10 ht-25 p-t-5">
            <span className="m-l-20" data-tip="Chat" onClick={(e=>props.addNotes(e,content))}>
              <i className="far fa-comment font-18 text-muted"></i>
              <span className="badge-2 up bg-lgrey">0</span>
            </span>
            <props.menu item={content}/>
          </div>
        </>
      }
      <CTTooltip />
    </div>
  )

  if(!data)return null;

  return (<GenericContent/>)
    
}

export default ContentCard;