import React, {Fragment} from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
import ScreeningEvents from './List/ScreeningEvents'
import ScreeningLists from './List/ScreeningLists'
import ScreeningEventForm from './Form/ScreeningEventForm'
import ScreeningForm from './Form/ScreeningForm'

const ScreeningEventRouter = (props) => (
  <Fragment>	
    <PrivateRoute path="/screening_events" searchString={props.searchString} component={ScreeningEvents} exact />
    <PrivateRoute path="/screening_lists/:screening_event_id?" component={ScreeningLists} exact />
    <PrivateRoute path="/screening_events/details/:id?" component={ScreeningEventForm} exact />
    <PrivateRoute path="/screening_events/create/:formId?/:id?" component={ScreeningEventForm} exact />
    <PrivateRoute path="/screening_lists/create/:eventId/:formType/:formId?/:id?" component={ScreeningForm} exact />
  </Fragment>
)

export default ScreeningEventRouter;