import React, {useState, Fragment, useContext, useEffect} from "react"
import ReactTooltip from "react-tooltip"
import querystringify from "querystringify"
import Pagination from '../Pagination'
import {Link} from "react-router-dom"
import useStyle from '../../hooks/useStyle'
import useModal from "../../hooks/useModal"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from "../../services/CommonService"
import JourneyTitle from "../../components/HealthJourney/GenericPortalPage/Title"
import DataSourceListModal from './DataSourceListModal'
import AddLocale from "../Locale/AddLocale"
import GenericModal from "../../components/Modals/GenericModal"
import env from "../../env"
import {OptionsObjList} from  "../Common/FormInput"
import FormFieldOrgAttributeService from "./FormFieldOrgAttributeService"
import CheckAccess from '../HealthJourney/Roles/CheckAccess' 
import { CTTooltip } from "../Common/CueTooltip"
import {usePageTitle} from '../../hooks/pageHead'
import FormDataChartsConfig from "./FormDataChartsConfig"

let requestParams = {}
let selectedFormField = {}
let selectedLocaleData = {}

const DataSourceList = (props) => {
  useStyle('filter_dialog');
  useStyle('add_role_dialog');
  useStyle("card");
  usePageTitle('DataSourceList');

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [dataSourceList, setDataSourceList] = useState([]);
  const [formTemplates, setFormTemplates] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [index, setIndex] = useState()
  const [dataSources, setDataSources] = useState({});
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [journeyTypeId, setJourneyTypeId] = useState();
  const [loading, setLoading] = useState(true)

  let params = props.match.params;
  let journey_profile = props.location.state;
  let queryParam = querystringify.parse(props.location.search);
  const isSuperUser = env.admin_emails.includes(currentUser.email)

  const {isOpen: isDataSourceModalOpen, toggleModal: toggleDataSourceModal} = useModal();
  const {isOpen: isLocaleModalOpen, toggleModal: toggleLocaleModal} = useModal();
  const {isOpen: isChartConfigModalOpen, toggleModal: toggleChartConfig} = useModal();

  useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
      'journey_type_id':journeyTypeId=='None'?null:journeyTypeId,
      'data_source_params': {
         "data_source_id":[3419],
      }
    }
    /* queryParam.form_ids*/
    
    getFormTemplates();
  }, [props.searchString, currentpage, journeyTypeId]);

  let getFormTemplates = () => { 
    setLoading(true)
    Cs.getFormTemplates(requestParams).then((res)=>{
      if(res.status == 200){
        setFormTemplates(res.data.form_templates)
        setDataSources(res.data.data_source_list)
        setLoading(false)
      }
    })
  }

  let getFormFields = (from) => { 
    let req = {
      'form_template_id':from.id, 
      'fields':['chart_count'],
      'sub_organization_id':currentUser.current_organization_id,
      'add_locale':true
    }
    Cs.getFormFieldsById(req).then((res)=>{
      if(res.status == 200){
        setFormFields(res.data.form_fields)
      }
    })
  }

  const handleClick =(form, i)=> {
    if(index != i){
      getFormFields(form);
      const tabScroll = document.getElementById('tab_'+i);
      window.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': tabScroll.offsetTop - 200
      })
    }  
    setFormFields([])
    setIndex(index == i?null:i)
  }

  let updateStatus = (o, form_template_id, indexList) =>{
    /*if(o.required)return;*/
    if(CheckAccess.checkIsAdmin(currentUser)){
      let req = {
        filterable_type: 'form_field',
        created_by: currentUser.id,
        filterable_id: o.index,  
        filterable_ids: indexList,  
        form_template_id: form_template_id,
        sub_organization_id: currentUser.current_organization_id
      }

      Cs.updateDataSourceListStatus(req).then((res)=>{
        if(res.status == 200){
          if(o.inactive_status){
            o.inactive_status=null;
          }else{
            o.inactive_status={};
          }
          setFormFields([...formFields])
        }
      }) 
    }
  }

  const setFormField = (e, field, locale) =>{
    e.stopPropagation()
    selectedFormField = field
    selectedFormField.locale = locale
    toggleDataSourceModal()
  }

  const checkAllFields = (form_template_id) => {
    let req = {
      filterable_type: 'form_field',
      form_template_id: form_template_id,
      sub_organization_id: currentUser.current_organization_id
    }
    Cs.deleteDataSourceListStatus(req).then((res)=>{
      formFields.forEach(d => d.inactive_status = null)
      setFormFields([...formFields])
    })
  }

  const uncheckAllFields = (form_template_id) => {
    let indexList = formFields.map(d => d.inactive_status==null && d.index)
    if(indexList.length > 0){
      updateStatus({}, form_template_id, indexList);
    }
  }
  
  const openLocale = (e, data, data_type, index) =>{
    e.stopPropagation()
    selectedLocaleData.data = data
    selectedLocaleData.type = data_type
    selectedLocaleData.index = index
    toggleLocaleModal()
  }

  const updateFormLocale = (locale, value) =>{
    if(selectedLocaleData.type === 'form_field'){
      let d = formFields[selectedLocaleData.index]
      d.locale = d.locale || {}
      d.locale[locale] = value
      formFields[selectedLocaleData.index] = d
      setFormFields([...formFields])
    }else if(selectedLocaleData.type === 'form_title'){
      let d = formTemplates[selectedLocaleData.index]
      d.locale_title = d.locale_title || {}
      d.locale_title[locale] = value
      formTemplates[selectedLocaleData.index] = d
      setFormTemplates([...formTemplates])
    }else if(selectedLocaleData.type === 'form_details'){
      let d = formTemplates[selectedLocaleData.index]
      d.locale_details = d.locale_details || {}
      d.locale_details[locale] = value
      formTemplates[selectedLocaleData.index] = d
      setFormTemplates([...formTemplates])
    }else if(selectedLocaleData.type === 'data_source_list'){
      let d = selectedLocaleData.data
      d.locale_data = d.locale_data || {}
      d.locale_data[locale] = {label:value}
      /*let d = formFields[selectedLocaleData.index]
      d.data_source_locale.push(locale)
      formFields[selectedLocaleData.index] = d
      setFormFields([...formFields])*/
    }
  }

  const updateOrgAttribute = (field, key, value) =>{
    if(field.org_attribute?.id){
      let req = {
        id: field.org_attribute.id,
        [key]: value
      }
      FormFieldOrgAttributeService.update(req).then((res)=>{
        if(res.status == 204){
          field.org_attribute[key] = value
          setFormFields([...formFields])
        }
      })
    }else{
      let req = {
        'form_template_id':field.id,
        'field_index':field.index,
        'sub_organization_id':currentUser.current_organization_id,
        [key]: value
      }

      FormFieldOrgAttributeService.create(req).then((res)=>{
        if(res.status == 201){
          field.org_attribute = res.data.form_field_org_attribute
          setFormFields([...formFields])
        }
      })
    }
  }
    
  const OrgAttributes = ({field}) =>{
    let attribute = field.org_attribute || {required: field.required}

    return(
      <Fragment>
        <span className={attribute.required ? 
            'fas fa-toggle-on font-22 green m-l-15'
            :'fas fa-toggle-on fa-rotate-180 font-22 red m-l-15'
            } onClick={e=>updateOrgAttribute(field, 'required', !attribute.required)}>
        </span>
        <span className="font-14 black m-l-5">Org Level Required</span>
      </Fragment>
    )
  }

  const ChartAttributes = ({field}) => {
    return(
      <div
        onClick={()=>{selectedFormField=field;toggleChartConfig()}} 
        className="badge-2 br-r-unset bg-lred m-l-5">
          <span className="fa fas fa-chart-line"/>
          <span className="font-14 m-l-5">{field.chart_count} Charts</span>
      </div>
    )
  }

  const LocalesPill = ({locales, field, onSelect}) => locales && locales.map((l, i) => 
    <span key={i} className="text-cap m-r-10 badge-2 bg-lgrey"
      onClick={e=>{onSelect && onSelect(e, field, l)}}>
      {l}
    </span>
  )

  const FormFieldsView = ({form}) => formFields && formFields.map((c, i) => {
    return(
      <Fragment key={i}>
        <div className="col-xs-12 carrier m-t-10 br-btm-grey p-5" style={{borderLeft: "3px solid #27a1fb"}}>
          <span className={'checkbox-container ' + (c.required && 'req-field')} key={i} onClick={e => updateStatus(c, form.id)}>
            <span>
              {c.label} 
              {isSuperUser && <span className="fas fa-edit m-l-15" onClick={e=>openLocale(e, c, 'form_field', i)}/>}          
            </span>
            {CheckAccess.checkIsAdmin(currentUser) &&
              <>
                <input type="checkbox" checked={c.inactive_status==null} readOnly/>
                <span className="checkbox-checkmark"></span>
              </>
            }
          </span>
          {c.locale &&
            <div className="m-t-5">
              <LocalesPill locales={Object.keys(c.locale)} field={c}/>
            </div>
          }
          {CheckAccess.checkIsAdmin(currentUser) && <OrgAttributes field={c}/>}
          {c.data_source_id &&
            <div className="m-t-5">
              <div className="badge-2 br-r-unset bg-lred m-l-5" onClick={e=>setFormField(e, c)}>{c.data_source_name}</div>
              <LocalesPill locales={c.data_source_locale} field={c} onSelect={setFormField}/>
            </div>
          }
          <div className="m-t-5">
            <ChartAttributes field={c}/>
          </div>
        </div>
        
        <CTTooltip />
      </Fragment>
    )
  })

  const FormTemplateView = formTemplates.map((o, k) => {
    const boxShadow =  index == k ? {border: "1px solid lightgrey", boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"} : {}
    return(
      <Fragment key={k}>
        <div className="col-xs-12 br-grey font-14 m-b-15" key={k} style={boxShadow} id={'tab_'+ k}>
          <div className="black bg-white m-t-10 p-10 d-flex" onClick={e=>handleClick(o, k)}>
            <div className="font-16 w-100p">
              {o.post.title} 
              {isSuperUser && <i className="fas fa-edit m-l-15" onClick={e=>openLocale(e, o, 'form_title', k)}/>}
              {o.locale_title &&
                <div className="m-t-5">
                  <LocalesPill locales={Object.keys(o.locale_title)}/>
                </div>
              }
            </div>
            <i className = {'m-r-10 p-5 font-24 ' + (index == k ? "far fa-caret-square-up" : "far fa-caret-square-down")} ></i>
          </div>
          {index == k && <div key={k} className="ht-250 bg-white p-5 scroll-auto">
            <div className="row">
              <Link 
                className="pull-left underline font-15"
                to={`/data_source_list/form/${currentUser.current_organization_id}`}>
                Suggest updates to the forms
              </Link>
            </div>
            <div className="m-t-10 m-b-10">
              <p dangerouslySetInnerHTML={{ __html: o.post.details }}/>
              {o.locale_details &&
                <LocalesPill locales={Object.keys(o.locale_details)}/>
              }
            </div>
            <i className="fas fa-edit m-l-15" onClick={e=>openLocale(e, o, 'form_details', k)}/>
            {CheckAccess.checkIsAdmin(currentUser) &&
              <div className="pull-left">
                <span className="badge-2 br-r-unset bg-black" onClick={e=>checkAllFields(o.id)}>Check All</span>
                <span className="badge-2 br-r-unset bg-black" onClick={e=>uncheckAllFields(o.id)}>Uncheck All</span>
              </div>
            }
            <FormFieldsView form={o}/>
           </div>
          }
        </div>
      </Fragment>
    )
  })

  return (
    <div id="ct">
      <div className="app_content">
        <div className="flex justspacebetween">
          <div className="app-title m-r-20">Data Choices</div>
            
          {dataSources.health_condition && 
            <select className="form-control p-5 w-250" onChange={e=>setJourneyTypeId(e.target.value)}>
              <option>None</option>
              <OptionsObjList list={dataSources.health_condition.options} id_key="pk_id" label_key="label" />
            </select>
          }
        </div>
      </div>
      {loading && <div className="spinner"/>}
      <div className="content row m-t-10">
        <div className="page-content row">
          <div className="content">
             {FormTemplateView}
          </div>
          <Pagination pagemeta={pagemeta} currentpage={currentpage} 
          setCurrentpage={setCurrentpage}/>
        </div>
      </div>
      
      {isDataSourceModalOpen && <DataSourceListModal 
        form_template_id={selectedFormField.id} 
        data_source_id={selectedFormField.data_source_id} 
        journey_profile_id={queryParam.journey_profile_id}
        toggleModal={toggleDataSourceModal} 
        locale={selectedFormField.locale}
        openLocale={openLocale}/>
      }

      {isLocaleModalOpen && <GenericModal component={AddLocale} 
        localeType={selectedLocaleData.type} 
        title="Language Translator"
        toggleModal={toggleLocaleModal} 
        onCancel={toggleLocaleModal} 
        labelObj={selectedLocaleData.data}
        updateFormLocale={updateFormLocale}
        locale={selectedLocaleData.locale}/>
      }
      
      {isChartConfigModalOpen &&
        <GenericModal component={FormDataChartsConfig}
          isOpen={isChartConfigModalOpen}
          toggleModal={toggleChartConfig}
          field={selectedFormField}/>
      }
    </div>
  )

}

export default DataSourceList;

/*
<i className="fas fa-edit m-l-15" onClick={e=>openLocale(e, o, 'form_details')}/>
            <div className="pull-left">
              <span className="badge br-r-unset bg-lred m-l-5" onClick={e=>checkAllFields(o.id)}>Check All</span>
              <span className="badge br-r-unset bg-lred m-l-5" onClick={e=>uncheckAllFields(o.id)}>Uncheck All</span>
            </div>
*/
