import React, {Fragment, useState, useEffect, memo} from "react";
import {Link} from "react-router-dom";
import moment from 'moment'
import importScript from '../../hooks/importScript'

let options = {}

const LineChart = (props) => {
  let {id, data_labels, height, series, x_axis, y_axis, colors, className, markers, title, tooltip, events} = props
  let [legend, setLegend] = useState(props.legend || {show:true, position:'top'})

  useEffect(() => {
    importScript('/scripts/apexcharts/apexcharts.min.js', setChart, window.ApexCharts);
    options[id] = {
      id:id,
      series: series,
      chart: {
        height: height || 350,
        type: 'line',
        background:"#fff",
        /*dropShadow: {
          enabled: true,
          color: '#000',
          top: 18,
          left: 7,
          blur: 10,
          opacity: 0.2
        },*/
        toolbar: {
          show: false
        },
        events: events
      },
      colors: colors,
      dataLabels: data_labels,
      stroke: {
        show: true,
        curve: 'smooth',
        width: 3,
      },
      grid: {
        borderColor: '#e7e7e7',  
      },
      markers: markers,
      legend: legend,
      /*responsive: [
        {
          breakpoint: 1000,
          options: {
            chart: {
              width: 1000,
            }
          }
        },
        {
          breakpoint: 500,
          options: {
            chart: {
              width: 500,
            }
          }
        }

      ]*/
    }

    if(tooltip)options[id].tooltip = tooltip;
    if(title){
      options[id].title = {
          text: title,
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: true,
          style: {
            fontSize:  '14px',
            fontWeight:  'bold',
            fontFamily:  undefined,
            color:  '#263238'
        }
      }
    }

    if(x_axis){
      options[id].xaxis = x_axis
    }/*else{
      options.xaxis = {
        type: 'category',
        categories: x_axis,
        tickAmount: 5,
        title: {
          text: x_axis_label,
        },
        labels:{
          formatter:(val)=>{
            return Math.round(val);
          }
        }
      }
    }*/

    if(y_axis){
      options[id].yaxis = y_axis
    }/*else{
      options.yaxis = {
        max: y_axis_max,
        min: y_axis_min,
        type:'numeric',
        tickAmount: ytick_amount,
        title: {
          text: y_axis_label
        },
        labels: {
          formatter: (val) => {
            return parseInt(val) 
          }
        }
      }
    }*/

  }, [])

  /*function setChart(){
    setTimeout(() => {
      const ele = document.querySelector(`#chart_${id}`)
      if(ele)
        new window.ApexCharts(ele, options).render()
    }, 0);
  }*/

  function setChart(){
    setTimeout(() => {
      options[id].chartEle = new window.ApexCharts(document.querySelector(`#chart_${id}`), options[id]);
      options[id].chartEle.render();  
    }, 0);
  }

  useEffect(()=>{
    if(options[id] && options[id].chartEle){
      options[id].chartEle.updateOptions({legend: legend})
      //window.ApexCharts.exec('chart_'+id, 'updateLegends', {legend: legend}, false, true);
    }
  }, [legend])

  return(
    <div className={className}>
      <div id={'chart_'+id}/>
      <div className="flex justspacebetween coldir-xs rowrev">
        <button className="btn bg-grey m-b-15 m-r-10" 
          onClick={()=>setLegend({...legend, show:!legend.show})}
        >Legends</button>
        <div className="font-12 lgrey">
          <i className="m-l-15 m-r-5 far fa-copyright" aria-hidden="true"/> 
          {new Date().getFullYear()} IPIPAL Inc. 
          All rights reserved.
        </div>
      </div>
    </div>
  )
 
}

export default memo(LineChart);