import React, {Fragment} from 'react';
import PrivateRoute from "../../routes/PrivateRoute";
import DigitalContentList from '../../components/DigitalContent/List/DigitalContentList'
import DigitalContentForm from '../../components/DigitalContent/Form/DigitalContentForm'

const DigitalContentRouter = (props) => (
  <Fragment>	
    <PrivateRoute path="/digital_contents" searchString={props.searchString} component={DigitalContentList} exact />
    <PrivateRoute path="/digital_contents/edit/:id?" component={DigitalContentForm} exact />
    <PrivateRoute path="/digital_contents/details/:id?" component={DigitalContentForm} exact />
    <PrivateRoute path="/digital_contents/create/:type?/:formId?/:id?" component={DigitalContentForm} exact />
  </Fragment>
)

export default DigitalContentRouter;