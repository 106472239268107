import React, {Fragment, useState, useEffect, useContext, useMemo} from "react"
import {Link, useHistory} from "react-router-dom"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../../services/CommonService"
import useModal from "../../../../../hooks/useModal"
import {FormFieldCard, RenderData} from "../../../UserRoles/NotificationPill"
import ActivityService from "../../Activities/ActivityService"
import useStyle from '../../../../../hooks/useStyle'
import NutritionCareNotification from '../../../../NutritionCares/NutritionCareNotification'
import GenericModal from "../../../../../components/Modals/GenericModal"
import ActivityForm from '../../../MotherInfantJourney/Activities/Form/ActivityForm'
import { LinkFontAwesomeIcon, ChatGrayIcon } from "../../../../Common/ImageIcons"
import useSort from '../../../../../hooks/useSorting'
import {FontAwesomeBtn} from "../../../../Common/Button"
import { CTTooltip, CueTip } from "../../../../Common/CueTooltip"
import {DraftBadge} from "../../../UserRoles/NotificationPill"
import {useActivityHook} from "./useActivityHook"

const Header = ({activity}) => (
    <div className="card-title card-title-gray flex p-b-15 p20 coldir">
      <h4 className="fw700 f18 card-heading flex coldir text-left">
        {activity.record_id}
      </h4>
      <div className="f14 m-t-10 fw500">
        {activity.author_name} 
        &nbsp;-&nbsp; 
        {Cs.formatUpdateDate(activity.updated_at)}
        <DraftBadge isDraft={activity.draft}/>
      </div>
    </div>
)

const Footer = ({activity}) => {
    return(
      <div className="card-footer flex justspacebetween p20 aligncenter">
        <ul className="flex aligncenter">
          <li className="m-r-25">{activity.record_id}</li>
          <li className="m-r-25">
            
          </li>
          <li className="m-r-25">
            
          </li>
        </ul>
      </div>
    )
 }

const GrowthMeasure = ({a, data, ds, child}) =>{
	const {ageFormat, getBgColorByZscore, getBgColorByZscoreLable, setChild} = useActivityHook(child)

	data.age_ago = useMemo(() => ageFormat(a.id, a.scheduled_date, data), [a.id]);
  const weightZscoreStyle = useMemo(() => getBgColorByZscore(data.weight_zscore, data.weight_zscore_label), [data.weight_zscore, data.weight_zscore_label]);
  const heightZscoreStyle =  useMemo(() => getBgColorByZscore(data.height_zscore, data.height_zscore_label), [data.height_zscore, data.height_zscore_label]);
  const wfhZscoreStyle =  useMemo(() => getBgColorByZscore(data.wfh_zscore, data.wfh_zscore_label), [data.wfh_zscore, data.wfh_zscore_label]);

	return (
		<>
			<div className="border-dashed-sm p-b-15 m-b-15">
	      <h5 className="fw500 m-b-5">Age</h5>
	      <div>{data.age_ago}</div>
	    </div>
	    <div className="border-dashed-sm p-b-15 m-b-15">
	      <h5 className="fw500 m-b-5">W</h5>
	      {ds.baby_weight && 
            <ul className="flex aligncenter badge-boxes">
              <li className="badge m-5-0 white" style={weightZscoreStyle.r1}>{ds.baby_weight?.label} kgs</li>
              <li className="badge m-5-0 white" style={weightZscoreStyle.r2}>{data.weight_zscore} Z</li>
              <li className="badge m-5-0 white bg-lgrey">{Cs.round(data.weight_percentile)}%</li>
              <li className="badge m-5-0 white d-hide" style={weightZscoreStyle.r4}>{data.weight_zscore_label}</li>
            </ul>
        }
	    </div>
	    <div className="border-dashed-sm p-b-15 m-b-15">
	      <h5 className="fw500 m-b-5">H</h5>
	      {ds.height_baby &&
            <ul className="flex aligncenter badge-boxes">
              <li className="badge white m-5-0" style={heightZscoreStyle.r1}>{ds.height_baby?.label} cms</li>
              <li className="badge white m-5-0" style={heightZscoreStyle.r2}>{data.height_zscore} Z</li>
              <li className="badge white m-5-0 bg-lgrey">{Cs.round(data.height_percentile)}%</li>
              <li className="badge white m-5-0 d-hide" style={heightZscoreStyle.r4}>{data.height_zscore_label}</li>
            </ul>
          } 
	    </div>
	    <div className="border-dashed-sm p-b-15 m-b-15">
	      <h5 className="fw500 m-b-5">WFH</h5>
	      {data.wfh && 
            <ul className="flex aligncenter badge-boxes">
              <li className="badge white m-5-0" style={wfhZscoreStyle.r1}>{data.wfh}</li>
              <li className="badge white m-5-0" style={wfhZscoreStyle.r2}>{data.wfh_zscore} Z</li>
              <li className="badge white m-5-0 bg-lgrey">{Cs.round(data.wfh_percentile)}%</li>
              <li className="badge white m-5-0 d-hide" style={wfhZscoreStyle.r4}>{data.wfh_zscore_label}</li>
            </ul>
          }
	    </div>
  	</>
  )
}

const ActivityCard = ({formFields, activity, child, componentName}) =>{
	useStyle('/css/tile-components.css', true)
	return (
	  <div id="ct">
	    <div className="bg-white border-rounded-10 card-shadow m-b-15">
	      <Header activity={activity}/>
	      <div className="card-content p20">
	        <div className="b-tab-table active scroll-auto ht-400">
	          <div className="p-b-15 m-b-15 m-t-15">
	          	<FormFieldCard formFields={formFields} 
	          		dataSource={activity.data_source || {}}
	          		data={activity.data || {}}/>
	          	{componentName === 'growth_measurement' &&
	          		<GrowthMeasure a={activity} data={activity.data} ds={activity.data_source} child={child}/>
	          	}
	          </div>
	        </div>
	      </div>
	      <Footer activity={activity}/>
	    </div>
	  </div>
  )
}

export {GrowthMeasure, ActivityCard}