import React, { useState, Fragment} from 'react'
import ReactDOM from 'react-dom'
import {Permissions} from "../../../constants/DataSourceList"
import LocaleList from "../../Locale/LocaleMenu"
import GenericModal from '../../Modals/GenericModal'
import useModal from "../../../hooks/useModal"
import {OptionsObjList} from "../../Common/FormInput"
import CheckAccess from "./CheckAccess"

const AddRole = ({ role, isOpen, toggleModal, addNewRole, dataSources, orgJourneyComponents, currentOrgMember}) => {

  if(role){
    role.permissions = role.permissions || {locale:{}, menu:{}, infant_ppt:{}, infant_process:{}, infant_portal:{}}
  }

  const [newRole, setNewRole] = useState(role)
  const [index, setIndex] = useState(null)
  
  /* 
   * To check an item exist in checkbox options.
   */
  const setAccess = (access_type, value) => {
    let obj = newRole.permissions[access_type]
    if(!obj){
      newRole.permissions[access_type] = {}
      obj = {}
    }
    if (obj[value]) {
      delete obj[value]
      delete newRole.permissions.locale?.[access_type]
    }else {
      obj[value] = true
    }
    newRole.permissions[access_type] = obj
    setNewRole({...newRole})
  }

  const setRW = (access_type, sid, rw) => {
    let obj = newRole.permissions[access_type][sid]
    obj = (typeof obj == "boolean"?{}:obj)
    if (obj[rw]) {
      delete obj[rw]
    }else {
      obj.read = (rw == 'write'?true:false)
      obj[rw] = true
    }
    newRole.permissions[access_type][sid] = obj
    setNewRole({...newRole})
  }

  const toggle = (access_type) => {
    newRole.access_type = access_type
    setNewRole({...newRole})
  }

  const setLocale = (language, permission_key) => {
    let languages = newRole.permissions[permission_key] || []
    if(languages.indexOf(language) > -1){
      languages = languages.filter(i => i !== language)
    }else{
      languages.push(language)
    }
    newRole.permissions[permission_key] = languages
    setNewRole({...newRole})
  }

  const onInputChange = (e) =>{
    setNewRole({...newRole, [e.target.name]:e.target.value})
  }

  const checkUserPermission = (permission_key, sid) =>{
    return CheckAccess.checkUserAccess(currentOrgMember, currentOrgMember?.permissions?.[permission_key], sid)
  }

  const Header=({component, cIndex}) =>{
    if(!component?.options){
      return null
    }

    const color = component?.settings?.bg_color || 'rgb(0, 199, 252)'
    const style = {borderLeft: `6px solid ${color}`}
    return(
      <div className='flex justspacebetween black p-5 font-15 br-grey m-b-10 m-t-10' 
        onClick={e=> index === cIndex ? setIndex(null) : setIndex(cIndex)}
        style={style}>
        <span className='m-l-10'>{component?.label}</span>
        { index === cIndex ? 
          <i className="fas fa-chevron-up black  m-r-10" onClick={e=>setIndex(null)}/>:
          <i className="fas fa-chevron-down black m-r-10" onClick={e=>setIndex(cIndex)}/>
        }
      </div>
    )
  }

  return(
    ReactDOM.createPortal(
      <Fragment>
        <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom border-rounded-10">
        
        <div className="flex justspacebetween modal-title">
          <h4 className="f18 text-white fw500 p12-10-15">{role.id?'Update':'Add'} Role</h4>
          <button className="btn-close" title="Close Modal" onClick={e=>toggleModal(e)}>
            ×
          </button>
        </div>

        <form>
          <div className="ml-section">
            <div className="ml-container ht-440 scroll-y">
              <label><b>Role</b></label>
              <input onChange={e=>onInputChange(e)} 
                className="ml-input ml-border ml-margin-bottom"
                type="text" placeholder="Role name" name="role" 
                required defaultValue={role.role}/>
              
              <label><b>Description</b></label>
              <textarea onChange={e=>onInputChange(e)} name="description"
                className="ml-input ml-border ml-margin-bottom" placeholder="Description" 
                defaultValue={role.description} required>
              </textarea>

              <label><b>Home Page</b></label>
              <select className="form-control ml-margin-bottom" 
                name="home_page_data_id" 
                defaultValue={role.home_page_data_id}
                onChange={e => onInputChange(e)}>
                <option>None</option>
                <OptionsObjList list={dataSources.inapp_home_pages.options} id_key="pk_id" label_key="label"/>
              </select>

              <span className="checkbox-container" onClick={() => setNewRole({...newRole, 'is_shareable':!newRole.is_shareable})}>
                <span>Add to Share List</span>
                <input type="checkbox" checked={newRole.is_shareable} readOnly/>
                <span className="checkbox-checkmark"/>
              </span>

              <div className="br-btm-grey m-b-10 m-t-10"/>

              <Fragment>
                <span className="checkbox-container" onClick={e => index === "5" ? setIndex(null) : setIndex("5")}>
                  <span>View all</span>
                  <input type="checkbox" checked={index === "5"} readOnly/>
                  <span className="checkbox-checkmark"/>
                </span>
                <Header component={orgJourneyComponents?.side_menu} cIndex="1"/>
                {(index === "1" || index === "5") && orgJourneyComponents?.side_menu?.options?.map((p, i)=>
                  <Fragment key={i}>
                    {checkUserPermission('menu', p.sid) &&
                      <>
                        <span className="checkbox-container ani-opc" onClick={e => setAccess('menu', p.sid)}>
                          <span>{p.label}</span>
                          <input type="checkbox" checked={newRole.permissions?.menu?.[p.sid]!=null} readOnly/>
                          <span className="checkbox-checkmark"/>
                        </span>
                        {newRole.permissions?.menu?.[p.sid]!=null &&
                          <div className="m-l-10 m-b-10">
                            <span className="m-r-10 font-15" onClick={e => setRW('menu', p.sid, 'read')}>
                              <i className={'m-r-5 far ' + (newRole.permissions?.menu?.[p.sid]?.read?'fa-check-circle':'fa-circle')}/> 
                              Read
                            </span>
                            <span className="font-15" onClick={e => setRW('menu', p.sid, 'write')}>
                              <i className={'m-r-5 far ' + (newRole.permissions?.menu?.[p.sid]?.write?'fa-check-circle':'fa-circle')}/> 
                              Write
                            </span>
                          </div>
                        }
                      </>
                    }
                  </Fragment>
                )}
              </Fragment>  

              <Fragment>
                <Header component={orgJourneyComponents?.element_ppt_mcj} cIndex="2"/>
                {(index === "2" || index === "5") && orgJourneyComponents?.element_ppt_mcj?.options?.map((p, i)=>
                  <Fragment key={i}>
                    {checkUserPermission('infant_ppt', p.sid) &&
                      <span className="checkbox-container ani-opc" onClick={e => setAccess('infant_ppt', p.sid)}>
                        <span>{p.label}</span>
                        <input type="checkbox" checked={newRole.permissions?.infant_ppt?.[p.sid]!=null} readOnly/>
                        <span className="checkbox-checkmark"/>
                      </span>
                    }
                  </Fragment>
                )}
              </Fragment>      

              <Fragment>
                <Header component={orgJourneyComponents?.pins_process_mcj} cIndex="3"/>
                {(index === "3" || index === "5") && orgJourneyComponents?.pins_process_mcj?.options?.map((p, i)=>
                  <Fragment key={i}>
                    {checkUserPermission('infant_process', p.sid) &&
                      <span className="checkbox-container ani-opc" onClick={e => setAccess('infant_process', p.sid)}>
                        <span>{p.label}</span>
                        <input type="checkbox" checked={newRole.permissions?.infant_process?.[p.sid]!=null} readOnly/>
                        <span className="checkbox-checkmark"/>
                      </span>
                    }
                  </Fragment>
                )}
              </Fragment>  

              <Fragment>
                <Header component={orgJourneyComponents?.mcj_portal} cIndex="4"/>
                {(index === "4" || index === "5") && orgJourneyComponents?.mcj_portal?.options?.map((p, i)=>
                  <Fragment key={i}>
                    {checkUserPermission('infant_portal', p.sid) &&
                      <span className="checkbox-container ani-opc" onClick={e => setAccess('infant_portal', p.sid)}>
                        <span>{p.label}</span>
                        <input type="checkbox" checked={newRole.permissions?.infant_portal?.[p.sid]!=null} readOnly/>
                        <span className="checkbox-checkmark"/>
                      </span>
                    }
                  </Fragment>
                )}
              </Fragment>  

              <LocaleIcon label="Set Langauage" 
                permissionKey="locale_translate" 
                setLocale={setLocale} 
                selectedLangauges={newRole.permissions?.locale_translate || []}/>

              <LocaleIcon label="Set Langauage Audit" 
                permissionKey="locale_audit"  
                setLocale={setLocale} 
                selectedLangauges={newRole.permissions?.locale_audit || []}/>
            </div>

            <div className="ml-button ml-block ml-section ml-padding bg-highlight white" 
              onClick={e=>{addNewRole(e, newRole)}}>
              <b>{role.id?'Update':'Create'}</b>
            </div>
            
          </div>
        </form>
      
        </div>
      </div>
    </Fragment>, document.body
  ))
}

const LocaleIcon = ({label, setLocale, selectedLangauges, permissionKey}) =>{

  const { isOpen: showLocaleModal, toggleModal: toggleLocaleModal} = useModal()
  
  const onLangaugeSelect = (locale) =>{
    setLocale(locale.sid, permissionKey)
  }

  return(
    <>
      <span className="m-l-35 white pill bg-highlight m-b-10" onClick={e=>{e.stopPropagation();toggleLocaleModal()}}>
        {label}
      </span>
      {showLocaleModal && <GenericModal component={LocaleList} title="Langauage"
        isOpen={showLocaleModal} toggleModal={toggleLocaleModal} 
        selectedItems={selectedLangauges} onSelect={onLangaugeSelect} 
        type="role_locale"/>
      }
    </>
  )
}

export default AddRole;