import React, { Fragment,useContext } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from "../../services/CommonService"

const CardMainMenu = ({activity, showMenu, disableClickEvent}) => (
  <div style={disableClickEvent} className="m-r-15">
    <i data-tip="Menu" onClick={e=>showMenu(e, activity)} className="fas fa-bars font-18"/>
  </div>
)

const MenuItem = ({id, link, className, icon, action, onSelect}) => {
  let linkAttribute = {'id':id,'to':link, 'className':className}

  return (
    <Link {...linkAttribute}  onClick={e=>onSelect && onSelect(e)}>
      <i className={icon}></i>
      <span className="font-13">{action}</span>
      <i className="fa fa-angle-right"></i>
    </Link>  
  )
}

const CardMenuInfo = ({formId, recordId, createdAt, userName}) => {
  const {state: { screen }, dispatch,} = useContext(AuthContext)

  return (
    <a href="">
      <i className="fas fa-info-circle"></i>
      <span className={(screen.width < 460 && 'lh-20')+' font-15'}>
        <small className="lred">since { Cs.formatUpdateDate(createdAt)}</small>
        <small className="m-l-10"> by {userName}</small>
        <small className="m-l-10">({formId}-{recordId})</small>
      </span>
      <i className="fa fa-angle-right"></i>
    </a>  
  )
}

const NavIcon = ({id, dataTip, link, className, icon, onSelect}) => {
  let linkAttribute = {'id':id, 'data-tip':dataTip, 'to':link, 'className':className}

  if(link){
    return (
      <Link {...linkAttribute}>
        <i className={icon}></i>
      </Link>  
    )
  }else{
    return (
      <span {...linkAttribute} onClick={e=>onSelect && onSelect(e)}>
        <i className={icon}></i>
      </span>
    )   
  }
  
}

export {MenuItem,CardMenuInfo,NavIcon,CardMainMenu}
