import React, {Fragment, useState, useContext, useEffect} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../Common/MenuItem"
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../Common/SortDirection'
import Cs from "../../services/CommonService"
import ConfirmMenu from "../Modals/ConfirmMenu"
import useModal from "../../hooks/useModal"
import OrganizationService from "./OrganizationService"
import SubscriptionsModal from '../Subscriptions/SubscriptionsModal'
import GenericModal from '../Modals/GenericModal'
import OrganizationPartnership from './OrganizationPartnership'
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons"
import {FontAwesomeLink} from "../Common/Button"
import {usePageTitle} from '../../hooks/pageHead'
import {OrgReportIcon} from './OrganizationReport'

let selectedOrganization = {}
let requestParams ={}
let dataSources = []
const OrganizationsTable = (props) => {
  useStyle('/css/tile-components.css', true)
  usePageTitle('Organizations')
  const params = props.match.params

  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])

  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal()
  const {isOpen:isPartnershipOpen, toggleModal:togglePartnershipModal} = useModal()
  const {isOpen:isSubscriptionOpen, toggleModal:toggleSubscriptionModal} = useModal()

  useEffect(() => {
    requestParams = {
      page:1,
      per_page:100,
      data_source:{
        type_of_use:'Organizational Use'
      },
      data_source_params: {
        data_source_id:[3678],
      }
    } 
    getAll()
  }, [params.type_of_use])

  const getAll = () =>{
    OrganizationService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        dataSources = res.data.data_source_list
        setList(res.data.organizations)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const onDelete = (org) => {
    selectedOrganization = org
    toggleDeleteModal()
  }

  const deleteOrg = (e) => {
    e.preventDefault();
    OrganizationService.delete(selectedOrganization.id).then((res) => {
      if (res.status == 204) {
        setList(list.filter(i => i.id !== selectedOrganization.id))
      }
    })
    toggleDeleteModal(e)
  }

  const updateOrg = (org, key) =>{
    let req = {
      id: org.id,
      [key]: !org[key]
    }
    OrganizationService.update(req).then((res) => {
      if (res.status == 204) {
        org[key] = !org[key]
        setList([...list])
      }
    })
  }

  const openSubscriptionModal = (o) =>{
    selectedOrganization = o
    toggleSubscriptionModal()
  }

  const resetOrganizationList = () => setList([...list])

  const openPartnershipModal = (o) =>{
    selectedOrganization = o
    togglePartnershipModal()
  }

  const tableData = list.map((o, k) => {
    let ds = o.data_source || {}
    let data = o.data || {}

    return(
      <TR key={k}>
        <TD className="text-gray">
          {o.record_id}
        </TD>
        <TD className="fw500">
          {data.name}
        </TD>
        <TD>
          {Cs.formatUpdateDate(o.created_at)}
        </TD>
        <TD>
          <span className="badge-2 bg-black-light-1">{o.members_count}</span>
        </TD>
        <TD className="font-18">
          <span className={o.is_active?'fas fa-check-circle':'far fa-circle'} onClick={e=> updateOrg(o, 'is_active')}/>
        </TD>
        <TD className="font-18">
          <span className="far fa-list-alt" onClick={e=>openSubscriptionModal(o)}/>
        </TD>
        <TD>
          {o.subscription?.id && 
            <div className="flex aligncenter">
              <LinkFontAwesomeIcon id="edit_organization" tooltip="Edit organization" link={`/organization/create/${o.id}`} css="fas fa-edit white">
              </LinkFontAwesomeIcon>

              {currentUser.current_member?.role_admin &&
                <LinkFontAwesomeIcon id="users_organization" tooltip="Users organization" link={`/${data.name}/members/${o.id}/true`} css="fas fa-users white">
                </LinkFontAwesomeIcon>
              }
                
              <LinkFontAwesomeIcon id="facilities_organization" tooltip="Facilities organization" link={`/health/list/facilities/${o.id}/${o.subscription?.id}`} css="fas fa-hospital white">
              </LinkFontAwesomeIcon>
              
              <OrgReportIcon organization={o}/>

              {/*organization -> 5 -> 'Training/Research Institute'*/}
              
              {o.data.organization != 5 &&
                <LinkFontAwesomeIcon id="partners_organization" tooltip="Partners organization" onSelect={()=>openPartnershipModal(o)} css="fas fa-handshake white">
                </LinkFontAwesomeIcon>
              }
              
              <LinkDeleteIcon id="delete_organization" tooltip="Delete organization" onSelect={()=>onDelete(o)}>
              </LinkDeleteIcon>
            </div>
          }
        </TD>
      </TR>
    )
  })

  return (
    <Fragment>
      <div id="ct">
      <div className="app_content">
        <div className="app-header flex justspacebetween m-t-15">
          <div className="flex coldir">
            <div className="app-title m-r-20">Organizations</div>
            <div className="font-15">
              List of Organizations - {list.length}
            </div>
          </div>

          <div className="flex aligncenter">
            <NavIcon id="new_org" dataTip="Create New Organization" 
              link="/organization/create"
              className="bg-highlight round-btn float-right m-r-5" 
             icon="fas fa-plus"/>
          </div>
        </div>
        
        <div className="app-content-section m-t-30 table-fix-head">
          <Table className="shadow-small">
            <THead>
              <TR>
                <TH onClick={e=>sort('record_id')}>
                  ID 
                  <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('data.name')}>
                  Name 
                  <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('created_at')}>
                  Date
                  <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('members_count')}>
                  Members
                  <SortDirection sort_column={requestParams.sort_column} column="members_count" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('is_active')}>
                  Active
                  <SortDirection sort_column={requestParams.is_active} column="is_active" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('is_active')}>
                  Subscription
                  <SortDirection sort_column={requestParams.is_active} column="is_active" reverse={requestParams.reverse}/>
                </TH>
                <TH>
                  Action
                </TH>
              </TR>
            </THead>
            <TBody>
              {tableData}
            </TBody>
          </Table>
        </div>
      </div>
      </div>

      <ConfirmMenu isOpen={isDeleteOpen} 
        toggleModal={toggleDeleteModal} 
        success={deleteOrg}/>
      
      {isSubscriptionOpen && 
        <GenericModal component={SubscriptionsModal} 
          toggleModal={toggleSubscriptionModal}
          organization={selectedOrganization} 
          resetOrganizationList={resetOrganizationList}
          title={selectedOrganization.data.name || 'Organization'}/>
      }

      {isPartnershipOpen &&
        <GenericModal component={OrganizationPartnership}
          toggleModal={togglePartnershipModal}
          organization={selectedOrganization} 
          title={selectedOrganization.data.name || 'Organization'}/>
      }
    </Fragment>
  )

}

export default OrganizationsTable;