import React from 'react'
import PrivateRoute from "../../routes/PrivateRoute"
import PublicRoute from "../../routes/PublicRoute"
import ContactListTable from './List/ContactListTable'
import ContactForm from './ContactForm'

const ContactsRouter = (props) => (
  <>	
    <PrivateRoute path="/list/contacts" searchString={props.searchString} component={ContactListTable} exact />
    <PublicRoute path="/contact/:id?" component={ContactForm} exact />
  </>
)

export default ContactsRouter;