import React, {useState, Fragment, useContext, useEffect} from 'react'
import FilterSlider from "../../../Common/FilterSlider"
import {MemberFilter, ImageUploadFilter} from '../../../../constants/DataSourceList'
import {CheckBox} from "../../../Common/FormInput"
import useModal from '../../../../hooks/useModal'
import SortDirection from '../../../Common/SortDirection'
import useSort from '../../../../hooks/useSorting'
import FormHelper from '../../../FormBuilder/FormHelpers'

const Filters = ({filter, setFilter, dataSource, currentUser, getFacilityList, requestParams}) =>{
  const {sort} = useSort(requestParams, getFacilityList)

  const stringFilter = (value, name) => {
    if(value!=null && value != filter[name]){
      setFilter({...filter, [name]:value})
    }else{
      delete filter[name]
      setFilter({...filter})
    }
  }

  const filterExists = (value, name) => FormHelper.existsListString(value, filter[name])

  return (
    <div className="filter-box-scroll p-5">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          General
        </h5>

        <CheckBox list={MemberFilter}
          labelKey="label" valueKey="sid" 
          toggle={stringFilter} exists={filterExists} 
          name="assignment_filter"/>

        <CheckBox list={ImageUploadFilter}
          labelKey="label" valueKey="sid" 
          toggle={stringFilter} exists={filterExists} 
          name="image_filter"/>
      </div>
    </div>
  )
}

const FilterBtn = (props) =>{
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()

  return(
    <>
      <span data-tip="Filter" 
        className="bg-highlight round-btn m-r-5" 
        onClick={()=>toggleFilterModal()}>
          <i className="fas fa-sliders-h"/>
      </span>

      {isFilterOpen &&
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={toggleFilterModal}
          {...props}/>
      }
    </>
  )
}

export {Filters, FilterBtn};