import React, { useEffect, useState } from "react";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner";
import DynamicForm from "../../../../components/FormBuilder/DynamicForm";
import FormTemplateId from "../../../../constants/FormTemplateList";
import TreatmentService from "../TreatmentService";
import FormContainer from '../../../../components/FormBuilder/FormContainer';

let formMode = null;
let treatment = {};
let data = {};
let formFn = {};

function TreatmentForm(props) {
  const { state: authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});
  let params = props.match.params;

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    TreatmentService.get(params.id)
      .then((res) => {
        if (res.status == 200) {
          treatment = res.data.treatment[0];
          data = treatment.data || {};
          //params.form_template_id = treatment.activity_form_id;

          /*TODO Render Delay Issue*/
          setFormRendered(true);
        }
      })
      .catch(function (res) {
        treatment = null;
      });
  };

  useEffect(() => {
    treatment = {};
    data = {};
    formFn = {
      form_type: "edu_team_journey.treatment",
      journey_category_id: params.journey_category_id,
    };
    formMode = "create-form-submissions";
    data = {};

    if (params.id) {
      get();
      formMode = "edit-form-submissions";
    } else if (!isFormRendered) {
      setFormRendered(true);
    }
  }, []);

  const create = (treatment) => {
    treatment.organization_id = authState.user.current_organization_id
    treatment.created_by = authState.user.id;
    treatment.updated_by = authState.user.id;
    treatment.journey_profile_id = params.journey_profile_id;
    treatment.treatment_form_id = params.treatment_form_id;
    treatment.treatment_type = params.treatment_type;
    treatment.treatment_id = params.treatment_id;
    TreatmentService.create(treatment)
      .then((res) => {
        if (res.status == 201) {
          onSubmitPageRedirect();
        }
      })
      .catch(function (res) {});
  };

  const update = (treatment) => {
    treatment.updated_by = authState.user.id;
    TreatmentService.update(treatment)
      .then((res) => {
        if (res.status == 204) {
          onSubmitPageRedirect();
        }
      })
      .catch(function (res) {});
  };

  const onSubmitPageRedirect = () => {
    if(props.isPopupMode){
      props.onFormSubmit()
    }else{
      props.history.push(
        `/health/treatment/${params.journey_profile_id}`
      ); 
    }
  };

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm
          formMode={formMode}
          formId={params.treatment_form_id}
          form={treatment}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}
        />
      </FormContainer>
    );
  } else {
    return null;
  }
}

export default TreatmentForm;
