import React, {Fragment, useState, useEffect} from "react"
import {Link} from "react-router-dom"
import useStyle from '../../../../hooks/useStyle'
import useSort from '../../../../hooks/useSorting'
import SortDirection from '../../../Common/SortDirection'
import Cs from "../../../../services/CommonService"
import useModal from "../../../../hooks/useModal"
import {NotificationPopup} from "../../../Common/NotificationPopup"
import AssessmentTable from "./AssessmentTable"
import AssessmentService from "../AssessmentService"
import { Table, THead, TH, TR, TD, TBody } from "../../../Common/TableView"

let requestParams = null
const UserAssessments = (props) => {

  const [assessments, setAssessments] = useState([])
  const [loading, setLoading] = useState(false)

  const { isOpen:isHelpMessageOpen, toggleModal:toggleHelpMessage } = useModal()

  useEffect(() => {
    requestParams = {
      'page':1,
      'per_page':100,
      'sort_column':'updated_at',
      'sort_direction':'desc',
    }

    getAssessmentList()

    return () => {
      requestParams = {} 
    }
  }, [props.searchString])

  let getAssessmentList = () => { 
    setLoading(true)
    AssessmentService.getUserAssessments(requestParams).then((res)=>{
      if(res.status == 200){
        setAssessments(res.data.assessments)
        res.data.assessments.length == 0 && !requestParams.search && toggleHelpMessage()
        setLoading(false)
      }
    })
  }

  const TableData = ({assessments}) => assessments && assessments.map((o, k) =>{
    let statusLabel = null
    let status = null
    let color = null
    if(o.response){
      if(o.response?.total_score){
        statusLabel = 'Grade published'
        status = 'graded'
        color ='green'
      }else{
        statusLabel = 'Grading is not posted yet'
        status = 'not_graded'
        color= 'blue'
      }
    }else{
      statusLabel = 'Quiz is active, but you have not opened it yet'
      status = 'not_submitted'
      color='red'
    }

    return(
      <TR key={k}>
        <TD className="text-gray" data-title="Id">{o.record_id}</TD>
        <TD className="fw500" data-title="Name of Quiz">{o.data.subject_name}</TD>
        <TD data-title="Submission Date">
          {Cs.formatUpdateDate(o.data.submission_deadline_formatted_date)}
        </TD>
        <TD data-title="Preview" >
          {o.is_published && status == 'not_submitted' &&  
            <Link id="question_form_icon" to={`/question/${o.id}?is_preview=true`} data-tip="Preview Question Form">
              <i className="far fa-file"/>
            </Link>
          }
        </TD>
        <TD>
          {o.response?.total_score?`${o.response?.total_score}/${o.total_score}`:null}
          <div>{statusLabel}</div>
        </TD>
      </TR>
    )
  })

  return (
    <Fragment>
       <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20">Quiz Assignments</div>
              <div className="font-15">
                {assessments.length} Items
              </div>
            </div>

            <div className="flex aligncenter">
            </div>  
          </div>

      {isHelpMessageOpen ? 
        <NotificationPopup title="Current Status" 
        message="No quiz items have been assigned yet." 
        onSuccess={toggleHelpMessage} isOpen={isHelpMessageOpen} 
        iconClass="fas fa-circle-info lred"
        toggleModal={toggleHelpMessage}/>
        :
        <div className="app-content-section m-t-30">
          <div className="row">
          {loading && <div className="spinner"/>}
          <>
            <Table className="shadow-small">
              <THead>
                <TR>
                  <TH>Id
                  </TH>
                  <TH>Name of Exam 
                  </TH>
                  <TH>Submission Date
                  </TH>
                  <TH>Response
                  </TH>
                  <TH>Status
                  </TH>
                </TR>
              </THead>
              <TBody>
                <TableData assessments={assessments} />
              </TBody>
            </Table>
          </>
          </div>
        </div>
      }
      </div>
      </div>
    </Fragment>
  )

}

export default UserAssessments;