import React, { useEffect, useState, useContext} from 'react';
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import DynamicForm from '../../../FormBuilder/DynamicForm';
import FormTemplateId from '../../../../constants/FormTemplateList'
import StepAssessmentService from "../StepAssessmentService";
import querystringify from "querystringify";
import FormContainer from '../../../FormBuilder/FormContainer';

let formMode = null; 
let step_assessment = {}; 
let data = {}; 
let formFn = {};  

function StepAssessmentForm(props) {
  const { state:{user:currentUser} } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;
  let queryParam = querystringify.parse(props.location.search);
  
  const get = () => {
    StepAssessmentService.get(params.id).then((res) => {
      if(res.status==200){
        step_assessment = res.data.step_assessment;
        data = step_assessment.data || {};

        setFormRendered(true);
      }
    }).catch(function(res){
       step_assessment = null;
    })
  }
  
  useEffect(() => { 
    step_assessment = {}; 
    data = {}; 
    formFn = {
      'form_type':'carrier_training.step_assessment', 
      'journey_category_id':params.journey_category_id,
      'editPermission':queryParam.edit
    } 
    formMode = 'create-form-submissions';
    data = {}; 
    
    if(params.id){
      get()
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, []);

  const create = (step_assessment) => {
    step_assessment.assessment_id = params.assessment_id
    step_assessment.step_assessment_type = params.type;
    step_assessment.step_assessment_form_id = FormTemplateId.TrainingStepAssessment;  
    StepAssessmentService.create(step_assessment).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (step_assessment) => {
    StepAssessmentService.update(step_assessment).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push(`/career_training/assessments/${params.journey_category_id}/${params.journey_profile_id}`)
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
       <DynamicForm formMode={formMode} formId={FormTemplateId.TrainingStepAssessment} form={step_assessment} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
      </FormContainer>
    ) 
  }else{
    return null;
  }
  
}

export default StepAssessmentForm;