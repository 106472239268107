import React, { useState, Fragment } from 'react'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, Description, requiredMsg} from '../FieldLabel'
import {CueTip} from '../../../components/Common/CueTooltip';

const SEARCH_WAIT_INTERVAL = 500
let searchKeyword = null
let timer = null

const InputMultiSelectSearch = ({renderField, field, position, formData, readOnly, errors, forceUpdate, formFn, isFormWizard, openFieldLabelModal, currentLocale}) => {
  let pagenate = formFn[field.client_id+'_pagenate'] || {}
  let resultPath = formFn[field.client_id+'_path']|| 'label'
  let listKey = field.client_id+'_array'
  formData[listKey] = formData[listKey] || []

  let [modelarray, setModelarray] = useState(formData[listKey])
  let [list, setList] = useState([])
  let [loading, setLoading] = useState(false)
  const [noResultFound, setNoResultFound] = useState(false);

  if(!renderField){
    return null
  }

  let objList = {}

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only),
    'className':'form-input form-input-gray form-input-full'
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip
  }
  
  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes)

  let validation = {
    'required': field.required
  }
      
  const handleChange=(event)=>{
    clearTimeout(timer)
    searchKeyword = event.target.value
    pagenate.page = 1
    timer = setTimeout(onSearch, SEARCH_WAIT_INTERVAL)
  }

  const onSearch = () => {
    if(searchKeyword && searchKeyword != ''){
      try{
        setNoResultFound(false)
        setLoading(true)
        formFn['get_'+field.client_id](searchKeyword, onSuccessCallback, onFailureCallback)
        //showResultSet = true; 
        //onChange(search);
      }catch(e){
        setLoading(false)
        console.log(e)
      }
    }else{
      setList([])
      formData[field.client_id] = null
      setError(true)
    }
  }

  const onSuccessCallback = (data) =>{
    if(data.length == 0) setNoResultFound(true);
    setLoading(false)
    setList(data)
  }

  const onFailureCallback = (error) =>{
    setLoading(false)
    setList(error)
  }

  const next = (e) => {
    if((pagenate.page+1) <= pagenate.pageSize){
      pagenate.page += 1; 
      onSearch();
    }
  }

  const previous = (e) => {
    if((pagenate.page - 1) > 0){ 
      pagenate.page -= 1; 
      onSearch();
    }
  }

  /* 
   * To check an item exist in checkbox options.
   */
  const toggle = (item) => {
    let selectedItems = formFn['toggle_'+field.client_id](item)
    setModelarray([...selectedItems])
    setError()
  }

  const exists = (item) => {
    return formFn['exist_'+field.client_id](item)
  }

  const onSelectItem = (item, e) =>{
    formFn['select_'+field.client_id](item)
  }

  const bindWindowClickEvent = () =>{
    let el = document.getElementById(field.client_id+'_result_set')
    el.classList.remove("hidden")
    window.addEventListener('click',eventFunction)
  }

  const eventFunction = (event) => {
    try{
      if(document.getElementById(field.client_id+'_container').contains(event.target)){
        console.log(field)
      }else{
        let el = document.getElementById(field.client_id+'_result_set');
        el.classList.add("hidden");
        window.removeEventListener("click",eventFunction);
        forceUpdate();
      }
    }catch(e){
    
    }
  }

  const close = () =>{
    let el = document.getElementById(field.client_id+'_result_set');
    el.classList.add("hidden");
  }

  FormHelper.setDisabled(field, inputAttributes);

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[listKey].length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{requiredMsg}</div>
        </div>
      )
    }else if(noResultFound){
      return(
        <div className="errormsg">
          <div>No Result Found</div>
        </div>
      )
    }
    return null;
  }

  return (
    <div {...parentAttributes}>
      <div className="flex coldir m-b-20 tooltip">
        <CueTip 
          positionCss={position>1?'top':'bottom'}
          tooltip={field.tooltip}
          currentLocale={currentLocale}
          locale={field.locale?.tooltip}/>
        <FieldLabel field={field} labelAttributes={labelAttributes} 
          currentLocale={currentLocale}
          openFieldLabelModal={openFieldLabelModal}/>
        {loading && <div className="spinner"></div>}
        <div className={`form-input-gray fstElement fstMultipleMode ${readOnly && 'input-readonly'}`} id={field.client_id+ '_container'}>
        {list && <OptionsObjList list={list} toggle={toggle} handleChange={handleChange}
          exists={exists} modelarray={modelarray} field={field} resultPath={resultPath}
          bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes}
          close={close} onSelectItem={onSelectItem} pagenate={pagenate} next={next} 
          previous={previous}/> 
        }   
        </div>  
        <Description description={field.description} currentLocale={currentLocale}
          locale={field.locale?.description}/>
        <Error/>
      </div>
    </div> 
  )
}

const OptionsObjList = ({ pagenate, next, previous, onSelectItem, list, toggle, exists, object, modelarray, field, bindWindowClickEvent, inputAttributes, handleChange, resultPath, close}) => {

  const selectableItems = list.map((o, k) =>  
    <span key={k} className={`fstResultItem ${exists(o)?'fstSelected':''} `}
     onClick={e => toggle(o)} title={o.description}>
      {o[resultPath]}
    </span>
  )

  const selectedItems = modelarray.map((i, k) =>  
    <div key={k} className="fstChoiceItem">
      <span onClick={e => onSelectItem(i, e)}>{i[resultPath]}</span>
      <span className="fstChoiceRemove" type="button" onClick={e => toggle(i, e)}>×</span>
    </div>
  )

  return(
    <Fragment>
      <div className="fstControls" onClick={e => bindWindowClickEvent(e)}>
        <div className="fstSelected">{selectedItems}</div>
        <input {...inputAttributes} onChange={e=>handleChange(e)} 
        className="fstQueryInput" placeholder="Type here to find available choices" 
        data-tip data-for={`tooltip_${field.client_id}`}/>
      </div>
      <div className="fstResults hidden" id={field.client_id+ "_result_set"}>
        <span className="fstClose badge-2" onClick={()=>close()}>Close</span>
        { (pagenate.pageSize>1 && list.length>0) &&
          <ul className="pager">
            <li onClick={e => previous(e)}><span>Previous</span></li>
            <li onClick={e => next(e)}><span>Next</span></li>
            <small className="m-l-15">{pagenate.page} of {pagenate.pageSize}</small>
          </ul> 
        }
        {selectableItems}
      </div>
    </Fragment>
  )
}

export default InputMultiSelectSearch;
