import React ,{Fragment, useState, useEffect, useContext} from "react"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import CommonService from "../../../services/CommonService"
import { ChatGrayIcon, CheckListGrayIcon, EnvelopeGrayIcon, HamburgerMenuIcon, MaximizeIcon } from "../../Common/ImageIcons"
import {FormFieldTable} from "../../HealthJourney/UserRoles/NotificationPill"
import {usePageTitle} from '../../../hooks/pageHead'

const List = (props) => {
  usePageTitle('Screening Camp')
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
 
  let {list, formFieldsList} = props;
  let {data, ds} = list;
  let formFields = formFieldsList[list.form_id] || []

  list.updated_at = CommonService.formatUpdateDate(list.updated_at)
  
 // data.baby_age = CommonService.getAge(data.dob_baby_formatted_date)
  
  const cardStyle = {
    backgroundColor:
      data.background_color || data.header_background || "#607D8B",
  }

  useEffect(() => {
    ReactTooltip.rebuild()
  })

  if (!data) return null;

  const MailTag = () => (
    <a href={`mailto: ${list.id}-team_activity@reply.cuetree.com?subject=Education lists-${data.name}-${list.record_id}`}
      target="_blank">
      <i className="fas fa-envelope font-18 text-muted" aria-hidden="true"></i>
    </a>
  )

  const Header = () => (
    <div className="card-title card-title-gray flex p-b-15 p20 coldir">
      <h4 className="fw700 f18 card-heading flex coldir text-left">
        {data.title || data.name}
      </h4>
      <div className="f14 m-t-10 fw500"> &nbsp;-&nbsp;{list.updated_at}</div>
      <MaximizeIcon onSelect={(e) => props.routeList(list)}/>
    </div>
  )

  const Footer = () => {
   return(
    <div className="card-footer flex justspacebetween p20 aligncenter">
      <ul className="flex aligncenter">
        <li className="m-r-25">
          <ChatGrayIcon onSelect={(e) => props.addNotes(e, list)}/>
        </li>
        <li className="m-r-25">
          <CheckListGrayIcon onSelect={(e) => props.addChecklist(e, list)}/>
        </li>
        <li className="m-r-25">
          <EnvelopeGrayIcon link={`/health/incoming/emails/treatment/${list.id}`}/>
        </li>
      </ul>
      <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, list)}/>
    </div>
  )}


  const ListCard = () => {
    return (
      <div className="bg-white border-rounded-10 card-shadow m-b-15">
        <Header />
        <div className="card-content p20">
          <table className="b-tab-table active scroll-auto ht-400">
            <tbody>
              <tr>
                <FormFieldTable formFields={formFields} data={data} dataSource={ds}/>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    )
  }
  
  return <ListCard />;
}

export default List;