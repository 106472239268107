import React, {Fragment, useState, useContext, useEffect, useMemo} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../../Common/MenuItem"
import useStyle from '../../../hooks/useStyle'
import useSort from '../../../hooks/useSorting'
import SortDirection from '../../../components/Common/SortDirection'
import Cs from "../../../services/CommonService"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import useModal from "../../../hooks/useModal"
import OrganizationMemberService from "./OrganizationMemberService"
import GenericModal from "../../Modals/GenericModal"
import AddMember from "./AddMember"
import MemberJourneyList from "./MemberJourneyList"
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { AddBtn, FontAwesomeBtn } from "../../Common/Button"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../Common/ImageIcons"
import {usePageTitle} from '../../../hooks/pageHead'
import CheckAccess from '../../HealthJourney/Roles/CheckAccess' 
import querystringify from "querystringify"
import {GeoDataIcon} from "./MemberGeoDataModal"
import PaginationSM from '../../Common/PaginationSM'
import {Filters, FilterBtn} from './Filter'

let selectedMember = {}
let requestParams ={}
const MemberList = (props) => {
  usePageTitle('Member List')
  
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  const {isPopupMode} = props

  const {state: { user: currentUser }, dispatch} = useContext(AuthContext)

  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])
  const [organization, setOrganization] = useState()
  const [subscription, setSubscription] = useState()
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [filter, setFilter] = useState({})

  const {isOpen:isMemberJourneyOpen, toggleModal:toggleMemberJourney} = useModal()
  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal()
  const {isOpen:isAddMemberOpen, toggleModal:toggleAddMemberModal} = useModal()

  const permission = useMemo(() => 
    CheckAccess.getMenuPermission(currentUser, 'facilities')
  , [currentUser.id])

  useEffect(() => {
    if(requestParams.page !== currentpage){
      setParams(currentpage)
      getAll()
    }
  }, [currentpage])

  useEffect(() => {
    setParams(1) //set page to 1
    getAll()
  }, [props.searchString, filter])

  const setParams = (page = currentpage, responseType) =>{
    setCurrentpage(page)
    requestParams = {
      page:currentpage,
      per_page:25,
      filter:filter,
      search:props.searchString,
      organization_id:params.organization_id,
      subscription_id:params.subscription_id,
      medical_facility_id:params.medical_facility_id,
      sub_facility_id:params.sub_facility_id,
      role_id:queryParam.role_id,
      response_type:responseType
    } 
  }

  const getAll = () =>{
    if(permission.read){
      OrganizationMemberService.getAll(requestParams).then((res)=>{
        if(res.status == 200){
          if(requestParams.response_type === 'csv'){
            requestParams.response_type = null;
            let dlnk = document.getElementById('dwnldLnk');
            let csv = 'data:text/csv;charset=utf-8,'+escape(res.data.organization_members);
            dlnk.download =  'CSV_'+ (new Date().getTime()) +'.csv';
            dlnk.href = csv;
            dlnk.click();
          }else{
            setPagemeta(res.data.meta)
            setList(res.data.organization_members)
            setOrganization(res.data.organization)
            setSubscription(res.data.organization?.subscription)
            setIsLoading(false)
          }
        }
      })
    }
  }

  const {sort} = useSort(requestParams, getAll)

  const downloadAsCsv = () => {
    requestParams.page = 1;
    requestParams.per_page = 1000;
    requestParams.response_type = 'csv';
    getAll();
  }

  const onDelete = (org) => {
    toggleDeleteModal()
    selectedMember = org
  }

  const deleteMember = (e) => {
    setIsLoading(true)
    e.preventDefault();
    OrganizationMemberService.delete(selectedMember.id).then((res) => {
      if (res.status == 204) {
        setList(list.filter(i => i.id !== selectedMember.id))
        setIsLoading(false)
      }
    })
    toggleDeleteModal(e)
  }
  
  const openAddMember = (member) =>{
    selectedMember = member || {}
    toggleAddMemberModal()
  }

  const addMember = (e, member, on_error) => {
    e && e.preventDefault()
    member.form_type = 'org_member'
    member.form_id = 547
    if(member.id){
      OrganizationMemberService.update(member).then(({status, data}) => {
        if (status == 204) {
          toggleAddMemberModal()
        }else if(status == 403){
          on_error(data.error)
        }
      })
    }else{
      member.organization_id = params.organization_id
      member.medical_facility_id = (member.medical_facility_id || params.medical_facility_id)
      member.is_active = true
      member.is_admin = params.admin
      OrganizationMemberService.create(member).then(({status, data}) => {
        if (status == 201) {
          setList([...data.organization_members, ...list])
          toggleAddMemberModal()
        }else if(status == 403){
          on_error(data.error)
        }
      })
    }
  }

  const updateMember = (member, key) =>{
    setIsLoading(true)
    let req = {
      id: member.id,
      [key]: !member[key]
    }
    OrganizationMemberService.update(req).then((res) => {
      if (res.status == 204) {
        member[key] = !member[key]
        setList([...list])
        setIsLoading(false)
      }
    })
  }

  const updatePasscode = (member_id) =>{
    setIsLoading(true)
    OrganizationMemberService.update({
      'id':member_id,
      'context':'reset_otp'
    }).then((res) => {
      if (res.status == 204) {
        alert('Success')
        setIsLoading(false)
      }
    })
  }

  const openMemberJourney = (m) =>{
    selectedMember = m
    toggleMemberJourney()
  }

  const sendInvite = (id) =>{
    setIsLoading(true)
    OrganizationMemberService.sendInvite({
      'id':id,
      'organization_id':params.organization_id
    }).then(({status})=>{
      if(status === 201){
        alert("Success")
        setIsLoading(false)
      }
    })
  }

  const refershReport = async () =>{
    setIsLoading(true)
    const {status} = await OrganizationMemberService.updateReport({
      'organization_id':params.organization_id
    })
    if(status === 201){
      alert("Success")
    }
    setIsLoading(false)
  }

  const tableData = list.map((o, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray">
          {o.record_id}
        </TD>
        <TD className="fw500">
          {o.email || o.phone_no}
          <div>{o.user?.name}</div>
        </TD>
        <TD>
          {Cs.formatUpdateDate(o.created_at)}
        </TD>
        <TD>
          {Cs.emailStatus(o)}
        </TD>
        <TD>
          {o.facility_member_mappings?.map((f, i)=>
            <span className="badge-2" key={i}>{f.name}</span>
          )}
        </TD>
        <TD>
          {o.roles}
          {o.role_admin?' Org Admin':''}
        </TD>
        <TD>
          <span className={o.is_active?'fas fa-check-circle':'far fa-circle'} onClick={()=> updateMember(o, 'is_active')}/>
        </TD>
        <TD>{o.report?.journey}</TD>
        <TD>{o.report?.journey_draft}</TD>
        <TD>{o.report?.activity}</TD>
        <TD>{o.report?.schedule_missed}</TD>
        <TD>{o.report?.lat_long}</TD>
        <TD>{Cs.formatUpdateDate(o?.report?.last_login_date)}</TD>
        {permission?.write &&
          <TD className="">
            <div className="flex aligncenter">
              <LinkFontAwesomeIcon onSelect={e=>openMemberJourney(o)} css="far fa-list-alt white"/>
              <LinkFontAwesomeIcon id="edit_members" tooltip="Edit members" onSelect={()=>openAddMember(o)} css="fas fa-edit white"/>
              <LinkDeleteIcon id="delete_members" tooltip="Delete members" onSelect={()=>onDelete(o)}/>
              <LinkFontAwesomeIcon tooltip="Send SMS" onSelect={()=>sendInvite(o.id)} css="fas fa-sms white"/>
              <LinkFontAwesomeIcon tooltip="Reset Passcode" onSelect={()=>updatePasscode(o.id)} css="fas fa-user-shield white"/>
              <GeoDataIcon organizationId={o.organization_id} 
                medicalFacilityId={o.medical_facility_id} 
                memberUserId={o.user_id}/>
            </div>
          </TD>
        }
      </TR>
    )
  })

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          {!isPopupMode ?
            <div className="app-header flex justspacebetween m-t-15">
              <div className="flex coldir">
                <div className="app-title m-r-20">{params.organization}</div>
                <div className="font-14">List of Members - {pagemeta.total}</div>
                <div className="font-14">Page - {currentpage}/{pagemeta.last_page}</div>
              </div>
              <div className="flex aligncenter">
                {permission?.write ?
                  <>
                    <NavIcon id="new_member" dataTip="Add New Member" 
                      onSelect={()=>openAddMember()}
                      className="bg-highlight round-btn float-right m-r-5" 
                      icon="fas fa-plus"/>

                    <NavIcon id="send_invite" dataTip="Send Invite" 
                      onSelect={()=>sendInvite()}
                      className="bg-highlight round-btn float-right m-r-5" 
                      icon="fas fa-sms"/>

                    <NavIcon id="csv" dataTip="CSV Download" 
                      onSelect={()=>downloadAsCsv()}
                      className="bg-highlight round-btn float-right m-r-5" 
                      icon="fas fa-download"/>

                    <NavIcon dataTip="Refersh Report" 
                      onSelect={()=>refershReport()}
                      className="bg-highlight round-btn float-right m-r-5" 
                      icon="fas fa-refresh"/>
                  </>
                  : null
                }
                <FilterBtn filter={filter} setFilter={setFilter} 
                  organizationId={params.organization_id}
                  requestParams={requestParams}
                  currentUser={currentUser}
                  className="bg-highlight round-btn float-right m-r-5"/>
              </div>
            </div>
            :null
          }
        {isLoading ? <div className="spinner"/> : null}
        <div className="app-content-section m-t-30 table-responsive">
          <Table className="shadow-small">
            <THead>
              <TR>
                <TH onClick={()=>sort('record_id')}>
                  ID 
                  <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('data.name')}>
                  User 
                  <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('updated_at')}>
                  Date
                  <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('is_email_sent')}>
                  Status
                  <SortDirection sort_column={requestParams.sort_column} column="is_email_sent" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('record_id')}>
                  Facility
                  <SortDirection sort_column={requestParams.record_id} column="record_id" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('roles')}>
                  Roles
                  <SortDirection sort_column={requestParams.is_active} column="is_active" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('is_active')}>
                  Active
                  <SortDirection sort_column={requestParams.is_active} column="is_active" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('report.journey')}>
                  Journeys guided
                   <SortDirection sort_column={requestParams.sort_column} column="report.journey" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('report.journey_draft')}>
                  Journeys Draft
                   <SortDirection sort_column={requestParams.sort_column} column="report.journey_draft" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('report.activity')}>
                  Journeys updates
                   <SortDirection sort_column={requestParams.sort_column} column="report.activity" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('report.schedule_missed')}>
                  Missed entries
                   <SortDirection sort_column={requestParams.sort_column} column="report.schedule_missed" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('report.lat_long')}>
                  Locations
                   <SortDirection sort_column={requestParams.sort_column} column="report.lat_long" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={()=>sort('report.last_login_date')}>
                  Login Date
                   <SortDirection sort_column={requestParams.sort_column} column="report.last_login_date" reverse={requestParams.reverse}/>
                </TH>
                {permission?.write &&
                  <TH>
                    Action
                  </TH>
                }
              </TR>
            </THead>
            <TBody>
              {tableData}
            </TBody>
          </Table>
        </div>
        <PaginationSM
          className="pagination-bar"
          currentPage={currentpage}
          totalItems={pagemeta.total}
          itemsPerPage={pagemeta.per_page}
          onPageChange={setCurrentpage}
          siblingCount={2}/>
        </div>
      </div>
      {permission?.write &&
        <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteMember}/>
      }
      {permission?.write && isAddMemberOpen && 
        <GenericModal component={AddMember} isOpen={isAddMemberOpen} 
          toggleModal={toggleAddMemberModal} onSubmit={addMember} editMember={selectedMember}
          organizationId={params.organization_id} currentUser={currentUser}
          medicalFacilityId={params.medical_facility_id}
          subscriptionId={subscription?.id}/>
      }
      {isMemberJourneyOpen && 
        <GenericModal component={MemberJourneyList} 
          isOpen={isMemberJourneyOpen} toggleModal={toggleMemberJourney}  
          params={params} member={selectedMember}
          title="Journeys" widthCss="w-100p"/>
      }
      <a id='dwnldLnk' download='csv.csv' target="_blank" className="hidden" /> 
    </Fragment>
  )

}

export default MemberList;