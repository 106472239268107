import React, { useEffect, useState, useContext } from 'react';
import querystringify from "querystringify";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import DynamicForm from '../../../components/FormBuilder/DynamicForm';
import FormTemplateId from '../../../constants/FormTemplateList'
import HelpService from '../HelpService';
import FormContainer from '../../../components/FormBuilder/FormContainer';

let formMode = null; 
let formId = null; 
let help = {}; 
let data = {}; 
let formFn = {};  

function HelpForm(props) {
  
  const { state:authState, dispatch } = useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  const state =  props.location.state
  let params = props.match.params;
  let currentUser = authState.user;
  let queryParam = querystringify.parse(props.location.search);

  /*TODO: Move API to Router to load data before view*/
  const getById = (id) => {
    HelpService.get(id).then((res) => {
      if(res.status==200){
        help = res.data.help;
        data = help.data || {};
        setFormRendered(true);
      }
    }).catch(function(res){
      help = null;
    })
  }
  
  useEffect(() => { 
    formMode = 'create-form-submissions';
    data = {}; 
    help = {}; 
    formFn = {'form_type':'help'};
    formFn.editPermission = true;

    if(state)
      Object.assign(data, state)
    
    if(params.id){
      getById(params.id)
      formMode = 'edit-form-submissions';
    }else if(!isFormRendered){
      setFormRendered(true);
    }
  }, []);


  const create = (help) => {
    help.created_by = currentUser.id;
    help.is_user_suggested = true;
    help.data.page = queryParam.page;
    help.parent_id = queryParam.parent_id;
    HelpService.create(help).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (help) => {
    HelpService.update(help).then((res) => {
      if(res.status==204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.push("/help")
  }
  
  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.HealthHelpRegistration} form={help} data={data} 
        formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
        setPost={setPost} />
      </FormContainer>
    );  
  }else{
    return null;
  }
  
}

export default HelpForm;