import React, {Fragment,useState} from "react";
import {Link} from "react-router-dom";
import useStyle from '../../../hooks/useStyle';
import useSort from '../../../hooks/useSorting';
import SortDirection from '../../Common/SortDirection'
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc';
import arrayMove from '../../Common/arrayMove';
import Cs from "../../../services/CommonService";
import {PillDsList,PillDsField} from '../../HealthJourney/UserRoles/NotificationPill'
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../Common/ImageIcons";

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt"></span>);

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>;
})

const ScreeningListTable = ({lists, requestParams, selectedList, getScreeningList, toggleDeleteModal}) => {

  const {sort} = useSort(requestParams, getScreeningList);
  
  const [currentAssessment, setCurrentAssessment] = useState();

  const TableData = ({lists}) => lists && lists.map((o, k) =>
    <TR key={k}>
      <TD className="text-gray" data-title="Id">{o.record_id}</TD>
      <TD data-title="Created Date">{Cs.formatUpdateDate(o.updated_at)}</TD>
      <TD data-title="Form Id">{o.form_id}</TD>
      <TD data-title="Form Type">{o.form_type}</TD>
      <TD data-title="Created By">{o.user?.name}</TD>
      <TD data-title="Options" >
        <div className="flex aligncenter">
          <LinkFontAwesomeIcon id="edit_screening_contents" tooltip="Edit screening contents" link={`/screening_lists/create/${o.screening_event_id}/${o.form_type}/${o.form_id}/${o.id}`} css="fas fa-edit text-white">
          </LinkFontAwesomeIcon>
          <LinkDeleteIcon id="delete_screening_contents" tooltip="Delete screening contents" onSelect={(e)=>toggleDeleteModal(e)}>
          </LinkDeleteIcon>
        </div>
      </TD>
    </TR>
  )

  return (
    <div className="col-xs-12 m-t-10">
      <SortableContainer distance={1} lockAxis="y" useDragHandle>
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH onClick={e=>sort('record_id')}>Id
                <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
              </TH>
              
              <TH onClick={e=>sort('updated_at')}>Updated Date 
                <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('form_id')}>Form Id
                <SortDirection sort_column={requestParams.sort_column} column="form_id" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('form_type')}>Form Type
                <SortDirection sort_column={requestParams.sort_column} column="form_type" reverse={requestParams.reverse}/> 
              </TH>
              <TH onClick={e=>sort('created_by')}>Created By
                <SortDirection sort_column={requestParams.sort_column} column="created_by" reverse={requestParams.reverse}/> 
              </TH>
              
              
              <TH onClick={e=>sort('created_at')}>Options
                <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/> 
              </TH>
            </TR>
          </THead>
          <TBody>
            <TableData lists={lists} />
          </TBody>
        </Table>
      </SortableContainer>
    </div>
  )

}

export default ScreeningListTable;