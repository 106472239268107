import React, {Fragment, useState, useContext} from "react";
import useStyle from '../../hooks/useStyle';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import GenericModal from "../Modals/GenericModal"

const NotificationPopup = (props) => {
  useStyle('dashboard');      

  return(
    <div className="col-xs-12">
        <div className="card card-style">
          <div className="success d-flex">
            <div>
                <h3><i className={props.iconClass || 'fa fa-check bg-green1-dark success-ico'}/></h3>
            </div>
            <div className="success-txt">
              <h3>{props.title || 'Success'}</h3>
              <p className="font-18 m-t-5" dangerouslySetInnerHTML={{ __html: props.message }}/>
            </div>
          </div>
          <div>
            <span onClick={e=>props.onSuccess()} className="success-btn">OK</span>
            {props.onCancel && 
              <span onClick={e=>props.onCancel()} className="success-btn bg-lred">Cancel</span>
            }
          </div>  
        </div>
    </div>
  )
}

const ConfirmAlert = (props) => {
  useStyle('dashboard');      

  return(
    <div className="col-xs-12 bg-white">
      <div className="p-10">
        <div className="success d-flex">
          <div className="success-txt">
            <p className="font-16" dangerouslySetInnerHTML={{ __html: props.message }}></p>
          </div>
        </div>
        <div className="col-xs-12 d-flex p-10">
          {props.options.map((o, i)=>
            <span key={i} 
              onClick={e=>props.onSelect(o.sid)} 
              className={`p-5 m-l-15 m-b-5 font-16 text-center w-50p white ${o.color}`}
              htmlFor={o.html_for}>
                {o.label}
            </span>
          )}
        </div>  
      </div>
    </div>
  )
}

const ScreenOrientationAlert = ({alert}) =>{
  useStyle('dashboard');   
  const {state: {screen}} = useContext(AuthContext)
  const [isOpen, setIsOpen] = useState(screen.xs)

  const onSuccess = () => setIsOpen(false)

  const Alert = () =>{
    return(
      <div className="col-xs-12">
        <div className="card card-style">
          <div className="success">
            <div>
              <h3><i className="fas fa-info-circle bg-green1-dark success-ico"/></h3>
            </div>
            <div className="success-txt">
              <div className="ore-alert">{alert}</div>
              <div>
                <img src="./images/orientation-200.png" className="w-100"/>
              </div>
            </div>
          </div>
          <div>
            <span onClick={()=>onSuccess()} className="success-btn">OK</span>
          </div>  
        </div>
      </div>
    )
  }

  if(isOpen){
    return(
      <GenericModal component={Alert} isOpen={isOpen} toggleModal={onSuccess}/>
    )
  }  
  
  return null  
}

export {NotificationPopup, ConfirmAlert, ScreenOrientationAlert};