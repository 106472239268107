import React, {useState, Fragment, useEffect, useContext} from "react";
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import Member from '../../components/Members/Member'
import MemberService from "../../components/Members/MemberService";
import ConfirmMenu from '../Modals/ConfirmMenu'
import AddMember from '../../components/Members/AddMember'
import {Link} from "react-router-dom";
import useModal from '../../hooks/useModal';
import useStyle from '../../hooks/useStyle';
import Cs from '../../services/CommonService';
import {usePageTitle} from '../../hooks/pageHead';

let requestParams = {}
let newMember = {data:{}}
let selectedMember = {}
let rolesList = []
let rolesObj = {}

const MemberList = (props) => {
  usePageTitle('Member List');
  useStyle('user_lists');
  useStyle('add_role_dialog');

  let params = props.match.params;

  const { state:currentUser, dispatch } = useContext(AuthContext);
  const [members, setMembers] = useState([]);
  
  const {isOpen:isDeletePopupOpen, toggleModal:toggleDeletePopup, success:deleteSuccess} = useModal();
  const {isOpen:isNewMemberPopupOpen, toggleModal:toggleNewMemberPopup, success:newMemberSubmit} = useModal();

  useEffect(() => {  
    requestParams = {
      'page': 1, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'desc',
      'memberable_id':params.memberable_id,
      'memberable_type':params.memberable_type
    }

    getMembersList();
  }, [currentUser.user.accelerator, props.searchString]);

  let getMembersList = () => { 
    MemberService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       rolesList = res.data.roles;
       rolesObj = Cs.listToObj(res.data.roles, 'record_id');
       setMembers(res.data.members)
      }
    })
  }

  const deleteMember = (e) => {
    e.preventDefault();
    MemberService.delete(selectedMember.id).then((res) => {
      if(res.status==204){
        setMembers(members.filter(r => r.record_id !== selectedMember.record_id));
        toggleDeletePopup(e);
      }
    })    
  }

  const setDelete = (e, member) => {
    selectedMember = member;
    toggleDeletePopup(e);
  }

  const setEdit = (e, member) => {
    newMember = member;
    toggleNewMemberPopup(e);
  }
  
  const addNewMember = (e, new_member) => {
    e.preventDefault();
    if(new_member.id){
      MemberService.update(new_member).then((res) => {
        if(res.status==204){
          restModal(e);
        }
      })  
    }else{
      new_member.created_by = currentUser.user.id
      new_member.memberable_id = params.memberable_id
      new_member.memberable_type = params.memberable_type

      MemberService.create(new_member).then((res) => {
        if(res.status==201){
          restModal(e);
        }
      })  
    }
  }

  const restModal = (e) =>{
    newMember = {data:{}};
    toggleNewMemberPopup(e);
    getMembersList();
  }
    
  return (
    <Fragment>
      <div className="content bottom-10">
        <h5 className="float-left font-500">User Lists</h5>
        <a href="#" data-tip="Invite User" className="bg-highlight round-btn float-right m-r-5 m-t-4" onClick={e=>restModal(e)}>
          <i className="fas fa-plus"></i>
        </a>
        <div className="clear"></div>
      </div>

      <div className="page-content clearfix">
        {members.map((member, i) => (
          <div className="col-xs-12 col-sm-6 col-md-4" key={i}>
            <Member member={member} setDelete={setDelete} setEdit={setEdit} 
              rolesObj={rolesObj} onDelete={toggleDeletePopup}/>
          </div>
        ))}
      </div>

      <ConfirmMenu isOpen={isDeletePopupOpen} toggleModal={toggleDeletePopup} success={deleteMember}/>
      { isNewMemberPopupOpen && <AddMember editMember={newMember} isOpen={isNewMemberPopupOpen}  
        toggleModal={toggleNewMemberPopup} addNewMember={addNewMember} rolesList={rolesList}/>}
    </Fragment>
  )

};

export default MemberList;