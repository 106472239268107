import React, { Fragment, useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import useStyle from '../../hooks/useStyle'
import useOnLine from '../../hooks/useOnLine'

const Footer = (props) => {
  useStyle('/css/footer.css', true)

  const { state:{isAuthenticated, user:currentUser}, dispatch } = useContext(AuthContext)
  const geoData = currentUser?.geo_data?.address

  const {isOnline} = useOnLine();

  return (
    <div id="ct" className="footer-container hide-xss">
      <footer className="bottom_bar">
        <div className="flex justspacebetween">
          <p className="f11 text-white p-5 lh-14">
            (C) {new Date().getFullYear()} IPIPAL INC. 
            All Rights Reserved.
          </p>
          {geoData ?
            <p className="text-white p-5 lh-14">
              <span className={`${isOnline?'green':'red'} fas fa-wifi font-14 m-r-5`}/>
              <span className="font-12">Access location: {geoData.county} - {geoData.country}.</span>
            </p>
            :
            null
          }
          <p className="text-white lh-14">
            <Link className="font-12 white m-r-15" target="_blank" to="/about_us/terms_of_service">Terms&nbsp;</Link> 
            <Link className="font-12 white" target="_blank" to="/about_us/privacy_policy">&nbsp;Privacy</Link>
          </p>
        </div>
      </footer>
    </div>
  )
}

export default Footer;
