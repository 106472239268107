import React, { Fragment, useState, useEffect, useContext, useMemo } from "react"
import { Link } from "react-router-dom"
import HealthCategoryService from "../HealthCategoryService"
import useStyle from "../../../hooks/useStyle"
import CheckAccess from "../Roles/CheckAccess"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../PreLoadSpinner"
import Cs from "../../../services/CommonService"
import querystringify from "querystringify"
import useModal from "../../../hooks/useModal"
import Tabs, { TabPane } from "rc-tabs"
import Title from "./Title"
import {NotesBox, NotesBtn} from "./NotesBox"
import GenericModal from "../../Modals/GenericModal"
import {PortalToggleMenu} from "./Common"
import Components from './Components'
import UpcomingSchedulesTable from "../Health/Calendars/UpcomingSchedulesTable"
import {ScribblePadList, ScribblePadBtn} from '../../ScribblePad/ScribblePadList'
import {RecordAudioSafari} from '../../Common/RecordAudio'
import {AudioRecordingList, AudioRecordingBtn} from '../../AudioRecordings/List'
import {Portal} from '../../../constants/DataSourceList' 
import ReactTooltip from "react-tooltip"
import {usePageTitle} from "../../../hooks/pageHead"
import SideMenuBar from "../../Common/SideMenuBar"
import CTLazyLoad from "../../Common/CTLazyLoad"
import {tabMoreIcon} from "../../Common/Button"
import ActivityModalLinks from "../MotherInfantJourney/Activities/List/ActivityModalLinks"
import MCJScheduleAlert from  "../MotherInfantJourney/Activities/List/MCJScheduleAlert"
import {getLocale} from '../../FormBuilder/FieldLabel'
import {usePageLocale} from '../../../hooks/useLocale'
import { LocalizeContext } from "../../../contexts/Localize/LocalizeStateProvider"
import { CTTooltip, CueTip } from "../../Common/CueTooltip"
import {ScreenOrientationAlert} from "../../Common/NotificationPopup"

const css = ['/css/tab-banner-layout.css']

let dataSource = {}
let journeyComponents = {}
let formProps = null
let extraProps = {}
let child

const PortalPage = (props) => {
  useStyle("add_role_dialog")
  
  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const {state: { user: currentUser }, dispatch} = useContext(AuthContext)
  const {localeState:{casePortalPage}, localeDispatch} = useContext(LocalizeContext)
  const currentOrgMember = currentUser?.current_member || {}
  const { labelLocale } = usePageLocale(currentUser.current_locale, 'casePortalPage')

  const [loading, setLoading] = useState(true)
  const [journey, setJourney] = useState({})
  const [todaySchedules, setTodaySchedules] = useState([])
  const [pageIds, setPageIds] = useState(['case_portal_page'])
  const [pptTabKey, setPptTabKey] = useState('')
  const [processTabKey, setProcessTabKey] = useState('')
  const [activityReloadTab, setActivityReloadTab] = useState()

  const { isOpen: isUpcomingSchedulesOpen, toggleModal:toggleUpcomingSchedulesModal } = useModal()
  const { isOpen: isAudioRecorderOpen, toggleModal:toggleAudioRecorder } = useModal()

  usePageTitle(journey?.data?.mother_name)

  const orgBgImageStyle = {
    background:`url(${Cs.getIconByType(currentUser?.current_organization?.data?.picture)}) center /cover`,
  }

  useEffect(() => {
    let req = {
      'subscription_id': currentUser.current_subscription?.id,
      'locale':currentUser.current_locale,
      'data_source_params': {
        'locale':currentUser.current_locale,
        'data_source_id': [3935]
      }
    }

    /*if(!casePortalPage){*/
    req.page_locale = {
      'data_source_ids':[3934, 3936, 3938, 3940],
      'locale':currentUser.current_locale
    }
    /*}*/

    HealthCategoryService.get(params.journey_profile_id, req).then((res)=>{
      setLoading(true)
      if(res.status === 200){
        res = res.data
        child = res.child
        journeyComponents = res.journey_components || {}
        if(queryParam.type === 'cancer_journey'){
          journeyComponents.firstTabs = journeyComponents.element_ppt_cancer.options
          journeyComponents.secondTabs = journeyComponents.pins_process_cancer.options
          extraProps.menuOptions = journeyComponents.indiv_health_portal
        }else if(queryParam.type === 'ckd_journey'){
          journeyComponents.firstTabs = journeyComponents.element_ppt_cancer.options
          journeyComponents.secondTabs = journeyComponents.pins_process_cancer.options
          extraProps.menuOptions = journeyComponents.indiv_health_portal
        }else if(queryParam.type === 'dementia_journey'){
          journeyComponents.firstTabs = journeyComponents.element_ppt_cancer.options
          journeyComponents.secondTabs = journeyComponents.pins_process_dementia.options
          extraProps.menuOptions = journeyComponents.indiv_health_portal
        }else if(queryParam.type === 'infant_journey'){
          extraProps.child = res.child
          extraProps.menuOptions = journeyComponents.maternity_infant_nodes
          journeyComponents.firstTabs = journeyComponents.element_ppt_mcj.options.filter((t)=> 
            CheckAccess.checkUserAccess(currentOrgMember, currentOrgMember?.permissions?.infant_ppt, t.sid) 
          )
          journeyComponents.secondTabs = journeyComponents.pins_process_mcj.options.filter((t)=> 
            CheckAccess.checkUserAccess(currentOrgMember, currentOrgMember?.permissions?.infant_process, t.sid)
          )

          if(!extraProps.child){
            journeyComponents.firstTabs = journeyComponents.firstTabs.filter((t)=> t.sid !== 'child_birth')
            journeyComponents.secondTabs = journeyComponents.secondTabs.filter((t)=> ['prenatal_care', 'periodic_check', 'protein_intake'].indexOf(t.sid) > -1)
          }
        }
        
        extraProps.timeFilter = (res.data_source_list.time_filter)
        setPptTabKey(!child ? 'journey_profile':'child_birth')
        setProcessTabKey(!child ? 'prenatal_care':'growth_measurement')
        onSecondTabChange(!child ? 'prenatal_care':'growth_measurement')
        setJourney(res.journey_profile)
        localeDispatch({
          type: "UPDATE",
          payload: {
            'casePortalPage': res.page_locale?.caseportal_page_elements,
            'childBirthTab': res.page_locale?.birth_details_tab,
            'commonTableHeaders': res.page_locale?.general_table_fields
          }
        })
        if(res.today_schedules.length > 0){
          setTodaySchedules(res.today_schedules)
          //toggleUpcomingScheduleAlert()
        }
        setLoading(false)
      }
    })
  }, [currentUser.current_locale])

  const openAudioRecorder = (e) =>{
    toggleAudioRecorder(e)
  }

  let onSecondTabChange = (key) => {
    setProcessTabKey(key)
    const preFix = queryParam.type === 'infant_journey'?'mcj_':''
    //setActiveKey(key)
    setPageIds([pageIds[0], preFix+key])
  }

  const reloadActivity = (tab) => {
    setProcessTabKey(tab)
    setActivityReloadTab(tab)
  }
    
  if(loading) 
    return <PreLoadSpinner />


  /*if(todaySchedules.length > 0){
    
    if(isUpcomingScheduleAlertOpen){
      return <GenericModal 
        component={MCJScheduleAlert} 
        journeyProfile={journey} child={child}
        onSuccess={toggleUpcomingScheduleAlert} 
        toggleModal={()=>setTodaySchedules([])}
        todaySchedules={todaySchedules}/>
    }

    return <ActivityModalLinks 
      child={child} journeyProfile={journey} 
      scheduleId={todaySchedules[0].id}
      schedule={todaySchedules[0]} autoOpenForm={true} 
      onScheduledFormSubmit={onScheduledActivityFormSubmit}/>
  } */

  const TabEle = ({tab}) => {
    const tabLocale = useMemo(() => {
      return{
        'label' : getLocale(tab, currentUser.current_locale, 'label'),
        'detail' : getLocale(tab, currentUser.current_locale, 'detail')
      }
    }, [tab.sid]);

    return <span data-tip={tabLocale.detail}>{tabLocale.label}</span>
  }

  return (
    <CTLazyLoad css={css}>
      <div id="ct">
        <div className="app_content m-t-15">
          <div className="bg-white border-rounded-10 flex justspacebetween m-b-20 p10 m-t-20">
            <h2 className="m-r-30 f18-xss">
              {labelLocale(1)}
              {/*journey.data_source?.health_condition?.label*/}
            </h2>
            <div className="flex aligncenter">
              <span id="pre_schedules" 
                className="bg-grey-4 round-btn m-r-5 m-t-4 tooltip"
                onClick={e=>toggleUpcomingSchedulesModal(e)}>
                  <i className="fas fa-calendar-alt"/>
                  <CueTip tooltip={labelLocale(4)} positionCss="bottom"/>
              </span>
              <AudioRecordingBtn journey={journey} currentUser={currentUser}/>
              {/*<ScribblePadBtn journey={journey} currentUser={currentUser}/>*/}
              <NotesBtn journey={journey} currentUser={currentUser}/>
            </div>
          </div>  

          <div id="case_ppt_details"
            className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-30 m-t-20-xs b-tab active">
            <Tabs defaultActiveKey={pptTabKey} moreIcon={tabMoreIcon}>
              {journeyComponents.firstTabs.map((tab, key) =>
                <TabPane tab={<TabEle tab={tab}/>} key={tab.sid}>
                  <Components componentName={tab.sid} 
                    journey={journey} 
                    formId={journey.form_id} 
                    dataSource={tab} 
                    orgBgImageStyle={orgBgImageStyle}
                    journeyType={queryParam.type}
                    {...extraProps}/>      
                </TabPane>
              )}
            </Tabs>
          </div>

          <MCJScheduleAlert child={child}
            journeyProfile={journey} 
            todaySchedules={todaySchedules}
            processNodes={journeyComponents.secondTabs}
            setTodaySchedules={setTodaySchedules}
            reloadActivity={reloadActivity}
            labelLocale={labelLocale}
            currentLocale={currentUser.current_locale}
          />

          <div id="case_process_details" className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-30 m-t-20-xs b-tab active">
            <Tabs activeKey={processTabKey} onChange={onSecondTabChange} moreIcon={tabMoreIcon}>
              {journeyComponents.secondTabs.map((tab, key) =>
                <TabPane tab={<TabEle tab={tab}/>} key={tab.sid}>
                  <Components componentName={tab.sid} 
                    journeyProfile={journey} 
                    dataSource={tab} 
                    journeyType={queryParam.type}
                    activityReloadTab={activityReloadTab} 
                    {...extraProps}/>      
                </TabPane>
              )}  
            </Tabs>
          </div>

        </div>
      </div>

      <ScreenOrientationAlert alert={labelLocale(8)}/>

      {isUpcomingSchedulesOpen &&
        <GenericModal component={UpcomingSchedulesTable} 
          isOpen={isUpcomingSchedulesOpen} 
          toggleModal={toggleUpcomingSchedulesModal} 
          title={labelLocale(4)}
          journeyProfileId={params.journey_profile_id}/>
      }
      
      <SideMenuBar pageIds={pageIds}/>
      
      <div className="m-b-80" />

    </CTLazyLoad>
  )
}

export default PortalPage;