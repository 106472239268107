import React from "react";
import Cs from '../../../services/CommonService';
import {HamburgerMenuIcon, EditGrayIcon, LinkDeleteIcon} from '../../Common/ImageIcons';
import {FormFieldCard, RenderData} from "../../HealthJourney/UserRoles/NotificationPill";

const HelpCard = ({help, formFieldsList, addDelete}) => {
  
  const {data, data_source:dataSource} = help
  let formFields = formFieldsList[496] || []
  
  if (!data) return null;

  const Header = () => (
    <div className="card-title card-title-gray flex p20 p-b-0 coldir">
      <h4 className="fw700 f18 card-heading flex coldir text-left">
        {data.title}
      </h4>
      <div className="f14 m-t-10 fw500">
        {help.author.name} 
        &nbsp;-&nbsp; 
        {Cs.formatUpdateDate(help.updated_at)} 
      </div>
    </div>
  )

  const Footer = () => {
    return(
      <div className="card-footer flex justspacebetween p20 aligncenter">
        <ul className="flex aligncenter">
          <li className="m-r-25">{help.record_id}</li>
          <li className="m-r-25">
            <EditGrayIcon link={`/help/create/${help.id}`}/>
          </li>
          <li className="m-r-25">
            <LinkDeleteIcon onSelect={()=>addDelete(help)}/>
          </li>
        </ul>
      </div>
    )
  }

  return (
    <div className="bg-white border-rounded-10 card-shadow m-b-15">
      <Header/>
      <div className="card-content p20">
        <div className="b-tab-table active scroll-auto ht-200">
          <div className="p-b-15 m-b-15 m-t-15">
            <FormFieldCard formFields={formFields} data={data} dataSource={dataSource}/>
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  )

}

/*
if(data !== null){
    return (
      <div className="card-container shadow-small" >
        <div className="card-header p-5" style={cardStyle}>
          <div className="font-16 black-light-1">
            {data.title}-{helpList.record_id}
          </div>
          <div className="lred">
            {CommonService.formatUpdateDate(helpList.created_at)}
          </div> 
        </div>

        <div className="ht-200 scroll-auto p-5 bg-white">
          <div id="help_img" dangerouslySetInnerHTML={{ __html: data.help_detail }}></div>
        </div>

        <div className="card-footer p-5">
          <div className="m-r-5 font-bold p-5">
            
          </div>
          <div className="pull-right">
            <div className="p-b-4">
              <props.menu help={helpList} />
            </div>
          </div>
        </div>
      </div>
    )
  }else{
   return null;
  }
*/
export default HelpCard;
