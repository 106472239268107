import React,{useState} from "react"

let ImageMap = ({field}) => {
  const {area_io, map_html, image_url, index} = field

  const onSelect = (attribute) =>{
    alert(attribute.label)
    console.log(attribute)
  }

  /*if(map_html){
    return map_html
  }else{
    return null
  }*/

  /*
    <map name={`#${index}`}>
        <area shape="rect" coords="34,44,270,350" alt="Computer" href="computer.htm"/>
        <area shape="rect" coords="290,172,333,250" alt="Phone" href="phone.htm"/>
        <area shape="circle" coords="337,300,44" alt="Coffee" href="coffee.htm"/>
      </map>
  */

  return( 
    <>
      <img src={area_io.img} alt="Health" useMap={`#${index}`}/>
      <map name={index}>
        <ImgAreaMap areas={area_io.areas || []} onSelect={onSelect}/>
      </map>
    </>
  )
}

const ImgAreaMap = ({areas=[], onSelect}) => areas.map((a, i) =>{
  const attributes = a?.attributes
  if(a.type == 'rectangle'){
    const coords = `${a.coords.x}, ${a.coords.y}, ${a.coords.width}, ${a.coords.height}`
    return <area key={i} shape="rect" coords={coords} onClick={e=>onSelect(attributes)} />
  }else if(a.type == 'circle'){
    const coords = `${a.coords.cx} ${a.coords.cy} ${a.coords.radius}`
    return <area key={i} shape="circle" coords={coords} onClick={e=>onSelect(attributes)} />
  }else if(a.type == 'polygon'){
    let coords = ''
    a.coords.points.forEach((p) => {
      coords += p.x
      coords += ' '
      coords += p.y
      coords += ' '
    })
    return <area key={i} shape="poly" coords={coords} onClick={e=>onSelect(attributes)} />
  }

  return null
})

export default ImageMap;