import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../PreLoadSpinner";
import DynamicForm from '../FormBuilder/DynamicForm';
import FormTemplateId from '../../constants/FormTemplateList'
import NutritionCareService from "./NutritionCareService";
import FormContainer from '../FormBuilder/FormContainer';
import querystringify from "querystringify";
import PreScheduleService from '../PreScheduleEvent/PreScheduleService'

let formMode = null; 
let formId = null; 
let nutritionCare = {}; 
let data = {}; 
let formFn = {};  

function NutritionCareForm(props) {
  const { state:authState, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let queryParam = querystringify.parse(props.location.search)
  let params = props.match.params
  let currentUser = authState.user
  
  formFn.pre_schedule_path = 'title'
  formFn.pre_schedule_pagenate = {
    page:1,
    per_page:15,
    is_form_search:true
  }

  const getById = (id) => {
    NutritionCareService.get(id).then((res) => {
      if(res.status==200){
        nutritionCare = res.data.nutrition_care
        data = nutritionCare.data || {}
        formId = nutritionCare.form_id
        setFormRendered(true)
      }
    }).catch(function(res){
      nutritionCare = null
    })
  }
  
  useEffect(() => { 
    data = {}
    nutritionCare = {} 
    formId = parseInt(params.formId)
    formMode = 'create-form-submissions'
    formFn = {
      'form_type':'nutrition_care'
    }
   
    if(props.match.path){
      if(props.match.path.includes('details')){
        getById(params.id)
        formMode = 'view-form-submissions'
      }else if(params.id){
        formMode = 'edit-form-submissions'
        getById(params.id)
      }else{
        setFormRendered(true)
      }
    }
  }, [])

  const create = (nutritionCare) => {
    nutritionCare.created_by = authState.user.id
    nutritionCare.form_type = params.type
    nutritionCare.form_id = params.formId
    nutritionCare.is_template = queryParam.is_template=='true'?true:false

    NutritionCareService.create(nutritionCare).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const update = (nutritionCare) => {
    NutritionCareService.update(nutritionCare.id, nutritionCare).then((res) => {
      if(res.status==204){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  formFn.get_pre_schedule = (search, on_success, on_failure) => {
    formFn.pre_schedule_pagenate.search = search
    
    PreScheduleService.getAll(formFn.pre_schedule_pagenate)
    .then((res)=>{
      on_success(res.data.pre_schedules)
    })
  }

  formFn.set_pre_schedule = (item) => {
    data.pre_schedule = item.title
    data.pre_schedule_fk_id = item.id
  }

  const onSubmitPageRedirect = (id) => {
    if(props.history.goBack){
      props.history.goBack()
    }else if(queryParam.is_template == 'true'){
      props.history.push("/templates/nutrition_cares?is_template=true") 
    }else{
      props.history.push("/nutrition_cares")  
    }
  }

  if(isFormRendered){
    return (
      <>
        <FormContainer post={post}>
          <DynamicForm formMode={formMode} formId={formId} form={nutritionCare}  
          formFn={formFn} onCreate={create} onUpdate={update} data={data} post={post} 
          onCancel={onSubmitPageRedirect} setPost={setPost} />
        </FormContainer>
      </>
    )  
  }else{
    return null
  }
  
}

export default NutritionCareForm;