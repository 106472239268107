import React, {useState, Fragment, useContext, useEffect, useRef} from "react"
import ReactTooltip from 'react-tooltip'
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import HealthCategoryService from "../HealthCategoryService"
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu"
import FormTemplateId from '../../../constants/FormTemplateList'
import {DataSources} from '../../../constants/DataSourceList'
import CommentsModal from "../../Modals/CommentsModal"
import {Link} from "react-router-dom"
import useModal from "../../../hooks/useModal"
import NotesModal from "../../Modals/NotesModal"
import ChecklistsModal from "../../Modals/ChecklistsModal"
import useStyle from '../../../hooks/useStyle'
import CheckAccess from '../Roles/CheckAccess'
import Cs from "../../../services/CommonService"
import GenericModal from "../../../components/Modals/GenericModal"
import ActivityForm from '../MotherInfantJourney/Activities/Form/ActivityForm'
import ChildForm from '../MotherInfantJourney/Child/ChildForm'
import querystringify from "querystringify"
import {PillDsField} from "../../HealthJourney/UserRoles/NotificationPill"
import InfantJourneyTable from "./InfantJourneyTable"
import DataSourceMenu from "../../Modals/DataSourceMenu"
import UpcomingSchedulesTable from "../Health/Calendars/UpcomingSchedulesTable"
import UsersRolesPopup from '../../DataAccesses/UsersRolesPopup'
import {usePageTitle} from "../../../hooks/pageHead"
import { CTTooltip } from "../../Common/CueTooltip"
import SideMenuBar from '../../Common/SideMenuBar'
import {FontAwesomeBtn} from "../../Common/Button"
import JourneyCard from "./JourneyCard"
import NutritionCareNotification from '../../NutritionCares/NutritionCareNotification'
import {NotificationPopup} from "../../Common/NotificationPopup"
import {OptionsObjList} from '../../Common/FormInput'
import {usePageLocale} from '../../../hooks/useLocale'
import { LocalizeContext } from "../../../contexts/Localize/LocalizeStateProvider"
import PaginationSM from '../../Common/PaginationSM'
import {Filters, FilterBtn} from './Filter'

let formProps = null
let requestParams = {}
let selectedJourney = {}
let dataSource = {}

const HealthJourneyList = (props) => {
  useStyle('/css/tile-components.css', true)

  usePageTitle('Journey List')

  let queryParam = querystringify.parse(props.location.search)

  const { state: {screen, user: currentUser}, dispatch} = useContext(AuthContext)
  const {localeDispatch} = useContext(LocalizeContext)

  let permission = currentUser.permission?.permissions?.infant_journey ?? {}

  const [journeyList, setJourneyList] = useState([])
  const [currentpage,setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState({})
  const [loading, setLoading] = useState(true)
  const [activeJourneyCount, setActiveJourneyCount] = useState(0)
  const [filter, setFilter] = useState({})

  const { labelLocale } = usePageLocale(currentUser.current_locale, 'journeyListPage')

  const { isOpen, toggleModal:toggleDeleteModal } = useModal()
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()
  const { isOpen: isJourneyMenuOpen, toggleModal: toggleJourneyMenuModal } = useModal()
  const { isOpen: isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen: isInfantActivityFormOpen, toggleModal:toggleInfantActivityModal } = useModal()
  const { isOpen: isUpcomingSchedulesOpen, toggleModal:toggleUpcomingSchedulesModal} = useModal()
  const { isOpen: isUserRoleOpen, toggleModal: toggleUserRole } = useModal()
  const { isOpen: isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal()

  const indexEle = useRef(null)

  let cardGridCss = "col-xs-12"
  let cardHeightCss = "ht-400"
  if (queryParam.id) {
    cardGridCss = "col-sm-12"
    cardHeightCss = "mih-380"
  }

  useEffect(() => {
    setParams(1) //set page to 1
    getJourneyList()
    return ()=>{
      ReactTooltip.hide()
    }
  }, [props.searchString, filter, currentUser.current_locale, currentUser.current_medical_facility_id, currentUser.current_organization_id, currentUser.current_sub_facility_id])

  useEffect(()=>{
    if(requestParams.page != currentpage){
      setParams(currentpage)
      getJourneyList()
    }
  }, [currentpage])

  const setParams = (page = currentpage) =>{
    setCurrentpage(page)
    requestParams = {
      'page':page,
      'per_page':15,
      'sort_column':'last_update_at',
      'sort_direction':'desc',
      'user_id':currentUser.id,
      'search': props.searchString,
      'organization_id':currentUser.current_organization_id,
      'medical_facility_id':currentUser.current_medical_facility_id,
      'sub_facility_id':currentUser.current_sub_facility_id,
      'id':queryParam.id,
      'fields':['active_status_count'],
      'filter':filter,
      'page_locale':{
        'page_ids':['health_cases', 'popup'],
        'locale':currentUser.current_locale,
      },
      'data_source_params': {
        'locale':currentUser.current_locale,
        'data_source_id': [3886]
      }
    }
  }

  let getJourneyList = (reload) => { 
    setLoading(true)
    HealthCategoryService.getAll(requestParams).then((res)=>{
      if(res.status === 200){
        dataSource = res.data.data_source_list
        setPagemeta(res.data.meta)
        setJourneyList(res.data.journey_profile) 
        setActiveJourneyCount(res.data.active_journey_count)
        localeDispatch({
          type: "UPDATE",
          payload: {
            'journeyListPage':(res.data.page_locale?.home_page_elements),
            'popup':(res.data.page_locale?.general_labels)
          }
        })
        setLoading(false)
      }
    })
  }

  const deleteJourney = (e) => {
    e.preventDefault(e)
    HealthCategoryService.delete(selectedJourney.id).then((res) => {
      if (res.status === 204) {
        setJourneyList(journeyList.filter(i => i.id !== selectedJourney.id))
      }
    })
    toggleDeleteModal(e)
  }

  const update = (idx, req) =>{
    HealthCategoryService.update(req).then((res)=>{
      if(res.status === 204){
        journeyList[idx] = {...journeyList[idx], ...req}
        setJourneyList([...journeyList])
      }
    })
  }

  const addDelete = (j) => {
    selectedJourney = j
    toggleDeleteModal()
  }

  /*const addMenu = (e, j) => {
    selectedJourney = j
    toggleMenuModal(e)
  }

  const addComment = (e, j) => {
    selectedJourney = j
    toggleCommentModal(e)
  }*/

  const onFormSubmit = () =>{
    getJourneyList(true)
    toggleInfantActivityModal()
  }

  let routeJourney = (journey)=>{
    Cs.routeJourney(journey, props)
  }

  const openNotification = (journey) =>{
    selectedJourney = journey
    toggleNutritionCareModal()
  }

  const routeJourneyForm = (ds) =>{
    Cs.routeJourneyForm(ds, props.history)
  }

  const openUserRole = (journey) => {
    selectedJourney = journey
    toggleUserRole()
  }

  const routeInfantActivityForm = (path, journey) =>{
    formProps = Cs.routeInfantPath(path, {}, journey, props.history)
    if(path.sid == 'instant_baby'){
      formProps.component = ChildForm
    }else{
      if(journey.child){
        let c = journey.child
        c.data.baby_age = Cs.getAge(c.data.dob_baby_formatted_date)
        formProps.location.state = {
          child_fk_id: c.record_id, 
          dob_baby_formatted_date: c.data.dob_baby_formatted_date, 
          id_mother: journey.data.cell_number, 
          baby_age: c.data.baby_age
        }
      }
      formProps.component = ActivityForm
    }
    toggleInfantActivityModal()
  }

  const updateSubFacility = (id) =>{
    dispatch({
      type: "APPEND",
      payload: {current_sub_facility_id: id}
    })
  }  

  const NoRecords = () =>(
    <NotificationPopup title="No case records to display" 
      message="Click the ADD button above to create a new case file." 
      onSuccess={toggleJourneyMenuModal}  
      iconClass="fas fa-circle-info lred"
    />
  )

  const JourneyCards = () => journeyList.length > 0 ? journeyList.map((j, i)=>
    <div className="col-md-4" key={i}>
      <JourneyCard journey={j} 
        journeyStatusList={dataSource.status_journey_case.options} 
        routeJourney={routeJourney}
        openNotification={openNotification}
        userLocale={currentUser.current_locale}
        openUserRole={openUserRole}
        labelLocale={labelLocale}
        onDelete={addDelete}
        update={update}
        index={i}/>
    </div>
  ):<NoRecords/>
  
  if(loading){
    return(
      <div className="spinner"/>
    )
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div>
              <div className="app-title m-r-20">{labelLocale(1)}</div>
              {currentUser.sub_facilities?.length >= 2 ? 
                <select className="form-control input-sm fw500 pull-right"
                  onChange={(e)=>updateSubFacility(e.target.value)}
                  defaultValue={currentUser.current_sub_facility_id}
                  placeholder="SubFacility">
                    <OptionsObjList list={currentUser.sub_facilities} id_key='id' label_key='name'/>
                </select>
                :
                null
              }
            </div>
              
            <div className="flex aligncenter rgap-5-xs">
              <div className="underline f16 m-r-15">{activeJourneyCount} Active</div>
              <FontAwesomeBtn
                id="upcoming_appointments" 
                tooltip="View Upcoming visits" 
                onSelect={()=>toggleUpcomingSchedulesModal()}
                colorCss="bg-dyellow" css="m-r-5"
                iconCss="far fa-calendar-alt" name={labelLocale(2)}>
              </FontAwesomeBtn>
              <FontAwesomeBtn 
                id="add_new_journey" 
                tooltip="Launch a new health journey" 
                onSelect={()=>toggleJourneyMenuModal()}
                css="m-r-5"
                iconCss="fas fa-plus" name={labelLocale(3)}>
              </FontAwesomeBtn>
              {currentUser.current_member?.has_all_facility_access ?
                <FilterBtn filter={filter} setFilter={setFilter} 
                  organizationId={currentUser.current_organization_id}
                  requestParams={requestParams} currentUser={currentUser}
                  className="bg-highlight round-btn float-right m-r-5"/>
                : 
                null
              }
            </div>
          </div>
            
          {screen.xs ?
            <div className="row m-t-30">  
              <JourneyCards/>
            </div> 
            :
            <InfantJourneyTable requestParams={requestParams}
              getJourneyList={getJourneyList} journeyList={journeyList} 
              setJourneyList={setJourneyList} permission={permission} 
              openUserRole={openUserRole}
              journeyStatusList={dataSource.status_journey_case.options}
              update={update} routeJourney={routeJourney}
              openNotification={openNotification}
              NoRecords={NoRecords} labelLocale={labelLocale}
              userLocale={currentUser.current_locale}
              {...props}/>  
          }
          
          <PaginationSM
            className="pagination-bar m-t-10"
            currentPage={currentpage}
            totalItems={pagemeta.total}
            itemsPerPage={pagemeta.per_page}
            onPageChange={setCurrentpage}
            siblingCount={2}/>

        </div>
      </div>
        
      <CTTooltip />

      {isInfantActivityFormOpen && 
        <GenericModal toggleModal={toggleInfantActivityModal} 
        isPopupMode="true" onFormSubmit={onFormSubmit} 
        closeConfirmPopup={true} {...formProps} />
      }
      <ConfirmMenu isOpen={isOpen} toggleModal={toggleDeleteModal} success={deleteJourney}/>
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} 
        item={selectedJourney} type="journey_profile"/>
      }
      {isJourneyMenuOpen && 
        <DataSourceMenu toggleModal={toggleJourneyMenuModal} 
        datasourceList={currentUser.current_subscription?.data_source?.journeys}
        dataSourceKey='data' dataSourceId={3419}
        onSelect={routeJourneyForm}/>
      }
      {isUpcomingSchedulesOpen &&
        <GenericModal component={UpcomingSchedulesTable} 
          isOpen={isUpcomingSchedulesOpen} 
          toggleModal={toggleUpcomingSchedulesModal} 
          title={labelLocale(7)} />
      }
      {isUserRoleOpen && 
        <GenericModal
          component={UsersRolesPopup}
          isOpen={isUserRoleOpen}
          toggleModal={toggleUserRole}
          itemType="journey_profile"
          itemId={selectedJourney.id}
          showRoles="true"
          showOrgMembers="true"
          title="Invite others to this case"/>
      }
      {isNutritionCareOpen && 
        <GenericModal component={NutritionCareNotification} 
          toggleModal={toggleNutritionCareModal} isOpen={isNutritionCareOpen}
          itemType="journey_profile" itemUuid={selectedJourney.id} 
          journeyProfileId={selectedJourney.id} closeIco="fas fa-chevron-down"/>
      }
      <SideMenuBar pageIds={['health_cases']}/>

    </Fragment>
  )

}

export default HealthJourneyList;