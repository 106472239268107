import env from '../../../../env';
import axios from 'axios';
import BaseService from '../../../../services/BaseService';

class FacilityService extends BaseService {

  uploadCsv(req){
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}health/upload_csv/medical_facilities`,req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        resolve(e.response);
      })
    })
  }

  report(req){  
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/report/medical_facilities', {params: req})
      .then((res) => {
        resolve(res)
      })
    })
  }

  getLocations(req){
    return new Promise((resolve, reject) => {
      axios.post(env.health_backend+'locations/medical_facilities', req).then((res) => {
        resolve(res);
      })
    })
  }

}

export default new FacilityService('health_backend','health/medical_facilities');