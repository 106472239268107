import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../PreLoadSpinner"
import DynamicForm from "../FormBuilder/DynamicForm"
import FormTemplateId from "../../constants/FormTemplateList"
import querystringify from "querystringify"
import FormContainer from '../FormBuilder/FormContainer'
import GenericModal from '../Modals/GenericModal'
import useModal from "../../hooks/useModal"
import TrainingProgramsService from './TrainingProgramsService'

let formMode = null
let trainingProgram = {}
let data = {}
let formFn = {}

function TrainingProgramForm(props) {
  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const params = props.match.params
  const path = props.match.path
  const history = props.history

  const get = () => {
    TrainingProgramsService.get(params.id).then((res) => {
      if (res.status == 200) {
        trainingProgram = res.data.training_program
        data = trainingProgram.data || {}
        setFormRendered(true)
      }
    }).catch((res) => {
      trainingProgram = null
    })
  }

  useEffect(() => {
    trainingProgram = {}
    data = {}
    formFn = {form_type: "health.training_program",}
    formMode = "create-form-submissions"
    data = {}
    if (params.id) {
      get()
      formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (trainingProgram) => {
    TrainingProgramsService.create(trainingProgram).then(({status, data}) => {
      if (status == 201) {
        onSubmitPageRedirect();
      }
    })
  }

  const update = (trainingProgram) => {
    TrainingProgramsService.update(trainingProgram).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = (cancel) => {
    if(props.onSuccess){
      props.toggleModal()
      props.onSuccess()
    }else{
      history.push('/list/training_programs')
    }
  }

  const onCancel = () => onSubmitPageRedirect(true);

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode}
          formId={FormTemplateId.TrainingProgramForm}
          form={trainingProgram}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
      </FormContainer>
    )
  } else {
    return null
  }
  
}

export default TrainingProgramForm;