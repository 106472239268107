import React from "react";
import {Link} from "react-router-dom";
import CommonService from '../../../../../services/CommonService';

export const Provider = (props) => {
  let journeyPlanner = props.journeyPlanner;
  let data = journeyPlanner.data;
  let ds = props.dataSources;

  const cardStyle = {
      backgroundColor: '#009688d1'
  };

  if(data == null)return null;

  const StaffingCard = () => {
    return (
      <div className="card-container bg-white ht-325 shadow-small" >
      <div class="white card-header  p-5" style={cardStyle}>
        <div className="font-16">
        Staffing - {ds.request_staffing_vol.options[journeyPlanner.data.request_staffing_vol].label}        </div>
        <div>
        {CommonService.formatUpdateDate(journeyPlanner.updated_at)}
        </div>
      </div>
      <div class="ht-227 scroll-auto p-5">
      <div className="content">
        <div className="vcard-field">
          <strong>START DATE</strong>
          <p>{journeyPlanner.data.starting_date}</p>
          <i className="fas fa-city color-green1-dark" />
        </div>

        <div className="vcard-field">
          <strong>END DATE</strong>
          <p>{journeyPlanner.data.ending_date}</p>
          <i className="fa fa-map-marker color-blue2-dark" />
        </div>

        <div className="vcard-field no-border">
          <strong>NOTES</strong>
          <p>{journeyPlanner.data.request_notes}</p>
          <i className="fas fa-share color-red2-light" />
        </div>

</div>
</div>
        <div class="card-footer p-5">
      <div class="m-r-5 pull-left font-bold p-5">
      {journeyPlanner.planner_form_id}-{journeyPlanner.record_id}
      </div>
      <div class="pull-right">
        <div className="m-t-5">
         <props.menu planner={journeyPlanner}/>

        </div>
      </div>
      </div>
      </div>
      );
  }

  const SponsorCard = () => {
    return (
    <div className="card-container bg-white ht-325 shadow-small">
    <div class="white card-header  p-5" style={cardStyle}>
        <div className="font-16">
        {journeyPlanner.planner_responses != null ?'Response For':'Request For'} 
              Sponsorship
        </div>
        <div>
        {CommonService.formatUpdateDate(journeyPlanner.updated_at)}
        {journeyPlanner.author_name}
        </div>
      </div>
      <div class="ht-227 scroll-auto p-5">
      <div className="content">
        {
          journeyPlanner.planner_responses &&

         ( <div><div className="vcard-field">
          <strong> Waiting for Sponsor Response</strong>
          <p>{CommonService.formatUpdateDate(journeyPlanner.planner_responses[0].updated_at)}</p>
          <i className="fas fa-city color-green1-dark" />
        </div>

        <div className="vcard-field">
          <strong> {journeyPlanner.planner_responses[0].data.reference}</strong>
        </div>

        <div className="vcard-field no-border">
          <strong> {journeyPlanner.planner_responses[0].data.url}</strong>
          {ds.level.options[journeyPlanner.planner_responses[0].data.level].label}
          {journeyPlanner.planner_responses[0].data.notes}
        </div></div>)
        }
       

        <div className="vcard-field">
          <strong>NOTES</strong>
          <p>{journeyPlanner.data.sponsorship_details}</p>
          <i className="fas fa-calendar-alt color-green1-dark" />
        </div>

      </div>
      </div>
      <div class="card-footer p-5">
      <div class="m-r-5 pull-left font-bold p-5">
      {journeyPlanner.planner_form_id}-{journeyPlanner.record_id}
      </div>
      <div class="pull-right">
        <div className="m-t-5">
         <props.menu planner={journeyPlanner}/>

        </div>
      </div>
      </div>
      </div>);
  }

  const MedicalCard = () => {
    return (
    <div className="card-container bg-white ht-325 shadow-small">
    <div class="white card-header  p-5" style={cardStyle}>
        <div className="font-16">
        Medical Support
        </div>
        <div>
        {CommonService.formatUpdateDate(journeyPlanner.updated_at)}
        </div>
      </div>
      <div class="ht-227 scroll-auto p-5">
      <div className="content">
        <div className="vcard-field">
          <strong>NOTES</strong>
          <p>{journeyPlanner.data.notes}</p>
          <i className="fas fa-city color-green1-dark" />
        </div>

      </div>
      </div>
      <div class="card-footer p-5">
      <div class="m-r-5 pull-left font-bold p-5">
      {journeyPlanner.planner_form_id}-{journeyPlanner.record_id}
      </div>
      <div class="pull-right">
        <div className="m-t-5">
         <props.menu planner={journeyPlanner}/>

        </div>
      </div>
      </div>
      </div>);
  }

  const ScreeningParticipantCard = () => {
    return (
    <div className="card-container bg-white ht-325 shadow-small">
    <div class="white card-header  p-5" style={cardStyle}>
        <div className="font-16">
        Classify Participants
        </div>
        <div>
        {CommonService.formatUpdateDate(journeyPlanner.updated_at)}
        </div>
      </div>
      <div class="ht-227 scroll-auto p-5">
      <div className="content">
        <div className="vcard-field">
          <strong>TIER 1 (ESSENTIAL). PERMITTED TO ATTEND</strong>
          { journeyPlanner.data.tier_1_array.map((value, index) => (
            <li key={index}>
              {ds.participant_types.options[value].label}
            </li>
          ))}
          <i className="fas fa-city color-green1-dark" />
        </div>

        <div className="vcard-field">
          <strong>TIER 2 (PREFERRED). <br/>SPECIAL PERMISSION REQUIRED TO ATTEND</strong>
          { journeyPlanner.data.tier_2_array.map((value, index) => (
            <li key={index}>
              {ds.participant_types.options[value].label}
            </li>
          ))}
          <i className="fa fa-map-marker color-blue2-dark" />
        </div>

        <div className="vcard-field no-border">
          <strong>TIER 3 (NON-ESSENTIAL). RESTRICTED ATTENDANCE</strong>
          {journeyPlanner.data.tier_3_array.map((value, index) => (
    <li key={index}>
      {ds.participant_types.options[value].label}
    </li>
  ))}
          <i className="fas fa-share color-red2-light" />
        </div>

        <div className="vcard-field">
          <strong>NOTES</strong>
          <p>{journeyPlanner.data.notes}</p>
          <i className="fas fa-calendar-alt color-green1-dark" />
        </div>

      </div>
      </div>
      <div class="card-footer p-5">
      <div class="m-r-5 pull-left font-bold p-5">
      {journeyPlanner.planner_form_id}-{journeyPlanner.record_id}
      </div>
      <div class="pull-right">
        <div className="m-t-5">
         <props.menu planner={journeyPlanner}/>

        </div>
      </div>
      </div>
      </div>);
  }

  const EventPlanCard = () => {
    return (
    <div className="card-container bg-white ht-325 shadow-small">
    <div class="white card-header  p-5" style={cardStyle}>
        <div className="font-16">
        Plan
        </div>
        <div>
        {CommonService.formatUpdateDate(journeyPlanner.updated_at)}
        </div>
      </div>
      <div class="ht-227 scroll-auto p-5">
      <div className="content">
        <div className="vcard-field">
          <strong>TIER 1</strong>
      
          <i className="fas fa-city color-green1-dark" />
        </div>
        
        <div className="vcard-field">
          <strong>NOTES</strong>
          <p >{journeyPlanner.data.notes}</p>
        </div>

      </div>
      </div>
      <div class="card-footer p-5">
      <div class="m-r-5 pull-left font-bold p-5">
      {journeyPlanner.planner_form_id}-{journeyPlanner.record_id}
      </div>
      <div class="pull-right">
        <div className="m-t-5">
         <props.menu planner={journeyPlanner}/>

        </div>
      </div>
      </div>
      </div>);
  }

  if(journeyPlanner.planner_type_id==7){
    return(<StaffingCard/>)
  }else if(journeyPlanner.planner_type_id==6){
    return(<SponsorCard/>)
  }else if(journeyPlanner.planner_type_id==2){
    return(<MedicalCard/>)
  }else if(journeyPlanner.planner_type_id==1){
    return(<ScreeningParticipantCard/>)
  }else if(journeyPlanner.planner_type_id==5){
    return(<EventPlanCard/>)
  }
  
};

export default Provider;