import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import DynamicForm from "../../../../components/FormBuilder/DynamicForm"
import FormTemplateId from "../../../../constants/FormTemplateList"
import ChildService from "./ChildService"
import querystringify from "querystringify"
import FormContainer from '../../../../components/FormBuilder/FormContainer'
import GeoLocation from "../../../../components/Common/GeoLocation"
import GenericModal from "../../../../components/Modals/GenericModal"
import NutritionCareNotification from '../../../NutritionCares/NutritionCareNotification'
import useModal from "../../../../hooks/useModal"
import SubFacilityService from '../MedicalCareFacility/SubFacility/SubFacilityService'

let formMode = null;
let activity = {};
let data = {};
let formFn = {};

function ChildForm(props) {
  const { state:{user:currentUser} } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal()

  let params = props.match.params
  let forigenObject = props.location.state
  let queryParam = querystringify.parse(props.location.search)

  const get = () => {
    let req = {
      journey_profile_id: params.journey_profile_id,
      child_type: 'child' 
    }
    
    ChildService.get(null, req).then((res) => {
      if (res.status === 200) {
        activity = {...res.data.child, ...activity.geo_data}
        data = activity.data || {}
        if(activity.id)
          formMode = "edit-form-submissions";
        setFormRendered(true)
      }
    }).catch((res) => 
      activity = null
    )
  }

  useEffect(() => {
    formMode = "create-form-submissions"
    activity = {}
    data = {}
    formFn = {
      form_type: "infant.child",
      journey_category_id: params.journey_profile_id,
    }
    
    if (props.path?.includes('form')) {
      setFormRendered(true)
    } else if (!isFormRendered) {
      get()
      formMode = "edit-form-submissions"
    }
  }, [])

  /*Medical Facility Auto Complete*/
  formFn.phc_path = 'name'

  formFn.get_phc = (search, on_success, on_failure) =>{
    const org = currentUser.organizations.find((o)=> o.id === currentUser.current_organization_id)
    on_success(org.medical_facilities)
    /*let req = {
      organization_id: currentUser.current_organization_id,
      medical_facility_id: currentUser.current_medical_facility_id
    }
    SubFacilityService.getMemberSubFacilities(req).then(({status, data})=>{
      if(status === 200){
        //setSubFacilityList(data.sub_facilities)
      }
    })*/
  }

  formFn.set_phc = (phc) =>{
    data.phc = phc.name
    data.medical_facility_id = phc.id
  }

  const create = (activity) => {
    if(forigenObject)activity.data = Object.assign(forigenObject, activity.data); 
    activity.organization_id = currentUser.current_organization_id;
    activity.created_by = currentUser.id;
    activity.updated_by = currentUser.id;
    activity.journey_profile_id = params.journey_profile_id;
    activity.child_form_id = (queryParam.form_id || FormTemplateId.HealthChildFormBasic);
    activity.child_type = "child";
    activity.member_role_id = currentUser.current_member?.role_record_id;
    ChildService.create(activity).then((res) => {
      if (res.status === 201) {
        if(res.data.activity?.show_nutrition_care){
          formFn.child = res.data.child
          formFn.activity = res.data.activity
          formFn.setLoading(false)
          toggleNutritionCareModal()
        }else{
          onSubmitPageRedirect(res.data.child);
        }
      }
    }).catch((res)=> {
      oncancel()
    });
  }

  const update = (activity) => {
    activity.updated_by = currentUser.id;
    activity.member_role_id = currentUser.current_member?.role_record_id;
    ChildService.update(activity).then((res) => {
      if (res.status === 200) {
        onSubmitPageRedirect(res.data.child);
      }
    }).catch((res)=> {});
  }

  const onSubmitPageRedirect = (child) => {
    if(props.isPopupMode){
      child ? props.onFormSubmit(child) : props.toggleModal()
    }else{
      props.history.push(
        `/health/common/page/${params.journey_profile_id}?type=infant_journey`
      )  
    }
  }

  const closeNutritionCare = (e) =>{
    onSubmitPageRedirect(activity)
    toggleNutritionCareModal(e)
  }

  const oncancel = () =>{
    onSubmitPageRedirect()
  }

  if (isFormRendered) {
    return (
      <>
        <FormContainer post={post} isPopupMode={props.isPopupMode}>
          <DynamicForm formMode={formMode} 
            formId={(queryParam.form_id || FormTemplateId.HealthChildFormBasic)}
            form={activity} data={data} formFn={formFn} onCreate={create}
            onUpdate={update} post={post} onCancel={oncancel}
            setPost={setPost} isPopupMode={props.isPopupMode}/>
          <GeoLocation geoData={activity}/>
        </FormContainer>

        {isNutritionCareOpen && 
          <GenericModal component={NutritionCareNotification} 
          toggleModal={closeNutritionCare} isOpen={isNutritionCareOpen}
          itemType="infant.activity" itemUuid={formFn.activity?.id}
          closeIco="fas fa-chevron-down"/>
        } 
      </>
    )
  } else {
    return null;
  }
  
}

export default ChildForm;