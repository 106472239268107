import React, { useState, Fragment, useEffect } from "react"
import PreScheduleEventService from "./../PreScheduleEventService"
import {OptionsObjList, CheckBox} from '../../Common/FormInput'
import {PreScheduleEventsDayList} from '../../../constants/DataSourceList'
import Cs from '../../../services/CommonService'

let requestParams = {}

const PreScheduleSteps = ({preSchedule}) => {
  const [preScheduleEvents, setPreScheduleEvents] = useState()
  const [rangeType, setRangeType] = useState()
  const [eventRange, setEventRange] = useState([])

  useEffect(() => {
  	requestParams = {
  	  pre_schedule_id: preSchedule.id,
      sequence_no: eventRange.toString(),
  	}
    getPreScheduleEvents()
  }, [rangeType])

  const getPreScheduleEvents = () =>{
  	PreScheduleEventService.getAll(requestParams).then((res)=>{
      const eventsObj = Cs.listToObj(res.data.pre_schedule_events, 'sequence_no')
  	  setPreScheduleEvents(eventsObj)	
  	})	  	
  }

  const generateRange = (start, end) => {
    return Array(end - start + 1).fill().map((_, idx) => start + idx)
  }

  let setRange = ({value, selectedIndex}) => { 
    const option = PreScheduleEventsDayList[selectedIndex-1]
    setEventRange(generateRange(option.start, option.end))
    setRangeType(value)
  }

  const addEvent = (event_no) =>{
    let newEvent = {
      pre_schedule_id: preSchedule.id, 
      sequence_type: 'day',
      sequence_no: event_no
    }
 	  PreScheduleEventService.create(newEvent).then((res)=>{
      if(res.status == 201){
        const event = res.data.pre_schedule_event
        preScheduleEvents[event.sequence_no] = event
        setPreScheduleEvents({...preScheduleEvents})
      } 
    })  
  }

  const deleteEvent = (event) => {
    PreScheduleEventService.delete(event.id).then((res) => {
      if (res.status == 204) {
        preScheduleEvents[event.sequence_no] = null
        setPreScheduleEvents({...preScheduleEvents})
      }
    })
  }

  const toggle = (event_no) =>{
    const event = preScheduleEvents[event_no]
    if(event){
      deleteEvent(event)
    }else{
      addEvent(event_no)
    }
  }
  
  const CheckBoxRangeView = () => eventRange.map((r, i)=>
    <span className="checkbox-container" key={i} onClick={e => toggle(r)}>
      <span>{r} Days</span>
      <input type="checkbox" checked={preScheduleEvents[r]} readOnly/>
      <span className="checkbox-checkmark"></span>
    </span>
  )

  return (
    <Fragment>
	    <div className="row p-5">
        <div className="col-xs-12">
          <select onChange={e => setRange(e.target)} name="day_range" 
            className="form-control font-18 webkit-appearance-none select-background" 
            defaultValue={requestParams.activity_form_id}>
              <option value="all">Select Day Range</option>
              <OptionsObjList list={PreScheduleEventsDayList} 
              id_key="start" label_key="label"/>
          </select>
        </div>
        <div className="col-xs-12 p-t-10">
          <CheckBoxRangeView/>
        </div>
	    </div>
	  </Fragment>
  )
}

export default PreScheduleSteps;