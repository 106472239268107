import React, {useState, createContext} from "react";
export const LocalizeContext = createContext();

export const initialState = {};

/*
export const initialLocaleState = {
	currentUserLocale:null,
 	pageLocales:null,
	labelLocale: function (key) {
	 	getLocale(this.pageLocales?.[key], this.currentUserLocale)
	}
};
*/