import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../PreLoadSpinner"
import DynamicForm from "../FormBuilder/DynamicForm"
import FormTemplateId from "../../constants/FormTemplateList"
import OrganizationService from "./OrganizationService"
import querystringify from "querystringify"
import FormContainer from '../FormBuilder/FormContainer'
import GeoLocation from "../Common/GeoLocation"
import {CityState} from "../Common/CityState"
import GenericModal from '../Modals/GenericModal'
import useModal from "../../hooks/useModal"
import {NotificationPopup} from '../Common/NotificationPopup'

let formMode = null
let organization = {}
let data = {}
let formFn = {}
const message = ` Thank you for your interest in the cueTree Health application.
  Your account is almost ready.
  A cueTree application representative will contact you to confirm your business
  information before your account can be activated.
  We look forward to serving your business.`

function OrganizationForm(props) {
  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  const params = props.match.params
  const path = props.match.path
  const history = props.history

  const {isOpen:isInactiveOrgNoticeOpen, toggleModal:toggleInactiveOrgNotice} = useModal()

  /*TODO: Move API to Router to load data before view*/
  const get = () => {
    OrganizationService.get(params.id).then((res) => {
      if (res.status == 200) {
        organization = {...res.data.organization, ...organization.geo_data}
        data = organization.data || {}
        setFormRendered(true)
      }
    }).catch((res) => {
      organization = null
    })
  }

  useEffect(() => {
    organization = {}
    data = {}
    formFn = {form_type: "health.organization", editPermission:true}
    formMode = "create-form-submissions"
    data = {}
    if (params.id) {
      get()
      formMode = "edit-form-submissions"
    } else if (!isFormRendered) {
      setFormRendered(true)
    }
  }, [])

  const create = (organization) => {
    OrganizationService.create(organization).then(({status, data}) => {
      if (status == 201) {
        if(!path.includes('/organization/')){
          currentUser.current_member = data.organization_member
          currentUser.current_organization = data.organization
          currentUser.current_organization_id = data.organization.id
          dispatch({
            type: "UPDATE",
            payload: {'user':currentUser},
          })
        }   
        onSubmitPageRedirect();
      }
    })
  }

  const update = (organization) => {
    OrganizationService.update(organization).then((res) => {
      if (res.status == 204) {
        if(currentUser.current_organization_id == organization.id){
          currentUser.current_organization = organization
          dispatch({
            type: "UPDATE",
            payload: {'user':currentUser},
          })
        }
        onSubmitPageRedirect()
      }
    })
  }

  const onSubmitPageRedirect = (cancel) => {
    if(path.includes('/organization/')){
      history.push('/list/organizations')
    }else if(currentUser.current_organization.is_active==false && !cancel){
      toggleInactiveOrgNotice()
    }else{
      history.push('/health/table')
    }
  }

  const onCancel = () => onSubmitPageRedirect(true);

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode}
          formId={FormTemplateId.OrganizationForm}
          form={organization}
          data={data}
          formFn={formFn}
          onCreate={create}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
        <GeoLocation geoData={organization}/>
        <CityState formFn={formFn} data={data}/>
        {isInactiveOrgNoticeOpen &&
          <GenericModal component={NotificationPopup} toggleModal={toggleInactiveOrgNotice} 
            isOpen={isInactiveOrgNoticeOpen} message={message} onSuccess={onCancel}/>
        }
      </FormContainer>
    )
  } else {
    return null
  }
}

export default OrganizationForm;