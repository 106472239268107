import React, {Fragment, useState, useContext, useEffect} from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../../Common/MenuItem"
import useStyle from '../../../hooks/useStyle'
import useSort from '../../../hooks/useSorting'
import SortDirection from '../../Common/SortDirection'
import Cs from "../../../services/CommonService"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import useModal from "../../../hooks/useModal"
import GenericModal from '../../Modals/GenericModal'
import TrainingSessionsService from './TrainingSessionsService'
import TrainingSessionForm from './Form'
import TrainingTopicForm from '../TrainingTopics/Form'
import FormTemplateId from "../../../constants/FormTemplateList"
import ProjectMenu from "../../Modals/ProjectMenu"
import SessionCard from "./SessionCard"
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc'
import arrayMove from '../../Common/arrayMove'
import ReactTooltip from "react-tooltip"

let selectedSession = {}
let requestParams ={}
let dataSources = []
let sessionFormProps = {}
let topicFormProps = {}

const DragHandle = sortableHandle(() => <span className="fas fa-arrows-alt"/>)

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>
})

const TrainingSessions = (props) => {
  useStyle('card')
  useStyle('card_table')
  
  const params = props.match.params

  const {state: { user: currentUser }, dispatch} = useContext(AuthContext)

  const [loading, setLoading] = useState(true)
  const [list, setList] = useState([])
  const [formFieldsList, setFormFieldsList] = useState({})
  const [disabledSort, setDisableSort] = useState(true)

  const {isOpen:isFormOpen, toggleModal:toggleFormModal} = useModal()
  const {isOpen:isTopicFormOpen, toggleModal:toggleTopicFormModal} = useModal()
  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal()
  const {isOpen:isMenuOpen, toggleModal:toggleMenuModal} = useModal()

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      page:1,
      per_page:100,
      sort_column:'sort_order',
      sort_direction:'asc',
      training_program_id:params.training_program_id,
      form_fields_params:{
        ids:[FormTemplateId.TrainingSessionForm]
      }
    } 
    getAll()
  }, [])

  const getAll = () =>{
    setLoading(true)
    TrainingSessionsService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        setList(res.data.training_sessions)
        setFormFieldsList(res.data.form_fields)
        setLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  let updateSortOrder = (id, prior_item_id, next_item_id, on_success) =>{
    setLoading(true)
    let req = {
      'update_type': 'sort_order',
      'prior_item_id':prior_item_id,
      'next_item_id':next_item_id,
      'id':id
    }
    TrainingSessionsService.updateSortOrder(req).then((res)=>{
      setLoading(false)
      if(res.status == 204){
        on_success()
      }
    })
  }

  const onSortEnd = ({oldIndex, newIndex}) => {
    let priorItemId = null
    let nextItemId = null

    if(newIndex == 0 ){
      console.log(list[newIndex].data.name)
      nextItemId = list[newIndex].id
    }else if(newIndex+1 == list.length){
      console.log(list[newIndex].data.name)
      priorItemId = list[newIndex].id
    }else if(oldIndex > newIndex){
      console.log(list[newIndex-1].data.name)
      console.log(list[newIndex].data.name)

      priorItemId = list[newIndex-1].id
      nextItemId = list[newIndex].id
    }else if(oldIndex < newIndex){
      console.log(list[newIndex].data.name)
      console.log(list[newIndex+1].data.name)

      priorItemId = list[newIndex].id
      nextItemId = list[newIndex+1].id
    }

    function onSuccess(){
      setList(arrayMove(list, oldIndex, newIndex))
    }

    updateSortOrder(list[oldIndex].id, priorItemId, nextItemId, onSuccess)
  }

  const onDelete = (program) => {
    selectedSession = program
    toggleDeleteModal()
  }

  const deleteItem = (e) => {
    e.preventDefault();
    TrainingSessionsService.delete(selectedSession.id).then((res) => {
      if (res.status == 204) {
        setList(list.filter(i => i.id !== selectedSession.id))
      }
    })
    toggleDeleteModal(e)
  }

  const openTrainingSessionForm = (e, id) =>{
    e.preventDefault()
    sessionFormProps = {
      match:{
        params:{
          id: id,
          training_program_id:params.training_program_id,
        }
      },
      isPopupMode:true,
      onSuccess:getAll
    }
    toggleFormModal()
  }

  const openTrainingTopicForm = (id, training_session_id) =>{
    topicFormProps = {
      match:{
        params:{
          id: id,
          training_program_id:params.training_program_id,
          training_session_id:training_session_id
        }
      },
      isPopupMode:true,
      onSuccess:getAll
    }
    toggleTopicFormModal()
  }

  const addMenu = (item) => {
    selectedSession = item
    toggleMenuModal()
  }

  const SortableItem = sortableElement((props) =>{
    return(
      <li>
        <SessionCard {...props}/>
      </li>
    )
  })

  const sessionPanels = list && list.map((s, i) => 
    <SortableItem key={`item-${i}`} index={i} disabled={disabledSort}
      session={s} formFieldsList={formFieldsList} 
      openTrainingTopicForm={openTrainingTopicForm} 
      openTrainingSessionForm={openTrainingSessionForm}
      onDelete={onDelete} updateSortOrder={updateSortOrder}/>
  )

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          <h4>Training Sessions</h4>
          <div className="font-15 m-t-10">
            List of Training Sessions - {list.length}
          </div>
        </div>
        <div className="col-xs-4">
          <NavIcon id="new_program" dataTip="Create New Training Sessions" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-plus"
            onSelect={openTrainingSessionForm}/>

          <NavIcon id="new_program" dataTip="Sort Training Sessions" 
            className="bg-highlight round-btn float-right m-r-5 m-t-4"
            icon={`fas fa-sort ${disabledSort?'':'green'}`}
            onSelect={()=>setDisableSort(!disabledSort)}/>
        </div>
      </div>
      
      {loading ? 
        <div className="spinner"/>
        :  
        <div className="row">
          <SortableContainer onSortEnd={onSortEnd} distance={1} lockAxis="y">
            {sessionPanels}
          </SortableContainer>
        </div>
      }

      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteItem}/>
      
      {isFormOpen && 
        <GenericModal component={TrainingSessionForm} isOpen={isFormOpen} 
        toggleModal={toggleFormModal} widthCss="w-100p" {...sessionFormProps} />
      }

      {isTopicFormOpen && 
        <GenericModal component={TrainingTopicForm} isOpen={isTopicFormOpen} 
        toggleModal={toggleTopicFormModal} widthCss="w-100p" {...topicFormProps} />
      }
    </Fragment>
  )

}

export default TrainingSessions;