import React, {useEffect} from 'react'
import Cs from "../../services/CommonService"
import ReactDOM from 'react-dom'

let selectedText = null
let iconRef = null
const SelectContainer = (props) => {
  const {index, onTextSelect, item} = props

  /*document.addEventListener("mouseup", (event) => {
      
    })*/

  /*return ()=>{
      document.removeEventListener('mouseup', (event) => {})
    }*/

  useEffect(() => {
    if(!iconRef)
      iconRef = document.getElementById('text_comment_icon')

    return ()=>{
      selectedText = null
      iconRef = null
    }
  }, [])

  const onMouseUp = (event) =>{
    selectedText = Cs.getSelectedText()
    const el = iconRef
    if (selectedText) {
      el.classList.remove("hidden")
      el.style.left = event.pageX + "px"
      el.style.top = event.pageY + "px"
    } else {
      el.classList.add("hidden")
    }
  } 

  return (
    <>
      <div onMouseUp={e => onMouseUp(e)}>
        {props.children}
      </div>
      {index == 0 && <SelectIco onTextSelect={onTextSelect} item={item}/>}
    </>
  )
}

const SelectIco = ({onTextSelect, item}) => (
  ReactDOM.createPortal(
    <div id="text_comment_icon"
      className="bg-highlight round-btn hidden p-abs zIndex" 
      onClick={()=>onTextSelect(null, item, selectedText)}>
      <i className="far fa-comment" data-tip="Chat"/>
    </div>, document.body
  )  
)

export default SelectContainer;