import React, { Fragment, useState, useEffect } from 'react'
import useStyle from '../../hooks/useStyle'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"

const CookieNotice = (props) => {
  useStyle('/css/cookie.css', true)

  const [visible, setVisible] = useState(localStorage.is_cookie_accepted || false);

  const toggleAgree =() =>{
    localStorage.is_cookie_accepted = true
    setVisible(!visible)
  }

  if(!visible){
    return (
      <div id="ct">
        <div className="cookie-section">
          <div className="flex coldir-xs aligncenter">
            <p className="m-r-30 text-white">
              <strong className="text-white">NOTICE :</strong> This website and related services use cookies to record and manage usage by each user.
              We use this data to manage and improve the services. 
              To learn more, please refer to the <a className="white d-in-grid underline" target="_blank" href="/about_us/privacy_policy">cueTree Privacy policy</a>, as they are updated. 
              By closing this notice or continuing to use this website or related services, you consent to the use of cookies according to the applicable terms.
            </p>
            <button className="btn yellow fw700 f14" onClick={()=>toggleAgree()} type="button" aria-label="Close">
              ACCEPT
            </button>
          </div>
        </div>
      </div>
    )
  }else{
    return null
  }

}

export default CookieNotice;