import React, {useState, Fragment, useContext, useEffect} from 'react'
import FilterSlider from "../../Common/FilterSlider"
import RoleAndUserFilter from '../../NotifiableRoles/RoleAndUserFilter'
import {CheckBox} from "../../Common/FormInput"
import useModal from '../../../hooks/useModal'
import SortDirection from '../../../components/Common/SortDirection'
import useSort from '../../../hooks/useSorting'
import RoleService from '../../HealthJourney/Roles/RoleService'
import FacilityService from '../../HealthJourney/MotherInfantJourney/MedicalCareFacility/FacilityService'
import {FontAwesomeBtn} from "../../Common/Button"

const Filters = ({organizationId, filter, setFilter, currentUser}) =>{
  
  const Filters = {
    'assignment':[
      {'label':'My Assignment', 'value':'true'},
    ],
  }

  const toggleFilter = (value, name) => {
    if(value!=null && value != filter[name]){
      setFilter({...filter, [name]:value})
    }else{
      delete filter[name]
      setFilter({...filter})
    }
  }

  const filterExists = (value, name) => filter[name] == value;
  
  return (
    <div className="filter-box-scroll p-5">
      <div className="bg-white">

        <CheckBox list={Filters.assignment} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="my_assignment"/> 
        
      </div>
    </div>
  )
}

const FilterBtn = (props) =>{
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const filterCount = Object.keys(props.filter).length

  return(
    <>
      <FontAwesomeBtn
        id="filter" 
        tooltip="Filters" 
        onSelect={()=>toggleFilterModal()}
        colorCss="bg-dyellow" css="m-r-5"
        iconCss="fas fa-sliders-h" 
        name="Filters">
        {filterCount > 0 ? <span className="badge-2 bg-black-light-1 up">{filterCount}</span>:''}
      </FontAwesomeBtn>

      {isFilterOpen &&
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={toggleFilterModal}
          {...props}/>
      }
    </>
  )
}

export {Filters, FilterBtn};