import React, {Fragment} from "react"
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import Cs from "../../services/CommonService"

const PendingRequests = (props) => {
  const {pendingRequests=[], dataSourceList} = props

  const TableData = () => pendingRequests.map((o, k) => {
    return(
      <TR key={k}>
        <TD>
          {Cs.formatUpdateDate(o.timestamp)}
        </TD>
        <TD className="text-gray">
          {o.data?.data?.case_id}
        </TD>
      </TR>
    )
  })

  const TableHead = ({}) =>{
    return(
      <div className="m-b-15">
        <h5 className="m-b-5 p-15">{dataSourceList.label}</h5>
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH>
                ID 
              </TH>
              <TH>
                Case ID
              </TH>
            </TR>
          </THead>
          <TBody>
            <TableData/>
          </TBody>
        </Table>
      </div>
    )
  }

  return (
    <div id="ct">
      <div className="table-responsive m-t-15">
        <TableHead/> 
      </div>
    </div>
  )

}

export default PendingRequests;