import React, {Fragment} from 'react'
import { Route, Switch } from 'react-router-dom'
import PrivateRoute from '../../routes/PrivateRoute'
import OrganizationsTable from './OrganizationsTable'
import PersonalUseTable from './PersonalUseTable'
import OrganizationForm from './OrganizationForm'
import MemberList from './Members/MemberList'
import SwitchActiveOrganization from './SwitchActiveOrganization'
import OrganizationPartnershipTable from './OrganizationPartnershipTable'

const Router = (props) => (
  <Fragment>	
    <PrivateRoute path="/list/organizations" component={OrganizationsTable} searchString={props.searchString} exact />
    <PrivateRoute path="/list/personal/users" component={PersonalUseTable} searchString={props.searchString} exact />
    <PrivateRoute path="/organization/create/:id?" component={OrganizationForm} exact />
    <PrivateRoute 
      path={['/:organization/members/:organization_id/:admin?', '/:organization/facility/members/:organization_id/:medical_facility_id/:sub_facility_id?']} 
      component={MemberList} searchString={props.searchString} exact />
    <PrivateRoute path="/list/partners" component={OrganizationPartnershipTable} searchString={props.searchString}/>
  	<Route path="/switch/organization" component={SwitchActiveOrganization} />
  </Fragment>
)

export default Router