import React, { Fragment, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import OrganizationsModal from './OrganizationsModal'

const SwitchActiveOrganization = (props) => {
   const { state: {user: currentUser }} = useContext(AuthContext)
   const org = currentUser?.current_organization

	return(
	  <>
		{org && !org.is_active && 
			<h4 className="m-l-15 m-t-80 font-16 lred">
				{org?.data?.name} is Inactive. <br/>
				Contact cuetree to activate
				{currentUser.organizations.length > 1 && ' or Switch to other Organizations.'}
			</h4>
		}
		<OrganizationsModal/>
	  </>
	)  	
}

export default SwitchActiveOrganization;