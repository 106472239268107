import React, {useState, Fragment, useContext, useEffect} from 'react'
import FilterSlider from "../../Common/FilterSlider"
import {OrgFilter} from '../../../constants/DataSourceList'
import {CheckBox} from "../../Common/FormInput"
import useModal from '../../../hooks/useModal'
import SortDirection from '../../Common/SortDirection'
import useSort from '../../../hooks/useSorting'
import FormHelper from '../../FormBuilder/FormHelpers'

const Filters = ({filter, setFilter, dataSource, currentUser, getpreSchedulesList, requestParams}) =>{
  const {sort} = useSort(requestParams, getpreSchedulesList)

  const stringFilter = (value, name) => {
    if(value!=null && value != filter[name]){
      setFilter({...filter, [name]:value})
    }else{
      delete filter[name]
      setFilter({...filter})
    }
  }

 /* const toggleFilter = (value, name) => {
    if(value && value != filter[name]){
      setFilter({...filter, [name]:value})
    }else{
      delete filter[name]
      setFilter({...filter})
    }
  }*/

  const exists = (value, name) => filter[name] === value

  return (
    <div className="filter-box-scroll p-5">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Assignment Filter
        </h5>

        <CheckBox list={OrgFilter}
          labelKey="label" valueKey="sid" 
          toggle={stringFilter} exists={exists} 
          name="assignment_filter"/>
      </div>
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Organizations
        </h5>

        <CheckBox list={currentUser.organizations || []} 
          labelKey="name" valueKey="id" 
          toggle={stringFilter} exists={exists} 
          name="assigned_to_organization_id"/> 
      </div>
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Language
        </h5>

        <CheckBox list={dataSource.languages.options || []} 
          labelKey="label" valueKey="value" 
          toggle={stringFilter} exists={exists} 
          name="language"/> 
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          IPA Types
        </h5>

        <CheckBox list={dataSource.journey_ipa_types?.options || []} 
          labelKey="label" valueKey="calendar_child_form" 
          toggle={stringFilter} exists={exists} 
          name="form_id"/> 
      </div>
    </div>
  )
}

const FilterBtn = (props) =>{
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const filterCount = Object.keys(props.filter).length
  
  return(
    <>
      <span data-tip="Filter" 
        className="bg-highlight round-btn m-r-5" 
        onClick={()=>toggleFilterModal()}>
          <i className="fas fa-sliders-h"/>
          {filterCount > 0 ? <span className="badge-2 bg-black-light-1 up">{filterCount}</span>:''}
      </span>

      {isFilterOpen &&
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={toggleFilterModal}
          {...props}/>
      }
    </>
  )
}

export {Filters, FilterBtn};