import React ,{Fragment,useState} from "react"
import {Link} from "react-router-dom"
import Cs from '../../services/CommonService'
import {FormFieldCard, RenderData} from "../HealthJourney/UserRoles/NotificationPill"
import CommonService from '../../services/CommonService'
import StepAlertList from './StepAlertList'
import Tabs, { TabPane } from 'rc-tabs'
import {MaximizeIcon, ChatGrayIcon, CheckListGrayIcon, EditGrayIcon, HamburgerMenuIcon} from '../Common/ImageIcons'

const Card = (props) => {
  let {nutritionCare, formFieldsList, addComment, addChecklist, addMenu, dataSource, permission} = props
  let {data, data_source} = nutritionCare
  let formFields = formFieldsList[nutritionCare.form_id] || []

  const [alerts, setAlerts] = useState(nutritionCare?.nutrition_care_alerts || [])
  const [activeKey, setActiveKey] = useState('1')

  const cardStyle = {backgroundColor: "#607D8B"}

  if (!data) return null;

  var onTabChange = function(key) {
    setActiveKey(key)
  }
  
  const TemplateEle = () =>{
    const css = 'badge badge-danger-dark text-white m-r-5 pull-right'
    if(nutritionCare.is_template){
      return <span className={css}>T/{nutritionCare.assigned_organizations?.length}</span>
    }else{
      return <span className={css}>C</span>
    }

    return null
  }

  const FormType = () =>{
    return dataSource.journey_ipa_types
    ?.options?.find((o=> o.ipa_child_form == nutritionCare.form_id))?.label
  }

  const Header = () => (
    <div className="card-title card-title-gray flex p-b-15 p20 coldir">
      <h4 className="fw700 f18 card-heading flex coldir text-left">
        {data.title}
      </h4>
      <div className="f14 m-t-10 fw500">
        {nutritionCare.author_name} &nbsp;-&nbsp; 
        {CommonService.formatUpdateDate(nutritionCare.updated_at)} &nbsp;-&nbsp;
        {FormType()}
        <TemplateEle/>
      </div>
    </div>
  )

  const Footer = () => {
    return(
      <div className="card-footer flex justspacebetween p20 aligncenter">
        <ul className="flex aligncenter">
          <li className="m-r-25">{nutritionCare.record_id}</li>
          <li className="m-r-25">
            <ChatGrayIcon onSelect={() => addComment(nutritionCare)}/>
          </li>
          <li className="m-r-25">
            <CheckListGrayIcon onSelect={() => addChecklist(nutritionCare)}/>
          </li>
          {permission.write ?
            <li className="m-r-25">
              <EditGrayIcon link={`/nutrition_care/edit/${nutritionCare.id}`}/>
            </li>
            : null
          }
        </ul>
        <HamburgerMenuIcon onSelect={()=>addMenu(nutritionCare)}/>
      </div>
    )
  }

  return (
    <div className="bg-white border-rounded-10 card-shadow m-b-15">
      <Header/>
      <div className="card-content p20">
        <ul className="flex aligncenter">
          <li>
            <span onClick={(e)=>onTabChange(1)}
              className={`b-nav-tab-table ${activeKey==1?'active':''}`}>
              Protocol
            </span>
          </li>
          <li>
            <span onClick={(e)=>onTabChange(2)} 
              className={`b-nav-tab-table ${activeKey==2?'active':''}`}>
              Alerts
              <span className="badge border-rounded badge-gray m-l-10">{alerts?.length || 0}</span>
            </span>
          </li>
        </ul>
        {activeKey==1 &&
          <div className="b-tab-table active scroll-auto ht-400">
            <div className="p-b-15 m-b-15 m-t-15">
              <FormFieldCard formFields={formFields} data={data} dataSource={data_source}/>
            </div>
          </div>
        }
        {activeKey==2 &&
          <div className="b-tab-table active scroll-auto ht-400">
            <StepAlertList alerts={alerts} setAlerts={setAlerts}/>
          </div>
        }
      </div>
      <Footer/>
    </div>
  )
}

export default Card;