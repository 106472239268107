import React, { useState, Fragment, useEffect, useContext } from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Activity from "./Activity";
import ActivityService from "../../Activities/ActivityService";
import Pagination from "../../../../Pagination";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import CommentsModal from "../../../../Modals/CommentsModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import CardMenu from "../../../../Modals/CardMenu";
import { Link } from "react-router-dom";
import useModal from "../../../../../hooks/useModal";
import useStyle from "../../../../../hooks/useStyle";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import FilterModal from "./TreatmentFilterModal";
import Title from "../../../GenericPortalPage/Title";
import CommonService from "../../../../../services/CommonService";

let requestParams = {}
let selectedActivity = {}
let data_source = {}
let journey_category = null;
let child_data = null;

const ActivityList = (props) => {
  useStyle("card")
  useStyle("card_table")
  useStyle("add_role_dialog")

  let params = props.match.params;
  let {project, child} = props.location.state;
  let queryParam = querystringify.parse(props.location.search)

  let cardGridCss = "col-xs-12 col-sm-6 col-md-4";
  let cardHeightCss = "ht-420";
  if (props.id) {
    cardGridCss = "col-sm-12";
    cardHeightCss = "mih-380";
  }

  const { state: authState, dispatch } = useContext(AuthContext)
  const [journeyActivityList, setJourneyActivityList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen:isFormFilterOpen, toggleModal:toggleFormFilterModal } = useModal()
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal,} = useModal()
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()

  useEffect(() => {
    ReactTooltip.rebuild()
    journey_category = null;
    requestParams = {
      data_source_params: {
        data_source_id: [3467],
        response_type: "object",
      },
      journey_profile_id: params.health_id,
      activity_form_id: queryParam.activity_form_id,
      id: props.id
    }

    getJourneyActivityList()
  }, [props.searchString, currentpage])

  let getJourneyActivityList = () => {
    ActivityService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        data_source = res.data.data_source_list;
        //requestParams.totalItems = res.data.meta.total;
        //journey_category = res.data.meta.journey_category;
        //CheckAccess.userAccess(authState.user, journey_category, res.data.meta.invited_journey_member)
        if(res.data.child){
          let c = res.data.child
          c.data.baby_age = CommonService.getAge(c.data.dob_baby_formatted_date)
          child_data = {
            child_fk_id: c.record_id, 
            dob_baby_formatted_date: c.data.dob_baby_formatted_date, 
            id_mother: c.data.id_mother, 
            baby_age: c.data.baby_age
          }
        }

        setJourneyActivityList(res.data.activities)
      }
    })
  }

  const deleteActivity = (e) => {
    e.preventDefault()
    ActivityService.delete(selectedActivity.id).then((res) => {
      if (res.status == 204) {
        setJourneyActivityList(
          journeyActivityList.filter((i) => i.id !== selectedActivity.id)
        )
      }
    })
    toggleDeleteModal(e)
  }

  const showMenu = (e, a) => {
    selectedActivity = a
    toggleMenuModal(e)
  }

  const addChecklist = (e, a) => {
    selectedActivity = a
    toggleChecklistModal(e)
  }

  const addNotes = (e, a) => {
    selectedActivity = a
    toggleCommentModal(e)
  }

  const routeActivity = (activity) => {
    props.history.push(
      `/team/activity/${params.journey_category_id}/${params.education_journey_profile_id}?id=${activity.id}`
    )
    setJourneyActivityList(
      journeyActivityList.filter((i) => i.id == activity.id)
    )
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        <a href="#" onClick={(e) => toggleDeleteModal(e)}>
          <span className="font-13">{item.activity_form_id} - {item.record_id}</span>
        </a>

        <Link to={`/health/activityForm/${item.journey_profile_id}/${item.id}?activity_form_id=${queryParam.activity_form_id}`}>
          <i className="far fa-edit font-15" aria-hidden="true"></i>
          <span className="font-13">Edit</span>
          <i className="fa fa-angle-right"></i>
        </Link>

        <a href="#" onClick={(e) => toggleDeleteModal(e)}>
          <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
          <span className="font-13">Delete</span>
          <i className="fa fa-angle-right"></i>
        </a>

        <Link to={{pathname: `/journey/user_role/${item.journey_category_id}/${item.education_journey_profile_id}/edu_team_journey.activity/${item.id}`, state: { journey: props.location.state, activity: item },}}>
          <i className="far fa-eye-slash text-muted font-15" aria-hidden="true"></i>
          <span className="font-13">
            Permissions{" "}
            <i className={`fas ${
                item.user_roles == null? "fa-times m-l-120 m-t-n2 lred":"fa-check m-l-120 m-t-n2 green"
            } `}></i>
          </span>
          <i className="fa fa-angle-right"></i>
        </Link>

        <Link to={{pathname: `/journey/users/${item.journey_category_id}/${item.education_journey_profile_id}/edu_team_journey.activity/${item.id}`, state: { journey: props.location.state, activity: item },}}>
          <i className="fa fa-users font-15" aria-hidden="true"></i>
          <span className="font-13">Team</span>
          <i className="fa fa-angle-right"></i>
        </Link>
      </Fragment>
    )
  }

  const Menu = ({ activity }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, activity)} className="fas fa-bars m-r-15 font-16"/>
  )

  /*if(journeyActivityList.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  const journeyActivityCard = journeyActivityList && journeyActivityList.map((a, i) => (
      <div className={cardGridCss} key={i}>
        <Activity
          activity={a}
          dataSource={data_source}
          menu={Menu}
          addNotes={addNotes}
          addChecklist={addChecklist}
          journeyCategory={journey_category}
          cardHeightCss={cardHeightCss}
          routeActivity={routeActivity}/>
        {selectedActivity.id == a.id && (
          <ProjectMenu
            isOpen={isMenuOpen}
            toggleModal={toggleMenuModal}
            item={selectedActivity}
            type="activity"
            MenuItems={menuItems}
          />
        )}
      </div>
  ))

  return (
    <React.Fragment>
      {!props.id &&
        <div className="content top-10 clearfix">
          <div className="col-xs-8">
            <Title title={queryParam.label + " Info"} project={props.location.state} />
          </div>
          <div className="col-xs-4">
            <Link to={{pathname:`/health/activityForm/${params.health_id}`,
                state: child_data}}
              data-tip="Create New Activity"
              className="bg-highlight round-btn float-right m-r-5 m-t-4">
              <i className="fas fa-plus"></i>
            </Link>

            <Link data-tip="Back to Journey Home"
              to={{pathname:`/health/portal/${params.health_id}`, state:project}} 
              className="bg-highlight round-btn float-right m-r-5 m-t-4">
              <i className="fas fa-home"></i>
            </Link>
          </div>
        </div>
      }
        
      <div className="page-content row">
        {journeyActivityCard}
        <Pagination totalItems={requestParams.totalItems} currentpage={currentpage}
          setCurrentpage={setCurrentpage} itemsPerPage={requestParams.per_page}/>
      </div>

      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteActivity} />
        {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedActivity} type="activity" />}
        {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedActivity} type="activity"/>}
        {isFormFilterOpen && <FilterModal journey_profile_id={params.health_id} data_source={data_source} isOpen={isFormFilterOpen} toggleModal={toggleFormFilterModal} />}
    </React.Fragment>
  )
}

export default ActivityList;