import React, {Fragment, useState, useEffect} from "react"
import DataPoint from '../../../../constants/ChildGrowthDataPoints'
import CommentsModal from "../../../Modals/CommentsModal";
import useModal from "../../../../hooks/useModal";

const GrowthAlert = ({selectedActivity}) => {
  
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal();
  let childAlertLabel = ``
  let childAlertMag = ``
  if(selectedActivity.height_note){
    childAlertMag = DataPoint.HeightWarning[selectedActivity.height_note]
    childAlertLabel = DataPoint.HeightWarningLabel[selectedActivity.height_note]
  }else if(selectedActivity.weight_note){
    childAlertMag = DataPoint.WeightWarning[selectedActivity.weight_note]
    childAlertLabel = DataPoint.WeightWarningLabel[selectedActivity.weight_note]
  }

  return(
    <Fragment>
      <div className="font-16 p-5 m-7 text-center">
        <i className={childAlertLabel}></i>
      </div>
      <div className="font-16 p-5 m-7" dangerouslySetInnerHTML={{ __html: childAlertMag }}></div>
      <div className="text-right p-5 m-7">
      <button className="dropbtn bg-submit m-t-5 m-b-10" onClick={e=>toggleCommentModal()}> Add Note</button>
      </div>
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedActivity} type="infant_journey.activity"/>}
    </Fragment>
  )

}  

export default GrowthAlert;