import React, { useEffect, useState } from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../PreLoadSpinner";
import DynamicForm from '../FormBuilder/DynamicForm';
import FormContainer from '../FormBuilder/FormContainer';
import FormTemplateId from '../../constants/FormTemplateList'
import DSSuggestionService from "./DSSuggestionService";

let formMode = null; 
let formId = null; 
let datasource = {}; 
let data = {}; 
let formFn = {};  

function DataSourceForm(props) {
  const { state:authState, dispatch } = React.useContext(AuthContext);
  const [isFormRendered, setFormRendered] = useState(false);
  const [post, setPost] = useState({});

  let params = props.match.params;

  let currentUser = authState.user;

  /*TODO: Move API to Router to load data before view*/
 
  const get = () => {
    DSSuggestionService.get(params.id).then((res) => {
      if(res.status==200){
        datasource = res.data.discussion_request;
        data = datasource.data || {};
        /*TODO Render Delay Issue*/
        setFormRendered(true);
      }
    }).catch(function(res){
       datasource = null;
    })
  }
  
  useEffect(() => { 
    datasource = {}; 
    data = {}; 
    formFn = {}; 
    formMode = 'create-form-submissions';
    if(params.id){
      formMode = 'edit-form-submissions';
      get()
    }else if(!isFormRendered){
      setFormRendered(true);
    }
   
  }, [params.id]);

  const create = (datasource) => {
    datasource.organization_id = params.organization_id;
    DSSuggestionService.add(datasource).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const update = (datasource) => {
    datasource.organization_id = params.organization_id;
    DSSuggestionService.update(datasource).then((res) => {
      if (res.status == 204) {
        onSubmitPageRedirect();
      }
    }).catch((res)=> {});
  }


  const onSubmitPageRedirect = () => {
    props.history.push("/list/data_sources")
  }

  if(isFormRendered){
    return (
      <FormContainer post={post}>
        <DynamicForm formMode={formMode} formId={FormTemplateId.DataSourceForm} form={datasource} data={data} 
          formFn={formFn} onCreate={create} onUpdate={update} post={post} onCancel={onSubmitPageRedirect} 
          setPost={setPost}/>
      </FormContainer>
    );  
  }else{
    return('')
  }
  
}

export default DataSourceForm;