import React, { useEffect, useState, useContext, Fragment} from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import CommonService from "../../services/CommonService";
import useStyle from '../../hooks/useStyle';
import {Link} from "react-router-dom";
import env from '../../env';

let data_source = null;
let users = {};
let profileImage = 'images/avatars/2s.png'

const HealthFamilyDashboard = (props) => {
  useStyle('dashboard');
  useStyle("card")
  useStyle("card_table")

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext); 

  useEffect(() => {


  }, [currentUser.id])


  return (
    <Fragment>
      <div className='content top-10 clearfix'>
        <div className="col-xs-8">
          Home / Family Name
        </div>
        <div className="col-xs-4">
          
        </div>
      </div>  
      <div className="page-content row">
        <div className="col-xs-12 col-md-3">
          <div className="card card-style">
            <div className="content ht-200">
              <Link to="" className="head-boder m-t-10">
                <h3><i className="fas fa-info m-r-5 font-24 green"/> Family info</h3>
              </Link>
              <p className="m-b-10">
              </p>
              Add Child Details
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-md-6">
          <div className="card card-style">
            <div className="content ht-200">
              <Link to="" className="head-boder m-t-10">
                <h3><i className="fas fa-table m-r-5 font-24 green"/> Details</h3>
              </Link>
              <p className="m-b-10">
              </p>
              Data from Form #500 displayed here in a simple row by row presentation
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-md-3">
          <div className="card card-style">
            <div className="content ht-200">
              <Link to="" className="head-boder m-t-10">
                <h3><i className="fas fa-edit m-r-5 font-24 green"/> Notes</h3>
              </Link>
              <p className="m-b-10">
              </p>
              This is a brief note left by John
              @jdoe. Mar 5, 2022
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-md-12">
          <div className="card card-style">
            <div className="content ht-200">
              <Link to="" className="head-boder m-t-10">
                <h3><i className="fas fa-globe m-r-5 font-24 lightcoral"/> Child Name Here </h3>
                <span>DOB: January 1, 2022. H: XX.XX cms. W: x.xx kgs</span>
              </Link>
              <p className="m-b-10">
                Tap to view more.
              </p>
              <div className={`ht-480 scroll-auto`}>
                <table className="table card-table">
                  <thead>
                    <th>
                      Id
                    </th>
                    <th>
                      Date
                    </th>
                    <th>
                      W (kgs)
                    </th>
                    <th>
                      H (cms)
                    </th>
                    <th>
                      Note
                    </th>
                    <th>
                      By
                    </th>
                    <th>
                      Options
                    </th>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        
                      </td>
                      <td>
                        
                      </td>
                      <td>
                        
                      </td>
                      <td>
                        
                      </td>
                      <td>
                        
                      </td>
                      <td>
                        
                      </td>
                      <td>
                        
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default HealthFamilyDashboard;