import React, { useEffect, useState } from 'react';
import PreLoadSpinner from "../PreLoadSpinner";
import DynamicForm from '../FormBuilder/DynamicForm';
import FormTemplateId from '../../constants/FormTemplateList'
import FormContainer from '../FormBuilder/FormContainer';
import ContactService from './ContactService'

let formMode = null; 
let contact = {}; 
let data = {}; 
let formFn = {};  

function ContactForm(props) {

  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  const [isSubmitted, setIsSubmitted] = useState(false)

  const params = props.match.params

  useEffect(() => { 
    data = {}
    contact = {} 
    formMode = 'create-form-submissions'
    formFn = {
      'form_type':'contactus',
      'is_public':true //Access From Template without login
    }
   
    if(params.id){
      formMode = 'edit-form-submissions'
      getById(params.id)
    }else{
      setFormRendered(true)
    }
  }, [])

  const getById = (id) => {
    ContactService.get(id).then((res) => {
      if(res.status===200){
        contact = res.data.contactus
        data = contact.data || {}
        setFormRendered(true)
      }
    }).catch(function(res){
      contact = null
    })
  }

  const create = (contact) => {
    ContactService.create(contact).then((res) => {
      if(res.status===201){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const update = (contact) => {
    ContactService.update(contact.id, contact).then((res) => {
      if(res.status===204){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = (id) => {
    setIsSubmitted(true)
  }

  if(isFormRendered){
    return (
      <div id="ct">
        <FormContainer post={post} isPopupMode={true}>
          {isSubmitted?
            <h4 className="bg-white p-20 text-center">Thank's will contact you soon.</h4>
            :
            <DynamicForm formMode={formMode} formId={FormTemplateId.ContactForm} form={contact}  
            formFn={formFn} onCreate={create} onUpdate={update} data={data} post={post} 
            onCancel={onSubmitPageRedirect} setPost={setPost} isPopupMode={true}/>
          }
        </FormContainer>
      </div>
    )  
  }else{
    return null
  }
  
}

export default ContactForm;