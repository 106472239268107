import React, {useRef, useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import SortDirection from '../Common/SortDirection'
import useSort from '../../hooks/useSorting';
import useStyle from '../../hooks/useStyle';
import Cs from '../../services/CommonService'
import {RecordAudioSafari} from '../Common/RecordAudio'
import FileUploadService from '../../services/FileUploadService'
import env from "../../env"
import {OptionsObjList} from "../Common/FormInput"
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import GenericModal from "../Modals/GenericModal"
import useModal from "../../hooks/useModal"
import { LocalizeContext } from "../../contexts/Localize/LocalizeStateProvider"
import {usePageLocale} from "../../hooks/useLocale"
import { CTTooltip, CueTip } from "../Common/CueTooltip"

let requestParams = null
let dataSource = {}

const AudioRecordingList = ({journeyProfileId, incrementCount}) => {

  const { state:{user:currentUser} } = useContext(AuthContext)

  let [list, setList] = useState([])
  const [selectedItem, setSelectedItem] = useState()
  const [subject, setSubject] = useState()
  
  useEffect(() => {
    requestParams = {
      page:1,
      per_page:100,
      fileable_type:'journey_profile_audio',
      journey_profile_id:journeyProfileId,
      data_source_params:{
        data_source_id: [3692],
      }
    }

    getAll()
  }, []);

  const getAll = () =>{
    FileUploadService.getAll(requestParams).then((res)=>{
      dataSource = res.data.data_source_list
      setList(res.data.file_uploads)
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const onUploadSuccess = (data) =>{
    data.file_upload.author = {name:currentUser.name}
    list.unshift(data.file_upload)
    setList([...list])
    incrementCount()
  }

  const onDelete = (file) => {
    Cs.deleteFileFromUrl(file.url).then((res)=>{
      if(res.status == 204){
        setList(list.filter((i) => i.id !== file.id))
      }
    })
  }

  const AudioRecordingTable = () =>(
    <Table className="shadow-small">
      <THead>
        <TR>
          <TH onClick={e=>sort('record_id')}>
            Id 
          </TH>
          <TH onClick={e=>sort('record_id')}>
            Audio
          </TH>
          <TH onClick={e=>sort('updated_at')}>
            Date
          </TH>
          <TH onClick={e=>sort('created_by')}>
            User
          </TH>
          <TH onClick={e=>sort('record_id')}>
            Options
          </TH>
        </TR>
      </THead>
      <TBody>
        {tableData}
      </TBody>
    </Table>
  )

  const tableData = list.map((s, k) => {
    return(
      <TR key={k}>
        <TD className="text-center" data-title="Id">{s.id}</TD>

        <TD className="text-center">
          <audio controls autoplay>
            <source src={env.file_url + s.url} type="audio/wav"/>
          </audio>
        </TD>

        <TD className="text-center">
          {Cs.formatUpdateDate(s.updated_at, 'MMMM DD YYYY')}
        </TD>
       
        <TD className="text-center">
          {s?.author?.name}
        </TD>

        <TD className="text-center">
          <span className="fas fa-trash m-r-15 font-18 text-muted" onClick={()=>onDelete(s)}/> 
        </TD>
      </TR>
    )
  })
  return (
    <div id="ct">
      <div className='p-10'>
        <label className="font-14">Subject</label>
        <select className="form-control ml-margin-bottom" onChange={e=>setSubject(e.target.value)} placeholder="Subject">
          <OptionsObjList list={dataSource.languages?.options || []} id_key='label' label_key='label'/>
        </select>
        <RecordAudioSafari journeyProfileId={journeyProfileId} onUploadSuccess={onUploadSuccess} subject={subject}/>
      </div>
      <div className="table-responsive">
        <AudioRecordingTable/>
      </div>
    </div>
  )
}

const AudioRecordingBtn = ({journey, currentUser}) =>{

  const { isOpen: isAudioRecorderOpen, toggleModal:toggleAudioRecorder } = useModal()

  const [count, setCount] = useState(journey.audio_file_count || 0)
  const {labelLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage')

  const incrementCount = () => setCount(parseInt(count) + 1)

  return(
    <>
      <span id="voice_message" 
        onClick={() => toggleAudioRecorder()} className="bg-grey-4 round-btn m-r-5 m-t-4 tooltip">
        <i className="fas fa-microphone" />
        <span className="badge-2 up p-abs">{count}</span>
        <CueTip tooltip={labelLocale(5)} positionCss="bottom"/>
      </span>
      
      {isAudioRecorderOpen && 
        <GenericModal component={AudioRecordingList} 
          toggleModal={toggleAudioRecorder} 
          title={labelLocale(5)}
          journeyProfileId={journey.id}
          incrementCount={incrementCount}/>
      }
    </>
  )
}

export {AudioRecordingList, AudioRecordingBtn};