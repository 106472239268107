import React, {useState} from "react";
import ReactTooltip from 'react-tooltip';
import {Link} from "react-router-dom";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import InvitedUser from '../InvitedUsers/InvitedUser'
import InvitedUserService from "../InvitedUsers/InvitedUserService";
import ConfirmMenu from '../../Modals/ConfirmMenu'
import InvitedUserModal from '../InvitedUsers/InvitedUserModal'
import useModal from '../../../hooks/useModal';
import useStyle from '../../../hooks/useStyle';
import PreLoadSpinner from "../../../components/PreLoadSpinner";
import Title from "../GenericPortalPage/Title";

let requestParams = {};
let newInviteUser = {};
let dataSources = {};
let familyMembers = [];
let roles = [];
let rolesObj ={};
let selectedInviteUser = null

const InvitedUserList = (props) => {
  
  useStyle('user_lists');
  useStyle('add_role_dialog');
  
  let params = props.match.params;
  let {project, child} = props.location.state;

  const { state:currentUser, dispatch } = React.useContext(AuthContext);
  const [invitedUserList, setInvitedUserList] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);

  let dataSources = {
    'notificationsList':{
      'new_journal_entry':'New Tile',
      'new_activity':'New Activity Entry',
      'new_comment':'New Comment'
    },
    'permissions':{
      'full_access':'Full Access',
      'add_activity':'Create Tile',
    }
  }

  React.useEffect(() => { 
    ReactTooltip.rebuild() 
    requestParams = {
      'journey_profile_id':params.journey_profile_id,
    
    }

    getInvitedUserList();
  }, [props.searchString]);

  let getInvitedUserList = () => { 
    InvitedUserService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       setInvitedUserList(res.data.roles)
      }
    })
  }

  const deleteUser = (e) => {
    e.preventDefault();
    InvitedUserService.delete(selectedInviteUser.id).then((res) => {
      if(res.status==204){
        setInvitedUserList(invitedUserList.filter(r => r.id !== selectedInviteUser.id));
      }
    })  
    toggleDeletePopup(e);  
  }

  const setEdit = (e, invite_user) => {
    e.stopPropagation();
    newInviteUser = invite_user;
    toggleInviteUserPopup(e);
  }
  
  const addInviteUser = (e, new_invite_user) => {
    e.preventDefault();
    if(new_invite_user.id){
      InvitedUserService.update(new_invite_user).then((res) => {
        if(res.status==204){
          setInvitedUserList([new_invite_user].concat(invitedUserList.filter(r => r.id !== new_invite_user.id)))
          restModal(e);
        }
      })  
    }else{
      new_invite_user.created_by = currentUser.user.id;
      new_invite_user.journey_proile_id = params.journey_proile_id;      
      InvitedUserService.create(new_invite_user).then((res) => {
        if(res.status==201){
          setInvitedUserList([new_invite_user].concat(invitedUserList))
          restModal(e)
        }
      })  
    }
  }

  const restModal = (e) =>{
    e.stopPropagation();
    newInviteUser = {};
    toggleInviteUserPopup(e);
  }

  const selectInviteUser = (e, invited_user) =>{
    selectedInviteUser=invited_user;
    toggleDeletePopup(e, invited_user)
  }

  const {isOpen:isDeletePopupOpen, toggleModal:toggleDeletePopup} = useModal();
  const {isOpen:isInviteUserRolePopupOpen, toggleModal:toggleInviteUserPopup} = useModal();

  const Menu = ({invited_user}) => (
    <div className="dropup pull-right m-r-15">
      <button className="dropbtn">Menu</button>      
      <div className="dropup-content">
        <a href="#" onClick={e => {setEdit(e, invited_user)}}>Edit</a>
        <a href="#" onClick={e => {selectInviteUser(e, invited_user)} }>Delete</a>
      </div>
    </div>
  )

  /*if(invitedUserList.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/
    
  return (
    <React.Fragment>
      <div className="content m-t-15">
        <Title title={"Invited Users"} project={props.location.state} />
        <div className="clearfix m-b-10"/>
        <a href="#" data-tip="Add Role" className="bg-highlight round-btn float-right m-r-5 m-t-4" onClick={e=>restModal(e)}>
          <i className="fas fa-plus"></i>
        </a>
        <a data-tip="Back to Journey Home" className="bg-highlight round-btn float-right m-r-5 m-t-4" onClick={e=>{props.history.goBack();e.preventDefault();}}>
          <i className="fas fa-home"></i>
        </a>
        <div className="clear"/>
      </div>

      <div className="page-content row">
        {invitedUserList.map((invited_user, i) => (
          <div className="col-xs-12 col-sm-6 col-md-4 m-b-10" key={i}>
            <InvitedUser invited_user={invited_user} menu={Menu} setEdit={setEdit} selectInviteUser ={selectInviteUser} rolesObj={rolesObj}/>
          </div>
        ))}
      </div>

      <ConfirmMenu isOpen={isDeletePopupOpen} toggleModal={toggleDeletePopup} success={deleteUser}/>
      {isInviteUserRolePopupOpen && <InvitedUserModal inviteUser={newInviteUser} isOpen={isInviteUserRolePopupOpen} toggleModal={toggleInviteUserPopup} addInviteUser={addInviteUser} dataSources={dataSources} roles={roles} familyMembers={familyMembers}/>}
    </React.Fragment>
  )

};

export default InvitedUserList;

