import React, {useState, Fragment, useContext, useEffect} from "react"
import Pagination from '../Pagination'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import CheckAccess from '../HealthJourney/Roles/CheckAccess' 
import { CTTooltip } from "../Common/CueTooltip"
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons"
import {FontAwesomeBtn} from "../Common/Button"
import ConfirmMenu from "../Modals/ConfirmMenu"
import FormDataChartsService from "./FormDataChartsService"
import useSort from '../../hooks/useSorting'
import SortDirection from '../Common/SortDirection'
import useModal from "../../hooks/useModal"
import Cs from "../../services/CommonService"
import {ChartTypes} from "../../constants/DataSourceList"
import {OptionsObjList} from "../Common/FormInput"
import GenericModal from "../Modals/GenericModal"
import FormHelper from "../FormBuilder/FormHelpers"

let requestParams = {}
let selectedChart = null
const FormDataChartsConfig = ({field}) => {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [loading, setLoading] = useState(false)
  const [masterList, setMasterList] = useState([])
  const [orgMap, setOrgMap] = useState([])
  const [dataSources, setDataSources] = useState([])
  const [isMasterOrg, setMasterOrg] = useState(false)

  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal()
  const {isOpen:isAddConfigOpen, toggleModal:toggleAddConfig} = useModal()

  useEffect(()=>{
    requestParams = {
      page:1,
      per_page:100,
      form_template_id:field.id,
      field_index:field.index,
      data_source_params: {
        data_source_id:[3902]
      }
    }

    getAll()
  }, [])

  const getAll = () =>{
    setLoading(true)
    FormDataChartsService.getAll(requestParams).then(({status, data})=>{
      if(status === 200){
        setMasterList(data.master_data_charts)
        setOrgMap(Cs.listToObj(data.org_data_charts, 'parent_data_chart_id'))
        setDataSources(data.data_source_list)
        setMasterOrg(data.is_master_org)
        setLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const onSubmit = (config, index) =>{
    if(config.id){
      updateChart(config, index)
    }else{
      addNewChart(config)
    }
    toggleAddConfig()
  }

  const addNewChart = (config) =>{
    setLoading(true)
    config.sub_organization_id = currentUser.current_organization_id
    config.form_template_id = field.id
    config.field_index = field.index
    FormDataChartsService.create(config).then(({status, data})=>{
      if(status === 201){
        if(isMasterOrg){
          setMasterList([...masterList, data.form_data_chart])
        }else{
          setOrgMap({
            ...orgMap, 
            [config.parent_data_chart_id]: data.form_data_chart
          })
        }
        setLoading(false)
      }
    })
  }

  const deleteChart = (e) =>{
    e && e.preventDefault()
    setLoading(true)
    FormDataChartsService.delete(selectedChart.id).then(({status}) => {
      if (status === 204) {
        if(isMasterOrg){
          setMasterList(masterList.filter(i => i.id !== selectedChart.id))
        }else{
          setOrgMap({
            ...orgMap,
            [selectedChart.parent_data_chart_id]:null
          })
        }
        setLoading(false)
      }
    })
    toggleDeleteModal(e)
  }

  const updateChart = (config, index, key) =>{
    setLoading(true)
    FormDataChartsService.update(config).then(({status, data})=>{
      if(status === 204){
        if(key){
          masterList[index][key] = config[key]
        }else{
          masterList[index] = config
        }
        setMasterList([...masterList])
        setLoading(false)
      }
    })
  }

  const onDelete = (chart) => {
    selectedChart = chart
    toggleDeleteModal()
  }

  const onEdit = (chart, index) =>{
    selectedChart = chart
    selectedChart.index = index
    toggleAddConfig()
  }

  const addToCurrentOrg = (chart) =>{
    const d = orgMap[chart.id] 
    if(d){
      onDelete(d)
    }else{
      let copyChart = {...chart, id:null, parent_data_chart_id:chart.id}
      addNewChart(copyChart)
    }
  }

  const tableData = masterList.map((o, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray">
          {o.id}
        </TD>
        <TD className="fw500 text-cap">
          {o.chart_type}
        </TD>
        <TD>
          {Cs.formatUpdateDate(o.created_at)}
        </TD>
        <TD>
          <div className="flex aligncenter">
            {isMasterOrg ?
              <>
                <span className={o.is_active ? 
                  'fas fa-toggle-on font-30 green m-l-5'
                  :'fas fa-toggle-on fa-rotate-180 font-30 red m-l-15'
                  } onClick={()=> {o.is_active = !o.is_active;updateChart(o, k, 'is_active')}}>
                </span>

                <LinkFontAwesomeIcon id="edit" tooltip="Edit" 
                  onSelect={()=>onEdit(o, k)}
                  css="fas fa-edit white"/>

                <LinkDeleteIcon id="delete" tooltip="Delete" onSelect={()=>onDelete(o)}/>
              </>
              :
              <span 
                onClick={()=> addToCurrentOrg(o)}
                className={orgMap[o.id] ? 
                  'fas fa-toggle-on font-30 green m-l-5'
                  :'fas fa-toggle-on fa-rotate-180 font-30 red m-l-15'
                } >
              </span>
            }
          </div>
        </TD>
      </TR>
    )
  })

  if(isAddConfigOpen){
    return <GenericModal component={AddChartConfig} 
      isOpen={isAddConfigOpen} toggleModal={toggleAddConfig}
      editChart={selectedChart} index={selectedChart?.index}
      onSubmit={onSubmit} dataSources={dataSources}/>
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20">
                {field.label}
              </div>
              <div className="font-15">
                Charts - {masterList.length}
              </div>
            </div>
            <div className="flex aligncenter">
              {isMasterOrg &&
                <FontAwesomeBtn css="m-r-5" 
                  name="Add" iconCss="fas fa-plus" isResponsive={false}
                  id="new_chart" tooltip="Create New Chart" 
                  onSelect={()=>{selectedChart=null;toggleAddConfig()}}>
                </FontAwesomeBtn>
              }
            </div>
          </div>
          
          {loading && <div className="spinner"/>}

          <div className="app-content-section m-t-30">
            <Table className="shadow-small">
              <THead>
                <TR>
                  <TH onClick={e=>sort('record_id')} >
                    ID 
                    <SortDirection sort_column={requestParams.sort_column} 
                      column="record_id" 
                      reverse={requestParams.reverse}/>
                  </TH>
                  <TH onClick={e=>sort('chart_type')} >
                    Chart Type
                    <SortDirection sort_column={requestParams.sort_column} 
                      column="chart_type" 
                      reverse={requestParams.reverse}/>
                  </TH>
                  <TH onClick={e=>sort('created_at')} >
                    Date
                    <SortDirection sort_column={requestParams.sort_column} 
                      column="created_at" 
                      reverse={requestParams.reverse}/>
                  </TH>
                  <TH>
                    Action
                  </TH>
                </TR>
              </THead>
              <TBody>
                {tableData}
              </TBody>
            </Table>
          </div>
        </div>
      </div>
        
      <ConfirmMenu isOpen={isDeleteOpen} 
        toggleModal={toggleDeleteModal} 
        success={deleteChart}/>
    </Fragment>
  )
}

const AddChartConfig = ({editChart, index, onSubmit, toggleModal, dataSources}) =>{
  
  const [chart, setChart] = useState(editChart || {})
  const [formError, setFormError] = useState({})

  useEffect(() => {
    FormHelper.validate('ct_form', chart, formError, setFormError)
  }, [chart])

  const onInputChange = ({name, value}) =>{
    setChart({...chart, [name]:value})
  }

  return(
    <Fragment>
      <div className="flex justspacebetween modal-title">
        <h4 className="f18 text-white fw500 p12-10-15">
          {chart.id?'Update':'Add'} Config
        </h4>
        <button className="btn-close" onClick={e=>toggleModal(e)}>⨉</button>
      </div>
      <form id="ct_form" className="ml-container">
        <label className="font-15 m-t-15 m-b-5 req-field">Chart</label>
        <select name="chart_type" onChange={e=>onInputChange(e.target)} 
          className="form-control ml-input ml-border m-b-20"
          defaultValue={chart.chart_type} required>
            <option value="">None</option>
            <OptionsObjList list={dataSources.chart_type.options} id_key="sid" label_key="label"/>
        </select>

        {formError.is_valid &&
          <div className="ml-button ml-block ml-section ml-padding bg-highlight white" 
          onClick={()=>onSubmit(chart, index)}>
            <b>{chart.id?'Update':'Add'}</b>
          </div>
        }  
      </form>
    </Fragment>  
  )
}

export default FormDataChartsConfig;