import env from '../env';
import BaseService from "./BaseService";
import axios from 'axios';

class FileUploadService extends BaseService {

  clone(req){
    return new Promise((resolve, reject) => {
      axios.post(env.health_backend+'health/file_upload/clone', req).then((res) => {
        // handle success
        resolve(res)
      })
    })
  }

}

export default new FileUploadService('health_backend', 'health/file_uploads');