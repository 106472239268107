import React, {Fragment} from "react"
import {Link} from "react-router-dom"
import {PermissionsObj} from "../../../constants/DataSourceList"
import Cs from "../../../services/CommonService"
import { MaximizeIcon } from "../../Common/ImageIcons"
import { LinkFontAwesomeIcon } from "../../Common/ImageIcons"

const Role = (props) => {
  let {role, dataSources, role:{data}, orgJourneyComponents, permission, organizationId} = props;  
  role.permissions = role.permissions || {}

  return (
    <Fragment>
      <div className="bg-white border-rounded-10 card-shadow m-b-15" >
        <div className="card-title card-title-gray flex p-b-15 p20 coldir">
          <h4 className="fw700 f18 card-heading flex coldir text-left">
            {role.role} &nbsp;-&nbsp;{role.description && role.description}
          </h4>
          <div className="f14 m-t-5 fw500"> 
            {role.user?.name}&nbsp;-&nbsp; {Cs.formatUpdateDate(role.updated_at)}
          </div>
        </div>
        
        <div className="card-content p10 ht-300 scroll-auto">
          {role.permissions ?
            <>
              <div className="br-grey p-10 m-t-10 m-b-10">
                <div className="m-b-5 font-500 font-14">{orgJourneyComponents?.side_menu?.label}</div>
                {Object.keys(role.permissions.menu || {}).map((value, index) => 
                  <li key={index} className="badge-2 p-5-10 d-hide">
                    {dataSources.side_menu[value]?.label}
                  </li>
                )} 
              </div>

              <div className="br-grey p-10 m-t-10 m-b-10">
                <div className="m-b-5 font-500 font-14">{orgJourneyComponents?.element_ppt_mcj?.label}</div>
                {Object.keys(role.permissions.infant_ppt || {}).map((value, index) => 
                  <li key={index} className="badge-2 p-5-10 d-hide">
                    {dataSources.infant_ppt[value]?.label}
                  </li>
                )} 
              </div>

              <div className="br-grey p-10 m-t-10 m-b-10">
                <div className="m-b-5 font-500 font-14">{orgJourneyComponents?.pins_process_mcj?.label}</div>
                {Object.keys(role.permissions.infant_process || {}).map((value, index) => 
                  <li key={index} className="badge-2 p-5-10 d-hide">
                    {dataSources.infant_process[value]?.label}
                  </li>
                )} 
              </div>
            </>
            :null
          }
            
          <div className="p-10 m-t-10 m-b-10">
            <div className="text-cap role">
              <div className="role-list-scroll">
                {role.role_users && role.role_users.map((u, i) => 
                  <div className="float-left m-l-15" key={i}>
                    <i onClick={e=>props.deleteInviteUser(e, u, role)} className="pull-right fa fa-times lred" />
                    <img src="images/avatars/2s.png" alt="img" className="shadow-small bg-blue2-dark" />
                    <a className="profile-pic">{u.email.substring(0,5)}</a>
                  </div> 
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer flex justspacebetween p20 aligncenter">
          <ul className="flex aligncenter">
            {permission.write && <>
                <li className="m-r-25">
                  <button className="btn btn-primary bg-highlight text-white" 
                    onClick={()=>props.addNotifiableRole(role)}>Reports to</button>
                </li>
                <li className="m-r-25">
                  <button className="btn btn-primary bg-highlight text-white" 
                    onClick={e=>props.setEdit(e, role)}>Edit</button>
                </li>
                <li className="m-r-25">
                  <button className="btn btn-primary bg-highlight text-white" 
                    onClick={e=>{props.selectRole(role);props.setDelete(e, role)}}>Delete</button>
                </li>
              </>
            }  
            <li className="m-r-25">
              <LinkFontAwesomeIcon id="organization_members" tooltip="Users" 
                link={`/${role.role}/members/${organizationId}/false?role_id=${role.record_id}`} 
                css="fas fa-users white" count={role.members_count}>
              </LinkFontAwesomeIcon>
            </li>
          </ul>
        </div>
      </div>
    </Fragment>
  )

};

export default Role;