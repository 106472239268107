import React, {Fragment, useState, useContext, useEffect} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../Common/MenuItem"
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../Common/SortDirection'
import Cs from "../../services/CommonService"
import OrganizationPartnershipService from "./OrganizationPartnershipService"
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"

let requestParams ={}
const OrganizationPartnershipTable = (props) => {
  useStyle('/css/sidebar-theme.css', true);

  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const currentOrg = currentUser?.current_organization

  const [isLoading, setIsLoading] = useState(true)
  const [list, setList] = useState([])

  useEffect(() => {
    requestParams = {
      page:1,
      per_page:100,
      data:{
        organization_type: currentOrg.data?.organization
      }
    } 
    getAll()
  }, [])

  const getAll = () =>{
    OrganizationPartnershipService.getPartnershipOrganizations(requestParams).then((res)=>{
      if(res.status == 200){
        setList(res.data.organizations)
      }
    })
  }

  const {sort} = useSort(requestParams, getAll)

  const updateStatus = (org, key) =>{
    let req = {
      id: org.id,
      [key]: !org[key]
    }
    OrganizationPartnershipService.update(req).then((res) => {
      if (res.status == 204) {
        org[key] = !org[key]
        setList([...list])
      }
    })
  }

  const tableData = list.map((o, k) => {
    let ds = o.data_source || {}
    let data = o.data || {}

    return(
      <TR key={k}>
        <td className="text-gray">
          {o.record_id}
        </td>
        <td className="fw500">
          {data.name}
        </td>
        <td>
          {Cs.formatUpdateDate(o.partnership_date)}
        </td>
        <td>
          {currentOrg.data.organization == 5 ?
            <span className={o.is_approved_by_provider?'fas fa-check-circle':'far fa-circle'} 
              onClick={()=> updateStatus(o, 'is_approved_by_provider')}/>
            :
            <span className={o.is_approved_by_provider?'fas fa-check-circle':'far fa-circle'}/>
          }
        </td>
        <td>
          {currentOrg.data.organization != 5 ? 
            <span className={o.is_approved_by_consumer?'fas fa-check-circle':'far fa-circle'} 
              onClick={()=> updateStatus(o, 'is_approved_by_consumer')}/>
            :
            <span className={o.is_approved_by_consumer?'fas fa-check-circle':'far fa-circle'}/>
          }
        </td>
        <td>
          {o.data_source?.organization?.label}
        </td>
        <td className="font-16 d-flex">
        </td>
      </TR>
    )
  })

  return (
    <Fragment>
      <div id="ct">
      <div className="app_content">
        <div className="app-header flex justspacebetween m-t-15">
          <div className="flex coldir">
            <div className="app-title m-r-20">Partners</div>
            <div className="font-15">
              List of Partners - {list.length}
            </div>
          </div>
        </div>
        
        <div className="app-content-section m-t-30">
          <Table className="shadow-small">
            <THead>
              <TR>
                <TH onClick={e=>sort('record_id')}>
                  ID 
                  <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('data.name')}>
                  Name 
                  <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('created_at')}>
                  Date
                  <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('is_approved_by_provider')}>
                  {currentOrg.data.organization == 5 ? 'Approve':'Training Institute Approved'} 
                  <SortDirection sort_column={requestParams.is_approved_by_provider} column="is_provider_org_approved" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('is_approved_by_consumer')}>
                  {currentOrg.data.organization == 5 ? 'District Approved':'Approve'} 
                  <SortDirection sort_column={requestParams.is_approved_by_consumer} column="is_consumer_org_approved" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('is_active')}>
                  Type
                  <SortDirection sort_column={requestParams.is_active} column="is_active" reverse={requestParams.reverse}/>
                </TH>
                <TH>
                  Reports
                </TH>
              </TR>
            </THead>
            <TBody>
              {tableData}
            </TBody>
          </Table>
        </div>
      </div>
      </div>
    </Fragment>
  )

}

export default OrganizationPartnershipTable;