import React, { useState, Fragment, useContext } from "react"
import moment from "moment"
import { useHistory, Link } from "react-router-dom"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import NutritionCareAlertService from "./NutritionCareAlertService"
import ConfirmMenu from "../Modals/ConfirmMenu"
import useModal from "../../hooks/useModal"
import Cs from "../../services/CommonService"
import PlannerService from "../HealthJourney/Health/Planners/PlannerService"
import LocaleList from "../Locale/LocaleMenu"
import GenericModal from "../Modals/GenericModal"
import CheckAccess from "../HealthJourney/Roles/CheckAccess"
import arrayMove from "../Common/arrayMove"
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc'
import {PlannerVideoPlayer} from "../Common/VideoPlayer"

const SortableContainer = sortableContainer(({children}) => {
  return <ul>{children}</ul>
})

const DragHandle = sortableHandle(() => 
  <span className="fas fa-arrows-alt step-drag-handle pull-left m-5"/>
)

let selectedAlert = null
let selectedPlanner = null
const StepAlertList = (props) => {
  let history = useHistory()
  const {alerts, setAlerts} = props

  const {state: { screen, user: currentUser }} = useContext(AuthContext)
  
  const [loading, setLoading] = useState(false)
  const [activeIndex, setActiveIndex] = useState()

  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal} = useModal()
  const {isOpen: showLocaleModal, toggleModal: toggleLocaleModal} = useModal()
  const { isOpen: isVideoPlayerOpen, toggleModal: toggleVideoPlayer } = useModal()

  const getPlanners = (i, alert) =>{
    const req = {
      ids: alert.data?.videos_suggested_array?.map((v)=> v.planner_fk_id)?.join(','),
      context: 'nutrition_care_alert'
    }

    if(req.ids.length > 0 && !alert.planners){
      PlannerService.getAll(req).then((res)=>{
        alert.planners = Cs.groupBy(res.data.planners, 'id')
        setActiveIndex(i == activeIndex ? null : i)
      })
    }else{
      setActiveIndex(i == activeIndex ? null : i)
    }
  }

  const deleteStep = (e) => {
    e.preventDefault()
    NutritionCareAlertService.delete(selectedAlert.id).then((res) => {
      if (res.status == 204) {
        setAlerts(
          alerts.filter((i) => i.id !== selectedAlert.id)
        )
      }
    })
    toggleDeleteModal(e)
  }

  const confirmDelete = (e, alert) => {
    selectedAlert = alert
    toggleDeleteModal(e)
  }

  const updateStep = (selectedAlert) => {
    NutritionCareAlertService.update(selectedAlert).then((res) => {
      if (res.status == 204) {}
    })
  }

  const translateAndClone = (e, alert) => {
    selectedAlert = Cs.deepCopy(alert) //Deep copy
    selectedAlert.id = null
    selectedAlert.data_source = null
    selectedAlert.record_id = null 
    selectedAlert.clone_id = alert.id
    toggleLocaleModal(e)
  } 

  const onTranslateComplete = (translatedData) => {
    toggleLocaleModal()
    translatedData.created_by = currentUser.id
    translatedData.updated_by = currentUser.id
    NutritionCareAlertService.create(translatedData).then((res) => {
      if(res.status==201){
        setAlerts([res.data.nutrition_care_alert, ...alerts])
      }
    }).catch(function(res){
       
    })
  } 
  
  const openVideo = (item) =>{
    if(item.planner_fk_id){
      selectedPlanner = item
      toggleVideoPlayer()
    }
  }

  return (
    <Fragment>
      <div>
        <h4 className="fw700 m-b-5">Items</h4>
        {alerts.length > 0 && alerts.map((s, i) => (
          <Fragment key={i}>
            <Alert s={s} getPlanners={getPlanners} idx={i} 
            currentUser={currentUser}
            confirmDelete={confirmDelete}
            translateAndClone={translateAndClone}
            activeIndex={activeIndex}
            updateStep={updateStep}
            openVideo={openVideo}
            />
          </Fragment>
        ))}
      </div>
      
      <ConfirmMenu isOpen={isDeleteModalOpen} 
        toggleModal={toggleDeleteModal}
        success={deleteStep}/>

      {showLocaleModal && <GenericModal component={LocaleList} 
        title="Langauage" isOpen={showLocaleModal} 
        toggleModal={toggleLocaleModal} 
        onTranslateComplete={onTranslateComplete} 
        selectedItem={selectedAlert}/>
      }

      {isVideoPlayerOpen && 
        <PlannerVideoPlayer
          toggleModal={toggleVideoPlayer}
          url={selectedPlanner.url}
          plannerId={selectedPlanner.id} />
      }
    </Fragment>
  )
}

const Menu = ({stepAlert, currentUser, confirmDelete, translateAndClone}) => {
    const isEdit = CheckAccess.checkLocaleTranslateAccess(currentUser, stepAlert, 'interventions', 'write')
    return(
      <span className="dropup pull-right">
        <a><i className="fas fa-bars font-18"/></a>
        <div className="dropup-content w-100p">
          {isEdit &&
            <Link to={`/nutrition_care/alert/${stepAlert.nutrition_care_id}/${stepAlert.id}?edit=${isEdit}`}>
              <i className="far fa-edit"/> Edit
            </Link>
          }
          {currentUser.id == stepAlert.created_by &&
            <a href="#" onClick={(e) => confirmDelete(e, stepAlert)}>
              <i className="far fa-trash-alt"/> Delete
            </a>
          }
          {stepAlert?.data_source?.language?.sid == 'english' &&
            <a href="#" onClick={(e) => translateAndClone(e, stepAlert)}>
              <i className="fas fa-globe"/> Language clone
            </a>
          }
        </div>
      </span>
    )
}

const Alert = ({s, openVideo, getPlanners, updateStep, idx, activeIndex, currentUser, confirmDelete, translateAndClone}) =>{
  return (
    <>
      <div className="card-item active m-b-15 m-t-15" onClick={() => getPlanners(idx, s)}>
        <p className="m-b-5">
          {s.data.title}
        </p>
        <Menu stepAlert={s} currentUser={currentUser} 
        confirmDelete={confirmDelete} 
        translateAndClone={translateAndClone}/>
        <ul className="flex aligncenter m-b-5 card-bullets">
          {/*<li class="m-r-10">
            <span class="eye-icon">
              <img src="./images/eye=icon-gray.png" className="m-r-10" alt="" />
            </span>
            0
          </li>*/}
          <li className="m-r-10">{Cs.formatUpdateDate(s.updated_at)}</li>
          <li className="m-r-10">{s.author_name}</li>
          <li className="m-r-10">{s.data_source?.language?.label}</li>
        </ul>
      </div>
      
      {activeIndex == idx && 
        <AlertCard stepAlert={s} data={s.data} updateStep={updateStep} openVideo={openVideo}/>
      }
    </>
  )
}

const AlertCard = ({stepAlert, data, updateStep, openVideo}) => {
  const [suggestedPlanners, setSuggestedPlanners] = useState(stepAlert.data.videos_suggested_array)
  
  const onSortEnd = ({oldIndex, newIndex}) => {
    stepAlert.data.videos_suggested_array = arrayMove(suggestedPlanners, oldIndex, newIndex)
    setSuggestedPlanners(stepAlert.data.videos_suggested_array)
    updateStep(stepAlert)
  }

  return (
    <div className="p-5 br-btm-grey">
      <a href={data.url} target="_blank" className="underline">{data.url}</a>
      <div className="m-t-5 rich-text" dangerouslySetInnerHTML={{ __html: data.details}}/>
      <SortableContainer onSortEnd={onSortEnd} distance={1} lockAxis="y">
        <PlannerLinks openVideo={openVideo}
          suggestedPlanners={suggestedPlanners} 
          planners={stepAlert.planners}/>
      </SortableContainer>
    </div>
  )
}

const PlannerLinks = ({openVideo, suggestedPlanners=[], planners={}}) => suggestedPlanners.map((p, i)=>{
  const planner = planners?.[p.planner_fk_id]?.[0] || {}
  p.url = planner.url
  return(
    <SortableItem key={`item-${i}`} index={i} planner={p} openVideo={openVideo}/>
  )  
})

const SortableItem = sortableElement(({planner, openVideo}) => 
  <li onClick={()=>openVideo(planner)} className="badge bg-black-light-1 text-white d-block m-b-10">
    <i className="fas fa-external-link-alt m-r-5" /> {planner.name} 
  </li>
)

export default StepAlertList;