import React, {Fragment, useState} from "react"
import {Link} from "react-router-dom"
import Cs from '../../services/CommonService'
import env from "../../env"
import {FormFieldTable, RenderData} from "../HealthJourney/UserRoles/NotificationPill"
import FormTemplateId from "../../constants/FormTemplateList"
import { CTTooltip } from "../Common/CueTooltip"
import { ChatGrayIcon, HamburgerMenuIcon, MaximizeIcon, EyeGrayIcon } from "../Common/ImageIcons"

const ProgramCard = (props) => {
  let {program, formFieldsList} = props
  let {data, data_source} = program;
  let formFields = formFieldsList[FormTemplateId.TrainingProgramForm] || []

  const GenericContent = () =>(
    <Fragment>
      <div className="bg-white border-rounded-10 card-shadow m-b-15">
        <div className="card-title card-title-gray flex p-b-15 p20 coldir"> 
          <h4 className="fw700 f18 card-heading flex justspacebetween text-left">
            <Link to={`/list/training/sessions/${program.id}`}>
              {data.host} 
            </Link>
          </h4>
          <div className="f14 m-t-10 fw500">{program.user?.name}-{Cs.formatUpdateDate(program.created_at)}</div>
        </div> 

        <div className="card-content p20">
          <div className="b-tab-table active scroll-auto ht-400">
            <FormFieldTable formFields={formFields} data={data} dataSource={data_source}/>
          </div>
        </div>

        <div className="card-footer flex justspacebetween p20 aligncenter">
          <ul className="flex aligncenter">
            <li className="m-r-25">
              <ChatGrayIcon onSelect={(e) => props.addNotes(e, program)}/>
            </li>
            <li className="m-r-25">
              <Link to={`/assessment/form?training_program_id=${program.id}`}>
                <i className="fas fa-plus-circle font-22 text-muted" data-tip="Add Sessions"></i>
              </Link>
            </li>
            <li className="m-r-25">
              <EyeGrayIcon count={program?.training_sessions.length} tooltip="View Sessions"/>
            </li>
          </ul>
          <HamburgerMenuIcon onSelect={(e)=>props.addMenu(program)}/>
        </div>
      </div>
      <CTTooltip />
    </Fragment>
  )

  if(!data)return null;

  return (<GenericContent/>)
    
}

export default ProgramCard;