import React, {useState, Fragment, useContext, useEffect, useMemo} from "react"
import querystringify from "querystringify"
import {Link} from "react-router-dom"
import ReactDOM from 'react-dom'
import Pagination from '../Pagination'
import useStyle from '../../hooks/useStyle'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from "../../services/CommonService"
import ReactTooltip from "react-tooltip"
import {OptionsObjList, Radio} from "../Common/FormInput"
import {Locales} from "../../constants/DataSourceList"
import importScript from '../../hooks/importScript'
import {InputTranslate} from "./InputTranslate"
import CheckAccess from '../HealthJourney/Roles/CheckAccess'

let selectedLocale;
const AddLocale = ({localeType, translateFieldKey, onCancel, labelObj, updateFormLocale, locale:defaultLocale}) => {
  useStyle('add_role_dialog')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [isLoading, setLoading] = useState(false)

  let defaultLangauge = null
  let localeData = null
  let englishLabel = null
  if(localeType === 'data_source_list'){
    englishLabel = labelObj.data.label
    localeData = labelObj.locale_data
  }else if(['form_field', 'form_tab'].indexOf(localeType) > -1){
    englishLabel = labelObj[translateFieldKey] //.label
    localeData = labelObj.locale?.[translateFieldKey]
  }else if(localeType === 'form_title'){
    englishLabel = labelObj.post.title
    localeData = labelObj.locale_title
  }else if(localeType === 'form_details'){
    englishLabel = labelObj.post.details
    localeData = labelObj.locale_details
  }else if(localeType === 'data_source_data'){
    englishLabel = labelObj.label
    localeData = labelObj.locale_data
  }

  const permittedLocales = useMemo(() => {
    try{
      if(CheckAccess.checkIsAdmin(currentUser)){
        return Locales
      }

      return Locales.filter((l) => currentUser.current_member.permissions.locale_translate.indexOf(l.sid)>-1)
    }catch(e){
      return []
    }
  }, [currentUser])

  if(defaultLocale){
    defaultLangauge = defaultLocale
  }else if(permittedLocales.length === 1){
    defaultLangauge = permittedLocales[0].sid
  }else{
    defaultLangauge = currentUser.current_locale
  }

  const editLocale = (d) =>{
    if(localeType=='data_source_list'){
      updateDataSourceList(labelObj.id, d)
    }else if(['form_field', 'form_tab'].indexOf(localeType) > -1){
      let req = {
        id: labelObj.formTemplateId || labelObj.id,
        client_id: labelObj.client_id,
        index:labelObj.index,
        language: d.locale,
        value: d.value,
        translated_field_key: translateFieldKey,
        attribute: localeType,
      }
      updateForm(req)
    }else if(localeType === 'form_title'){
      const req = {
        id: labelObj.formTemplateId || labelObj.id,
        attribute: 'form_title',
        language: d.locale,
        value: d.value
      }
      updateForm(req)
    }else if(localeType === 'form_details'){
      const req = {
        id: labelObj.formTemplateId || labelObj.id,
        attribute: 'form_details',
        language: d.locale,
        value: d.value
      }
      updateForm(req)
    }else if(localeType === 'data_source_data'){
      updateDataSourceList(labelObj.pk_id, d)
    }
  }

  const updateDataSourceList = (id, d) =>{
    setLoading(true)
    let req = {
      id: id,
      locale_data: labelObj.locale_data || {}
    }
    req.locale_data[d.locale] = {label:d.value}
    Cs.updateDataSourceList(id, req).then((res)=>{
      updateFormLocale(d.locale, d.value)
      setLoading(false)
      onCancel()
    })
  }

  const updateForm = (req) =>{
    setLoading(true)
    Cs.updateFormFieldLocale(req).then((res)=>{
      updateFormLocale(req.language, req.value)
      setLoading(true)
      onCancel()
    })
  }

  return(
    <Fragment>
      <div className="ml-container m-t-10">
        {isLoading ? <div className="spinner"/> : null}
        <LocaleForm onCancel={onCancel} editLocale={editLocale} 
        localeData={localeData || {}} englishLabel={englishLabel} 
        defaultLangauge={defaultLangauge} localeType={localeType}
        permittedLocales={permittedLocales} isLoading={isLoading}/>
      </div>
    </Fragment>
  )
}

const LocaleForm = ({isLoading, onCancel, editLocale, localeData, englishLabel, defaultLangauge, localeType, permittedLocales}) => {
  const [loading, setLoading] = useState(false)
  const [translatedText, setTranslatedText] = useState('')
  //const [locale, setLocale] = useState(localeData || {})
  let [data, setData] = useState({
    locale: defaultLangauge, 
    value: localeType.includes('data_source')?localeData[defaultLangauge]?.label:localeData[defaultLangauge]
  })

  useEffect(()=>{
    selectedLocale = Locales.find((l)=> defaultLangauge === l.sid)
  }, [])

  const googleTranslate = (iso_code) =>{
    setLoading(true)
    let req = {
      "source": "en",
      "q": [englishLabel],
      "target": iso_code.target
    }
    
    Cs.googleTranslate(req).then((res)=>{
      console.log(res)
      if(res.data){
        setLoading(false)
        setTranslatedText(res.data.translations[0].translatedText)
        setData({
          ...data, 
          value:res.data.translations[0].translatedText 
        })
      }else{
        setLoading(false)
      }
    })
  } 

  const handleLocale = (e) =>{
    data.locale = e.target.value
    if(localeType.includes('data_source')){
      data.value = (localeData[e.target.value]?.label || '')
    }else{
      data.value = (localeData[e.target.value] || '')
    }
    setData({...data})
    selectedLocale = Locales[e.target.selectedIndex-1]
    if(data.value.length === 0 && selectedLocale.iso_code){
      googleTranslate({"target": selectedLocale.iso_code})
    }
  }

  const handleInput = (e) =>{
    setData({...data, [e.target.name]:e.target.value})
  }

  return(
    <form>
      {loading && <div className="spinner"/>}
      <div className="ml-section">
        <div className="col-xs-12 p-10 bg-ltint br-btm-grey ml-margin-bottom">
          <label className="text-cap"><b>Current Text</b></label>
          <p className="font-15">{englishLabel}</p>
        </div>
       
        <div className="col-xs-12 ml-margin-bottom">
          <label className="text-cap"><b>Pick Your Language</b></label>
          <select name="locale" id="locale" className="form-control" onChange={e=>handleLocale(e)} 
            defaultValue={data.locale}>
            <option value="" disabled selected>Select an item from list below</option>
            <OptionsObjList list={permittedLocales} id_key="sid" label_key="label"/>
          </select>
        </div>
        <div className="col-xs-12 ml-margin-bottom">
          <label className="text-cap"><b>Suggested Translation</b></label>
          <p className="font-15">{translatedText}</p>
        </div>
        <div className="col-xs-12 ml-margin-bottom">
          {selectedLocale?.value && 
            <InputTranslate isRichText={false} 
              localeId={selectedLocale.value} 
              inputFieldId="value"
              adjustTranslatePosition={false}
              onInsertTextArea={handleInput}
            />
          }
          {(data.locale) && 
            <Fragment>
              <label className="text-cap">
                <b>Add Translated Text</b>
                <i className="fas fa-globe m-l-5"/>
              </label>
              <textarea name="value" id="value" 
                className="form-control"
                onChange={e=>handleInput(e)} value={data.value}/>
            </Fragment>
          }
        </div> 
        {isLoading ? null : 
          <div className="col-xs-12 text-center ml-margin-bottom">
            <button type="button"
              className="dropbtn bg-submit p-5"
              onClick={()=>editLocale(data)}>
                <b>{data.id?'Update':'Create'}</b>
            </button>
            <button type="button"
              className="dropbtn bg-cancel p-5"
              onClick={()=>onCancel(false)}>
                <b>Cancel</b>
            </button>
          </div>
        }
      </div>
    </form>  
  )
}

export default AddLocale;