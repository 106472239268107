import React, { Fragment, useState, useEffect, PureComponent} from "react"
import { Link } from "react-router-dom"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import DataPoint from "../../../../constants/ChildGrowthDataPoints"
import ActivityService from "../../../../components/HealthJourney/MotherInfantJourney/Activities/ActivityService"
import Title from "../../GenericPortalPage/Title"
import CommentsModal from "../../../Modals/CommentsModal"
import useModal from "../../../../hooks/useModal"
import querystringify from "querystringify"
import GrowthAlert from "../Child/GrowthAlert"
import GenericModal from "../../../Modals/GenericModal"
import { OptionsObjList, DateTimePicker } from "../../../Common/FormInput"
import Tabs, { TabPane } from "rc-tabs"
import NutritionCareNotification from '../../../NutritionCares/NutritionCareNotification'
import {WHOMetricsList, AgeDataFilterList, WHOGraphList} from "../../../../constants/DataSourceList"
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

let req = {}
let options = {}
let selectedActivity = {}

const WHOGrowthChart = (props) => {
  let params = props.match.params
  let { project, child, stateGrowthData } = props.location.state || {}

  let queryParam = querystringify.parse(props.location.search)

  const [growthData, setGrowthData] = useState(stateGrowthData || {})
  const [loading, setLoading] = useState(true)
  const [chartType, setChartType] = useState()
  const [filter, setFilter] = useState({})
  const [activeTab, setActiveTab] = useState('Weight-for-age')

  const { isOpen:isAlertOpen, toggleModal:toggleAlertModal } = useModal()
  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal()

  const genderKey = child.data.gender == 2?'Boys':'Girls'
  let childSeriesIndex = 1

  let gridClass = "col-xs-12 col-sm-6"

  if (params.index) {
    gridClass = "col-xs-12";
    WHOGraphList = [WHOGraphList[params.index]];
    //childSeriesIndex = 8;
  }

  useEffect(() => {
    setFilter({...filter, report_type:'Weight-for-age'})
  }, [])

  useEffect(() => {
    if(filter.report_type)
      getGrowthData(filter.report_type)
  }, [filter])

  const getGrowthData = () =>{
    setLoading(true)
    const req = {
      journey_profile_id: params.health_id,
      report_type: filter.report_type,
      age_period_type: filter.age_period_type,
      age_period: filter.age_period
    }
    ActivityService.report(req).then((res) => {
      setActiveTab(filter.report_type)
      setGrowthData(res.data)
      setLoading(false)
    })
  }

  const openChart = (i) => {
    props.history.push(
      `/health/chart/${params.health_id}/${i}?label=${queryParam.label}`,
      {stateGrowthData:growthData, project:project, child:child}
    )
  }

  const onChangeFilter = (e) =>{
    const f = AgeDataFilterList[e.target.selectedIndex]
    setFilter({ 
      ...filter, 
      age_period_type: f.type,
      age_period: f.value 
    })
  }

  const Header = ({ data, index }) => {
    const cardStyle = {
      height: "40px",
      textAlign: "center",
      backgroundColor: data.bg_color || "#607D8B",
    }

    return (
      <div className="white card-header" style={cardStyle}>
        <div className="font-18 p-7">
          {data.label}
          <span className="fas fa-expand pull-right white" onClick={(e) => openChart(index)}/>
        </div>
      </div>
    )
  }

  const onDateChanged = (name, value, date) => {
    setFilter({ ...filter, [name]: value })
  }

  const GraphContainer = ({graphs}) => graphs && graphs.map((w, i) => {
    let colors = ["#00a86b"]
    let series = []

    if(w.is_zscore_graph){
      colors = ["#EE220C", "#FF42A1", "#F27200", "#00a86b", "#F27200", "#FF42A1", "#EE220C", "#222"]

      series = [
        {
          name: 'sd4neg',
          type: 'line',
          data: growthData[w.who_zscore_key].sd4neg,
        },
        {
          name: 'sd3neg',
          type: 'line',
          data: growthData[w.who_zscore_key].sd3neg,
        },
        {
          name: 'sd2neg',
          type: 'line',
          data: growthData[w.who_zscore_key].sd2neg,
        },
        {
          name: 'sd0',
          type: 'line',
          data: growthData[w.who_zscore_key].sd0,
        },
        {
          name: 'sd2',
          type: 'line',
          data: growthData[w.who_zscore_key].sd2,
        },
        {
          name: 'sd3',
          type: 'line',
          data: growthData[w.who_zscore_key].sd3,
        },
        {
          name: 'sd4',
          type: 'line',
          data: growthData[w.who_zscore_key].sd4,
        },
        {
          name: w.y_axis_label,
          type: 'line',
          data: growthData[w.data_key],
        },
      ]
    }else{  
      series.push(
        {
          name: "Median",
          type: "line",
          data: growthData[w.who_key].median,
        }
      )

      if (growthData[w.who_key]["3rd"]) {
        series.push({
          name: "3rd",
          type: "line",
          data: growthData[w.who_key]["3rd"],
        });
        colors.push("#fa1818");
      }
        
      if (growthData[w.who_key]["15th"]) {
        series.push({
          name: "15th",
          type: "line",
          data: growthData[w.who_key]["15th"],
        });
        colors.push("#fac218");
      }

      if (growthData[w.who_key]["85th"]) {
        series.push({
          name: "85th",
          type: "line",
          data: growthData[w.who_key]["85th"],
        });
        colors.push("#fac218");
      }

      if (growthData[w.who_key]["97th"]) {
        series.push({
          name: "97th",
          type: "line",
          data: growthData[w.who_key]["97th"],
        });
        colors.push("#fa1818");
      }

      if (growthData[w.data_key]) {
        series.push({
          name: "Baby",
          type: "line",
          data: growthData[w.data_key],
        });
        colors.push("#222");
      }

    }

    return (
      <div className={`${gridClass} p-20 clearfix`} key={i}>
        <Header data={w} index={i} />
        <ChartSeries series={series}/>                        
      </div>
    )
  })

  const tabsView = WHOGraphList.map((g, i)=>{
    return (
      <TabPane tab={g.tab} key={g.tab}> 
        {(!loading && activeTab == g.tab) ? <GraphContainer graphs={g.graphs}/>:<div className="spinner"/>}
      </TabPane>
    )
  })

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-12 col-sm-8">
          <div className="bold-500 font-16">{project?.data?.mother_name}</div>
          <div className="bold-500 font-16">{child?.data?.baby_name}</div>
          <div className="bold-500">
            <a className="underline"
              href="https://www.who.int/tools/child-growth-standards/standards/weight-for-age" 
              target="_blank">
                Reference
             </a>
          </div>
        </div>

        <div className="col-xs-12 col-sm-4">
          <Link to={{pathname: `/infant/portal/${params.health_id}`, state: project}}
            data-tip="Back to Journey Home"
            className="bg-highlight round-btn float-right m-r-5 m-t-4 m-b-10">
              <i className="fas fa-home"/>
          </Link>

          {activeTab != 'Weight-for-length' &&
            <select className="form-control input-sm" 
              onChange={e=>onChangeFilter(e)} placeholder="Filter" defaultValue={filter}>
                <OptionsObjList list={AgeDataFilterList} id_key='value' label_key='label'/>
            </select>
          }
        </div>
      </div>

      <div className="page-content row">
        <Tabs defaultActiveKey={activeTab} onChange={(key)=>setFilter({...filter, report_type:key})} moreIcon="+">
          {tabsView}
        </Tabs>
      </div>

      {isAlertOpen && 
        <GenericModal title="Alert" HeaderColor="bg-highlight"
          component={GrowthAlert} toggleModal={toggleAlertModal}
          selectedActivity={selectedActivity}/>
      }

      {isNutritionCareOpen && 
        <GenericModal component={NutritionCareNotification} 
        toggleModal={toggleNutritionCareModal} isOpen={isNutritionCareOpen}
        itemType="infant.activity" itemUuid={selectedActivity.id}
        closeIco="fas fa-chevron-down"/>
      }
    </Fragment>
  )
}

const ChartSeries = ({series}) =>{
  return (
    <>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="x" type="number" />
          <YAxis dataKey="y" type="number"/>
          <Tooltip />
          <Legend />
          {series.map((s) => (
            <Line type="monotone" dataKey="y" data={s.data} name={s.name} key={s.name} />
          ))}
        </LineChart>
      </ResponsiveContainer>
    </>
  )
}
  
export default WHOGrowthChart;

/*
const ChartSeries = ({id, series}) =>{
  
  useEffect(()=>{
    new window.Chart(id, {
      type: "line",
      data: {
        datasets: series
      },
      options: {
        options: {
          parsing: {
            xAxisKey: 'x',
            yAxisKey: 'y'
          }
        }
      }
    })
  }, [])

  return (
    <>
      <canvas id={id}></canvas>
    </>
  )
}
*/