import React,{Fragment} from "react";
import {Image, ImageList} from "../../Common/Image"
import FormHelpers from '../../FormBuilder/FormHelpers'

let PermissionList = ({list, obj}) => list && Object.keys(list).length>0 ? Object.keys(list).map((n, i) =>
  <span className="text-cap round-tab bg-yellow-white" key={i}>{obj[n]}&emsp;</span>
): <span>None</span>

let NotificationList = ({list, obj}) => list && list.length>0 ? list.map((n, i) =>
  <span key={i} className="text-cap round-tab bg-yellow-white">{obj[n]}&emsp;</span>
): <span>None</span>

let PillDsList = ({list, dataKey, styleName}) => list && list.length>0 ? list.map((n, i) =>
  <Fragment key={i}>
    <div className={styleName} style={{backgroundColor:n.data.bg_color}}>{n.data[dataKey] || '-'}&emsp;</div>
  </Fragment>
): <span>None</span>

let PillDsField = ({field, dataKey, styleName}) => field ?
  <div style={{display: 'inherit'}} className={styleName}>{field[dataKey] && field[dataKey]}</div>: <div>None</div>

let PillTagList = ({list, styleName}) => list && list.length>0 ? list.map((n, i) =>
  <div key={i} className={styleName}>{n}&emsp;</div>
): <span>None</span>

const DraftBadge = ({isDraft, styleName}) => isDraft? <span className={`badge-2 white bg-lgrey ${styleName?styleName:''}`}>Draft</span>:null;

let PillList = ({list, dataKey, style, onSelect}) => list && list.length>0 ? list.map((n, i) =>{
  if(n==null)return null;
  
  const props = {
    className:style?style:'text-cap round-tab bg-highlight white',
  }

  if(onSelect){
    props.onClick = () => onSelect(n)
  }

  return(
    <span {...props} key={i}>
      {n[dataKey]}&emsp;
    </span>
  )  
}):null

let GrowthField = ({data, note}) => {
  let className
   if(note === "above_median"){
     className = "c-abmedian"
   }else if(note === "below_median"){
     className = "c-bemedian"
   }else{
     className ="c-median"
   }
  return <div style={{display: 'inherit'}} className={className}>{data && data || '-'}</div>
}

let GrowthSpanField = ({data, note, unit}) => {
  let className
   if(note === "above_median"){
     className = "bg-submit"
   }else if(note === "below_median"){
     className = "bg-cancel"
   }else{
     className ="bg-safforn"
   }
  return <span className={className + " pill p-5 white"}>{data && data + " " + unit}</span>
}

let RenderData = ({data = {}, ds = {}, dataKey, onDataItemSelect}) =>{

  let d = ds?.[dataKey]   
  if(d){
    if(Array.isArray(d)){
      return <PillDsList list={d} dataKey='label' styleName="badge bg-highlight white m-r-5 m-b-5"/>
    }else{
      return(
        <div>{d.bg_color?
            <span className="pill white" style={{backgroundColor:d.bg_color}}>{d.label}</span>
            :d.label
          }
        </div>
      )  
    }
  }else if(Array.isArray(data[dataKey+'_array'])){
    return (
      <div className="flex coldir">
        <PillList list={data[dataKey+'_array']} 
          onSelect={onDataItemSelect}
          dataKey='name' style="badge white bg-highlight m-r-5 m-b-5"/>
      </div>
    )
  }else if(data[dataKey]){
    return(
      <div>{data[dataKey]}</div> 
    )
  }

  return null 
}

const FormFieldTable = ({formFields = [], data = {}, dataSource = {}, onDataItemSelect}) => formFields.map((f, i)=>{
  if(FormHelpers.checkFieldConditionality(f, data)){
    if(f.component == 'richtext'){
      return(
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{f.label}</h5>
          <div dangerouslySetInnerHTML={{ __html: data?.[f.client_id]}}/>
        </div>
      )
    }else if('imageupload'.includes(f.component)){
      return(
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{f.label}</h5>
          <Image image={data?.[f.client_id]} styleName="w-70p"/>
        </div>
      )
    }else if('multi_file_upload'.includes(f.component)){
      return(
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{f.label}</h5>
          <ImageList list={data?.[f.client_id]} styleName="w-70p"/>
        </div>
      )
    }else if(f.component === 'rich_image_map'){
      return null
    }else if(f.component === 'lottie_svg'){
      return null
    }

    return( 
      <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{f.label}</h5>
          <RenderData data={data} ds={dataSource} 
          dataKey={f.client_id} onDataItemSelect={onDataItemSelect}/>
      </div>
    )
  }else{
    return null
  }
})

const TableColumnData = ({formFields = [], data = {}, dataSource = {}}) => formFields.map((f, i)=>{
  if(f.component == 'richtext'){
    return(
      <td className="text-center" key={i}>
        <div className="pre-wrap mxh-100 scroll-x" dangerouslySetInnerHTML={{ __html: data?.[f.client_id]}}/>
      </td>
    )
  }else if('imageupload'.includes(f.component)){
    return(
      <td className="text-center" key={i}>
        <Image image={data?.[f.client_id]} styleName="w-70p"/>
      </td>
    )
  }else if('multi_file_upload'.includes(f.component)){
    return(
      <td className="text-center" key={i}>
        <ImageList list={data?.[f.client_id]} styleName="w-70p"/>
      </td>
    )
  }else if(f.component == 'rich_image_map'){
    return null
  }else if(f.component == 'lottie_svg'){
    return null
  }else if(f.component == 'rating'){
    return(
      <td className="text-center" key={i}>
        {data?.['sum_of_'+f.client_id]}
      </td>
    )
  }

  return( 
    <td className="text-center" key={i}>
      <RenderData data={data} ds={dataSource} dataKey={f.client_id}/>
    </td>
  )
})

const FormFieldCard = ({formFields = [], data = {}, dataSource = {}, onDataItemSelect}) => formFields.map((f, i)=>{
  if(FormHelpers.checkFieldConditionality(f, data)){
    if(f.component == 'richtext'){
      return(
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{f.label}</h5>
          <p dangerouslySetInnerHTML={{ __html: data?.[f.client_id]}}></p>
        </div>
      )
    }else if('imageupload'.includes(f.component)){
      return(
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{f.label}</h5>
          <Image image={data?.[f.client_id]} styleName="w-70p"/>
        </div>
      )
    }else if('multi_file_upload'.includes(f.component)){
      return(
        <div key={i} className="border-dashed-sm p-b-15 m-b-15">
          <h5 className="fw500 m-b-5">{f.label}</h5>
          <ImageList list={data?.[f.client_id]} styleName="w-70p"/>
        </div>
      )
    }else if(f.component == 'rich_image_map'){
      return null
    }else if(f.component == 'lottie_svg'){
      return null
    }

    return( 
      <div key={i} className="border-dashed-sm p-b-15 m-b-15">
        <h5 className="fw500 m-b-5">{f.label}</h5>
        <RenderData data={data} ds={dataSource} dataKey={f.client_id} onDataItemSelect={onDataItemSelect}/>
      </div>
    )
  }else{
    return null
  }
})
export {PillList, DraftBadge, FormFieldCard, TableColumnData, PillTagList, PermissionList, NotificationList, PillDsList, PillDsField, GrowthField, GrowthSpanField, FormFieldTable};