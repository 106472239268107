import React, {useState, Fragment, useContext, useEffect} from 'react'
import FilterSlider from "../../Common/FilterSlider"
import RoleAndUserFilter from '../../NotifiableRoles/RoleAndUserFilter'
import {CheckBox} from "../../Common/FormInput"
import useModal from '../../../hooks/useModal'
import SortDirection from '../../../components/Common/SortDirection'
import useSort from '../../../hooks/useSorting'
import RoleService from '../../HealthJourney/Roles/RoleService'
import FacilityService from '../../HealthJourney/MotherInfantJourney/MedicalCareFacility/FacilityService'

const Filters = ({organizationId, filter, setFilter, currentUser}) =>{
  
  const [roles, setRoles] = useState([])
  const [medicalFacilities, setMedicalFacilities] = useState([])
  const [talukas, setTalukas] = useState([])
  const [loading, setLoading] = useState([])

  useEffect(()=>{
    getRoleAndMedicalFacilities()
    getTalukas()
  }, [])

  const Filters = {
    'is_active':[
      {'label':'Active', 'value':'true'},
      {'label':'InActive', 'value':'false'}
    ],
    'draft':[
      {'label':'Draft', 'value':'true'}
    ],
    'is_email_sent':[
      {'label':'Invitation sent', 'value':'true'},
      {'label':'Invitation Not Sent', 'value':'false'}
    ],
    'joined_from_invite':[
      {'label':'Joined', 'value':'true'},
      {'label':'Not Joined', 'value':'false'}
    ],
    'login':[
      {'label':'Never Login', 'sid':'no_login'},
      {'label':'No Login Past 7 days', 'sid':'no_login_past_7_days'}
    ]
  }

  const getRoleAndMedicalFacilities = () =>{
    setLoading(true)
    RoleService.getAll({
      'roleable_type':'infant_journey',
      'organization_id':organizationId,
      'add_medical_facilities':true,
      'sort_column':'role',
      'sort_direction':'asc'
    }).then(({status, data})=>{
      if(status === 200){
        setRoles(data.roles)
        setMedicalFacilities(data.medical_facilities)
        setLoading(false)
      }
    })    
  }

  const getTalukas = () =>{
    setLoading(true)
    FacilityService.getLocations({
      'organization_id':organizationId,
      'fields':['taluka'],
      'is_distinct':true,
    }).then(({data, status})=>{
      setLoading(false)
      setTalukas(data.list)  
    }) 
  }

  const toggleFilter = (value, name) => {
    if(value!=null && value != filter[name]){
      setFilter({...filter, [name]:value})
    }else{
      delete filter[name]
      setFilter({...filter})
    }
  }

  /*const getMedicalFacilities = (name, value) =>{
    setLoading(true)
    FacilityService.getLocations({
      organization_id:organizationId,
      fields:['id', 'phc_name'],
    }).then((res)=>{
      setLoading(false)
      setMedicalFacilities(res.data.list)  
    })  
  }*/

  const filterExists = (value, name) => filter[name] == value;
  
  if(loading)
    return <div className="spinner"/>

  return (
    <div className="filter-box-scroll p-5">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Role
        </h5>

        <CheckBox list={roles} 
          labelKey="role" valueKey="record_id" 
          toggle={toggleFilter} exists={filterExists} 
          name="role_record_id"/> 
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Status
        </h5>

        <CheckBox list={Filters.is_active} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="is_active"/> 

        <CheckBox list={Filters.is_email_sent} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="is_email_sent"/> 

        <CheckBox list={Filters.joined_from_invite} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="joined_from_invite"/> 

        <CheckBox list={Filters.draft} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="draft"/> 

        <CheckBox list={Filters.login} 
          labelKey="label" valueKey="sid" 
          toggle={toggleFilter} exists={filterExists} 
          name="login"/> 
      </div>
      
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Facilities
        </h5>

        <CheckBox list={medicalFacilities} 
          labelKey="name" valueKey="id" 
          toggle={toggleFilter} exists={filterExists} 
          name="medical_facility_id"/> 
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Taluka
        </h5>

        <CheckBox list={talukas} 
          labelKey="taluka" valueKey="taluka" 
          toggle={toggleFilter} exists={filterExists} 
          name="talukas"/> 
      </div>
    </div>
  )
}

const FilterBtn = (props) =>{
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const filterCount = Object.keys(props.filter).length

  return(
    <>
      <span data-tip="Filter" 
        className={props.className?props.className:"bg-highlight round-btn float-right m-l-15 m-t-4"} 
        onClick={()=>toggleFilterModal()}>
          <i className="fas fa-sliders-h"/>
          {filterCount > 0 ? <span className="badge-2 bg-black-light-1 up">{filterCount}</span>:''}
      </span>

      {isFilterOpen &&
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={toggleFilterModal}
          {...props}/>
      }
    </>
  )
}

export {Filters, FilterBtn};