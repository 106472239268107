import React, {Fragment} from 'react';
import PrivateRoute from "../../../routes/PrivateRoute";
import ProviderList from '../Health/Providers/List/ProviderList'
import ActivityList from '../Health/Activities/List/ActivityList'
import ActivityForm from '../Health/Activities/Form/ActivityForm'
import Schedules from '../Health/Calendars/Schedules'
import InvitedUserList from "../InvitedUsers/InvitedUserList";
import HealthSchedules from '../Health/Calendars/HealthSchedules'
import PlannerList from '../Health/Planners/List/PlannerList'
import PlannerReelList from '../Health/Planners/List/PlannerReelList'
import PlannerForm from '../Health/Planners/Form/PlannerForm'
import CancerPortalPage from '../Health/HealthPortalPage/CancerPortalPage'

const HealthRouter = (props) => (
  <Fragment>
    <PrivateRoute path="/cancer/page/:health_id?" component={CancerPortalPage} exact/>	
    <PrivateRoute path="/health/providers/:health_id?" searchString={props.searchString} component={ProviderList} exact />
    <PrivateRoute path="/health/activities/:health_id?" searchString={props.searchString} component={ActivityList} exact />
    <PrivateRoute path="/health/activityForm/:journey_profile_id/:id?" searchString={props.searchString} component={ActivityForm} exact />
    <PrivateRoute path="/health/invite/user/:journey_profile_id" component={InvitedUserList} searchString={props.searchString} exact />
    <PrivateRoute path={["/health/schedules/:journey_profile_id","/infant/schedules/:journey_profile_id"]} component={HealthSchedules} exact/>
    <PrivateRoute path="/health/planner" searchString={props.searchString} component={PlannerList} exact />
    <PrivateRoute path="/health/planner/reel/:journey_profile_id" searchString={props.searchString} component={PlannerReelList} exact />
    <PrivateRoute path="/health/planner/form/:id?" searchString={props.searchString} component={PlannerForm} exact />
  </Fragment>
)

export default HealthRouter;