import React, {Fragment, useState, useEffect} from "react"
import {Link, useHistory} from "react-router-dom"
import SortDirection from '../../../../components/Common/SortDirection'
import useSort from '../../../../hooks/useSorting'
import Cs from "../../../../services/CommonService"
import ApexChart from "../../../Charts/ApexChart"
import DynamicTable from "../../../Common/DynamicTable"
import {EmptyRowAlert} from '../../../Common/TableView'

const DailyCheckTable = (props) =>{
  const {requestParams, sort, onRowClick, formFields, measurements, routeForm, component, Footer, graphData, addNotes, addChecklist} = props

  const OptionsEle = ({item}) => (
    <td className="text-center">
      <span id="record_edit" onClick={e=>routeForm(component, item.id)} className="fas fa-edit m-r-15 font-18 text-muted"/>  
      <span className="far fa-comment m-r-15 font-18 text-muted" onClick={e=>addNotes(e)}/>
      <span className="far fa-check-circle m-r-15 font-18 text-muted" onClick={e=>addChecklist(e)}/>
    </td>
  )

  return(
    <>
      <div className="table-responsive">
        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={formFields} formDataList={measurements} 
          tClass="table"
          onRowClick={onRowClick}
          OptionsEle={OptionsEle}/>

        {measurements.length === 0 &&
          <EmptyRowAlert/>
        }
      </div>
    </>
  )    
}

export default DailyCheckTable;

{/*
<div className="col-xs-12 p-20">
        <div className="br-grey ht-450 wk-center bg-white">
          <ApexChart id="chat_4" chartType="bar" title="Daily Journal" data={graphData} />
          <Footer type="daily_bar_chart" />
        </div>
      </div>

  <div className="col-xs-12 col-sm-6 p-20">
    <div className="br-grey ht-450 wk-center bg-white">
      <WellnessLineGraph type="daily" title="Daily Journal" dataSource={dataSources}/>
      <Footer type="daily_line_chart" />
    </div>
  </div>
*/}