import env from '../../env';
import axios from 'axios';
const url = `${env.health_backend}health/form_suggestions`

class DSSuggestionService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${url}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  add(suggestion){
    return new Promise((resolve, reject) => {
      axios.post(`${url}`, suggestion).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(suggestion){
    return new Promise((resolve, reject) => {
      axios.put(`${url}/${suggestion.id}`, suggestion).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${url}/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new DSSuggestionService();