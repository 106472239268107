import env from '../../env'
import axios from 'axios'

class PreScheduleService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.health_backend}health/pre_schedules`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(id, req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.health_backend}health/pre_schedules/${id}`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(req){
    return new Promise((resolve, reject) => {
      axios.post(env.health_backend+'health/pre_schedules', req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(req) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.health_backend}health/pre_schedules/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.health_backend}health/pre_schedules/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  clone(req){
    return new Promise((resolve, reject) => {
      axios.post(env.health_backend+'health/pre_schedules/clone', req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new PreScheduleService();