import React, {useState, Fragment, useEffect, useContext, lazy, Suspense} from "react";
import {Link} from "react-router-dom";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import FeedbackService from "../../../components/Feedback/FeedbackService";
import Pagination from '../../Pagination'
import ConfirmMenu from "../../Modals/ConfirmMenu";
import ProjectMenu from "../../Modals/ProjectMenu"
import useModal from "../../../hooks/useModal";
import MenuModal from "../../Modals/Menu";
import useStyle from '../../../hooks/useStyle';
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../Common/ImageIcons"
import {FontAwesomeLink} from "../../Common/Button"
import {usePageTitle} from '../../../hooks/pageHead'
import Cs from '../../../services/CommonService'
import useSort from '../../../hooks/useSorting'
import SortDirection from '../../Common/SortDirection'

let requestParams = {}
let selectedFeedback = {}
let dataSource = {}

/*const Styles = () =>{
  lazy(() => import("../../../test.css"));
  return null
}*/

const FeedbackList = (props) => {
  useStyle('/css/tile-components.css', true)
  usePageTitle('FeedbackList')

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  
  const [feedbackList, setFeedbackList] = useState([]);
  const [currentpage,setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  
  const { isOpen:isDeleteOpen, toggleModal:toggleDeleteModal } = useModal();

  useEffect(() => {
    /*import('../../../test.lazy.css').then((styles) => {                    
      styles.use();
    })*/

    //require('../../../test.lazy.css')
    
    requestParams = {
      'is_active': true,
      'page': currentpage,
      'paginate': 1,
      'per_page': 20,
      'sort_column': 'updated_at',
      'sort_direction': 'desc',
      'search': null,
    }

    getFeedbackList();
    return () =>{
      delete require.cache[require.resolve('../../../test.lazy.css')]
    }
  }, [currentUser.id, currentpage]);

  let getFeedbackList = () => { 
    FeedbackService.getAll(requestParams).then((res)=>{
     if(res.status == 200){
       requestParams.totalItems = res.data.meta.total;
       setFeedbackList(res.data.feedbacks);
      }
    })
  }
  
  const {sort} = useSort(requestParams, getFeedbackList)

  const deleteFeedback = (e) => {
    e.preventDefault();
    FeedbackService.delete(selectedFeedback.id).then((res) => {
      if (res.status == 204) {
        setFeedbackList(feedbackList.filter(i => i.id !== selectedFeedback.id))
      }
    })
    toggleDeleteModal(e);
  }

  const updateStatus = (feeback, callback) => {
    feeback.data.status = feeback.data.status==1?0:1;
    FeedbackService.update(feeback).then((res) => {
      if (res.status == 200) {
        callback(feeback.data);
      }
    })
  }

  const onDelete = (f) => {
    selectedFeedback = f;
    toggleDeleteModal();
  }

  const routeForm = (id) =>{
    props.history.push(`/feedback/${id}`)
  }

  const tableData = feedbackList.map((o, k) => {
    const data = o.data || {}

    return(
      <TR key={k}>
        <TD className="text-gray">
          {o.record_id}
        </TD>
        <TD onSelect={()=>routeForm(o.id)}>
          <p dangerouslySetInnerHTML={{ __html: data.notes }}/>
        </TD>
        <TD className="fw500">
          {o.author_name}
        </TD>
        <TD>
          {Cs.formatUpdateDate(o.created_at)}
        </TD>
        <TD>
          <div className="flex aligncenter">
            <LinkDeleteIcon id="delete" tooltip="Delete" onSelect={()=>onDelete(o)}/>
          </div>
        </TD>
      </TR>
    )
  })
/*
<Suspense fallback={<></>}>
        <Styles/>
      </Suspense>
*/

  return (
    <Fragment>
      <div id="ct">
      <div className="app_content">
        <div className="app-header flex justspacebetween m-t-15">
          <div className="flex coldir">
            <div className="app-title m-r-20">Feedbacks</div>
            <div className="font-15">
              List of Feedback - {feedbackList.length}
            </div>
          </div>
        </div>
        
        <div className="app-content-section m-t-30 table-fix-head">
          <Table className="shadow-small">
            <THead>
              <TR>
                <TH onClick={e=>sort('record_id')}>
                  ID 
                  <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('data.notes')}>
                  Feedback 
                  <SortDirection sort_column={requestParams.sort_column} column="data.notes" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('users.email')}>
                  User 
                  <SortDirection sort_column={requestParams.sort_column} column="users.email" reverse={requestParams.reverse}/>
                </TH>
                <TH onClick={e=>sort('created_at')}>
                  Date
                  <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
                </TH>
                <TH>
                  Action
                </TH>
              </TR>
            </THead>
            <TBody>
              {tableData}
            </TBody>
          </Table>
        </div>
      </div>
      </div>

      <ConfirmMenu isOpen={isDeleteOpen} 
        toggleModal={toggleDeleteModal} 
        success={deleteFeedback}/>
      
    </Fragment>
  )

}

export default FeedbackList;