import React, { useState, useContext, useEffect, Fragment } from "react";
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider";
import Forum from "../../../components/Forums/List/Forum";
import ForumService from "../../../components/Forums/ForumService";
import Pagination from "../../Pagination";
import { Link } from "react-router-dom";
import ConfirmMenu from "../../Modals/ConfirmMenu";
import useModal from "../../../hooks/useModal";
import FormTemplateId from "../../../constants/FormTemplateList";
import useStyle from "../../../hooks/useStyle";
import {NavIcon} from '../../Common/MenuItem'
import ReactTooltip from 'react-tooltip';
import { CTTooltip } from "../../Common/CueTooltip";

let requestParams = {};
let selectedForum = {}

const ForumList = (props) => {
  useStyle('forum');
  useStyle("card");

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext);
  const [forumList, setForumList] = useState([]);
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal} = useModal()

  useEffect(() => {
    requestParams = {
      'per_page':15,
      'page':1,
      'sort_column' : 'updated_at',
      'sort_direction' : 'desc',
    }
    getForumsList()
  }, [currentUser.id])

  let getForumsList = () => {
    ForumService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        setForumList(res.data.discussion_requests);
      }
    })
  }

  const deleteForumPost = (e) => {
    e.preventDefault();
    ForumService.delete(selectedForum.id).then((res) => {
      if(res.status == 204) {
        setForumList(forumList.filter(r => r.record_id !== selectedForum.record_id));
      }
    })
    toggleDeleteModal(e);
  }

  const Delete = ({ forum }) => {
    selectedForum = forum
    return(
    <a href="#" onClick={(e) => toggleDeleteModal(e)} className="m-l-25">
      <i className="fa fa-trash font-18 text-muted" data-tip="Delete" aria-hidden="true"></i>
    </a>
  )}

  const Edit = ({ forum }) => (
    <Link to={`/forums/create/${forum.id}`} className="m-l-25">
      <i className="far fa-edit font-18 text-muted" data-tip="Edit" aria-hidden="true"></i>
    </Link>
  )

  const Reply = ({ forum }) => (
    <Link to={`/forums/reply/${forum.id}`} className="m-l-25">
      <i className="fa fa-reply font-18 text-muted" data-tip="Reply" aria-hidden="true"></i>
    </Link>
  )

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          <h4 className="pull-left">Practice Community</h4>
        </div>
        <div className="col-xs-4"> 
          <Link to="/forums/create"
            data-tip="Add Forum"
            className="bg-highlight round-btn float-right m-r-5 m-t-4">
            <i className="fa fa-plus"></i>
          </Link>
        </div>
      </div>
        
      <div className="page-content row">
        {forumList && forumList.map((form, i) => (
          <div className="col-xs-12 container" key={i}>
            <Forum forum={form} reply={Reply} edit={Edit} delete={Delete}/>
          </div>
        ))}
      </div>

      <CTTooltip />
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteForumPost} />
    </Fragment>
  )
}

export default ForumList;