import env from '../env';
import axios from 'axios';
import CommonService from './CommonService'
import BaseService from './BaseService';
const url = `${env.health_backend}health/comments`

class CommentsService extends BaseService{

}

export default new CommentsService('health_backend','health/comments');