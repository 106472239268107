import React, {useState} from 'react'
import Cs from "../../../../../services/CommonService"

const useActivityHook = (child_params={}) => {
  const [child, setChild] = useState(child_params)

  const ageFormat = (id, scheduled_date, data) =>{
    const visit_date = id?data.visit_date_formatted_date:scheduled_date
    if(visit_date){
      const babyAge = Cs.calcDate(child.data.dob_baby_formatted_date, visit_date)
      
      let age_ago = babyAge.days +' days'

      if(babyAge.totalDays > 6){
        age_ago = `${babyAge.weeks}W ${babyAge.days}D`
      }
      
      if(babyAge.totalDays > 30){  
        age_ago = `${babyAge.months}M ${age_ago}`
      }

      if(babyAge.totalDays > 364){  
        age_ago = `${babyAge.years}Y ${age_ago}`
      }

      return age_ago
    }
  }

  const getBgColorByZscore = (z_score, label) =>{
    try{
      z_score = parseFloat(z_score)
      if(z_score <= -3){
        const style = {'backgroundColor' : 'rgb(245, 77, 89)'}
        return {
          'r1': style,
          'r2': style,
          'r4': getBgColorByZscoreLable(label)
        }
      }else if(z_score <= -2){
        const style = {'backgroundColor' : '#f98136'}
        return {
          'r1': style,
          'r2': style,
          'r4': getBgColorByZscoreLable(label)
        }
      }else if(z_score <= -1){
        const style = {'backgroundColor' : '#DECE06'}
        return {
          'r1': style,
          'r2': style,
          'r4': getBgColorByZscoreLable(label)
        }
      }else{
        const style = {'backgroundColor' : '#00a86b'}
        return {
          'r1': style,
          'r2': style,
          'r4': style
        }
      }
    }catch(e){
      console.error(e.message)
      return {}
    }
  }

  const getBgColorByZscoreLable = (zlabel) =>{
    switch (zlabel) {
      case 'SUW':
      case 'SS':
      case 'Severe Stunting':
      case 'SAM':
        return {'backgroundColor':'rgb(245, 77, 89)'}
        break;
      case 'MUW':
      case 'Moderate Stunting':
      case 'MS':
      case 'MAM':
        return {'backgroundColor':'#f98136'}
        break;
      case 'Mild':
        return {'backgroundColor':'#F9D209'}
        break;
      default:
        return {'backgroundColor' : '#00a86b'}
    }
  }

  return {
    child, 
    setChild,
    ageFormat,
    getBgColorByZscore,
    getBgColorByZscoreLable
  }
}

export {useActivityHook};