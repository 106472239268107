import React, {useState, Fragment, useEffect, useContext} from "react"
import {Link} from "react-router-dom"
import moment from 'moment/min/moment.min'
import useStyle from '../../../../hooks/useStyle'
import useSort from '../../../../hooks/useSorting'
import SortDirection from '../../../Common/SortDirection'
import Cs from '../../../../services/CommonService'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import AnswerService from "../AnswerService"
import {NavIcon,CardMenuInfo,MenuItem,CardMainMenu} from '../../../Common/MenuItem'
import AnswerValidation from "./AnswerValidation"
import useModal from "../../../../hooks/useModal"
import GenericModal from "../../../Modals/GenericModal"
import AssessmentService from "../AssessmentService"
import ConfirmMenu from "../../../Modals/ConfirmMenu"
import ReactTooltip from "react-tooltip"
import Pagination from '../../../Pagination'
import { CTTooltip } from "../../../Common/CueTooltip"

let requestParams = {}
let selectedAnswer = {}
let dataSourceList = null
const AssessmentAnswers = (props) => {
  useStyle('table')  
  
  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [answers, setAnswers] = useState([])
  const [assessment, setAssessment] = useState()
  const [stepAssessment, setStepAssessment] = useState([])
  const [currentpage, setCurrentpage] = useState(1) 
  const [loading, setLoading] = useState(true)
  const [pagemeta, setPagemeta] = useState([])

  const {isOpen:isAnswerPopupOpen, toggleModal:toggleAnswerPopup} = useModal()
  const {isOpen:isResultModalOpen, toggleModal:toggleResultModal} = useModal()

  let params = props.match.params

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      'page':currentpage,
      'per_page':15,
      'sort_column':'updated_at',
      'sort_direction':'desc',
      'totalItems':0,
      'search':props.searchString,
      'assessment_id':params.assessment_id,
      'data_source_params': {
        'data_source_id':[3412],
      },
    }

    getAssessmentAnswers()

    return () => {
     requestParams = {} 
     selectedAnswer = {}
     dataSourceList = null
    }
  }, [props.searchString, currentpage]);

  let getAssessmentAnswers = () => { 
    setLoading(true)
    AnswerService.getAll(requestParams).then((res)=>{
      if(res.status == 200){
        requestParams.totalItems = res.data.meta.total
        dataSourceList = res.data.data_source_list
        setAssessment(res.data.assessment)
        setStepAssessment(res.data.assessment.step_assessments)
        setAnswers(res.data.responses)
        setLoading(false)
      }
    })
  }

  const {sort} = useSort(requestParams, getAssessmentAnswers);
  
  const openAnswerPopup = (answer) =>{
    if(answer){
      selectedAnswer = answer
      toggleAnswerPopup()
    }
  }

  const updateAssessment = (e) => {
    e.preventDefault()
    let req = {id: assessment.id, is_result_published: !assessment.is_result_published}
    AssessmentService.update(req).then((res)=>{
      if(res.status==204){
        assessment.is_result_published = !assessment.is_result_published;
        setAssessment({...assessment})
        toggleResultModal(e)
      }
    })
  }

  const Questions = ({answer, correct, setCorrect}) => stepAssessment.map((q, k) =>{
    return(
      <tr key={k} className="bold-100">
        <td onClick={e => setCorrect(!correct)}>
          <span className={'lh1p6 font-16 '+(correct?'far fa-check-circle':'far fa-circle')}/>
        </td>
        <td>{q.question} - </td>
        <td>
          {answer.data[q.record_id]}
        </td>
      </tr>
    )
  })

  const Answers = ({answer}) =>{
    const [correct, setCorrect] = useState(answer.is_correct)

    return(
      <tbody>
        <Questions answer={answer} correct={correct} setCorrect={setCorrect}/>
      </tbody>
    )
  }

  const tableData = answers.map((answer, k) =>{
    /*const answer = answers[a.id]
    const email_notification = a?.sent_email_notifications

    let statusLabel = null
    let color = null
    if(!answers?.[a.id] && email_notification){
      if(email_notification.joined_from_invite){
        statusLabel = 'You have opened the Assessment, but your submission is pending'
        color='yellow'
      }else if(email_notification.is_email_sent){
        statusLabel = 'Assessment is active, but you have not opened it yet'
        color='red'
      }  
    }else if(answer){
      if(answer?.post_assessment_id){
        statusLabel = 'Grade published'
        color ='green'
      }else{
        statusLabel = 'You have submitted the Assessment, but grading is not posted yet'
        color= 'blue'
      }
    }*/

    return(
      <tr key={k} className="bold-100">
        <td data-title="Id">{answer.record_id}</td>
        <td className="font-16">
          {answer.author_name}
        </td>
        <td className="font-16">
          <i className={`fas fa-circle font-18`}></i>
          <span onClick={e => openAnswerPopup(answer)} className="m-l-10 underline pointer">
            Answer
          </span>
        </td>
        <td className="font-16">
          {Cs.formatUpdateDate(answer?.created_at, 'MMM DD, YYYY hh:mm a')}
        </td>
        <td>
          {answer?.total_score?`${answer.total_score}/${assessment.total_score}`:'Not graded'}
        </td>
      </tr>
    )
  })

  if(loading){
    return(<div className="spinner"/>)
  }

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-6">
          <h4>{assessment.data.subject_name}</h4>
        </div>

        <div className="col-xs-6 float-right">         
          <NavIcon dataTip="Publish Result" 
            onSelect={e=>toggleResultModal(e)}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon={assessment.is_result_published?'far fa-check-circle':'far fa-circle'}/>

          <NavIcon id="back_icon" dataTip="Back to Assessment List" 
            onSelect={e=>props.history.goBack()}
            className="bg-highlight round-btn float-right m-r-5 m-t-4" 
            icon="fas fa-arrow-left"/>
        </div>
      </div>
      
      <div className="page-content row">
        <div className="table-responsive">
          <table className="shadow-small">
            <thead>
              <tr className="white bg-black">
                <th onClick={e=>sort('record_id')}>ID 
                  <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
                </th>
                <th onClick={e=>sort('total_score')}>Student 
                  <SortDirection sort_column={requestParams.total_score} column="total_score" reverse={requestParams.reverse}/> 
                </th>
                <th>Status</th>
                <th className="w-20p" onClick={e=>sort('created_at')}>Submission Date/Time 
                  <SortDirection sort_column={requestParams.created_at} column="created_at" reverse={requestParams.reverse}/> 
                </th>
                <th onClick={e=>sort('user')}>Total Points
                  <SortDirection sort_column={requestParams.sort_column} column="question" reverse={requestParams.reverse}/>
                </th>
              </tr>
            </thead>
            <tbody>
              {tableData}
            </tbody>
          </table>
        </div>
      </div>

      <Pagination totalItems={requestParams.totalItems} 
        currentpage={currentpage} 
        setCurrentpage={setCurrentpage} 
        itemsPerPage={requestParams.per_page}/>

      {isAnswerPopupOpen && 
        <GenericModal component={AnswerValidation} 
          title={'Grading '+selectedAnswer.author_name}
          isOpen={isAnswerPopupOpen} toggleModal={toggleAnswerPopup} widthCss="w-100p"
          answerId={selectedAnswer.id} assessmentId={selectedAnswer.assessment_id}
          dataSource={dataSourceList}/>
      }    
      
      <ConfirmMenu isOpen={isResultModalOpen} 
        toggleModal={toggleResultModal} 
        success={updateAssessment} 
        message="Want to change result publish?"/>

      <CTTooltip />
    </Fragment>
  )

}

export default AssessmentAnswers;