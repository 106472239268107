import env from '../env';
import axios from 'axios';
import BaseService from './BaseService';

let url = env.health_backend+'health/profiles'

class ProfileService extends BaseService {

}

export default new ProfileService('health_backend', 'health/profiles');