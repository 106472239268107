import React, {useContext, Fragment, useState, useEffect} from "react"
import querystringify from "querystringify"
import {AuthContext} from "../../contexts/Authentication/AuthStateProvider"
import AuthenticationService from "../../services/AuthService"
import moment from '../../../node_modules/moment/min/moment.min.js'
import useStyle from '../../hooks/useStyle'
import {Link} from "react-router-dom"
import Cs from "../../services/CommonService"
import GeoLocation from "../Common/GeoLocation"
import {usePageTitle} from "../../hooks/pageHead"
import env from "../../env"
import useOTP from '../../hooks/useOTP'
import useAuth from './useAuth' 

let geoData = {}
const Login = (props) => {
  useStyle('/css/login.css', true) 
  usePageTitle('Login')

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  const { state: authState, dispatch } = useContext(AuthContext)
  const { user, setUser, handleFormSubmit, routeUser } = useAuth(props.history, dispatch, geoData)
  //const {} = useOTP()
  const [count, setCount] = useState(Math.random())

  useEffect(()=>{
    //uncomment jul 31
    //rm - registration_mode
    if(queryParam.rm){
      setDefaultRegmode(queryParam.rm === 'phone_no'?'phone_no':'email')
    }else if(localStorage.ct_health_rm){
      setDefaultRegmode(localStorage.ct_health_rm)
    }
  }, [])

  const setDefaultRegmode = (rm) => setUser({...user, registration_mode:rm})

  const handleInputChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }

  const setAuthType = (type) => {
    setUser({
      ...user,
      registration_mode: type,
    })
  }
  
  useEffect(() => {
    if(authState.isAuthenticated){
      routeUser(authState.user)
    }
    invitationLinkVisited()
  }, [authState.isAuthenticated])

  const invitationLinkVisited = () => {
    if(params.organization_member_id){
      AuthenticationService.inviteLinkVisited(params.organization_member_id)
    }
  }

  return (
    <div id="ct">
      {user.isSubmitting ? <div className="spinner"/>:null}
      <GeoLocation geoData={geoData} getAddress="true"/>
      <section className="login-page flex justspacebetween">
        <form className="login-left wid_50 pos_relative flex aligncenter">
          <div className="login-form">
            <h2 className="title m-b-10">Welcome</h2>
            <p className="text-lite-gray m-b-30">
              Enter your sign in details below
            </p>
            <div className="form-fields flex m-b-20">
              <div onClick={e=>setAuthType('email')} className={`col-xs-6 auth-btn ${user.registration_mode=='email'?'auth-btn-active':''}`}>Email</div>
              <div onClick={e=>setAuthType('phone_no')} className={`col-xs-6 auth-btn ${user.registration_mode=='phone_no'?'auth-btn-active':''}`}>Mobile</div>
            </div>
            {user.registration_mode === 'email' &&
              <div className="form-fields flex coldir m-b-20">
                <label className="form-label m-b-10">Email</label>
                <input
                  type="email"
                  className="form-input fulwid"
                  placeholder="Enter your email"
                  name="email"
                  onChange={handleInputChange}
                  value={user.email}
                  required
                />
              </div>
            }
            {user.registration_mode === 'phone_no' &&
              <div className="form-fields flex coldir m-b-20">
                <label className="form-label m-b-10">Mobile</label>
                <input 
                  className="form-input fulwid"
                  value={user.phone_no}
                  type="number" 
                  defaultValue={user.phone_no}
                  placeholder="9999999999"
                  name="phone_no"
                  onChange={handleInputChange}
                  required/>
              </div>
            }
            <div className="form-fields flex coldir">
              <label className="form-label m-b-10">Password</label>
              <input type="password" 
                className="form-input"
                placeholder="password"
                autoComplete="one-time-code"
                name="password"
                onChange={handleInputChange}
                value={user.password}
                required />
            </div>
            {user.errorMessage && (
              <p className="m-t-5 error">{user.errorMessage}</p>
            )}
            {/*<Link to="/forget_password" className="tright m-t-5 m-b-15 text-link">Forgot password</Link>*/}
            <p className="f1r m-t-10">
              By signing in, you agree to the <a href="/about_us/terms_of_service" target="_blank" className="text-link">
                <strong>Terms</strong>
              </a> and <a href="/about_us/privacy_policy" target="_blank" className="text-link">
                <strong>Privacy policy.</strong>
              </a>
            </p>
            <button disabled={user.isSubmitting}
              onClick={handleFormSubmit}
              className="btn btn-primary fulwid m-t-15 f16 fw500 tcenter text-white">
              Sign in
            </button>
            <div className="m-t-20">
              <a className="btn fulwid m-t-15 f16 fw500 tcenter text-white btn-green"
                href="https://wa.me/15550504177?text=Sign me into Cuedwell"
                target="_blank">
                  Sign in with Whatsapp
              </a>

              {/*<p>
                To learn more, please
                <Link to="/contact" className="m-l-5 text-link">
                  <strong>contact us.</strong>
                </Link>
              </p>*/}
            </div>
          </div>
        </form>
        <div className="login-right tcenter justifycenter wid_50 pos_relative flex aligncenter">
          <div className="login-content">
            <a href="#" className="m-b-20">
              <img className="w-115" src="images/logo/cuetree_Icon.png" alt="" />
            </a>
            <h3 className="fw500 m-b-15">Transform health and wellbeing journeys</h3>
            <p className="text-lite-gray m-b-20 wid_80 w-80 wid_100_xs mx-auto">
               {Cs.displayRandomPhrase(Math.floor(4*count))}
            </p>
            <div className="text-lite-gray badge badge-md badge-content m-b-20 mx-auto fw500">
              From Screening to extended care
            </div>
            <div className="m-t-10 mx-auto">
              <ul className="flex gap-5 justifycenter">
                <li>
                  <a href="#">
                    <img src="images/fb-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/instagram-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/twitter-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/linked-icon.png" alt="" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <img src="images/whatsapp-icon.png" alt="" />
                  </a>
                </li>
              </ul>
            </div>
            <div className="flex justspacebetween m-t-50">
              <p className="text-lite-gray f12">
                <i className="m-l-15 m-r-5 far fa-copyright" aria-hidden="true"/> 
                {new Date().getFullYear()} IPIPAL INC. All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default Login;