import React, { Fragment, useState, useEffect } from "react"
import { Link } from "react-router-dom"
import moment from "moment"
import importScript from "../../../../hooks/importScript"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import LineChart from "../../../../components/Charts/LineChart"
import DataPoint from "../../../../constants/ChildGrowthDataPoints"
import ActivityService from "../../../../components/HealthJourney/MotherInfantJourney/Activities/ActivityService"
import Title from "../../GenericPortalPage/Title"
import CommentsModal from "../../../Modals/CommentsModal"
import useModal from "../../../../hooks/useModal"
import querystringify from "querystringify"
import GrowthAlert from "./GrowthAlert"
import GenericModal from "../../../Modals/GenericModal"
import { OptionsObjList, DateTimePicker } from "../../../Common/FormInput"
import Tabs, { TabPane } from "rc-tabs"
import NutritionCareNotification from '../../../NutritionCares/NutritionCareNotification'
import useStyle from "../../../../hooks/useStyle"
import Cs from "../../../../services/CommonService"
import { ChatGrayIcon, EnvelopeGrayIcon } from "../../../Common/ImageIcons"

let req = {}
let options = {}
let selectedActivity = {}

/*let MetricsList = [
  { label: "Weight-for-age", sid: "weight_age", value: 1 },
  { label: "Length-for-age", sid: "length_age", value: 0 },
  { label: "Weight-for-length", sid: "length_weight", value: 2 },
  { label: "Head circumference-for-age", sid: "head_age", value: 3 },
]*/

let DataFilterList = [
  { label: "Age Filter" },
  { label: "1W", value: 1, type:'week' },
  { label: "2W", value: 2, type:'week' },
  { label: "4W", value: 4, type:'week' },
  { label: "13W", value: 13, type:'week' },
  { label: "6M", value: 6, type:'month' },
  { label: "1Y", value: 1, type:'year' },
  { label: "3Y", value: 3, type:'year' },
  { label: "5Y", value: 5, type:'year' },
]

let graphList = [
    {
      tab:'Weight-for-age',
      graphs:[
        {
          id:'1',
          label: "Weight-for-age",
          who_key: "WhoWeightVsAgePercentiles",
          data_key: "WeightVsAgeData",
          x_axis_label: "Months",
          y_axis_label: "Weight (Kgs)",
          y_axis_min:2,
        },
        {
          id:'2',
          label: "Weight-for-age (Z score)",
          who_key: "WhoWeightVsAgePercentiles",
          who_zscore_key: "WhoWeightVsAgeZscores",
          data_key: "WeightVsAgeData",
          zscore_data_key: "WeightVsAgeZscore",
          x_axis_label: "Months",
          y_axis_label: "Weight (Kgs)",
          is_zscore_graph:true,
          y_axis_min:1,
          ytick_amount:8
        },
      ]
    },
    {
      tab:'Length-for-age',
      graphs:[
        {
          id:'4',
          label: "Length-for-age",
          who_key: "WhoLengthVsAgePercentiles",
          data_key: "LengthVsAgeData",
          x_axis_label: "Months",
          y_axis_label: "Height (cms)",
        },
        {
          id:'5',
          label: "Length-for-age (Z score)",
          who_key: "WhoLengthVsAgePercentiles",
          who_zscore_key: "WhoLengthVsAgeZscores",
          data_key: "LengthVsAgeData",
          zscore_data_key: "LengthVsAgeZscore",
          x_axis_label: "Months",
          y_axis_label: "Height (cms)",
          is_zscore_graph:true
        },
      ]
    },
    {
      tab: "Weight-for-length",  
      graphs:[
        {
          id:'7',
          label: "Weight-for-length",
          who_key: "WhoWeightVsHeightPercentiles",
          data_key: "LengthVsWeightData",
          x_axis_label: "Height (cms)",
          y_axis_label: "Weight (Kgs)",
          y_axis_min:1,
          y_axis_max: 28,
          ytick_amount:12,
        },
        {
          id:'8',
          label: "Weight-for-length (Z score)",
          who_key: "WhoWeightVsHeightPercentiles",
          who_zscore_key: "WhoWeightVsHeightZscores",
          data_key: "LengthVsWeightData",
          zscore_data_key: "LengthVsWeightZscore",
          x_axis_label: "Height (cms)",
          y_axis_label: "Weight (Kgs)",
          y_axis_min:1,
          y_axis_max: 28,
          ytick_amount:12,
          is_zscore_graph:true
        },
      ]
    }
]

const GrowthChart = (props) => {
  useStyle('/css/charts-layout-tiles.css', true)
  useStyle('/css/tab-banner-layout.css', true)

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)

  let {project, child, stateGrowthData} = props.location.state
  const [growthData, setGrowthData] = useState(stateGrowthData || {})
  const [loading, setLoading] = useState(true)
  const [chartType, setChartType] = useState()
  const [filter, setFilter] = useState({})
  const [activeTab, setActiveTab] = useState('Weight-for-age')

  const { isOpen:isAlertOpen, toggleModal:toggleAlertModal } = useModal()
  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleComments } = useModal()
  const { isOpen:isActivityDataPoint, toggleModal:toggleActivityDataPoint } = useModal()

  //const genderKey = child.data.gender == 2?'Boys':'Girls'
  let gridClass = "col-xs-12 col-sm-6"

  if (params.index) {
    gridClass = "col-xs-12";
    graphList = [graphList[params.index]];
  }

  useEffect(() => {
    setFilter({...filter, report_type:'Weight-for-age'})
  }, [])

  useEffect(() => {
    if(filter.report_type)
      getGrowthData(filter.report_type)
  }, [filter])

  const getGrowthData = () =>{
    setLoading(true)
    const req = {
      'journey_profile_id': params.health_id,
      'report_type': filter.report_type,
      'age_period_type': filter.age_period_type,
      'age_period': filter.age_period
    }
    ActivityService.report(req).then((res) => {
      setActiveTab(filter.report_type)
      setGrowthData(res.data)
      setLoading(false)
    })
  }

  const openChart = (i) => {
    props.history.push(
      `/health/chart/${params.health_id}/${i}?label=${queryParam.label}`,
      {stateGrowthData:growthData, project:project, child:child}
    )
  }

  const onChangeFilter = (e) =>{
    const f = DataFilterList[e.target.selectedIndex]
    setFilter({ 
      ...filter, 
      age_period_type: f.type,
      age_period: f.value 
    })
  }

  const onDateChanged = (name, value, date) => {
    setFilter({ ...filter, [name]: value })
  }

  let tooltip = {
    custom: function({ series, seriesIndex, dataPointIndex, w }) {
      try {
        return (
            '<div class="arrow_box p-5">' +
              "<span>" +
                w.globals.labels[dataPointIndex] +
                ": " +
                series[seriesIndex][dataPointIndex] +
              "</span>" +
            "</div>"
          );

        /*if (seriesIndex == series.length-1) { 
          const d = w.config.series[seriesIndex].data[dataPointIndex];
          const bodyClass = 'br-btm-dgrey'
          let tooltip = `<div class="font-14">
              <div class="p-l-15 p-r-15 p-t-5 p-b-5 bg-yellow-white" style="background:'#FF42A1'">
                ${project?.data?.mother_name} - ${child?.data?.baby_name}
              </div>
              <div class="p-l-15 p-r-15 p-t-5 p-b-5">
                ${tooltipBody('Date', Cs.formatUpdateDate(d.created_at))}
                ${tooltipBody('Age', d.baby_age)}
            `
            if(d.weight){
              tooltip += tooltipBody('Weight', d.weight)
            }
            if(d.length){
              tooltip += tooltipBody('Height', d.length)
            }
            if(d.nutrition_care_alert_count){
              tooltip += tooltipBody('Alerts', d.nutrition_care_alert_count)
            }

            tooltip += `</div></div>`
          return tooltip
        }else{
          
        }*/
      }catch(e){
        console.error(e.message)
      }
    }

    /*y: {
      formatter: (value, { series, seriesIndex, dataPointIndex, w }) => {
        let childAlertMag = ``;
        try {
          if (seriesIndex == series.length-1) { //child measure data is at the last index
            //const d = growthData[w.config.id + "Data"][dataPointIndex];
            const d = w.config.series[seriesIndex].data[dataPointIndex];
            return '<ul>' +
                '<li><b>Price</b>: ' + d.x + '</li>' +
                '<li><b>Number</b>: ' + d.y + '</li>' +
                '<li><b>Product</b>: \'' + d.product + '\'</li>' +
                '<li><b>Info</b>: \'' + d.info + '\'</li>' +
                '<li><b>Site</b>: \'' + d.site + '\'</li>' +
              '</ul>';

            /*if (d.height_note) {
              childAlertMag = DataPoint.HeightWarning[d.height_note];
            } else if (d.weight_note) {
              childAlertMag = DataPoint.WeightWarning[d.weight_note];
            }*/
         /* }
        } catch (e) {
          console.log(e.message);
        }
        return value + "<div>" + childAlertMag + "</div>"
      }
    }*/
  }

  let events = {
    /*
      https://apexcharts.com/docs/options/chart/events/
      click, legendClick,  markerClick
    */
    dataPointSelection: (event, chartContext, { seriesIndex, dataPointIndex, w:{config} }) => {
      try {
        if (seriesIndex) {
          selectedActivity = config.series[seriesIndex].data[dataPointIndex];
          if(selectedActivity.id){
            toggleActivityDataPoint()
          }
          /*if(selectedActivity.nutrition_care_alert_count>0){
            toggleNutritionCareModal();
          }else if(selectedActivity.id){
            toggleComments()
          }*/
        }
      } catch (e) {
        console.log(e.message);
      }
    }
  }

  const dataLabels = {
    enabled: true,
    enabledOnSeries: [],
    formatter: function(val, {seriesIndex, dataPointIndex, w}) {
      if(val!=null){
        return w.config.series[seriesIndex].data[dataPointIndex].nutrition_care_alert_count
      }
    }
  }

  const setYAxis = (axis) =>{
    axis.axisTicks = {
      show: true
    }
    
    axis.axisBorder = {
      show: true
    }

    axis.labels = {
      formatter:(val)=>{
        return Math.round(val)
      }
    }    

    return axis
  }

  const tooltipBody = (title, details) =>(
    <div class="br-btm-dgrey">{title}: {details}</div>
  )

  const activityDataPoint = () =>{
    const d = selectedActivity
    const bodyClass = 'br-btm-dgrey'
    return (
      <div id="ct">
        <div className="bg-white border-rounded-10 card-shadow m-b-15">
          <div className="card-title card-title-gray flex p-b-15 p20 coldir">
            <h4 className="fw700 f18 card-heading flex coldir text-left">
              {project?.data?.mother_name} - {child?.data?.baby_name}
            </h4>
          </div>
          <div className="card-content p20">
            <div className="b-tab-table active scroll-auto ht-400"> 
              <div className="border-dashed-sm p-b-15 m-b-15">
                <h5 className="fw500 m-b-5">Date</h5>
                <div>{Cs.formatUpdateDate(d.created_at)}</div>
              </div>
              <div className="border-dashed-sm p-b-15 m-b-15">
                <h5 className="fw500 m-b-5">Age</h5>
                <div>{d.baby_age}</div>
              </div>
              <div className="border-dashed-sm p-b-15 m-b-15">
                <h5 className="fw500 m-b-5">Weight</h5>
                <div>{d.weight}</div>
              </div>
              <div className="border-dashed-sm p-b-15 m-b-15">
                <h5 className="fw500 m-b-5">Height</h5>
                <div>{d.length}</div>
              </div>
            </div>
          </div>
          <div className="card-footer flex justspacebetween p20 aligncenter">
            <ul className="flex aligncenter">
              <li className="m-r-25">
                <ChatGrayIcon onSelect={()=>toggleComments()}/>
              </li>
              <li className="m-r-25">
                <EnvelopeGrayIcon 
                count={d.nutrition_care_alert_count}
                onSelect={()=>toggleNutritionCareModal()}/>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }

  const tabsView = graphList.map((g, i)=>{
    return (
      <TabPane tab={g.tab} key={g.tab}> 
        {(!loading && activeTab === g.tab) ? 
          <GraphContainer graphs={g.graphs} openChart={openChart} tooltip={tooltip}
            events={events} growthData={growthData} gridClass={gridClass}/>
          :
          <div className="spinner"/>
        }
      </TabPane>
    )
  })

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
        <div className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20-xs">
          <div className="flex justspacebetween aligncenter p20 wid_100">
            <div className="flex alignstart justspacebetween">
              <div>
                <div className="f24 m-r-20">{project?.data?.mother_name} - {child?.data?.baby_name}</div>
                <a className="underline"
                  href="https://www.who.int/tools/child-growth-standards/standards/weight-for-age" 
                  target="_blank">Reference</a>
              </div>
            </div>
            <div className="flex justifyflexend alignbaseline">
              {activeTab !== 'Weight-for-length' &&
                <select className="form-control input-sm" 
                  onChange={e=>onChangeFilter(e)} placeholder="Filter" defaultValue={filter}>
                    <OptionsObjList list={DataFilterList} id_key='value' label_key='label'/>
                </select>
              }
              <span onClick={()=>props.history.goBack()}
                data-tip="Back to Journey Home"
                className="bg-highlight round-btn float-right m-l-10">
                  <i className="fas fa-home"/>
              </span>
            </div>
          </div>
        </div>
        <div className="app-content-section m-t-30 m-t-20-xs">
          <div id="case_process_details" className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-30 m-t-20-xs b-tab active">
            <Tabs defaultActiveKey={activeTab} onChange={(key)=>setFilter({...filter, report_type:key})} moreIcon="+">
              {tabsView}
            </Tabs>
          </div>
        </div>
        </div>
      </div>

      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
          toggleModal={toggleComments}
          item={selectedActivity} 
          type="infant_journey.activity"/>
      }

      {isAlertOpen && 
        <GenericModal title="Alert" HeaderColor="bg-highlight"
        component={GrowthAlert} toggleModal={toggleAlertModal}
        selectedActivity={selectedActivity}/>
      }

      {isNutritionCareOpen && 
        <GenericModal component={NutritionCareNotification} 
        toggleModal={toggleNutritionCareModal} isOpen={isNutritionCareOpen}
        itemType="infant.activity" itemUuid={selectedActivity.id}
        closeIco="fas fa-chevron-down"/>
      }

      {isActivityDataPoint && 
        <GenericModal 
        component={activityDataPoint} 
        toggleModal={toggleActivityDataPoint}
        selectedActivity={selectedActivity}/>
      }
    </Fragment>
  )
}

const Header = ({data, index, openChart}) => {
  const cardStyle = {
    height: "40px",
    textAlign: "center",
    backgroundColor: data.bg_color || "#607D8B",
  }

  return (
    <div className="card-title card-title-gray flex justspacebetween p-b-15 p20">
      <h4 className="fw700 f18 card-heading flex coldir text-left">
        {data.label}
      </h4>
      <span className="fas fa-expand pull-right white" onClick={(e) => openChart(index)}/>
    </div>
  )
}

const GraphContainer = ({graphs, growthData, gridClass, events, openChart, tooltip}) => graphs && graphs.map((w, i) => {
  let colors = ["#00a86b"]
  let height = 350
  let markers = { size: [0], shape: "square", }
  let series = []
  let y_axis = null
  let x_axis = null
  let data_labels = {}
  const legend = {show:false, position:'right'}

  if(w.is_zscore_graph){
      colors = ["#EE220C", "#FF42A1", "#F27200", "#00a86b", "#F27200", "#FF42A1", "#EE220C", "#6a7b88"]
      markers.size = [0, 0, 0, 0, 0, 0, 0, 15]
      /*dataLabels.enabledOnSeries = [7]
      data_labels = Object.assign(data_labels, dataLabels)*/

      series = [
        {
          name: 'sd4neg',
          type: 'line',
          data: growthData[w.who_zscore_key].sd4neg,
        },
        {
          name: 'sd3neg',
          type: 'line',
          data: growthData[w.who_zscore_key].sd3neg,
        },
        {
          name: 'sd2neg',
          type: 'line',
          data: growthData[w.who_zscore_key].sd2neg,
        },
        {
          name: 'sd0',
          type: 'line',
          data: growthData[w.who_zscore_key].sd0,
        },
        {
          name: 'sd2',
          type: 'line',
          data: growthData[w.who_zscore_key].sd2,
        },
        {
          name: 'sd3',
          type: 'line',
          data: growthData[w.who_zscore_key].sd3,
        },
        {
          name: 'sd4',
          type: 'line',
          data: growthData[w.who_zscore_key].sd4,
        },
        {
          name: w.y_axis_label,
          type: 'line',
          data: growthData[w.data_key],
        },
      ]

      x_axis = {
        type: "numeric",
        tickAmount: 22,
        title: {
          text: growthData[w.who_key].x_axis_label
        },
        labels:{
          formatter:(val)=>{
            return Math.round(val);
          }
        }
      }

      if(growthData.TickAmount){
        x_axis.min = growthData.XAxisMin
        x_axis.max = growthData.XAxisMax
        x_axis.tickAmount = growthData.TickAmount
      }

      y_axis = {
        type: "numeric",
        title: {
          text: w.y_axis_label
        },
        labels:{
          formatter:(val)=>{
            return Math.round(val);
          }
        }
      }
    }else{  
      /*dataLabels.enabledOnSeries = [5]
      data_labels = Object.assign(data_labels, dataLabels)*/
      
      series.push(
        {
          name: "Median",
          type: "line",
          data: growthData[w.who_key].median,
        }
      )

      if (growthData[w.who_key]["3rd"]) {
        series.push({
          name: "3rd",
          type: "line",
          data: growthData[w.who_key]["3rd"],
        });
        colors.push("#fa1818");
        markers.size.push(0);
      }
        
      if (growthData[w.who_key]["15th"]) {
        series.push({
          name: "15th",
          type: "line",
          data: growthData[w.who_key]["15th"],
        });
        colors.push("#fac218");
        markers.size.push(0);
      }

      if (growthData[w.who_key]["85th"]) {
        series.push({
          name: "85th",
          type: "line",
          data: growthData[w.who_key]["85th"],
        });
        colors.push("#fac218");
        markers.size.push(0);
      }

      if (growthData[w.who_key]["97th"]) {
        series.push({
          name: "97th",
          type: "line",
          data: growthData[w.who_key]["97th"],
        });
        colors.push("#fa1818");
        markers.size.push(0);
      }

      if (growthData[w.data_key]) {
        series.push({
          name: "Baby",
          type: "line",
          data: growthData[w.data_key],
        });
        colors.push("#6a7b88");
        markers.size.push(15);
      }

      let title = {
        text: w.label,
        align: "left",
      }

      x_axis = {
        type: "numeric",
        tickAmount: 22,
        title: {
          text: growthData[w.who_key].x_axis_label,
        },
        labels:{
          formatter:(val)=>{
            return Math.round(val);
          }
        }
      }

      if(growthData.TickAmount){
        x_axis.min = growthData.XAxisMin
        x_axis.max = growthData.XAxisMax
        x_axis.tickAmount = growthData.TickAmount
      }

      y_axis = {
        type: "numeric",
        title: {
          text: w.y_axis_label
        },
        labels:{
          formatter:(val)=>{
            return Math.round(val);
          }
        }
      }

  }

  return (
    <div className={`${gridClass} p-20 clearfix`} key={i}>
      <Header data={w} index={i} openChart={openChart}/>
      <div className="card-body flex aligncenter justifycenter coldir p20">
        <div className="br-grey bg-white w-full">
        <LineChart y_axis_min={w.y_axis_min}
          y_axis_max={w.y_axis_max} 
          ytick_amount={w.ytick_amount}
          series={series}
          y_axis_label={w.y_axis_label}
          colors={colors}
          x_axis={x_axis}
          markers={markers}
          className="clearfix"
          events={events}
          id={w.id}
          height={height}
          y_axis={y_axis}
          data_labels={data_labels}
          legend={legend}
          tooltip={tooltip}/>
        </div>
      </div>
    </div>
  )
})

export default GrowthChart;