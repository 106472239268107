import React, { useState} from 'react';
 
const RoleSelectList = ({roles, selectedRoles=[], onSelect}) =>{ 
  	const toggle = (role) =>{
  	  const idx = selectedRoles.indexOf(role.id)	
  	  idx > -1 ? selectedRoles.splice(idx, 1): selectedRoles.push(role.id)	
  	  onSelect(selectedRoles)	
  	}

  	return(
      <div className="p-5">
      	{roles.map((r, i) => 
      	  <div className="m-b-5" onClick={e=>toggle(r)} key={i}>
	      	<span className={selectedRoles.indexOf(r.id)>-1 ?
	      		'font-18 far fa-check-square text-muted'
	      		:'font-18 far fa-square text-muted'}/>
      		<span className="m-l-15 font-16">{r.role}</span>
      	  </div>
        )}
      </div>
    )
} 

export default RoleSelectList;