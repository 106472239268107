import React ,{Fragment, useState, useEffect, useContext} from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import Cs from "../../../../../services/CommonService";
import {PillDsField, PillDsList, PillTagList} from "../../../UserRoles/NotificationPill"
import ReactTooltip from "react-tooltip";
import {ImageList, ImagePlayList} from "../../../../Common/Image"
import env from "../../../../../env"

const CounselingReel = (props) => {
  let counseling = props.counseling;
  counseling.updated_at = Cs.formatUpdateDate(counseling.updated_at)
  
  let data = counseling.data || {};
  let ds = counseling.data_source || {};
  
  const cardStyle = {
    backgroundColor: "#607D8B",
  }

  if (!data) return null;

  const Header = () => (
    <div className="white card-header p-5" style={cardStyle}>
      <div className="font-16">
        {data.name}
      </div>
      <div className="font-16">
        {counseling.updated_at}
      </div>
    </div>
  )

  const Footer = () => {
   return(
      <div className="card-footer">
        <div className="pull-left">
          <span className="m-l-25" onClick={(e) => props.addNotes(e, counseling)}>
            <i className="far fa-comment font-18 text-muted" data-tip="Chat"></i>
            <span className="badge-2 up bg-lgrey">{counseling.comments_count || 0}</span>
          </span>
          <span className="m-l-25" onClick={(e) => props.addChecklist(e, counseling)}>
            <i className="far fa-check-circle font-18 text-muted" data-tip="Checklist"></i>
            <span className="badge-2 up bg-lgrey">{counseling.check_list_count || 0}</span>
          </span>
          {env.admin_emails.includes(props.currentUser.email) && 
            <span className="m-l-25" onClick={(e) => props.openMapper(counseling)}>
              <i className="fas fa-hands-helping font-18 text-muted" data-tip="Add Data Source Mapper"></i>
            </span>
          }
        </div>
        <div className="pull-right">
          <props.menu counseling={counseling} />
        </div>
      </div>
    )
  }

  const CounselingCard = () => {
    return (
      <div className="card-container bg-white shadow-small w-350 m-r-15" >
        <div className="black bg-white br-btm-grey card-header p-5">
          <div className="font-16 m-t-10">
            <PillDsField field={ds.stage} dataKey="label"/>
          </div>
        </div>
        <div className={`ht-320 scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td className="d-flex" onClick={(e) => props.counsellGallery(e, counseling)}>
                  <div className="ht-200 w-200 m-auto d-flex">
                  <ImagePlayList list={data.image_upload} styleName="img-responsive w-350"/>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <strong>Notes</strong>
                  <div className="ws-inl" dangerouslySetInnerHTML={{ __html: data.description }}/>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer/>
      </div>
    )
  }

  return <CounselingCard />;
}

export default CounselingReel;