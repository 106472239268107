import React, {Fragment, useState, useRef, useEffect} from 'react'
import {CueTip} from '../../../components/Common/CueTooltip'
import FormHelper from '../../../components/FormBuilder/FormHelpers'
import EditDataSourceIcon from '../EditDataSourceIcon'
import {FieldLabel, OptionLabel, Description, requiredMsg} from '../FieldLabel'
import useModal from "../../../hooks/useModal"
import GenericModal from "../../Modals/GenericModal"
import Cs from '../../../services/CommonService'
import DataSourceMapper from "../DataSourceMapper"
import {ImageFlexList} from '../../Common/Image'

const InputSelect = ({labelLocale, renderField, formId, screen, field, formData, readOnly, errors, forceUpdate, formFn, isFormWizard, openDataSourceModal, openFieldLabelModal, currentLocale, position}) => {
  const [showError, setShowError] = useState(false)
  const [options, setOptions] = useState(field.options_key_value?.options || field.options)
  const [objList, setObjList] = useState({})
  const [loading, setLoading] = useState(true)

  const { isOpen:isMenuOpen, toggleModal:toggleMenuModal } = useModal()

  const fieldEleRef = useRef()
  const fstListRef = useRef()

  /*useEffect(() => {
    return () => {*/
      /*if(formFn['is_'+field.client_id+'_open']){
        bindWindowClickEvent()
      } */     
   /* }
  }, [])*/

  useEffect(()=>{
    formFn.ds[field.client_id] = formFn.ds[field.client_id] || {}
    if(renderField)setResultSet();
  }, [currentLocale, renderField])

  if(!renderField){
    //delete formData[field.client_id]
    return null
  }

  const op_kv = field.options_key_value
  
  const setResultSet = () =>{
    if(op_kv && op_kv.options){
      setObjList(
        op_kv.options.reduce((obj, item) => (obj[item.value] = OptionLabel({option:item, currentLocale:currentLocale}), obj), {})
      )  
    }
    setLoading(false)
  }

  let inputAttributes = {
    'id': field.client_id,
    'name': field.client_id,
    'placeholder': labelLocale(5),
    'disabled':(readOnly || field.read_only),
    'required':field.required,
    'className':'dd-button'
  }

  let parentAttributes = {
    'tooltip': field.tooltip
  }
  
  let validation = {
    required: field.required
  }

  let labelAttributes = FormHelper.setLabelStyle(field, isFormWizard, parentAttributes)
  
  const handleChange = (item) =>{
    const value = (typeof item === 'object'?item.value:item)
  	/*const { target } = event;*/
    /*const name = field.client_id*/
    formData[field.client_id] = (value==null?null:FormHelper.isNumeric(value))
    /*if(value==null){
      formData[name]=null
    }else{
      formData[name] = FormHelper.isNumeric(value)
    }*/
    formFn['on_select_'+field.client_id] && formFn['on_select_'+field.client_id](item, errors[field.client_id])
    formFn.ds[field.client_id] = item
    setError(false)
    FormHelper.setFormTitle(field, formData, value, op_kv)
    forceUpdate()
    hideList()
  }

  const clearSelection = () =>{
    if(formData[field.client_id] != null){
      formData[field.client_id] = null
      FormHelper.setFormTitle(field, formData, null, op_kv)
    }
    setError(false)
  }

  const setInputValue = (optionList=[], inputValue, setValue) =>{
    if(formData[field.client_id] == null){
      const option = optionList[0]
      const label = objList?option?.label:option
      if(label?.toLowerCase() == inputValue?.toLowerCase()){
        setValue(objList?option?.label:option)
        handleChange(option)
      }else{
        setValue(null)
        forceUpdate()
      }
    }
    setError()
  }

  const setError = (reset_state) => {
    if(field.required){
      const name = field.client_id
      errors[name].invalid = (formData[name]==null || errors[name].err_msg)?true:false
      errors[name].touched = true
      if(errors[name].invalid){
        errors.invalid[name] = true
      }else{
        delete errors.invalid[name]
      }
      if(reset_state){
        setShowError(!showError)
        formFn.refreshFormSubmit()  
      }
    }
  }

  const exists = (item) => {
    return formData[field.client_id] == item
  }

  const bindWindowClickEvent = () =>{
    if(formFn['on_'+field.client_id+'_open']){
      formFn['on_'+field.client_id+'_open'](
        (field.options_key_value?.options || field.options), 
        setOptions
      )
    }
    if(screen.xs == true){
      toggleMenuModal()
    }else{
      fstListRef.current.classList.remove("hidden")
      formFn['is_'+field.client_id+'_open'] = true
      //$scope.onOpen({req:$scope.model});
      window.addEventListener('click', eventFunction)  
    }
  }

  const eventFunction = (event) => {
    try{
      if(fieldEleRef.current.contains(event.target)){
        //console.log(field)
      }else{
        hideList()
        //$scope.onClose({req:$scope.model});
        forceUpdate()
      }
    }catch(e){
    
    }
  }

  const hideList = () =>{
    if(screen.xs == true){
      toggleMenuModal()
    }else{
      fstListRef.current.classList.add("hidden")
      formFn['is_'+field.client_id+'_open'] = false
      window.removeEventListener("click", eventFunction)
    }
  }

  FormHelper.setDisabled(field, inputAttributes)
  //FormHelper.checkFieldValidity(field, formData, errors);

  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          {errors[field.client_id].err_msg || requiredMsg}
        </div>
      )
    }

    return null
  }

  if(loading)
    return null;
  
  return (
  	<Fragment>
      <div {...parentAttributes}>
        <div className="flex coldir m-b-20 tooltip">
          <CueTip 
            positionCss={position>1?'top':'bottom'}
            tooltip={field.tooltip}
            currentLocale={currentLocale}
            locale={field.locale?.tooltip}/>
      	  <FieldLabel field={field} 
            labelAttributes={labelAttributes} 
            currentLocale={currentLocale}
            openDataSourceModal={openDataSourceModal}
            openFieldLabelModal={openFieldLabelModal}/>
     	    <div ref={fieldEleRef} id={field.client_id+ '_container'} className={readOnly ? 'input-readonly':''} >
            {(op_kv && op_kv.options) 
              ? <OptionsObjList objList={objList} list={options} 
                exists={exists} handleChange={handleChange} currentLocale={currentLocale}
                model={formData[field.client_id]} field={field} 
                bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes} 
                fstListRef={fstListRef} formId={formId} dataTypes={op_kv.data_types}
                setError={setError} clearSelection={clearSelection}
                setInputValue={setInputValue}/> 
              : <OptionsStringList list={options} exists={exists} 
                handleChange={handleChange} model={formData[field.client_id]} field={field}
                bindWindowClickEvent={bindWindowClickEvent} inputAttributes={inputAttributes} 
                fstListRef={fstListRef} setError={setError} clearSelection={clearSelection}
                setInputValue={setInputValue}/>
            }   
          </div>
          <Description description={field.description} />
          <Error/>
   	    </div>
      </div> 

      {isMenuOpen && (
        <GenericModal
          component={SingleSelectPopup}
          inputAttributes={inputAttributes} 
          handleChange={handleChange} 
          setError={setError}
          exists={exists}
          optionObjList={options}
          optionStringList={options}
          dataTypes={op_kv.data_types}
          title={field.label}
          isOpen={isMenuOpen}
          toggleModal={toggleMenuModal}
          currentLocale={currentLocale}
          clearSelection={clearSelection}
          setInputValue={setInputValue}/>
      )}

    </Fragment>
  )
}

const OptionsStringList = ({list, model, exists, field, handleChange, clearSelection, bindWindowClickEvent, inputAttributes, fstListRef, setError, setInputValue}) => {
  
  let [optionList, setOptionList] = useState(list)
  const inputEleRef = useRef()

  useEffect(()=>{
    /*Popup mode selection change*/
    setSearch(model!=null?model:null)
  }, [model])

  const search = (term) =>{
    clearSelection()
    if(term.length>0){
      setOptionList(FormHelper.searchList(list, term))  
    }else{
      setOptionList([...list])
    }
  }

  const setSearch = (term) =>{
    inputEleRef.current.value = term
  }

  const selectableItems = optionList && optionList.map((o, k) =>{
    return(  
      <li key={k} className={exists(o)?'dd-selected':''} onClick={e => handleChange(o)} title={o}>
        {o}
      </li>
    )
  })
  
  //onBlur={()=> setInputValue(optionList, inputEleRef.current.value, setSearch)}
  
  return(
    <Fragment>
      <div className="dropdown" onClick={e=>bindWindowClickEvent(e)}>
        <input {...inputAttributes} defaultValue={model} 
        ref={inputEleRef}
        onChange={e=>search(e.target.value)} 
        onFocus={()=> setOptionList(list)}/>
        <span className="dd-button-caret"/>
      </div>
      <ul className="dd-menu scroll-sm hidden" ref={fstListRef} id={field.client_id+ "_result_set"}>
        {selectableItems}
      </ul>
    </Fragment>
  )
}

const OptionsObjList = ({dataTypes, formId, list, objList, model, exists, field, handleChange, clearSelection, bindWindowClickEvent, inputAttributes, currentLocale, fstListRef, setError, setInputValue}) => {

  let [optionList, setOptionList] = useState(list)
  const inputEleRef = useRef()
  let itemHeight = dataTypes && dataTypes.image_url?{height: '100px'}:{}

  useEffect(()=>{
    /*Popup mode selection change*/
    setSearch(model!=null?objList[model]:null)
  }, [model])

  //https://javascript.info/regexp-anchors
  const search = (term) =>{
    clearSelection()
    if(term.length>0){
      setOptionList(FormHelper.searchObjList(list, term))  
    }else{
      setOptionList(list)
    }
  }

  const setSearch = (term) =>{
    inputEleRef.current.value = term
  }

  const selectableItems = optionList && optionList.map((o, k) => { 
    return(
      <Fragment key={k}>  
        <li style={itemHeight} className={exists(o.value)?'dd-selected':''}
         onClick={() => handleChange(o)} title={o.description}>
          <div>
            <OptionLabel option={o} currentLocale={currentLocale}/>
            {itemHeight && o.image_url && <img src={Cs.getIconByType(o.image_url)} alt="img" className="w-100" style={{height:"60px"}}/>}
            {o.images ? <ImageFlexList styleName="radio-img" list={o.images}/> : null}
          </div>
        </li>
      </Fragment>
    )
  })

//onBlur={()=>setInputValue(optionList, inputEleRef.current.value, setSearch)}
  
  return(
    <>
      <div className="dropdown"
        onClick={e=>bindWindowClickEvent(e)} >
        <input {...inputAttributes} 
        defaultValue={objList[model]} ref={inputEleRef}
        onChange={e=>search(e.target.value)} 
        onFocus={()=> setOptionList(list)}/>
        <span className="dd-button-caret"/>
      </div>
      <ul className="dd-menu scroll-sm hidden" ref={fstListRef} id={field.client_id+ "_result_set"}>
        {selectableItems}
      </ul>
    </>
  )
}

const SingleSelectPopup = ({inputAttributes, dataTypes, handleChange, clearSelection, exists, optionObjList, optionStringList, currentLocale, setError, setInputValue}) =>{

  let [optionList, setOptionList] = useState(optionObjList ? optionObjList : optionStringList)
  const inputEleRef = useRef()
  let itemHeight = dataTypes && dataTypes.image_url?{height: '100px'}:{}

  const search = (term) =>{
    clearSelection()
    if(term.length>0){
      setOptionList(OptionsObjList ? FormHelper.searchObjList(optionObjList, term):FormHelper.searchList(optionStringList, term))  
    }else{
      setOptionList(OptionsObjList ? optionObjList: optionStringList)
    }
  }

  const setSearch = (term) =>{
    inputEleRef.current.value = term
  }

  const optionStringItems = optionList ? optionList.map((o, k) =>  
    <li key={k} className={exists(o)?'dd-selected':''} 
      onClick={e => handleChange(o)} title={o}>
        {o}
    </li>
  ):null

  const optionObjectItems = optionList.map((o, k) =>  
    <li key={k} style={itemHeight} className={exists(o.value)?'dd-selected':''}
      onClick={e => handleChange(o)} title={o.description}>
        <div className="font-16">
          {/*o[currentLocale]?.label || o.label*/}
          <OptionLabel option={o} currentLocale={currentLocale}/>
          {itemHeight && o.image_url && <img src={Cs.getIconByType(o.image_url)} alt="img" className="w-100 pull-right" style={{height:"60px"}}/>}
          {o.images ? <ImageFlexList styleName="radio-img" list={o.images}/> : null}
        </div>
    </li>
  )

//onBlur={()=>setInputValue(optionList, inputEleRef.current.value, setSearch)}
//autoFocus  

  return(
    <div id="ct">
      <div className="dropdown">
        <input {...inputAttributes}  ref={inputEleRef}
          onChange={e=>search(e.target.value)}/>
        <span className="dd-button-caret"/>
      </div>
      <ul className="dd-menu margin-unset">
        {OptionsObjList? optionObjectItems : optionStringItems}
      </ul>
    </div>
  )

}
export default InputSelect;