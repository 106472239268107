import React, {Fragment, useState, useEffect} from "react"
import {Link} from "react-router-dom"
import env from '../../env'
import useStyle from "../../hooks/useStyle"
import Cs from "../../services/CommonService"
import FileUploadService from '../../services/FileUploadService'
import useModal from "../../hooks/useModal"
import { LinkFontAwesomeIcon } from "../Common/ImageIcons"
import GenericModal from '../Modals/GenericModal'

let searchKeyword = null;
let timer = null;
const SEARCH_WAIT_INTERVAL = 1000;
const MediaFilterList = ({fileableId, fileableType, onFileCloneSuccess}) => {
  const [dataSources, setDataSources] = useState({})
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState({source:'data_source'})

  /*useEffect(()=>{
    getDataSourceList()
  }, [])*/

 /* const getImageList = () =>{
    FileUploadService.getAll().then((res)=>{
      setList(res.data.file_uploads)
    })
  }*/

  const getDataSourceList = () => { 
    setLoading(true)
    const req = {
      'data_source_params':{
        'fields':['data_type'],
        'data_source_id':[searchKeyword], 
        'per_page' : 30
      }
    }
    Cs.getDataSource(req).then(data => {
      setDataSources(data)
      setLoading(false)
    })
  }

  const cloneImage = (clone_url) =>{
    FileUploadService.clone({
      url: clone_url,
      fileable_type: fileableType,
      fileable_id: fileableId
    }).then(({status, data})=>{
      if(status === 201){
        onFileCloneSuccess(data.file_upload)
      }
    })
  }

  const handleChange=(search, triggerChange)=>{
    clearTimeout(timer)
    searchKeyword = search
    timer = setTimeout(triggerChange, SEARCH_WAIT_INTERVAL)
  }

  return (
    <div id="ct">
      <div className="row">
        {loading ? 
          <div className="spinner"/>
          :
          <>
            <div className="row">
              <div className="col-md-4">
                <div className="card-body flex aligncenter justifycenter coldir p10">
                  <input className="form-input form-input-gray form-input-full" 
                  placeholder="Select DataSource by id"
                  onChange={e=>handleChange(e.target.value, getDataSourceList)}
                  />
                </div>
              </div>
              <div className="col-md-4">
                <div className="card-body flex aligncenter justifycenter coldir p10">

                </div>
              </div>
            </div>
            {filter.source === 'data_source' &&
              <div className="row">
                <DataSourceImageList dataSourceList={dataSources} cloneImage={cloneImage}/>
              </div>
            }
          </>
        }
      </div>
    </div>
  )
}

const DataSourceImageList  = ({dataSourceList, cloneImage}) => Object.values(dataSourceList).map((dataSource, pi)=>{

  const dataTypes = Object.values(dataSource.data_type || {})
  //const imageTypes = dataTypes.filter((t)=> 
  //['multi_image_upload', 'public_multi_image_upload', 'image_upload'].indexOf(t.type)>-1)
  
  return(
    <Fragment key={pi}>
      {dataSource.options.map((o, ci)=>
        <Fragment key={ci}>
          {dataTypes.map((type, i)=>
            <ImageContainer type={type} option={o} cloneImage={cloneImage} key={i}/>
          )}
        </Fragment>
      )}
    </Fragment>
  )
})

const ImageContainer = ({type, option, cloneImage})=>{
  if(['multi_image_upload', 'public_multi_image_upload'].indexOf(type.type)>-1){
    return <ImageList lists={option[type.key]} cloneImage={cloneImage}/>
  }else if(['image_upload', 'public_image_upload'].indexOf(type.type)>-1){
    return <Image url={option[type.key]} cloneImage={cloneImage}/>
  }

  return null
}

const Image = ({url, cloneImage}) => {
  if(url){
    return(
      <div className="col-md-3 text-center">
        <img src={url} className="img-responsive w-250 ht-150 p-5"/> 
        <div className="m-t-25" onClick={()=>cloneImage(url)}>
          <div className="ellipsis">{Cs.getFileName(url)}</div>
          <input type="checkbox" readOnly />
        </div>   
      </div>
    )
  }

  return null
}

const ImageList = ({lists=[], cloneImage}) => lists ? lists.map((url, k)=>{
  return <Image url={url} cloneImage={cloneImage} key={k}/>
}):null

const MediaListBtn = ({fileableId, fileableType, onFileCloneSuccess}) => {
  const { isOpen: isImageFilterOpen, toggleModal: toggleImageFilter} = useModal()

  return(
    <>
      <LinkFontAwesomeIcon 
        css="far fa-images font-18 white"
        onSelect={() => toggleImageFilter()}/>

      {isImageFilterOpen &&
        <GenericModal component={MediaFilterList} title="Media List"
          isOpen={isImageFilterOpen} toggleModal={toggleImageFilter}
          fileableId={fileableId} fileableType={fileableType}
          onFileCloneSuccess={onFileCloneSuccess}/>
      }
    </>
  )
}

export {MediaFilterList, MediaListBtn};