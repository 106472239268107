import env from '../env';
import axios from 'axios';
import BaseService from './BaseService';
const url = `${env.health_backend}health/checklists`

class ChecklistService extends BaseService{
 
  status(checklist){
    return new Promise((resolve, reject) => {
      console.log('status toggle',checklist)
      axios.put(`${url}/${checklist.id}/status`, checklist).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
  
}

export default new ChecklistService('health_backend', 'health/checklists');
