import { useState, useEffect } from 'react';
import Cs from "../services/CommonService";

const WAIT_INTERVAL = 1000;
let timer = null;
const useClientSearch = (searchableList=[], setSearchedList, searchableKey) => {
  
  let [searchKeyword, setSearchKeyword] = useState()

  useEffect(() => {
    timer = null
  }, [])

  const onSearch=(event)=>{
    clearTimeout(timer)
    searchKeyword = event.target.value
    timer = setTimeout(search, WAIT_INTERVAL)
    setSearchKeyword(searchKeyword)
  }

  const search = () =>{
    if(searchKeyword.length>0 && searchableList.length>0){
      searchKeyword = searchKeyword.toLowerCase()
      /*for(let s of searchableList){
        console.log(s.toString())
      }*/
      const result = Cs.nestedListSearch(searchableList, searchKeyword)
      setSearchedList([...result])  
    }else{
      setSearchedList([...searchableList])
    }
  }

  return {
    onSearch
  }
};

const useSearch = (setSearchString) => {
  
  const [searchButton, setSearchButton] = useState(false)
  let [searchKeyword, setSearchKeyword] = useState()

  useEffect(() => {
    timer = null
  }, [])

  const onSearchCallback = (event) =>{
    clearTimeout(timer)
    searchKeyword = event.target.value
    timer = setTimeout(triggerChange, WAIT_INTERVAL)
    setSearchKeyword(searchKeyword)
  }

  const triggerChange = (c) => {
    setSearchString(searchKeyword)
  }

  const searchClick=() =>{
    if(searchButton === true){
      setSearchButton(false)
    }else{
      setSearchButton(true)
    }
  }

  const clearSearch = () => {
    setSearchKeyword("")
    setSearchString(null)
  }

  return {
    onSearchCallback,
    searchKeyword,
    searchClick,
    searchButton,
    setSearchButton,
    clearSearch
  }
};

export {useSearch, useClientSearch};