import env from '../../../../env';
import axios from 'axios';
import BaseService from '../../../../services/BaseService';

class CounselingService extends BaseService{
  
  uploadCsv(req){
    return new Promise((resolve, reject) => {
      axios.post(`${env.infant_backend}upload_csv/counselings`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  notifications(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.infant_backend}notification/counselings`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new CounselingService('infant_backend','counselings');