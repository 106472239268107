import React, {Fragment, useState, useContext, useMemo, useEffect} from "react"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import querystringify from "querystringify"
import useStyle from '../../../../../hooks/useStyle'
import useSort from '../../../../../hooks/useSorting'
import SortDirection from '../../../../Common/SortDirection'
import {sortableContainer, sortableElement, sortableHandle} from 'react-sortable-hoc'
import Cs from "../../../../../services/CommonService"
import { Table, THead, TH, TR, TD, TBody } from "../../../../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../../../Common/ImageIcons"
import CheckAccess from '../../../Roles/CheckAccess'
import SubFacilityService from "./SubFacilityService"
import useModal from "../../../../../hooks/useModal"
import {usePageTitle} from '../../../../../hooks/pageHead'
import FormTemplateId from "../../../../../constants/FormTemplateList"
import {MenuItem, NavIcon} from '../../../../Common/MenuItem'
import PaginationSM from "../../../../Common/PaginationSM"
import ConfirmMenu from "../../../../Modals/ConfirmMenu"

let requestParams = {}
let selectedFacility = {}
const FacilityTableList = (props) => {
  useStyle('/css/tile-components.css', true)
  usePageTitle('Facility List')

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  const {isPopupMode} = props

  const { state: {screen, user: currentUser }} = useContext(AuthContext)
  const organizationId = params.organization_id || currentUser.current_organization_id

  const [subFacilityList, setSubFacilityList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal} = useModal()

  const permission = useMemo(() => 
    CheckAccess.getMenuPermission(currentUser, 'facilities')
  , [currentUser.id])

  useEffect(() => {
    requestParams = {
      page: currentpage,
      per_page: 50,
      facility_form_id: FormTemplateId.HealthMedicalForm,
      organization_id: organizationId,
      medical_facility_id: params.medical_facility_id
    }

    getFacilityList()
    return () => {
      requestParams = {}
    }
  }, [props.searchString, currentpage])

  let getFacilityList = () => {
    if(permission.read){
      SubFacilityService.getAll(requestParams).then(({status, data}) => {
        if (status === 200) {
          setSubFacilityList(data.sub_facilities)
          setPagemeta(data.meta)
        }
      })
    }
  }

  const {sort} = useSort(requestParams, getFacilityList);
  
  const onDelete = (facility) =>{
    selectedFacility = facility
    toggleDeleteModal()
  }

  const deleteFacility = (e) => {
    e.preventDefault()
    SubFacilityService.delete(selectedFacility.id).then((res) => {
      if (res.status === 204) {
        setSubFacilityList(
          subFacilityList.filter((i) => i.id !== selectedFacility.id)
        )
      }
    })
    toggleDeleteModal(e)
  }

  const TableData = ({subFacilityList}) => subFacilityList && subFacilityList.map((o, k) =>
    <TR key={k}>
      <TD className="fw500 text-gray" data-title="Id">{o.record_id}</TD>
      <TD data-title="Name">{o.data.name_subfacility || o.data.name}</TD>
      <TD data-title="Created Date">{Cs.formatUpdateDate(o.updated_at)}</TD>
      <TD data-title="Options">
        <div className="flex aligncenter">
          <LinkDeleteIcon id="delete_facility" tooltip="Delete facility" onSelect={()=>onDelete(o)}>
          </LinkDeleteIcon>
          <LinkFontAwesomeIcon css="fas fa-users white" count={o.members_count} 
            link={`/${o.data.name}/facility/members/${organizationId}/${o.medical_facility_id}/${o.id}`}/>
        </div>
      </TD>
    </TR>
  )

  return (
    <div id="ct">
      <div className="app_content">
        {!isPopupMode ?
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20">Medical Sub Facilities</div>
              <div className="font-14">{subFacilityList.length} Items</div>
            </div>

            <div className="flex aligncenter">
              {permission.write &&  
                <>
                  <Link to={`/health/sub_facility/${organizationId}/${params.medical_facility_id}`} data-tip="Create Sub Facility"
                    className="bg-highlight round-btn float-right m-r-5">
                    <i className="fas fa-plus"/>
                  </Link>
                </>
              }
            </div>
          </div>
          :null
        }
            
        <div className="app-content-section m-t-30">
            <div className="table-fix-head">
              <Table className="shadow-small">
                <THead>
                  <TR>
                    <TH onClick={e=>sort('record_id')}>Id
                      <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
                    </TH>
                    <TH onClick={e=>sort('data.name')}>Facility Name
                      <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/> 
                    </TH>
                    <TH onClick={e=>sort('created_by')}>Created By
                      <SortDirection sort_column={requestParams.sort_column} column="created_by" reverse={requestParams.reverse}/> 
                    </TH>
                    <TH onClick={e=>sort('created_at')}>Options
                      <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/> 
                    </TH>
                  </TR>
                </THead>
                <TBody>
                  <TableData subFacilityList={subFacilityList} />
                </TBody>
              </Table>
            </div>

          <PaginationSM
            className="pagination-bar"
            currentPage={currentpage}
            totalItems={pagemeta.total}
            itemsPerPage={pagemeta.per_page}
            onPageChange={setCurrentpage}
            siblingCount={2}/>
            
          </div>
        </div>

      <ConfirmMenu isOpen={isDeleteModalOpen} 
        toggleModal={toggleDeleteModal} 
        success={deleteFacility}/>

    </div>
  )

}

export default FacilityTableList;