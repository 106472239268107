import React, {Fragment} from 'react';
import PrivateRoute from "../../../routes/PrivateRoute";
import ChildList from '../MotherInfantJourney/Child/ChildList'
import ChildForm from '../MotherInfantJourney/Child/ChildForm'
import FamilyList from '../MotherInfantJourney/Family/FamilyList'
import FamilyForm from '../MotherInfantJourney/Family/FamilyForm'
//import ActivityList from '../MotherInfantJourney/Activities/List/ActivityList'
import FacilityList from './MedicalCareFacility/List'
import FacilityForm from './MedicalCareFacility/Form'
import SubFacilityTable from './MedicalCareFacility/SubFacility/SubFacilityTable'
import SubFacilityForm from './MedicalCareFacility/SubFacility/Form'
import GrowthChart from './Child/GrowthChart'
import HealthJourneyForm from '../../../components/HealthJourney/Form/HealthJourneyForm'
import InfantPath from './Path/InfantPath'
import InfantPathHome from './Path/InfantPathHome'
import ActivityForm from '../MotherInfantJourney/Activities/Form/ActivityForm'
import CounselingList from '../MotherInfantJourney/Counseling/List/CounselingList'
import CounselingForm from '../MotherInfantJourney/Counseling/Form/Form'
import CounselingReelList from '../MotherInfantJourney/Counseling/List/CounselingReelList'
import {ReportsPage} from './Charts/ReportsPage'
import InstantMeasurement from './Child/InstantMeasurement'
import WHOChart from '../MotherInfantJourney/Charts/WHOChart'

const InfantJourneyRouter = (props) => (
  <Fragment>
    <PrivateRoute path="/infant/child/:health_id" setHelpId={props.setHelpId} component={ChildList} exact />	
    <PrivateRoute path="/infant/child/form/:journey_profile_id/:id?" setHelpId={props.setHelpId} component={ChildForm} exact />	
    <PrivateRoute path="/infant/family/:id" setHelpId={props.setHelpId} component={HealthJourneyForm} exact />	
    <PrivateRoute path="/infant/family/form/:journey_profile_id/:id?" setHelpId={props.setHelpId} component={FamilyForm} exact />	
  	<PrivateRoute path="/health/list/facilities/:organization_id/:subscription_id" component={FacilityList} exact />
  	<PrivateRoute path="/health/facility/:organization_id/:id?" component={FacilityForm} exact />
    <PrivateRoute path="/health/chart/:health_id" component={GrowthChart} exact />
    <PrivateRoute path="/health/chart/:health_id/:index" component={GrowthChart} exact />
    <PrivateRoute path="/health/path/:health_id" searchString={props.searchString} component={InfantPath} exact />
    <PrivateRoute path="/health/path" component={InfantPathHome} exact />
    <PrivateRoute path="/infant/activity/form/:journey_profile_id/:id?" searchString={props.searchString} component={ActivityForm} exact />
    <PrivateRoute path="/infant/list/counseling"  searchString={props.searchString} component={CounselingList} exact/>
    <PrivateRoute path="/templates/counselings" searchString={props.searchString} component={CounselingList} exact/>
    <PrivateRoute path="/infant/list/counseling/reel/:health_id" searchString={props.searchString} component={CounselingReelList} exact/>
    <PrivateRoute path={["/infant/counseling/form/:id?", "/infant/counseling/form/:form_type/:form_id/:id?"]} component={CounselingForm} exact />
    <PrivateRoute path="/infant/chart/state" component={ReportsPage} exact />
    <PrivateRoute path="/infant/measurements" component={InstantMeasurement} exact />
    <PrivateRoute path="/health/rechart/:health_id" component={WHOChart} exact />
    <PrivateRoute path="/health/sub_facilities/:organization_id/:medical_facility_id" component={SubFacilityTable} exact />
    <PrivateRoute path="/health/sub_facility/:organization_id/:medical_facility_id/:id?" component={SubFacilityForm} exact />
  </Fragment>
)
/*
    <PrivateRoute path={["/infant/activities/:health_id","/infant/weekly/:health_id"]} searchString={props.searchString} setHelpId={props.setHelpId} component={ActivityList} exact />

*/
export default InfantJourneyRouter;