import React, {useState, Fragment, useContext, useEffect} from 'react'
import FilterSlider from "../Common/FilterSlider"
import RoleAndUserFilter from '../NotifiableRoles/RoleAndUserFilter'
import {OrgFilter, UserRoleFilter} from '../../constants/DataSourceList'
import {CheckBox} from "../Common/FormInput"
import useModal from '../../hooks/useModal'
import SortDirection from '../../components/Common/SortDirection'
import useSort from '../../hooks/useSorting'

const sortColumns = [
  {label:'ID', sortColumn:'record_id'},
  {label:'IPA Type', sortColumn:'form_id'},
  {label:'User', sortColumn:'updated_by'},
  {label:'Date', sortColumn:'updated_at'}
]

const Filters = ({filter, setFilter, dataSource, currentUser, getIPA, isTemplate, requestParams}) =>{
  const {sort} = useSort(requestParams, getIPA)

  const toggleFilter = (value, name) => {
    if(name === 'assignment_filter'){
      if(value === 'no_org_mapping'){
        delete filter.assigned_to_organization_id
      }else if(value === 'no_alert_recipient'){
        delete filter.role_id
        delete filter.user_id
      }
    }

    if(value!=null && value != filter[name]){
      setFilter((name === 'form_id')?{[name]:value}:{...filter, [name]:value})
    }else{
      delete filter[name]
      setFilter({...filter})
    }
  }

  const filterExists = (value, name) => filter[name] == value;
  
  return (
    <div className="filter-box-scroll p-5">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Sort
        </h5>
        <div>
          {sortColumns.map((c, i)=>
            <div onClick={()=>sort(c.sortColumn)} className="pill white bg-lgrey" key={i}>
              {c.label}     
              <SortDirection sort_column={requestParams.sort_column} column={c.sortColumn} 
              reverse={requestParams.reverse}/>
            </div>
          )}
        </div>
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Assignment Filter
        </h5>

        <CheckBox list={isTemplate?OrgFilter:UserRoleFilter} 
          labelKey="label" valueKey="sid" 
          toggle={toggleFilter} exists={filterExists} 
          name="assignment_filter"/> 
      </div>

      {isTemplate && filter.assignment_filter !== 'no_org_mapping' &&
        <div className="bg-white">
          <h5 className="font-16 text-center bg-highlight white">
            Organizations
          </h5>

          <CheckBox list={currentUser.organizations || []} 
            labelKey="name" valueKey="id" 
            toggle={toggleFilter} exists={filterExists} 
            name="assigned_to_organization_id"/> 
        </div>
      }
        
      {!isTemplate &&
        <RoleAndUserFilter toggle={toggleFilter} exists={filterExists}/>
      }
        
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Language
        </h5>

        <CheckBox list={dataSource.languages?.options || []} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="language"/> 

        <small>{dataSource.languages.id}</small>
      </div>

      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          IPA Types
        </h5>

        <CheckBox list={dataSource.journey_ipa_types?.options || []} 
          labelKey="label" valueKey="ipa_child_form" 
          toggle={toggleFilter} exists={filterExists} 
          name="form_id"/> 

        <small>{dataSource.journey_ipa_types.id}</small>
      </div>

      {filter.form_id == 506 &&
        <div className="bg-white">
          <h5 className="font-16 text-center bg-highlight white">
            {dataSource.child_dev_stage.label}
          </h5>

          <CheckBox list={dataSource.child_dev_stage?.options || []} 
            labelKey="label" valueKey="value" 
            toggle={toggleFilter} exists={filterExists} 
            name="child_dev_stage"/> 

          <small>{dataSource.child_dev_stage.id}</small>
        </div>
      }
      
      {filter.form_id == 563 &&
        <div className="bg-white">
          <h5 className="font-16 text-center bg-highlight white">
            {dataSource.pregnancy_stage.label}
          </h5>

          <CheckBox list={dataSource.pregnancy_stage?.options || []} 
            labelKey="label" valueKey="value" 
            toggle={toggleFilter} exists={filterExists} 
            name="pregnancy_stage"/> 

          <small>{dataSource.pregnancy_stage.id}</small>
        </div>
      }
    </div>
  )
}

const FilterBtn = (props) =>{
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const filterCount = Object.keys(props.filter).length
  
  return(
    <>
      <span data-tip="Filter" 
        className="bg-highlight round-btn float-right m-l-15 m-t-4" 
          onClick={()=>toggleFilterModal()}>
            <i className="fas fa-sliders-h"/>
            {filterCount > 0 ? <span className="badge-2 bg-black-light-1 up">{filterCount}</span>:''}
      </span>

      {isFilterOpen &&
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={toggleFilterModal}
          {...props}/>
      }
    </>
  )
}

export {Filters, FilterBtn};