import React, {Fragment, useState, useEffect, useMemo, useContext} from "react"
import {Link, useHistory} from "react-router-dom"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../../services/CommonService"
import {PillDsField, PillDsList} from "../../../UserRoles/NotificationPill"
import ReactTooltip from "react-tooltip"
import ChildGrowth from "../../../../../constants/ChildGrowthDataPoints"
import SortDirection from '../../../../../components/Common/SortDirection'
import useSort from '../../../../../hooks/useSorting'
import ActivityService from "../../Activities/ActivityService"
import PreLoadSpinner from "../../../../../components/PreLoadSpinner"
import GenericModal from "../../../../../components/Modals/GenericModal"
import ActivityForm from '../../../MotherInfantJourney/Activities/Form/ActivityForm'
import useModal from "../../../../../hooks/useModal"
import NutritionCareNotification from '../../../../NutritionCares/NutritionCareNotification'
import UploadForm from '../../../MotherInfantJourney/Activities/Form/UploadForm'
import DynamicTable from '../../../../Common/DynamicTable'
import {InfantGrowthZscoreLabel} from '../../../../../constants/DataSourceList'
import { CTTooltip, CueTip } from "../../../../Common/CueTooltip"
import PreScheduleAlert from "../../../../PreScheduleEvent/List/PreScheduleAlert"
import moment from '../../../../../../node_modules/moment/min/moment.min.js'
import {FontAwesomeBtn} from "../../../../Common/Button"
import {OptionsObjList} from "../../../../Common/FormInput"
import { LocalizeContext } from "../../../../../contexts/Localize/LocalizeStateProvider"
import {usePageLocale} from "./../../../../../hooks/useLocale"
import {DraftBadge} from "../../../UserRoles/NotificationPill"
import {ActivityCard} from "./ActivityCard"
import {useActivityHook} from "./useActivityHook"
import PaginationSM from '../../../../Common/PaginationSM'
import {EmptyRowAlert} from '../../../../Common/TableView'

let requestParams = null
/*let activityForm = {
  prenatal_care: 507,
  growth_measurement: 503,
  feeding_assessment: 502,
  assess_cf: 504,
}*/
let actFormProps = null
let selectedItem = null
//let todayActivityItem = null
const InfantActivityTable = (props) => {

  let history = useHistory()
  const {WarningStyle} = ChildGrowth

  const {toggleMenuModal, journeyProfile, searchString, componentName, dataSource, timeFilter, activityReloadTab} = props
  const {child_form:formId} = dataSource

  const {state: { user: currentUser }, dispatch} = useContext(AuthContext)
  //const {localeState:{casePortalPage}, localeDispatch} = useContext(LocalizeContext)

  const [loading, setLoading] = useState(true)
  const [activities, setActivities] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState({})
  const [formFields, setFormFields] = useState()
  const [tableFields, setTableFields] = useState()
  const [filter, setFilter] = useState({
    'label': timeFilter?.options[0]?.label, 
    'date_type':timeFilter?.options[0]?.sid
  })

  const { isOpen:isInfantActivityFormOpen, toggleModal:toggleInfantActivityModal } = useModal()
  const { isOpen:isNutritionCareOpen, toggleModal:toggleNutritionCareModal } = useModal()
  const { isOpen:isAssessMenuOpen, toggleModal: toggleAssessMenuModal } = useModal()
  const { isOpen:isGrowthMeasureUploadOpen, toggleModal:toggleGrowthMeasureUploadModal } = useModal()
  const { isOpen:isPreScheduleOpen, toggleModal: togglePreScheduleModal } = useModal()
  const { isOpen:isActivityCardOpen, toggleModal: toggleActivityCardModal } = useModal()

  const {labelLocale} = usePageLocale(currentUser.current_locale, 'casePortalPage')
  const {labelLocale:tableHeadLocale} = usePageLocale(currentUser.current_locale, 'commonTableHeaders')
  const {child, setChild, ageFormat, getBgColorByZscore, getBgColorByZscoreLable} = useActivityHook()

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      page: currentpage,
      per_page: 15,
      sort_column: 'updated_at',
      sort_direction: 'desc',
      journey_profile_id: journeyProfile.id,
      search: searchString,
      filter: filter,
      form_fields_params: {
        ids:[formId],
        locale: currentUser.current_locale,
      }
    }

    if([503, 507].includes(formId)){
      requestParams.sort_column = 'scheduled_date'
    }else if(formId == 583){
      requestParams.sort_column = 'data.record_date_formatted_date'
    }

    if(componentName === 'assess_bf'){
      setLoading(false)
    }else{
      getActivityList()
    } 
  }, [searchString, currentpage, filter])

  useEffect(()=>{
    if(activityReloadTab === dataSource.sid)
      getActivityList()
  }, [activityReloadTab])

  let getActivityList = () => {
    setLoading(true)
    requestParams.activity_form_id = formId
    if(formFields) delete requestParams.form_fields_params
    
    ActivityService.getAll(requestParams).then(({status, data}) => {
      if (status === 200) {
        requestParams.totalItems = data.meta.total
        //journey_category = res.data.meta.journey_category;
        //CheckAccess.userAccess(authState.user, journey_category, res.data.meta.invited_journey_member)
        setAge(data.child)
        setChild(data.child)
        setActivities(data.activities)
        setPagemeta(data.meta)
        if(data.form_fields?.[formId]){
          setFormFields(data.form_fields[formId])
          setTableFields(data.form_fields[formId].filter((f) => f.show_in_table_view==='true'))
        }
        setLoading(false)
        //if(todayActivityItem)openPreSchedule(todayActivityItem)
      }
    })
  }

  const {sort} = useSort(requestParams, getActivityList)

  const onRangeFilterChange = (selectedIndex) =>{
    const f = timeFilter.options[selectedIndex]
    setFilter({
      label:f.label,
      date_type:f.sid
    })
  } 

  const setAge = (child) =>{
    if(!child)return;
    const data = child.data
    child.age_in_weeks =  Cs.compareDate(data.dob_baby_formatted_date, null, 'weeks', true)
    child.age_in_month =  Cs.compareDate(data.dob_baby_formatted_date, null, 'months', true, true)?.toFixed(2)
  }

  const routeActivityForm = (e, id, schedule_id, state) =>{
    e && e.stopPropagation()
    actFormProps = Cs.routeInfantPath(dataSource, {}, journeyProfile, history, null, id)
    if(child){
      let c = child
      c.data.baby_age = Cs.getAge(c.data.dob_baby_formatted_date)
      actFormProps.location.state = {
        child_fk_id: c.record_id, 
        dob_baby_formatted_date: c.data.dob_baby_formatted_date, 
        id_mother: journeyProfile.data.cell_number, 
        baby_age: c.data.baby_age,
        birth_weight: c.data_source.weight.label,
        birth_height: c.data_source.height.label
      }
    }

    if(dataSource.sid === 'growth_measurement' && state){
      const d = Cs.formatUpdateDate(state.visit_date ,'YYYY-MM-DD')
      actFormProps.location.state.visit_date = d
      actFormProps.location.state.visit_date_formatted_date = d 
    }

    actFormProps.match.params.schedule_id = schedule_id
    actFormProps.component = ActivityForm
    toggleInfantActivityModal()
  }

  const onFormSubmit = (activity={}) =>{
    if(activity.show_nutrition_care){
      openNotification(null, activity)
    }
    getActivityList()
    toggleInfantActivityModal()
  }

  /*const tabChange = function(key) {
    if(key=='nutrition_intake_bf'){
      toggleAssessMenuModal()
      setActivityType(key)
    }else{
      setActivityType(key)  
    }
  }*/

  const openNotification = (e, activity) =>{
    e && e.stopPropagation()
    selectedItem = activity
    toggleNutritionCareModal()
  }

  const openUploadForm = (e, activity) =>{
    e.stopPropagation()
    selectedItem = activity
    toggleGrowthMeasureUploadModal()
  }

  const openPreSchedule = (activity) => {
    if(activity.pre_schedule_id){
      selectedItem = activity
      togglePreScheduleModal()
    }
  }

  const routeAssess = (path) =>{
    Cs.routeInfantPath(path, child, journeyProfile, history)  
  }

  const openActivityCard = (activity) =>{
    selectedItem = activity
    toggleActivityCardModal()
  }

  const date = (client_id, row) => {
    return <>
      {row.id
        ?row.data?.record_date
        :Cs.formatUpdateDate(row.scheduled_date, 'MMM D YYYY')
      }
    </>
  }
    
  const PrenatalCareTable = () =>{
    const specialFields = [
      {label:'Id', client_id:'record_id'},
      {label:tableHeadLocale(1), client_id:'data.record_date', ele:date},
      {label:tableHeadLocale(2), client_id:'author_name'}
    ]
    
    const OptionsEle = ({item}) => (
      <td className="text-center">
        <span id="record_edit" onClick={e=>routeActivityForm(e, item.id, item.schedule_id)} className="fas fa-edit m-r-15 font-18 text-muted"/>  
        { item.nutrition_care_alert_count > 0 &&
          <span id="record_alert" onClick={e=>openNotification(e, item)} className="fas fa-envelope m-r-15 font-18 text-muted">
            <span className="badge-2 up bg-lred">{item.nutrition_care_alert_count}</span> 
          </span>
        }    
      </td>
    )
    
    return(
      <div className="table-responsive table-fix-head">        
        <button id="add_measure_growth" data-tip="Record details in a new entry" 
          onClick={e=>routeActivityForm(e)} 
          type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
          {labelLocale(3)}
        </button>

        <span className="tooltip pull-right m-r-15" id="date_filter">
          <select onChange={(e)=>onRangeFilterChange(e.target.selectedIndex)}
            className="form-control ht-33 fw500 mw-150"
            defaultValue={filter.label}>
            <OptionsObjList list={timeFilter?.options} id_key="label" label_key="label" user_locale={currentUser.current_locale}/>
          </select>
          <CueTip tooltip="View data for a calendar range" positionCss="bottom"/>
        </span>

        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={tableFields} specialFields={specialFields} 
          formDataList={activities} tClass="table" isSortable={false} 
          OptionsEle={OptionsEle} currentLocale={currentUser.current_locale}
          onRowClick={openActivityCard} isSortable={false}/>
      </div>  
    )
  }
  
  /*
    <table className="shadow-small">
        <thead>
          <tr className="white bg-black">
            <th onClick={e=>sort('record_id')} className='text-center'>
              Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('updated_at')} className='text-center'>
              Date 
              <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.prenatal_stage')} className='text-center'>
              Prenatal Stage
              <SortDirection sort_column={requestParams.sort_column} column="data.prenatal_stage" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.mother_weight')} className='text-center'>
              Weight
              <SortDirection sort_column={requestParams.sort_column} column="data.mother_weight" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.note')} className='text-center'>
              Note
              <SortDirection sort_column={requestParams.sort_column} column="data.note" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('updated_by')} className='text-center'>
              By
              <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('created_at')} className='text-center'>
              Actions
              <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {prenatalCareTableData}
        </tbody>
      </table>
  */

  const prenatalCareTableData = activities.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <tr key={k} onClick={e=>routeActivityForm(e, a.id)}>
        <td className="text-center" data-title="Id">
          {a.record_id}
          <DraftBadge isDraft={a.draft}/>
        </td>

        <td className="text-center">
          {data.record_date}
        </td>
       
        <td className="text-center">
          {ds.prenatal_stage?.label}
        </td>

        <td className="text-center">
          {ds.mother_weight?.label}
        </td>
        
        <td className="text-center">
          <span dangerouslySetInnerHTML={{ __html: a.data?.notes}}></span>
        </td>

        <td className="text-center">
          {a.author_name}
          <div>{a?.member_role?.role}</div>
        </td>

        <td className="text-center">
          {a.id && Cs.isToday(a.updated_at) &&
            <span onClick={e=>routeActivityForm(e, a.id)} className="fas fa-edit m-r-15 font-18 text-muted" /> 
          }
        </td>
      </tr>
    )
  })

  const GrowthMeasureTable = () =>(
    <div className="table-responsive table-fix-head">
      <FontAwesomeBtn id="add_measure_growth" tooltip="Record details in a new entry" 
        iconCss="fa fas fa-plus" isResponsive={false} name={labelLocale(3)}
        css="pull-right m-b-5 m-r-15 lh-17" colorCss="btn-green"
        onSelect={(e)=>routeActivityForm(e)}>
      </FontAwesomeBtn>

      <FontAwesomeBtn id="who_charts" tooltip="WHO Growth Charts" 
        iconCss="fa fas fa-chart-line" isResponsive={false} name={labelLocale(2)}
        css="pull-right m-b-5 m-r-15 lh-17" colorCss="bg-highlight"
        onSelect={()=>routeAssess({sid:'wellness'})}>
      </FontAwesomeBtn>

      <span className="tooltip pull-right m-r-15" id="date_filter">
        <select onChange={(e)=>onRangeFilterChange(e.target.selectedIndex)}
          className="form-control ht-33 fw500 mw-150"
          defaultValue={filter.label}>
          <OptionsObjList list={timeFilter?.options} id_key="label" label_key="label" user_locale={currentUser.current_locale}/>
        </select>
        <CueTip tooltip="View data for a calendar range" positionCss="bottom"/>
      </span>
        
      <table className="table">
        <thead>
          <tr>
            <th className='text-center'>
              Id 
            </th>
            
            <th className='text-center'>
              Location
            </th>

            <th className='text-center'>
              Date 
            </th>

            <th className='text-center'>
              Age
            </th>
              
            <th className='text-center'>
              W
            </th>
            <th className='text-center'>
              H
            </th>
            <th className='text-center'>
              WFH
            </th>
            <th className='text-center'>
              Visit Instructions
            </th>
            <th className='text-center'>
              By
            </th>
            <th className='text-center'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <GrowthMeasureTableData/>
        </tbody>
      </table>
    </div>
  )

  const GrowthMeasureTableData = () => activities.map((a, i) => 
    <GrowthMeasureRowData a={a} key={i}/>
  )

  const GrowthMeasureRowData = ({a}) =>{
    let ds = a.data_source || {}
    let data = a.data || {}
    let scheduleDayDiff = null
    let msg = '' 
    if(!a.id){
      scheduleDayDiff = Cs.compareDate(
        a.scheduled_date, moment().format('YYYY-MM-DD'), 'days'
      )
      //todayActivityItem = scheduleDayDiff == 0?a:null
      msg =  scheduleDayDiff >= 0 ? 
        (a.notification_text || 'A visit to take new measurements is scheduled for this date')
        :
        'Measurement data from this visit was not submitted.'
    }
    
    data.age_ago = useMemo(() => ageFormat(a.id, a.scheduled_date, data), [a.id]);
    const weightZscoreStyle = useMemo(() => getBgColorByZscore(data.weight_zscore, data.weight_zscore_label), [data.weight_zscore, data.weight_zscore_label]);
    const heightZscoreStyle =  useMemo(() => getBgColorByZscore(data.height_zscore, data.height_zscore_label), [data.height_zscore, data.height_zscore_label]);
    const wfhZscoreStyle =  useMemo(() => getBgColorByZscore(data.wfh_zscore, data.wfh_zscore_label), [data.wfh_zscore, data.wfh_zscore_label]);

    /*const weightZscoreStyle = {
      backgroundColor:InfantGrowthZscoreLabel[data.weight_zscore_label]?.backgroundColor
    }
    const heightZscoreStyle = {
      backgroundColor:InfantGrowthZscoreLabel[data.height_zscore_label]?.backgroundColor
    }
    const wfhZscoreStyle = {
      backgroundColor:InfantGrowthZscoreLabel[data.wfh_zscore_label]?.backgroundColor
    }*/
    
    return(
      <tr>
        <td className="text-center" onClick={()=> openActivityCard(a)}>
          {a.record_id}
          {scheduleDayDiff==0?<div className="dot-highlight"/>:null}
          <DraftBadge isDraft={a.draft}/>
        </td>

        <td className="text-center mw-50">
          {ds.location?.label}
        </td>

        <td className="text-center">
          {a.id
            ?data.visit_date
            :Cs.formatUpdateDate(a.scheduled_date, 'MMM D YYYY')
          }
        </td>
        
        <td className="text-center">
          {data.age_ago}
        </td>

        <td className="text-center">
          {ds.baby_weight && 
            <ul className="flex aligncenter coldir badge-boxes">
              <li className="badge m-5-0 white" style={weightZscoreStyle.r1}>{ds.baby_weight?.label} kgs</li>
              <li className="badge m-5-0 white" style={weightZscoreStyle.r2}>{data.weight_zscore} Z</li>
              <li className="badge m-5-0 white bg-purple">{Cs.round(data.weight_percentile)}%</li>
              <li className="badge m-5-0 white d-hide" style={weightZscoreStyle.r4}>{data.weight_zscore_label}</li>
            </ul>
          }
        </td>

        <td className="text-center">
          {ds.height_baby &&
            <ul className="flex aligncenter coldir badge-boxes">
              <li className="badge white m-5-0" style={heightZscoreStyle.r1}>{ds.height_baby?.label} cms</li>
              <li className="badge white m-5-0" style={heightZscoreStyle.r2}>{data.height_zscore} Z</li>
              <li className="badge white m-5-0 bg-purple">{Cs.round(data.height_percentile)}%</li>
              <li className="badge white m-5-0 d-hide" style={heightZscoreStyle.r4}>{data.height_zscore_label}</li>
            </ul>
          }  
        </td>

        <td className="text-center">
          {data.wfh && 
            <ul className="flex aligncenter coldir badge-boxes">
              <li className="badge white m-5-0" style={wfhZscoreStyle.r1}>{data.wfh}</li>
              <li className="badge white m-5-0" style={wfhZscoreStyle.r2}>{data.wfh_zscore} Z</li>
              <li className="badge white m-5-0 bg-purple">{Cs.round(data.wfh_percentile)}%</li>
              <li className="badge white m-5-0 d-hide" style={wfhZscoreStyle.r4}>{data.wfh_zscore_label}</li>
            </ul>
          }
        </td>

        <td className="text-center tooltip" onClick={()=>openPreSchedule(a)}>
          {(msg.length > 0 || a.pre_schedule_id) &&  
            <>
              <span className="fa fa-info-circle font-18 text-gray"/>
              <CueTip tooltip={msg}/>
            </>
          }
        </td>

        <td className="text-center mw-75">
          <div>{a.author_name}</div>
          <div>{a?.member_role?.role}</div>
          <div>{Cs.formatUpdateDate(a.updated_at, 'MMM D YYYY hh:mm A')}</div>
        </td>

        <td className="text-center">
          {a.id && Cs.isToday(data.visit_date_formatted_date || a.scheduled_date) &&
            <Fragment>
              <span id="record_edit" onClick={e=>routeActivityForm(e, a.id, a.schedule_id, {visit_date:a.scheduled_date})} className="fas fa-edit m-r-15 m-t-10 font-18 text-muted" />       
              {/*<span id="record_upload" onClick={e=>openUploadForm(e, a)} className="fas fa-camera m-r-15 m-t-10 font-18 text-muted" />*/}
            </Fragment>
          } 
          {a.nutrition_care_alert_count > 0 &&
            <span id="record_alert" onClick={e=>openNotification(e, a)} className="fas fa-envelope m-r-15 m-t-10 font-18 text-muted">
              <span className="badge-2 up bg-lred">{a.nutrition_care_alert_count}</span> 
            </span>
          }
          {!a.id && Cs.compareDate(a.scheduled_date, null, 'days', false, true) <= 0 && 
            <span 
              onClick={e=>routeActivityForm(e, a.id, a.schedule_id, {visit_date:a.scheduled_date})} 
              className="fas fa-plus m-r-15 m-t-10 font-18"/>
          }                             
        </td>
      </tr>
    )
  }

  const FeedingAssessmentTable = () =>(
    <div className="table-responsive table-fix-head">
      <button id="add_measure_growth" data-tip="Record details in a new entry" 
        onClick={e=>routeActivityForm(e)}
        type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
          {labelLocale(3)}
      </button>
      <table className="table">
        <thead>
          <tr>
            <th className='text-center'>
              Id 
            </th>
            <th className='text-center'>
              Date 
            </th>
            <th className='text-center'>
              Mother Training
            </th>
            <th className='text-center'>
              Breast milk given
            </th>
            <th className='text-center'>
              Breast crawl performed 
            </th>
            <th className='text-center'>
              Breast fed in the hospital
            </th>
            <th className='text-center'>
              By
            </th>
            <th className='text-center'>
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {feedingAssessmentTableData}
        </tbody>
      </table>
    </div>
  )

  const feedingAssessmentTableData = activities.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <tr key={k}>
        <td className="text-center" onClick={()=> openActivityCard(a)}>
          {a.record_id}
          <DraftBadge isDraft={a.draft}/>
        </td>

        <td className="text-center">
          {data.record_date}
        </td>
       
        <td className="text-center">
          <div>{ds?.mother_training?.label}</div>
        </td>

        <td className="text-center">
          <div>{ds?.first_hour?.label}</div>
        </td>
        
        <td className="text-center">
          <div>{ds?.crawl_at_delivery?.label}</div>
        </td>

        <td className="text-center">
          <div>{ds?.exclusive_bf?.label}</div>
        </td>

        <td className="text-center">
          {a.author_name}
          <div>{a?.member_role?.role}</div>
        </td>

        <td className="text-center">
          {a.id && Cs.isToday(data.date_cf_formatted_date) &&
            <span onClick={e=>routeActivityForm(e, a.id)} 
              className="fas fa-edit m-r-15 font-18 text-muted" />
          }
        </td>
      </tr>
    )
  })

  /*const AssessCFTable = () =>(
    <div className="table-responsive table-fix-head">
      <button id="add_measure_growth" data-tip="Record details in a new entry" 
        onClick={e=>routeActivityForm(e)}
        type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
          Add
      </button>
      <table className="table">
        <thead>
          <tr>
            <th onClick={e=>sort('record_id')} className='text-center'>Id 
              <SortDirection sort_column={requestParams.sort_column} column="record_id" reverse={requestParams.reverse}/> 
            </th>
            <th onClick={e=>sort('updated_at')} className='text-center'>Date 
              <SortDirection sort_column={requestParams.sort_column} column="updated_at" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.baby_weight')} className='text-center'>W (kgs)
              <SortDirection sort_column={requestParams.sort_column} column="data.baby_weight" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.height_baby')} className='text-center'>
              H (cms)
              <SortDirection sort_column={requestParams.sort_column} column="data.height_baby" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.muac')}className='text-center'>
              MUAC
              <SortDirection sort_column={requestParams.sort_column} column="data.muac" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.if_bf')}className='text-center'>
              If BF
              <SortDirection sort_column={requestParams.sort_column} column="data.if_bf" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.sam')}className='text-center'>
              SAM
              <SortDirection sort_column={requestParams.sort_column} column="data.sam" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('data.mam')}className='text-center'>
              MAM
              <SortDirection sort_column={requestParams.sort_column} column="data.mam" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('updated_by')} className='text-center'>
              By
              <SortDirection sort_column={requestParams.sort_column} column="updated_by" reverse={requestParams.reverse}/>
            </th>
            <th onClick={e=>sort('created_at')} className='text-center'>
              Actions
              <SortDirection sort_column={requestParams.sort_column} column="created_at" reverse={requestParams.reverse}/>
            </th>
          </tr>
        </thead>
        <tbody>
          {AssessCFTableData}
        </tbody>
      </table>
    </div>
  )

  const AssessCFTableData = activities.map((a, k) => {
    let ds = a.data_source || {}
    let data = a.data || {}

    return(
      <tr key={k} onClick={e=>routeActivityForm(e, a.id)}>
        <td className="text-center" data-title="Id">{a.record_id}</td>

        <td className="text-center">
          {Cs.formatUpdateDate(data.visit_date_formatted_date, 'MMMM D YYYY')}
        </td>
       
        <td className="text-center">
          <div style={WarningStyle[data.weight_note]}>{ds.baby_weight?.label}</div>
        </td>

        <td className="text-center">
          <div style={WarningStyle[data.height_note]}>{ds.baby_height?.label}</div>
        </td>
        
        <td className="text-center">
          <div>{data.muac}</div>
        </td>

        <td className="text-center">
          <div>{ds?.if_bf?.label}</div>
        </td>

        <td className="text-center">
          <div>{ds?.sam?.label}</div>
        </td>

        <td className="text-center">
          <div>{ds?.mam?.label}</div>
        </td>

        <td className="text-center">
          {a.author_name}
        </td>

        <td className="text-center">
          {a.id && Cs.isToday(data.visit_date_formatted_date) &&
            <Fragment>
              <span id="record_edit" onClick={e=>routeActivityForm(e, a.id)} className="fas fa-edit m-r-15 font-18 text-muted" /> 
            </Fragment>
          }
          { a.nutrition_care_alert_count > 0 &&
            <span id="record_alert" onClick={e=>openNotification(e, a)} className="fas fa-envelope m-r-15 font-18 text-muted">
              <span className="badge-2 up bg-lred">{a.nutrition_care_alert_count}</span> 
            </span>
          }
        </td>
      </tr>
    )
  })*/

  const AssessCFTable = () =>{

    const specialFields = [
      {label:'Id', client_id:'record_id'},
      {label:'Date', client_id:'data.record_date', ele:date},
      {label:'By', client_id:'author_name'}
    ]
    
    const OptionsEle = ({item}) => (
      <td className="text-center">
        <span id="record_edit" 
          onClick={e=>routeActivityForm(e, item.id)} 
          className="fas fa-edit m-r-15 font-18 text-muted"/> 

          {item.nutrition_care_alert_count > 0 &&
            <span id="record_alert" onClick={e=>openNotification(e, item)} 
              className="fas fa-envelope m-r-15 m-t-15 font-18 text-muted">
              <span className="badge-2 up bg-lred">{item.nutrition_care_alert_count}</span> 
            </span>
          }
      </td>
    )
    
    return(
      <div className="table-responsive table-fix-head">        
        <button id="add_immunization" data-tip="Record Assess CF details" 
          onClick={e=>routeActivityForm(e)} 
          type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
          {labelLocale(3)}
        </button>

        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={tableFields} specialFields={specialFields} 
          formDataList={activities} tClass="table" isSortable={false}
          OptionsEle={OptionsEle} onRowClick={openActivityCard}/>
      </div>  
    )
  }

  const ImmunizationTable = () =>{
    const specialFields = [
      {label:'Id', client_id:'record_id'},
      {label:'Date', client_id:'data.record_date', ele:date},
      {label:'By', client_id:'author_name'}
    ]
    
    const OptionsEle = ({item}) => (
      <td className="text-center">
        <span id="record_edit" 
          onClick={e=>routeActivityForm(e, item.id)} 
          className="fas fa-edit m-r-15 font-18 text-muted"/>  
      </td>
    )
    
    return(
      <div className="table-responsive table-fix-head">        
        <button id="add_immunization" data-tip="Record Immunization details" 
          onClick={e=>routeActivityForm(e)} 
          type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
          Add
        </button>

        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={tableFields} specialFields={specialFields} 
          formDataList={activities} tClass="table" isSortable={false}
          OptionsEle={OptionsEle} onRowClick={openActivityCard}/>
      </div>  
    )
  }

  const PeriodicCheck = () =>{

    const specialFields = [
      {label:'Id', client_id:'record_id'},
      {label:'Date', client_id:'data.record_date', ele:date},
      {label:'By', client_id:'author_name'}
    ]
    
    const OptionsEle = ({item}) => (
      <td className="text-center">
        <span id="record_edit" 
          onClick={e=>routeActivityForm(e, item.id)} 
          className="fas fa-edit m-r-15 font-18 text-muted"/>  
      </td>
    )
    
    return(
      <div className="table-responsive table-fix-head">        
        <button id="add_immunization" data-tip="Record Assess CF details" 
          onClick={e=>routeActivityForm(e)} 
          type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
          {labelLocale(3)}
        </button>

        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={tableFields} specialFields={specialFields} 
          formDataList={activities} tClass="table" isSortable={false} 
          OptionsEle={OptionsEle} onRowClick={openActivityCard}/>
      </div>  
    )
  }

  const ProteinIntake = () =>{

    const specialFields = [
      {label:'Id', client_id:'record_id'},
      {label:'Date', client_id:'data.record_date', ele:date},
      {label:'By', client_id:'author_name'}
    ]
    
    const OptionsEle = ({item}) => (
      <td className="text-center">
        <span id="record_edit" 
          onClick={e=>routeActivityForm(e, item.id)} 
          className="fas fa-edit m-r-15 font-18 text-muted"/>  

        {item.nutrition_care_alert_count > 0 &&
          <span id="record_alert" onClick={e=>openNotification(e, item)} 
            className="fas fa-envelope m-r-15 m-t-15 font-18 text-muted">
            <span className="badge-2 up bg-lred">{item.nutrition_care_alert_count}</span> 
          </span>
        }
      </td>
    )
    
    return(
      <div className="table-responsive table-fix-head">        
        <button id="add_immunization" data-tip="Record Protein Intake" 
          onClick={e=>routeActivityForm(e)} 
          type="button" className="btn btn-icon-add btn-sm text-white pull-right m-b-5 m-r-15">
          {labelLocale(3)}
        </button>

        <DynamicTable requestParams={requestParams} onSort={sort} 
          formFields={tableFields} specialFields={specialFields} 
          formDataList={activities} tClass="table" isSortable={false} 
          OptionsEle={OptionsEle} onRowClick={openActivityCard}/>
      </div>  
    )
  }

  if(loading) return(<PreLoadSpinner/>)

  if(isActivityCardOpen){
    return <GenericModal 
      component={ActivityCard} 
      componentName={componentName}
      child={child}
      isOpen={isActivityCardOpen}
      toggleModal={toggleActivityCardModal}
      activity={selectedItem}
      formFields={formFields}/>
  }

  let components = {
    prenatal_care:PrenatalCareTable,
    growth_measurement:GrowthMeasureTable,
    feeding_assessment:FeedingAssessmentTable,
    assess_cf:AssessCFTable,
    immunization:ImmunizationTable,
    periodic_check:PeriodicCheck,
    protein_intake:ProteinIntake
  }

  const TagName = components[componentName];  

  if(TagName){
    return (
      <>
        <TagName {...props}/>
        <PaginationSM
          className="pagination-bar"
          currentPage={currentpage}
          totalItems={pagemeta.total}
          itemsPerPage={pagemeta.per_page}
          onPageChange={setCurrentpage}
          siblingCount={2}/>

        {activities.length === 0 &&
          <div className="text-center f16 m-t-15">
            {/*There is no data to display. Click "Add" or "+" to add details.*/}
            <EmptyRowAlert/>
          </div>
        }
                
        {isInfantActivityFormOpen && 
          <GenericModal toggleModal={toggleInfantActivityModal} 
          isPopupMode="true" onFormSubmit={onFormSubmit} 
          closeConfirmPopup={true} {...actFormProps} />
        }

        {isNutritionCareOpen && 
          <GenericModal component={NutritionCareNotification} 
          toggleModal={toggleNutritionCareModal} isOpen={isNutritionCareOpen}
          itemType="infant.activity" itemUuid={selectedItem.id} 
          closeIco="fas fa-chevron-down"/>
        } 

        {isGrowthMeasureUploadOpen && 
          <GenericModal component={UploadForm} 
          toggleModal={toggleGrowthMeasureUploadModal} 
          isOpen={isGrowthMeasureUploadOpen}
          itemType="infant.activity" item={selectedItem.id} 
          isPopupMode="true"/>
        }  

        {isPreScheduleOpen && 
          <GenericModal component={PreScheduleAlert} 
          title="Data to collect for the specified date and videos to watch"
          toggleModal={togglePreScheduleModal} isOpen={isPreScheduleOpen}
          preScheduleId={selectedItem.pre_schedule_id}
          child={child} journeyProfile={journeyProfile} 
          schedule={selectedItem} isPopupMode="true"/>
        }
      </>
    )
  }else{
    return <div>None</div>
  }

}

export default InfantActivityTable;