import React, { Fragment, useState, useEffect, useContext } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import useStyle from "../../../hooks/useStyle"
import CTLazyLoad from "../../Common/CTLazyLoad"
import { CTTooltip, CueTip } from "../../Common/CueTooltip"
import {usePageLocale} from '../../../hooks/useLocale'
import { LocalizeContext } from "../../../contexts/Localize/LocalizeStateProvider"
import {FontAwesomeBtn} from "../../Common/Button"
import useModal from "../../../hooks/useModal"
import DataSourceMenu from "../../Modals/DataSourceMenu"
import Cs from "../../../services/CommonService"
import useOnLine from '../../../hooks/useOnLine'
import HealthCategoryService from '../HealthCategoryService'
import {PlannerVideoPlayer} from '../../Common/VideoPlayer'

const css = ['/css/tab-banner-layout.css']

const UpcomingDashboard = (props) => {
  
  const { state: {screen, user: currentUser}, dispatch} = useContext(AuthContext)
  const {localeDispatch} = useContext(LocalizeContext)
  
  const { labelLocale } = usePageLocale(currentUser.current_locale, 'journeyListPage')
  const {isOnline, isConnectionOk} = useOnLine();
  
  const [schedules, setSchedules] = useState([])
  const [draftCases, setDraftCases] = useState([])
  const [recentCases, setRecentCases] = useState([])
  const [uncheckedPlanners, setUncheckedPlanners] = useState([])
  const [selectedPlanner, setSelectedPlanner] = useState()

  const { isOpen: isJourneyMenuOpen, toggleModal: toggleJourneyMenuModal } = useModal()
  const { isOpen: isPlayerOpen, toggleModal: togglePlayerModal } = useModal()

  useEffect(()=>{
    get()
  }, [])

  const get = async () =>{
    const {status, data} = await HealthCategoryService.getUpcomingCaseInfo()
    if(status === 200){
      setSchedules(data.schedules)
      setDraftCases(data.draft_journeys)
      setRecentCases(data.recent_journeys)
      setUncheckedPlanners(data.planners)
    }
  }

  const routeJourneyForm = (ds) =>{
    Cs.routeJourneyForm(ds, props.history)
  }

  let routeJourney = (journey)=>{
    Cs.routeJourney(journey, props)
  }

  const openPlannerVideo = (planner) =>{
    setSelectedPlanner(planner)
    togglePlayerModal()
  }

  return (
    <CTLazyLoad css={css}>
      <div id="ct">
        <div className="app_content m-t-15">
          <div className="bg-white border-rounded-10 flex justspacebetween m-b-20 p10 m-t-20">
            <h2 className="m-r-30 f18-xss">
              
            </h2>
            <div className="flex aligncenter">
              <FontAwesomeBtn 
                name="Add" id="add_new_journey" 
                tooltip="Launch a new health journey" 
                onSelect={()=>toggleJourneyMenuModal()}
                css="m-r-5"
                iconCss="fas fa-plus" name={labelLocale(3)}>
              </FontAwesomeBtn>

              <FontAwesomeBtn 
                name="Language" tooltip="Language Menu" 
                onSelect={()=>toggleJourneyMenuModal()}
                css="m-r-5"
                iconCss="fas fa-globe" name={labelLocale(3)}>
              </FontAwesomeBtn>

              <FontAwesomeBtn 
                tooltip="Connectivity" 
                css="m-r-5" name={labelLocale(3)}
                iconCss={`${isOnline?'white':'red'} fas fa-wifi`}>
              </FontAwesomeBtn>

              <FontAwesomeBtn 
                tooltip="Connection Signal" 
                css="m-r-5" name={labelLocale(3)}
                iconCss={`${isConnectionOk?'white':'red'} fas fa-wifi`}>
              </FontAwesomeBtn>
            </div>
          </div>

          <div className="row">
              
            <div className="col-md-6">
              <div className="bg-white border-rounded-10 flex coldir m-b-20 p10">
                <h2 className="m-r-30 f20 f18-xss">
                  Draft Cases
                </h2>
                <ul className="m-t-15 f16 mih-155 scroll-x pointer">
                  {draftCases.map((s, key) =>
                    <li className="flex aligncenter br-btm-dgrey m-r-20 m-b-5 mih-155 scroll-x" key={key}>
                      <span className="far fa-bell lred m-r-15"/>
                      <div>
                        <div>{s.data.record_title}</div>
                        <div>{s.data.case_id}</div>
                      </div>
                      <span className="fas fa-expand ml-auto" onClick={()=>routeJourney(s)}/>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            
            <div className="col-md-6">  
              <div className="bg-white border-rounded-10 flex coldir m-b-20 p10">
                <h2 className="m-r-30 f20 f18-xss">
                  Last 3 Cases
                </h2>
                <ul className="m-t-15 f16 mih-155 scroll-x pointer">
                  {recentCases.map((s, key) =>
                    <li className="flex aligncenter br-btm-dgrey m-r-20 m-b-5" key={key}>
                      <span className="far fa-bell lred m-r-15"/>
                      <div>
                        <div>{s.data.record_title}</div>
                        <div>{s.data.case_id}</div>
                      </div>
                      <span className="fas fa-expand ml-auto" onClick={()=>routeJourney(s)}/>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>  

          <div className="row">
            <div className="col-md-6">
              <div className="bg-white border-rounded-10 flex coldir m-b-20 p10">
                <h2 className="m-r-30 f20 f18-xss">
                  Upcoming Calendar Cases
                </h2>
                <ul className="m-t-15 f16 mih-155 scroll-x pointer">
                  {schedules.map((s, key) =>
                    <li className="flex aligncenter br-btm-dgrey m-r-20 m-b-5" key={key}>
                      <span className="far fa-bell lred m-r-15"/>
                      <div>
                        <div>{s.data.title}</div>
                        <div>{Cs.formatUpdateDate(s.start)}</div>
                      </div>
                      <span className="fas fa-expand ml-auto"/>
                    </li>
                  )}
                </ul>
              </div>
            </div>

            <div className="col-md-6">
              <div className="bg-white border-rounded-10 flex coldir m-b-20 p10">
                <h2 className="m-r-30 f20 f18-xss">
                  Unchecked Guide Videos
                </h2>
                <ul className="m-t-15 f16 mih-155 scroll-x pointer">
                  {uncheckedPlanners.map((s, key) =>
                    <li className="flex aligncenter br-btm-dgrey m-r-20 m-b-5" key={key} onClick={()=>openPlannerVideo(s)}>
                      <span className="far fa-bell lred m-r-15"/>
                      <div>
                        <div>{s.data.name}</div>
                        <div>{s.data.notification_text}</div>
                      </div>
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isJourneyMenuOpen && 
        <DataSourceMenu toggleModal={toggleJourneyMenuModal} 
        datasourceList={currentUser.current_subscription?.data_source?.journeys}
        dataSourceKey='data' dataSourceId={3419}
        onSelect={routeJourneyForm}/>
      }

      {isPlayerOpen &&
        <PlannerVideoPlayer url={selectedPlanner.data.reference_url} toggleModal={togglePlayerModal}/>
      }
    </CTLazyLoad>
  )
}

export default UpcomingDashboard;
