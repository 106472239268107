import React, {Fragment, useContext, useEffect} from "react"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../../../services/CommonService"
import StepActivities from "../../StepActivities/StepActivities"
import env from "../../../../../env"
import { CTTooltip } from "../../../../Common/CueTooltip"
import { LinkFontAwesomeIcon, ChatGrayIcon, CheckListGrayIcon, HamburgerMenuIcon, MaximizeIcon } from "../../../../Common/ImageIcons"

const Planner = (props) => {

  let {activity, permission} = props
  activity.updated_at = Cs.formatUpdateDate(activity.updated_at)
  let data = activity.data
  let dataSource = activity.data_source
  const imgStyle = {height: '240px', width: '100%'}
  const cardStyle = {
    backgroundColor:data.background_color || data.header_background || "#607D8B"
  }
  
  if (!data) return null;

  if(data.reference_url){
    const params = new URL(data.reference_url).searchParams;
    data.img_url = params.get('v')
  }

  const Footer = () => {
    return(
      <div className="card-footer flex justspacebetween p20 aligncenter">
        <ul className="flex aligncenter">
          {permission.write &&
            <li className="m-r-25" data-tip="Active/Inactive">
              <LinkFontAwesomeIcon onSelect={e=> props.updateStatus(activity, 'draft')} 
              css={'font-18 white '+(activity.draft?'far fa-circle':'fas fa-check-circle')}/>
            </li>
          }
          <li className="m-r-25" data-tip="Post/View messages">
            <ChatGrayIcon onSelect={(e) => props.addNotes(e, activity)} 
            count={activity.comments_count || 0}/>
          </li>
          <li className="m-r-25" data-tip="Post/View checklist items">
            <CheckListGrayIcon onSelect={(e) => props.addChecklist(e, activity)} 
            count={activity.check_list_count || 0}/>
          </li>
          {permission.write &&
            <li className="m-r-25"  data-tip="Save video bookmarks">
              <LinkFontAwesomeIcon onSelect={(e) => props.addBookMark(e, activity)} 
              css="far fa-bookmark font-16 white" 
              count={activity.planner_step_count || 0}/>
            </li>
          }
        </ul>
        <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, activity)}/>
      </div>
    )
  }

  const Card = () => {
    return (
      <Fragment>
        <div className="bg-white border-rounded-10 card-shadow m-b-15">
          <div className="card-title card-title-gray flex p-b-15 p20 coldir">
            <h4 className="fw700 f18 card-heading flex coldir text-left">
              {data.name}
            </h4>
            <div className="f14 m-t-10 fw500"> 
              ({dataSource?.language?.label})&nbsp;-&nbsp; {activity.updated_at}
            </div>
          </div>
          <div className="card-content p20">
            <div className="scroll-auto ht-400">
              <h5 className="f16 m-b-10">Video</h5>
                <a target="_blank" href={data.reference_url} className="text-center play-btn">
                  <img className="fulwid" src={`https://img.youtube.com/vi/${data.img_url}/maxresdefault.jpg`}/>
                </a>
              <h4 className="f16 m-t-15 m-b-15 border-dashed-sm p-b-15 fw700">Source of content</h4>
              <p className="f14 m-b-10">Details</p>
              <ol className="m-t-15 card-numbers border-dashed-sm p-b-15 p-l-15">
                <li className="m-b-10 f16 lh-19">
                  <div className="pre-wrap" dangerouslySetInnerHTML={{ __html: data.details}}/>
                </li>
              </ol>
            </div>
          </div>
          <Footer/>
        </div>
      </Fragment>
    )
  }

    return <Card />;
}

export default Planner;