import React, { useEffect, useState, useContext, Fragment} from 'react'
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import CommonService from "../../services/CommonService"
import useStyle from '../../hooks/useStyle'
import {Link} from "react-router-dom"
import PreLoadSpinner from "../PreLoadSpinner"
import env from '../../env'
import TrainingProgramsService from './TrainingProgramsService'

let profileImage = 'images/avatars/2s.png'

const TrainingManager = (props) => {
  useStyle('/css/our-journey.css', true);

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [recentTrainingPrograms, setRecentTrainingPrograms] = useState([])
  const [recentAssessments, setRecentAssessments] = useState([])
  const [favouriteTrainingPrograms, setFavouriteTrainingPrograms] = useState([])
  const [favouriteAssessments, setFavouriteAssessments] = useState([])

  useEffect(() => {
    getAll()
  }, [currentUser.id])

  let getAll = function(){
    TrainingProgramsService.getTrainingManagerContent().then(({status, data})=>{
      if(status == 200){
        setRecentTrainingPrograms(data.recent_training_programs)
        setRecentAssessments(data.recent_assessments)
        setFavouriteTrainingPrograms(data.favourite_training_programs)
        setFavouriteAssessments(data.favourite_assessments)
      }
    })
  }

  const getProfileImg = (n) =>{
    if(n.sender && n.sender.profile_image_url){
      const userImg = env.file_url+n.sender.profile_image_url
      return userImg
    }
    return profileImage
  }

  const RecentTrainingProgramsView = ({}) => recentTrainingPrograms.map((n, k) =>  (
    <Fragment key={k}>
      <div class="card-title flex justspacebetween border-dashed-sm m-t-20 p-b-15">
        <div class="flex">
          <div class="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
            <img src={getProfileImg(n)} className="icon-gray" width="50"/>
          </div>
          <div>
            <h4 class="fw700 f14 card-heading">{n.data.host}</h4>
            <p class="text-gray f12 m-b-0">
            {n.data.location} 
            </p>
            <p class="text-gray f12 m-b-0">{n.data.start}</p>
          </div>
        </div>
        <a href="#" class="arrow-icon flex aligncenter justifycenter border-rounded-10">
          <img src="./images/arrow-right.png" alt="" />
        </a>
      </div>
    </Fragment>
  ))

  const RecentAssessmentsView = ({}) => recentAssessments.map((n, k) =>  (
    <Fragment key={k}>  
      <div class="card-title flex justspacebetween border-dashed-sm m-t-20 p-b-15">
        <div class="flex">
          <div class="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
            <img src={getProfileImg(n)} className="icon-gray" width="50"/>
          </div>
          <div>
            <h4 class="fw700 f14 card-heading">{n.data.subject_name}</h4>
            <p class="text-gray f12 m-b-0">
              {n.data.date_time}
            </p>
            <p class="text-gray f12 m-b-0" dangerouslySetInnerHTML={{ __html: n.data.details }} ></p>
          </div>
        </div>
        <a href={`/question/${n.id}?is_preview=true`} class="arrow-icon flex aligncenter justifycenter border-rounded-10">
          <img src="./images/arrow-right.png" alt="" />
        </a>
      </div>
    </Fragment>
  ))

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div class="app-header flex justspacebetween">
            <div class="flex aligncenter">
              <div class="app-title m-r-20">Training Dashboard</div>
            </div>
            <div class="flex aligncenter">
              <div class="badge badge-sm border-rounded-30 badge-dark text-white fw900 f12">
                This Month
              </div>
            </div>
          </div>
          <div className="app-content-section m-t-30 m-t-0-xs">
            <div class="row">
              <div class="col-md-3">
                <div class="card card-highlite card-yellow-bg flex aligncenter justspacebetween pos_relative m-b-15">
                  <div>
                    <h4 class="fw700 card-heading">Programs</h4>
                    <a href="/list/training_programs" class="f12">Tab to view more</a>
                  </div>
                  <div class="card-number m-r-10">{recentTrainingPrograms.length}</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card-highlite card-blue-bg flex aligncenter justspacebetween pos_relative m-b-15">
                  <div>
                    <h4 class="fw700 card-heading">Quiz</h4>
                    <a href="/list/assessments/" class="f12">Tab to view more</a>
                  </div>
                  <div class="card-number m-r-10">{recentAssessments.length}</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card-highlite card-litegreen-bg flex aligncenter justspacebetween pos_relative m-b-15">
                  <div>
                    <h4 class="fw700 card-heading">Pinned Programs</h4>
                    <a href="#" class="f12">Tab to view more</a>
                  </div>
                  <div class="card-number m-r-10">{recentTrainingPrograms.length}</div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="card card-highlite card-yellow-bg flex aligncenter justspacebetween pos_relative m-b-15">
                  <div>
                    <h4 class="fw700 card-heading">Pinned Quiz</h4>
                    <a href="#" class="f12">Tab to view more</a>
                  </div>
                  <div class="card-number m-r-10">{recentAssessments.length}</div>
                </div>
              </div>
            </div>
            <div className="row m-t-30">
              <div class="col-md-4">
                <div class="bg-white border-rounded-10 p20 m-b-15">
                  <div class="card-title flex aligncenter border-dashed-sm p-b-15">
                    <div class="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
                      <img src="./images/pin-icon.png" alt="" />
                    </div>
                    <div>
                      <h4 class="fw700 card-heading">Most Recent Training Program entries</h4>
                      <p class="text-gray f12">Over all journey</p>
                    </div>
                  </div>
                  <div class="card-content">
                    <RecentTrainingProgramsView/>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="bg-white border-rounded-10 p20 m-b-15">
                  <div class="card-title flex aligncenter border-dashed-sm p-b-15">
                    <div class="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
                      <img src="./images/pin-icon.png" alt="" />
                    </div>
                    <div>
                      <h4 class="fw700 card-heading">Most Recent Training Quiz entries</h4>
                      <p class="text-gray f12">Over all journey</p>
                    </div>
                  </div>
                  <div class="card-content">
                    <RecentAssessmentsView/>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="bg-white border-rounded-10 p20 m-b-15">
                  <div class="card-title flex aligncenter border-dashed-sm p-b-15">
                    <div class="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
                      <img src="./images/pin-icon.png" alt="" />
                    </div>
                    <div>
                      <h4 class="fw700 card-heading">Pinned Training Program entries</h4>
                      <p class="text-gray f12">Over all journey</p>
                    </div>
                  </div>
                  <div class="card-content">
                    <RecentTrainingProgramsView/>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="bg-white border-rounded-10 p20 m-b-15">
                  <div class="card-title flex aligncenter border-dashed-sm p-b-15">
                    <div class="card-icon border-rounded card-icon-brown-bg flex aligncenter justifycenter m-r-15">
                      <img src="./images/pin-icon.png" alt="" />
                    </div>
                    <div>
                      <h4 class="fw700 card-heading">Pinned Quiz entries</h4>
                      <p class="text-gray f12">Over all journey</p>
                    </div>
                  </div>
                  <div class="card-content">
                    <RecentAssessmentsView/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>      
    </Fragment>
  )
}

export default TrainingManager;