import env from '../../../env'
import axios from 'axios'
import BaseService from "../../../services/BaseService"
let url = env.health_backend+'training/step_assessments'
let options_url = env.health_backend+'training/step_assessment/options'

class StepAssessmentService extends BaseService {
  
  getOptions(req){
    return new Promise((resolve, reject) => {
      axios.get(`${options_url}`,  {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  }

  deleteOption(req){
    return new Promise((resolve, reject) => {
      axios.delete(`${options_url}/${req.id}`,  {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  }

  updateOption(req){
    return new Promise((resolve, reject) => {
      axios.put(`${options_url}/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  }

  updateSortOrder(req){
    return new Promise((resolve, reject) => {
      axios.put(`${env.health_backend}health/sort/step_assessments/${req.id}`, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }
}

export default new StepAssessmentService('health_backend', 'training/step_assessments');