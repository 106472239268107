import React, { useState, Fragment, useEffect, useContext, useRef, useMemo } from "react"
import ReactTooltip from "react-tooltip"
import querystringify from "querystringify"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import Planner from "./Planner"
import PlannerService from "../PlannerService"
import Pagination from "../../../../Pagination"
import ConfirmMenu from "../../../../Modals/ConfirmMenu"
import ProjectMenu from "../../../../Modals/ProjectMenu"
import FormTemplateId from "../../../../../constants/FormTemplateList"
import CommentsModal from "../../../../Modals/CommentsModal"
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import CardMenu from "../../../../Modals/CardMenu"
import { Link } from "react-router-dom"
import useModal from "../../../../../hooks/useModal"
import useStyle from "../../../../../hooks/useStyle"
import PreLoadSpinner from "../../../../PreLoadSpinner"
import Title from "../../../GenericPortalPage/Title"
import GenericModal from "../../../../Modals/GenericModal"
import {SwiperContainer, SwiperSlide} from "../../../../Common/Swiper"
import CommonService from "../../../../../services/CommonService"
import PlannerSteps from "./PlannerSteps"
import {MapperDataSourceListModal} from "../../../../FormBuilder/MapperDataSourceListModal"
import env from "../../../../../env"
import FilterSlider from "../../../../Common/FilterSlider"
import {CheckBox} from "../../../../Common/FormInput"
import {MenuItem, NavIcon} from '../../../../Common/MenuItem'
import LocaleList from "../../../../Locale/LocaleMenu"
import CheckAccess from "../../../Roles/CheckAccess"
import DataLogList from '../../../../DataLogs/DataLogList'
import SideMenuBar from "../../../../Common/SideMenuBar"
import PaginationSM from '../../../../Common/PaginationSM'
import {usePageTitle} from '../../../../../hooks/pageHead'

let requestParams = {}
let selectedPlanner = {}
let dataSource = {}
let journeyCategory = null

const PlannerList = (props) => {
  useStyle('/css/tile-components.css', true)
  usePageTitle('Guide List')

  let params = props.match.params
  let {project, child} = props.location.state || {}
  let queryParam = querystringify.parse(props.location.search)

  const {state: { screen, user: currentUser }, dispatch} = useContext(AuthContext)

  const permission = useMemo(() => 
    CheckAccess.getMenuPermission(currentUser, 'guides'), [currentUser.id]
  )

  const currentOrgData = currentUser.current_organization.data
  const [plannerList, setPlannerList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState({})
  const [filter, setFilter] = useState({})
  const [loading, setLoading] = useState(true) 
  const [triggerApi, setTriggerApi] = useState(true)
  const [formFields, setFormFields] = useState({})

  const {isOpen: isBookMarkOpen, toggleModal:toggleBookMarkModal } = useModal()
  const {isOpen: isChecklistOpen, toggleModal:toggleChecklistModal} = useModal()
  const {isOpen: isCommentOpen, toggleModal:toggleCommentModal} = useModal()
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal} = useModal()
  const {isOpen: isMenuOpen, toggleModal: toggleMenuModal} = useModal()
  const {isOpen: isPlannerMapperOpen, toggleModal: togglePlannerMapper} = useModal()
  const {isOpen: isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const {isOpen: showLocaleModal, toggleModal: toggleLocaleModal} = useModal()
  const {isOpen: isDataLogOpen, toggleModal:toggleDataLogModal } = useModal()

  const indexEle = useRef(null)

  let cardGridCss = "col-md-4"
  let cardHeightCss = "ht-400"
  if (queryParam.id) {
    cardGridCss = "col-sm-6 col-md-8"
    cardHeightCss = "mih-380"
  }

  useEffect(() => {
    ReactTooltip.rebuild()
    journeyCategory = null
    requestParams = {
      page: currentpage,
      per_page: 15,
      sort_column:'updated_at',
      sort_direction:'desc',
      search: props.searchString,
      filter: filter,
      data_source_params: {
        data_source_id:[3613],
        order_by:'order_list'
      }
    }

    //Mar 1
    /*if(requestParams.filter.language){
      delete requestParams.filter.languages
    }else{
      requestParams.filter.languages = [
        1, 
        currentOrgData?.language, 
        currentOrgData?.second_preferred_lang
      ]
    }*/

    if(currentpage != 0){
      getJourneyPlannerList()
    }else {
      setCurrentpage(1)
    }
  }, [currentpage])

  useEffect(() => {
    ReactTooltip.rebuild()
    setCurrentpage(0)
  }, [props.searchString, filter])

  let getJourneyPlannerList = () => {
    if(permission.read){
      setLoading(true)
      PlannerService.getAll(requestParams).then((res) => {
        if (res.status == 200) {
          dataSource = res.data.data_source_list
          setPagemeta(res.data.meta)
          setPlannerList(res.data.planners)  
          setLoading(false)
        }
      })
    }
  }

  const updateStatus = (planner, key) => {
    if(permission.write){
      const req = {
        id:planner.id,
        [key]:!planner[key]
      }
      PlannerService.update(req).then((res) => {
        if (res.status == 204) {
          planner[key] = !planner[key]
          setPlannerList([...plannerList])
        }
      })
    }
  }

  const deletePlanner = (e) => {
    e.preventDefault()
    PlannerService.delete(selectedPlanner.id).then((res) => {
      if (res.status == 204) {
        setPlannerList(
          plannerList.filter((i) => i.id !== selectedPlanner.id)
        )
      }
    })
    toggleDeleteModal(e)
  }

  const showMenu = (e, a) => {
    selectedPlanner = a
    toggleMenuModal(e)
  }

  const addChecklist = (e, a) => {
    selectedPlanner = a
    toggleChecklistModal(e)
  }

  const addNotes = (e, a) => {
    selectedPlanner = a
    toggleCommentModal(e)
  }

  const addBookMark =(e, a) =>{
    //setActiveIndex(i==activeIndex?null:i)
    selectedPlanner = a
    toggleBookMarkModal(e)
  }

  const routePlanner = (activity) => {
    props.history.push(
      `/team/activity/${params.journeyCategory_id}/${params.education_journey_profile_id}?id=${activity.id}`
    )
    
    setPlannerList(
      plannerList.filter((i) => i.id == activity.id)
    )
  }

  const translateAndClone = (e, item) => {
    selectedPlanner = CommonService.deepCopy(item) //Deep copy
    selectedPlanner.id = null
    selectedPlanner.data_source = null
    selectedPlanner.record_id = null 
    selectedPlanner.clone_id = item.id
    toggleLocaleModal(e)
  } 

  const onTranslateComplete = (translatedData) => {
    toggleLocaleModal()
    translatedData.created_by = currentUser.id
    translatedData.updated_by = currentUser.id
    translatedData.organization_id = currentUser.current_organization_id
    PlannerService.create(translatedData).then((res) => {
      if(res.status==201){
        setPlannerList([res.data.planner, ...plannerList])
      }
    }).catch(function(res){
       
    })
  }

  const openLogs = (e, item) =>{
    selectedPlanner = item
    toggleDataLogModal(e)
  }

  const toggleFilter = (value, name) => {
    if(value && value != filter[name]){
      setFilter({...filter, [name]:value})
    }else{
      delete filter[name]
      setFilter({...filter})
    }
  }

  const filterExists = (value, name) => filter[name] == value;

  const PlannerFilter = () => (
    <div className="p-5 scroll-x">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Language
        </h5>

        <CheckBox list={dataSource.languages.options || []} labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} name="language"/> 
      </div>
    </div>
  )

  const menuItems = ({ item }) => {
    //const isEdit = CheckAccess.checkLocaleTranslateAccess(currentUser, item, 'guides', 'write')

    return (
      <Fragment>
        <Link>
          <span className="font-13">{item.planner_form_id} - {item.record_id}</span>
        </Link>
        
        {permission.write &&  
          <a href onClick={e=>openLogs(e, item)}>
            <i className="fas fa-globe"></i>
            <span className="font-13">Versions</span>
            <i className="fa fa-angle-right"></i>
          </a>
        }
          
        {permission.write && 
          <Link to={`/health/planner/form/${item.id}?edit=${permission.write}`}>
            <i className="far fa-edit font-15" aria-hidden="true"></i>
            <span className="font-13">Edit</span>
            <i className="fa fa-angle-right"></i>
          </Link>
        }

        {permission.write && CheckAccess.hasDeleteAccess(currentUser, item) &&       
          <a href="#" onClick={(e) => toggleDeleteModal(e)}>
            <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
            <span className="font-13">Delete</span>
            <i className="fa fa-angle-right"></i>
          </a>  
        }
          
        {permission.write && !item.clone_id &&
          <a href="#" onClick={(e) => translateAndClone(e, item)}>
            <i className="fas fa-globe"/> 
            <span className="font-13">Language copy</span>
            <i className="fa fa-angle-right"/>
          </a>
        }

        <a href="#">
          <i className="far fa-eye"/> 
          <span className="font-13">Views count</span>
          <i className="fa fa-angle-right"/>
        </a>

        {permission.write && 
          <a href="#" onClick={(e) => {e.preventDefault();openPlannerMapper(item)}}>
            <i className="fas fa-hands-helping"/> 
            <span className="font-13">Add Data Source Mapper</span>
            <i className="fa fa-angle-right"/>
          </a>
        }
      </Fragment>
    )
  }

  const Menu = ({ activity }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, activity)} className="fas fa-bars m-r-15 font-16"/>
  )

  const openPlannerMapper = (planner) =>{
    selectedPlanner = planner
    togglePlannerMapper()
  }

  const plannerCard = plannerList && plannerList.map((a, i) => (
    <div className={cardGridCss} key={i}>
      <Planner activity={a} dataSource={dataSource} menu={Menu} addMenu={showMenu}
        addNotes={addNotes} addChecklist={addChecklist} addBookMark={addBookMark}
        journeyCategory={journeyCategory} cardHeightCss={cardHeightCss}
        routePlanner={routePlanner} openPlannerMapper={openPlannerMapper} 
        currentUser={currentUser} updateStatus={updateStatus}
        permission={permission}/>
      {selectedPlanner.id == a.id && (
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
          item={selectedPlanner}  type="activity"  MenuItems={menuItems}/>
      )}
    </div>
  ))

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20">Guide</div>
              <div className="font-15">
                {pagemeta.total} Items
              </div>
            </div>

            {permission.write &&
              <div className="flex aligncenter">
                <Link id="add_new_btn" to={`/health/planner/form`}
                  data-tip="Create New Planner"
                  className="bg-highlight round-btn float-right m-r-5 m-t-4">
                  <i className="fas fa-plus"></i>
                </Link>
                <NavIcon id="filter_btn" dataTip="Filter Items" 
                  className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                  onSelect={toggleFilterModal} icon="fas fa-sliders-h"/>
              </div>
            }
          </div>


      {loading && <div className="spinner"/>}

      <div className="app-content-section m-t-30">
        <div className="row">
          {plannerCard}
        </div>
        <PaginationSM
          className="pagination-bar"
          currentPage={currentpage}
          totalItems={pagemeta.total}
          itemsPerPage={pagemeta.per_page}
          onPageChange={setCurrentpage}
          siblingCount={2}
        />
      </div>
      </div>
      </div>
      {isBookMarkOpen && 
        <GenericModal title="Video Bookmarks" 
        subtitle={selectedPlanner.data.name} component={PlannerSteps} 
        toggleModal={toggleBookMarkModal} selectedPlanner={selectedPlanner}/>
      }
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deletePlanner} />
      {isChecklistOpen && 
        <ChecklistsModal isOpen={isChecklistOpen} 
        toggleModal={toggleChecklistModal} 
        item={selectedPlanner} type="planner" />
      }
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} item={selectedPlanner} 
        type="planner"/>
      }
      {permission.write && isPlannerMapperOpen && 
        <MapperDataSourceListModal isOpen={isPlannerMapperOpen} 
        toggleModal={togglePlannerMapper} mappingId={selectedPlanner.id} 
        mappingType="planner"/>
      }
      {permission.write && isFilterOpen && 
        <FilterSlider component1={PlannerFilter} 
        openSideNav={isFilterOpen}
        setOpenSideNav={toggleFilterModal}/>
      }
      {permission.write && showLocaleModal && 
        <GenericModal component={LocaleList} title="Langauage"
        isOpen={showLocaleModal} toggleModal={toggleLocaleModal}
        onTranslateComplete={onTranslateComplete} 
        selectedItem={selectedPlanner}/>
      }
      {permission.write && isDataLogOpen && 
        <GenericModal component={DataLogList} title="Versions" widthCss="w-100p"
        isOpen={isDataLogOpen} toggleModal={toggleDataLogModal}
        logType="counselings" logItem={selectedPlanner}
        formFields={formFields[selectedPlanner.planner_form_id]}/>
      }
      <SideMenuBar pageIds={['planners']}/>
    </Fragment>
  )
}

export default PlannerList;