import React, {Fragment, useState, useEffect, useContext} from "react"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import useStyle from "../../../../hooks/useStyle"
import {tabMoreIcon} from "../../../Common/Button"
import Tabs, { TabPane } from "rc-tabs"
import Charts from "./Charts"
import Leaderboard from "./Leaderboard"
import useModal from '../../../../hooks/useModal'
import {CheckBox, DateTimePicker, OptionsObjList} from "../../../Common/FormInput"
import {GraphMetricsList, PresetDateRange, MaternalPregnancyFilter} from "../../../../constants/DataSourceList"
import CommonService from "../../../../services/CommonService"

const ReportsPage = ({id}) => {
  useStyle('/css/charts-layout-with-selector.css', true)

  const [isFilterOpen, toggleFilter] = useState(false)
  const [activeTab, setActiveTab] = useState('kpis')

  return(
    <>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween card bg-white border-rounded-10 m-t-20-xs">
            <div className="flex justspacebetween aligncenter p20 wid_100">
              <div className="flex alignstart justspacebetween">
                <div className="app-title m-r-20">Reports</div>
              </div>
            </div>
            {/*<div className="flex aligncenter">
              <span data-tip="Filter" className="bg-highlight round-btn m-r-15" 
                onClick={()=>toggleFilter(!isFilterOpen)}>
                  <i className="fas fa-sliders-h"></i>
              </span>
            </div>*/}
          </div>
          <div className="app-content-section m-t-30 m-t-20-xs">
            <div className="row">
              <Tabs activeKey={activeTab} onChange={(key)=>setActiveTab(key)} moreIcon={tabMoreIcon}>
                <TabPane tab="KPIs" key="kpis">
                  <Charts isFilterBtnClick={isFilterOpen} activeTab={activeTab}/>    
                </TabPane>
                <TabPane tab="Leaderboard" key="leadership">
                  <Leaderboard isFilterBtnClick={isFilterOpen} activeTab={activeTab}/>  
                </TabPane>
                {/*<TabPane tab="Accounts" key="accounts">
                    
                </TabPane>*/}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  ) 
}

export {ReportsPage};