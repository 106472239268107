import React, {Fragment} from "react"
import JourneyBlock from "../List/JourneyBlock"
import TreatmentTable from "../Treatments/List/TreatmentTable"
import InfantActivityTable from "../MotherInfantJourney/Activities/List/InfantActivityTable"
import ChildBlock from "../MotherInfantJourney/Child/ChildBlock"
import ChildHistoryBlock from "../PatientAdditionalInfo/ChildHistoryBlock"
import AssessBFTable from "../MotherInfantJourney/Activities/List/AssessBFTable"
import MedicalFacilityBlock from "../MotherInfantJourney/MedicalCareFacility/MedicalFacilityBlock"
import WellnessBlock from "../Wellness/List/WellnessBlock"
import DailyMeasurementTable from "../DailyMeasurements/List/DailyMeasurementTable"
import FamilyMemberList from "../../Organizations/Members/FamilyMemberList"

const Components = (props) => {
  let components = {
    journey_profile: JourneyBlock,
    diagnosis: TreatmentTable,
    treatment: TreatmentTable,
    child_birth: ChildBlock,
    birth_history: ChildHistoryBlock,
    household_info: ChildHistoryBlock,
    addnal_info: ChildHistoryBlock,
    household_details: ChildHistoryBlock,
    prior_services: ChildHistoryBlock,
    prenatal_care: InfantActivityTable,
    feeding_assessment: InfantActivityTable,
    growth_measurement: InfantActivityTable,
    assess_bf: AssessBFTable,
    assess_cf: InfantActivityTable,
    immunization: InfantActivityTable,
    periodic_check: InfantActivityTable,
    protein_intake: InfantActivityTable,
    cancer_journey : {
      journey_profile: JourneyBlock,
      symptoms:WellnessBlock,
      daily_check:DailyMeasurementTable,
      pain_tracking:WellnessBlock,
      side_effects:WellnessBlock,
      care_giver:FamilyMemberList,
      health_center:MedicalFacilityBlock,
      family_member:FamilyMemberList
    },
    dementia_journey : {
      journey_profile: JourneyBlock,
      symptoms:WellnessBlock,
      daily_check:DailyMeasurementTable,
      pain_tracking:WellnessBlock,
      side_effects:WellnessBlock,
      care_giver:FamilyMemberList,
      health_center:MedicalFacilityBlock,
      family_member:FamilyMemberList
    },
    ckd_journey : {
      journey_profile: JourneyBlock,
      symptoms:WellnessBlock,
      daily_check:DailyMeasurementTable,
      pain_tracking:WellnessBlock,
      side_effects:WellnessBlock,
      care_giver:FamilyMemberList,
      health_center:MedicalFacilityBlock,
      family_member:FamilyMemberList
    },
  }

  let TagName = null
  if(props.journeyType == 'infant_journey'){
    TagName = components[props.componentName]  
  }else if(props.journeyType){
    TagName = components[props.journeyType][props.componentName]  
  }else{
    TagName = components[props.componentName]  
  }

  if(TagName){
  	return <TagName {...props}/>
  }else{
  	return <div>None</div>
  }
  
}

export default Components