import React ,{Fragment, useState, useEffect} from "react"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import Cs from "../../../services/CommonService"
import { ChatGrayIcon, CheckListGrayIcon, EnvelopeGrayIcon, HamburgerMenuIcon, MaximizeIcon } from "../../Common/ImageIcons"
import {FormFieldTable} from "../../HealthJourney/UserRoles/NotificationPill"

const Event = (props) => {
  let {event, formFieldsList} = props
  let {data, data_source} = event
  let formFields = formFieldsList[event.form_id] || []
  event.updated_at = Cs.formatUpdateDate(event.updated_at)
  
  useEffect(() => {
    ReactTooltip.rebuild()
  })

  if (!data) return null;

  const MailTag = () => (
    <a href={`mailto: ${event.id}-screening_event@reply.cuetree.com?subject=Screening Event-${data.name}-${event.record_id}`}
      target="_blank">
      <i className="fas fa-envelope font-18 text-muted" aria-hidden="true"></i>
    </a>
  )

  const Header = () => (
    <div className="card-title card-title-gray flex p-b-15 p20 coldir">
      <h4 className="fw700 f18 card-heading flex coldir text-left">
        {data.title || data.name}
      </h4>
      <div className="f14 m-t-10 fw500"> &nbsp;-&nbsp;{event.updated_at}</div>
    </div>
  )

  const Footer = () => {
   return(
    <div className="card-footer flex justspacebetween p20 aligncenter">
      <ul className="flex aligncenter">
        <li className="m-r-25">
          <ChatGrayIcon count={event.comments_count} onSelect={(e) => props.addNotes(e, event)}/>
        </li>
        <li className="m-r-25">
          <CheckListGrayIcon count={event.check_list_count} onSelect={(e) => props.addChecklist(e, event)}/>
        </li>
        <li className="m-r-25">
          <EnvelopeGrayIcon count={event.mail_count} link={`/health/incoming/emails/treatment/${event.id}`}/>
        </li>
      </ul>
      <HamburgerMenuIcon onSelect={(e)=>props.addMenu(e, event)}/>
    </div>
  )}


  const EventCard = () => {
    return (
      <div className="bg-white border-rounded-10 card-shadow m-b-15">
        <Header/>
        <div className="card-content p20">
          <ul className="flex aligncenter justspacebetween">
            <li>
              <Link to={`/screening_lists/${event.id}`} 
                className="b-nav-tab-table active">
                  <i className="fas fa-eye"/>
                  View
              </Link>
            </li>
            <li>
              <Link to={`/screening_lists/create/${event.id}/${event.data_source.type_health_condition?.label}/${event.data_source.type_health_condition?.ticket_form}`}
                className="b-nav-tab-table active m-l-10">
                  <i className="fas fa-plus"/>
                  Create
              </Link>
            </li>
          </ul>
          <div className="b-tab-table active scroll-auto ht-400 m-t-5">
            <FormFieldTable formFields={formFields} data={data} dataSource={data_source}/>
          </div>
        </div>
        <Footer />
      </div>
    )
  }
  
  return <EventCard />
}

export default Event;