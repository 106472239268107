import env from '../../../env'
import axios from 'axios'
import BaseService from "../../../services/BaseService"
let url = env.health_backend+'training/assessment/response_fields'

class AnswerService extends BaseService {

  getAnswerFields(req){
    return new Promise((resolve, reject) => {
      axios.get(url, {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

  updateAnswerField(req) {
    return new Promise((resolve, reject) => {
      axios.put(url+'/'+req.id, req).then((res) => {
        // handle success
        resolve(res);
      })
    })
  }

}

export default new AnswerService('health_backend', 'training/assessment/responses')