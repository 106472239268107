import React, { Fragment, useState, useEffect, useContext } from 'react'
import ReactDOM from 'react-dom'
import useStyle from '../../../hooks/useStyle'
import {Link, useHistory} from "react-router-dom"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import SubscriptionAccessesService from '../../Subscriptions/SubscriptionAccessesService'
import CheckAccess from './CheckAccess'
import {DataSourcePermissionKey} from '../../../constants/DataSourceList'
import FavouriteService from '../../../services/FavouritesService'

const UserAccessComponents = ({isOpen, toggleModal}) => {
  useStyle("add_role_dialog")
  useStyle("filter_dialog")
  
  const history = useHistory()
  
  const { state:{user:currentUser}} = useContext(AuthContext)
  const currentMember = currentUser.current_member

  const [components, setComponents] = useState({})
  const [favourites, setFavourites] = useState({})
  const [rolesDescription, setRolesDescription] = useState()
  const [loading, setLoading] = useState(true)
  const [index, setIndex] = useState(null)

  useEffect(()=>{
    SubscriptionAccessesService.getAll({
      subscription_id: currentUser.current_subscription?.id,
      role_ids: currentMember.role_record_id,
      favourite_params:{
        favorable_type: 'journey_component'
      }
    }).then(({status, data})=>{
      if(status == 200){
        setComponents(data.journey_components)
        setRolesDescription(data.roles?.join('\n'))
        setFavourites(data.favourites)
        //setIndex(Object.keys(data.journey_components)?.length - 1)
        setLoading(false)
      }
    })
  }, [])

  useEffect(() => {
    // try{
    //   setTimeout(() => {
    //     for (const g in components) {
    //       if(document.getElementById(`dsl_${g}`).childElementCount == 0)
    //           //.remove()
    //           document.getElementById(`ds_${g}`).style.display = "none"
    //     }
    //   }, 0)
    // }catch(e){
    //   console.log(e)
    // }
  }, [components])

  const addOrRemoveFavourite = (component, favourite) =>{
    setLoading(true)
    if(favourite?.id){
      FavouriteService.delete(favourite.id).then(({status, data})=>{
        if(status == 204)
          favourites[component.pk_id] = null
          setLoading(false)
      })
    }else{
      const req = {
        'favorable_type':'journey_component', 
        'favorable_id':component.pk_id, 
      }
      FavouriteService.create(req).then(({status, data})=>{
        if(status == 201)
          favourites[component.pk_id] = data.favourite
          setLoading(false)
      })
    }
  }

  const Favourite = ({component}) =>{
    const isFav = favourites[component.pk_id]?'fas fa-star':'far fa-star'
    return <i onClick={e=>addOrRemoveFavourite(component, favourites[component.pk_id])} className={`${isFav} black pull-right`}/>
  }

  const Components = ({list=[], dataSourceId}) =>{
    const permissionKey = DataSourcePermissionKey[dataSourceId]
    return(
      <Fragment>
        <div className="popup-menu-grid br-grey" id={`dsl_${dataSourceId}`}>
          {list.map((o, k) =>
            <Fragment key={k}> 
              {CheckAccess.checkUserAccess(currentMember, currentMember?.permissions?.[permissionKey], o.sid)?
                <div className="ver-icons">
                  <span>
                    <i className={o.icon || "fas fa-plus"} style={{ color: o.icon_color }}/>
                  </span>
                  <div className="popup-menu-title">{o.label}</div>
                  <Favourite component={o}/>
                  <div className="m-l-40 black-light-1">{o.description || o.detail}</div>
                </div>
                :
                null
              }
            </Fragment> 
          )}
        </div>
      </Fragment>  
    )
  }

  const DataSource = () => Object.keys(components).map((o, k) =>{
    const color = components[o]?.settings?.bg_color || '#5E5E5E'
    const style = {borderLeft: `6px solid ${color}`}
    return(
      <Fragment key={k}>
        <div className="black p-5 font-15 br-grey m-b-10 m-t-10" id={`ds_${o}`} 
          onClick={e=> index === k ? setIndex(null) : setIndex(k)} 
          style={style}>
            {components[o].label}
            <i className={`fas ${index === k ? 'fa-chevron-up' : 'fa-chevron-down'} black pull-right m-r-10`} 
              onClick={e=>setIndex(index === k?null:k)}
            />
        </div>
            
        {(index === k || index === "5") && <Components list={components[o].options} dataSourceId={o}/>}
      </Fragment>
    )
  })
    
  return(ReactDOM.createPortal(
    <Fragment>
      <div className="ml-modal">
        <div className="ml-modal-content ml-card-4 ml-animate-zoom border-rounded-10">
          
          <div className="flex justspacebetween modal-title">
            <h4 className="f18 text-white fw500 p12-10-15">Services available to access</h4>
            <button className="btn-close"
              title="Close Modal" onClick={e=>toggleModal(e)}>×
            </button>
          </div>
          
          {loading?
            <div className="spinner"/>:
            <div className="p-10 scroll-x ht-500">
              {rolesDescription &&
                <div className="ml-center bg-draft white p-5 font-15 m-t-5 m-b-15">
                  {rolesDescription}
                </div>
              }
              <span className="checkbox-container" onClick={e => index === "5" ? setIndex(null) : setIndex("5")}>
                <span className="font-15 bold-500">View all</span>
                <input type="checkbox" checked={index === "5"} readOnly/>
                <span className="checkbox-checkmark"/>
              </span>
              <DataSource/>
            </div>
          }
            
        </div>
      </div>
    </Fragment>, document.body)
  )

}

export default UserAccessComponents;