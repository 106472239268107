import React, {useState, Fragment, useContext, useEffect, useMemo} from "react"
import ReactTooltip from "react-tooltip"
import querystringify from "querystringify"
import Pagination from '../Pagination'
import {Link} from "react-router-dom"
import useStyle from '../../hooks/useStyle'
import useModal from "../../hooks/useModal"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import Cs from "../../services/CommonService"
import JourneyTitle from "../../components/HealthJourney/GenericPortalPage/Title"
import DataSourceListModal from './DataSourceListModal'
import AddLocale from "../Locale/AddLocale"
import GenericModal from "../../components/Modals/GenericModal"
import env from "../../env"
import {OptionsObjList} from  "../Common/FormInput"
import FormFieldOrgAttributeService from "./FormFieldOrgAttributeService"
import CheckAccess from '../HealthJourney/Roles/CheckAccess' 
import { CTTooltip } from "../Common/CueTooltip"
import {usePageTitle} from '../../hooks/pageHead'
import FormDataChartsConfig from "./FormDataChartsConfig"
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons"
import {FontAwesomeLink} from "../Common/Button"

let requestParams = {}
let selectedFormField = {}
let selectedLocaleData = {}

const getDefaultJourneyType = (currentUser)=>{
  try{
    return (currentUser.current_subscription.data_source.journeys[0].data.pk_id)
  }catch(e){
    return 'None'
    console.error('No journeys')
  }
}

const FormsList = (props) => {
  useStyle('filter_dialog');
  useStyle('add_role_dialog');
  useStyle("card");
  usePageTitle('Data Choices');

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext);
  const [dataSourceList, setDataSourceList] = useState([]);
  const [formTemplates, setFormTemplates] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [formTabs, setFormTabs] = useState([]);
  const [index, setIndex] = useState()
  const [dataSources, setDataSources] = useState({});
  const [currentpage, setCurrentpage] = useState(1);
  const [pagemeta, setPagemeta] = useState([]);
  const [journeyTypeId, setJourneyTypeId] = useState(getDefaultJourneyType(currentUser));
  const [loading, setLoading] = useState(true)

  let params = props.match.params;
  let journey_profile = props.location.state;
  let queryParam = querystringify.parse(props.location.search);
  const isSuperUser = env.admin_emails.includes(currentUser.email)

  const {isOpen: isDataSourceModalOpen, toggleModal: toggleDataSourceModal} = useModal();
  const {isOpen: isLocaleModalOpen, toggleModal: toggleLocaleModal} = useModal();
  const {isOpen: isChartConfigModalOpen, toggleModal: toggleChartConfig} = useModal();

  const permission = useMemo(() => 
    CheckAccess.getMenuPermission(currentUser, 'data_choices')
  , [currentUser.id])

  useEffect(() => {
    requestParams = {
      'page': currentpage, 
      'per_page' : 30,
      'search': (props.searchString || null),  
      'sort_column': 'updated_at', 
      'sort_direction': 'asc',
      'journey_type_id':journeyTypeId=='None'?null:journeyTypeId,
      'data_source_params': {
         "data_source_id":[3419],
      }
    }
    /* queryParam.form_ids*/
    
    getFormTemplates();
  }, [props.searchString, currentpage, journeyTypeId]);

  let getFormTemplates = () => { 
    if(permission.read){
      setLoading(true)
      Cs.getFormTemplates(requestParams).then((res)=>{
        if(res.status == 200){
          setFormTemplates(res.data.form_templates)
          setDataSources(res.data.data_source_list)
          setLoading(false)
        }
      })
    }
  }

  let getFormFields = (from) => { 
    if(permission.read){
      let req = {
        'form_template_id':from.id, 
        'fields':['chart_count', 'tooltip', 'description', 'columns'],
        'add_form_tabs':true,
        'sub_organization_id':currentUser.current_organization_id,
        'add_locale':true
      }
      Cs.getFormFieldsById(req).then((res)=>{
        if(res.status === 200){
          setFormFields(res.data.form_fields)
          setFormTabs(res.data.form_tabs)
        }
      })
    }
  }

  const handleClick =(form, i)=> {
    if(index != i){
      getFormFields(form);
      const tabScroll = document.getElementById('tab_'+i);
      window.scrollTo({
        'behavior': 'smooth',
        'left': 0,
        'top': tabScroll.offsetTop - 200
      })
    }  
    setFormFields([])
    setIndex(index == i?null:i)
  }

  let updateStatus = (o, form_template_id, indexList) =>{
    /*if(o.required)return;*/
    if(CheckAccess.checkIsAdmin(currentUser)){
      let req = {
        filterable_type: 'form_field',
        created_by: currentUser.id,
        filterable_id: o.index,  
        filterable_ids: indexList,  
        form_template_id: form_template_id,
        sub_organization_id: currentUser.current_organization_id
      }

      Cs.updateDataSourceListStatus(req).then((res)=>{
        if(res.status == 200){
          if(o.inactive_status){
            o.inactive_status=null;
          }else{
            o.inactive_status={};
          }
          setFormFields([...formFields])
        }
      }) 
    }
  }

  const setFormField = (e, field, locale) =>{
    e.stopPropagation()
    selectedFormField = field
    selectedFormField.locale = locale
    toggleDataSourceModal()
  }

  const checkAllFields = (form_template_id) => {
    let req = {
      filterable_type: 'form_field',
      form_template_id: form_template_id,
      sub_organization_id: currentUser.current_organization_id
    }
    Cs.deleteDataSourceListStatus(req).then((res)=>{
      formFields.forEach(d => d.inactive_status = null)
      setFormFields([...formFields])
    })
  }

  const uncheckAllFields = (form_template_id) => {
    let indexList = formFields.map(d => d.inactive_status==null && d.index)
    if(indexList.length > 0){
      updateStatus({}, form_template_id, indexList);
    }
  }
  
  const openLocale = (e, data, data_type, index, field_key, locale) =>{
    e.stopPropagation()
    selectedLocaleData.data = data
    selectedLocaleData.type = data_type
    selectedLocaleData.index = index
    selectedLocaleData.locale = locale
    /*name of the field to translate */
    selectedLocaleData.translate_field_key = field_key
    toggleLocaleModal()
  }

  const updateFormLocale = (locale, value) =>{
    const key = selectedLocaleData.translate_field_key
    if(selectedLocaleData.type === 'form_field'){
      let d = formFields[selectedLocaleData.index]
      /*Check table view column update*/
      if(d.component === 'table_view' && selectedLocaleData.data?.index?.includes('col_')){
        d = d?.columns?.find((c)=> c.index === selectedLocaleData.data?.index)
        if(!d)return;
      }
      /*d.locale = d.locale || {label:{}}
      d.locale.label[locale] = value
      formFields[selectedLocaleData.index] = d*/
      d.locale[key] = d.locale[key] || {[key]:{}}
      d.locale[key][locale] = value
      formFields[selectedLocaleData.index] = d
      setFormFields([...formFields])
    }else if(selectedLocaleData.type === 'form_tab'){
      let d = formTabs[selectedLocaleData.index]
      d.locale[key] = d.locale[key] || {[key]:{}}
      d.locale[key][locale] = value
      formTabs[selectedLocaleData.index] = d
      setFormTabs([...formTabs])
    }else if(selectedLocaleData.type === 'form_title'){
      let d = formTemplates[selectedLocaleData.index]
      d.locale_title = d.locale_title || {}
      d.locale_title[locale] = value
      formTemplates[selectedLocaleData.index] = d
      setFormTemplates([...formTemplates])
    }else if(selectedLocaleData.type === 'form_details'){
      let d = formTemplates[selectedLocaleData.index]
      d.locale_details = d.locale_details || {}
      d.locale_details[locale] = value
      formTemplates[selectedLocaleData.index] = d
      setFormTemplates([...formTemplates])
    }else if(selectedLocaleData.type === 'data_source_list'){
      let d = selectedLocaleData.data
      d.locale_data = d.locale_data || {}
      d.locale_data[locale] = {label:value}
      /*let d = formFields[selectedLocaleData.index]
      d.data_source_locale.push(locale)
      formFields[selectedLocaleData.index] = d
      setFormFields([...formFields])*/
    }
  }

  const updateOrgAttribute = (field, key, value) =>{
    if(field.org_attribute?.id){
      let req = {
        id: field.org_attribute.id,
        [key]: value
      }
      FormFieldOrgAttributeService.update(req).then((res)=>{
        if(res.status == 204){
          field.org_attribute[key] = value
          setFormFields([...formFields])
        }
      })
    }else{
      let req = {
        'form_template_id':field.id,
        'field_index':field.index,
        'sub_organization_id':currentUser.current_organization_id,
        [key]: value
      }

      FormFieldOrgAttributeService.create(req).then((res)=>{
        if(res.status == 201){
          field.org_attribute = res.data.form_field_org_attribute
          setFormFields([...formFields])
        }
      })
    }
  }

  return (
    <div id="ct">
      <div className="app_content">
        <div className="flex justspacebetween">
          <div className="app-title m-r-20">Data Choices</div>
            
          {dataSources.health_condition && 
            <select className="form-control w-250" 
              onChange={e=>setJourneyTypeId(e.target.value)}
              defaultValue={journeyTypeId}>
                <option>None</option>
                <OptionsObjList 
                  has_key="data"
                  list={currentUser.current_subscription?.data_source?.journeys} 
                  id_key="pk_id" label_key="label" />
            </select>
          }
        </div>
      </div>
      
      {loading && <div className="spinner"/>}
      
      <div className="app-content-section clearfix">
        <div className="content">
          <FormTemplateView formTemplates={formTemplates} 
            handleClick={handleClick} currentUser={currentUser} 
            openLocale={openLocale} checkAllFields={checkAllFields} 
            uncheckAllFields={uncheckAllFields} 
            index={index} isSuperUser={isSuperUser}
            formFields={formFields} setFormField={setFormField} 
            formTabs={formTabs} setFormTabs={setFormTabs}
            updateStatus={updateStatus}
            updateOrgAttribute={updateOrgAttribute}
            toggleChartConfig={toggleChartConfig}/>
        </div>
        
        <Pagination pagemeta={pagemeta} 
          currentpage={currentpage} 
          setCurrentpage={setCurrentpage}/>
      </div>
      
      {isDataSourceModalOpen && 
        <DataSourceListModal 
          form_template_id={selectedFormField.id} 
          data_source_id={selectedFormField.data_source_id} 
          journey_profile_id={queryParam.journey_profile_id}
          toggleModal={toggleDataSourceModal} 
          locale={selectedFormField.locale}
          openLocale={openLocale}/>
      }

      {isLocaleModalOpen && 
        <GenericModal component={AddLocale} 
          localeType={selectedLocaleData.type} 
          title="Language Translator"
          toggleModal={toggleLocaleModal} 
          onCancel={toggleLocaleModal} 
          labelObj={selectedLocaleData.data}
          updateFormLocale={updateFormLocale}
          locale={selectedLocaleData.locale}
          translateFieldKey={selectedLocaleData.translate_field_key}/>
      }
      
      {isChartConfigModalOpen &&
        <GenericModal component={FormDataChartsConfig}
          isOpen={isChartConfigModalOpen}
          toggleModal={toggleChartConfig}
          field={selectedFormField}/>
      }
    </div>
  )

}

const OrgAttributes = ({field, updateOrgAttribute}) =>{
  let attribute = field.org_attribute || {required: field.required}

  return(
    <div className="flex aligncenter coldir m-t-10">
      <span className={attribute.required ? 
        'fas fa-toggle-on font-22 green'
        :'fas fa-toggle-on fa-rotate-180 font-22 red'
        } onClick={()=>updateOrgAttribute(field, 'required', !attribute.required)}>
      </span>
      <span className="font-14 black">Org Level Required</span>
    </div>
  )
}

const ChartAttributes = ({field, toggleChartConfig}) => {
  return(
    <div
      onClick={()=>{selectedFormField=field;toggleChartConfig()}} 
      className="badge-2 br-r-unset bg-lred m-l-5">
        <span className="fa fas fa-chart-line"/>
        <span className="font-14 m-l-5">{field.chart_count} Charts</span>
    </div>
  )
}

const LocalesPill = ({locales, onSelect}) => locales && locales.map((l, i) => 
  <span key={i} className="text-cap m-r-10 badge-2 mw-150 bg-lgrey"
    onClick={(e)=>{onSelect && onSelect(e, l)}}>
    {l}
  </span>
)

const FormTabsView = ({form, formTabs, currentUser, setFormTabs, isSuperUser, updateStatus, openLocale, updateOrgAttribute}) => formTabs && formTabs.map((t, i) => {
  return(
    <Fragment key={i}>
      <div className="d-flex aligncenter">
        <span>{t.label}</span> 
        <i className="fas fa-edit m-l-15" onClick={e=>openLocale(e, t, 'form_tab', i, 'label')}/>
      </div>
              
      {t.locale &&
        <LocalesPill 
        onSelect={(e, locale)=>openLocale(e, t, 'form_tab', i, 'label', locale)}
        locales={t.locale?.label?Object.keys(t.locale.label):[]}/>
      }
      <div className="brbd-2"/>
    </Fragment>
  )
})

const FormFieldsView = (props) => props.formFields && props.formFields.map((c, i) => {
  return (
    <Fragment key={i}>
      <FormField i={i} c={c} {...props}/> 
      {c.component === 'table_view'?
        <TableColView i={i} formField={c} columns={c.columns} {...props}/>
        :
        null
      }
    </Fragment>
  )
})

const TableColView = (props) => props.columns && props.columns.map((c, i) => {
  c.id = props.formField.id;

  return <FormField key={i} c={c} isSubField={true} {...props}/> 
})

const FormField = ({c, i, form, formFields, isSubField=false, currentUser, setFormField, isSuperUser, updateStatus, openLocale, toggleChartConfig, updateOrgAttribute}) =>{
  return(
    <Fragment >
      <TR>
        {!isSubField && CheckAccess.checkIsAdmin(currentUser) &&
          <TD className="flex aligncenter coldir">
            <div className="d-flex" onClick={() => updateStatus(c, form.id)}>
              <input type="checkbox" className="checkbox-sm" 
              checked={c.inactive_status==null} readOnly />
            </div>
            <OrgAttributes updateOrgAttribute={updateOrgAttribute} field={c}/>
          </TD>
        }
        <TD>
          <span className={c.required?'req-field':''}>{c.label}</span> 
          <span className="fas fa-edit m-l-15" onClick={e=>openLocale(e, c, 'form_field', i, 'label')}/>
          {c.locale &&
            <div className="m-t-5">
              <LocalesPill 
              onSelect={(e, locale)=>openLocale(e, c, 'form_field', i, 'label', locale)}
              locales={c.locale?.label?Object.keys(c.locale.label):[]}/>
            </div>
          }      
        </TD>
        <TD>
          {c.description_text &&
            <div>
              <p dangerouslySetInnerHTML={{ __html: c.description_text }}/>
              <span className="fas fa-edit m-l-15" onClick={e=>openLocale(e, c, 'form_field', i, 'description_text')}/>
              {c.locale &&
                <div className="m-t-5">
                  <LocalesPill 
                  onSelect={(e, locale)=>openLocale(e, c, 'form_field', i, 'description_text', locale)}
                  locales={c.locale?.description_text?Object.keys(c.locale.description_text):[]}/>
                </div>
              }
            </div>
          }
          {c.description &&
            <div>
              <p dangerouslySetInnerHTML={{ __html: c.description }}/>
              <span className="fas fa-edit m-l-15" onClick={e=>openLocale(e, c, 'form_field', i, 'description')}/>
              {c.locale &&
                <div className="m-t-5">
                  <LocalesPill 
                  onSelect={(e, locale)=>openLocale(e, c, 'form_field', i, 'description', locale)}
                  locales={c.locale?.description?Object.keys(c.locale.description):[]}/>
                </div>
              }
            </div>
          }
          {c.tooltip &&
            <div>
              <p dangerouslySetInnerHTML={{ __html: c.tooltip }}/>    
              <span className="fas fa-edit m-l-15" onClick={e=>openLocale(e, c, 'form_field', i, 'tooltip')}/>
              {c.locale &&
                <div className="m-t-5">
                  <LocalesPill 
                  onSelect={(e, locale)=>openLocale(e, c, 'form_field', i, 'tooltip', locale)}
                  locales={c.locale?.tooltip?Object.keys(c.locale.tooltip):[]}/>
                </div>
              }
            </div>
          }
        </TD>
        <TD>
          {c.data_source_id &&
            <div className="m-t-5">
              <div className="badge-2 br-r-unset bg-lred m-l-5" onClick={e=>setFormField(e, c)}>{c.data_source_name}</div>
              <br/>
              <LocalesPill 
                locales={c.data_source_locale} field={c} 
                onSelect={(e, locale)=>setFormField(e, c, locale)}/>
            </div>
          }         
        </TD>
        {!isSubField && CheckAccess.checkIsAdmin(currentUser) &&
          <TD>
            <ChartAttributes field={c} toggleChartConfig={toggleChartConfig}/>         
          </TD>
        }
      </TR>  
    </Fragment>
  )
}

const FormTemplateView = ({formTemplates, formFields, setFormField, setFormTabs, formTabs, updateStatus, handleClick, currentUser, openLocale, checkAllFields, uncheckAllFields, index, isSuperUser, toggleChartConfig, updateOrgAttribute}) => formTemplates.map((o, k) => {
    const boxShadow = index === k ? {border: "2px solid #00A2FF", boxShadow: "0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)"} : {}
    return(
      <Fragment key={k}>
        <div className="col-xs-12 br-grey m-b-15" key={k} style={boxShadow} id={'tab_'+ k}>
          <div className="black bg-white p-5 d-flex brbd-2 p-l-15" onClick={()=>handleClick(o, k)}>
            <div className="font-16 w-100p">
              {o.post.title} 
              <i className="fas fa-edit m-l-15" onClick={e=>openLocale(e, o, 'form_title', k)}/>
              {o.locale_title &&
                <div className="m-t-5">
                  <LocalesPill 
                  onSelect={(e, locale)=>openLocale(e, o, 'form_title', k, null, locale)}
                  locales={Object.keys(o.locale_title)}/>
                </div>
              }
            </div>
            <i className={'m-r-10 p-5 font-24 far ' + (index === k ? 'fa-caret-square-down' : 'fa-caret-square-up')} />
          </div>
          
          {index === k && 
            <div key={k} className="ht-250 bg-white p-5 p-l-15 scroll-auto">
              <div className="d-flex aligncenter">
                <p dangerouslySetInnerHTML={{__html: o.post.details}}></p>
                <i className="fas fa-edit m-l-15" onClick={e=>openLocale(e, o, 'form_details', k)}/>
              </div>
              
              {o.locale_details &&
                <LocalesPill 
                onSelect={(e, locale)=>openLocale(e, o, 'form_details', k, null, locale)}
                locales={Object.keys(o.locale_details)}/>
              }
              <div className="brbd-2"/>

              <div className="col-xs-12 m-b-5 m-t-10">
                <div className="bold-600">Tab Label</div>
                <FormTabsView form={o} 
                  formTabs={formTabs} 
                  currentUser={currentUser} 
                  setFormTabs={setFormTabs} 
                  isSuperUser={isSuperUser} 
                  openLocale={openLocale} 
                />
              </div>

              <div className="col-xs-12 m-b-5 m-t-10">
                {CheckAccess.checkIsAdmin(currentUser) &&
                  <div className="pull-left">
                    <span className="badge-2 br-r-unset bg-black" onClick={e=>checkAllFields(o.id)}>Check All</span>
                    <span className="badge-2 br-r-unset bg-black" onClick={e=>uncheckAllFields(o.id)}>Uncheck All</span>
                  </div>
                }
                <Link 
                  className="pull-right underline font-15"
                  to={`/data_source_list/form/${currentUser.current_organization_id}`}>
                  Suggest updates to the forms
                </Link>
              </div>
                      
              <div className="col-xs-12 table-responsive p-0">
                <Table className="shadow-small w-100p">
                  <THead>
                    <TR>
                      {CheckAccess.checkIsAdmin(currentUser) &&
                        <TH className="p-2 bold-400 text-center miw-95">
                          Active 
                        </TH>
                      }
                      <TH>
                        Label 
                      </TH>
                      <TH>
                        Note 
                      </TH>
                      <TH>
                        Data Source
                      </TH>
                      {CheckAccess.checkIsAdmin(currentUser) &&
                        <TH>
                          Charts
                        </TH>
                      }
                    </TR>
                  </THead>
                  <tbody>
                    <FormFieldsView form={o} formFields={formFields} 
                      currentUser={currentUser} setFormField={setFormField} 
                      isSuperUser={isSuperUser} updateStatus={updateStatus} 
                      openLocale={openLocale} updateOrgAttribute={updateOrgAttribute}
                      toggleChartConfig={toggleChartConfig}/>
                  </tbody>
                </Table>
              </div>
            </div>
          }
        </div>
      </Fragment>
    )
})
export default FormsList;