const exitAlertMsg = `If you click "OK" this form will close and you will lose the data you entered.

If you click "Cancel" this form will remain open and you can submit by clicking "Submit" or "Save draft".`

const Portal = {  
  cancer_journey:[3675, 3676],
  infant_journey:[3673, 3674, 3692],
}

const Locales = [
  {label:"English (default)", sid:"english", value:1},
  {label:'Gujarati (India)', sid:'gujarati', iso_code:'gu', value:4},
  {label:'Tamil (India)', sid:'tamil', iso_code:'ta', value:5},
  {label:'Marathi (India)', sid:'marathi', iso_code:'mr', value:3},
  {label:'Hindi (India)', sid:'hindi', iso_code:'hi', value:2},
  {label:'Kannada (India)', sid:'kannada', iso_code:'kn', value:11},
  {label:'Malayalam (India)', sid:'malayalam', iso_code:'ms', value:7},
  {label:'Telugu (India)', sid:'telugu', iso_code:'te', value:6},
]

const Permissions = [
  {label: 'Invite User', sid: 'invite_user'},
  {label: 'Roles', sid: 'roles'},
  {label: 'Reports', sid:'reports'},
  {label: 'Manage Planners', sid:'manage_planner'},
  {label: 'Manage Counseling', sid:'manage_counseling'}
]

const PermissionsObj = {
  invite_user: {label: 'Invite User', sid: 'invite_user'},
  roles: {label: 'Roles', sid: 'roles'},
  reports: {label: 'Reports', sid:'reports'},
  manage_planners: {label: 'Manage Planners', sid:'manage_planner'},
  manage_counseling: {label: 'Manage Counseling', sid:'manage_counseling'}
}

const AdminTypes = [
  {'label':'Admin', 'key':'role_admin'},
  {'label':'Deputy Admin', 'key':'role_dupty_admin'}
]

const FormAlertTypes = [
  {label: 'Single prompt based on group selection of values', sid: 'min_selection'},
  {label: 'Individual prompt based on individual values', sid: 'data_source_list'},
]

const DataSourceSelectionType = [
  {label: 'Selected', sid: 'selected'},
  {label: 'UnSelected', sid: 'un_selected'},
]

const ActiveStatusList = [
  {label:'ALL', value:null},
  {label:'Active', value:'true'},
  {label:'InActive', value:'false'},
]

/*const JourneyStatusList = [
  {label:'Active', value:'active'},
  {label:'Inactive', value:'inactive'},
  {label:'Closed', value:'closed'},
]*/

const DefaultFilter = {label:'ALL', value:null}

const setDefaultLabel = (options_obj, options_list) =>{
  if(options_obj){
    options_obj[0] = DefaultFilter
  }else if(options_list){
    options_list.unshift(DefaultFilter)
  }
}

const PreScheduleEventsDayList = []

const setEventsDayList = (day_diff, last_day=365) =>{
  for (let i = 1; i <= last_day; i = i+day_diff) {
    PreScheduleEventsDayList.push({label:`Day ${i} - Day ${i+day_diff}`, start:i, end:i+day_diff})
  }
}
setEventsDayList(30)

const OrgRoleConfirmOptions = [
  {label: 'Yes', sid: 'roles_created', color:'bg-submit'},
  {label: 'No', sid: 'roles_not_created', color:'bg-cancel'},
]

const DataSourcePermissionKey = {
  3530:'infant_portal',
  3673:'infant_ppt',
  3674:'infant_process',
  3737:'menu'
}

const InfantGrowthZscoreLabel = {
  SUW:{label:'Severely Underweight', backgroundColor:'rgb(245, 77, 89)'},
  MUW:{label:'Moderately Underweight', backgroundColor:'black'},
  SS:{label:'Severely Stunted ', backgroundColor:'rgb(245, 77, 89)'},
  MS:{label:'Moderately Stunted', backgroundColor:'black'},
  SAM:{label:'Severely Acute Malnourished', backgroundColor:'rgb(245, 77, 89)'},
  MAM:{label:'Moderately Acute Malnourished', backgroundColor:'black'},
}

const DateRangeList = [
  {label:'1W', value:'1W'},
  {label:'2W', value:'2W'},
  {label:'4W', value:'4W'},
  {label:'13W', value:'13W'},
  {label:'6M', value:'6M'},
  {label:'1Y', value:'1Y'},
  {label:'3Y', value:'3Y'},
  {label:'5Y', value:'5Y'},
]

const DataSources = {  
  OrganizationJourneyMenu:3419,
  IndividualJourneyMenu:3838
}

let WHOMetricsList = [
  { label: "Weight-for-age", sid: "weight_age", value: 1 },
  { label: "Length-for-age", sid: "length_age", value: 0 },
  { label: "Weight-for-length", sid: "length_weight", value: 2 },
  { label: "Head circumference-for-age", sid: "head_age", value: 3 },
]

let AgeDataFilterList = [
  { label: "Age Filter" },
  { label: "1W", value: 1, type:'week' },
  { label: "2W", value: 2, type:'week' },
  { label: "4W", value: 4, type:'week' },
  { label: "13W", value: 13, type:'week' },
  { label: "6M", value: 6, type:'month' },
  { label: "1Y", value: 1, type:'year' },
  { label: "3Y", value: 3, type:'year' },
  { label: "5Y", value: 5, type:'year' },
]

let WHOGraphList = [
    {
      tab:'Weight-for-age',
      graphs:[
        {
          id:'1',
          label: "Weight-for-age",
          who_key: "WhoWeightVsAgePercentiles",
          data_key: "WeightVsAge",
          x_axis_label: "Months",
          y_axis_label: "Weight (Kgs)",
          y_axis_min:2,
        },
        {
          id:'2',
          label: "Weight-for-age (Z score)",
          who_key: "WhoWeightVsAgePercentiles",
          who_zscore_key: "WhoWeightVsAgeZscores",
          data_key: "WeightVsAge",
          zscore_data_key: "WeightVsAgeZscore",
          x_axis_label: "Months",
          y_axis_label: "Weight (Kgs)",
          is_zscore_graph:true,
          y_axis_min:1,
          ytick_amount:8
        },
      ]
    },
    {
      tab:'Length-for-age',
      graphs:[
        {
          id:'4',
          label: "Length-for-age",
          who_key: "WhoLengthVsAgePercentiles",
          data_key: "LengthVsAge",
          x_axis_label: "Months",
          y_axis_label: "Height (cms)",
        },
        {
          id:'5',
          label: "Length-for-age (Z score)",
          who_key: "WhoLengthVsAgePercentiles",
          who_zscore_key: "WhoLengthVsAgeZscores",
          data_key: "LengthVsAge",
          zscore_data_key: "LengthVsAgeZscore",
          x_axis_label: "Months",
          y_axis_label: "Height (cms)",
          is_zscore_graph:true
        },
      ]
    },
    {
      tab: "Weight-for-length",  
      graphs:[
        {
          id:'7',
          label: "Weight-for-length",
          who_key: "WhoWeightVsHeightPercentiles",
          data_key: "LengthVsWeight",
          x_axis_label: "Height (cms)",
          y_axis_label: "Weight (Kgs)",
          y_axis_min:1,
          y_axis_max: 28,
          ytick_amount:12,
        },
        {
          id:'8',
          label: "Weight-for-length (Z score)",
          who_key: "WhoWeightVsHeightPercentiles",
          who_zscore_key: "WhoWeightVsHeightZscores",
          data_key: "LengthVsWeight",
          zscore_data_key: "LengthVsWeightZscore",
          x_axis_label: "Height (cms)",
          y_axis_label: "Weight (Kgs)",
          y_axis_min:1,
          y_axis_max: 28,
          ytick_amount:12,
          is_zscore_graph:true
        },
      ]
    }
]

const GraphMetricsList = [
   {label:'Mothers', sid:'health_metrics'},
   {label:'Infants', sid:'infant_metrics'},
   {label:'Growth metrics', sid:'who_median'},
   /*{label:'Service delivery', sid:'who_median'},*/
   {label:'Resource management', sid:'user_report'},
   {label:'By Geography', sid:'geography'},
   {label:'By Notification', sid:'notification'}
]

const PresetDateRange = [
  {label:'Last 30 days', sid:'last_30_days'},
  {label:'Last Calendar Month', sid:'last_month'},
  {label:'Last 90 days', sid:'last_90_days'},
]

const MaternalPregnancyFilter = [
  {label:'Number of new MCJ registrations', sid:'new_mcj', data_key:'maternal_pregnancy', chart:'bar'},
  {label:'Zscore Statics Table', sid:'zscore_statics', data_key:'zscore_statics', chart:'table'},
  {label:'Stage of pregnancy', sid:'pregnancy_stage', data_key:'maternal_pregnancy', chart:'pie'},
  {label:'Education Status', sid:'education', data_key:'maternal_pregnancy', chart:'pie'},
  {label:'Age of mother', sid:'mother_age', data_key:'maternal_pregnancy', chart:'bar'},
  {label:'Mother Weight', sid:'mother_weight', data_key:'maternal_pregnancy', chart:'treemap'},
  {label:'Mother Height', sid:'mother_height', data_key:'maternal_pregnancy', chart:'treemap'},
  {label:'Number of new births', sid:'new_birth_count', data_key:'child_birth', chart:'pie'},
  {label:'Location of delivery', sid:'delivery_location', data_key:'child_birth', chart:'pie'},
  {label:'Type of delivery', sid:'delivery_type', data_key:'child_birth', chart:'pie'},
  {label:'Completed weeks of pregnancy at delivery', sid:'gestational_week', data_key:'child_birth', chart:'bar'},
  {label:'Gender of child', sid:'gender', data_key:'child_birth', chart:'pie'},
  {label:'Distribution of baby weight at birth', sid:'birth_weight', data_key:'child_birth', chart:'treemap'},
  {label:'Distribution of baby length at birth', sid:'birth_height', data_key:'child_birth', chart:'treemap'},
  {label:'Key reason for not recording details', sid:'reason_no_measure', data_key:'growth_measurement', chart:'pie'},
  {label:'Location and stage when measurement taken', sid:'measurement_location', data_key:'growth_measurement', chart:'pie'},
  {label:'Babys weight (in kgs)', sid:'baby_weight', data_key:'growth_measurement', chart:'treemap'},
  {label:'Length of baby (in centimeters)', sid:'baby_height', data_key:'growth_measurement', chart:'treemap'},
  {label:'Is the child suffering from any illness', sid:'illness_list', data_key:'growth_measurement', chart:'pie'},
]

const LeaderboardCharts = {
  taluka:[
    {label:'Top 10 list of Talukas with most MCJs registered', sid:'most_registered', data_key:'talukas', chart:'bar'},
    {label:'Bottom 10 list of Talukas with most MCJs registered', sid:'least_registered', data_key:'talukas', chart:'bar'},
    {label:'Top 10 list of Talukas with most weights under WHO median', sid:'weight_below_median', data_key:'talukas', chart:'bar'},
    {label:'Top 10 list of Talukas with most weights above WHO median', sid:'weight_above_median', data_key:'talukas', chart:'bar'},
    {label:'Top 10 list of Talukas with most heights under WHO median', sid:'height_below_median', data_key:'talukas', chart:'bar'},
    {label:'Top 10 list of Talukas with most heights above WHO median', sid:'height_above_median', data_key:'talukas', chart:'bar'},
  ],
  village:[
    {label:'Top 10 list of Villages with most MCJs registered ', sid:'most_registered', data_key:'villages', chart:'bar'},
    {label:'Bottom 10 list of Villages with most MCJs registered', sid:'least_registered', data_key:'villages', chart:'bar'},
    {label:'Top 10 list of Villages with most weights under WHO median', sid:'weight_below_median', data_key:'villages', chart:'bar'},
    {label:'Top 10 list of Villages with most weights above WHO median', sid:'weight_above_median', data_key:'villages', chart:'bar'},
    {label:'Top 10 list of Villages with most heights under WHO median', sid:'height_below_median', data_key:'villages', chart:'bar'},
    {label:'Top 10 list of Villages with most heights above WHO median', sid:'height_above_median', data_key:'villages', chart:'bar'},
  ],
  medical_facility:[ 
    {label:'Top 10 list of Facilities with most MCJs registered', sid:'most_registered', data_key:'facilities', chart:'bar'},
    {label:'Bottom 10 list of Facilities with most MCJs registered', sid:'least_registered', data_key:'facilities', chart:'bar'},
    {label:'Top 10 list of Facilities with most weights under WHO median', sid:'weight_below_median', data_key:'facilities', chart:'bar'},
    {label:'Top 10 list of Facilities with most weights above WHO median', sid:'weight_above_median', data_key:'facilities', chart:'bar'},
    {label:'Top 10 list of Facilities with most heights under WHO median', sid:'height_below_median', data_key:'facilities', chart:'bar'},
    {label:'Top 10 list of Facilities with most heights above WHO median', sid:'height_above_median', data_key:'facilities', chart:'bar'},
  ]  
}

/*const InfantActivityFilter = [
  {label: 'Show previous week', per_page:15, date_type:'past_week'},
  {label: 'Show next week', per_page:15, date_type:'next_week'},
  {label: 'Show all past details', per_page:15, date_type:'all_past_details'},
  {label: 'Show all upcoming details', per_page:15, date_type:'all_future_details'}
]*/

const ApexResponsive = {
  pieChart:[
          {
            breakpoint: 1000,
            options: {
              chart: {
                width: 475,
              }
            }
          },
          {
            breakpoint: 500,
            options: {
              chart: {
                width: 350,
              }
            }
          }
        ]
}

const ChartTypes = [
  {label: 'Bar', sid: 'bar'},
  {label: 'Pie', sid: 'pie'},
  {label: 'Line', sid:'line'},
  {label: 'Tree Map', sid:'treemap'}
]

const OrgFilter = [
  {label: 'No Org Assignment', sid: 'no_org_mapping'},
]

const UserRoleFilter = [
  {label: 'No Alert Recipient', sid: 'no_alert_recipient'}
]

const NoFormAlertFilter = [
  {label: 'No Form Configuration', sid: 'no_form_field_mapping'},
]

const MemberFilter = [
  {'label':'No Member assignment', 'sid':'no_member_assigned'},
]

const ImageUploadFilter = [
  {'label':'No image upload', 'sid':'no_image_upload'},
]

export {
  DataSources, AdminTypes, OrgRoleConfirmOptions, Portal, Locales, exitAlertMsg,
  Permissions, PermissionsObj, FormAlertTypes, DataSourceSelectionType, 
  ActiveStatusList, DefaultFilter,  setDefaultLabel, PreScheduleEventsDayList,
  DataSourcePermissionKey, InfantGrowthZscoreLabel, DateRangeList,
  WHOMetricsList, AgeDataFilterList, WHOGraphList, GraphMetricsList, PresetDateRange,
  MaternalPregnancyFilter, LeaderboardCharts, ApexResponsive, 
  ChartTypes, OrgFilter, UserRoleFilter, NoFormAlertFilter, MemberFilter, ImageUploadFilter
}