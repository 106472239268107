import React, { Fragment, useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import WellnessService from "../WellnessService"
import useStyle from "../../../../hooks/useStyle"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../PreLoadSpinner"
import Cs from "../../../../services/CommonService"
import querystringify from "querystringify"
import useModal from "../../../../hooks/useModal"
import Title from "../../GenericPortalPage/Title"
import GenericModal from "../../../Modals/GenericModal"
import {usePageTitle} from "../../../../hooks/pageHead"
import PainTrackingTable from "./PainTrackingTable"
import SideEffectsTable from "./SideEffectsTable"
import SymptomsTable from "./SymptomsTable"
import WellnessLineGraph from "./WellnessLineGraph"

let requestParams = {}
let dataSources = {}
const WellnessBlockPage = (props) => {
  useStyle('/css/tile-components.css', true)

  let params = props.match.params
  const {journeyProfile, component} = props.location.state
  const {child_form:formId} = component

  const {state: { user: currentUser }, dispatch} = useContext(AuthContext)
  
  const [ loading, setLoading ] = useState(true)
  const [ symptoms, setSymptomList ] = useState([])
  const [ graphData, setGraphData ] = useState({})
  const [ formFields, setFormFields ] = useState({})
  const [ type, setType ] = useState('')

  usePageTitle(journeyProfile?.data?.mother_name)
  
  useEffect(() => {
    requestParams = {
      page:1,
      per_page:7,
      sort_column:'updated_at',
      sort_direction:'desc',
      journey_profile_id: journeyProfile.id,
      symptom_form_id:formId,
      add_line_chart:true,
      form_fields_params: {
        ids:[formId],
        is_table_view:true
      }
    }
    
    if(component.sid == 'pain_tracking'){
      getPainRecords()
    }else if(component.sid == 'symptoms'){
      getSymptomsRecords()
    }else if(component.sid == 'side_effects'){
      getSideEffects()
    }
  }, [props.searchString])

  let getPainRecords = () => {
    setLoading(true)
    requestParams.chart_type = 'pain_tracking'
    requestParams.date_filter = "week"
    requestParams.data_source_params = {
      data_source_id: [3441, 3436, 3470, 3464, 3431, 3428]
    }

    WellnessService.getAll(requestParams).then(({status, data})=>{
      dataSources = data.data_source_list
      setSymptomList(data.symptoms)
      setGraphData({'bar_chart':data.bar_chart, 'line_chart':data.line_chart})
      setFormFields(data.form_fields)
      setLoading(false)
    })
  }

  let getSymptomsRecords = () => {
    setLoading(true)
    requestParams.chart_type = 'symptoms'
    requestParams.date_filter = 'week'
    requestParams.data_source_params = {
      data_source_id: [3672]
    }

    WellnessService.getAll(requestParams).then(({status, data})=>{
      dataSources = data.data_source_list
      setSymptomList(data.symptoms)
      setGraphData({'bar_chart':data.bar_chart, 'line_chart':data.line_chart})
      setFormFields(data.form_fields)
      setLoading(false)
    })
  }

  let getSideEffects = () => {
    setLoading(true)
    requestParams.chart_type = 'side_effects'
    requestParams.date_filter = "week"
    requestParams.data_source_params = {
      data_source_id: [3441, 3436, 3464, 3431, 3470, 3457]
    }

    WellnessService.getAll(requestParams).then(({status, data})=>{
      dataSources = data.data_source_list
      setSymptomList(data.symptoms)
      setGraphData({'bar_chart':data.bar_chart, 'line_chart':data.line_chart})
      setFormFields(data.form_fields)
      setLoading(false)
    })
  }

  const TreeMap = () =>{
    if(component.sid == 'symptoms'){
      return null
    }else if(component.sid == 'side_effects'){
      return(
        <div className="br-grey ht-450 wk-center bg-white">
          <WellnessLineGraph type="side_effects" dataSource={dataSources} 
            report={graphData.line_chart}
            reportList={dataSources.side_effects_multiselect.options}/>              
        </div>
      )
    }else if(component.sid == 'pain_tracking'){
      return(
        <div className="br-grey ht-450 wk-center bg-white">
          <WellnessLineGraph type="pain_tracking" dataSource={dataSources} 
            report={graphData.line_chart}
            reportList={dataSources.area_complaint.options}/>              
        </div>
      )
    }
  }

  if (loading) return <PreLoadSpinner />

  return (
    <div id="ct">
      <div className="app_content">
        <div className="app-header flex justspacebetween m-t-15">
          <div className="flex coldir">
            <Title 
            title={journeyProfile.data_source?.health_condition?.label} 
            project={journeyProfile} />
          </div>
        </div>
      </div>  

      <div className="page-content row">
        {loading ? <div className="spinner"/> : <TreeMap/>}    
      </div>
    </div>
  )
}

export default WellnessBlockPage;