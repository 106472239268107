import React, {useState, Fragment, useContext, useEffect} from 'react'
import FilterSlider from "../../../../Common/FilterSlider"
import RoleAndUserFilter from '../../../../NotifiableRoles/RoleAndUserFilter'
import {OrgFilter, UserRoleFilter, NoFormAlertFilter} from '../../../../../constants/DataSourceList'
import {CheckBox} from "../../../../Common/FormInput"
import useModal from '../../../../../hooks/useModal'
import SortDirection from '../../../../Common/SortDirection'
import useSort from '../../../../../hooks/useSorting'
import FormHelper from '../../../../FormBuilder/FormHelpers'

const Filters = ({filter, setFilter, dataSource, currentUser, getCounselingList, requestParams, isTemplate}) =>{
  const {sort} = useSort(requestParams, getCounselingList)

  const toggleFilter = (value, name) => {
    const setter = (list) => {
      if(list?.length === 0){
        delete filter[name]
        setFilter({...filter})
      }else{
        setFilter({...filter, [name]:list})
      }
    }
    FormHelper.toggleListString(value, filter[name], setter)
    /*if(value && value != filter[name]){
      setFilter({...filter, [name]:value})
    }else{
      delete filter[name]
      setFilter({...filter})
    }*/
  }

  const stringFilter = (value, name) => {
    if(name === 'assignment_filter'){
      if(value === 'no_org_mapping'){
        delete filter.assigned_to_organization_id
      }else if(value === 'no_alert_recipient'){
        delete filter.role_id
        delete filter.user_id
      }
    }
    
    if(value!=null && value != filter[name]){
      setFilter({...filter, [name]:value})
    }else{
      delete filter[name]
      setFilter({...filter})
    }
  }

  const filterExists = (value, name) => FormHelper.existsListString(value, filter[name])

  return (
    <div className="filter-box-scroll p-5">
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Assignment Filter
        </h5>

        <CheckBox list={isTemplate?OrgFilter:UserRoleFilter}
          labelKey="label" valueKey="sid" 
          toggle={stringFilter} exists={filterExists} 
          name="assignment_filter"/>

        
        <CheckBox list={NoFormAlertFilter}
          labelKey="label" valueKey="sid" 
          toggle={stringFilter} exists={filterExists} 
          name="assignment_filter"/> 
      </div>
      
      {isTemplate && filter.assignment_filter !== 'no_org_mapping' &&
        <div className="bg-white">
          <h5 className="font-16 text-center bg-highlight white">
            Organizations
          </h5>

          <CheckBox list={currentUser.organizations || []} 
            labelKey="name" valueKey="id" 
            toggle={stringFilter} exists={filterExists} 
            name="assigned_to_organization_id"/> 
        </div>
      }
        
      {!isTemplate &&
        <RoleAndUserFilter toggle={stringFilter} exists={filterExists}/>
      }

      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          {dataSource.feeding_mini_journey.label}
        </h5>

        <CheckBox list={dataSource.feeding_mini_journey.options || []} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="stages"/> 
      </div>
      <div className="bg-white">
        <h5 className="font-16 text-center bg-highlight white">
          Language
        </h5>

        <CheckBox list={dataSource.languages.options || []} 
          labelKey="label" valueKey="value" 
          toggle={toggleFilter} exists={filterExists} 
          name="languages"/> 
      </div>
    </div>
  )
}

const FilterBtn = (props) =>{
  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const filterCount = Object.keys(props.filter).length

  return(
    <>
      <span data-tip="Filter" 
        className="bg-highlight round-btn m-r-5" 
        onClick={()=>toggleFilterModal()}>
          <i className="fas fa-sliders-h"/>
          {filterCount > 0 ? <span className="badge-2 bg-black-light-1 up">{filterCount}</span>:''}
      </span>

      {isFilterOpen &&
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={toggleFilterModal}
          {...props}/>
      }
    </>
  )
}

export {Filters, FilterBtn};