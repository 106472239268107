import React from "react"
import { Fragment } from "react"
import {Link} from "react-router-dom"
import CommonService from '../../../services/CommonService'
import useModal from "../../../hooks/useModal"
//import ActivityMenuModal from "../MotherInfantJourney/Activities/List/ActivityMenuModal"
import {OptionsObjList} from "../../Common/FormInput"
import {Table, THead, TH, TR, TD, TBody} from "../../Common/TableView"
import {PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon} from "../../Common/ImageIcons"
import Cs from '../../../services/CommonService'

const Dementia = (props) => {
  let {journey, dataSources, onDelete, openUserRole, routeJourney, openNotification, index, update, journeyStatusList, userLocale} = props
  let {data, data_source:ds} = journey
  const statusStyle = journey.status?{
    color:'white',
    backgroundColor: journeyStatusList.find(o=> o.sid === journey.status)?.bg_color
  }:{}

  return(
    <div className="bg-white border-rounded-10 card-shadow m-b-15">
      <div className="card-content p20">
        <div className="scroll-auto ht-150">
          <div className="flex">
            <h4 className="fw700 f18 text-left">
              {journey.record_id} - {data.patient_name} 
            </h4>
            <select className="form-control input-sm fw500 ml-auto w-100" style={statusStyle}
              onChange={e=>update(index, {id:journey.id, status:e.target.value})} 
              placeholder="Status" defaultValue={journey.status}>
                <OptionsObjList list={journeyStatusList} id_key='sid' label_key='label' user_locale={userLocale}/>
            </select>
          </div>
            
          <div className="m-b-10">
            {ds.health_condition?.label}
          </div>
        
          <div className="flex aligncenter m-b-10">
            <div className="flex aligncenter m-r-20">
              <PhoneIcon title={data.cell_number_formatted || <font className="c-bemedian">Mobile Not Provided</font>}/>
            </div>
            <div className="flex aligncenter">
              <MapIcon title={data.taluka || <font className="c-bemedian">Address Not Provided</font>}/>
            </div>
          </div>

          <div className="flex aligncenter m-b-10">
            <div className="flex aligncenter m-r-20">
              {Cs.formatUpdateDate(journey.updated_at)}
            </div>
            <div className="flex aligncenter">
              {journey.updated_user?.name}
            </div>
          </div>
        </div>
        <div>
          {journey.status != 'closed' && 
            <div className="flex aligncenter justifyflexend">
              <LinkMapIcon id="path_details" tooltip="View path details" 
                onSelect={()=>routeJourney(journey)}/>
              {journey.nutrition_care_alert_count > 0 &&
                <LinkMailIcon id="notification" count={journey.nutrition_care_alert_count} 
                  tooltip="Journey Notification" onSelect={()=>openNotification(journey)} 
                  className="far fa-envelope m-l-15 font-18 text-muted"/>
              }
              <LinkUserIcon tooltip="Assign journey" onSelect={()=>openUserRole(journey)}/>
              <LinkDeleteIcon id="delete_journey" tooltip="Delete journey" 
                onSelect={()=>onDelete(journey)}/>
            </div>
          }
        </div>
      </div>
    </div>
  )  
}

export default Dementia;