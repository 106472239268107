import React, {Fragment, useEffect, useState, useContext} from "react"
import useStyle from "../../hooks/useStyle"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import HelpService from "./HelpService"
import env from "../../env"
import CommonService from "../../services/CommonService"
import PreLoadSpinner from "../../components/PreLoadSpinner"
import ImagePlaceholder from "../Common/ImagePlaceholder"
import {SwiperContainer, SwiperSlide} from "../Common/Swiper"
import querystringify from "querystringify"
import CheckAccess from '../HealthJourney/Roles/CheckAccess'

let requestParams = {}
let cardViewCount = 1

const WelcomeTour = (props) => {
  useStyle("slideshow")

  let queryParam = querystringify.parse(props.location.search)

  const { state: {screen, user: currentUser}, dispatch } = useContext(AuthContext)
  const [welcomeTourList, setWelcomeTourList] = useState([])
  const [settings, setSetting] = useState(currentUser.setting)
  const [slideIndex, setSlideIndex] = useState(0)
  const [loading, setLoading] = useState(true)
  const [currentpage, setCurrentpage] = useState(1) 

  useEffect(() => {
    requestParams = {
      page: 1,
      paginate: 1,
      per_page: 10,
      sort_column: "updated_at",
      sort_direction: "desc",
      search: null
    }
    
    requestParams.journey_type_id = CheckAccess.getJourneyTypeIds(currentUser)

    getWelcomeTour()
  }, [currentUser.id])

  let getWelcomeTour = () => {
    HelpService.getHelpScreens(requestParams).then((res) => {
      if (res.status == 200) {
        //requestParams.totalItems = res.data.meta.total;
        requestParams.totalPages = Math.ceil(res.data.digital_contents.length / cardViewCount)
        setWelcomeTourList(res.data.digital_contents)
        setLoading(false)
      }
    })
  }
  
  const updateSetting = () => {
    let req = {id: settings.id, welcome_tour: 'Completed'} 
    CommonService.updateSetting(req).then((res) => {
      if (res.status == 204) {
        currentUser.setting = res.data.setting;
        dispatch({
          type: "UPDATE",
          payload: { user: currentUser },
        })
        routeUser(currentUser)
      }
    })
  }

  const routeUser = (user) =>{
    props.history.push("/health/table")
  }
  
  const ImageSvgRender = ({ content }) => {
    if(content.data.lottie){
      return null
    }else{
      return(
        <ImagePlaceholder 
          imageClass={`img-responsive m-t-15 m-b-15 ${screen.xs ? '' :'img-resize'}`} 
          url={content.data.image?env.file_url + content.data.image:''}/>
      )
    }
  }

  const showSlides = (n) => {
    if(n > (welcomeTourList.length-1)){
      setSlideIndex(0)
    }else if(n < 0){
      setSlideIndex(welcomeTourList.length-1)
    }else{
      setSlideIndex(n)
    }     
  }

  const slide = welcomeTourList.map((w, k) => 
    <SwiperSlide key={k}>
      <div className="mySlides ss-fade bg-white wk-center">
        { w.data.title && <div className="ss-text font-24 p-10">{w.data.title}<br/></div>}
        <div className="ss-numbertext">{k+1} / {welcomeTourList.length}</div>
        <div className="rich-text ss-text font-16" dangerouslySetInnerHTML={{ __html: w.data.description }}></div>
        <div><ImageSvgRender content={w}/></div>
      </div>
    </SwiperSlide>
  )
      
  if(loading){
    return(<PreLoadSpinner/>)
  }

  return (
    <Fragment>
      <div className="page-content bg-white">
        <h4 className="text-center font-18  p-7">New user welcome tour</h4>
        <SwiperContainer currentpage={currentpage} setCurrentpage={setCurrentpage}
          requestParams={requestParams} cardViewCount={cardViewCount} autoHeight="true">
            {slide}
        </SwiperContainer> 
        <div className="">
          <div className="p-20 m-b-40 bg-white col-xs-12 col-sm-push-8 text-center clearfix">
            <button className="butn ss-finish" onClick={(e) =>updateSetting()}>
              {slideIndex+1 == welcomeTourList.length ? 'Finish':'Skip'}
            </button>
            <div>Click this button to set up your account.</div>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default WelcomeTour;