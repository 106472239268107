import React, {useContext, Fragment} from 'react'
import EditDataSourceIcon from './EditDataSourceIcon'
import FormAlertMessage from "./FormAlertMessage"
import {HelpIcon} from "../Help/HelpSidePanel"

const FieldLabel = ({formId, field, labelAttributes, openFieldLabelModal, openDataSourceModal, currentLocale}) => {
   field.label = getLocaleLabel(field, currentLocale)
   
   return(
   	<label {...labelAttributes}>
         {field.icon && <i className={field.icon + " font-20 m-r-10"} style={{color:field.icon_color}}/>}
         <span dangerouslySetInnerHTML={{ __html: field.label}}/>
         {openDataSourceModal && <EditDataSourceIcon field={field} open={openDataSourceModal}/>}
         <EditDataSourceIcon field={field} open={openFieldLabelModal} iconClass="fas fa-edit"/>
         <FormAlertMessage field={field} formId={formId}/>
         <HelpIcon recordIds={field.help_id}/>
      </label>	
   )  
}

const getLocaleLabel = (field, currentLocale, key="label") => {
   return field.locale?.[key]?.[currentLocale] || field[key];
}

const OptionLabel = ({option, currentLocale}) => {
   return option.locale?.[currentLocale]?.label || option.label
}

const TabLabel = ({tab, currentLocale}) => {
   return( 
      <span>
         {tab.locale?.[currentLocale] || tab.label}
         <HelpIcon recordIds={tab.help_id}/>
      </span>
   )
}

const Description = ({description, currentLocale, locale, showOnHover=false}) => {
   return(
      <span className={`${showOnHover?'rich-text':''} fts-13 pull-left`} dangerouslySetInnerHTML={{ __html: (locale?.[currentLocale] || description) }}></span>   
   )
}

const FormTitle = ({title, locale, currentLocale}) => {
   return (
      <span className='pull-left'>{locale?.[currentLocale] || title}</span>
   )
}

const FormDescription = ({details, currentLocale, locale}) => {
   return(
      <span className="form-details" dangerouslySetInnerHTML={{ __html: (locale?.[currentLocale] || details) }}></span>   
   )
}

const getLocaleData = (data, currentLocale='english', localeKey='label') =>{
   return data?.locale_data?.[currentLocale]?.[localeKey] || data?.[localeKey]
}

const getLocale = (data, currentLocale='english', localeKey='label') =>{
   return data?.[currentLocale]?.[localeKey] || data?.[localeKey]
}

const requiredMsg = "You must select a value from the list that is displayed."

export {getLocaleLabel, FormTitle, FormDescription, FieldLabel, OptionLabel, Description, TabLabel, getLocaleData, getLocale, requiredMsg}