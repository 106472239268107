import React, { useState, Fragment, useEffect, useContext, useRef, useMemo } from "react"
import ReactTooltip from "react-tooltip"
import querystringify from "querystringify"
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider"
import { CacheContext } from "../../../../../contexts/Cache/CacheStateProvider"
import {Counseling} from "./Counseling"
import CounselingService from "../../Counseling/CounselingService"
import Pagination from "../../../../Pagination"
import ConfirmMenu from "../../../../Modals/ConfirmMenu"
import ProjectMenu from "../../../../Modals/ProjectMenu"
import FormTemplateId from "../../../../../constants/FormTemplateList"
import CommentsModal from "../../../../Modals/CommentsModal"
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import CardMenu from "../../../../Modals/CardMenu"
import { Link, useHistory } from "react-router-dom"
import useModal from "../../../../../hooks/useModal"
import useStyle from "../../../../../hooks/useStyle"
import PreLoadSpinner from "../../../../PreLoadSpinner"
import Cs from "../../../../../services/CommonService"
import Title from "../../../GenericPortalPage/Title"
import env from "../../../../../env"
import NotifiableRolesPopup from '../../../../NotifiableRoles/NotifiableRolesPopup'
import GenericModal from '../../../../Modals/GenericModal'
import LocaleList from "../../../../Locale/LocaleMenu"
import CheckAccess from "../../../Roles/CheckAccess"
import SelectContainer from "../../../../Common/SelectContainer"
import FilterSlider from "../../../../Common/FilterSlider"
import {CheckBox} from "../../../../Common/FormInput"
import {MenuItem, NavIcon} from '../../../../Common/MenuItem'
import DataLogList from '../../../../DataLogs/DataLogList'
import SideMenuBar from "../../../../Common/SideMenuBar"
import {usePageTitle} from '../../../../../hooks/pageHead'
import {ReadCsvFile, CsvTableView} from "../../../../Common/ReadCsvFile"
import FormHelper from "../../../../FormBuilder/FormHelpers"
import PaginationSM from '../../../../Common/PaginationSM'
import DataSourceMenu from "../../../../Modals/DataSourceMenu"
import { Table, THead, TH, TR, TD, TBody } from "../../../../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../../../../Common/ImageIcons"
import useSort from '../../../../../hooks/useSorting'
import SortDirection from '../../../../Common/SortDirection'
import {PillList} from '../../../UserRoles/NotificationPill'
import {LocaleLabel, LocaleLabelList} from "../../../../Common/FormInput"
import {OrgFilter, UserRoleFilter} from '../../../../../constants/DataSourceList'
import OrgDataMapperTable from '../../../../OrgDataMapper/OrgDataMapperTable'
import {DataSourceMapperIcon} from "../../../../FormBuilder/MapperDataSourceListModal"
import {FilterBtn} from './Filters'

let requestParams = {}
let selectedCounseling = {}
let dataSource = null
let pageTitle = null
const CounselingList = (props) => {
  useStyle('/css/tile-components.css', true)
  usePageTitle('Counseling List')

  let params = props.match.params
  let {project, child} = props.location.state || {}
  let queryParam = querystringify.parse(props.location.search)
  const {isPopupMode} = props
  const history = useHistory()

  let cardGridCss = "col-md-4"
  let cardHeightCss = "ht-420"
  if (props.id) {
    cardGridCss = "col-sm-12"
    cardHeightCss = "mih-380"
  }

  const {state: { screen, user: currentUser }, dispatch} = useContext(AuthContext)
  const {cacheState, cacheDispatch} = useContext(CacheContext)
  
  //const isMaster = CheckAccess.checkIsAdmin(currentUser)
  const {isTemplate, filterCacheKey} = useMemo(() => 
    (props.location.pathname === '/templates/counselings')?
    {isTemplate:true, filterCacheKey:'counselingTemplateFilters'}
    :
    {isTemplate:false, filterCacheKey:'counselingFilters'}
    , [props.location]
  )
  const permission = useMemo(() => 
    CheckAccess.getMenuPermission(currentUser, 'counseling'), [currentUser.id]
  )

  const [counselingList, setCounselingList] = useState([])
  const [loading, setLoading] = useState(false)
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [filter, setFilter] = useState(cacheState?.[filterCacheKey] || {})
  const [formFields, setFormFields] = useState()
  const [stepPlanners, setStepPlanners] = useState({})
  const [parsedCsvDataList, setParsedCsvDataList] = useState([])
  const [csvFormFields, setCsvFormFields] = useState([])
  const [csvFormType, setCsvFormType] = useState()
  const [isCsvBtnClicked, setCsvBtnClicked] = useState(false)
  const [showTableView, setShowTableView] = useState(true)
  let   [sortParam, setSortParam] = useState({})

  const { isOpen: isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen: isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal} = useModal()
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()
  const { isOpen: isAssessMenuOpen, toggleModal: toggleAssessMenuModal} = useModal()
  const { isOpen: isNotifiableRoleOpen, toggleModal:toggleNotifiableRoleModal } = useModal()
  const { isOpen: showLocaleModal, toggleModal: toggleLocaleModal} = useModal()
  const { isOpen: isDataLogOpen, toggleModal:toggleDataLogModal } = useModal()
  const { isOpen: isCsvTableViewOpen, toggleModal: toggleCsvTableView } = useModal()
  const { isOpen: isCsvUploadOpen, toggleModal: toggleCsvUpload} = useModal()
  const { isOpen: isCounselingMenuOpen, toggleModal: toggleCounselingMenu } = useModal()
  const { isOpen: isCounselingOpen, toggleModal: toggleCounselingModal } = useModal()
  const { isOpen: isOrgDataMapperOpen, toggleModal: toggleOrgDataMapper } = useModal()

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      ...sortParam,
      page: currentpage,
      per_page: 15,
      search: props.searchString,
      filter: filter,
      id: props.id,
      fields:['step_planners'],
      data_source_params: {
        data_source_id: [3613, 3615, 3887]
      },
      form_fields_params:{
        ids:[485],
        locale:currentUser.current_locale
      }
    }

    if(currentpage != 1){
      delete requestParams.form_fields_params
      delete requestParams.data_source_params
    }
    
    if(isTemplate){
      requestParams.is_template = true
      requestParams.fields.push('assigned_organizations')
      //requestParams.is_template = CheckAccess.checkOrgAccess(currentUser, 'counseling')
      pageTitle = 'Counseling Templates'
    }else{
      requestParams.fields.push('alert_recipients')
      pageTitle = 'Counseling List'
    }

    getCounselingList()  

    return () => {
      requestParams = {}
      cacheDispatch({
        type: "UPDATE",
        payload: {
          [filterCacheKey]:filter
        }
      })
    }  
  }, [currentpage, props.searchString, filter, props.location, sortParam])

  let getCounselingList = () => {
    if(permission.read){
      setLoading(true)
      CounselingService.getAll(requestParams).then(({status, data}) => {
        if (status === 200) {
          setPagemeta(data.meta)
          if(currentpage === 1){
            dataSource = data.data_source_list
            setFormFields(data.form_fields)
          }
          setCounselingList([...data.counselings])
          setStepPlanners(Cs.listToObj(data.step_planners, 'id'))
          setLoading(false)
        }
      })
    }
  }

  const deleteCounseling = (e) => {
    e.preventDefault()
    if(permission.write){
      setLoading(true)
      CounselingService.delete(selectedCounseling.id).then((res) => {
        if (res.status === 204) {
          setCounselingList(
            counselingList.filter((i) => i.id !== selectedCounseling.id)
          )
          setLoading(false)
        }
      })
    }
    toggleDeleteModal(e)
  }

  const update = (req, index) => {
    if(permission.write){
      setLoading(true)
      CounselingService.update(req).then(({status})=>{
        if(status === 204){
          if(req.data)
            counselingList[index].data = req.data
          setCounselingList([...counselingList])
          setLoading(false)
          //update(req, index)
        }
      })
    }
  }

  const showMenu = (e, a) => {
    selectedCounseling = a
    toggleMenuModal(e)
  }

  const routeForm = (datasource) =>{
    if(isCsvBtnClicked){
      setCsvFormType({...datasource})
      setCsvBtnClicked(false)
      console.log(isCsvUploadOpen)
      toggleCounselingMenu()
      toggleCsvUpload()
    }else{
      history.push(
        `/infant/counseling/form/${datasource.sid}/${datasource.counseling_child_form}?is_template=${isTemplate || false}`
      )  
    }
  }

  const onDelete = (e, a) => {
    selectedCounseling = a
    toggleDeleteModal(e)
  }

  const addChecklist = (e, a) => {
    selectedCounseling = a
    toggleChecklistModal(e)
  }

  const addNotes = (e, a, selected_text) => {
    selectedCounseling = a
    selectedCounseling.selectedText = selected_text
    toggleCommentModal(e)
  }

  /*const openMapper = (a) =>{
    selectedCounseling = a
    togglePlannerMapper()
  }*/

  const addNotifiableRole = (e, item) => {
    e.preventDefault()
    if(permission.write){
      selectedCounseling = item
      toggleNotifiableRoleModal(e)
    }
  }

  const translateAndClone = (e, item) => {
    if(permission.write){
      selectedCounseling = Cs.deepCopy(item); //Deep copy
      selectedCounseling.id = null
      selectedCounseling.data_source = null
      selectedCounseling.record_id = null 
      selectedCounseling.clone_id = item.id
      toggleLocaleModal(e)
    }
  } 

  const onTranslateComplete = (translatedData) => {
    toggleLocaleModal()
    translatedData.created_by = currentUser.id
    translatedData.updated_by = currentUser.id
    CounselingService.create(translatedData).then((res) => {
      if(res.status===201){
        setCounselingList([res.data.counseling, ...counselingList])
      }
    }).catch(function(res){
       
    })
  } 

  const openLogs = (e, item) =>{
    if(permission.write){
      selectedCounseling = item
      toggleDataLogModal(e)
    }
  }

  const closeCounselingMenu = () =>{
    setCsvBtnClicked(false)
    setCsvFormType(null)
    toggleCounselingMenu()
    isCsvUploadOpen && toggleCsvUpload()
  }

  const onReadCallback = (contents) =>{
    if(permission.write){
      let req = {
        user_id: currentUser.id,
        organization_id:currentUser.current_organization_id,
        contents:contents
      }
      CounselingService.uploadCsv(req).then(({status, data}) => {
        if (status === 200) {
          toggleCsvUpload()
          setParsedCsvDataList(data.parsed_rows)
          setCsvFormFields(data.form_fields)
          toggleCsvTableView()
        }else if(status === 433){
          alert(data.error)
        }
      })
    }
  }

  const insertParsedCsv = () => {
    if(permission.write){
      CounselingService.create({
        form_id: csvFormType.counseling_child_form,
        form_type:csvFormType.sid,
        organization_id: currentUser.current_organization_id,
        is_template:true,
        bulk_insert:true,
        counselings:parsedCsvDataList
      }).then((res) => {
        if (res.status === 201) {
          getCounselingList()
          toggleCsvTableView()
          setCsvFormType(null)
        }else{
          alert(res.data.error || 'Upload Error')
        }
      })
    }
  }

  const openOrgDataMapperTable = (e, item) =>{
    if(permission.write){
      e.preventDefault()
      selectedCounseling = item
      toggleOrgDataMapper()
    }
  }

  const openCounseling = (item, index) =>{
    selectedCounseling = item
    selectedCounseling.index = index
    toggleCounselingModal()
  }

  const menuItems = ({ item }) => {
   /* const isEdit = CheckAccess.checkLocaleTranslateAccess(currentUser, item, 'counseling', 'write')
    if(!isEdit) return null;*/
    return (
      <Fragment>
        <a href="#">
          <span className="font-13">{item.counseling_form_id} - {item.record_id}</span>
        </a>
        {(isTemplate && permission.write) &&
          <a href onClick={e=>openOrgDataMapperTable(e, item)}>
            <i className="fas fa-check-circle"/>
            <span className="font-13">Add to Organization</span>
            <i className="fa fa-angle-right"/>
          </a>
        }

        {!isTemplate && permission.write && 
          <a href onClick={e=>addNotifiableRole(e, item)}>
            <i className="fas fa-exclamation"></i>
            <span className="font-13">Add alert recipients</span>
            <i className="fa fa-angle-right"></i>
          </a>
        }

        {permission.write && 
          <Link to={`/infant/counseling/form/${item.id}?edit=${permission.write}`}>
            <i className="far fa-edit font-15" aria-hidden="true"></i>
            <span className="font-13">Edit</span>
            <i className="fa fa-angle-right"></i>
          </Link>
        }

        {isTemplate && !item.clone_id && permission.write &&
          <a href="#" onClick={(e) => translateAndClone(e, item)}>
            <i className="far fa-copy"/> 
            <span className="font-13">Language copy</span>
            <i className="fa fa-angle-right"/>
          </a>
        }

        {isTemplate && permission.write &&
          <a href onClick={e=>openLogs(e, item)}>
            <i className="fas fa-globe"></i>
            <span className="font-13">Versions</span>
            <i className="fa fa-angle-right"></i>
          </a>
        }

        {permission.write && CheckAccess.hasDeleteAccess(currentUser, item) &&     
          <a href="#" onClick={(e) => onDelete(e, item)}>
            <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
            <span className="font-13">Delete</span>
            <i className="fa fa-angle-right"></i>
          </a>
        }
      </Fragment>
    )
  }

  const CounselingCardList = ({counselingList}) => counselingList && counselingList.map((a, i) => (
    <div className={cardGridCss} key={i}>
      <SelectContainer index={i} item={a} onTextSelect={addNotes}>
        <CounselingCard index={i} counseling={a} dataSource={dataSource} 
        currentUser={currentUser} showMenu={showMenu} 
        addNotes={addNotes} addChecklist={addChecklist}
        cardHeightCss={cardHeightCss}  
        addNotifiableRole={addNotifiableRole} update={update}
        stepPlanners={stepPlanners} menuItems={menuItems}
        isMenuOpen={isMenuOpen} toggleMenuModal={toggleMenuModal}
        permission={permission}/>
      </SelectContainer>
    </div>
  ))

  const CounselingCard = (props) =>(
    <>
      <Counseling {...props}/>
   
      {selectedCounseling.id === props.counseling.id && 
        <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
          item={props.counseling} type="counseling"
          MenuItems={menuItems}/>
      }
    </>
  )
  
  const CounselingPopup = (props) =>(
    <div id="ct">
      <CounselingCard {...props}/>  
    </div>
  )

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20">{pageTitle}</div>
              <div className="font-15">
                {pagemeta.total} Items
              </div>
            </div>

            {!isPopupMode ?
              <div className="flex aligncenter">
                {isTemplate && permission.write &&
                  <span id="add_new_btn" 
                    onClick={()=>toggleCounselingMenu()}
                    data-tip="Create New Counseling"
                    className="bg-highlight round-btn m-r-5">
                      <i className="fas fa-plus"/>
                  </span>
                }
                  
                <span data-tip="Toggle Table View" 
                  className="bg-highlight round-btn m-r-5" 
                  onClick={()=>setShowTableView(!showTableView)}>
                    <i className="fas fa-table"/>
                </span>

                {permission.write &&
                  <FilterBtn filter={filter} setFilter={setFilter} 
                    dataSource={dataSource} requestParams={requestParams}
                    getCounselingList={getCounselingList}
                    currentUser={currentUser} isTemplate={isTemplate}
                  />
                }
                  
                {isTemplate && permission.write &&  
                  <>
                    <span id="upload_btn" data-tip="Upload Csv File" 
                      onClick={()=>{setCsvBtnClicked(true);toggleCounselingMenu()}} 
                      className="bg-highlight round-btn m-r-5">
                        <i className="fas fa-upload"/>
                    </span>

                    <DataSourceMapperIcon 
                      mappingType="infant_journey.counseling" 
                      iconType='button'/>
                  </>
                }
              </div>
              :null
            }
          </div>
        
          <div className="app-content-section m-t-30">
            {loading ? 
              <div className="spinner"/>
              :
              <>
                {showTableView ?
                  <TableView counselingList={counselingList} 
                    currentUser={currentUser} 
                    addNotifiableRole={addNotifiableRole} 
                    onDelete={onDelete}
                    getCounselingList={getCounselingList}
                    translateAndClone={translateAndClone}
                    openOrgDataMapperTable={openOrgDataMapperTable} 
                    openCounseling={openCounseling}
                    isTemplate={isTemplate}
                    permission={permission}
                    sortParam={sortParam}
                    setSortParam={setSortParam}/>
                  :
                  <div className="row">
                    <CounselingCardList counselingList={counselingList}/>
                  </div>
                }
              </>
            }  
            <PaginationSM
              className="pagination-bar"
              currentPage={currentpage}
              totalItems={pagemeta.total}
              itemsPerPage={pagemeta.per_page}
              onPageChange={setCurrentpage}
              siblingCount={2}
            />
          </div>
        </div>
      </div>

      <ConfirmMenu isOpen={isDeleteModalOpen} 
        toggleModal={toggleDeleteModal} 
        success={deleteCounseling} />
      
      {isCounselingOpen && 
        <GenericModal component={CounselingPopup} permission={permission}
        isOpen={isCounselingOpen} toggleModal={toggleCounselingModal} 
        counseling={selectedCounseling} index={selectedCounseling.index}
        dataSource={dataSource} 
        currentUser={currentUser} showMenu={showMenu} 
        addNotes={addNotes} addChecklist={addChecklist}
        cardHeightCss={cardHeightCss}  
        addNotifiableRole={addNotifiableRole} update={update}
        stepPlanners={stepPlanners}
        menuItems={menuItems}
        isMenuOpen={isMenuOpen} 
        toggleMenuModal={toggleMenuModal}/>
      }

      {isChecklistOpen && 
        <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} 
        item={selectedCounseling} type="infant_journey.counseling" />
      }
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} 
        item={selectedCounseling} type="infant_journey.counseling"/>
      }
      {isNotifiableRoleOpen && 
        <GenericModal isOpen={isNotifiableRoleOpen} 
        toggleModal={toggleNotifiableRoleModal} 
        title="Pick applicable roles to receive notification" 
        component={NotifiableRolesPopup} 
        notifiableType="counseling"
        notifiableId={selectedCounseling.id}/>
      }
      {showLocaleModal && 
        <GenericModal component={LocaleList} title="Langauage"
        isOpen={showLocaleModal} toggleModal={toggleLocaleModal}
        onTranslateComplete={onTranslateComplete} selectedItem={selectedCounseling}/>
      }
      {isDataLogOpen && 
        <GenericModal component={DataLogList} title="Versions" widthCss="w-100p"
        isOpen={isDataLogOpen} toggleModal={toggleDataLogModal}
        logType="counselings" logItem={selectedCounseling}
        formFields={formFields[selectedCounseling.counseling_form_id]}/>
      }
      {isCsvTableViewOpen && 
        <GenericModal component={CsvTableView} 
        isOpen={isCsvTableViewOpen} toggleModal={toggleCsvTableView}
        formFields={csvFormFields} dataList={parsedCsvDataList}
        title="Preview of the list of counselings to be uploaded" 
        widthCss="w-100p" insertParsedCsv={insertParsedCsv}
        />
      }
      {isCsvUploadOpen && 
        <ReadCsvFile isFileUploadOpen={isCsvUploadOpen} 
        toggleFileUpload={toggleCsvUpload} onReadCallback={onReadCallback} />
      }
      {isCounselingMenuOpen && 
        <DataSourceMenu toggleModal={closeCounselingMenu} 
          dataSourceId={dataSource.journey_ipa_types?.id}
          datasourceList={dataSource.journey_ipa_types?.options} 
          onSelect={routeForm} onSelectProps={isCsvBtnClicked}/>
      }
      {isOrgDataMapperOpen &&
        <GenericModal component={OrgDataMapperTable} 
          title={selectedCounseling.data_source?.stage?.label}
          isOpen={isOrgDataMapperOpen} toggleModal={toggleOrgDataMapper}
          itemType="counseling" itemId={selectedCounseling.id}/>
      }
      <SideMenuBar pageIds={['counselings']}/>
    </Fragment>
  )
}

const TableView = ({sortParam, setSortParam, permission, getCounselingList, counselingList, openCounseling, onDelete, currentUser, addNotifiableRole, translateAndClone, openOrgDataMapperTable, isTemplate}) =>{
  const onSortChange = () => {
    setSortParam({...sortParam})
  }
  const {sort} = useSort(sortParam, onSortChange)

  return(
    <div className="table-fix-head">
      <Table className="shadow-small">
        <THead>
          <TR>
            <TH onClick={()=>sort('record_id')}>
              ID 
              <SortDirection sort_column={requestParams.sort_column} 
              column="record_id" reverse={requestParams.reverse}/>
            </TH>
            <TH onClick={()=>sort('data.stage')} >
              Stage 
              <SortDirection sort_column={requestParams.sort_column} 
              column="data.stage" reverse={requestParams.reverse}/>
            </TH>
            <TH onClick={()=>sort('data.step')} >
              Step
              <SortDirection sort_column={requestParams.sort_column} 
              column="data.step" reverse={requestParams.reverse}/>
            </TH>
            <TH>
              Language
            </TH>
            {permission.write ?
              <>
                {isTemplate ?
                  <TH>
                    Orgs
                  </TH>
                  :
                  <TH>
                    Alert Recipients
                  </TH>
                }
              </>
              :
              null
            }
            <TH onClick={()=>sort('updated_by')} >
              User 
              <SortDirection sort_column={requestParams.sort_column} 
              column="updated_by" reverse={requestParams.reverse}/>
            </TH>
            <TH onClick={()=>sort('updated_at')} >
              Date
              <SortDirection sort_column={requestParams.sort_column} 
              column="updated_at" reverse={requestParams.reverse}/>
            </TH>
            {permission.write ?
              <TH>
                Action
              </TH>
              :null
            }
          </TR>
        </THead>
        <TBody>
          <TableData counselingList={counselingList}
            onDelete={onDelete} 
            currentUser={currentUser}
            translateAndClone={translateAndClone}
            addNotifiableRole={addNotifiableRole}
            openOrgDataMapperTable={openOrgDataMapperTable}
            openCounseling={openCounseling}
            isTemplate={isTemplate}
            permission={permission}/>
        </TBody>
      </Table>
    </div>
  )
}

const TableData = ({permission, counselingList, currentUser, openCounseling, addNotifiableRole, translateAndClone, onDelete, openOrgDataMapperTable, isTemplate}) => counselingList.map((o, k) => {
  const data = o.data || {}
  const ds = o.data_source || {}

  return(
    <TR key={k}>
      <TD className="text-gray" onSelect={()=>openCounseling(o, k)}>
        {o.record_id}
      </TD>
      <TD className="fw500" onSelect={()=>openCounseling(o, k)}>
        <LocaleLabel dataSource={ds.stage} currentLocale={currentUser.current_locale}/> 
          - 
        <LocaleLabel dataSource={ds.language} currentLocale={currentUser.current_locale}/>
      </TD>
      <TD>
        {ds.symptoms ?
          <LocaleLabelList list={ds.symptoms} currentLocale={currentUser.current_locale} css="d-block"/>
          :
          <LocaleLabel dataSource={ds.step} currentLocale={currentUser.current_locale}/>
        }
      </TD>
      <TD>
        <div className="flex aligncenter coldir">
          {!o.clone_id &&
            <LinkFontAwesomeIcon 
              css="far fa-copy white"
              id="clone" tooltip="Language copy" 
              onSelect={(e)=>translateAndClone(e, o)}>
            </LinkFontAwesomeIcon>
          }
          <span className="badge-2 m-t-5">{ds?.language?.label}</span>
        </div>
      </TD>
      {permission.write ?
        <>
          {isTemplate ? 
            <TD>
              <div className="flex aligncenter coldir">
                <LinkFontAwesomeIcon 
                  css="fas fa-plus-circle white"
                  id="new_org" tooltip="Add Organization" 
                  onSelect={(e)=>openOrgDataMapperTable(e, o)}>
                </LinkFontAwesomeIcon>
                <div className="m-t-5">
                  <PillList style="badge-2" list={o.assigned_organizations} dataKey="organization_name"/>
                </div>
              </div>
            </TD>
            :
            <TD>
              <div className="flex aligncenter coldir">
                {CheckAccess.checkOrgAccess(currentUser, 'counseling') &&
                  <LinkFontAwesomeIcon 
                    css="far fa-envelope white"
                    id="add_alert" tooltip="Add Alert Recipients" 
                    onSelect={(e)=>addNotifiableRole(e, o)}>
                  </LinkFontAwesomeIcon>
                }
                <div className="m-t-5">
                  {o.alert_recipients?.map((a, i)=>
                    <span className="badge-2" key={i}>{a.role || a.email_id}</span>
                  )}
                </div>
              </div>
            </TD>
          }
        </>
        :null
      }
      <TD>
        {o.author?.name}
      </TD>
      <TD>
        {Cs.formatUpdateDate(o.updated_at)}
      </TD>
      {permission.write ?
        <TD>
          <div className="flex aligncenter">
            <LinkFontAwesomeIcon id="edit" 
              tooltip="Edit Nutrition care" css="fas fa-edit white"
              link={`/infant/counseling/form/${o.id}?edit=${permission.write}`} />

            {CheckAccess.hasDeleteAccess(currentUser, o) &&       
              <LinkDeleteIcon id="delete" tooltip="Delete entry" onSelect={(e)=>onDelete(e, o)}/>
            }
          </div>
        </TD>
        :
        null
      }
    </TR>
  )
})

export default CounselingList;