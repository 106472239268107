import React, {useState, useEffect, Fragment, useRef} from 'react'
import querystringify from "querystringify"
import ReactTooltip from 'react-tooltip'
import {Calendar, Views, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {Link} from "react-router-dom"
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import DragObject from "./DragObject"
import Title from "../../GenericPortalPage/Title"
import AddEvent from "./AddEvent"
import useModal from '../../../../hooks/useModal'
import useStyle from '../../../../hooks/useStyle'
import HealthScheduleService from "./HealthScheduleService"
import Cs from "../../../../services/CommonService";

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
const localizer = momentLocalizer(moment) // or globalizeLocalizer
const DragAndDropCalendar = withDragAndDrop(Calendar)
let data_sources = {};
let newEvent = null;

let HealthSchedules = (props) => {
  useStyle('add_role_dialog')

  let events = []
  let params = props.match.params
  let {project, child} = props.location.state
  let queryParam = querystringify.parse(props.location.search)

  const [data, setData] = useState({events: [], displayDragItemInCell: true,})
  const [isNewEventPopupOpen, setNewEventPopupOpen] = useState(false)

  useEffect(() => {
    ReactTooltip.rebuild()
    let req = {
      'journey_profile_id':params.journey_profile_id,
      'page':1,
      'per_page':100
    }

    HealthScheduleService.getAll(req).then((res)=>{
      if(res.status == 200){
        res.data.schedules.forEach((e, i)=>{
          events.push({
            'id':e.id,
            'title':e.data.title,
            'start': Cs.stringToDate(e.start),
            'end': Cs.stringToDate(e.end || e.start),
            'item_type':e.item_type,
            'item_id':e.item_id,
            'allDay':true
          })
        })
        data.events = events;
        setData({...data})
      }
    })
    
    setAgendaLabel()

  }, [])

  const setAgendaLabel = () =>{
    setTimeout(function() {
      let calendarEl = document.querySelector("div.rbc-toolbar > span:nth-child(3) > button:nth-child(4)")
      if(calendarEl){
        calendarEl.innerText = 'Upcoming'
      }else{
        setAgendaLabel()
      }
    }, 1000)
  }

  const handleDragStart = (e, drag_item) => {
    /*let item = {
      'id':e.currentTarget.getAttribute('data-id'),
      'data_type':e.currentTarget.getAttribute('data-type'),
      'title':e.currentTarget.getAttribute('activity-type'),
      'activity_type':e.currentTarget.getAttribute('activity-type'),
      'activity_form_id':e.currentTarget.getAttribute('activity-form-id'),
    }*/
    let item = {
      'id':drag_item.value,
      'data_type':'education_journal_entry',
      'title':drag_item.label,
      'activity_type':drag_item.label,
      'activity_form_id':drag_item.child_form
    }
    data.draggedEvent = item;
    setData({...data})
  }

  const dragFromOutsideItem = () => {
    return data.draggedEvent
  }

  const onDropFromOutside = ({ start, end, allDay }) => {
    const { draggedEvent } = data;

    let event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start,
      end,
      allDay: allDay,
    }

    let req = {
      start_date: moment(start).format('YYYY-MM-DD'),
      start_date_string: moment(start).format('YYYY-MM-DD'),
      data: {'title':event.title},
      journey_profile_id: params.journey_profile_id,
    }
    
    req.schedule_item_id = 'new'
    req.schedule_item_type = draggedEvent.data_type
    req.journey_profile_id = params.journey_profile_id
    req.journal_entry_data = {
      'fj_edu_entry_type': parseInt(draggedEvent.id),
      'start_date': moment(start).format('MM/DD/YYYY'),
      'start_date_formatted_date': req.start_date,
    }
    req.activity_form_id = draggedEvent.activity_form_id
    req.activity_type = draggedEvent.activity_type
    req.data.sub_type = parseInt(draggedEvent.id)

    /*HealthScheduleService.createSchedule(req).then((res)=>{
      if(res.status==201){
        let d = res.data;
        event.id = d.id;
        event.title = d.data.title;
        event.item_id = d.schedule_item_id;
        event.item_type = d.schedule_item_type;
        event.data = d.data;
        event.activity_form_id = res.config.data.activity_form_id;
        event.is_new = true;
        setData({ ...data })
        moveEvent({ event, start, end })
      }
    })*/

    data.draggedEvent = null;
  }

  const moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    /*const { events } = data;

    let allDay = event.allDay

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    data.events = nextEvents;
    setData({...data})
    if(!event.is_new)updatedEvent(event, start, end)*/

    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  }

  const resizeEvent = ({ event, start, end }) => {
    /*const { events } = data

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    setData({
      events: nextEvents,
    })

    updatedEvent(event, start, end)*/
    //alert(`${event.title} was resized to ${start}-${end}`)
  }

  const updatedEvent = (event, start, end) =>{
    let req = {
      'id':event.id,
      'start_date':moment(start).format('YYYY-MM-DD'),
      'start_date_string':moment(start).format('YYYY-MM-DD'),  
    }

    if(event.end){
      req.end_date = moment(end).format('YYYY-MM-DD')
    }

    /*HealthScheduleService.update(req).then((res)=>{
      if(res.status==204){
        
      }
    })*/
  }

  /*const newEvent = (event) => {
    // let idList = this.state.events.map(a => a.id)
    // let newId = Math.max(...idList) + 1
    // let hour = {
    //   id: newId,
    //   title: 'New Event',
    //   allDay: event.slots.length == 1,
    //   start: event.start,
    //   end: event.end,
    // }
    // this.setState({
    //   events: this.state.events.concat([hour]),
    // })
  }*/

  const onSelectSlot = (slotInfo)=>{  
    newEvent = {
      'schedule_item_id': params.journey_profile_id, 
      'schedule_item_type': 'journey_profile',
      'journey_profile_id': params.journey_profile_id,
      'start_date': moment(slotInfo.start).format("YYYY-MM-DD"),
      'data':{}
    }
    setNewEventPopupOpen(true)
  }

  const addNewEvent = (e, event)=>{
   /* HealthScheduleService.create(event).then((res)=>{
      if(res.status==201){
        let e = res.data.schedule;
        if(e.start_date){
          e.start = new Date(e.start_date)
          e.end = e.end_date?new Date(e.end):e.start;
          let d = {
            'id':e.id,
            'title':e.data.title,
            'start': Cs.stringToDate(e.start_date),
            'end': Cs.stringToDate(e.end_date || e.start_date),
            'allDay':true,
            'item_id':e.schedule_item_id,
            'item_type':e.schedule_item_type
          }
          data.events = [d, ...data.events]
          setData({...data})
        }     
      }
    })*/
    setNewEventPopupOpen(false)
  }
  
  const onSelectEvent = (event) =>{
    
  }

  const remove = (e, event) => {
   /* HealthScheduleService.delete(event.event).then((res)=>{
      if(res.status == 204){
        data.events = data.events.filter((i) => i.id !== event.event.id)
        setData({...data})
      }
    })*/
  }

  const CustomEvent = (event) => { 
    return ( 
      <span>
        {event.title} 
        <i className="fas fa-times pull-right" onClick={e=>remove(e, event)}></i>
      </span> 
    ) 
  }

  return(
    <Fragment>

      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          <Title title={"Calendar"} project={project} />
        </div>
        <div className="col-xs-4">
          <Link to={ props.match.path.includes('infant') ? {pathname:`/infant/portal/${params.journey_profile_id}`}: {pathname:`/health/portal/${params.journey_profile_id}`}}
            data-tip="Back to Journey Home" className="bg-highlight round-btn float-right m-r-5 m-t-4">
            <i className="fas fa-home"/>
          </Link>
        </div>
      </div>

      <div className="p-5 bg-white">
        <DragAndDropCalendar localizer={localizer}
          events={data.events}
          startAccessor="start"
          endAccessor="end" 
          onEventDrop={moveEvent}
          resizable
          onEventResize={resizeEvent}
          onDragStart={console.log}
          onSelectEvent={(event)=>onSelectEvent(event)}
          onSelectSlot={(slotInfo)=>onSelectSlot(slotInfo)}
          selectable="true"
          components={{event: CustomEvent}}/>
      </div>

      {isNewEventPopupOpen &&
        <AddEvent newEvent={newEvent} isOpen={isNewEventPopupOpen} toggleModal={setNewEventPopupOpen} addNewEvent={addNewEvent}/>
      }
    </Fragment>
  )
}

export default HealthSchedules;

/*
dragFromOutsideItem={data.displayDragItemInCell ? dragFromOutsideItem : null}
          onDropFromOutside={onDropFromOutside}
          handleDragStart={handleDragStart}
*/