import React, { useState } from 'react';
import FormHelper from '../../../components/FormBuilder/FormHelpers';
import {CueTooltip} from '../../../components/Common/CueTooltip';

const InputMultiSelect = ({field, formData, readOnly, errors, forceUpdate}) => {
  let op_kv = field.options_key_value;
  let listKey = field.client_id+'_array';
  formData[listKey] = formData[listKey] || [];

  /*For State Management*/
  const [modelarray, setModelarray] = useState(formData[listKey]);

  let inputAttributes = {
    'type':'text',
    'name': field.client_id,
    'placeholder': field.placeholder,
    'disabled':(readOnly || field.read_only)
  }

  let labelAttributes = {
    'className':`w-title ${field.required && 'req-field'}`,
    'id':'label_'+field.client_id
  }
  
  let parentAttributes = {
    'tooltip': field.tooltip,
    'id': field.client_id
  }

  let validation = {'required': field.required}

  FormHelper.setDisabled(field, inputAttributes);
  //FormHelper.checkFieldValidity(field, formData, errors);
  
  /* 
   * To check an item exist in checkbox options.
   */
  const toggle = (item) => {
     let idx = modelarray.indexOf(item);
     if (idx > -1) {
       setModelarray(modelarray.filter(i => i !== item))
       formData[listKey].splice(idx, 1);
     }else {
      setModelarray([...modelarray, item])
      item = FormHelper.isNumeric(item)
      formData[listKey].push(item);
     }
     
     formData[field.client_id] =  formData[listKey].toString();
     setError();
     forceUpdate();
     FormHelper.setFormTitle(field, formData, modelarray, op_kv)
     //$scope.setValidity();
  }

  const exists = (item) => {
    return modelarray.indexOf(item) > -1
  }

  const setError = (event) => {
    if(field.required){
      const name = field.client_id;
      errors[name].invalid = formData[listKey].length==0?true:false;
      errors[name].touched = true;
      if(errors[name].invalid){
        errors.invalid[name] = true;
      }else{
        delete errors.invalid[name]
      }
    }
  }
  
  const Error = () => {
    if(field.required && (errors[field.client_id].touched || errors.isSubmitted) && errors[field.client_id].invalid){
      return(
        <div className="errormsg">
          <div>{field.label} is required.</div>
        </div>
      )
    }
    return null;
  }

  return (
    <div className="form-group col-xs-12" {...parentAttributes}>
      <label {...labelAttributes}>{field.label}</label>
      <div className="fts-13 m-b-5">{field.cue_prompt_description}</div>
      <Error/>
      <div className="popup-menu-grid" data-tip data-for={`tooltip_${field.client_id}`}>
        {(op_kv && op_kv.options) 
          ? <OptionsObjList list={op_kv.options} inputAttributes={inputAttributes} toggle={toggle} exists={exists} /> 
          : <OptionsStringList list={field.options} inputAttributes={inputAttributes} toggle={toggle} exists={exists} />
        }     
      </div>
      <div className="fts-13">{field.description}</div>
      <CueTooltip id={field.client_id} description={field.tooltip}/>
    </div> 
  )
}

/*
<span className={`chip chip-medium bg-gray1-dark ${ exists(o.value) && 'select-active'} `} 
    key={k} onClick={e => toggle(o.value, e)}>
    <i style={bgColor} className={`${(o.icon || 'fas fa-plus')}`}></i>
    <strong className="black-light-1 font-500">{o.label}</strong>
  </span>

  <span className={`chip chip-medium bg-gray1-dark ${ exists(o) && 'select-active'} `} 
    key={k} onClick={e => toggle(o, e)}>
    <i className={`${(o.icon || 'fas fa-plus')} bg-green1-dark`}></i>
    <strong className="black-light-1 font-500">{o}</strong>
  </span>
*/

const OptionsObjList = ({list, inputAttributes, toggle, exists}) => list.map((o, k) =>{
  if(o.read_only=='yes')return null;

  return (
    <div className={`ver-icons ${exists(o.value) && 'select-active'} `} onClick={e => toggle(o.value, e)} key={k}>
      <a href="/">
        <i className={`${o.icon || "fas fa-plus"}`} style={{ color: o.icon_color }}/>
      </a>
      <div className="popup-menu-title">
        {o.label}
      </div>
      <i className="fas fa-chevron-right text-muted pull-right"/>
    </div>
  )
})

const OptionsStringList = ({list, inputAttributes, toggle, exists}) => list.map((o, k) =>
  <div className={`ver-icons ${exists(o) && 'select-active'} `} onClick={e => toggle(o, e)} key={k}>
    <a href="/">
      <i className="fas fa-plus"/>
    </a>
    <div className="popup-menu-title">
      {o.label}
    </div>
    <i className="fas fa-chevron-right text-muted pull-right"/>
  </div>
)

export default InputMultiSelect;