import React, { useState, Fragment, useEffect, useContext } from "react";
import ReactTooltip from "react-tooltip";
import querystringify from "querystringify";
import { AuthContext } from "../../../../../contexts/Authentication/AuthStateProvider";
import CounselingReel from "./CounselingReel";
import CounselingService from "../CounselingService";
import Pagination from "../../../../Pagination";
import ConfirmMenu from "../../../../Modals/ConfirmMenu";
import ProjectMenu from "../../../../Modals/ProjectMenu";
import FormTemplateId from "../../../../../constants/FormTemplateList";
import CommentsModal from "../../../../Modals/CommentsModal";
import ChecklistsModal from "../../../../Modals/ChecklistsModal"
import CardMenu from "../../../../Modals/CardMenu";
import { Link } from "react-router-dom";
import useModal from "../../../../../hooks/useModal";
import useStyle from "../../../../../hooks/useStyle";
import PreLoadSpinner from "../../../../PreLoadSpinner";
import Cs from "../../../../../services/CommonService";
import Title from "../../../GenericPortalPage/Title";
//import MapperDataSourceListModal from "../../../../FormBuilder/MapperDataSourceListModal"
import env from "../../../../../env"
import GalleryModal from "../../../../Modals/GalleryModal"

let requestParams = {}
let selectedCounseling = {}
let data_source = {}
let journey_category = null;
let child_data = null;

const CounselingReelList = (props) => {
  useStyle("card")
  useStyle("card_table")
  useStyle("add_role_dialog")
  
  let params = props.match.params;
  let {project, child} = props.location.state || {};
  let queryParam = querystringify.parse(props.location.search)

  let cardGridCss = "pull-left";
  let cardHeightCss = "";
  if (props.id) {
    cardGridCss = "col-sm-12";
    cardHeightCss = "mih-380";
  }

  const {state: { screen, user: currentUser }, dispatch,} = useContext(AuthContext)
  const [counselingList, setCounselingList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  
  const { isOpen:isChecklistOpen, toggleModal:toggleChecklistModal } = useModal()
  const { isOpen:isCommentOpen, toggleModal:toggleCommentModal } = useModal()
  const { isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal,} = useModal()
  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()
  const { isOpen: isAssessMenuOpen, toggleModal: toggleAssessMenuModal} = useModal()
  const {isOpen: isPlannerMapperOpen, toggleModal: togglePlannerMapper} = useModal()
  const { isOpen:isGalleryOpen, toggleModal:toggleGalleryModal } = useModal();

  useEffect(() => {
    journey_category = null;
    requestParams = {
      data_source_params: {
        data_source_id: [3467],
        response_type: "object",
      },
      journey_profile_id: params.health_id,
      search: props.searchString,
      id: props.id
    }

    getCounselingList()
  }, [props.searchString, currentpage])

  let getCounselingList = () => {
    CounselingService.getAll(requestParams).then((res) => {
      if (res.status == 200) {
        data_source = res.data.data_source_list;
        requestParams.totalItems = res.data.meta.total;
        //journey_category = res.data.meta.journey_category;
        //CheckAccess.userAccess(authState.user, journey_category, res.data.meta.invited_journey_member)

        setCounselingList(res.data.counselings)
      }
    })
  }

  const deleteCounseling = (e) => {
    e.preventDefault()
    CounselingService.delete(selectedCounseling.id).then((res) => {
      if (res.status == 204) {
        setCounselingList(
          counselingList.filter((i) => i.id !== selectedCounseling.id)
        )
      }
    })
    toggleDeleteModal(e)
  }

  const showMenu = (e, a) => {
    selectedCounseling = a
    toggleMenuModal(e)
  }

  const addChecklist = (e, a) => {
    selectedCounseling = a
    toggleChecklistModal(e)
  }

  const addNotes = (e, a) => {
    selectedCounseling = a
    toggleCommentModal(e)
  }

  const openMapper = (a) =>{
    selectedCounseling = a
    togglePlannerMapper()
  }

  const counsellGallery = (e, a) => {
    selectedCounseling = a
    toggleGalleryModal(e);
  }

  const menuItems = ({ item }) => {
    return (
      <Fragment>
        <a>
          <span>
            Updated at {Cs.formatUpdateDate(item.updated_at)} 
          </span>
        </a>
        <a href="#" onClick={(e) => toggleDeleteModal(e)}>
          <span className="font-13">{item.counseling_form_id} - {item.record_id}</span>
        </a>

        {env.admin_emails.includes(currentUser.email) && <Fragment>
          <Link to={`/infant/counseling/form/${item.journey_profile_id}/${item.id}`}>
            <i className="far fa-edit font-15" aria-hidden="true"></i>
            <span className="font-13">Edit</span>
            <i className="fa fa-angle-right"></i>
          </Link>

          <a href="#" onClick={(e) => toggleDeleteModal(e)}>
            <i className="far fa-trash-alt font-15" aria-hidden="true"></i>
            <span className="font-13">Delete</span>
            <i className="fa fa-angle-right"></i>
          </a>
        </Fragment>}
      </Fragment>
    )
  }

  const Menu = ({ counseling }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, counseling)} className="fas fa-bars m-r-15 font-16"/>
  )

  /*if(counselingList.length == 0){
    return(
      <PreLoadSpinner/>
    )
  }*/

  const CounselingCard = ({counselingList}) => counselingList && counselingList.map((a, i) => (
    <div className={cardGridCss} key={i}>
      <CounselingReel counsellGallery={counsellGallery} counseling={a} dataSource={data_source} currentUser={currentUser}
        menu={Menu} addNotes={addNotes} addChecklist={addChecklist}
        journeyCategory={journey_category} cardHeightCss={cardHeightCss}
        openMapper={openMapper}/>
 
        {selectedCounseling.id == a.id && (
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
            item={selectedCounseling} type="counseling"
            MenuItems={menuItems}/>
        )}
    </div>
  ))

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
          <Title title={queryParam.label + " Info"} project={project} />
        </div>
        <div className="col-xs-4">
          <Link data-tip="Back to Journey Home"
            to={{pathname:`/infant/portal/${params.health_id}`, state:project}} 
            className="bg-highlight round-btn float-right m-r-5 m-t-4">
            <i className="fas fa-home"></i>
          </Link>
        </div>
      </div>
        
      <div className="page-content row">
        <div className="col-xs-12 p-l-15">
          <span className="font-16" style={{borderBottom: '3px solid #00A2FF'}}>Suggestion List</span>
        </div>
        <div className="recentList br-btm-grey" style={{width:'100%',display:'flex',whiteSpace:'nowrap',overflowX:'scroll'}}>
          <CounselingCard counselingList={counselingList}/>
        </div>
      </div>

      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} success={deleteCounseling} />
      {isChecklistOpen && <ChecklistsModal isOpen={isChecklistOpen} toggleModal={toggleChecklistModal} item={selectedCounseling} type="infant_journey.counseling" />}
      {isCommentOpen && <CommentsModal isOpen={isCommentOpen} toggleModal={toggleCommentModal} item={selectedCounseling} type="infant_journey.counseling"/>}
      {isGalleryOpen && <GalleryModal isOpen={isGalleryOpen} toggleModal={toggleGalleryModal} item={selectedCounseling} type="product"/>}

    </Fragment>
  )
}

export default CounselingReelList;
/*
      {isPlannerMapperOpen && <MapperDataSourceListModal isOpen={isPlannerMapperOpen} toggleModal={togglePlannerMapper} item={selectedCounseling} type="infant_journey.counseling"/>}  

*/