import React, { useState, Fragment, useEffect, useContext, useMemo } from "react"
import ReactTooltip from "react-tooltip"
import querystringify from "querystringify"
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import Medical from "./Medical"
import Pagination from "../../../../components/Pagination"
import ConfirmMenu from "../../../../components/Modals/ConfirmMenu"
import ProjectMenu from "../../../../components/Modals/ProjectMenu"
import FormTemplateId from "../../../../constants/FormTemplateList"
import CommentsModal from "../../../../components/Modals/CommentsModal"
import ChecklistsModal from "../../../../components/Modals/ChecklistsModal"
import CardMenu from "../../../../components/Modals/CardMenu"
import { Link, useHistory } from "react-router-dom"
import useModal from "../../../../hooks/useModal"
import useStyle from "../../../../hooks/useStyle"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import Title from "../../GenericPortalPage/Title"
import FacilityService from "./FacilityService"
import {MenuItem, NavIcon} from '../../../Common/MenuItem'
import {ReadCsvFile, CsvTableView} from "../../../Common/ReadCsvFile"
import UsersRolesPopup from '../../../DataAccesses/UsersRolesPopup'
import GenericModal from '../../../Modals/GenericModal'
import {LeafletMap} from '../../../Common/Leaflet'
import {ConfirmAlert} from '../../../Common/NotificationPopup'
import {OrgRoleConfirmOptions} from '../../../../constants/DataSourceList'
import CheckAccess from '../../Roles/CheckAccess'
import FacilityTableList  from './FacilityTableList'
import SideMenuBar from "../../../Common/SideMenuBar"
import PaginationSM from "../../../Common/PaginationSM"
import {usePageTitle} from '../../../../hooks/pageHead'
import {FilterBtn} from './Filters'

let requestParams = {}
let selectedFacility = {}
let dataSource = {}

const FacilityList = (props) => {
  useStyle('/css/tile-components.css', true)
  usePageTitle('Facility List')

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  const {isPopupMode} = props
  const history = useHistory()

  const { state: {screen, user: currentUser }} = useContext(AuthContext)

  const organizationId = params.organization_id || currentUser.current_organization_id

  const [loading, setLoading] = useState(false)
  const [rolesCount, setRolesCount] = useState(0)
  const [facilityList, setFacilityList] = useState([])
  const [currentpage, setCurrentpage] = useState(1)
  const [pagemeta, setPagemeta] = useState([])
  const [csvFormFields, setCsvFormFields] = useState([])
  const [parsedCsvDataList, setParsedCsvDataList] = useState([])
  const [showFacilityContentTable, setShowFacilityContentTable] = useState(true)
  const [filter, setFilter] = useState({})

  const {isOpen: isUserRoleOpen, toggleModal: toggleUserRole } = useModal()
  const {isOpen: isChecklistOpen, toggleModal: toggleChecklistModal } = useModal()
  const {isOpen: isCommentOpen, toggleModal: toggleCommentModal } = useModal()
  const {isOpen: isDeleteModalOpen, toggleModal: toggleDeleteModal,} = useModal()
  const {isOpen: isMenuOpen, toggleModal: toggleMenuModal } = useModal()
  const {isOpen: isMapOpen, toggleModal: toggleMapModal } = useModal()
  const {isOpen: isCsvTableViewOpen, toggleModal: toggleCsvTableView } = useModal()
  const {isOpen: isCsvUploadOpen, toggleModal: toggleCsvUpload} = useModal()
  const {isOpen: isRoleConfirmOpen, toggleModal: toggleRoleConfirm } = useModal()

  let cardGridCss = "col-md-4"
  let cardHeightCss = "ht-400"
  if (queryParam.id) {
    cardGridCss = "col-sm-12 col-sm-6 col-md-8"
    cardHeightCss = "mih-380"
  }

  const permission = useMemo(() => 
    CheckAccess.getMenuPermission(currentUser, 'facilities')
  , [currentUser.id])

  useEffect(() => {
    ReactTooltip.rebuild()
    requestParams = {
      page: currentpage,
      per_page: 10,
      filter: filter,
      facility_form_id: FormTemplateId.HealthMedicalForm,
      organization_id: organizationId,
    }

    requestParams.medical_facility_id = CheckAccess.checkIsAdmin(
      currentUser, 
    )? null: currentUser.current_medical_facility_id;

    getFacilityList()
    return () => {
      requestParams = {}
    }
  }, [props.searchString, currentpage, filter])

  let getFacilityList = () => {
    if(permission.read){
      FacilityService.getAll(requestParams).then((res) => {
        if (res.status == 200) {
          setFacilityList(res.data.facilities)
          setPagemeta(res.data.meta)
          setRolesCount(res.data.roles_count)
        }
      })
    }
  }

  const deleteFacility = (e) => {
    e.preventDefault()
    setLoading(true)
    FacilityService.delete(selectedFacility.id).then((res) => {
      if (res.status == 204) {
        setFacilityList(
          facilityList.filter((i) => i.id !== selectedFacility.id)
        )
        setLoading(false)
      }
    })
    toggleDeleteModal(e)
  }

  const showMenu = (e, a) => {
    selectedFacility = a
    toggleMenuModal(e)
  }

  const addChecklist = (e, a) => {
    selectedFacility = a
    toggleChecklistModal(e)
  }

  const addNotes = (e, a) => {
    selectedFacility = a
    toggleCommentModal(e)
  }

  const openMapModal = (f) =>{
    FacilityService.report({medical_facility_id:f.id}).then(({status, data})=>{
      if(status == 200){
        selectedFacility.report = data.report
        selectedFacility = f
        selectedFacility.markerTitle = `
          <h5>${f.phc_name}</h5>
          <div>Active Journey - ${data.report.active_journey_count}</div>
          <div>Closed Journey - ${data.report.closed_journey_count}</div>
          <div>Members - ${data.report.facility_members_count}</div>
        `
        toggleMapModal()
      }
    })
  }

  const routeActivity = (facility) => {
    history.push(
      `/list/medical/facilities?id=${facility.id}`
    )
  }

  const onReadCallback = (contents) =>{
    setLoading(true)
    let req = {
      user_id: currentUser.id,
      organization_id:organizationId,
      contents:contents
    }
    FacilityService.uploadCsv(req).then(({status, data}) => {
      if (status === 200) {
        setParsedCsvDataList(data.parsed_rows)
        setCsvFormFields(data.form_fields)
        toggleCsvTableView()
        setLoading(false)
      }else if(status == 433){
        alert(data.error)
        setLoading(false)
      }
    })
  }

  const insertParsedCsv = () => {
    setLoading(true)
    FacilityService.create({
      form_id: FormTemplateId.HealthFacilityForm,
      organization_id: organizationId,
      bulk_insert:true,
      facilities:parsedCsvDataList
    }).then((res) => {
      if (res.status === 201) {
        getFacilityList()
        toggleCsvTableView()
        setLoading(false)
      }else{
        setLoading(false)
        alert(res.data.error || 'Upload Error')
      }
    })
  }

  const openUserRole = (facility) => {
    selectedFacility = facility
    toggleUserRole()
  }

  const onRoleConfirmPopup = (action) => {
    if(action === 'roles_created'){
      toggleCsvUpload()
    }else{
      history.push(`/health/role/infant_journey/${currentUser.id}/${organizationId}/${currentUser?.current_subscription?.id}`)
    }
    toggleRoleConfirm()
  }

  const menuItems = ({ item }) => {
    return (
      <>
        <span className="m-l-15">
          {item.record_id}
        </span>
        {permission.write && 
          <>
            <Link to={`/health/facility/${organizationId}/${item.id}`}>
              <i className="far fa-edit font-15" aria-hidden="true"/>
              <span className="font-13">Edit</span>
              <i className="fa fa-angle-right"/>
            </Link>

            <a href="#" onClick={(e) => toggleDeleteModal(e)}>
              <i className="far fa-trash-alt font-15" aria-hidden="true"/>
              <span className="font-13">Delete</span>
              <i className="fa fa-angle-right"/>
            </a>
          </>
        }
      </>
    )
  }

  const Menu = ({ facility }) => (
    <i data-tip="Menu" onClick={(e) => showMenu(e, facility)} className="fas fa-bars m-r-15 font-16"/>
  )

  const toggleFacilityContentTable = () => setShowFacilityContentTable(!showFacilityContentTable)

  const facilitiesCard = facilityList && facilityList.map((a, i) => (
    <div className={cardGridCss} key={i}>
      <Medical facility={a} dataSource={dataSource} menu={Menu}
        addNotes={addNotes} addChecklist={addChecklist}
        cardHeightCss={cardHeightCss} routeActivity={routeActivity}
        openUserRole={openUserRole} openMapModal={openMapModal}
        organizationId={organizationId} addMenu={showMenu}
        permission={permission}/>
        {selectedFacility.id == a.id && (
          <ProjectMenu isOpen={isMenuOpen} toggleModal={toggleMenuModal}
            item={selectedFacility}  type="facility"  MenuItems={menuItems}/>
        )}
    </div>
  ))

  const roleEle = (client_id, row) => (
    <>
      {row.role_error?<span className="lred bold-500">{row.role_error}</span> : row.data.role}
    </>
  )

  const csvSpecialFields = [
    {label:'Role', client_id:'role', ele:roleEle},
  ]

  return (
    <>
      <div id="ct">
        <div className="app_content">
          {!isPopupMode ?
            <div className="app-header flex justspacebetween m-t-15">
              <div className="flex coldir">
                <div className="app-title m-r-20">Medical Facilities</div>
                <div className="font-14">{pagemeta?.total} Items</div>
              </div>

              <div className="flex aligncenter">
                <NavIcon id="facilities_list" dataTip="Toggle Digital Content Table" 
                  onSelect={toggleFacilityContentTable}
                  className="bg-highlight round-btn float-right m-r-5" 
                  icon="fa fa-table"/>
                
                <FilterBtn filter={filter} setFilter={setFilter} 
                  dataSource={dataSource} requestParams={requestParams}
                  getFacilityList={getFacilityList}
                  currentUser={currentUser}/>

                {permission.write &&  
                  <>
                    <Link id="scribble_btn" to={`/health/facility/${organizationId}`} data-tip="Create New Facility"
                      className="bg-highlight round-btn float-right m-r-5">
                        <i className="fas fa-plus"/>
                    </Link>

                    <span id="upload_btn" onClick={e=>toggleRoleConfirm()} data-tip="Upload Csv File" 
                      className="bg-highlight round-btn float-right m-r-5">
                        <i className="fas fa-upload"/>
                    </span>
                  </>
                }
              </div>
            </div>
            :null
          }
            
          <div className="app-content-section m-t-30">
            <div className="row">
              {loading?<div className="spinner"/>:null}
              {!showFacilityContentTable && facilitiesCard}
            </div>

            {showFacilityContentTable && 
              <FacilityTableList facilityList={facilityList} requestParams={requestParams}
                selectedFacility={selectedFacility} getFacilityList={getFacilityList} 
                toggleDeleteModal={toggleDeleteModal} organizationId={organizationId}
                openMapModal={openMapModal} permission={permission}/>
            }

            <PaginationSM
              className="pagination-bar"
              currentPage={currentpage}
              totalItems={pagemeta.total}
              itemsPerPage={pagemeta.per_page}
              onPageChange={setCurrentpage}
              siblingCount={2}/>

            {/* <Pagination totalItems={pagemeta.total} currentpage={currentpage}
              setCurrentpage={setCurrentpage} itemsPerPage={pagemeta.per_page}/> */}
          </div>
        </div>
      </div>
      
      <ConfirmMenu isOpen={isDeleteModalOpen} toggleModal={toggleDeleteModal} 
        success={deleteFacility}/>
        
      {isChecklistOpen && 
        <ChecklistsModal isOpen={isChecklistOpen} 
        toggleModal={toggleChecklistModal} item={selectedFacility} 
        type="medical_Facility"/>
      }
          
      {isCommentOpen && 
        <CommentsModal isOpen={isCommentOpen} 
        toggleModal={toggleCommentModal} 
        item={selectedFacility} type="medical_Facility"/>
      }
          
      {isUserRoleOpen && 
        <GenericModal component={UsersRolesPopup} 
        isOpen={isUserRoleOpen} toggleModal={toggleUserRole}
        itemType="medical_facility" itemId={selectedFacility.id} 
        showRoles="true" showOrgMembers="true" 
        title="Assign this case to Roles and/or Users"/>
      }

      {isMapOpen && 
        <GenericModal component={LeafletMap} isOpen={isMapOpen}
        toggleModal={toggleMapModal} lat={selectedFacility.data.latitude} 
        long={selectedFacility.data.longitude} 
        markerTitle={selectedFacility.markerTitle} title="Map"/>
      }

      {isCsvTableViewOpen && 
        <GenericModal component={CsvTableView} loading={loading}
        isOpen={isCsvTableViewOpen} toggleModal={toggleCsvTableView}
        formFields={csvFormFields} dataList={parsedCsvDataList}
        title="Preview of the list of facilities to be uploaded" 
        widthCss="w-99p" insertParsedCsv={insertParsedCsv}
        csvSpecialFields={csvSpecialFields}/>
      }

      {isRoleConfirmOpen &&
        <GenericModal component={ConfirmAlert} title="Bulk Upload Facilities"
          message='Click “Yes” if the facilities list file you are about to upload is (1) in the .csv format and (2) each facility entry has a role associated with it.' 
          options={OrgRoleConfirmOptions} onSelect={onRoleConfirmPopup}
          isOpen={isRoleConfirmOpen} toggleModal={toggleRoleConfirm}/>
      }
          
      {isCsvUploadOpen && 
        <ReadCsvFile 
        isFileUploadOpen={isCsvUploadOpen} 
        onReadCallback={onReadCallback} />
      }

      <SideMenuBar pageIds={['facilities']}/>
    </>
  )
}

export default FacilityList;