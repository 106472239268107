import React from "react"
import { Fragment } from "react"
import {Link} from "react-router-dom"
import CommonService from '../../../services/CommonService'
import useModal from "../../../hooks/useModal"
//import ActivityMenuModal from "../MotherInfantJourney/Activities/List/ActivityMenuModal"
import {OptionsObjList} from "../../Common/FormInput"
import {Table, THead, TH, TR, TD, TBody} from "../../Common/TableView"
import {PhoneIcon, MapIcon, LinkMapIcon, LinkMailIcon, LinkUserIcon, LinkDeleteIcon} from "../../Common/ImageIcons"
import Cs from '../../../services/CommonService'
import {NotificationBadge} from "../../Common/BadgePill"

const MobileRequired = ({labelLocale}) => <font className="c-bemedian">{labelLocale(13)}</font>;
const BabyNameRequired = ({labelLocale}) => <font className="c-bemedian">{labelLocale(12)}</font>;
const AddressRequired = ({labelLocale}) => <font className="c-bemedian">Address Not Provided</font>;

const Infant = (props) => {
  let {journey, dataSources, onDelete, openUserRole, routeJourney, openNotification, index, update, journeyStatusList, userLocale, labelLocale} = props
  let {data, data_source:ds} = journey
  let child = journey.child || {data:{}}
  let measurement = journey.last_activity.child_measurement || {data:{}}
  const statusStyle = journey.status?{
    color:'white',
    backgroundColor: journeyStatusList.find(o=> o.sid == journey.status)?.bg_color
  }:{}

  return(
    <div className="bg-white border-rounded-10 card-shadow m-b-15">
      <div className="card-content p20">
        <div className="scroll-auto ht-150">
          <div className="flex">
            <h4 className="fw700 f18 text-left">
              {journey.record_id} - {data.mother_name || data.patient_name} - {child.data.baby_name?` ${child.data.baby_name}`:<BabyNameRequired labelLocale={labelLocale}/>}
            </h4>
            <select className="form-control input-sm fw500 ml-auto w-100" style={statusStyle}
              onChange={e=>update(index, {id:journey.id, status:e.target.value})} 
              placeholder="Status" defaultValue={journey.status}>
                <OptionsObjList list={journeyStatusList} id_key='sid' label_key='label' user_locale={userLocale}/>
            </select>
          </div>
            
          <div className="m-b-10">
            {ds.health_condition?.label}
          </div>
        
          <div className="flex aligncenter m-b-10">
            <div className="flex aligncenter m-r-20">
              {data.cell_number_formatted? 
                <PhoneIcon title={data.cell_number_formatted}/>
                :
                <MobileRequired labelLocale={labelLocale}/>
              }
            </div>
            <div className="flex aligncenter">
              {(data.taluka || data.address)?
                <MapIcon title={data.taluka || data.address}/>
                :
                <AddressRequired labelLocale={labelLocale}/>
              }
            </div>
          </div>

          <div className="flex aligncenter m-b-10">
            <div className="flex aligncenter coldir m-r-20">
              <div>{journey?.last_updated_user?.name}</div>
              <div>{journey?.member_role?.role}</div>
            </div>
            <div className="flex aligncenter m-r-20">
              {Cs.formatUpdateDate(measurement.last_update_at)}
            </div>
          </div>

          <div className="dblock m-b-10">
            {measurement?.data_source?.location?.label}
          </div>
        </div>
        <div>
          {journey.status != 'closed' && 
            <div className="flex aligncenter justifyflexend">
              <LinkMapIcon id="path_details" 
                tooltip="View path details" 
                onSelect={()=>routeJourney(journey)}>
                  <NotificationBadge 
                    count={journey.schedule_today_count}/>
                  <NotificationBadge
                    position="bottom" 
                    count={journey.schedule_missed_count}/>
              </LinkMapIcon>
              {journey.nutrition_care_alert_count > 0 &&
                <LinkMailIcon id="notification" 
                  count={journey.nutrition_care_alert_count} 
                  tooltip="Journey Notification" 
                  onSelect={()=>openNotification(journey)} 
                  className="far fa-envelope m-l-15 font-18 text-muted"/>
              }
              <LinkUserIcon tooltip="Assign journey" onSelect={()=>openUserRole(journey)}/>
              <LinkDeleteIcon id="delete_journey" tooltip="Delete journey" onSelect={()=>onDelete(journey)}/>
            </div>
          }
        </div>
      </div>
    </div>
  )  
}

export default Infant;