import React, { useState, Fragment, useEffect } from 'react'
import useModal from "../../../hooks/useModal"
import ConfirmMenu from "../../Modals/ConfirmMenu"
import useStyle from '../../../hooks/useStyle'
import useSort from '../../../hooks/useSorting'
import SortDirection from '../../../components/Common/SortDirection'
import TraineeUserService from './TraineeUserService'
import Cs from "../../../services/CommonService"
import {NavIcon} from "../../Common/MenuItem"
import AddTraineeUser from './AddTraineeUser'
import { Table, THead, TH, TR, TD, TBody } from "../../Common/TableView"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from '../../Common/ImageIcons'

let selectedTrainee = null
let requestParams = {}
const TraineeUserList = (props) => {

  const [loading, setLoading] = useState(false)
  const [traineeList, setTraineeList] = useState([])

  const {isOpen:isDeleteOpen, toggleModal:toggleDeleteModal} = useModal()
  const {isOpen:isAddTraineeOpen, toggleModal:toggleAddTraineeModal} = useModal()

  useEffect(()=>{
    selectedTrainee = null
    requestParams={
      item_type:props.itemType,
      item_id:props.itemId,
    }
    getTraineeList()
  }, [])

  const getTraineeList = () =>{
    TraineeUserService.getAll(requestParams).then(({status, data})=>{
      if(status == 200){
        setTraineeList(data.trainee_users)
      }
    })
  }

  const {sort} = useSort({}, getTraineeList)

  const onDelete = (trainee) => {
    toggleDeleteModal()
    selectedTrainee = trainee
  }

  const deleteTrainee = (e) => {
    e.preventDefault()
    TraineeUserService.delete(selectedTrainee.id).then((res) => {
      if (res.status == 204) {
        setTraineeList(traineeList.filter(i => i.id !== selectedTrainee.id))
      }
    })
    toggleDeleteModal(e)
  }
  
  const openAddTrainee = (e, trainee) =>{
    selectedTrainee = trainee || {}
    toggleAddTraineeModal(e)
  }

  const addTrainee = (e, trainee, on_error) => {
    e.preventDefault()
    if(trainee.id){
      TraineeUserService.update(trainee).then(({status, data}) => {
        if (status == 204) {
          toggleAddTraineeModal()
        }else if(status == 403){
          on_error(data.error)
        }
      })
    }else{
      trainee.organization_id = props.organizationId
      trainee.item_id = props.itemId
      trainee.item_type = props.itemType
      TraineeUserService.create(trainee).then(({status, data}) => {
        if (status == 201) {
          setTraineeList([...data.trainee_users, ...traineeList])
          toggleAddTraineeModal()
        }else if(status == 403){
          on_error(data.error)
        }
      })
    }
  }

  const tableData = traineeList.map((o, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray">
          {o.record_id}
        </TD>
        <TD className="fw500">
          {o.email}
        </TD>
        <TD>
          {Cs.formatUpdateDate(o.created_at)}
        </TD>
        <TD className="">
          <div className="flex aligncenter">
            <LinkFontAwesomeIcon id="edit_trainee_contents" tooltip="Edit trainee contents" onSelect={()=>openAddTrainee(null, o)} css="fas fa-edit text-white">
            </LinkFontAwesomeIcon>
            <LinkDeleteIcon id="delete_trainee_contents" tooltip="Delete trainee contents" onSelect={(e)=>onDelete(o)}>
            </LinkDeleteIcon>
          </div>
        </TD>
      </TR>
    )
  })

  return (
    <Fragment>
     <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20">Trainee List</div>
              <div className="font-15">
                 Number of Trainee - {traineeList.length}
              </div>
            </div>

            <div className="flex aligncenter">
              <NavIcon id="new_member" dataTip="Add New trainee" 
                className="bg-highlight round-btn float-right m-r-5 m-t-4" 
                icon="fas fa-plus"
                onSelect={e=>openAddTrainee(e)}/>
            </div>  
          </div>

        <div className="app-content-section m-t-30">
        <Table className="shadow-small">
          <THead>
            <TR>
              <TH onClick={e=>sort('record_id')}>
                ID 
                <SortDirection sort_column={requestParams.sort_column} 
                column="record_id" reverse={requestParams.reverse}/>
              </TH>
              <TH onClick={e=>sort('email')}>
                User 
                <SortDirection sort_column={requestParams.sort_column} 
                column="data.name" reverse={requestParams.reverse}/>
              </TH>
              <TH onClick={e=>sort('updated_at')}>
                Date
                <SortDirection sort_column={requestParams.sort_column} 
                column="updated_at" reverse={requestParams.reverse}/>
              </TH>
              <TH>
                Action
              </TH>
            </TR>
          </THead>
          <Table>
            {tableData}
          </Table>
        </Table>
      </div>
      </div>
      </div>
      <ConfirmMenu isOpen={isDeleteOpen} toggleModal={toggleDeleteModal} success={deleteTrainee}/>
      
      {isAddTraineeOpen && 
        <AddTraineeUser isOpen={isAddTraineeOpen} toggleModal={toggleAddTraineeModal} 
          onSubmit={addTrainee} editTrainee={selectedTrainee}
        />
      }
    </Fragment>
  )
}

export default TraineeUserList;