import React, { useState, Fragment, useEffect } from "react"
import { Link } from "react-router-dom"
import useStyle from "../../../../hooks/useStyle"
import Cs from "../../../../services/CommonService"
import Title from "../../GenericPortalPage/Title"
import HealthCategoryService from "../../HealthCategoryService"
import querystringify from "querystringify"
import DataSourceMenu from "../../../Modals/DataSourceMenu"
import useModal from "../../../../hooks/useModal"
//import ActivityList from "../../Health/Activities/List/ActivityList"
import GenericModal from "../../../Modals/GenericModal"

let child_data = null
let dsPath = null
let selectedActivity = null

const InfantPath = (props) => {
  useStyle('timeline')
  let queryParam = querystringify.parse(props.location.search);

  let params = props.match.params;
  let {project, child} = props.location.state;
  
  const [journey, setJourney] = useState()
  const [timeline, setTimeLine] = useState([])

  const { isOpen: isMenuOpen, toggleModal: toggleMenuModal} = useModal()
  const { isOpen: isActivityOpen, toggleModal: toggleActivityModal} = useModal()

  useEffect(() => {

  }, [])


  const routeActivity = (d) =>{
    props.history.push(`/infant/activity/form/${params.health_id}?activity_form_id=${d.child_form}`, child_data)
  }

  const ActivityCard = () => {
    return(
      <div class="row">
        <div class="button-home button-2 button-2--secondary--blue" style={{padding:"10px",margin:"25px"}}> <i class="fa fa-home font-24 p-5" aria-hidden="true"></i> Add a new family </div>
        <div class="button-home button-2 button-2--secondary--red" style={{padding:"10px",margin:"25px"}}> <i class="fa fa-child font-24 p-5" aria-hidden="true"></i>Add a new child </div>
        <div class="button-home button-2 button-2--secondary--green" style={{padding:"10px",margin:"25px"}}> <i class="fa fa-calculator font-24 p-5" aria-hidden="true"></i>Add data to a family</div>
        <div class="button-home button-2 button-2--secondary--grey" style={{padding:"10px",margin:"25px"}}><i class="fa fa-comment font-24 p-5" aria-hidden="true"></i>Send a message</div>
        <div class="button-home button-2 button-2--secondary--orange" style={{padding:"10px",margin:"25px"}}><i class="fa fa-bell font-24 p-5" aria-hidden="true"></i>View notifications</div>
      </div>
    )
  }

  return (
    <Fragment>
      <div className="content top-10 clearfix">
        <div className="col-xs-8">
            <div className="date-right" style={{padding:"11px"}}>
              <div className="font-16">Home</div>
            </div>
        </div>
        <div className="col-xs-4">
        
        </div>
      </div>  
      <div className="time-container">
  
        <div className="time-header">
          <div className="color-overlay">
            {/*<div className="day-number">8</div>*/}
            <div className="date-right bg-black ht-50" style={{padding:"11px"}}>
              <div className="month font-16 ">Welcome</div>
            </div>
          </div>
        </div>
  
        <div className="timeline">
          <ActivityCard/>
        </div>  
      </div>

      {isMenuOpen && <DataSourceMenu id="3614" toggleModal={toggleMenuModal} onSelect={routeActivity}/>}
      
    </Fragment>
  )
}
/*
{isActivityOpen && <GenericModal title="Activity" component={ActivityList} 
        toggleModal={toggleActivityModal} {...props} id={selectedActivity.notifiable_id}/>}
*/
export default InfantPath;