import React from "react";
import {Link} from "react-router-dom";
import CommonService from '../../../services/CommonService';


export const Role = (props) => {
  let {invited_user, rolesObj} = props;

  return (
    <React.Fragment>
      <div className="user-list-container bg-white">
        <div className="user-list-left">
          <img src="images/avatars/2s.png" alt="img" className="shadow-small bg-blue2-dark" />
          <h4>{invited_user.email}</h4>
          <p className="color-highlight">
            {invited_user.full_access && <a className="p-2 br-groove-black margin-2 bg-blue-white black-light-1">Full Access</a>}
            {invited_user.role_ids && invited_user.role_ids.map((role_id, i) => (<a className="p-2 br-groove-black margin-2 bg-blue-white black-light-1" key={i} >{rolesObj[role_id]}</a>))}
          </p>
          <div className="text-cap role">
            <a className="black">{ invited_user.message && invited_user.message.substring(0,110)}</a>
            <span>
              <strong>Email</strong>
              <a><span className={invited_user.is_email_sent?'far fa-check-circle':'far fa-circle'}></span></a>
            </span>
            <span>
              <strong>Visit</strong>
              <a><span className={invited_user.is_invite_link_visited?'far fa-check-circle':'far fa-circle'}></span></a>
            </span>
            <span>
              <strong>Join</strong>
              <a><span className={invited_user.joined_from_invite?'far fa-check-circle':'far fa-circle'}></span></a>
            </span>
          </div>
        </div>  
        <div>
          <div className="action-btn bg-highlight" onClick={e=>{props.setEdit(e, invited_user)}}>Edit</div>
          <div className="action-btn bg-highlight" onClick={e=>{props.selectInviteUser(e, invited_user)}}>Delete</div>
        </div>
      </div>
    </React.Fragment>
  )

};

export default Role;