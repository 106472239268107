import React, { Fragment, useEffect, useRef, useState, useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import ReactDOM from 'react-dom'
import env from "../../../env"
import HealthCategoryService from "../HealthCategoryService"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import Cs from "../../../services/CommonService"
import ReactTooltip from "react-tooltip"
import CheckAccess from '../Roles/CheckAccess' 

let portalPath = null;

const PortalSideMenu = (props) => {
  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const sideNavEl = useRef(null)
  const sideNavMenuEl = useRef(null)
  const history = useHistory()

  let {journey, child, journeyType, menuOptions, openSideNav, setOpenSideNav, permissionKey} = props
  const {current_member} = currentUser
  //const [journey, setJourney] = useState()

  /*useEffect(() => { 
    let req = {
      'data_source_params':{
        "data_source_id":[menuDsId]
      }
    }

    HealthCategoryService.get(journeyProfileId, req).then((res)=>{
      if(res.status == 200){
        res = res.data
        const dsKey = Object.keys(res.data_source_list)
        portalPath = res.data_source_list[dsKey].options
        setJourney(res.journey_profile)
      }
    })

  }, [])*/

  useEffect(() => {
    ReactTooltip.rebuild()
    document.addEventListener("keydown", handleEscape);
    document.addEventListener("click", handleClick, true);

    return () => {
      document.removeEventListener("keydown", handleEscape);
      document.removeEventListener("click", handleClick, true);
    }
  }, [])

  const handleEscape = (e) => {
    if (e.keyCode === 27) setOpenSideNav(false);
  }

  const handleClick = (e) => {
    if (sideNavEl.current && !(sideNavEl.current.contains(e.target) || sideNavMenuEl.current.contains(e.target))) {
      setOpenSideNav(false);
    }
  }

  /*const permission = {
    guides: () => {
      return env.admin_emails.includes(currentUser.email) ? true:false
    }
  }*/

  const Icon = ({icon, icon_color, path}) =>{
    if(CheckAccess.checkUserAccess(current_member, current_member?.permissions?.[permissionKey], path.sid)){
      return(
        <Fragment>
          <a onClick={e=>routePath(e, path)}>
            <i className={'fa '+icon} style={{color:icon_color}}/>
            <span data-tip={path.hover_text}>{path.label}</span>
            <i className="fa fa-angle-right" />
          </a>
        </Fragment>
      )  
    }else{
      return null
    }
  }

  const Icons = ({icons}) => icons && icons.map((p, i) => (
    <Icon icon={p.icon} icon_color={p.icon_color} path={p}  key={i}/>
  ))

  const routePath = (e, path) => {
    e.stopPropagation();
    if(journeyType == 'cancer_journey'){
      Cs.routeCancerPath(path, journey, history)  
    }else if(journeyType == 'infant_journey'){
      Cs.routeInfantPath(path, child, journey, history)
    }
  }

  return (ReactDOM.createPortal(
    <div id="menu-main" ref={sideNavEl}
      className={`menu pos-fixed right-0 menu-box-path menu-box-detached round-medium ${openSideNav ? "menu-active" : ""} `}
      style={{ marginTop: '40px', width: 260, opacity: 1, display: "block", 'lineHeight': '48px', 'minHeight': '500px' }}>
        
        <div className="menu-icons">
          <p className="under-heading center-text font-800 font-16">
            Journey Menu

            <a href="#" className="close-menu"
              onClick={(e) => {e.preventDefault();setOpenSideNav(false)}}
              style={{'marginBottom':'unset', float: 'right'}}>
                <i className="fa fa-times color-red2-dark" />
            </a>
          </p>
          
          <div className="clear" />
        </div>

        <div className="content top-20 bottom-10">
          <div className="link-list link-list-1" ref={sideNavMenuEl}>
            {journey?<Icons icons={menuOptions?.options || []}/>:<div className="spinner"/>}
          </div>
        </div>
    </div>, document.body)
  )
}

export default PortalSideMenu;