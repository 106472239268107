import env from '../env';
import axios from 'axios';
import moment from '../../node_modules/moment/min/moment.min.js'

class CommonService {

  static useAxios(req){
    return new Promise((resolve, reject) => {
      axios(req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static getNotifications(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/notifications', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  /*static getDataSources(req){
    return new Promise((resolve, reject) => {
      axios.get(env.ecn_backend_api+'data_sources', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }*/

  static getDataSourceList(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/data_source_lists', {params:req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static createDataSourceList(params){
    return new Promise((resolve, reject) => { 
      var u = env.health_backend+'health/data_source_lists'
      axios.post(u,params).then((res) => {
        if(res.status == 200){
          resolve( res.data );
        }
      })
    })
  }

  static updateDataSourceList(id, req){
    return new Promise((resolve, reject) => { 
      var u = env.health_backend+'health/data_source_list/'+id
      axios.put(u, req).then((res) => {
        if(res.status == 204){
          resolve( res.data );
        }
      })
    })
  }

  static searchDataSource(params){
    return new Promise((resolve, reject) => {	
    	var u = env.health_backend+'health/data_source_lists/search'
     	axios.post(u,params).then(function success(res){
     		if(res.status == 200){
       		resolve( res.data );
     		}
     	})
  	})
  }

  static createDataSource(req){
    return new Promise((resolve, reject) => { 
      var u = env.health_backend+'health/data_source_lists'
      axios.post(u, req).then(function success(res){
        if(res.status == 201){
          resolve( res );
        }else{
          reject()
        }
      })
    })
  }

  static updateDataSourceListStatus(params){
    return new Promise((resolve, reject) => { 
      let u = env.health_backend+'health/data_source_lists/status'
      axios.post(u, params).then(function success(res){
        if(res.status == 200){
          resolve( res );
        }else{
          reject()
        }
      })
    })
  }

  static deleteDataSourceListStatus(req){
    return new Promise((resolve, reject) => {
      let u = env.health_backend+'health/data_source_lists/status'
      axios.delete(u, {params:req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })  
  }

  static getDataSource(params){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/data_sources', {params: params}).then((res) => {
        // handle success
          resolve(res.data.data_source_lists);
      }).catch((e) => {
        // handle error
          reject(e);
      })
    })
  }

	static updateNotification(id, req){
    return new Promise((resolve, reject) => {
      axios.put(env.health_backend+'health/notifications/'+id, req).then((res) => {
        // handle success
          resolve(res);
      }).catch((e) => {
        // handle error
          reject(e);
      })
    })
	}

	static deleteNotification(req){
	  let url = '?delete_all='+req.delete_all
    return new Promise((resolve, reject) => {
      axios.delete(env.health_backend + 'health/notifications/'+req.id+url).then((res) => {
        // handle success
          resolve(res);
      }).catch((e) => {
        // handle error
          reject(e);
      })
    })	
	}

	static uploadFileToUrl(file, privacy_type, data, upload_type, url){
	  return new Promise((resolve, reject) => {
      let fd = new FormData()
    	fd.append('file', file)
    	fd.append('privacy_type', privacy_type)
      if(data){
        for (const property in data) {
          if(data[property])
            fd.append(`${property}`, data[property])
        }
      }

    	let reader = new FileReader()
    	let image = new Image()
    	reader.readAsDataURL(file)  

    	reader.onload = function(_file) {
      	image.src = _file.target.result;   
      	let postImageUploadurl = env.health_backend+'health/'+(url || 'file_uploads')

      	if(this.width > 1000){
          postImageUploadurl = postImageUploadurl+'?max_width=1000'
      	}
      		
      	axios.post(postImageUploadurl, fd, {headers: {'Content-Type': undefined}}).then(function success(res){
        	try{
            if(upload_type != 'comment')res.data.file_upload.url = res.data.file_upload.url.split('?')[0]; 
        		resolve(res)
        	}catch(e){
        		reject(res)
        	}
      	}).catch(function error(data){
        	reject(data)
      	})
    	}
    })
  }

  static deleteFileFromUrl(url){
    return new Promise((resolve, reject) => {	
    	var list = url.split("/");
    	var id = list[5];
    	axios.delete(`${env.health_backend}health/file_uploads/${id}`).then(function(res){
    		resolve(res)
    	}).catch(function error(data){
       	reject(data);
    	})
    })	
  }

  static getFileUrl(file){
    return env.file_url+file; 
  } 

 	static getIconByType(file, type, pre_url){
    if(file){
      if(file.match(/\.(jpeg|jpg|gif|png|ico|svg|webp)$/) != null){
        if(file.includes('selftree-public')){
          return file;
        }else{
          return env.file_url+file; 
        }
      }else if(file.match(/\.(pdf|PDF)$/)){
        return "/images/pdf_icon.png";
      }else if(file.match(/\.(xls|XLXS|XLS|xlsx|ods)$/) != null){
        return "/images/excel_icon.png";
      }else if(file.match(/\.(doc|docx|odt)$/)){
        return "/images/doc_icon.jpg";
      }else if(file.match(/\.(ppt|pptx|odp)$/)){
        return "/images/ppt.jpg";
      }else if(file.lastIndexOf(".csv")>0){
        return "/images/csv.jpg";
      }else if(file.lastIndexOf(".key")>0){
        return "/images/key.png";
      }else{
        return "/images/attachement-64.png";
      }
    }
  }

  static trimTitle(title){
    return title.length > 30 ? (title.substring(0,36) + '...'):title;
  }

	static getFileName(url){
    return url.substring((url.lastIndexOf('/'))+1);
  }

  static getFormTemplateById(id, req){
	  return new Promise((resolve, reject) => {
		  const u = env.health_backend+'health'+(req?.is_public?'/public':'')+'/form_templates/'+(id)

		  axios.get(u, {params: req, cache: true}).then(function(response){
			  resolve( response.data );
		  }).catch(function(response){
			  reject(response)
		  })  
    })
	}

  static getFormTemplates(req){
    return new Promise((resolve, reject) => {
      var u = env.health_backend+'health/form_templates'

      axios.get(u, {params:req}).then(function(response){
        resolve( response );
      })
    })
  }
  
  static getFormFieldsById(req){
    return new Promise((resolve, reject) => {
      var u = env.health_backend+'health/form_template/form_fields'

      axios.get(u, {params:req}).then(function(response){
       resolve( response );
      })
    })
  }

  static getFormWizardFields(req){
    return new Promise((resolve, reject) => {
      axios.post(env.health_backend+'form_wizard_fields/form_templates', req).then((res) => {
        // handle success
          resolve(res);
      }).catch((e) => {
        // handle error
          reject(e);
      })
    })
  }

  static getFormWizardSchedules(form_wizard_schedule_id){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+`form_wizard_schedulers/${form_wizard_schedule_id}`).then((res)=>{
        resolve( res );
      }).catch(function(res){
        reject(res)
      })
    })
  }

  static getFormTemplateRules(req){
    return new Promise((resolve, reject) => {
      var u = env.health_backend+'health/get_form_template_rules'

      axios.get(u, {params:req}).then(function(response){
        resolve( response );
      })
    })
  }

  static syncFormTemplateRules(){
    return new Promise((resolve, reject) => {
      var u = env.health_backend+'health/sync/template_rules'

      axios.get(u).then(function(response){
        resolve( response );
      })
    })
  }
  
  /*static removeUnusedCss(){
    document.head.querySelectorAll(`link[css-remove="true"]`).forEach(function(a){
      a.remove()
    })    
    //document.querySelectorAll('[rel="stylesheet"]');
  }*/

  /*static loadCss(file_name){
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', `/styles/${file_name}.css`);
    link.setAttribute('css-remove', "true");
    document.head.appendChild(link);
  }*/

  static formatUpdateDate(date, format){
    if(date)
      return moment(date).format(format || 'MMM D, YYYY');
  }

  static compareDate(date1, date2, return_type='days', non_negative=false, add_decimal=false){
    const time1 = date2?moment(date2):moment()
    const time2 = moment(date1)

    return  non_negative ? 
      time1.diff(time2, return_type, add_decimal) : time2.diff(time1, return_type, add_decimal)
  }
  
  static isToday(date){
    return moment().isSame(date, 'day')
  }

  static getCurrentDateTime(){
    return moment().format()
  }

  static geTimeDiff(end, start){
    const ms = moment(end).diff(moment(start))
    const d = moment.duration(ms)._data
    return `${d.hours}:${d.minutes}:${d.seconds}`
  }

  static calcDate(date1, date2) {
    let mdate1 = moment(date1)
    const mdate2 = moment(date2)

    const totalYears = mdate2.diff(mdate1, 'years')
    const totalDays = mdate2.diff(mdate1, 'days')
    if(totalYears > 0)
      mdate1 = mdate1.add('years', totalYears) 
    
    const monthsPassed = mdate2.diff(mdate1, 'months')
    if(monthsPassed > 0)
      mdate1 = mdate1.add('months', monthsPassed) 
    
    const weeksPassed = mdate2.diff(mdate1, 'weeks')
    if(weeksPassed > 0)
      mdate1 = mdate1.add('weeks', weeksPassed) 

    const daysPassed = mdate2.diff(mdate1, 'days')

    return {
      "totalDays":totalDays,
      "days":daysPassed,
      "years":totalYears,
      "months":monthsPassed,
      "weeks":weeksPassed,
    }

    /*
    * ref - https://blog.bitsrc.io/calculate-the-difference-between-two-2-dates-e1d76737c05a
    * calcDate() : Calculates the difference between two dates
    * @date1 : "First Date in the format MM-DD-YYYY"
    * @date2 : "Second Date in the format MM-DD-YYYY"
    * return : Object
    */
  }

  static extractHostName(url){
    if(url && url.length>0){
      let u = document.createElement('a');
      u.href = url;
      return u.hostname;
    }
    return '';
  }

  static deactivate(current_admin){
    return new Promise((resolve, reject) => {
      axios.put(`${env.health_backend}organization_admins/${current_admin.id}/deactivate`).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    })
  }

  static updateSetting(setting){
    return new Promise((resolve, reject) => {
      axios.put(`${env.health_backend}settings/${setting.id}`, setting).then((res) => {
        resolve(res);
      }).catch((e) => {
        reject(e);
      })
    }) 
  }

  static groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
  }, {});
  }

  static b64EncodeUnicode(str) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
     return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode('0x' + p1);
     }));
  }

  static b64DecodeUnicode(str) {
     // Going backwards: from bytestream, to percent-encoding, to original string.
     return decodeURIComponent(atob(str).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
     }).join(''));
  }

  static subString(str){
    return str && str.substring(0,200)
  }

  static routeJourney(journeyProfile, props){
    if(journeyProfile.data.health_condition == 1){
      /*Cancer journey template*/
      //props.history.push(`/cancer/page/${journeyProfile.id}`)
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:'?type=cancer_journey', 
      })
    }else if(journeyProfile.data.health_condition == 2){
      /*Maternal/Infant Health (Advanced) journey*/
      //props.history.push(`/infant/page/${journeyProfile.id}?type='infant_journey'`)
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:'?type=infant_journey', 
      })
    }else if(journeyProfile.data.health_condition == 3){
      /*Infant Nutrition and Growth Assessment (Base) journey*/
      //props.history.push(`/infant/page/${journeyProfile.id}?type='infant_journey'`)
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:'?type=infant_journey', 
      })
    }else if(journeyProfile.data.health_condition == 5){
      /*Dementia journey*/
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:'?type=dementia_journey', 
      })
    }
    else if(journeyProfile.data.health_condition == 7){
      /*CKD journey*/
      props.history.push({
        pathname:`/health/common/page/${journeyProfile.id}`,
        search:'?type=ckd_journey', 
      })
    }
  }

  static routeJourneyForm(ds, history){
    history.push({
      pathname:`/health/form/${ds.child_form}`,
      search:`?type=${ds.value}&journey_type=${ds.sid}`, 
      state:{'health_condition': ds.value}
    })
  }

  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  static linkify(inputText) {
    try{
      let replacedText, replacePattern1, replacePattern2, replacePattern3;

      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
      replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
      replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

      //Change email addresses to mailto:: links.
      replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
      replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

      return replacedText;
    }catch(e){
      return inputText;
    }
  }

  static getFileUploads(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/file_uploads', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static getJourneyHyperLinks(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/journey_links', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    }) 
  }

  static getIncomingMails(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/incoming_emails', {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    }) 
  }

  static deleteIncomingMails(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.health_backend}health/incoming_emails/${req}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static getRatingById(id){
    return new Promise((resolve, reject) => {
      axios.get(env.family_tree_backend+'ratings/'+id).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
  
  static createRating(req){
    return new Promise((resolve, reject) => {
      axios.post(env.family_tree_backend+'ratings', req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static updateRating(req, id){
    return new Promise((resolve, reject) => {
      axios.put(env.family_tree_backend+`ratings/${id}`, req).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  static stringToDate(date){
    let s = date.split(/[^0-9]/);
    //for (i=0;i<a.length;i++) { alert(a[i]); }
    //new Date (s[0],s[1]-1,s[2],s[3],s[4],s[5])
    //endDate is considering the 0:00:00 time will display one day before so add 23 59 59
    return new Date (s[0],s[1]-1,s[2], '23', '59', '59');
  }

  static listToObj(list, key){
    return list && list.reduce((obj, item) => (obj[item[key]] = item, obj) ,{});
  }
  
  static getAge(d1, d2){
    if(d1){
      d2 = d2 || new Date();
      return moment(d2).diff(moment(this.stringToDate(d1)), 'month'); 
    }
  }

  static get_countries(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'countries', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })   
  }
  
  static get_state(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'states', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })   
  }

  static get_district(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'cities', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })   
  }

  static get_taluka(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'sub_districts', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })   
  }

  static get_village(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'villages', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })   
  }
  
  static googleTranslate(req){
    return new Promise((resolve, reject) => {
      axios.post(env.backend+"chat_bot/google_translate", req).then((res) => {
          // handle success
        resolve(res.data);
      })
    })  
  }

  static googleInputTools(params){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/inputtools', {params:params}).then(({status, data}) => {
        if(data[0] == "SUCCESS"){
          resolve(data[1])
        }
      })
    })
  }

  static updateFormFieldLocale(req){
    return new Promise((resolve, reject) => {
      axios.put(env.health_backend+'health/form_template/update_locale', req).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  }

  static getPublicHealthCenters(req){ 
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'public_health_centers', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    })  
  }

  static getFormAssessmentFields(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'training/assessment/form', {params: req}).then((res) => {
        // handle success
        resolve(res);
      })
    }) 
  }

  static listToObj(list, key){
    return list && list.reduce((obj, item) => (obj[item[key]] = item, obj) ,{});
  }

  static routeMedicalFacility(organization_id){
    return {
      isPopupMode:true,
      match:{
        params:{
          organization_id
        }
      },
      location:{
        search: ``
      }
    }
  }

  static routeOrgMemberList(organization_id){
    return {
      isPopupMode:true,
      match:{
        params:{
          organization_id,
        }
      },
      location:{
        search: ``
      }
    }
  }

  static routeCancerPath(path, journey, history, id){
    if(['symptoms', 'daily_check', 'pain_tracking', 'side_effects', 'care_giver', 'health_center'].includes(path.sid)){
      return {
        match:{
          params:{
            journey_profile_id: journey.id,
            id: id
          }
        },
        location:{
          search: `?form_id=${path.child_form}&label=${path.sid}`
        }
      }
    }else if(['family_member'].includes(path.sid)){
      return {
        match:{
          params:{
            id: id
          }
        },
        location:{
          search: `?form_id=${path.child_form}&label=${path.sid}`
        }
      }
    }
    /*if(path.sid == 'treatment'){
      history.push(`/health/treatment/${project.id}`, project);
    }else if(path.sid == 'calendar'){
      history.push(`/health/schedules/${project.id}`, project)
    }else if(path.sid == 'provider'){
      history.push(`/health/providers/${project.id}`, project)
    }else if(path.sid == 'wellness'){
      history.push(`/health/wellness/${project.id}`, project)
    }else if(path.sid == 'suggestion'){
      history.push(`/health/suggestions/${project.id}`, project)
    }else if(path.value == 6){
      history.push(`/health/role/${project.id}`, project)
    }else if(path.value == 7){
      history.push(`/health/activities/${project.id}?label=${path.label}`, project)
    }else if(path.sid == 'appointment'){
      history.push(`/health/treatmentForm/${project.id}/${path.value}/${path.label}/${path.child_form}`, project)
    }else if(path.sid == 'medication_prescribed'){
      history.push(`/health/treatmentForm/${project.id}/${path.value}/${path.label}/${path.child_form}`, project)
    }else if(path.sid == 'track_side_effects'){
      history.push(`/health/wellnessForm/${project.id}/${path.value}/${path.label}/${path.child_form}`, project)
    }else if(path.sid == 'record_pain_sensation'){
      history.push(`/health/wellnessForm/${project.id}/${path.value}/${path.label}/${path.child_form}`, project)
    }else if(path.sid == 'record_daily_journal'){
      history.push(`/health/wellnessForm/${project.id}/${path.value}/${path.label}/${path.child_form}`, project)
    }*/
  }

  static routeInfantPath(path, child, project, history, parent_activity_id, activity_id){
    /*if(path.sid === 'portal'){
      history.push(`/infant/portal/${project.id}`)
    }else */
    if(path.sid === 'guides'){
      history.push(`/health/planner/reel/${project.id}?planner_form_id=476&label=${path.label}`, {project, child})
    }else if(path.sid === 'calendar'){
      history.push(`/infant/schedules/${project.id}?label=${path.label}`, {project, child})
    }/*else if(path.sid === 'journal'){
      history.push(`/infant/weekly/${project.id}?activity_form_id=482&label=${path.label}`, {project, child})
    }else if(path.sid === 'family'){
      history.push(`/infant/family/${project.id}?label=${path.label}`, {project, child})
    }*/else if(path.sid === 'wellness'){
      history.push(`/health/chart/${project.id}?label=${path.label}`, {project, child})
    }else if(path.sid === 'baby'){
      //c.data.baby_age = Cs.getAge(c.data.dob_baby_formatted_date)
      let child_data = {
        id_mother: project.data?.cell_number, 
      }
      history.push(`/infant/child/form/${project.id}/${child.id ?? ''}?label=${path.label}`, child_data)
    }else if(path.sid === 'track'){
      history.push(`/health/path/${project.id}?label=${path.label}`, {project, child});
    }else if(path.sid === 'visits'){
      history.push(`/infant/activities/${project.id}?activity_form_id=483&label=${path.label}`, {project, child})
    }else if(path.sid === 'bfmethod'){
      history.push(`/infant/activities/${project.id}?activity_form_id=486&label=${path.label}`, {project, child})
    }else if(path.sid === 'instant_baby' || path.sid === 'child_birth'){
      return {
        match:{params:{
          journey_profile_id: project.id,
        }},
        location:{
          search: `?activity_form_id=487&label=child`,
          state:{id_mother: project.data?.cell_number}
        }
      }
      //history.push(`/infant/child/form/${project.id}/?form_id=487&label=${path.label}`, child_data)
    }else if(['cue','hold', 'latch', 'feed', 'other', 'prepare'].includes(path.sid_code)){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          state:{},
          search: `?activity_form_id=${path.child_form}&label=${path.label}`
        }
      }
      //history.push(`/infant/activity/form/${project.id}?activity_form_id=${path.child_form}&label=${path.label}&add_parent_activity_id=true`)
    }else if(path.sid === 'Counseling'){
      history.push(`/infant/list/counseling/reel/${project.id}?label=${path.label}`, {project, child})
    }else if(['birth_history', 'prior_services', 'household_details', 'addnal_info', 'household_info'].includes(path.sid)){
      return {
        match:{
          params:{
            journey_profile_id: project.id,
            id: activity_id
          }
        },
        location:{
          state:{id_mother: project.data?.cell_number},
          search: `?form_id=${path.child_form}&label=${path.sid}`
        }
      }
    }else if(['growth_measurement', 'feeding_assessment', 'assess_cf', 'assess_bf', 'prenatal_care', 'periodic_check', 'protein_intake', 'immunization'].includes(path.sid)){
      return {
        match:{
          params:{
            journey_profile_id: project.id,
            id: activity_id
          }
        },
        location:{
          state:{},
          search: `?activity_form_id=${path.child_form}&label=${path.sid}`
        }
      }
    }/*
  else if(path.sid === 'household_details'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          state:{id_mother: project.data?.cell_number},
          search: `?form_id=545&label=household_details`
        }
      }
    }else if(path.sid === 'addnal_info'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          state:{id_mother: project.data?.cell_number},
          search: `?form_id=471&label=addnal_info`
        }
      }
    }else if(path.sid === 'household_info'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          state:{id_mother: project.data?.cell_number},
          search: `?form_id=498&label=household_info`
        }
      }
    }else if(path.sid === 'birth_history'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          state:{id_mother: project.data?.cell_number},
          search: `?form_id=540&label=birth_history`
        }
      }
    }else if(path.sid === 'prenatal_care'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          state:{id_mother: project.data?.cell_number},
          search: `?activity_form_id=${path.child_form}&label=prenatal_care`
        }
      }
    }else if(path.sid === 'assess_bf'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          state:{id_mother: project.data?.cell_number},
          search: `?activity_form_id=${path.child_form}&label=assess_bf`
        }
      }
    }else if(path.sid === 'assess_cf'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          state:{id_mother: project.data?.cell_number},
          search: `?activity_form_id=${path.child_form}&label=assess_cf`
        }
      }
    }else if(path.sid === 'feeding_assessment'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          state:{id_mother: project.data?.cell_number},
          search: `?activity_form_id=502&label=feeding_assessment`
        }
      }
      //history.push(`/health/activityForm/${project.id}?activity_form_id=488&label=${path.label}`, project)
    }else if(path.sid === 'growth_measurement'){
      return {
        match:{params:{
          journey_profile_id: project.id,
          id: activity_id
        }},
        location:{
          state:{id_mother: project.data?.cell_number},
          search: `?activity_form_id=503&label=growth_measurement`
        }
      }
      //history.push(`/health/activityForm/${project.id}?activity_form_id=488&label=${path.label}`, project)
    }
    */
  }

  static getCardCount(screen){
    if(screen.xs){
      return 1
    }else if(screen.sm){
      return 2
    }else if(screen.md){
      return 3
    }else if(screen.lg){
      return 3
    }
  }

  static emailStatus(item){
    if(!item.is_active){
      return 'InActive'
    }
    if(item.joined_from_invite){
      return 'Joined'
    }
    if(item.is_link_visited){
      return 'Invitation opened'
    }
    if(item.is_email_sent){
      return 'Invitation sent'
    }
  }

  static reverseGeocode(req){
    return new Promise((resolve, reject) => {
      axios.get('https://nominatim.openstreetmap.org/reverse', {params:req, withCredentials: false}).then((res) => {
        // handle success
        resolve(res);
      })
    })   
  }

  static displayRandomPhrase(i){
    let r_text = [];
    r_text[0] = "From screening to extended care"
    r_text[1] = "Support community and individual beneficiary needs"
    r_text[2] = "Leverage custom digital capabilities"
    r_text[3] = "Provide field staff with powerful tools"
    r_text[3] = "Augment trained staff capacity in the field"
    r_text[3] = "Mentor staff, not monitor"
    r_text[3] = "Translate insights into measurable outcomes"
    r_text[3] = "Get actionable health and well-being insights"
    return r_text[i]
  }
  
  static round(value){
    return value?Math.round(value):null;
  }

  static getSelectedText() {
    let text = "";
    if (typeof window.getSelection != "undefined") {
        text = window.getSelection().toString();
    } else if (
        typeof document.selection != "undefined" &&
        document.selection.type == "Text"
    ) {
        text = document.selection.createRange().text;
    }
    return text;
  }

  static stripeHtml(text){
    return text?.replace(/(<([^>]+)>)/gi, "")
  }

  static getSeconds(time){
    try{
      if(time){
        const a = time.split(':')
        a.length<3 && a.unshift(0);
        return (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2])
      }
    }catch(e){
      console.log(e.message)
    }
  }

  static getBrowserType(){
    const ua = navigator.userAgent;
    if((ua.indexOf("Opera") || ua.indexOf('OPR')) != -1 ){
      return 'Opera';
    }else if(ua.indexOf("Chrome") != -1 ){
      return 'Chrome';
    }else if(ua.indexOf("Safari") != -1){
      return 'Safari';
    }else if(ua.indexOf("Firefox") != -1 ) {
      return 'Firefox';
    }else if((ua.indexOf("MSIE") != -1 ) || (!!document.documentMode == true )) //IF IE > 10
    {
      return 'IE'; 
    }
  }  
  
  static formFieldListToObj = (formsMap={}) =>{
    for (const f in formsMap){
      formsMap[f] = this.listToObj(formsMap[f], 'client_id')
    }
    return formsMap
  }

  static searchList = (list, searchKeyword) =>{
    return list.filter(o => JSON.stringify(o)?.toLowerCase()?.indexOf(searchKeyword) > -1);
  }

  static nestedListSearch = (list, searchKeyword) =>{
    const result = this.searchList(list, searchKeyword)
    for(const o of result){
      for (const [k, v] of Object.entries(o)) {
        if(Array.isArray(v)){
          o[k] = this.searchList(v, searchKeyword)
        }
      } 
    }
    return result
    //return list.filter(o => JSON.stringify(o)?.toLowerCase()?.indexOf(searchKeyword) > -1);
  }

  static deepCopy = (object) =>{
    return JSON.parse(JSON.stringify(object))
  }

  /*static nextUid() {
    //https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getTime
    const dateObject = new Date();
    const uniqueId = 
      dateObject.getFullYear() + '' + 
      dateObject.getMonth() + '' + 
      dateObject.getDate() + '/' + 
      dateObject.getTime();
    return uniqueId.toString();
  }*/

}

export default CommonService;