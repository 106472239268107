import React, { useEffect, useState, useContext} from 'react'
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../../../components/PreLoadSpinner"
import DynamicForm from '../../../FormBuilder/DynamicForm'
import FormTemplateId from '../../../../constants/FormTemplateList'
import AnswerService from "../AnswerService"
import querystringify from "querystringify"
import FormContainer from '../../../FormBuilder/FormContainer'
import FormWizard from '../../../FormBuilder/FormWizard'
import Cs from "../../../../services/CommonService"

let formMode = null 
let answer = {} 
let data = {} 
let formFn = {}  

function QuestionForm(props) {
  const { state:{user:currentUser} } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  
  const get = () => {
    AnswerService.get(params.id).then((res) => {
      if(res.status==200){
        answer = res.data.response
        data = answer.data || {}
        setFormRendered(true)
      }
    })
  }
  
  useEffect(() => { 
    answer = {} 
    data = {}
    formMode = 'create-form-submissions'
    formFn = {
      'form_type':'assessment_response', 
      'editPermission':queryParam.edit
    } 
    
    if(params.id){
      get()
      formMode = 'edit-form-submissions'
    }else if(!isFormRendered){
      setFormRendered(true)
    }
  }, [])

  const create = (answer) => {
    answer.assessment_id = params.assessment_id
    AnswerService.create(answer).then((res) => {
      if(res.status==201){
        onSubmitPageRedirect(res.data)
      }
    })
  }

  const update = (answer) => {
    AnswerService.update(answer).then((res) => {
      if(res.status==204){
        onSubmitPageRedirect(answer)
      }
    })
  }

  const onSubmitPageRedirect = (res) => {
    formFn.successMessage = `Your submitted response (ID: ${res.record_id}) was received at ${Cs.formatUpdateDate(res.updated_at, 'MMM DD, YYYY hh:mm a')} `
    formFn.setIsFormSubmitted && formFn.setIsFormSubmitted()
  }

  const onCancel = () =>{
    if(queryParam.is_preview){
      props.history.push("/list/assessments")
    }else{
      props.history.push('/list/quiz')
    }
  }

  if(params.assessment_id){
    return(
      <FormWizard assessment_id={params.assessment_id} form={answer} data={data} 
      formFn={formFn} onCreate={create} onUpdate={update} onCancel={onCancel}
      confirmSubmissionAlert={true} showFieldMenu={true} 
      journey_category_id={params.journey_category_id}/>
    )
  }

  return null
  
}

export default QuestionForm