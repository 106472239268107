import env from '../../env';
import axios from 'axios';
import BaseService from '../../services/BaseService';

class ScreeningListService extends BaseService {
  /*
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.health_backend}health/screening_list`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  create(profile){
    return new Promise((resolve, reject) => {
      axios.post(`${env.health_backend}health/screening_list`,profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    return new Promise((resolve, reject) => {
      axios.get(env.health_backend+'health/screening_list/'+(req.id || null), { params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.health_backend}health/screening_list/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.health_backend}health/screening_list/${id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }
*/
}

export default new ScreeningListService('health_backend', 'health/screening_list');