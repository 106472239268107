import React, {Fragment, useState, useContext, useEffect, useMemo} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import {NavIcon} from "../Common/MenuItem"
import useStyle from '../../hooks/useStyle'
import useSort from '../../hooks/useSorting'
import SortDirection from '../../components/Common/SortDirection'
import Cs from "../../services/CommonService"
import ConfirmMenu from "../Modals/ConfirmMenu"
import useModal from "../../hooks/useModal"
import GenericModal from "../Modals/GenericModal"
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { AddBtn, FontAwesomeBtn } from "../Common/Button"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons"
import {usePageTitle} from '../../hooks/pageHead'
import CheckAccess from '../HealthJourney/Roles/CheckAccess' 
import OfflineService from './OfflineService'
import ActivityForm from '../HealthJourney/MotherInfantJourney/Activities/Form/ActivityForm'
import ChildForm from '../HealthJourney/MotherInfantJourney/Child/ChildForm'
import PendingRequests from './PendingRequests'

let selectedItem = {}
let requestParams ={}
//https://developer.mozilla.org/en-US/docs/Web/API/Request/json
const FormList = (props) => {
  usePageTitle('Form List')
  
  let params = props.match.params

  const {state: { user: currentUser }, dispatch} = useContext(AuthContext)
  const [isLoading, setIsLoading] = useState(true)
  const [componentNames, setComponentNames] = useState([])
  const [components, setComponents] = useState({})
  const [journeys] = useState(currentUser?.current_subscription?.data_source?.journeys)
  const [formProps, setFormProps] = useState({})
  const [requestPendingQue, setRequestPendingQue] = useState({})

  const {isOpen:isFormOpen, toggleModal:toggleFormModal} = useModal()
  const {isOpen:isPendingRequestOpen, toggleModal:togglePendingRequest} = useModal()

  useEffect(() => {
    requestParams = {
      organization_id:currentUser.current_organization_id,
      locale: currentUser.current_locale
    } 
    getAll()
    getOfflineQue()
  }, [])

  const mcjForms = {
    'child_birth': ChildForm,
    'default':ActivityForm
  }

  const getAll = () =>{
    OfflineService.getJourneyComponent(requestParams).then(({status, data})=>{
      if(status === 200){
        setComponents(data.journey_components)
        setComponentNames(Object.keys(data.journey_components))
      }
    })
  }

  const getOfflineQue = async () =>{
    return new Promise(function(resolve, reject) {
      const messageChannel = new MessageChannel()
      messageChannel.port1.onmessage = function(event) {
        // The response from the service worker is in event.data
        if (event.data.error) {
          alert(event.data.error)
        } else {
          setRequestPendingQue(Cs.groupBy(event.data, 'form_id'))
        }
      }

      navigator.serviceWorker.controller.postMessage('OfflineQue',
        [messageChannel.port2]
      )
    })
  }

  const openForm = (componentName, component) =>{
    try{
      if(component.sid === 'journey_profile'){
        Cs.routeJourneyForm(component, props.history)
      }else if(componentName.includes('_mcj')){
        /*MCJ Journey*/
        let fromProp = Cs.routeInfantPath(component, {}, {}, props.history)
        fromProp.component = (
          mcjForms[component.sid] ? mcjForms[component.sid]:mcjForms['default']
        )
        setFormProps(fromProp)
        toggleFormModal()
      }else if(componentName.includes('_cancer')){
        /*Cancer Journey*/
        //let props = Cs.routeCancerPath(component, {}, props.history)
      }else if(componentName.includes('_dementia')){  
        /*Cancer Journey*/
        //let props = Cs.routeCancerPath(component, {}, props.history)
      }
    }catch(e){
      console.error(e.message)
    }
  }

  const onFormSubmit = (activity={}) =>{
    toggleFormModal()
    getOfflineQue()
  }

  const openPeningRequestList = (form) =>{
    selectedItem = form
    togglePendingRequest()
  }

  const TableData = ({component={}, componentName}) => component?.options ? component.options.map((o, k) => {
    return(
      <TR key={k}>
        <TD>
          {o.child_form}
        </TD>
        <TD className="text-gray">
          {o.label}
        </TD>
        <TD className="text-gray" onSelect={()=>openPeningRequestList(o)}>
          <span className="badge-2 bg-lred">
            {requestPendingQue?.[o.child_form]?.length || 0}
          </span>
        </TD>
        <TD>
          <div className="flex aligncenter">
            <LinkFontAwesomeIcon onSelect={()=>openForm(componentName, o)} css="fas fa-plus-circle font-18 white"/>
          </div>
        </TD>
      </TR>
    )
  }):null

  const TableHead = ({componentName}) =>{
    const component = components[componentName]
    return(
      <div className="m-b-15">
              <h5 className="m-b-5">{component.label}</h5>
              <Table className="shadow-small">
                <THead>
                  <TR>
                    <TH>
                      ID 
                    </TH>
                    <TH>
                     Form
                    </TH>
                    <TH>
                     Pending Cache
                    </TH>
                    <TH>
                      Action
                    </TH>
                  </TR>
                </THead>
                <TBody>
                  <TableData component={component} componentName={componentName}/>
                </TBody>
              </Table>
      </div>
    )
  }

  return (
    <Fragment>
      <div id="ct">
        <div className="app_content">
          <div className="app-header flex justspacebetween m-t-15">
            <div className="flex coldir">
              <div className="app-title m-r-20">Offline Forms</div>
            </div>
          </div>

        <div className="app-content-section m-t-30 table-responsive">
          {componentNames.map((f, i)=>
            <TableHead componentName={f} key={i}/> 
          )}
        </div>
        </div>
      </div>

      {isFormOpen && 
        <GenericModal toggleModal={toggleFormModal} isPopupMode="true" 
        onFormSubmit={onFormSubmit} {...formProps} />
      }

      {isPendingRequestOpen && 
        <GenericModal toggleModal={togglePendingRequest} 
        component={PendingRequests} 
        pendingRequests={requestPendingQue[selectedItem.child_form]} 
        dataSourceList={selectedItem}/>
      }
    </Fragment>
  )

}

export default FormList;