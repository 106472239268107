import env from '../../../../env';
import axios from 'axios';

class ProviderService {
  
  getAll(req){
    return new Promise((resolve, reject) => {
      axios.get(`${env.ace_backend}event/planners`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  get(req){
    console.log('req sp axios',req)
    return new Promise((resolve, reject) => {
      axios.get(`${env.edu_team_backend}activities/${req.id}`, {params: req}).then((res) => {
        console.log('getid sp',res)
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        console.log('getid sp',e)
        reject(e);
      })
    })
  }

  create(profile){
    console.log('creating new journey',profile)
    return new Promise((resolve, reject) => {
      axios.post(env.edu_team_backend+'education_journey_profiles',profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  getCatId(req){
    return new Promise((resolve, reject) => {
      axios.post(`${env.edu_team_backend}activities`, {params: req}).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  update(profile) {
    return new Promise((resolve, reject) => {
      axios.put(`${env.edu_team_backend}activities/${profile.id}`, profile).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

  delete(req) {
    return new Promise((resolve, reject) => {
      axios.delete(`${env.edu_team_backend}activities/${req.id}`).then((res) => {
        // handle success
        resolve(res);
      }).catch((e) => {
        // handle error
        reject(e);
      })
    })
  }

}

export default new ProviderService();