import React, { useState, Fragment, useEffect } from "react";
import PlannerStepService from "../PlannerStepService"
import useStyle from '../../../../../hooks/useStyle';
import useSort from '../../../../../hooks/useSorting';
import SortDirection from '../../../../../components/Common/SortDirection'
import { Table, THead, TH, TR, TD, TBody } from "../../../../../components/Common/TableView"
import useModal from "../../../../../hooks/useModal"
import {ReadCsvFile} from "../../../../Common/ReadCsvFile"
import {FontAwesomeBtn} from "../../../../Common/Button"

let requestParams = {}
const PlannerSteps = ({selectedPlanner}) => {

  const [plannerSteps, setPlannerSteps] = useState([]);
  const [loading, setLoading] = useState(false)
  let [newStep, setNewStep] = useState({planner_id: selectedPlanner.id, data:{}});

  const { isOpen: isFileUploadOpen, toggleModal: toggleFileUpload} = useModal()

  useEffect(() => {
  	requestParams = {
  	  planner_id: selectedPlanner.id
  	}
    getPlannerSteps()
  }, []);

  const getPlannerSteps = () =>{
  	PlannerStepService.getAll(requestParams).then((res)=>{
  	  setPlannerSteps(res.data.planner_steps)	
  	})	  	
  }

  const {sort} = useSort(requestParams, getPlannerSteps);

  const handleInputChange = (event) => {
    newStep.data[event.target.name] = event.target.value
    setNewStep({...newStep})
  }

  const resetForm = () =>{
    setNewStep({planner_id: selectedPlanner.id, data:{}})
  }

  const addPlannerStep = () =>{
  	if(newStep.id){
  	  PlannerStepService.update(newStep).then((res)=>{
  	  	if(res.status == 204){
          resetForm()
  	    }	
	    })	
  	}else{
  	  PlannerStepService.create(newStep).then((res)=>{
    		if(res.status == 201){
    		  setPlannerSteps([res.data.planner_step, ...plannerSteps])
    		  resetForm()
    		}	
    	})	
  	}
  }

  const deleteStep = (step) => {
    PlannerStepService.delete(step.id).then((res) => {
      if (res.status == 204) {
        setPlannerSteps(
          plannerSteps.filter((i) => i.id !== step.id)
        )
      }
    })
  }

  const onReadCallback = (contents, file_name) =>{
    setLoading(true)
    let req = {
      planner_id:selectedPlanner.id,
      video_url:selectedPlanner.data.reference_url,
      file_name:file_name,
      language:selectedPlanner.data.language,
      contents:contents
    }
    PlannerStepService.uploadTxt(req).then(({status, data}) => {
      if (status === 200) {
        setPlannerSteps([...plannerSteps, ...data])
        setLoading(false)
      }else if(status == 433){
        alert(data.error)
        setLoading(false)
      }
    })
  }

  const tableData = plannerSteps.map((p, i)=>{
    let seconds = 0
    try{
      const a = p.data.start_time.split(':')
      a.length<3 && a.unshift(0);
      seconds = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2])
    }catch(e){

    }

    return(
    	<TR key={i}>
          <TD>
            <a href={`${selectedPlanner.data.reference_url}&t=${seconds}`} target="_blank">{p.data.start_time}</a>
          </TD>

          <TD>
            {p.data.description}
          </TD>

          <TD>
            {!p.file_name && <Fragment>
              <span onClick={e=>deleteStep(p)} className="far fa-trash-alt font-15"/>
              <span onClick={e=>setNewStep(p)} className="m-l-15 far fa-edit font-15"/>		
            </Fragment>}
          </TD>
      </TR>
    )
  })

  const StepListTable = () => (
  	<div className="m-t-10">
      <Table className="shadow-small">
        <THead>
          <TR>
            <TH className="TH-sm" onClick={e=>sort('data.start_time')}>ID
              <SortDirection sort_column={requestParams.sort_column} column="data.start_time" reverse={requestParams.reverse}/> 
            </TH>
            <TH className="TH-sm" onClick={e=>sort('data.title')}>Narration 
              <SortDirection sort_column={requestParams.sort_column} column="data.name" reverse={requestParams.reverse}/>
            </TH>
            <TH className="TH-sm">
              Options	 
            </TH>
          </TR>
        </THead>
        <TBody>
          {tableData}
        </TBody>
      </Table>
    </div>
  )

  return (
    <Fragment>
      <div id="ct">
        <div className="p-10">
          <div className="row p-5">
            <div className="col-xs-12">
              <FontAwesomeBtn tooltip="Upload Text File" 
                iconCss="fa fas fa-plus" isResponsive={false} name="Upload Text File"
                colorCss="btn-green"
                css="pull-right m-b-5 m-r-15 lh-17"
                onSelect={()=>toggleFileUpload()}>
              </FontAwesomeBtn>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 p-5">
            <label className="text-cap"><b>Time</b></label>
            <input placeholder="HH:MM:SS" type="text" name="start_time" id="start_time" 
              onChange={e=>handleInputChange(e)} 
              className="form-input form-input-gray form-input-full"
              defaultValue={newStep.data.start_time} value={newStep.data.start_time || ''}/>
          </div>
          <div className="col-xs-12 col-sm-6 p-5">
            <label className="text-cap"><b>Description</b></label>
            <textarea onChange={e=>handleInputChange(e)} placeholder="Description" 
              name="description" id="description" 
              className="form-input form-input-gray form-text-area form-input-full"
              defaultValue={newStep.data.description} value={newStep.data.description || ''}/>
          </div>
          <div className="col-xs-12 text-center">
            {newStep.data.start_time && newStep.data.description &&
              <button className="dropbtn bg-submit" onClick={e=>addPlannerStep()}>
                <b>{newStep.id?'Update':'Create'}</b>
              </button>
            }  
          </div>
          <StepListTable/>
        </div>
      </div>

      {isFileUploadOpen && 
        <ReadCsvFile fileType="txt"
        isFileUploadOpen={isFileUploadOpen} 
        onReadCallback={onReadCallback} />
      }
	  </Fragment>
  )
}

export default PlannerSteps;