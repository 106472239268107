import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider";
import PreLoadSpinner from "../PreLoadSpinner";
import DynamicForm from '../FormBuilder/DynamicForm';
import FormTemplateId from '../../constants/FormTemplateList'
import NutritionCareAlertService from "./NutritionCareAlertService";
import FormContainer from '../FormBuilder/FormContainer';
import PlannerService from '../HealthJourney/Health/Planners/PlannerService'
import PlannerStepService from '../HealthJourney/Health/Planners/PlannerStepService'
import useModal from "../../hooks/useModal";
import GenericModal from "../Modals/GenericModal"
import querystringify from "querystringify";

let formMode = null; 
let formId = null; 
let nutritionCareAlert = {}; 
let data = {}; 
let formFn = {};  

function AlertForm(props) {

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})
  const [plannerStepList, setPlannerStepList] = useState([])

  const { isOpen:isPlannerStepOpen, toggleModal:toggleStepPlanner } = useModal()

  let params = props.match.params
  let queryParam = querystringify.parse(props.location.search)
  
  formFn.videos_suggested_path = 'name'
  formFn.videos_suggested_pagenate = {
    page:1,
    per_page:15
  }

  const getById = (id) => {
    NutritionCareAlertService.get(id).then((res) => {
      if(res.status===200){
        nutritionCareAlert = res.data.nutrition_care_alert
        data = nutritionCareAlert.data || {}
        setFormRendered(true)
      }
    }).catch(function(res){
      nutritionCareAlert = null
    })
  }
  
  useEffect(() => { 
    formMode = 'create-form-submissions'
    data = {}
    nutritionCareAlert = {} 
    formFn = {
      form_type:'nutrition_care_alert',
      editPermission: queryParam.edit,
      localeKey:'language'
    }
   
    if(params.id){
      formMode = 'edit-form-submissions'
      getById(params.id)
    }else{
      setFormRendered(true)
    }
  }, [])

  const create = (nutritionCareAlert) => {
    nutritionCareAlert.created_by = currentUser.id
    nutritionCareAlert.nutrition_care_id = params.nutrition_care_id
    
    NutritionCareAlertService.create(nutritionCareAlert).then((res) => {
      if(res.status===201){
        onSubmitPageRedirect()
      }
    }).catch(function(res){
       
    })
  }

  const update = (nutritionCareAlert) => {
    NutritionCareAlertService.update(nutritionCareAlert).then((res) => {
      if(res.status===204){
       onSubmitPageRedirect();
      }
    }).catch(function(res){
       
    })
  }

  const onSubmitPageRedirect = () => {
    props.history.goBack()
    //props.history.push("/nutrition_cares")
  }

  formFn.get_videos_suggested = (search, on_success, on_failure) => {
    let req = {
      search: search,
      step_search: search,
      is_form_search: true,
      filter:{language:data.language},
      page: formFn.videos_suggested_pagenate.page,
      per_page: formFn.videos_suggested_pagenate.per_page
    }
    
    PlannerService.getAll(req).then(({status, data})=>{
      formFn.videos_suggested_pagenate.pageSize = data.meta.last_page
      on_success(data.planners)
    })
  }

  formFn.toggle_videos_suggested = (item) =>{
    const listKey = 'videos_suggested_array'
    const idx = data[listKey].findIndex(o => o.planner_fk_id == item.planner_fk_id);
    if(idx > -1) {
      data[listKey].splice(idx, 1);
    }else{
      data[listKey].push({name:item.name, planner_fk_id:item.id});
    }
    data.videos_suggested = data[listKey].map((o)=> o.id).join(",");
    return data[listKey]
  }

  formFn.exist_videos_suggested = (item, list_field) =>{
    return data.videos_suggested_array.findIndex(o => o.planner_fk_id == item.planner_fk_id) > -1;
  }

  formFn.select_videos_suggested = (p) => {
    p.step_planners = p.step_planners || []
    formFn.seletedPlanner = p

    let req = {
      planner_id: p.planner_fk_id,
    }

    PlannerStepService.getAll(req).then((res) => {
      setPlannerStepList(res.data.planner_steps)
      toggleStepPlanner()
    })
  }

  const PlannerStepList = ({}) => plannerStepList.map((o, k) =>
    <span className="checkbox-container" key={k} onClick={e => selectStepPlanner(o)}>
      <span>{o.data.start_time} - {o.data.description}</span>
      <input type="checkbox" checked={existStepPlanner(o)} readOnly/>
      <span className="checkbox-checkmark"></span>
    </span>
  )

  const PlannerModal = ({}) => (
    <div className="p-5">
      <h5 className="bg-lgrey ml-center white p-5 m-b-5">Planner</h5>
      <PlannerStepList/>
    </div>
  )

  const selectStepPlanner = (step_planner) =>{
    let list = formFn.seletedPlanner.step_planners
    const idx = list.findIndex(o => o == step_planner.id);
    if(idx > -1) {
      list.splice(idx, 1);
    }else{
      list.push(step_planner.id);
    }
    setPlannerStepList([...plannerStepList])
  }

  const existStepPlanner = (step_planner) =>{
    let list = formFn.seletedPlanner.step_planners
    return list.findIndex(o => o == step_planner.id) > -1; 
  }

  if(isFormRendered){
    return (
      <>
        <FormContainer post={post}>
          <DynamicForm formMode={formMode} formId={FormTemplateId.NutritionCareAlertForm} 
          form={nutritionCareAlert} data={data} formFn={formFn} onCreate={create} 
          onUpdate={update} post={post} onCancel={onSubmitPageRedirect} setPost={setPost}/>
        </FormContainer>

        {isPlannerStepOpen && <GenericModal component={PlannerModal} 
          toggleModal={toggleStepPlanner} isPopupMode={true}/>
        }
      </>
    )  
  }else{
    return null
  }
  
}

export default AlertForm;