import React, { useEffect, useState, useContext } from "react"
import { AuthContext } from "../../../contexts/Authentication/AuthStateProvider"
import PreLoadSpinner from "../../PreLoadSpinner"
import DynamicForm from "../../FormBuilder/DynamicForm"
import FormTemplateId from "../../../constants/FormTemplateList"
import OrganizationMemberService from "./OrganizationMemberService"
import querystringify from "querystringify"
import FormContainer from '../../FormBuilder/FormContainer'
import GeoLocation from "../../Common/GeoLocation"
import {CityState} from "../../Common/CityState"

let formMode = null
let orgMember = {}
let data = {}
let formFn = {}

function MemberForm(props) {
  const {state: { user: currentUser }, dispatch,} = useContext(AuthContext)
  const [isFormRendered, setFormRendered] = useState(false)
  const [post, setPost] = useState({})

  useEffect(() => {
    if (props.id) {
      orgMember = props.orgMember || {}
      data = orgMember.data || {}
      formFn = {form_type: "health.organization_member",}
      formMode = "edit-form-submissions"
      setFormRendered(true)
    }
  }, [])

  const update = (org_member) => {
    props.updateMember(org_member)
  }

  const onSubmitPageRedirect = () => {
      props.toggleModal();
  }

  const onCancel = () => onSubmitPageRedirect(true);

  if (isFormRendered) {
    return (
      <FormContainer post={post} isPopupMode={props.isPopupMode}>
        <DynamicForm formMode={formMode}
          formId={FormTemplateId.OrganizationMemberForm}
          form={orgMember}
          data={data}
          formFn={formFn}
          onUpdate={update}
          post={post}
          onCancel={onSubmitPageRedirect}
          setPost={setPost}
          isPopupMode={props.isPopupMode}/>
        <GeoLocation geoData={orgMember}/>
        <CityState formFn={formFn} data={data}/>
      </FormContainer>
    )
  } else {
    return null
  }
}

export default MemberForm;