import React, { useState, useEffect, Fragment} from 'react'
import ReactDOM from 'react-dom'
import {Link} from "react-router-dom"
import RoleService from '../../HealthJourney/Roles/RoleService'
import {AdminTypes} from '../../../constants/DataSourceList'
import MemberForm from "./MemberForm"
import Tabs, { TabPane } from "rc-tabs"
import NumberFormat from 'react-number-format'
import {CountryCodes} from "../../../constants/CountryCodes"
import FormHelper from "../../FormBuilder/FormHelpers"
import {CheckBox, DateTimePicker, OptionsObjList} from "../../Common/FormInput"
import {FacilityLocationFilter} from "../../HealthJourney/MotherInfantJourney/MedicalCareFacility/FacilityLocationFilter"

let invalidEmails = []
let timeout = null
const AddMember = (props) => {
  let {editMember, toggleModal, onSubmit: addMember, organizationId, medicalFacilityId, subscriptionId, currentUser} = props
  if(editMember){
    //editMember.role_ids = editMember.role_ids || []
    /*editMember.medical_facility_id = editMember.medical_facility_id || []
    editMember.medical_sub_facility_id = editMember.medical_sub_facility_id || []*/
    editMember.facility_member_mappings = editMember.facility_member_mappings || [] 
    editMember.facilities = editMember.facilities || []
    editMember.remove_facility_mapping_ids = editMember.remove_facility_mapping_ids || []
  }

  const [newMember, setNewMember] = useState(editMember)
  const [loading, setLoading] = useState(true)
  const [roles, setRoles] = useState([])
  //const [medicalFacilities, setMedicalFacilities] = useState([])
  const [formError, setFormError] = useState({})
  const [error, setError] = useState()
  const [selectedFacilities, setSelectedFacilities] = useState(editMember.facilities)

  useEffect(() => {
    getRoles()
    return ()=>{
      invalidEmails = []
    }
  }, [])

  useEffect(() => {
    if(!loading){
      if(newMember.medical_facility_ids?.length > 0)
        setSelectedFacilities(selectedFacilities.filter(
          f => editMember.medical_facility_ids.indexOf(f.id)>-1
        ))

      FormHelper.validate('ct_form', newMember, formError, setFormError)
    }
  }, [newMember])

  const getRoles = () =>{
    setLoading(true)
    RoleService.getAll({
      'roleable_type':'infant_journey',
      'organization_id':organizationId,
      //'add_medical_facilities':medicalFacilityId?false:true,
    }).then(({status, data})=>{
      if(status == 200){
        //setMedicalFacilities(data.medical_facilities || [])
        setRoles(data.roles)
        setLoading(false)
      }
    })    
  }

  /* 
   * item ->(facility or sub_facility)
   */
  const setFacility = (item, lkey, rkey, medical_facility_id) => {
    let list = newMember.facility_member_mappings
    const idx = list.findIndex((f) => f[lkey] === item[rkey])
    if(idx > -1) {
      if(list[idx].id){
        /*This ids will be removed in backend*/
        newMember.remove_facility_mapping_ids.push(list[idx].id)
      }
      list.splice(idx, 1)
    }else{
      let mapping = {[lkey]:item[rkey]}
      if(lkey === 'medical_sub_facility_id'){
        mapping['medical_facility_id'] = medical_facility_id
      }else if(lkey === 'medical_facility_id'){
        mapping.has_all_sub_facility_access = true
      }
      list.push(mapping)
    }
    setNewMember({...newMember, facility_member_mappings:list})
  }

  const existsFacility = (item, lkey, rkey) => {
    let list = newMember.facility_member_mappings
    return list.findIndex((f) => f[lkey] === item[rkey]) > -1
  }

  const isMedicalFacilityUnselected = (item) => {
    /*Parent medical facility not selected*/
    let list = newMember.facility_member_mappings
    return list.findIndex((f) => (f.medical_sub_facility_id == null && f.medical_facility_id === item.id)) < 0
  }

  const toggle = (key) => {
    if(key === 'all_facility'){
      setNewMember({...newMember, 'has_all_facility_access': !newMember.has_all_facility_access})
    }else{
      setNewMember({...newMember, [key]: !newMember[key]})
    }
  }

  /*const setEmail = (member) => {
    newMember.email = member.email
  }*/

  const setUserEmail = (value) =>{
    clearTimeout(timeout)

    // Make a new timeout set to go off in 1000ms (1 second)
    timeout = setTimeout(() => {
      newMember.email = value
      invalidEmails = FormHelper.validateEmails(value)
      setNewMember({...newMember})
    }, 1000)
  }

  const validateForm = () =>{
    if(((newMember.invite_mode === 'email' && invalidEmails?.length === 0) 
      || (newMember.invite_mode === 'phone_no' && newMember?.phone_no?.length > 0))
      && formError.is_valid){
      return true
    }

    return false
  }

  const toggleAdmin = (admin_type) =>{
    AdminTypes.forEach((a) =>
      newMember[a.key] = (a.key === admin_type?!newMember[a.key]:false)
    )
    setNewMember({...newMember})
  }

  const updateMember = (member_info)=>{
    setNewMember({...newMember, data:member_info.data})
    addMember(null, newMember, onError)
  }

  const setFormatNumber = (values) =>{
    const { formattedValue, value } = values
    newMember.phone_no = value
  }

  const onInputChange = (e) =>{
    newMember[e.name] = e.value
    setNewMember({...newMember})
  }

  const onError = (error) =>{
    setError(error)
    setTimeout(function() {
      setError()
    }, 5000)
  }

  return(
    <Fragment>
      <div className="flex justspacebetween modal-title">
        <h4 className="f18 text-white fw500 p12-10-15">
          {newMember.id?'Update':'Add'} User
        </h4>
        <button className="btn-close" onClick={e=>toggleModal(e)}>⨉</button>
      </div>
      {loading ? <span className="spinner"/>:
        <Tabs moreIcon="+">
          <TabPane tab="Permission" key="0">
            <form id="ct_form" className="ml-container m-b-10">
                <label className="form-label m-b-5 req-field">Invite Mode</label>
                <select name="invite_mode" onChange={e=>onInputChange(e.target)} 
                  className="form-control ml-input ml-border m-b-20"
                  defaultValue={newMember.invite_mode} required>
                  <option value="">None</option>
                  <option value="email">Email</option>
                  <option value="phone_no">Phone No</option>
                </select>
                
                {newMember.invite_mode === 'email' &&
                  <>
                    <label className="form-label m-b-5 req-field">Email</label>
                    <input onChange={e=>setUserEmail(e.target.value)} 
                      className="ml-input ml-border"
                      type="text" placeholder="Email" name="email" 
                      defaultValue={newMember.email} required/>
                    <div className="red m-b-20">
                      {invalidEmails.length>0 && ('Invalid Email '+ invalidEmails.toString())}
                    </div>
                  </>
                }

                {newMember.invite_mode === 'phone_no' &&  
                  <>
                    <label className="form-label m-b-5 req-field">Mobile</label>
                    <input onChange={e=>newMember.phone_no = e.target.value} 
                      className="ml-input ml-border m-b-20"
                      type="number" placeholder="9999999999" name="phone_no" 
                      defaultValue={newMember.phone_no} required/>
                  </>
                }

                {currentUser?.current_member?.role_master && !newMember.role_record_id && <>
                  <label className="form-label m-b-5">Admin</label>
                  {AdminTypes.map((a, k) =>
                    <Fragment key={k}>
                      <span className="checkbox-container" onClick={e => toggleAdmin(a.key)}>
                        <span>{a.label}</span>
                        <input type="checkbox" checked={newMember[a.key]} readOnly/>
                        <span className="checkbox-checkmark"/>
                      </span>
                    </Fragment>
                  )}
                  </>
                }
                  
                {!(newMember.role_admin || newMember.role_dupty_admin) && 
                  <>
                    <label className="form-label m-b-5 m-t-20 req-field">Role</label>
                    <select name="role_record_id" 
                      onChange={e=>onInputChange(e.target)} 
                      className="form-control ml-input ml-border m-b-20"
                      defaultValue={newMember.role_record_id} required>
                      <option value="">None</option>
                      <OptionsObjList list={roles} id_key='record_id' label_key='role'/>
                    </select>
                  </>
                }
                  
                {roles?.length === 0 && 
                  <Link className="m-b-20" 
                    to={`/health/role/infant_journey/${currentUser.id}/${organizationId}/${subscriptionId}`}>
                      Add Roles
                  </Link>
                }
                
                {editMember.facility_member_mappings &&
                  <>
                    <label className="form-label m-b-5 req-field">
                      Medical Facility
                    </label>
                    {editMember.facility_member_mappings.map((f, i)=>
                      <span className="badge-2" key={i}>
                        <i onClick={()=>setFacility(
                            f, 
                            (f.medical_sub_facility_id ? 'medical_sub_facility_id' : 'medical_facility_id'),
                            (f.medical_sub_facility_id ? 'medical_sub_facility_id' : 'medical_facility_id')
                          )} 
                          className="fas fa-times-circle m-r-10" />
                        {f.name}
                      </span>
                    )}
                  </>
                }

                <label className="form-label m-b-5 req-field">Invite Message</label>
                <textarea maxLength="100" onChange={e => onInputChange(e.target)}
                  className="ml-input ml-border" placeholder="Invitation Message" 
                  name="message" defaultValue={newMember.message} required>
                </textarea>
                <span>Maximum 100 Characters</span>

                {newMember.has_all_facility_access ? 
                  null
                  :
                  <input type="hidden" name="facility_member_mappings" required/>
                }
                  
                <div className="error">{error}</div>

                {validateForm() &&
                  <div className="ml-button ml-block ml-section ml-padding bg-highlight white" 
                    onClick={e=>addMember(e, newMember, onError)}>
                    <b>{newMember.id?'Update':'Invite'}</b>
                  </div>
                }  
            </form>
          </TabPane>
          
          <TabPane tab="Area/Facilities" key="1">
            <FacilityLocationFilter organizationId={organizationId}
            setSelectedFacilities={setSelectedFacilities}
            selectedFacilities={selectedFacilities}
            existsFacilities={existsFacility}
            setMemberFacilities={setFacility}
            toggleAllFacility={toggle}
            isAllFacilityChecked={newMember.has_all_facility_access}
            isMedicalFacilityUnselected={isMedicalFacilityUnselected}/>
            {validateForm() &&
              <div className="ml-button ml-block ml-section ml-padding bg-highlight white" 
                onClick={e=>addMember(e, newMember, onError)}>
                  <b>{newMember.id?'Update':'Invite'}</b>
              </div>
            }
          </TabPane>
          
          {newMember.id && 
            <TabPane tab="Info" key="2">
              <MemberForm id={newMember.id} orgMember={newMember} 
                updateMember={updateMember} isPopupMode={true}
                toggleModal={toggleModal}/>  
              <div className="error">{error}</div>              
            </TabPane>
          }
        </Tabs>  
      }
    </Fragment>
  ) 
}

export default AddMember;

/*
{medicalFacilityId == null &&
                  <>
                    <label className="form-label m-b-5 req-field">Medical Facility</label>
                    <select name="medical_facility_id" 
                      onChange={e=>onInputChange(e.target)} 
                      className="form-control ml-input ml-border m-b-20"
                      defaultValue={newMember.medical_facility_id} required>
                      <option value="">None</option>
                      <OptionsObjList list={medicalFacilities} id_key='id' label_key='phc_name'/>
                    </select>
                  </>
                }

<NumberFormat mask="_" 
                  defaultValue={newMember.phone_no}
                  onValueChange={(values) => setFormatNumber(values)}
                  format={CountryCodes[countryCode][3]} 
                  className="ml-input ml-border ml-margin-bottom"/>
*/