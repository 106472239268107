import env from '../../../../env';
import axios from 'axios';
import BaseService from '../../../../services/BaseService';

class PlannerStepService extends BaseService{
	
	uploadTxt(req){
	  return new Promise((resolve, reject) => {
	    axios.post(`${env.health_backend}health/upload/planner_steps`, req).then((res) => {
	      // handle success
	      resolve(res);
	    }).catch((e) => {
	      // handle error
	      resolve(e.response);
	    })
	  })
  	}
}

export default new PlannerStepService('health_backend', 'health/planner_steps');