import React, { Fragment, useState, useEffect, useContext} from 'react'
import { AuthContext } from '../../contexts/Authentication/AuthStateProvider'
import AuthenticationService from '../../services/AuthService'
import DataAccessesService from './DataAccessesService'
import useStyle from '../../hooks/useStyle'
import ReactDOM from 'react-dom'
import Cs from '../../services/CommonService'
import env from '../../env'
import OrganizationMemberService from '../Organizations/Members/OrganizationMemberService'
import {useSearch} from '../../hooks/useSearch'
import WorkmateService from '../Workmate/WorkmateService'

const UsersRolesPopup = (props) => {
  useStyle('dashboard')	

  const {itemType, itemId, showRoles, showOrgMembers} = props
  const { state: { screen, user: currentUser }} = useContext(AuthContext)

  let [roles, setRoles] = useState([])
  let [workmates, setWorkMates] = useState([])
  let [workmatesByRole, setWorkMatesByRole] = useState({})
  let [orgMembers, setOrgMembers] = useState([])
  let [dataAccess, setDataAccess] = useState([])
  const [shareableMembers, setShareableMembers] = useState([])
  const [loading, setLoading] = useState(true)

  const {onSearchCallback, searchKeyword} = useSearch(() => searchOrgMember());

  useEffect(() => {
    getRoles()
  }, [])

  useEffect(()=>{
    setWorkMatesByRole(Cs.groupBy(workmates, 'role'))
  }, [workmates])

  const getRoles = () =>{
	  setLoading(true)
  	const req = {
      item_type: itemType,
      item_id: itemId,
  	  organization_id: currentUser.current_organization_id,
      roleable_type: 'infant_journey',
      page:1,
      per_page:30,
  	}
    DataAccessesService.getAll(req).then((res)=>{
      if(res.status === 200){
        setRoles(res.data.roles)
        setWorkMates(res.data.workmates)
        setDataAccess(res.data.organization_data_access)
        setShareableMembers(res.data.shareable_org_members)
		    setLoading(false)
      }
    })
  }

  const searchOrgMember = async () =>{
    setLoading(true)
    const req = {
      'organization_id':currentUser.current_organization_id,
      'default_fields':'ignore',
      'fields':['id', 'email', 'user_id', 'phone_no', 'role_name', 'data.affiliation'],
      'workmate_not_exist':true,
      'search':searchKeyword
    }
    const {status, data} = await OrganizationMemberService.search(req)
    if(status === 200){
      setOrgMembers(data.organization_members)
      setLoading(false)
    }
  }

  const createOrRemove = (access, item, key) =>{
    setLoading(true)
  	if(access){
  	  DataAccessesService.delete(access.id).then((res)=>{
	      if(res.status == 204){
	        setDataAccess(dataAccess.filter((i) => i.id !== access.id))
          setLoading(false)
	      }
	    })	
  	}else{
  		let req = {
        'item_type':itemType,
        'item_id':itemId,
	  	  'organization_id':currentUser.current_organization_id,
	  	}
      if(key == 'role'){
        req.role_id = item.id
        req.role_record_id = item.record_id
      }else if(key == 'org_member'){
        req.user_id = item.user_id
        req.email_id = item.email
        req.organization_member_id = item.organization_member_id
      }
	    DataAccessesService.create(req).then((res)=>{
	      if(res.status === 201){
	        setDataAccess([res.data.organization_data_access, ...dataAccess])    
          setLoading(false)
	      }
	    })
  	}
  }

  const addWorkmate = async (member) =>{
    setLoading(true)
    const req = {
      'user_id':currentUser.id,
      'workmate_id':member.user_id,
      'organization_id':currentUser.current_organization_id
    }
    const {status, data} = await WorkmateService.create(req)
    if(status === 201){
      let workmate = Object.assign({}, member) 
      member.organization_member_id = member.id
      member.id = data.workmate.id
      setWorkMates([...workmates, workmate])
      setOrgMembers(orgMembers.filter((o)=> o.id !== member.id))
      setLoading(false)
    }
  }

  const removeWorkmate = async (workmate) =>{
    setLoading(true)
    const {status} = await WorkmateService.delete(workmate.id)
    if(status === 204){
      setWorkMates(workmates.filter((o)=> o.id !== workmate.id))
      setLoading(false)
    }
  }

  const toggleMember = (member) =>{
    const r = dataAccess.find((i) => i.user_id === member.user_id)
    createOrRemove(r, member, 'org_member')
  }

  const toggleRole = (role) =>{
    const r = dataAccess.find((i) => i.role_id === role.id)
    createOrRemove(r, role, 'role')
  }

  const roleExists = (role) =>{
    const r = dataAccess.find((i) => i.role_id === role.id)
    return r?.id
  }

  const WorkmateList = ({workmates}) => workmates.map((m, i) => {
    if(currentUser.email === m.email)
      return null;

    const access = dataAccess.find((i) => i.user_id === m.user_id)
    let notificationStatus = '';
    if(access?.is_notification_sent){
      notificationStatus = 'Notified'
    }else if(access?.is_viewed){
      notificationStatus = 'Viewed'
    }

    return(
      <div className="m-b-5" key={i}>
        <span 
          onClick={()=>toggleMember(m)}
          className={access?'font-18 far fa-check-square text-muted':'font-18 far fa-square text-muted'}/>
        <span 
          className="m-l-15 font-16" 
          onClick={()=>toggleMember(m)}>{m.email || m.phone_no} {m.affiliation} {notificationStatus}</span>
        {access ? 
          null
          :
          <span className="m-l-15 fas fa-user-times lred font-18" onClick={()=>removeWorkmate(m)}/>
        }
      </div>
    )
  })

  const ShareableMemberList = ({shareableMembers}) => shareableMembers.map((m, i) => {
    if(currentUser.email === m.email)
      return null;

    const access = dataAccess.find((i) => i.user_id === m.user_id)
    let notificationStatus = '';
    if(access?.is_notification_sent){
      notificationStatus = 'Notified'
    }else if(access?.is_viewed){
      notificationStatus = 'Viewed'
    }

    return(
      <div className="m-b-5" key={i}>
        <span 
          onClick={()=>toggleMember(m)}
          className={access?'font-18 far fa-check-square text-muted':'font-18 far fa-square text-muted'}/>
        <span 
          className="m-l-15 font-16" 
          onClick={()=>toggleMember(m)}>{m.email || m.phone_no} {m.affiliation} {notificationStatus}</span>
      </div>
    )
  })

  const WorkmateGroupRole = ({roleKeys}) => roleKeys.map((r, i) => {
    return(
      <Fragment key={i}>
        <h4 className="bold font-16 m-t-15">{r}</h4>
        <WorkmateList workmates={workmatesByRole[r] || []}/>
      </Fragment>
    )
  })

  const MemberList = () => orgMembers.map((m, i) => {
    if(currentUser.email === m.email)
      return null;

    return(
      <div className="m-b-5" onClick={e=>addWorkmate(m)} key={i}>
        <span className={'font-18 fas fa-user-check text-muted'}/>
        <span className="m-l-15 font-16">{m.email || m.phone_no}</span>
      </div>
    )
  })

  const RoleList = () => roles.map((r, i) => {
  	return(
      <div className="m-b-5" onClick={e=>toggleRole(r)} key={i}>
      	<span className={roleExists(r)?'font-18 far fa-check-square text-muted':'font-18 far fa-square text-muted'}/>
      	<span className="m-l-15 font-16">{r.role}</span>
      </div>
    )
  }) 

  if(loading){
    return(
      <div className="spinner"/>
    )
  }

  return(
  	<div className="p-15">
      <h4 className="bold font-16 m-t-15">Add Member</h4>
      <div className="input-group m-r-20 m-t-10 m-b-10" id="search_box">
        <input type="text" onChange={(e)=>onSearchCallback(e)} placeholder="Search" className="form-control input-sm"/>
        <span className="input-group-btn">
          <p type="button" className="bg-highlight input-sm font-12">
            <i className="fas far fa-search white font-14"/>
          </p>
        </span>
      </div>
      {searchKeyword ?
        <MemberList/>
        :
        null  
      }

      <WorkmateGroupRole roleKeys={Object.keys(workmatesByRole)}/>

      {shareableMembers.length > 0 ?
        <>
          <h4 className="bold font-16 m-t-15">{shareableMembers[0].role}</h4>
          <ShareableMemberList shareableMembers={shareableMembers}/>
        </>
        :
        null
      }
    </div>
  )
}
/*
<h4 className="bold font-16">Role</h4>
      <RoleList/>
*/
export default UsersRolesPopup;