import React, {Fragment, useState, useEffect, useContext} from "react"
import {AuthContext} from "../../../../contexts/Authentication/AuthStateProvider"
import HealthCategoryService from "../../HealthCategoryService"
import ApexChart from "../../../Charts/ApexChart"
import {DateTimePicker, OptionsObjList} from "../../../Common/FormInput"
import PreLoadSpinner from "../../../PreLoadSpinner"
import useStyle from "../../../../hooks/useStyle"
import {TDInputMultiSelect} from "../../../Common/FormInput"
import {PresetDateRange, LeaderboardCharts} from "../../../../constants/DataSourceList"
import {Filters} from "./Filters"
import FilterSlider from "../../../Common/FilterSlider"
import useModal from '../../../../hooks/useModal'
import { Table, THead, TH, TR, TD, TBody } from "../../../Common/TableView"
import useSort from '../../../../hooks/useSorting'
import SortDirection from '../../../Common/SortDirection'
import VillageLeaderboard from './VillageLeaderboard'
import GenericModal from '../../../Modals/GenericModal'

let requestParams = null
const Leaderboard = ({isFilterBtnClick, activeTab}) => {

  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})
  const [filter, setFilter] = useState({})
  const [charts, setCharts] = useState(LeaderboardCharts)
  const [reportType, setReportType] = useState('leadership')
  const [selectedTaluka, setSelectedTaluka] = useState()

  const { state:{screen, user:currentUser}, dispatch } = useContext(AuthContext)

  const { isOpen:isFilterOpen, toggleModal:toggleFilterModal } = useModal()
  const { isOpen:isVillageReportOpen, toggleModal:toggleVillageReportModal } = useModal()

  useEffect(() => {
    toggleFilterModal()
  }, [isFilterBtnClick])

  useEffect(()=>{
    requestParams = {
      filter_type:reportType,
      geo_level:'talukas',
      start_date:filter.start_date,
      end_date:filter.end_date,
      district:currentUser.current_organization?.data?.district,
      talukas:filter.talukas,  
      villages:filter.villages, 
      medical_facility_ids:filter.medical_facility_ids 
    }
  	getReport()
  }, [filter, reportType])

  const getReport = ()=>{
  	setLoading(true)
  	HealthCategoryService.getInfantInsights(requestParams).then((res)=>{
      setData(res.data.reports)
	    setLoading(false)			
	  })
  }
  
  const {sort} = useSort(requestParams, getReport)

  const openTalukaReport = (taluka_data) =>{
    setSelectedTaluka(taluka_data)
    toggleVillageReportModal()
  }

  return(
    <>
      {loading ? 
        <div className="spinner"/>
        :
        <div className="app-content-section">
          <div className="row">
            <div className="bg-white p20 border-rounded-10 m-b-30">
              <div className="row">
                <div className="col-md-12">
                  <div className="card-title-gray flex justspacebetween p20">
                    <h4 className="fw700 f18 text-left">
                      <span>
                        {currentUser.current_organization?.data?.state} 
                        {currentUser.current_organization?.data?.state && ' > '} 
                      </span>
                      <span>{currentUser.current_organization?.data?.district} </span> 
                    </h4>
                    <h4 className="fw700 f16">
                      Last 90 Days
                    </h4>
                  </div>
                  <div className="card-body p20 table-responsive clearfix">
                    <Table className="shadow-small">
                      <THead>
                        <TR>
                          <TH onClick={e=>sort('taluka')}>
                            No 
                            <SortDirection sort_column={requestParams.sort_column} column="taluka" reverse={requestParams.reverse}/>
                          </TH>
                          <TH onClick={e=>sort('taluka')}>
                            Name 
                            <SortDirection sort_column={requestParams.sort_column} column="taluka" reverse={requestParams.reverse}/>
                          </TH>
                          <TH onClick={e=>sort('villages')}>
                            Villages
                            <SortDirection sort_column={requestParams.sort_column} column="villages" reverse={requestParams.reverse}/>
                          </TH>
                          <TH onClick={e=>sort('medical_facilities')}>
                            Facilities
                            <SortDirection sort_column={requestParams.sort_column} column="medical_facilities" reverse={requestParams.reverse}/>
                          </TH>
                          <TH onClick={e=>sort('active_cases')}>
                            Users
                            <SortDirection sort_column={requestParams.is_active} column="active_cases" reverse={requestParams.reverse}/>
                          </TH>
                          <TH onClick={e=>sort('active_cases')}>
                            Cases
                            <SortDirection sort_column={requestParams.is_active} column="is_active" reverse={requestParams.reverse}/>
                          </TH>
                          <TH onClick={e=>sort('weight_below_median')}>
                            Weight Below Median
                            <SortDirection sort_column={requestParams.is_active} column="weight_below_median" reverse={requestParams.reverse}/>
                          </TH>
                          <TH onClick={e=>sort('weight_above_median')}>
                            Weight Above Median
                            <SortDirection sort_column={requestParams.is_active} column="weight_above_median" reverse={requestParams.reverse}/>                            
                          </TH>
                          <TH onClick={e=>sort('height_below_median')}>
                            Height Below Median
                            <SortDirection sort_column={requestParams.is_active} column="height_below_median" reverse={requestParams.reverse}/>                            
                          </TH>
                          <TH onClick={e=>sort('height_above_median')}>
                            Height Above Median
                            <SortDirection sort_column={requestParams.is_active} column="height_above_median" reverse={requestParams.reverse}/>
                          </TH>
                        </TR>
                      </THead>
                      <TBody>
                        <TableData reports={data} openTalukaReport={openTalukaReport}/>
                      </TBody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      {isVillageReportOpen && 
        <GenericModal component={VillageLeaderboard} 
          isOpen={isVillageReportOpen}
          toggleModal={toggleVillageReportModal}
          talukaData={selectedTaluka}
          widthCss="w-90p"
        />
      }
      {/*{(isFilterOpen && activeTab === 'leadership') && 
        <FilterSlider component1={Filters} 
          openSideNav={isFilterOpen}
          setOpenSideNav={toggleFilterModal}
          chartFilter={filter}
          setChartFilter={setFilter}
          onFilterSubmit={getReport}
          reportType={reportType}/>
      }*/}
    </>
  )
 
}

const TableData = ({reports=[], openTalukaReport}) => reports.map((r, k) => {
    return(
      <TR key={k}>
        <TD className="text-gray">
          {k+1}
        </TD>
        <TD onSelect={()=>openTalukaReport(r)} className="fw500 text-blue underline">
          {r.taluka}
        </TD>
        <TD>
          {r.villages}
        </TD>
        <TD>
          {r.medical_facilities}
        </TD>
        <TD className="font-18">
        </TD>
        <TD>
          {r.active_cases}
        </TD>
        <TD>
          {r.weight_below_median}
        </TD>
        <TD>
          {r.weight_above_median}
        </TD>
        <TD>
          {r.height_below_median}
        </TD>
        <TD>
          {r.height_above_median}
        </TD>
      </TR>
    )
  })

export default Leaderboard;