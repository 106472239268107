import React, {useState, useEffect, Fragment, useContext} from "react"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import {Link} from "react-router-dom"
import useStyle from '../../hooks/useStyle'
import CommonService from '../../services/CommonService'
import NutritionCareNotification from '../NutritionCares/NutritionCareNotification'

const PublicNotificationsPage = (props) => {
  useStyle('dashboard')
  
  let params = props.match.params
  const { state:currentUser, dispatch } = useContext(AuthContext)

  const setNotificationRead = (n) => {
    let req = {'status':'read'}
    CommonService.updateNotification(n.id, req).then((res)=>{
      n.status = 'read';
    })
  }

  return (
    <Fragment>
      <div className="col-xs-12">
        <div className="card card-style">
          <div className="content">
            <NutritionCareNotification 
              itemType="journey_profile" 
              itemUuid={params.journey_profile_id} 
              parentNotificationId={params.parent_notification_id}
            />
          </div>
        </div>
      </div>
    </Fragment>
  )

}

export default PublicNotificationsPage;