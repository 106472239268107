import React, { Fragment, useState, useEffect, useContext} from "react"
import ReactDOM from "react-dom"
import { Link } from "react-router-dom"
import useStyle from "../../hooks/useStyle"
import Cs from "../../services/CommonService"
import useEscape from "../../hooks/useEscape"
import FormAlertService from "../../services/FormAlertService"
import useModal from "../../hooks/useModal"
import { AuthContext } from "../../contexts/Authentication/AuthStateProvider"
import env from "../../env"
import FormTemplateList from '../../constants/FormTemplateList'
import {FormAlertTypes, DataSourceSelectionType} from "../../constants/DataSourceList"
import {OptionsObjList} from '../Common/FormInput'
import DataSourceMapperModal from "./DataSourceMapperModal"
import ReactTooltip from 'react-tooltip'
import GenericModal from '../Modals/GenericModal'
import { Table, THead, TH, TR, TD, TBody } from "../Common/TableView"
import { AddBtn, FontAwesomeBtn } from "../Common/Button"
import { LinkDeleteIcon, LinkFontAwesomeIcon } from "../Common/ImageIcons"

let selectedFormRule = null
const FormRuleAlert = ({formId}) => {
  useStyle("add_role_dialog")
  useStyle("filter_dialog")

	const { state:{user:currentUser}, dispatch } = useContext(AuthContext)
  
  const [loading, setLoading] = useState(true)
  const [formRules, setFormRules] = useState([])

	const {isOpen: isDsModalOpen, toggleModal: toggleDsModal} = useModal()

  useEffect(() => {
  	getFormRules()
  	selectedFormRule = null
  }, [])

  const getFormRules = () =>{
  	Cs.getFormTemplateRules({
  		'form_template_id':formId
  	}).then(({status, data}) => {
      if(status === 200)
    	  setFormRules(data)
        setLoading(false)
    })
  }

  const syncFormRules = () =>{
    setLoading(true)
    Cs.syncFormTemplateRules().then(({status})=>{
      if(status === 204){
        alert("Success")
        getFormRules()
      }else{
        alert("Failed")
      }
      setLoading(false)
    })
  }

  const openDataMapping = (rule) =>{
    selectedFormRule = rule
    toggleDsModal()
  }

  const TableData = () => formRules.map((r, k) => {
	  return(
	    <TR key={k}>
  	    <TD className="text-gray">
  	      {r.id}
  	    </TD>
  	    <TD className="fw500">
  	      {r.name}
  	    </TD>
  	    <TD>
	        <span onClick={()=>openDataMapping(r)} className="fas fa-list">
            <span className="badge-2 sm">{r.counseling_count}</span>
          </span>
	      </TD>
	    </TR>
	  )
  })

  if(isDsModalOpen){
    return(
    	<DataSourceMapperModal formId={formId} 
      isOpen={isDsModalOpen} toggleModal={toggleDsModal} 
      type="infant_journey.counseling"
      formRule={selectedFormRule}/>
    )
  }

  return(
  	<div id="ct">
  	  {loading ? 
        <div className="spinner"/>
        :
        <div className="table-fix-head">
          <FontAwesomeBtn 
            iconCss="fa fa-refresh" isResponsive={false} name="Refresh"
            css="pull-right m-b-5 m-r-15 m-t-5 lh-17"
            colorCss="btn-green"
            onSelect={()=>syncFormRules()}>
          </FontAwesomeBtn>

          <Table className="shadow-small">
            <THead>
              <TR>
                <TH>Id</TH>
                <TH>Name</TH>
                <TH>Alert</TH>
              </TR>
            </THead>
            <TBody>
              <TableData/>
            </TBody>
          </Table>
        </div>
      }
  	</div>
  )
}

const FormRuleAlertIcon = ({formId, currentUser}) =>{
  
  const {isOpen: isModalOpen, toggleModal: toggleModal} = useModal()
  useEscape(toggleModal)

  if(!env.admin_emails.includes(currentUser.email)){
    return null 
  }else if(isModalOpen){
  	return(
  		<GenericModal component={FormRuleAlert} 
      title="Form Rules" formId={formId}
  		toggleModal={toggleModal} isOpen={isModalOpen}/> 
   	) 	
  }else{
  	return(
      <span className='fas fa-exclamation-triangle cursor' onClick={()=>toggleModal()}/>
    )
  } 

}

export {FormRuleAlert, FormRuleAlertIcon};