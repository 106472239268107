export default {
  OrganizationForm:439,
  HealthProfileForm:439,
  HealthJourneyForm:471, //433
  HealthTreatmentForm:436,
  HealthActivityForm:440,
  HealthChildForm:487,
  HealthChildFormBasic:501,
  HealthFamilyForm:471,
  HealthJournalForm:473,
  HealthPlannerForm:476,
  HealthFacilityForm:470,
  HealthHelpRegistration:496,
  HealthForumNewDiscussion:494,
  HealthForumReplyDiscussion:495,
  HealthCounselingForm:485,
  PreScheduleEventForm:524,
  NutritionCareAlertForm:537,
  DataSourceForm:536,
  UploadMeasureGrowthMCJForm:535,
  ScreeningEventForm:542,
  SubscriptionForm:544,
  OrganizationMemberForm: 547,
  MappingForms:['503', '477', '486'],
  McjMeasureGrowthForm:503,
  TrainingProgramForm:550,
  TrainingSessionForm:551,
  TrainingContentForm:552,
  AssessBfForm:477,
  TrainingAssessment:554,
  ContactForm:571,
  FeedbackForm:578
}