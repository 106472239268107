import React, {Fragment} from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { AuthContext } from "../../../../contexts/Authentication/AuthStateProvider";
import CommonService from "../../../../services/CommonService";
import StepActivities from "../../Health/StepActivities/StepActivities";
import {PillDsField, PillDsList, PillTagList} from "../../UserRoles/NotificationPill"

const Treatment = (props) => {
  const { state: authState, dispatch } = React.useContext(AuthContext);

  let activity = props.activity;
  activity.updated_at = CommonService.formatUpdateDate(activity.updated_at);
  let data = activity.data;
  let ds = props.dataSource;

  const cardStyle = {
    backgroundColor:
      data.background_color || data.header_background || "#607D8B",
  };

  if (!data) return null;

  let userRoleLink = {
    pathname: `/journey/user_role/${props.activity.journey_category_id}/${props.activity.education_journey_profile_id}/edu_team_journey.activity/${props.activity.id}`,
    state: { journey: props.journeyCategory, activity: props.activity },
  };

  const MailTag = () => (
    <a
      href={`mailto: ${activity.id}-team_activity@reply.cuetree.com?subject=Education Activity-${data.name}-${activity.record_id}`}
      target="_blank"
    >
      <i className="fas fa-envelope font-18 text-muted" aria-hidden="true"></i>
    </a>
  );

  const Header = () => (
    <div
      className="white card-header p-5"
      style={cardStyle}
      onClick={(e) => props.routeActivity(activity)}
    >
      <div className="font-16">
        {data.name}
        <i className="fas fa-expand pull-right white expand"></i>
      </div>
      <div className="font-16">
        {activity.updated_at}
        <Link
          className="pull-right white expand p-top-0"
          onClick={(e) => e.stopPropagation()}
          to={userRoleLink}
        >
          <i
            className={`fas ${
              activity.user_roles == null ? "fa-lock" : "fa-unlock"
            } `}
          ></i>
        </Link>
      </div>
    </div>
  );

  const Footer = () => {
    return(
    <div className="card-footer">
      <div className="pull-left">
        <span className="m-l-15">
          {activity.activity_form_id} - {activity.record_id}
        </span>
        <span
          className="m-l-25"
          onClick={(e) => {
            props.addNotes(e, activity);
          }}
        >
          <i className="far fa-comment font-18 text-muted"></i>
          <span className="badge-2 up bg-lgrey">{activity.comments_count}</span>
        </span>
        <span
          className="m-l-25"
          onClick={(e) => {
            props.addChecklist(e, activity);
          }}
        >
          <i className="far fa-check-circle font-18 text-muted"></i>
          <span className="badge-2 up bg-lgrey">{activity.check_list_count}</span>
        </span>
        <span className="m-l-25">
          <Link
            to={`/health/incoming/emails/treatment/${activity.id}`}
          >
            <i className="fas fa-envelope font-18 text-muted"></i>
            <span className="badge-2 up bg-lgrey">
              {activity.mail_count || 0}
            </span>
          </Link>
        </span>
      </div>
      <div className="pull-right">
        <props.menu activity={activity} />
      </div>
    </div>
  )};


  const CovidCard = () => {
    return (
      <div className="card-container bg-white shadow-small">
        <div
          className="white card-header p-5"
          style={cardStyle}
          onClick={(e) => props.routeActivity(activity)}
        >
          <div className="font-16">
            {data.name}
            <i className="fas fa-expand pull-right white expand"></i>
          </div>
          <div className="font-16">
            {activity.updated_at}
            <Link
              className="pull-right white expand p-top-0"
              onClick={(e) => e.stopPropagation()}
              to={userRoleLink}
            >
              <i
                className={`fas ${
                  activity.user_roles == null ? "fa-lock" : "fa-unlock"
                } `}
              ></i>
            </Link>
          </div>
        </div>
        <div className={`scroll-auto ${props.cardHeightCss}`}>
          <table className="table card-table">
            <tbody>
              <tr>
                <td>
                  <span className="pull-right">
                    Appointment Date: { CommonService.formatUpdateDate(data.appt_formatted_date)}
                  </span>
                  <strong>Notes</strong>
                  <div>{data.notes}</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <Footer />
      </div>
    );
  };

    return <CovidCard />;
};

export default Treatment;

/*<span className="m-l-25"><MailTag/></span>*/
